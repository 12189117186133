import { cx } from '@flowus/common/cx';
import type { SegmentDTO } from '@next-space/fe-api-idl';
import { BlockType, PermissionRole, PermissionType, TextType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useState } from 'react';
import { Button } from 'src/common/components/button';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { message } from 'src/common/components/message';
import { useOpenModal } from 'src/common/components/next-modal';
import type { SearchItem } from 'src/components/search';
import { SearchMenu } from 'src/components/search';
import { covertNextBlockToSearchItem } from 'src/components/search/covert-next-block-to-search-item';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { useTransaction } from 'src/hooks/use-transaction';
import { useCurrentUser } from 'src/hooks/user';
import { addBlock } from 'src/redux/managers/block/add';
import { getState } from 'src/redux/store';
import { bizTracker } from 'src/utils/biz-tracker';
import { getLocationOrigin } from 'src/utils/location-utils';
import { AnnotationType } from '../../type';
import { annotationFilter } from './annotation-filter';
import { useAnnotations } from './use-annotations';

const ScopeOptions = [
  { name: '所有标注', type: 'all' },
  { name: '当前筛选范围', type: 'filter' },
];

const ContentOptions = [
  { name: '带引用链接的标注', type: 'refBlock' },
  { name: '仅导出标注内容', ype: 'AnnotationContent' },
];

interface Props {
  onCloseModal: () => void;
  uuid: string;
}

export const ExportSetting: FC<Props> = ({ onCloseModal, uuid }) => {
  const openModal = useOpenModal();
  const transaction = useTransaction();

  const currentUser = useCurrentUser();

  const annotationsMap = useAnnotations(uuid);
  const [scope, setScope] = useState(ScopeOptions[0]);
  const [contentType, setContentType] = useState(ContentOptions[0]);
  const [targetPage, setTargetPage] = useState<{ id: string; title: string }>({
    id: '',
    title: '创建新页面',
  });

  const selectScope = (event: React.MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom-end',
      offset: [0, 10],
      content: ({ onCloseModal }) => {
        return (
          <ListView
            className="next-modal w-[180px] py-[5px] bg-white4"
            onItemClick={() => onCloseModal()}
            items={ScopeOptions.map((item) => {
              return {
                type: ListItemType.OPERATION,
                data: {
                  title: item.name,
                  arrow: <Icon name="IcSelectedSpace" size="normal" />,
                  hasArrow: scope?.type === item.type,
                  onClick: () => setScope(item),
                },
              };
            })}
          />
        );
      },
    });
  };

  const selectAnnotationContent = (event: React.MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom-end',
      offset: [0, 10],
      content: ({ onCloseModal }) => {
        return (
          <ListView
            className="next-modal w-[180px] py-[5px] bg-white4"
            onItemClick={() => onCloseModal()}
            items={ContentOptions.map((item) => {
              return {
                type: ListItemType.OPERATION,
                data: {
                  title: item.name,
                  arrow: <Icon name="IcSelectedSpace" size="normal" />,
                  hasArrow: contentType?.type === item.type,
                  onClick: () => setContentType(item),
                },
              };
            })}
          />
        );
      },
    });
  };

  const selectPage = async (event: React.MouseEvent) => {
    const popcorn = event.currentTarget;
    const spaceId = getCurrentSpaceId();

    const openSelectMenu = () => {
      const { blocks } = getState();

      const defaultItems = (blocks[spaceId]?.subNodes ?? [])
        .map((id) => {
          const itemBlock = blocks[id];
          if (!itemBlock) return undefined;

          if (
            itemBlock.type === BlockType.PAGE ||
            itemBlock.type === BlockType.COLLECTION_VIEW ||
            itemBlock.type === BlockType.COLLECTION_VIEW_PAGE
          ) {
            return covertNextBlockToSearchItem(itemBlock);
          }

          return undefined;
        })
        .filter((item): item is SearchItem => !!item);

      openModal.dropdown({
        popcorn,
        placement: 'bottom-end',
        offset: [0, 10],
        content: ({ onCloseModal }) => (
          <SearchMenu
            onSelect={(target) => {
              if (target.type !== BlockType.PAGE) {
                message.warning('不支持该页面类型');
                return;
              }

              setTargetPage({ id: target.uuid, title: target.title });
              onCloseModal();
            }}
            defaultValue={defaultItems}
            searchType="page"
            footer={
              <div className="absolute bottom-0 w-full h-10 bg-white2 border-t border-grey6">
                <div
                  className="flex items-center justify-center h-full animate-hover text-grey3"
                  onClick={() => {
                    setTargetPage({ id: '', title: '创建新页面' });
                    onCloseModal();
                  }}
                >
                  <Icon name="IcAddSmallBold" size="small" />
                  <div className="ml-1.5 text-t2">创建新页面</div>
                </div>
              </div>
            }
            source="move"
          />
        ),
      });
    };

    openSelectMenu();
  };

  const items = [
    { title: '导出范围', name: scope?.name, onClick: selectScope },
    { title: '导出内容', name: contentType?.name, onClick: selectAnnotationContent },
    { title: '导出至页面', name: targetPage.title, onClick: selectPage },
  ];

  const exportToPage = () => {
    bizTracker.event('export_pdf_highlight', { file_id: uuid });

    transaction(() => {
      const spaceId = getCurrentSpaceId();

      const pageId =
        targetPage.id ||
        addBlock(
          {
            type: BlockType.PAGE,
            permissions: [
              {
                role: PermissionRole.EDITOR,
                type: PermissionType.USER,
                userId: currentUser.uuid,
              },
            ],
          },
          { parentId: spaceId, last: true }
        );

      const pageNumbers = Object.keys(annotationsMap ?? {}).sort((a, b) => Number(a) - Number(b));
      pageNumbers.forEach((number) => {
        const annotations = annotationsMap?.[number];
        if (!annotations) return;

        annotations.forEach((annotation) => {
          if (
            scope?.type === 'filter' &&
            annotationFilter.filter &&
            annotation.color !== annotationFilter.filter
          ) {
            return;
          }

          if (annotation.type === AnnotationType.HIGHLIGHT) {
            const segment: SegmentDTO = {
              enhancer: {},
              type: TextType.TEXT,
              text: annotation.text ?? '',
            };

            if (contentType?.type === 'refBlock') {
              segment.type = TextType.MENTION_BLOCK;
              segment.uuid = annotation.uuid;
            }

            addBlock(
              { type: BlockType.TEXTAREA, data: { segments: [segment] } },
              { parentId: pageId, last: true }
            );
          } else {
            const segment: SegmentDTO = { enhancer: {}, type: TextType.TEXT, text: '图片标注.png' };

            if (contentType?.type === 'refBlock') {
              addBlock(
                {
                  type: BlockType.REFERENCE,
                  data: { ref: { uuid: annotation.uuid }, display: 'image', segments: [segment] },
                },
                { parentId: pageId, last: true }
              );
            } else {
              addBlock(
                {
                  type: BlockType.FILE,
                  data: {
                    display: 'image',
                    segments: [segment],
                    extName: 'png',
                    height: annotation.height,
                    ossName: annotation.ossName,
                    size: annotation.size,
                    width: annotation.width,
                  },
                },
                { parentId: pageId, last: true }
              );
            }
          }
        });
      });

      window.open(`${getLocationOrigin()}/${pageId}`);
      onCloseModal();
    });
  };

  return (
    <div className="next-modal w-[360px] py-4">
      {items.map(({ title, name, onClick }) => {
        return (
          <div className="flex justify-between items-center h-10 px-8" key={name}>
            <span className="text-t2 text-grey3">{title}</span>
            <button
              className={cx('flex items-center animate-hover p-1 text-t2 text-black')}
              onClick={onClick}
            >
              {name}
              <Icon size="xxsmall" name={'IcArrow'} className="ml-2" />
            </button>
          </div>
        );
      })}
      <div className="flex items-center justify-end mt-3.5 pr-8">
        <Button onClick={onCloseModal} className="mr-2.5 text-t2">
          取消
        </Button>

        <Button colorType="active" className="text-t2-medium text-white" onClick={exportToPage}>
          导出
        </Button>
      </div>
    </div>
  );
};
