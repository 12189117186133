import { useCallback } from 'react';
import { request } from 'src/common/request';
import { $currentUserCache } from 'src/services/user/current-user';
import { ViewPath } from 'src/utils';
import { locationToUrl } from 'src/utils/location-utils';
import { useClearUser } from './use-clear-user';

/**
 * 登出
 */
export const useLogout = () => {
  const clearUser = useClearUser();

  return useCallback(async () => {
    void request.infra.logoutUser.raw($currentUserCache.uuid);
    await clearUser();
    locationToUrl(ViewPath.login);
  }, [clearUser]);
};
