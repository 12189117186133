import { cx } from '@flowus/common/cx';
import { throttle } from 'lodash-es';
import { forwardRef, memo, useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useBitable } from 'src/bitable/context';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import { useShowTimelineTable } from 'src/hooks/collection-view/use-collection-view';
import { useIsMobileSize } from 'src/services/app/hook';
import { ONE_HOURS } from 'src/utils/date-utils';
import { Direction } from '../const';
import { StepLengthMap, UnitWidthMap, ZoomLevel, ZoomLevels } from './const';
import { useTimeline } from './context';
import {
  formatDate,
  getDateTimeStamp,
  getTimelineDateRange,
  getUnitLength,
  getUnitTime,
  isWeekOrMonth,
} from './utils/get-timeline-dates';

export const TimelineHeader = memo(
  forwardRef<HTMLDivElement>((_, ref) => {
    const { container, setTimelineDates, scrollLeft, stepLength, timelineDates, zoomLevel } =
      useTimeline();
    const { viewId, managerReadonly, isLocked, embed } = useBitable();
    const isShowTable = useShowTimelineTable(viewId);
    const isMobileSize = useIsMobileSize();
    const openModal = useOpenModal();

    const [currentDate, setCurrentDate] = useState<number>();
    const unitWidth = UnitWidthMap[zoomLevel];

    useEffect(() => {
      const containerNode = container.current;
      if (!containerNode) return;

      const handleScroll = () => {
        const unitLength = Math.floor(containerNode.scrollLeft / UnitWidthMap[zoomLevel]);
        const date = (timelineDates[0] as number) + unitLength * getUnitTime(zoomLevel);

        if (date) {
          setCurrentDate((oldDate) => {
            let newDate = 0;
            if (isWeekOrMonth(zoomLevel) || zoomLevel === ZoomLevel.QUARTER) {
              newDate = new Date(date).setDate(2);
            } else if (zoomLevel === ZoomLevel.YEAR) {
              newDate = new Date(date).setMonth(0, 2);
            } else if (zoomLevel === ZoomLevel.DAY || zoomLevel === ZoomLevel.HOUR) {
              newDate = getDateTimeStamp(date) + 12 * ONE_HOURS;
            }

            return newDate !== oldDate ? newDate : oldDate;
          });
        }
      };

      handleScroll();
      const scrollFn = throttle(handleScroll, 60);
      containerNode.addEventListener('scroll', scrollFn);
      return () => containerNode.removeEventListener('scroll', scrollFn);
    }, [unitWidth, container, zoomLevel, timelineDates]);

    const handleClick = (event: React.MouseEvent) => {
      if (isLocked || managerReadonly) return;

      const items = ZoomLevels.map((item) => ({
        type: ListItemType.OPERABLE_BLOCK_TEXT,
        data: { title: item.name, ...item, selected: item.level === zoomLevel },
      }));

      openModal.dropdown({
        popcorn: event.currentTarget,
        placement: 'bottom',
        content: ({ onCloseModal }) => {
          return (
            <ListView
              className="next-modal w-36 py-2"
              items={items}
              onItemClick={({ data }) => {
                if (!container.current) return;

                stepLength.current = StepLengthMap[data.level as ZoomLevel];
                const newDates = getTimelineDateRange({
                  zoomLevel: data.level,
                  length: stepLength.current,
                });
                setTimelineDates(newDates);

                scrollLeft.current =
                  getUnitLength(Date.now(), newDates[0] as number, data.level) *
                    UnitWidthMap[data.level as ZoomLevel] -
                  container.current.clientWidth / 2;

                updateViewFormat(viewId, { timelinePreference: { zoomLevel: data.level } });
                onCloseModal();
              }}
            />
          );
        },
      });
    };

    const jumpToToday = () => {
      if (!container.current) return;

      const newDates = getTimelineDateRange({
        zoomLevel,
        length: stepLength.current,
      });

      setTimelineDates(newDates);

      scrollLeft.current =
        getUnitLength(Date.now(), newDates[0] as number, zoomLevel) * UnitWidthMap[zoomLevel] -
        container.current.clientWidth / 2;
    };

    const jump = (dir: Direction) => {
      if (container.current) {
        if (dir === Direction.left) {
          container.current.scrollLeft -= container.current.clientWidth / 2;
        } else {
          container.current.scrollLeft += container.current.clientWidth / 2;
        }
      }
    };

    return (
      <div
        className="absolute top-0.5 left-0 z-40 flex h-[36px] w-full items-center whitespace-nowrap"
        ref={ref}
      >
        {currentDate && (
          <>
            <div className="flex h-full">
              <div className={cx('flex items-center bg-white1', isShowTable ? 'pl-2' : 'pl-4')}>
                {!isShowTable && !managerReadonly && !isLocked && (
                  <Tooltip
                    popup="显示表格"
                    onClick={() => updateViewFormat(viewId, { timelineShowTable: true })}
                  >
                    <Icon name="IcUnfold" size="middle" className="animate-hover mr-4 text-grey3" />
                  </Tooltip>
                )}

                <span className="text-t2-medium inline-flex h-full items-center bg-white1">
                  {formatDate(currentDate).year}年
                  {zoomLevel !== ZoomLevel.YEAR && <>{formatDate(currentDate).month}月</>}
                  {(zoomLevel === ZoomLevel.DAY || zoomLevel === ZoomLevel.HOUR) && (
                    <>{formatDate(currentDate).date}日</>
                  )}
                </span>
              </div>

              <span
                className="h-full w-6"
                style={{
                  backgroundImage: 'linear-gradient(to right, var(--white1) 20%, transparent 100%)',
                }}
              />
            </div>

            <div className="ml-auto flex h-full items-center">
              <span
                className="h-full w-6"
                style={{
                  backgroundImage: 'linear-gradient(to left, var(--white1) 20%, transparent 100%)',
                }}
              />

              <div
                className={cx(
                  'flex h-full items-center bg-white1',
                  !embed && !isMobileSize && 'pr-24'
                )}
              >
                <button
                  onClick={handleClick}
                  className="text-t2 flex h-full items-center px-3 text-grey3"
                >
                  {ZoomLevels.find((item) => item.level === zoomLevel)?.name}
                  {!managerReadonly && <Icon size="xxxsmall" name="IcArrow" className="ml-1" />}
                </button>

                <Tooltip className="animate-hover rounded-sm">
                  <Icon name="IcArrowDateBack" size="middle" onClick={() => jump(Direction.left)} />
                </Tooltip>

                <span className="text-t2-medium mx-1.5 cursor-pointer" onClick={jumpToToday}>
                  今天
                </span>

                <Tooltip className="animate-hover rounded-sm">
                  <Icon
                    name="IcArrowDateNext"
                    size="middle"
                    onClick={() => jump(Direction.right)}
                  />
                </Tooltip>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }),
  shallowEqual
);
