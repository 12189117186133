import type { CollectionSchema } from '@next-space/fe-api-idl';
import { CollectionSchemaType, CollectionViewType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { useUpdateDateTime } from 'src/hooks/collection-view/use-update-date';
import { useObservableBlock, useObservableStore } from 'src/services/rxjs-redux/hook';
import { ICON_MAP } from '../const';
import { useBitable } from '../context';

interface Props {
  isStartTime: boolean;
  onCloseModal?: () => void;
}

export const DateTime: FC<Props> = ({ isStartTime, onCloseModal }) => {
  const { viewId, collectionId } = useBitable();
  const updateDateTime = useUpdateDateTime();
  const { startBy, endBy } = useObservableStore(
    ({ collectionViews }) => {
      const view = collectionViews[viewId];
      const { timelineBy, timelineByEnd, calendarBy, calendarByEnd } = view?.format ?? {};
      const isTimeline = view?.type === CollectionViewType.TIMELINE;
      return {
        startBy: isTimeline ? timelineBy : calendarBy,
        endBy: isTimeline ? timelineByEnd : calendarByEnd,
      };
    },
    [viewId]
  );

  const allDateProperties = useObservableBlock(collectionId, (collection) => {
    const schema = collection?.data.schema ?? {};
    const allDateProperties: (CollectionSchema & { id: string })[] = [];

    Object.entries(schema).forEach(([property, propertySchema]) => {
      if (
        propertySchema.type === CollectionSchemaType.CREATED_AT ||
        propertySchema.type === CollectionSchemaType.UPDATED_AT ||
        propertySchema.type === CollectionSchemaType.DATE
      ) {
        allDateProperties.push({
          id: property,
          ...propertySchema,
        });
      }
    });

    return allDateProperties;
  });

  const getItems = () => {
    return allDateProperties.map((property) => ({
      type: ListItemType.OPERATION,
      data: {
        id: property.id,
        title: property.name,
        icon: ICON_MAP.date,
        hasArrow: isStartTime ? property.id === startBy : property.id === endBy,
        arrow: <Icon name="IcSelectedSpace" size="normal" />,
      },
    }));
  };

  return (
    <ListView
      className="w-[160px] next-modal-scroll py-[5px]"
      items={getItems()}
      onItemClick={(property) => {
        if (property.data.id === (isStartTime ? startBy : endBy)) return;
        updateDateTime(viewId, isStartTime ? 'startTime' : 'endTime', property.data.id);

        onCloseModal?.();
      }}
    />
  );
};
