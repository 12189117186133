export const createActivityModalAnchor = () => {
  let popcorn = document.getElementById('ActivityModalAnchor');

  if (!popcorn) {
    popcorn = document.createElement('div');
    popcorn.id = 'ActivityModalAnchor';
    popcorn.setAttribute(
      'style',
      'position:fixed; bottom:40px; right:20px; z-index:-1; height:50px; width:50px;'
    );
    document.body.appendChild(popcorn);
  }

  return {
    popcorn,
    reCreate: () => {
      document.body.appendChild(popcorn as HTMLElement);
    },
    remove: () => {
      document.body.removeChild(popcorn as HTMLElement);
    },
  };
};
