import type { SegmentDTO } from '@next-space/fe-api-idl';
import { isFinite } from 'lodash-es';
import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { request } from 'src/common/request';
import { segmentsToText } from 'src/editor/utils/editor';
import { blocksActions } from 'src/redux/reducers/blocks';
import { cache, dispatch } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { isPageLike } from 'src/utils/block-type-utils';

import type { OtherPage, SuggestProps } from '../types';
import { CommonSuggest } from './common-suggest';
import { useSuggest } from './hooks';

export const LinkOther: FC<SuggestProps> = ({ uuid, setState }) => {
  const { block, setPages, spaceId, ...rest } = useSuggest({
    uuid,
    setState,
    type: 'other',
  });

  const fetchSuggest = useCallback(async () => {
    const { blocks } = await request.infra.searchReferences(spaceId, { pageId: uuid });
    if (blocks) {
      dispatch(blocksActions.update({ blocks: blocks as Record<string, NextBlock> }));

      const other: OtherPage = {};

      Object.keys(blocks)
        .filter((pageId) => pageId !== uuid)
        .slice(0, rest.isPro ? Infinity : 2)
        .forEach((pageId) => {
          const page = blocks[pageId];
          if (!page) return;
          const title = segmentsToText(page.data.segments);
          if (isFinite(+title)) return;
          if (!title) return;

          const handleSegments = (_id: string, segments?: SegmentDTO[]) => {
            if (!segments) return;
            if (segments.every(({ text }) => text.indexOf(title) < 0)) return;

            const data = {
              uuid: _id,
              segments: segments.reduce((_segments, _segment, _i) => {
                const handleText = (segment: SegmentDTO) => {
                  const i = segment.text.indexOf(title);
                  if (i > -1) {
                    const pre = segment.text.slice(0, i);
                    if (pre) {
                      _segments.push({ ...segment, text: pre });
                    }
                    _segments.push({
                      type: 'suggest' as any,
                      uuid: pageId,
                      text: '',
                      enhancer: {},
                    });
                    const last = segment.text.substring(i).replace(title, '');
                    if (last) {
                      handleText({ ...segment, text: last });
                    }
                  } else {
                    _segments.push(segment);
                  }
                };
                handleText(_segment);

                return _segments;
              }, [] as SegmentDTO[]),
            };

            if (Array.isArray(other[pageId])) {
              other[pageId]?.push(data);
            } else {
              other[pageId] = [data];
            }
          };

          const loop = (_id: string) => {
            const _block = cache.blocks[_id];
            if (!_block || isPageLike(_block.type)) return;
            const { segments, collectionProperties = {} } = _block.data;
            handleSegments(_id, segments);
            Object.keys(collectionProperties).forEach((key) =>
              handleSegments(_id, collectionProperties[key])
            );
            _block.subNodes.forEach(loop);
          };

          block?.subNodes.forEach(loop);
        });

      setPages(other);
    }
  }, [block?.subNodes, rest.isPro, setPages, spaceId, uuid]);

  useEffect(() => {
    void fetchSuggest();
  }, [fetchSuggest]);

  return (
    <CommonSuggest
      type="other"
      uuid={uuid}
      title="当前提及了其他页面"
      fetchSuggest={fetchSuggest}
      {...rest}
    />
  );
};
