export const sleep = (durationInMs: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, durationInMs);
  });
};

export const isBuildIn = () => {
  //不要去掉问号，插件会报错
  try {
    return __BUILD_IN__;
  } catch {
    return import.meta?.env?.VITE_LOCALE === 'en' || import.meta?.env?.VITE_PRODUCT === 'buildin';
  }
};
