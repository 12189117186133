import type { createRequest, IconData, OccupationDTO } from '@next-space/fe-api-idl';
import type { Argument } from 'classnames';
import classNames from 'classnames';
import type { FC } from 'react';

export type MessageType =
  | {
      content: string;
      duration: number;
    }
  | string
  | any;

export interface OccupationListProps {
  onSelect?: (occupation: OccupationDTO) => void;
}
interface EmojiIconProps {
  value?: string;
  size?: number;
}

interface LoginInjector {
  cdnHost: string;
  event: (eventName: string, params: Record<string, unknown>, flush?: boolean) => void;
  message: {
    success: (props: MessageType) => void;
    /** 报错 */
    error: (props: MessageType) => void;
    /** 警告 */
    warning: (props: MessageType) => void;
  };

  request: ReturnType<typeof createRequest>;
  setBrowserTitle: (title: string, icon?: IconData) => void;
  EmojiIcon: FC<EmojiIconProps>;
  afterLogin: () => void;
  wechatId: string;
  origin: string; //process.env.vitepre
  cx: (...args: Argument[]) => string;
}

//默认实现为空,这里需要由外部提供一些实现
export const loginInjector: LoginInjector = {
  /** cdn域名 */
  cdnHost: 'https://cdn.flowus.cn/',
  //埋点相关
  event: () => {},
  //弹消息相关
  message: {
    success: () => {},
    error: () => {},
    warning: () => {},
  },
  request: {} as ReturnType<typeof createRequest>,
  setBrowserTitle: () => {},
  EmojiIcon: (props) => {
    return (
      <div
        className="flex items-center emoji"
        style={{
          fontSize: props.size ?? 24,
        }}
      >
        {props.value}
      </div>
    );
  },
  afterLogin: () => {},
  wechatId: '',
  // @ts-ignore env
  origin: import.meta.env?.VITE_PREFIX ?? 'https://test.allflow.cn',
  cx: classNames,
};

//使用login需要初始化一些数据，保证一些基础功能能用，登录内部不再重复实现一遍
type LoginOption = Partial<LoginInjector & { request: any }>;
export const loginInit = (option: LoginOption) => {
  if (option.cdnHost) {
    loginInjector.cdnHost = option.cdnHost;
  }
  if (option.event) {
    loginInjector.event = option.event;
  }
  if (option.message?.success) {
    loginInjector.message.success = option.message.success;
  }
  if (option.message?.error) {
    loginInjector.message.error = option.message.error;
  }
  if (option.message?.warning) {
    loginInjector.message.warning = option.message.warning;
  }
  if (option.request) {
    loginInjector.request = option.request;
  }
  if (option.setBrowserTitle) {
    loginInjector.setBrowserTitle = option.setBrowserTitle;
  }
  if (option.EmojiIcon) {
    loginInjector.EmojiIcon = option.EmojiIcon;
  }
  if (option.afterLogin) {
    loginInjector.afterLogin = option.afterLogin;
  }
  if (option.wechatId) {
    loginInjector.wechatId = option.wechatId;
  }
  if (option.origin) {
    loginInjector.origin = option.origin;
  }
  if (option.cx) {
    loginInjector.cx = option.cx;
  }
};
