export enum SearchParams {
  callbackPath = 'from',
  inviteCode = 'code',
  inviteCodeByWeChat = 'invite_code',
  inviteTeam = 'team',
  page = 'page',
  cooperate = 'cooperate',
  /** 创建团队空间内测码，已废弃 */
  teamApplyCode = 'teamApplyCode',
  promotionChannel = 'promotionChannel',
  /** 页面密码 */
  password = 'psw',
  // 快速注册，走的手机号
  quickRegister = 'quick-register',
  /** space_id*/
  spaceId = 'space_id',
  /** 需要打开的弹窗，比如新手任务，升级空间弹窗 */
  openDialog = 'open',
}
