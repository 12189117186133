import dayjs from 'dayjs';
import type { FC, ReactNode } from 'react';
import { useEffect, useRef } from 'react';
import { Button } from 'src/common/components/button';
import { message } from 'src/common/components/message';
import type { ModalSchema } from 'src/common/components/next-modal';
import { useOpenModal } from 'src/common/components/next-modal';
import { DATE_TIME_FORMAT } from 'src/common/const';
import { request } from 'src/common/request';
import { fetchSpaces } from 'src/hooks/space';
import { Images } from 'src/image';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { confetti } from 'src/utils/confetti';
import { openQiMoor } from 'src/utils/qi-moor';

// #region 对公转账
export const PayOfflineModal: FC<{
  orderId: string;
  price: number;
  endTime: number;
  remark?: string;
  changePayType?: () => void;
  customFooter?: ReactNode;
  onCloseModal: ModalSchema.CloseModalType;
  onFinish?: () => void;
}> = (props) => {
  const { price, endTime, remark, changePayType, customFooter, onCloseModal, onFinish } = props;
  const intervalTimer = useRef<NodeJS.Timeout>();
  const openModal = useOpenModal();
  /** 如果用户网速慢的话，可能会触发多次刷新订单，这里加个flag */
  const refreshRunning = useRef<boolean>(false);

  // 刷新订单
  const refreshOrder = (orderId: string) => {
    if (orderId) {
      refreshRunning.current = true;
      void request.infra.getOrderStatus.raw(orderId).then(async (res) => {
        if (res.code === 200) {
          switch (res.data.status) {
            // 已经支付
            case 20:
              intervalTimer.current && clearInterval(intervalTimer.current);
              await fetchSpaces();
              onCloseModal();
              void confetti.fireworks();
              onFinish?.();
              message.success('订单支付成功');
              break;
            // 已经取消
            case 30:
              message.error('订单失效，请重新下单');
              intervalTimer.current && clearInterval(intervalTimer.current);
              break;
            // 待支付
            case 10:
              refreshRunning.current = false;
              break;
            default:
          }
        } else {
          intervalTimer.current && clearInterval(intervalTimer.current);
        }
      });
    } else {
      onCloseModal();
    }
  };

  const onCopy = () => {
    void writeTextInClipboard(
      `转账金额：${price}\n收款方账户名：云上绿洲（杭州）科技有限公司\n收款方账户：110944870510101\n开户行：招商银行股份有限公司北京分行望京融科支行\n转账备注：${remark}`
    );
  };

  useEffect(() => {
    intervalTimer.current = setInterval(() => {
      if (!refreshRunning.current) {
        void refreshOrder(props.orderId);
      }
    }, 3000);
    return () => {
      intervalTimer.current && clearInterval(intervalTimer.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.orderId]);

  return (
    <div className="w-[550px] px-12 pt-6 pb-10 text-center">
      <div className="text-h2 mb-6">购买团队版</div>
      <div className="mb-2.5 text-t2-medium">
        请于<span className="text-blue">{dayjs(endTime).format(DATE_TIME_FORMAT)}之前</span>
        完成转账，转账时请备注订单，我们会在确认到账后2个工作日内开通服务。
      </div>
      <div className="text-t4 text-grey3 mb-7">
        如有任何疑问，请立即联系
        <span
          className="border-b border-grey3 cursor-pointer"
          onClick={() => {
            openQiMoor(openModal);
          }}
        >
          在线客服
        </span>
        帮你解决。
      </div>
      <div className="border border-black/10 rounded py-4 px-5 mb-2.5 relative">
        <div className="flex items-center h-10">
          <Button onClick={onCopy}>复制账户信息</Button>
        </div>
        {[
          {
            title: '转账金额',
            content: price,
          },
          {
            title: '收款方账户名',
            content: '云上绿洲（杭州）科技有限公司',
          },
          {
            title: '收款方账户',
            content: '110944870510101',
          },
          {
            title: '开户行',
            content: '招商银行股份有限公司北京分行望京融科支行',
          },
          {
            title: '转账备注',
            content: remark,
          },
        ].map((item) => (
          <div
            key={item.title}
            className="flex items-center justify-between h-10 text-t2 text-grey3"
          >
            <span>{item.title}</span>
            <span className="text-t2-medium text-black">{item.content}</span>
          </div>
        ))}
        <img src={Images.orderRemarks} className="absolute -bottom-6 right-3 pointer-events-none" />
      </div>
      <div className="text-red text-t2 text-right pr-20">记得填写备注，否则无法确定到账！</div>
      <div className="flex items-center justify-center my-4">
        {customFooter}
        {changePayType && (
          <Button
            className="w-32 text-t2-medium"
            onClick={() => {
              changePayType();
            }}
          >
            更换支付方式
          </Button>
        )}
      </div>
    </div>
  );
};
// #endregion
