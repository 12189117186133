import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { segmentsToText } from 'src/editor/utils/editor';
import { getCurrentSpace } from 'src/hooks/space';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { judgeSharePage, useGetPageId } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { useDrawerOpenState } from '../drawer/shared';
import { bizTracker } from 'src/utils/biz-tracker';
import { useOpenModal } from '@flowus/common/next-modal';
import { Modals } from '@flowus/shared';
import { useEnableAI } from 'src/hooks/block/use-enable-AI';
import { emitter } from '@flowus/common';

interface Props {
  /** space,page是未输入关键词，ai是输入关键词之后的类型 */
  showType: 'space' | 'page';
  className?: string;
  keyword?: string;
}
/** https://flowus.cn/57e8ca8c-a7c1-475a-8987-6088018c1899 */
export const AiTip: FC<Props> = (props) => {
  const openModal = useOpenModal();
  const isShare = judgeSharePage();
  const pageId = useGetPageId();
  const block = usePickBlock(pageId, ['data'], ['segments']);
  const [drawState, setDrawState] = useDrawerOpenState();
  const { enableAI } = useEnableAI();

  if (isShare || !enableAI) return <div className="mt-2" />;

  let text = '';
  if (props.keyword) {
    text = `问问AI"${props.keyword}"`;
  } else if (props.showType === 'space') {
    text = `在「${getCurrentSpace().title}」中问问AI`;
  } else if (props.showType === 'page') {
    text = `在「${segmentsToText(block?.data.segments) ?? getUntitledName(block?.type)}」中问问AI`;
  } else if (props.showType === 'ai') {
    text = `问问AI "知识库"`;
  }
  return (
    <div
      className={cx('flex items-center animate-hover', props.className)}
      onClick={() => {
        openModal.closeModal(Modals.QUICK_SEARCH);
        if (drawState === 'ai-chat') {
          // 如果有问题就发送问题过去
          emitter.emit('aiPage', {
            prompt: props.keyword ?? '',
            pageId: props.showType === 'page' ? pageId : '',
          });
          return;
        }
        bizTracker.event('ai-search');
        setDrawState('ai-chat');
        setTimeout(() => {
          if (props.keyword || props.showType === 'page') {
            emitter.emit('aiPage', {
              prompt: props.keyword ?? '',
              pageId: props.showType === 'page' ? pageId : '',
            });
          }
        }, 300);
      }}
    >
      <Icon name={'MIcAiMiddleColor'} size="middle" />
      <div className="text-t2-medium ml-1.5">{text}</div>
    </div>
  );
};
