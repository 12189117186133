import {
  CardSize,
  CollectionCardImageDir,
  CollectionSchemaType,
  CollectionViewType,
  CoverAspectType,
  CoverType,
} from '@next-space/fe-api-idl';
import type { MouseEvent } from 'react';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { useOpenModal } from 'src/common/components/next-modal';
import { Switch } from 'src/common/components/switch';
import { ActivityIds } from 'src/hooks/activities/activity-ids';
import { useUpdateTask } from 'src/hooks/activities/use-update-task';
import { ActivitiesListType } from 'src/hooks/activities/use-activity';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import {
  useCardImageDir,
  useCardSize,
  useCoverAspectType,
  useCoverSetting,
  useShowPropertyName,
} from 'src/hooks/collection-view/use-collection-view';
import { useProperties } from 'src/hooks/collection-view/use-properties';
import { useUpdateCardSize } from 'src/hooks/collection-view/use-update-card-size';
import { useUpdateCoverType } from 'src/hooks/collection-view/use-update-cover-type';
import { useBitable } from '../context';

export const CardSizeMap = {
  [CardSize.SMALL]: '小',
  [CardSize.MEDIUM]: __BUILD_IN__ ? '中号' : '中',
  [CardSize.LARGE]: '大',
};

export const CardImageDirMap = {
  [CollectionCardImageDir.HORIZONTAL]: '横向',
  [CollectionCardImageDir.VERTICAL]: '纵向',
};

export const CardPreviewMap = {
  [CoverType.NONE]: '无',
  [CoverType.PAGE_COVER]: '封面',
  [CoverType.PAGE_CONTENT]: '页面内容',
};

export const CardControl = () => {
  const updateTask = useUpdateTask();
  const { viewId, viewType, isLocked } = useBitable();

  const [properties = []] = useProperties(viewId);
  const cardSize = useCardSize(viewId);
  const imageDir = useCardImageDir(viewId);
  const coverAspectType = useCoverAspectType(viewId);
  const showPropertyName = useShowPropertyName(viewId);
  const { previewType, previewProperty } = useCoverSetting(viewId);

  const openModal = useOpenModal();
  const updateCoverType = useUpdateCoverType();
  const updateCardSize = useUpdateCardSize();

  const isBoard = viewType === CollectionViewType.BOARD;

  const changeCardPreview = (event: MouseEvent) => {
    if (isLocked) return;

    const fileProperties = properties.filter((it) => it.type === CollectionSchemaType.FILE);
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom-end',
      offset: [-8, 0],
      content: ({ onCloseModal }) => {
        return (
          <ListView
            className="next-modal w-[160px] py-[5px]"
            items={[
              {
                type: ListItemType.OPERABLE_BLOCK_TEXT,
                data: {
                  title: '无',
                  type: CoverType.NONE,
                  selected: previewType === CoverType.NONE,
                },
              },
              {
                type: ListItemType.OPERABLE_BLOCK_TEXT,
                data: {
                  title: '封面',
                  type: CoverType.PAGE_COVER,
                  selected: previewType === CoverType.PAGE_COVER,
                },
              },
              {
                type: ListItemType.OPERABLE_BLOCK_TEXT,
                data: {
                  title: '页面内容',
                  type: CoverType.PAGE_CONTENT,
                  selected: previewType === CoverType.PAGE_CONTENT,
                },
              },
              {
                type: ListItemType.LINE,
                isHidden: fileProperties.length <= 0,
              },
              ...fileProperties.map((it) => {
                return {
                  type: ListItemType.OPERATION,
                  data: {
                    title: it.name,
                    icon: 'IcTitleFiles',
                    //  TODO: CoverType.FILE_PROPERTY
                    type: CoverType.FILE_PROPERTY,
                    property: it.property,
                    selected:
                      previewType === CoverType.FILE_PROPERTY && previewProperty === it.property,
                  },
                };
              }),
            ]}
            onItemClick={(item) => {
              if (item.data.type !== previewType || item.data.property !== previewProperty) {
                updateCoverType(viewId, item.data.type, item.data.property);
                // 积分任务
                if (item.data.type === CoverType.PAGE_COVER) {
                  void updateTask(
                    ActivityIds.GUIDE_FIRST_TIME_BOARD_VIEW,
                    ActivitiesListType.advancedList
                  );

                  if (!isBoard) {
                    void updateTask(
                      ActivityIds.CREATE_GALLERY_VIEW,
                      ActivitiesListType.advancedList,
                      { step2: true }
                    );
                  }
                }
              }

              onCloseModal();
            }}
          />
        );
      },
    });
  };

  const changeCardSize = (event: MouseEvent) => {
    if (isLocked) return;

    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom-end',
      offset: [-8, 0],
      content: ({ onCloseModal }) => {
        return (
          <ListView
            className="next-modal w-[160px] py-[5px]"
            items={[
              {
                type: ListItemType.OPERABLE_BLOCK_TEXT,
                data: {
                  title: CardSizeMap[CardSize.SMALL],
                  type: CardSize.SMALL,
                  selected: cardSize === CardSize.SMALL,
                },
              },
              {
                type: ListItemType.OPERABLE_BLOCK_TEXT,
                data: {
                  title: CardSizeMap[CardSize.MEDIUM],
                  type: CardSize.MEDIUM,
                  selected: cardSize === CardSize.MEDIUM,
                },
              },
              {
                type: ListItemType.OPERABLE_BLOCK_TEXT,
                data: {
                  title: CardSizeMap[CardSize.LARGE],
                  type: CardSize.LARGE,
                  selected: cardSize === CardSize.LARGE,
                },
              },
            ]}
            onItemClick={(item) => {
              if (item.data.type === cardSize) return;
              updateCardSize(viewId, item.data.type);
              onCloseModal();
            }}
          />
        );
      },
    });
  };

  const changeImageDir = (event: MouseEvent) => {
    if (isLocked) return;

    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom-end',
      offset: [-8, 0],
      content: ({ onCloseModal }) => {
        return (
          <ListView
            className="next-modal w-[160px] py-[5px]"
            items={[
              {
                type: ListItemType.OPERABLE_BLOCK_TEXT,
                data: {
                  title: '横向',
                  type: CollectionCardImageDir.HORIZONTAL,
                  selected: imageDir === CollectionCardImageDir.HORIZONTAL,
                },
              },
              {
                type: ListItemType.OPERABLE_BLOCK_TEXT,
                data: {
                  title: '纵向',
                  type: CollectionCardImageDir.VERTICAL,
                  selected: imageDir === CollectionCardImageDir.VERTICAL,
                },
              },
            ]}
            onItemClick={(item) => {
              if (item.data.type === imageDir) return;
              updateViewFormat(viewId, {
                [isBoard ? 'boardImageDir' : 'galleryImageDir']: item.data.type,
              });
              onCloseModal();
            }}
          />
        );
      },
    });
  };

  const handleSwitch = (visible: boolean) => {
    if (isLocked) return;

    updateViewFormat(viewId, {
      [isBoard ? 'boardCoverAspect' : 'galleryCoverAspect']: visible
        ? CoverAspectType.CONTAIN
        : CoverAspectType.COVER,
    });
  };

  const handlePropertyNameSetting = (visible: boolean) => {
    if (isLocked) return;

    updateViewFormat(viewId, {
      [isBoard ? 'boardShowPropertyName' : 'galleryShowPropertyName']: visible,
    });
  };

  return (
    <div>
      <button
        className="animate-hover flex h-10 w-full items-center justify-between px-2"
        onClick={changeCardPreview}
      >
        <span className="text-t2">卡片预览</span>
        <div className="flex items-center">
          <span className="text-t2 mr-2 text-grey3">
            {previewType === CoverType.FILE_PROPERTY
              ? (() => {
                  const property = properties.find((it) => it.property === previewProperty);
                  return (
                    <span className="flex items-center">
                      <Icon name="IcTitleFiles" size="middle" />
                      <span>{property?.name}</span>
                    </span>
                  );
                })()
              : CardPreviewMap[previewType]}
          </span>
          <Icon name="IcArrow" size="xxxsmall" />
        </div>
      </button>

      {previewType !== CoverType.FILE_PROPERTY && (
        <button
          className="animate-hover flex h-10 w-full items-center justify-between px-2"
          onClick={changeImageDir}
        >
          <span className="text-t2">卡片样式</span>
          <div className="flex items-center">
            <span className="text-t2 mr-2 text-grey3">{CardImageDirMap[imageDir]}</span>
            <Icon name="IcArrow" size="xxxsmall" />
          </div>
        </button>
      )}

      <button
        className="animate-hover flex h-10 w-full items-center justify-between px-2"
        onClick={changeCardSize}
      >
        <span className="text-t2">卡片大小</span>
        <div className="flex items-center">
          <span className="text-t2 mr-2 text-grey3">{CardSizeMap[cardSize]}</span>
          <Icon name="IcArrow" size="xxxsmall" />
        </div>
      </button>

      {previewType !== CoverType.NONE && (
        <div className="flex h-10 w-full items-center justify-between px-2">
          <span className="text-t2">适应预览图</span>
          <Switch
            open={coverAspectType === CoverAspectType.CONTAIN}
            onSwitch={handleSwitch}
            disabled={isLocked}
          />
        </div>
      )}

      <div className="flex h-10 w-full items-center justify-between px-2">
        <span className="text-t2">显示属性名</span>
        <Switch open={showPropertyName} onSwitch={handlePropertyNameSetting} disabled={isLocked} />
      </div>
    </div>
  );
};
