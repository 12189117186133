import type { FC } from 'react';
import { useForm } from 'react-hook-form';
import { v4 } from 'uuid';
import { Button } from './button';
import { FormInput } from './input';
import { message } from './message';
import { commonPublicLink } from '../const';

const mid = v4();

interface StateType {
  aliLogonId: string;
  aliLogonUsername: string;
  identityNumber: string;
}
interface Props {
  onSuccess: () => void;
  payApi: (params: StateType) => Promise<void | boolean>;
}

export const TransferModal: FC<Props> = (props) => {
  const { onSuccess, payApi } = props;
  const { register, handleSubmit } = useForm<StateType>();

  const onSubmit = async (params: StateType) => {
    if (message.exists(mid)) {
      return;
    }
    message.loading({
      content: '开始提现',
      key: mid,
    });

    await payApi(params).finally(() => {
      message.closeMessage(mid);
    });

    onSuccess();
    message.success('提现完成');
  };

  const list = [
    {
      key: 'aliLogonId',
      placeholder: '支付宝账户',
    },
    {
      key: 'aliLogonUsername',
      placeholder: '支付宝真实姓名',
    },
    {
      key: 'identityNumber',
      placeholder: '身份证号',
    },
  ] as const;

  return (
    <form className="p-4 space-y-5 px-6 w-96" onSubmit={handleSubmit(onSubmit)}>
      <div className="text-h4">提现申请</div>
      <div className="space-y-2.5">
        {list.map((item) => (
          <FormInput
            key={item.key}
            addonBefore={
              <span className="flex-shrink-0 text-t2 border-r pr-2 text-grey3">
                {item.placeholder}
              </span>
            }
            placeholder={`输入${item.placeholder}`}
            className="h-9 px-2 rounded ring-grey6"
            inputProps={register(item.key, { required: true }) as any}
          />
        ))}
        <div className="text-t4 text-grey3">
          <span>继续提现表示您已同意</span>
          <a
            target="_blank"
            href={commonPublicLink.yzh}
            className="text-active_color animate-click"
            rel="noreferrer"
          >
            《共享经济合作伙伴协议》
          </a>
        </div>
        <Button colorType="active" className="ml-auto" type="submit">
          提现
        </Button>
      </div>
    </form>
  );
};
