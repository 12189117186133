import { useMemo, type FC } from 'react';
import { useChartFormat, useUpdateChartFormat } from 'src/hooks/chart';
import { useChartBlockId } from './chart-config-context';
import { Select } from './select';
import { useSelectProperty } from './use-select-property';
import { useStore } from 'react-redux';
import { getProperty } from 'src/utils/collection-util';
import { Icon } from 'src/common/components/icon';
import type { YAxisProperty } from '@next-space/fe-api-idl';
import { AggregationAction } from '@next-space/fe-api-idl';
import { invalidProperty } from './data';
import { cx } from '@flowus/common/cx';

const defaultYAxisProperty: YAxisProperty = {
  propertyId: 'title',
  aggregation: AggregationAction.COUNT_ALL,
};
/** 分组聚合 */
export const GroupCombine: FC = () => {
  const chartBlockId = useChartBlockId();
  const updateChartFormat = useUpdateChartFormat();
  const format = useChartFormat(chartBlockId);
  const selectProperty = useSelectProperty();
  const collectionId = format?.ref?.uuid;
  const yAxisPropertyIds = format?.yAxisPropertyIds ?? (collectionId ? [defaultYAxisProperty] : []);
  const store = useStore();
  const propertyInfo = useMemo(() => {
    if (!format?.groupAggregationPropertyId) return;
    return (
      getProperty(store, collectionId, format?.groupAggregationPropertyId ?? '') ?? invalidProperty
    );
  }, [store, collectionId, format?.groupAggregationPropertyId]);
  if (yAxisPropertyIds.length > 1) return null;
  if (format?.chartMode?.type === 'Number' || format?.chartMode?.type === 'Pie') return null;

  const propertyNode = propertyInfo ? (
    <div className="flex items-center">
      {propertyInfo.icon && <Icon name={propertyInfo.icon} size="middle" />}
      <div
        className={cx('text-ellipsis max-w-[124px] ml-0.5', {
          'text-red': invalidProperty === propertyInfo,
        })}
      >
        {propertyInfo?.title}
      </div>
      <div
        className="p-0.5 animate-hover"
        onClick={(e) => {
          e.stopPropagation();
          updateChartFormat(chartBlockId, {
            groupAggregationPropertyId: '',
          });
        }}
      >
        <Icon name={'IcTitleClose'} size="small" />
      </div>
    </div>
  ) : (
    '不分组'
  );

  return (
    <div className="mt-1 text-t2 flex items-center justify-between">
      <div>分组聚合</div>
      <Select
        showBorder={false}
        title={propertyNode}
        onClick={(e) => {
          if (!collectionId) return;
          selectProperty(collectionId, {
            popcorn: e.currentTarget,
            callback: (propertyId) => {
              updateChartFormat(chartBlockId, {
                groupAggregationPropertyId: propertyId,
              });
            },
          });
        }}
      />
    </div>
  );
};
