import { BlockType } from '@next-space/fe-api-idl';
import { contentToString, splitContent } from '@next-space/fe-inlined';
import { useCallback } from 'react';
import { findLanguage } from 'src/editor/editor/plugin/code/codemirror-utils';
import {
  DEFAULT_CODE_LANGUAGE,
  LAST_SELECTED_CODE_LANGUAGE,
} from 'src/editor/editor/plugin/code/const';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import { segmentsToText } from 'src/editor/utils/editor';
import { convertContentToSegments } from 'src/editor/utils/segments';
import { useFocusEditableByBlockId } from 'src/hooks/editor/use-focus-by-id';
import { useTransaction } from 'src/hooks/use-transaction';
import { HISTORY_EFFECTS } from 'src/redux/actions';
import { addBlock } from 'src/redux/managers/block/add';
import { removeBlock } from 'src/redux/managers/block/remove';
import { updateBlock } from 'src/redux/managers/block/update';
import { useCloseCreateBlockMenuList } from 'src/redux/managers/ui';
import { isLikeFoldBlock } from 'src/redux/managers/ui/use-fold';
import { dispatch, getState } from 'src/redux/store';
import type { NextWhere, SegmentType } from 'src/redux/types';
import { $appUiStateCache } from 'src/services/app';
import { getLastCodeLanguage } from 'src/utils/block-utils';
import { setLocalStorage } from 'src/utils/local-storage';
import { v4 as uuid4 } from 'uuid';

export const useEnterKey = (uuid: string, segmentType: SegmentType) => {
  const transaction = useTransaction();
  const closeCreateBlockMenuList = useCloseCreateBlockMenuList();
  const getEditorModel = useGetOrInitEditorModel();
  const focusEditableAt = useFocusEditableByBlockId();
  const enterKey = (event: globalThis.KeyboardEvent) => {
    event.preventDefault();
    const { blocks } = getState();
    const block = blocks[uuid];

    const editorModel = getEditorModel(uuid);
    const selection = editorModel?.selection;

    if (!block || !editorModel || !selection) return;
    if (editorModel.isComposing) return;

    if (event.shiftKey) {
      editorModel.performChange((ctx) => {
        ctx.replace('\n');
      });
      return;
    }

    const text = segmentsToText(block.data.segments);
    if (
      text === '' &&
      [
        BlockType.TODO,
        BlockType.HEADER,
        BlockType.ORDER_LIST,
        BlockType.LIST,
        BlockType.MARK,
        BlockType.QUOTE,
        BlockType.FOLD_LIST,
        BlockType.TOGGLE_HEADER,
      ].includes(block.type)
    ) {
      transaction(() => {
        updateBlock(block.uuid, { type: BlockType.TEXTAREA });
        focusEditableAt(block.uuid, 0);
      });
      return;
    }

    const { content } = editorModel;
    const { offset, endOffset } = selection;

    // 公式块快捷输入
    const contentStr = contentToString(content);
    if (/(\$\$|￥￥)$/.test(contentStr)) {
      updateBlock(block.uuid, {
        type: BlockType.EQUATION,
        local: true,
        data: {
          ...block.data,
          segments: [],
        },
      });
      return;
    }

    // 代码块Markdown快捷键
    if (
      selection.isCollapsed &&
      offset === content.length &&
      /^(?:(`|·){3,}|~{3,}|～{3,})[A-Za-z#() +.-]*$/.test(contentStr)
    ) {
      const language = findLanguage(contentStr.slice(3).trim());
      const newBlockId = uuid4();
      transaction(() => {
        addBlock(
          {
            uuid: newBlockId,
            type: BlockType.CODE,
            data: { format: { language } },
          },
          { parentId: block.parentId, after: block.uuid }
        );
        removeBlock(block.uuid);
        dispatch(
          HISTORY_EFFECTS({
            init() {
              // 聚焦新 block
              setTimeout(() => {
                focusEditableAt(`${newBlockId}_mock`, 0);
              }, 150);
            },
            undo() {
              focusEditableAt(block.uuid, offset);
            },
            redo() {
              setTimeout(() => {
                focusEditableAt(`${newBlockId}_mock`, 0);
              }, 150);
            },
          })
        );
      });
      return;
    }

    const [left] = splitContent(content, offset);
    const [, right] = splitContent(content, endOffset);
    const beforeSegments = convertContentToSegments(left);
    const afterSegments = convertContentToSegments(right);

    // 在前方插入一个 textarea block
    if (offset === 0) {
      const parent = blocks[block.parentId];
      if (!parent) return;
      const index = parent.subNodes.indexOf(block.uuid);
      const prevId = parent.subNodes[index - 1];
      if (segmentType === 'segments') {
        transaction(() => {
          updateBlock(block.uuid, {
            data: { segments: afterSegments },
          });
          addBlock(
            {},
            prevId
              ? { parentId: block.parentId, after: prevId }
              : { parentId: block.parentId, first: true }
          );
        });
        focusEditableAt(block.uuid, 0);
      } else {
        // 如果是caption则往后加
        transaction(() => {
          updateBlock(block.uuid, {
            data: {
              showEmptyCaption: false,
              caption: [],
            },
          });
          const nextClockId = addBlock(
            {
              data: { segments: afterSegments },
            },
            { parentId: block.parentId, after: block.uuid }
          );
          focusEditableAt(nextClockId, 0);
        });
      }

      closeCreateBlockMenuList();
      return;
    }

    let newType = BlockType.TEXTAREA;
    let where: NextWhere = { parentId: block.parentId, after: block.uuid };
    const flowTypes = [
      BlockType.TEXTAREA,
      BlockType.LIST,
      BlockType.ORDER_LIST,
      BlockType.TODO,
      BlockType.FOLD_LIST,
    ];
    // 当前是fold-list(toggleHeader不算)，并且没有展开，则在下方再新建一个fold-list
    const isExpand = $appUiStateCache.$expandFoldRecord[block.uuid];
    if (isLikeFoldBlock(block.type) && !isExpand) {
      if (flowTypes.includes(block.type)) {
        newType = block.type;
      }
    } else {
      const [firstChildId] = block.subNodes;
      const firstChild = firstChildId ? blocks[firstChildId] : undefined;
      if (firstChild || [BlockType.FOLD_LIST, BlockType.TOGGLE_HEADER].includes(block.type)) {
        where = { parentId: block.uuid, first: true };
      }

      if (firstChild) {
        if (flowTypes.includes(firstChild.type)) {
          newType = firstChild.type;
        }
      }
    }

    transaction(() => {
      updateBlock(block.uuid, {
        data: { [segmentType]: beforeSegments },
      });
      const newBlockId = addBlock(
        {
          type: newType,
          data: { segments: afterSegments, level: block.data.level },
        },
        where
      );
      closeCreateBlockMenuList();
      dispatch(
        HISTORY_EFFECTS({
          init() {
            // 聚焦新 block
            focusEditableAt(newBlockId, 0);
          },
          undo() {
            focusEditableAt(block.uuid, offset, segmentType);
          },
          redo() {
            focusEditableAt(newBlockId, 0);
          },
        })
      );
    });
  };

  return useCallback(enterKey, [
    uuid,
    getEditorModel,
    transaction,
    focusEditableAt,
    segmentType,
    closeCreateBlockMenuList,
  ]);
};
