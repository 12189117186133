import { CoverType } from '@next-space/fe-api-idl';
import { chunk, debounce } from 'lodash-es';
import { useEffect } from 'react';
import { useCoverSetting } from 'src/hooks/collection-view/use-collection-view';
import { fetchRecordContent } from 'src/hooks/page/use-fetch-record-content';
import { getIntersectionRatio } from 'src/utils/collision';
import { useBitable } from '../context';
import './style.css';

export const useCardPageContent = (container: HTMLDivElement | null) => {
  const { viewId } = useBitable();
  const { previewType } = useCoverSetting(viewId);

  useEffect(() => {
    if (previewType !== CoverType.PAGE_CONTENT) return;

    const containerNode = container;
    const nextSpacePage = containerNode?.closest('.next-space-page');
    if (!containerNode || !nextSpacePage) return;

    const fetchPageContent = () => {
      const allNodes = containerNode.querySelectorAll('[data-card-id]');
      const pageRect = nextSpacePage.getBoundingClientRect();

      const blockIds = new Set<string>();
      (allNodes as NodeListOf<HTMLElement>).forEach((node) => {
        const { cardId, loaded } = node.dataset;
        if (!cardId || loaded === 'true') return;

        const nodeRect = node.getBoundingClientRect();
        if (getIntersectionRatio(nodeRect, pageRect) > 0) {
          blockIds.add(cardId);
          node.dataset.loaded = 'true';
        }
      });

      if (blockIds.size > 0) {
        const blockIdArray = chunk([...blockIds], 50);
        blockIdArray.forEach((v) => {
          void fetchRecordContent([...v]);
        });
      }
    };

    setTimeout(() => fetchPageContent(), 100);

    const handleScroll = debounce(fetchPageContent, 100);

    nextSpacePage.addEventListener('scroll', handleScroll);
    return () => {
      nextSpacePage.removeEventListener('scroll', handleScroll);
    };
  }, [container, previewType, viewId]);
};
