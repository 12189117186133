import { Icon } from 'src/common/components/icon';
import { cx } from '@flowus/common/cx';

import { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { AutoHeightTextArea } from 'src/common/components/auto-height-text-area';
import { $appUiStateCache, setAppUiState, useAiPDFQuotes } from 'src/services/app';
import type { PDFViewApplication } from '../service/pdf-viewer-application';
import { parsePDFAiMessage } from 'src/editor/editor/uikit/ai-editor/utils';
import { getToken } from 'src/utils/get-next-auth';
import { useCurrentUser } from 'src/hooks/user';
import { v4 } from 'uuid';
import { message } from 'src/common/components/message';
import { Tooltip } from 'src/common/components/tooltip';
import isHotkey from 'is-hotkey';
import { PRODUCT_AI_TITLE } from 'src/const/title';
import { useCurrentSpace } from 'src/hooks/space';

interface Props {
  uuid?: string;
  recordId?: string;
  propertyId?: string;
  ossName: string;
  application: PDFViewApplication;
  container: React.RefObject<HTMLDivElement>;
}

export const PromptInput: FC<Props> = ({ recordId, uuid, propertyId, ossName, container }) => {
  const [prompt, setPrompt] = useState('');
  const quotes = useAiPDFQuotes();
  const userData = useCurrentUser();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const currentSpace = useCurrentSpace();

  useEffect(() => {
    inputRef.current?.focus();
  }, [quotes]);

  const submitPrompt = async () => {
    const blockId = uuid ?? recordId;
    if (!blockId) return;
    if (!prompt.trim()) return;

    setPrompt('');
    inputRef.current?.blur();

    const quote =
      quotes.length > 0
        ? quotes.map((item) => ({ pageNumber: item[0], content: item[1], coords: item[2] }))
        : [];
    const params = {
      quote,
      question: prompt,
      ossName,
      blockId,
      propertyId,
      spaceId: currentSpace.uuid,
    };
    void askAi(userData.uuid, params, container);
  };

  return (
    <div className="p-4 bg-white2 sticky bottom-0 border-t">
      {quotes.length > 0 && (
        <>
          {quotes.map((quote) => {
            return (
              <div className="flex justify-between items-center mb-2.5" key={quote[1]}>
                <Tooltip
                  popup={quote[1]}
                  popupClass={'line-clamp-none'}
                  className="py-2 px-3 bg-grey8 rounded text-ellipsis flex-1 flex items-center "
                >
                  <span className="w-1 bg-grey4 mr-3 inline-block h-5 flex-shrink-0" />
                  <span className="text-ellipsis">{quote[1]}</span>
                </Tooltip>

                <Icon
                  name="IcUploadCancel"
                  size="middle"
                  className="ml-3 text-grey4 cursor-pointer"
                  onClick={() => {
                    setAppUiState({
                      $pdfQuotes: quotes.filter(
                        (item) => item[0] !== quote[0] || item[1] !== quote[1]
                      ),
                    });
                  }}
                />
              </div>
            );
          })}
        </>
      )}
      <div className="flex justify-between items-end bg-white2 px-4 py-2.5 border rounded">
        <AutoHeightTextArea
          ref={inputRef}
          singleLine={false}
          placeholder={`告诉 ${PRODUCT_AI_TITLE} 助手写什么...`}
          boxClassName="flex-grow"
          fontClassName="text-t2 pdf-sidebar-input"
          onKeyDown={(event) => {
            if (event.nativeEvent.isComposing) return;

            if (isHotkey('enter')(event)) {
              void submitPrompt();
            }
          }}
          value={prompt}
          onChange={(event) => {
            const node = event.target as HTMLTextAreaElement | null;
            if (!node) return;
            setPrompt(node.value);
          }}
        />

        <Icon
          name={'IcCommentSend'}
          onClick={() => submitPrompt()}
          size="large"
          className={cx(
            'text-grey4 self-end cursor-pointer justify-end',
            prompt && 'text-active_color'
          )}
        />
      </div>
    </div>
  );
};

export const askAi = async (
  userId: string,
  params: {
    quote: {
      pageNumber: number;
      content: string;
      coords?: number[];
    }[];
    question: string;
    ossName: string;
    blockId: string;
    propertyId: string | undefined;
  },
  container: React.RefObject<HTMLDivElement>,
  retryId = ''
) => {
  let newAIContents = [...$appUiStateCache.$pdfAIContents];
  if (retryId) {
    newAIContents = $appUiStateCache.$pdfAIContents.filter((item) => item.uuid !== retryId);
  }

  const initId = v4();
  const chatData = {
    uuid: initId,
    ai: '',
    user: params.question,
    createdBy: userId,
    createdAt: Date.now(),
    quote: params.quote.length > 0 ? params.quote.map((item) => item.content).join('\n') : '',
    originQuote: params.quote,
    links: [],
  };

  setAppUiState({
    $pdfAIContents: newAIContents.concat({ ...chatData }),
    $pdfQuotes: [],
  });

  setTimeout(() => {
    const containerNode = container.current;
    if (!containerNode) return;
    containerNode.scrollTop = containerNode.scrollHeight;
  });

  const headers: HeadersInit = { 'Content-Type': 'application/json' };

  const token = getToken();
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const response = await fetch('/api/chat/ask', {
    method: 'POST',
    headers,
    body: JSON.stringify(params),
  });

  if (!response.ok) return;

  const res = response.body;
  if (!res) return;

  const reader = res.getReader();
  const decoder = new TextDecoder();

  const pageNumbers: { page: number; coords: number[] }[] = [];
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const { value, done } = await reader.read();
    if (done) {
      const index = $appUiStateCache.$pdfAIContents.findIndex(
        (item) => item.uuid === chatData.uuid
      );

      if (index > -1) {
        const newContents = [...$appUiStateCache.$pdfAIContents];
        newContents[index] = {
          ...chatData,
          links: pageNumbers.map(({ page, coords }) => ({
            type: 'quote',
            pageNumber: page,
            content: '',
            coords,
          })),
        };
        setAppUiState({ $pdfAIContents: newContents });
      }
      break;
    }

    const chunkValue = decoder.decode(value);
    if (chunkValue.startsWith('error:')) {
      break;
    }

    const data = parsePDFAiMessage(chunkValue);
    const error = data.find((item) => item.code === 500);
    if (error) {
      const newContents = [...$appUiStateCache.$pdfAIContents];
      const index = $appUiStateCache.$pdfAIContents.findIndex(
        (item) => item.uuid === chatData.uuid
      );
      newContents[index] = {
        ...chatData,
        error: error.msg as string,
      };
      setAppUiState({ $pdfAIContents: newContents });

      message.warning(error.msg ?? '');
      return;
    }

    if (!location.origin.includes('flowus.cn')) {
      // eslint-disable-next-line no-console
      console.log(data);
    }

    data.forEach((item) => {
      const { type, pageNumber, content, coords = [] } = item;
      if (type === 'quote' && pageNumber) pageNumbers.push({ page: Number(pageNumber), coords });
      if (type === 'uuid' && content) chatData.uuid = content;
      if (type === 'answer' && content) {
        chatData.ai += content;
      }
    });

    const index = $appUiStateCache.$pdfAIContents.findIndex(
      (item) => item.uuid === chatData.uuid || item.uuid === initId
    );

    if (index > -1) {
      const newContents = [...$appUiStateCache.$pdfAIContents];
      newContents[index] = { ...chatData };
      setAppUiState({ $pdfAIContents: newContents });
    }
  }
};
