import { cx } from '@flowus/common/cx';
import { Checkbox } from 'src/components/check-box';
import { FoldMenu } from 'src/editor/component/fold-menu';
import { useReadonly } from 'src/hooks/page';
import { useTransaction } from 'src/hooks/use-transaction';
import { multiUpdateBlock } from 'src/redux/managers/block/update';
import { useExpand } from 'src/redux/managers/ui/use-fold';
import { $searchParams } from 'src/utils';
import { usePickBlock } from 'src/utils/pick-block';
import type { BlockElement } from '../core/type';
import { BlockBefore } from '../uikit/block-before';
import { BlockChildren } from '../uikit/block-children';
import { Editable } from '../uikit/editable';
import { BlockDrop } from './dnd/block-drop';

export const TodoBlockElement: BlockElement = ({ id, root, children }) => {
  const [expand = true] = useExpand(id);
  const block = usePickBlock(id, ['data'], ['checked']);
  const transaction = useTransaction();
  const readonly = useReadonly(id);

  if (!block) return null;

  const { checked } = block.data;

  const isExpand = $searchParams.print || expand;

  return (
    <div className="relative">
      <FoldMenu id={id} />
      <BlockDrop id={id} horizontal={root} className="my-px relative">
        <div className="block-has-icon-container">
          <BlockBefore className="block-before-icon-mt">
            <Checkbox
              size="large"
              checked={checked}
              onClick={(event) => {
                if (readonly) return;
                event.stopPropagation();
                transaction(() => {
                  multiUpdateBlock({ data: { checked: !checked } }, id);
                });
              }}
            />
          </BlockBefore>
          <Editable
            uuid={id}
            className={cx('flex-1 w-0 mr-4', checked ? 'opacity-50' : 'no-underline', {
              'line-through': checked,
            })}
            placeholder="待办列表"
          />
        </div>
        {children && isExpand && <BlockChildren blockId={id}>{children}</BlockChildren>}
      </BlockDrop>
    </div>
  );
};
