import type { FC } from 'react';
import { useMemo } from 'react';
import { IconWidget } from 'src/common/components/emoji-widget';
import { Icon } from 'src/common/components/icon';
import { HOST_NAME } from 'src/common/const';
import { Option } from '../option';
import { domainErrorMsg, DomainStatus } from './helper';
import { SettingProvider } from '../common';
import { useModel } from 'src/common/create-model';

export const SpaceDomain: FC = () => {
  const {
    spaceSettingState: { domain = '', checkDomain, domainStatus },
  } = useModel(SettingProvider);

  const domainStatusView = useMemo(() => {
    switch (domainStatus) {
      case DomainStatus.CHECKING: {
        return (
          <div className="text-t4 flex items-center opacity-100 transition-all">
            <Icon
              className="text-grey4 ml-2.5 mr-1 animate-spin align-middle text-xl leading-none"
              name="IcNaviLoading"
              size="middle"
            />
            <span className="text-grey4 whitespace-nowrap">{'检测中...'}</span>
          </div>
        );
      }
      case DomainStatus.CAN_USE: {
        return (
          <div className="text-t4 text-active_color flex items-center opacity-100 transition-all">
            <IconWidget className="ml-2.5 mr-1" iconSize={14} emojiStr={'👌'} disableHover={true} />
            <span className="whitespace-nowrap">{'域名可用'}</span>
          </div>
        );
      }
      case DomainStatus.CAN_NOT_USE: {
        return (
          <div className="text-t4 text-red flex items-center opacity-100 transition-all">
            <IconWidget className="ml-2.5 mr-1" iconSize={14} emojiStr={'⚠️'} disableHover={true} />
            <span className="whitespace-nowrap">{domainErrorMsg[DomainStatus.CAN_NOT_USE]}</span>
          </div>
        );
      }
      case DomainStatus.LEN_NOT_RIGHT: {
        return (
          <div className="text-t4 text-red flex items-center opacity-100 transition-all">
            <IconWidget className="ml-2.5 mr-1" iconSize={14} emojiStr={'⚠️'} disableHover={true} />
            <span className="whitespace-nowrap">{domainErrorMsg[DomainStatus.LEN_NOT_RIGHT]}</span>
          </div>
        );
      }
      default:
        return null;
    }
  }, [domainStatus]);

  return (
    <>
      <Option
        title="空间域名"
        description={`页面分享链接支持自定义域名，且旧链接仍然生效。链接将显示为：${HOST_NAME}/${domain}`}
      />
      <div className="mt-2 flex items-center">
        <div className="bg-grey8 text-t2 border-grey6 relative flex w-80 items-center rounded-sm border">
          <input
            spellCheck={false}
            placeholder="请输入英文域名"
            className="text-t2 h-7.5 placeholder-grey4 w-full flex-grow cursor-text bg-transparent px-2 align-middle text-black outline-none"
            value={domain}
            maxLength={31}
            onChange={(e) => {
              const text = e.target.value.trim();
              if (/^[a-z0-9_-]*$/.test(text)) {
                checkDomain(e.target.value.trim());
              }
            }}
          />
        </div>

        {domainStatusView}
      </div>
    </>
  );
};
