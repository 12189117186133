import { fastEqual } from '@flowus/common/utils/tools';
import { createCache, createSetState } from '@flowus/common/zustand/utils';
import produce from 'immer';
import { assign } from 'lodash-es';
import { useCallback } from 'react';
import { map } from 'rxjs';
import { useObservable } from 'rxjs-hooks';
import type { PropertyManagePlace } from 'src/bitable/table-view/types';
import { useHasNewActivity } from 'src/hooks/activities/use-has-new-activity';
import type { MemberState } from 'src/redux/types';
import { $networkStatus } from 'src/services/network-status';
import { useIsInRight } from 'src/utils/right-utils';
import { $appUiState } from '../state';
import type { AppUiStateType } from '../type';
import { FolderViewType } from '../type';

export const $appUiStateCache = createCache($appUiState);
const $setAppUiState = createSetState($appUiState);

export const useAppUiState = <T>(selector: (state: AppUiStateType) => T) =>
  $appUiState(selector, fastEqual);

export const setAppUiState = (value: Partial<AppUiStateType>) => {
  $setAppUiState(produce((pre: AppUiStateType) => assign(pre, value)));
};

export const useSetIsEditingPage = () => {
  const isInRightPage = useIsInRight();

  return useCallback(
    (status: boolean) => {
      let isFocusRight = $appUiStateCache.$isFocusInRightPanel;
      if (isInRightPage && status && !isFocusRight) {
        isFocusRight = true;
      }
      setAppUiState({
        $isEditingPage: status,
        $isFocusInRightPanel: isFocusRight,
      });
    },
    [isInRightPage]
  );
};

export const useFirstLoadPage = () => {
  return useAppUiState((state) => state.$firstLoadPage);
};

export const useAiPDFSideBar = () => {
  return useAppUiState((state) => state.$showPDFSidebar);
};

export const useAiPDFHistory = () => {
  return $appUiState((state) => state.$pdfAIHistory);
};

export const usePDFHistorySideBar = () => {
  return $appUiState((state) => state.$pdfHistorySidebar);
};

export const useAiPDFQuotes = () => {
  return useAppUiState((state) => state.$pdfQuotes);
};

export const useAiPDFContents = () => {
  return useAppUiState((state) => state.$pdfAIContents);
};

export const useIsFocusInRightPanel = () => {
  return useAppUiState((state) => state.$isFocusInRightPanel);
};

export const useReadyUpdate = () => {
  return useAppUiState((state) => state.$readyUpdate);
};

export const useBodyWidth = () => {
  return useAppUiState((state) => state.$bodyWidth);
};

export const useBodyHeight = () => {
  return useAppUiState((state) => state.$bodyHeight);
};

export const useThemeState = () => {
  return useAppUiState((state) => state.$theme);
};

export const useFatalError = () => {
  return useAppUiState((state) => state.$fatalError);
};

export const useServiceCenterRedBadge = () => {
  const hasNewActivity = useHasNewActivity();
  const $serviceCenterRedBadge = useAppUiState((state) => state.$serviceCenterRedBadge);

  return $serviceCenterRedBadge || hasNewActivity;
};

export const usePressedSpace = () => {
  return useAppUiState((state) => state.$pressedSpace);
};

export const useIsEditingPage = () => {
  return useAppUiState((state) => state.$isEditingPage);
};

export const useToday = () => {
  return useAppUiState((state) => state.$today);
};

export const usePageRootLoaded = () => {
  return useAppUiState((state) => state.$pageRootLoaded);
};

export const useCreateBlockMenuListId = () => {
  return useAppUiState((state) => state.$createBlockMenuListId);
};

export const useGetOffline = () => {
  return useObservable(
    () => $networkStatus.onStatusChange.pipe(map(() => $networkStatus.offline)),
    $networkStatus.offline
  );
};

export const useIsMobileSize = () => {
  return useAppUiState((state) => state.$isMobileSize);
};

export const useIsCollapse = () => {
  return useAppUiState((state) => state.$isCollapsed);
};

export const useColumnResizing = () => {
  return useAppUiState((state) => state.$columnResizing);
};

export const useMenuListKeywords = () => {
  return useAppUiState((state) => state.$menuListKeywords);
};

export const useTextareaPlaceHolder = (id?: string, opt?: { defaultPlaceholder?: string }) => {
  const [alwaysShowPlaceHolder, placeHolder] = useAppUiState((state) => {
    if (state.$textareaPlaceHolder && state.$textareaPlaceHolder?.blockId === id) {
      return [state.$textareaPlaceHolder.alwaysShow, state.$textareaPlaceHolder.placeHolder];
    }
    // 默认行为
    return [false, opt?.defaultPlaceholder ?? '请输入文字'];
  });

  return { alwaysShowPlaceHolder, placeHolder };
};

export const useSlashSelection = () => {
  return useAppUiState((state) => state.$slashSelection);
};

export const useIgnoreSlash = () => {
  return useAppUiState((state) => state.$ignoreSlash);
};

export const useFocusedInSyncBlockId = () => {
  return useAppUiState((state) => state.$focusedInSyncBlockId);
};

export const useIsSelecting = () => {
  return useAppUiState((state) => state.$isSelecting);
};

export const useExpandPageRecord = (id: string) => {
  return useAppUiState((state) => !!state.$expandPageRecord[id]);
};

// set
export const setExpandPageRecord = (ids: string[], status: boolean) => {
  const expandPageRecord = { ...$appUiStateCache.$expandPageRecord };

  ids.forEach((item) => {
    expandPageRecord[item] = status;
  });

  setAppUiState({ $expandPageRecord: expandPageRecord });
};

export const useExpandFoldRecord = (id: string) => {
  return useAppUiState((state) => state.$expandFoldRecord[id]);
};

// set
export const setExpandFoldRecord = (ids: string[], status: boolean) => {
  const expandFoldRecord = { ...$appUiStateCache.$expandFoldRecord };

  ids.forEach((item) => {
    expandFoldRecord[item] = status;
  });

  setAppUiState({ $expandFoldRecord: expandFoldRecord });
};

export const useAlwaysShowSync = () => {
  const offline = useGetOffline();

  return useAppUiState((state) => {
    return !!(
      state.$isEditingPage &&
      !(state.$syncUpFault.backup || state.$syncUpFault.noPermission || (offline && state.$dirty))
    );
  });
};

export const useFileIllegal = () => {
  return useAppUiState((state) => state.$fileIllegal);
};

export const useDirty = () => {
  return useAppUiState((state) => state.$dirty);
};

export const useSyncUpFault = () => {
  return useAppUiState((state) => state.$syncUpFault);
};

export const useMembersStates = () => {
  const $membersStates = useAppUiState((state) => state.$membersStates);
  return $membersStates ?? {};
};

// set
export const addMemberState = (member: MemberState) => {
  setAppUiState({
    $membersStates: { ...$appUiStateCache.$membersStates, [member.userId]: member },
  });
};

export const useAiMembers = () => {
  return useAppUiState((state) => state.$aiMembers);
};

export const useNewCreatedRecord = () => {
  return useAppUiState((state) => state.$newCreatedRecord);
};

export const useCollectionSearchById = (id: string) => {
  return useAppUiState((state) => state.$collectionSearch[id]);
};
export const useIsEditBlock = (id: string) => {
  return useAppUiState((state) => state.$editingBlockId === id);
};

export const useBlockFolderListViewType = (uuid: string): FolderViewType => {
  return useAppUiState((state) => {
    const folderListViewType = state.$folderListViewTypeList[uuid];
    return folderListViewType ?? FolderViewType.TABLE;
  });
};

export const useSharedPages = () => {
  return useAppUiState((state) => state.$sharedPages);
};

export const useNotificationsMagic = () => {
  return useAppUiState((state) => state.$notificationsMagic);
};

// set
export const invalidateNotifications = () => {
  setAppUiState({ $notificationsMagic: {} });
};

export const useGuestsList = () => {
  return useAppUiState((state) => state.$guestsList);
};

export const useShowToolbarInfoById = (id: string) => {
  return useAppUiState((state) => {
    return state.$toolbarInfo?.syncId === id && state.$toolbarInfo.showing;
  });
};

export const useTableShowHiddenGroupById = (id: string) => {
  return useAppUiState((state) => {
    return state.$tableShowHiddenGroup?.[id] === true;
  });
};

export const useTableGroupIsUnFolded = (id: string, tableGroupValue: string) => {
  return useAppUiState((state) => {
    return state.$tableGroupFoldStatus?.[id]?.[tableGroupValue] !== false;
  });
};

export const useIsNewProperty = (propertyId: string, propertyManagePlace: PropertyManagePlace) => {
  return useAppUiState((state) => {
    return (
      state.$newTableProperty?.propertyId === propertyId &&
      state.$newTableProperty.from === propertyManagePlace
    );
  });
};
/** 分享页点击右上角的评论按钮之后才能分享，这里才会返回true */
export const useAllowCommentInSharePage = () => {
  return useAppUiState((state) => !!state.$allowCommentInSharePage);
};

export const useIsNewPropertyByIsSameGroup = (
  propertyId: string,
  propertyManagePlace: PropertyManagePlace,
  groupUniqueId: string
) => {
  return useAppUiState((state) => {
    const { $newTableProperty } = state;
    let isSameGroup = true;
    if ($newTableProperty?.groupInfo) {
      isSameGroup = $newTableProperty.groupInfo === groupUniqueId;
    }
    return (
      isSameGroup &&
      $newTableProperty?.propertyId === propertyId &&
      $newTableProperty.from === propertyManagePlace
    );
  });
};

export const useIsOpenImagePreview = () => {
  return useAppUiState((state) => state.$isOpenImagePreview);
};

export const useIsLowConfig = () => {
  return useAppUiState((state) => state.$lowConfig);
};

export const useIsCtrlKeyDown = () => {
  return useAppUiState((state) => state.$isCtrlKeyDown);
};
export const useSubscribePages = () => {
  return useAppUiState((state) => state.$subscribePages);
};

export const useExChangeCodeRedBadge = () => {
  return useAppUiState((state) => state.$exChangeCodeRedBadge);
};
