import type { FC } from 'react';
import { memo, useEffect, useRef, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { useBitable } from 'src/bitable/context';
import { deepEqual } from '@flowus/common/utils/tools';
import { MembersCoordinate } from 'src/editor/editor/uikit/members-coordinate';
import { Selector } from 'src/editor/editor/uikit/selector';
import { useBiTableGroupsAndProvideRecordWalker } from 'src/hooks/collection-view/table-groups';
import type { TableGroupData } from 'src/hooks/collection-view/table-groups/select-collection-groups';
import { useCardImageDir, useCardSize } from 'src/hooks/collection-view/use-collection-view';
import { useHoveringEmpty } from 'src/hooks/page/use-dnd/hooks';
import { useTableGroupIsUnFolded } from 'src/services/app';
import { usePageScrollRef } from 'src/views/main/page-doc/context';
import { AddGroup } from '../add-group';
import { AddRecord } from '../add-record';
import { GalleryCard } from '../board-view/card';
import { useCardPageContent } from '../board-view/use-card-page-content';
import { getCardSize, isEnableVirtuoso, OVERSCAN } from '../const';
import { GroupHeader } from '../group-header';
import { BitableLoadMoreContext, useBitableLoadMoreContext } from '../hooks';
import { useUpdateGroups } from '../hooks/ues-check-group';
import { BitableLoadMore } from '../load-more';
import { useInitGroupFoldStatus } from '../table-view';
import { ToggleHiddenGroup } from '../toggle-hidden-group';
import type { CollectionViewProps } from 'src/views/main/page-bitable/types';

export const GalleryView: FC<CollectionViewProps> = (props) => {
  const { viewId } = useBitable();
  const biTableGroups = useBiTableGroupsAndProvideRecordWalker(viewId);

  return (
    <BitableLoadMoreContext recordIds={biTableGroups?.sortedRecordIds || []}>
      <GalleryBody {...props} />
    </BitableLoadMoreContext>
  );
};

const GalleryBody: FC<CollectionViewProps> = () => {
  const { viewId, readonly, embed } = useBitable();
  const biTableGroups = useBiTableGroupsAndProvideRecordWalker(viewId);
  const cardSize = useCardSize(viewId);
  const imageDir = useCardImageDir(viewId);
  const hoveringEmpty = useHoveringEmpty(viewId);
  const { recordIds } = useBitableLoadMoreContext();
  useInitGroupFoldStatus();
  useUpdateGroups(biTableGroups);

  const container = useRef<HTMLDivElement | null>(null);
  const [galleryContainer, setContainer] = useState(container.current);
  useCardPageContent(galleryContainer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!galleryContainer) {
      setContainer(container.current);
    }
  });

  const pageRef = usePageScrollRef();
  const [scrollContainer, setScrollContainer] = useState(pageRef?.current);
  useEffect(() => {
    setScrollContainer(pageRef?.current);
  }, [scrollContainer, pageRef]);

  if (!scrollContainer) return null;

  if (!biTableGroups) return null;

  const { withoutValidGroup, sortedRecordIds, visibleGroups, hiddenGroups } = biTableGroups;

  return (
    <>
      {withoutValidGroup ? (
        <>
          <div
            ref={container}
            data-is-empty={sortedRecordIds.length === 0 ? true : null}
            data-view-id={viewId}
            className="p-1 block-list grid gap-4 relative border-t border-grey6 pt-4 group"
            style={{
              gridTemplateColumns: `repeat(auto-fill, minmax(${getCardSize(
                cardSize,
                imageDir
              )}px, 1fr))`,
            }}
          >
            {recordIds.map((uuid) => (
              <GalleryCard recordId={uuid} key={uuid} />
            ))}

            {hoveringEmpty && (
              <div className="absolute top-0 left-0 w-full h-1 bg-black/30 opacity-0 group-hover:opacity-100"></div>
            )}

            {!readonly && !embed && <MembersCoordinate />}

            <AddRecord />
          </div>

          <BitableLoadMore />
        </>
      ) : (
        <>
          <div className="block-list" ref={container}>
            {isEnableVirtuoso(visibleGroups.length) ? (
              <Virtuoso
                customScrollParent={scrollContainer}
                overscan={OVERSCAN}
                increaseViewportBy={OVERSCAN}
                skipAnimationFrameInResizeObserver
                computeItemKey={(index) => index}
                totalCount={visibleGroups.length}
                itemContent={(index) => {
                  const tableGroup = visibleGroups[index];
                  if (!tableGroup) return null;
                  return (
                    <BitableLoadMoreContext recordIds={tableGroup.recordIds}>
                      <GalleryGroup key={tableGroup.value} tableGroup={tableGroup} />;
                    </BitableLoadMoreContext>
                  );
                }}
              />
            ) : (
              <>
                {visibleGroups.map((tableGroup) => (
                  <BitableLoadMoreContext recordIds={tableGroup.recordIds} key={tableGroup.value}>
                    <GalleryGroup tableGroup={tableGroup} />
                  </BitableLoadMoreContext>
                ))}
              </>
            )}

            <ToggleHiddenGroup
              hiddenGroupsLength={hiddenGroups.length}
              hiddenGroups={
                hiddenGroups.length > 100 ? (
                  <Virtuoso
                    customScrollParent={scrollContainer}
                    overscan={OVERSCAN}
                    increaseViewportBy={OVERSCAN}
                    skipAnimationFrameInResizeObserver
                    computeItemKey={(index) => index}
                    totalCount={hiddenGroups.length}
                    itemContent={(index) => {
                      const tableGroup = hiddenGroups[index];
                      if (!tableGroup) return null;
                      return (
                        <BitableLoadMoreContext recordIds={tableGroup.recordIds}>
                          <GalleryGroup key={tableGroup.value} tableGroup={tableGroup} />;
                        </BitableLoadMoreContext>
                      );
                    }}
                  />
                ) : (
                  <>
                    {hiddenGroups.map((tableGroup) => (
                      <BitableLoadMoreContext recordIds={tableGroup.recordIds}>
                        <GalleryGroup key={tableGroup.value} tableGroup={tableGroup} />
                      </BitableLoadMoreContext>
                    ))}
                  </>
                )
              }
            />

            {!readonly && !embed && <MembersCoordinate />}
          </div>

          <AddGroup />
        </>
      )}

      {!readonly && !embed && <Selector type="collection" />}
    </>
  );
};

interface GalleryGroupProps {
  tableGroup: TableGroupData;
}

const GalleryGroup: FC<GalleryGroupProps> = memo((props) => {
  const { tableGroup } = props;
  const { viewId } = useBitable();
  const cardSize = useCardSize(viewId);
  const imageDir = useCardImageDir(viewId);
  const { value, groupProperty } = tableGroup;
  const hoveringEmpty = useHoveringEmpty(viewId);
  const isUnFolded = useTableGroupIsUnFolded(viewId, value);
  const { recordIds } = useBitableLoadMoreContext();

  return (
    <div
      data-is-empty={recordIds.length === 0 ? true : null}
      data-view-id={viewId}
      data-group-value={value}
      data-group-property={groupProperty}
      className={'relative group pb-5'}
    >
      <GroupHeader tableGroup={tableGroup} />

      {hoveringEmpty && (
        <div className="absolute top-10 left-0 w-full h-1 bg-black/30 opacity-0 group-hover:opacity-100"></div>
      )}

      {isUnFolded && (
        <>
          <div
            className="grid gap-4 block-list p-1"
            style={{
              gridTemplateColumns: `repeat(auto-fill, minmax(${getCardSize(
                cardSize,
                imageDir
              )}px, 1fr))`,
            }}
          >
            {recordIds.map((uuid) => (
              <GalleryCard recordId={uuid} key={uuid} groupValue={value} />
            ))}
            <AddRecord
              where={{ after: recordIds[recordIds.length - 1] }}
              groupName={tableGroup.value}
            />
          </div>

          <BitableLoadMore />
        </>
      )}
    </div>
  );
}, deepEqual);
