import { isInOtherBrowsers, Uuid } from '@flowus/common';
import { $downloadManager } from 'src/services/download-manager';
import { isFlowUsApp } from 'src/utils/electron-util';

// const changeDataType = (url: string) => {
//   return fetch(url, { mode: 'no-cors' })
//     .then((response) => {
//       return response.blob();
//     })
//     .then((blob) => {
//       return URL.createObjectURL(blob);
//     });
// };

export const downloadUrlFile = async (url: string, fileName?: string) => {
  // if (fileName.endsWith('.zip')) {
  //   url = await changeDataType(url);
  // }
  if (isFlowUsApp.check) {
    void $downloadManager.download({
      id: Uuid.v4(),
      url,
      fileName: fileName?.replace('/', '-'),
    });

    return;
  }
  if (isInOtherBrowsers()) {
    downloadFileNoBlank(url, fileName);
    return;
  }
  const elem = window.document.createElement('a');
  elem.target = '_blank';
  elem.href = url;
  if (fileName) {
    elem.download = fileName;
  }
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
};

export const guessFileName = (url: string) => {
  const queryIndex = url.indexOf('?');
  if (queryIndex > 0) {
    url = url.substring(0, queryIndex);
  }
  if (!url.endsWith('/')) {
    const index = url.lastIndexOf('/') + 1;
    if (index > 0) {
      return url.substring(index);
    }
  }
  return '未知文件名';
};

/** 真·不需要另外新开窗户就能下载 */
export const downloadFileNoBlank = (url: string, fileName?: string) => {
  if (isFlowUsApp.check) {
    void $downloadManager.download({
      id: Uuid.v4(),
      url,
      fileName: fileName?.replace('/', '-'),
    });
    return;
  }
  const elem = document.createElement('a');
  if (fileName) {
    elem.download = fileName;
  }
  elem.href = url;
  elem.click();
};
