import { lazy, Suspense } from 'react';
import { LoadingContainer } from 'src/common/components/loading-container';
import type { VideoJSProps } from './_video';

const LazyVideoJS = lazy(() => import('./_video').then(({ VideoJS }) => ({ default: VideoJS })));
export const VideoJS = (props: VideoJSProps) => {
  return (
    <Suspense fallback={<LoadingContainer style={props.style} />}>
      <LazyVideoJS {...props} />
    </Suspense>
  );
};
