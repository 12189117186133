import { getIsDarkMode } from '../../utils/get-dark';
import {
  correctColorKey,
  darkBoardGroupBackgroundColor,
  darkBoardGroupNumberTextColor,
  darkBoardGroupTextColor,
  darkCardBackgroundColor,
  darkDataBaseProgressBar,
  darkDataBaseProgressBarBg,
  darkMarkBlockBackgroundColor,
  darkMarkBlockBorderColor,
  darkRaw,
  darkTagBackgroundColor,
  darkTagTextColor,
  darkTextBackground,
  darkTextColor,
  lightBoardGroupBackgroundColor,
  lightBoardGroupNumberTextColor,
  lightBoardGroupTextColor,
  lightCardBackgroundColor,
  lightDataBaseProgressBar,
  lightDataBaseProgressBarBg,
  lightMarkBlockBackgroundColor,
  lightMarkBlockBorderColor,
  lightRaw,
  lightTagBackgroundColor,
  lightTagTextColor,
  lightTextBackground,
  lightTextColor,
} from './data';

type OnlyModeType = 'light' | 'dark';

interface ColorOption {
  onlyMode?: OnlyModeType;
}

const getColor = (
  lightColors: Record<string, string>,
  darkColors: Record<string, string>,
  key?: string,
  option?: ColorOption
) => {
  if (!key) return key;

  if (key.startsWith('#')) return;

  if (['pink_purple', 'blue_cobalt'].includes(key)) {
    key = correctColorKey[key] ?? key;
  }

  const isDark = (() => {
    if (option?.onlyMode) {
      return option.onlyMode === 'dark';
    }
    return getIsDarkMode();
  })();

  const color = isDark ? darkColors[key] : lightColors[key];
  if (!color) return key;
  if (color?.includes(',')) {
    const [left, right] = color.split(',');
    return `linear-gradient(to right, ${left} 0%, ${right} 100%)`;
  }
  return color;
};

const fixBgColorKey = (key?: string) => {
  if (key === 'green_blue') {
    key = 'green';
  }
  return key;
};

/** block背景颜色 */
export const getBlockBackgroundColor = (colorKey?: string) => {
  return getColor(lightTextBackground, darkTextBackground, colorKey);
};

/** block文字颜色 */
export const getBlockTextColor = (colorKey?: string) => {
  return getColor(lightTextColor, darkTextColor, colorKey);
};

/** tag 文字颜色 */
export const getTagTextColor = (colorKey?: string, option?: ColorOption) => {
  return getColor(lightTagTextColor, darkTagTextColor, colorKey, option);
};

/** tag背景颜色 */
export const getTagBackgroundColor = (colorKey?: string) => {
  return getColor(lightTagBackgroundColor, darkTagBackgroundColor, colorKey);
};

/** 看板的文字颜色 */
export const getBoardGroupTextColor = (colorKey?: string) => {
  return getColor(lightBoardGroupTextColor, darkBoardGroupTextColor, colorKey);
};

/** 看板的图标文字颜色 */
export const getBoardGroupNumberTextColor = (colorKey?: string) => {
  return getColor(lightBoardGroupNumberTextColor, darkBoardGroupNumberTextColor, colorKey);
};

/** 看板泳道背景颜色 */
export const getBoardGroupBackgroundColor = (colorKey?: string) => {
  return getColor(lightBoardGroupBackgroundColor, darkBoardGroupBackgroundColor, colorKey);
};

/** 着重文字背景色 */
export const getMarkBlockBackgroundColor = (colorKey?: string) => {
  colorKey = fixBgColorKey(colorKey);
  return getColor(lightMarkBlockBackgroundColor, darkMarkBlockBackgroundColor, colorKey);
};

/** 着重文字边框色 */
export const getMarkBlockBorderColor = (colorKey?: string) => {
  colorKey = fixBgColorKey(colorKey);
  return getColor(lightMarkBlockBorderColor, darkMarkBlockBorderColor, colorKey);
};

/** 日历背景颜色 */
export const getCardBackgroundColor = (colorKey?: string) => {
  return getColor(lightCardBackgroundColor, darkCardBackgroundColor, colorKey);
};

/** 多维表进度条颜色 */
export const getDataBaseProgressBarColor = (colorKey?: string) => {
  return getColor(lightDataBaseProgressBar, darkDataBaseProgressBar, colorKey);
};

/** 多维表进度条背景颜色 */
export const getDataBaseProgressBarBgColor = (colorKey?: string) => {
  return getColor(lightDataBaseProgressBarBg, darkDataBaseProgressBarBg, colorKey);
};

/** Icon库颜色 */
export const getIconsColor = (colorKey?: string) => {
  return getColor(lightRaw, darkRaw, colorKey);
};

/**
 * 外边框颜色是基础色的20%
 */
export const getBorderColor = (textColor?: string, backgroundColor?: string) => {
  // 如果都没有值，默认会有个外边框
  if (!textColor && !backgroundColor) getMarkBlockBorderColor(textColor || 'grey');
  if (backgroundColor) return;

  return getMarkBlockBorderColor(textColor || 'grey');
};
