import type { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { cx } from '@flowus/common/cx';
import { CollectionViewType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { Fragment, useCallback } from 'react';
import { useBitable } from 'src/bitable/context';
import { Divider } from 'src/common/components/divider';
import { Icon } from 'src/common/components/icon';
import { SortableList } from 'src/common/components/sortable-list';
import { getViewFormat } from 'src/hooks/block/get-view-format';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import { realGroupName, useBiTableGroups } from 'src/hooks/collection-view/table-groups';
import { useManualSortGroup } from 'src/hooks/collection-view/use-collection-view';
import { ViewIconMap, ViewNameMap } from '../const';
import type { SettingProps } from '../type';
import { GroupItem } from './item';
import { GroupSetting } from './setting';

export const GroupList: FC<SettingProps> = ({ isSubGroup, isFromViewSetting }) => {
  const { viewId, viewType, isLocked } = useBitable();
  const handleGroupSort = useHandleGroupSort();

  const tableGroups = useBiTableGroups(viewId);
  const manualSortGroup = useManualSortGroup(isSubGroup);

  if (!tableGroups || !viewType) return null;

  const toggleAll = (visible: boolean) => {
    if (isLocked) return;
    const { groups = [], subGroups = [] } = getViewFormat(viewId) ?? {};

    const isBoard = viewType === CollectionViewType.BOARD;
    const isBoardColumn = isBoard && !isSubGroup;
    const groupsName = isBoardColumn ? 'boardGroups' : 'collectionGroups';
    const currentGroups = isBoard && isSubGroup ? subGroups : groups;
    updateViewFormat(viewId, {
      [groupsName]: currentGroups.map((item) => {
        return { ...item, visible, emptyHidden: !visible };
      }),
    });
  };

  const {
    visibleGroups,
    hiddenGroups,
    visibleSubGroups = [],
    hiddenSubGroups = [],
    withoutValidGroup,
    hasValidSubGroup,
  } = tableGroups;

  const visibleGroupNames: string[] = (isSubGroup ? visibleSubGroups : visibleGroups).map(
    (group) => group.value
  );
  const hiddenGroupNames: string[] = (isSubGroup ? hiddenSubGroups : hiddenGroups).map(
    (group) => group.value
  );
  const isShowGroupList = (isSubGroup && hasValidSubGroup) || (!isSubGroup && !withoutValidGroup);

  return (
    <div className={cx('py-[5px]', isFromViewSetting ? 'w-full' : 'next-modal-scroll w-[400px]')}>
      <div className="text-t2 flex h-10 items-center px-4">
        <Icon className="mr-1" name={ViewIconMap[viewType]} size="middle" />
        <span className="mr-2 font-medium">{ViewNameMap[viewType]}</span>
        <span className="max-w-[140px]">{isSubGroup ? '子分组设置' : '分组设置'}</span>
      </div>

      <GroupSetting isSubGroup={isSubGroup} />

      {isShowGroupList && (
        <>
          <Divider className="mx-4 my-1" />
          {[visibleGroupNames, hiddenGroupNames].map((groupNames, index) => {
            if (groupNames.length === 0) return null;
            const isVisibleGroups = index === 0;

            return (
              <Fragment key={index}>
                <div className="flex h-10 items-center justify-between px-4">
                  <span className="text-t2 text-grey3">
                    {isVisibleGroups ? '可见分组' : '隐藏分组'}
                  </span>
                  <button
                    className={cx(
                      'text-t2 text-active_color',
                      isLocked && 'cursor-not-allowed opacity-20'
                    )}
                    onClick={() => toggleAll(!isVisibleGroups)}
                  >
                    {isVisibleGroups ? '全部隐藏' : '全部显示'}
                  </button>
                </div>

                <SortableList
                  virtual={groupNames.length > 100} // 拖动会有 bug，控制总数大于 100 再虚拟列表
                  disabled={isLocked || !manualSortGroup}
                  items={groupNames.map((groupName) => ({ id: groupName }))}
                  onChange={(_, event) => handleGroupSort(viewId, event, isSubGroup)}
                  renderItemContent={({ item }) => {
                    return (
                      <GroupItem
                        groupName={item.id}
                        visible={isVisibleGroups}
                        isSubGroup={isSubGroup}
                        showDragButton={manualSortGroup}
                      />
                    );
                  }}
                />
              </Fragment>
            );
          })}
        </>
      )}
    </div>
  );
};

export const useHandleGroupSort = () => {
  const handleGroupSort = (viewId: string, { active, over }: DragEndEvent, isSubGroup = false) => {
    if (!over) return;
    if (active.id === over.id) return;

    let oldIndex = 0;
    let newIndex = 0;
    const { groups = [], subGroups = [], view } = getViewFormat(viewId) ?? {};

    const currentGroups = isSubGroup ? subGroups : groups;
    const activeValue = realGroupName(active.id.toString());
    const overValue = realGroupName(over.id.toString());
    currentGroups.forEach((group, index) => {
      if (activeValue === group.value) {
        oldIndex = index;
      }

      if (overValue === group.value) {
        newIndex = index;
      }
    });

    const isBoardColumn = view?.type === CollectionViewType.BOARD && !isSubGroup;
    updateViewFormat(viewId, {
      [isBoardColumn ? 'boardGroups' : 'collectionGroups']: arrayMove(
        currentGroups,
        oldIndex,
        newIndex
      ),
    });
  };

  return useCallback(handleGroupSort, []);
};
