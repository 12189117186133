import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { LoadingContainer } from 'src/common/components/loading-container';
import { useOpenModal } from 'src/common/components/next-modal';
import { useSize } from 'src/common/utils/use-size';
import type { TemplateCategory } from 'src/hooks/template/types';
import { useFetchTemplates } from 'src/hooks/template/use-fetch-templates';
import { Modals } from 'src/modals';
import { bizTracker } from 'src/utils/biz-tracker';
import { TemplateAside } from './template-aside';
import { TemplateContext } from './template-context';
import { RightPanel } from './template-panel';
import { TemplatePreview } from './template-preview';
import type { From } from './types';

interface Props {
  targetId?: string;
  from: From;
  keyword?: string;
  onCategoryChange?: (category: string) => void;
}
const GAP = 354 + 30; // item宽+padding
const MIN_WIDTH = 1040;
// TODO:接口数据做个缓存
/** 模板中心 */
export const TemplateCenter: FC<Props> = (props) => {
  const [officialTemplates, setOfficialTemplates] = useState<TemplateCategory[]>([]);
  const fetchTemplates = useFetchTemplates();
  const openModal = useOpenModal();
  const { width: screenSize } = useSize(document.body, 'width');

  const [currentCategory, setCurrentCategory] = useState('');

  useEffect(() => {
    if (__BUILD_IN__) {
      setCurrentCategory('myTemplate');
      return;
    }
    void fetchTemplates().then((res) => {
      setOfficialTemplates(res ?? []);

      const firstGroup = res?.[0];
      if (!firstGroup) return;
      setCurrentCategory(`${firstGroup.name}/${firstGroup.subGroups?.[0]?.name}`);
    });
  }, [fetchTemplates]);

  useEffect(() => {
    props.onCategoryChange?.(currentCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategory]);

  useEffect(() => {
    bizTracker.event('template_page', {
      is_from: props.from === 'quickSetting' ? 'side_bar' : 'new_page',
    });
  }, [props.from]);

  const openTemplatePreview = (uuid: string) => {
    openModal.modal({
      modalId: Modals.TEMPLATE_PREVIEW,
      content: () => (
        <TemplatePreview
          uuid={uuid}
          targetId={props.targetId}
          currentCategory={currentCategory}
          officialTemplates={officialTemplates}
          from={props.from}
        />
      ),
    });
  };

  let width = MIN_WIDTH;
  if (screenSize) {
    while (width < screenSize * 0.9) {
      // 不能超过屏幕宽度的90%
      width += GAP;
    }
    width = width - GAP;
  }

  if (!currentCategory) return null;

  return (
    <TemplateContext.Provider value={props.from}>
      <div className="overflow-auto w-full h-[calc(100%-50px)] flex flex-col">
        {!officialTemplates && <LoadingContainer />}

        {/* <TemplateHeader keyword={props.keyword} currentCategory={currentCategory} /> */}

        <div className="flex overflow-hidden flex-1">
          {!__BUILD_IN__ && (
            <TemplateAside
              officialTemplates={officialTemplates}
              setCurrentCategory={setCurrentCategory}
              currentCategory={currentCategory}
            />
          )}

          <RightPanel
            targetId={props.targetId}
            officialTemplates={officialTemplates}
            currentCategory={currentCategory}
            openTemplatePreview={openTemplatePreview}
          />
        </div>
      </div>
    </TemplateContext.Provider>
  );
};
