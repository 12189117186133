import { cx } from '@flowus/common/cx';
import type { IElementComponent, IElementMeta } from '@next-space/fe-inlined';
import { newContent, newText, normalizeFormat, registerElementMeta } from '@next-space/fe-inlined';
import { IconWidget } from 'src/common/components/emoji-widget';
import { IS_WINDOWS } from 'src/common/utils/environment';
import { INLINE_EMOJI_TAG } from './const';
import type { InlinePlugin } from './inline-plugin';
import { useComputeTextStyle } from './utils';

const INLINE_EMOJI_META: IElementMeta = {
  tag: INLINE_EMOJI_TAG,
  hasContent: false,
  hasBoundary: false,
  legalize: (element) => {
    const text = element.props.text as string;
    return newContent([newText(`${text}`)]);
  },
  setFormat: (element, format) => {
    element.props.textFormat = normalizeFormat(format, element.props.textFormat as any);
  },
  toString: (element) => {
    return element.props.text as string;
  },
};
registerElementMeta(INLINE_EMOJI_META);

const InlineEmoji: IElementComponent = ({ htmlDataProps, textFormat: textFormat0, text }) => {
  const textFormat = normalizeFormat(textFormat0 as any);
  const textStyle = useComputeTextStyle(textFormat);
  return (
    // NOTE 不要随意加样式相关，会有bug，比如文字+emoji 下划线
    <span {...htmlDataProps} style={textStyle}>
      <IconWidget
        contentEditable={false}
        emojiStr={text as string}
        disableHover={true}
        inline={true}
        className={cx('inline cursor-default mp-px', {
          // 'align-middle -translate-y-0.5': !IS_APPLE,//为什么要加这行，commit信息不清楚
        })}
        style={{
          top: IS_WINDOWS ? '-0.1em' : undefined,
        }}
      />
    </span>
  );
};

export const EmojiInlinePlugin: InlinePlugin = {
  elementMeta: INLINE_EMOJI_META,
  initialize(api) {
    api.setElementComponent(this.elementMeta.tag, InlineEmoji);
  },
};
