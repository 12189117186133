export { SearchParams } from '@flowus/login/const';
export { $searchParams } from '@flowus/common/flowus-utils/search-params';
export { Regex } from '@flowus/common/url';

export enum ViewPath {
  login = '/login/',
  create = '/create/',
  preview = '/preview/',
  share = '/share/',
  form = '/form/',
  main = '/',
  notFoundPage = '/404',
  product = '/product',
  callback = '/callback',
  subscribe = '/subscribe/share/',
}

export const toPercent = (num: number, total: number) =>
  Math.max(Math.round((num / total) * 10000) / 100, 0);
