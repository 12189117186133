import { Button } from '@flowus/common/components';
import { cx } from '@flowus/common/cx';
import { useFinalValue } from '@flowus/common/hooks/react-utils';
import { PermissionRole } from '@next-space/fe-api-idl';
import { createMemoryHistory } from 'history';
import { useAtomValue } from 'jotai';
import type { FC, ReactNode } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { batch } from 'react-redux';
import { Router } from 'react-router-dom';
import { Icon } from 'src/common/components/icon';
import { LoadingContainer } from 'src/common/components/loading-container';
import { useCloseModal, useOpenModal } from 'src/common/components/next-modal';
import { UUID_NAMESPACE } from 'src/common/const';
import { LocalStorageKey } from 'src/const/local-storage-state-key';
import { EditableNamespace } from 'src/editor/editor/uikit/editable-context';
import { segmentsToText } from 'src/editor/utils/editor';
import { ActivityIds } from 'src/hooks/activities/activity-ids';
import { ActivitiesListType } from 'src/hooks/activities/use-activity';
import { useUpdateTask } from 'src/hooks/activities/use-update-task';
import { useBlock } from 'src/hooks/block/use-block';
import { useFetchPage } from 'src/hooks/page';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useCurrentSpace } from 'src/hooks/space';
import { useIsCustomTemplate } from 'src/hooks/space/use-is-custom-template';
import type { Template, TemplateCategory } from 'src/hooks/template/types';
import { useHandleCopyTemplate } from 'src/hooks/template/use-copy-template';
import { useCustomTemplatesCategory } from 'src/hooks/template/use-custom-templates-category';
import { useTransaction } from 'src/hooks/use-transaction';
import { useCurrentUser } from 'src/hooks/user';
import { Modals } from 'src/modals';
import { archiveBlock } from 'src/redux/managers/block/archive';
import { useCloseAllModal } from 'src/redux/managers/ui';
import { SearchParams } from 'src/utils';
import { bizTracker } from 'src/utils/biz-tracker';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { getLocationOrigin } from 'src/utils/location-utils';
import { fixLocation, parseRightPageId } from 'src/utils/right-utils';
import { Breadcrumb } from '../header/breadcrumb';
import { useOpenButtonTips } from '../header/helper';
import { Page } from '../page';
import { PageScene, PageSceneContext } from '../scene-context';
import './index.css';
import { MoreMenu } from './more-menu';
import { searchResultState } from './template-context';
import type { From } from './types';

interface Props {
  uuid: string;
  from: From;
  officialTemplates: TemplateCategory[];
  currentCategory: string;
  targetId?: string;
}
/** 是否可以提到下一分类，目前自定义模板分类和推荐分类里预览不支持跳到下一分类 */

/** 前进后退按钮逻辑复杂,需配合需求看:  https://www.notion.so/flowus/d67860eb9b574337b4d0ba6d87922dea */
export const TemplatePreview: FC<Props> = (props) => {
  const { uuid, officialTemplates, currentCategory, from } = props;

  const isCustomTemplate = useIsCustomTemplate(uuid);
  const customTemplates = useCustomTemplatesCategory();
  const updateTask = useUpdateTask();
  const handleCopyTemplate = useHandleCopyTemplate();
  const currentUser = useCurrentUser();
  const currentSpace = useCurrentSpace();

  const { keyword, templates: searchTemplates = [] } = useAtomValue(searchResultState);

  const [previewCategory, setPreviewCategory] = useState(currentCategory);
  const [isLastCategory, setIsLastCategory] = useState(false);

  const allCategories = useMemo(() => {
    const allCategories: string[] = [];
    officialTemplates.forEach((firstCategory) => {
      firstCategory.subGroups?.forEach((item) =>
        allCategories.push(`${firstCategory.name}/${item.name}`)
      );
    });

    return allCategories;
  }, [officialTemplates]);

  const getTemplateFromCategory = (categoryPath: string) => {
    let templates: Template[] = [];

    const path = categoryPath.split('/').filter(Boolean);

    const isCustomTemplate = categoryPath === 'myTemplate';
    if (isCustomTemplate) {
      customTemplates.subGroups?.forEach((item) => {
        templates.push(...(item.templates ?? []));
      });
    } else {
      const firstCategoryData = officialTemplates.find((item) => item.name === path[0]);
      const secondCategoryData = firstCategoryData?.subGroups?.find(
        (item) => item.name === path[1]
      );

      templates = secondCategoryData?.templates ?? [];
      secondCategoryData?.subGroups?.forEach((item) => {
        templates.push(...(item.templates ?? []));
      });
    }

    return templates;
  };
  const openButtonTips = useOpenButtonTips({
    localKey: LocalStorageKey.COMMENT_TIPS,
    endDay: 0,
  });
  const ref = useRef<HTMLButtonElement>(null);

  let templates: Template[] = [];
  if (keyword) {
    templates = searchTemplates;
  } else {
    templates = getTemplateFromCategory(previewCategory);
  }

  const [templateIndex, setTemplateIndex] = useState(
    templates.findIndex((item) => item.id === uuid)
  );

  const templateId = templates[templateIndex]?.id ?? '';
  // pageId不一定就是templateId，因为模板里面可能有其它页面，比如table的item，点击就会跳到对应的page
  const [pageId, setPageId] = useState(templateId);
  const fetchPage = useFetchPage();
  const closeModal = useCloseModal();
  const closeAllModal = useCloseAllModal();
  const openModal = useOpenModal();
  const [loading, setLoading] = useState(true);
  const templateHistory = useFinalValue(() =>
    createMemoryHistory({ initialEntries: [`/share/${uuid}`] })
  );
  const transaction = useTransaction();
  const { role } = usePermissions(pageId);
  const block = useBlock(pageId);

  useEffect(() => {
    templateHistory.replace(`${templateId}`);
  }, [templateHistory, templateId]);

  useEffect(() => {
    bizTracker.event('template_view', {
      template_id: uuid,
    });
  }, [uuid]);
  useEffect(() => {
    setTimeout(() => {
      openButtonTips(ref, {
        content: '成功分享即可获得 AI 使用次数 🎉',
        autoClose: true,
      });
    }, 1000);
  }, [openButtonTips]);

  useEffect(() => {
    if (!pageId) return;
    let mount = true;
    setLoading(true);
    void fetchPage(pageId).finally(() => {
      if (mount) {
        setLoading(false);
      }
    });
    return () => {
      mount = false;
    };
  }, [pageId, fetchPage]);

  useEffect(() => {
    return templateHistory.listen((location0, action) => {
      const location = fixLocation(location0);
      const pageId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
      if (action === 'PUSH' || action === 'REPLACE') {
        setPageId((id) => {
          if (id === pageId) {
            // 针对多维表做适配，我们直接打开，不会弹出右边的弹窗
            const rightPageId = parseRightPageId(location.search);
            if (rightPageId) return rightPageId;
          }
          return pageId;
        });
      }
    });
  }, [templateHistory]);

  const openTip = (isNext: boolean, e: React.MouseEvent) => {
    if (keyword) return;
    openModal.dropdown({
      popcorn: e.currentTarget,
      placement: 'bottom',
      content: ({ onCloseModal }) => {
        return (
          <>
            <div className="bg-black/75 rounded px-2 py-1">
              {isLastCategory ? (
                <div className="text-t4 text-white">无更多模板</div>
              ) : (
                <>
                  <div className="text-t4 text-white">该分类下无更多模板</div>
                  <div
                    className="cursor-pointer text-t4 text-white underline"
                    onClick={() => {
                      onCloseModal();
                      batch(() => {
                        const path = previewCategory.split('/').filter(Boolean);
                        const index = allCategories.indexOf(`${path[0]}/${path[1]}`);

                        const newPreviewCategory = allCategories[isNext ? index + 1 : index - 1];
                        if (!newPreviewCategory) {
                          setIsLastCategory(true);
                          return;
                        }

                        const templates = getTemplateFromCategory(newPreviewCategory);

                        setPreviewCategory(newPreviewCategory);
                        setTemplateIndex(isNext ? 0 : templates.length - 1);
                        setLoading(true);
                      });
                    }}
                  >
                    {[`查看`, isNext ? '下一分类' : '上一分类'].join('')}
                  </div>
                </>
              )}
              <div id="triangle-up" data-popper-arrow className="opacity-75" />
            </div>
          </>
        );
      },
    });
  };
  if (!templateId) return null;
  const enablePreviousBtn = templateIndex !== 0;
  const enableNextBtn = templateIndex !== templates.length - 1;
  const canEdit = role === PermissionRole.EDITOR || role === PermissionRole.WRITER;

  let breadCrumb: ReactNode = null;
  if (isCustomTemplate) {
    const isMyTemplate = customTemplates.subGroups?.[0]?.templates?.find(
      (item) => item.id === uuid
    );
    breadCrumb = isMyTemplate ? '我的模板' : '分享给我的模板';
  } else {
    breadCrumb = (
      <>
        {previewCategory.split('/')[0]}
        <span className="mx-2.5">/</span>
        {previewCategory.split('/')[1]}
        <span className="ml-2.5">/</span>
        {!loading && <Breadcrumb ancestorId={templateId} />}
      </>
    );
  }
  return (
    <Router history={templateHistory}>
      <PageSceneContext.Provider value={PageScene.TEMPLATE}>
        <EditableNamespace.Provider value={UUID_NAMESPACE.PAGE_TEMPLATE}>
          <div className="relative flex flex-col w-[1040px] h-[85.42vh] next-modal">
            <div className="flex w-full justify-between items-center h-[55px] px-4 flex-shrink-0">
              <div className="flex">
                <div
                  className="flex cursor-pointer hover:opacity-80 items-center hover:normal-bg px-1 rounded"
                  onClick={() => closeModal(Modals.TEMPLATE_PREVIEW)}
                >
                  <Icon name="IcTemplateCenterMiddleBold" size="middle" />
                  <div className="text-t2 ml-2">模板库</div>
                </div>
                <div className="flex items-center text-grey4 ml-2.5">/</div>
                <div className="flex overflow-hidden items-center ml-2.5 text-black text-t2">
                  {breadCrumb}
                </div>
              </div>

              <div className="flex items-center">
                {isCustomTemplate && canEdit && (
                  <MoreMenu
                    className="w-8 h-8 border border-solid border-grey5 rounded mr-5 "
                    moreColor="black"
                    onEdit={() => window.open(`/${uuid}`)}
                    onDelete={() => {
                      openModal.warning({
                        title: '确认删除模板？',
                        content: '删除后，该模板将从模板库移除并进入回收站',
                        confirmText: '确认删除',
                        colorType: 'red',
                        confirm: () => {
                          transaction(() => {
                            archiveBlock(uuid);
                          });
                          closeModal(Modals.TEMPLATE_PREVIEW);
                        },
                      });
                    }}
                  />
                )}
                <div
                  className={cx(
                    'flex items-center justify-center cursor-pointer border border-solid border-grey5 w-[49px] h-8 rounded-l-l ',
                    {
                      'hover:normal-bg': enablePreviousBtn,
                    }
                  )}
                  onClick={(e) => {
                    if (enablePreviousBtn) {
                      batch(() => {
                        setTemplateIndex(templateIndex - 1);
                        const templateId = templates[templateIndex - 1]?.id ?? '';
                        if (templateId !== pageId) {
                          templateHistory.replace(`/${templateId}`);
                          setLoading(true);
                        }
                      });
                    } else if (!isCustomTemplate) {
                      openTip(false, e);
                    }
                  }}
                >
                  <Icon
                    name="IcArrowDateBack"
                    size="middle"
                    className={cx({ 'opacity-30': !enablePreviousBtn })}
                  />
                </div>
                <div
                  className={cx(
                    'flex items-center justify-center cursor-pointer border-r border-t border-b border-solid border-grey5 w-[49px] h-8 rounded-r ',
                    {
                      'hover:normal-bg': enableNextBtn,
                    }
                  )}
                  onClick={(e) => {
                    if (enableNextBtn) {
                      batch(() => {
                        setTemplateIndex(templateIndex + 1);
                        const templateId = templates[templateIndex + 1]?.id ?? '';
                        if (templateId !== pageId) {
                          templateHistory.replace(`/${templateId}`);
                          setLoading(true);
                        }
                      });
                    } else if (!isCustomTemplate) {
                      openTip(true, e);
                    }
                  }}
                >
                  <Icon
                    name="IcArrowDateNext"
                    size="middle"
                    className={cx({ 'opacity-30': !enableNextBtn })}
                  />
                </div>
                <Button
                  ref={ref}
                  className="ml-3"
                  onClick={() => {
                    const params = new URLSearchParams();
                    params.set(SearchParams.inviteCode, currentUser.inviteCode);
                    params.set(SearchParams.spaceId, currentSpace.uuid);
                    const title =
                      segmentsToText(block?.data.segments) ?? getUntitledName(block?.type);
                    const shareContent =
                      `我在FlowUs发现了模板《${title}》，高效又实用，你也来试试吧！点击链接，一键拷贝到你的空间 👉` +
                      `${getLocationOrigin()}/share/${uuid}?${params.toString()}`;
                    void writeTextInClipboard(shareContent, {
                      message: '🎉 成功复制模板链接，分享给好友吧！',
                    });
                  }}
                >
                  分享该模板
                </Button>
                <Button
                  colorType="active"
                  className="ml-3"
                  onClick={async () => {
                    const template = templates[templateIndex];
                    if (!template) {
                      return;
                    }
                    const pageId = await handleCopyTemplate(
                      {
                        ...template,
                        addCopyLimit: template.isCustom ? false : true,
                      },
                      from
                    );
                    if (pageId) {
                      closeAllModal();
                    }

                    // 积分任务
                    void updateTask(
                      ActivityIds.COPY_PAGE_FROM_TEMPLATE,
                      ActivitiesListType.basicList
                    );
                  }}
                >
                  使用该模板
                </Button>
              </div>
            </div>
            {loading ? <LoadingContainer className="flex-1" /> : <Page uuid={pageId} />}
          </div>
        </EditableNamespace.Provider>
      </PageSceneContext.Provider>
    </Router>
  );
};
