import { cx } from '@flowus/common/cx';
import { BlockType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import type { IconName, IconProp } from 'src/common/components/icon';
import { Icon } from 'src/common/components/icon';
import type { ComponentProp } from 'src/common/components/icon/types';
import { cache } from 'src/redux/store';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { getBlockIconByType } from 'src/utils/block-utils';
import { getFileIcon, getSegmentsFileNameInfo } from 'src/utils/file';
import { useDriveContext } from 'src/views/main/page-drive/drive-content/hook';

interface Props extends ComponentProp {
  uuid?: string;
  size?: IconProp['size'];
  isBold?: boolean;
}
/** page的default icon */
export const BlockDefaultIcon: FC<Props> = (props) => {
  const { uuid, size = 'normal', className, isBold, ...rest } = props;
  const { isDriveIcon } = useDriveContext();

  const blockInfo = useObservableBlock(uuid, (block) => {
    if (!block) return;
    return {
      type: block.type,
      hasContent: block.subNodes?.length > 0,
    };
  });

  if (!blockInfo || !uuid) {
    return (
      <Icon
        className={cx('text-grey3', className)}
        name={getBlockIconByType(BlockType.PAGE, false, false, isBold)}
        size={size}
        {...rest}
      />
    );
  }

  let iconName;

  // 如果是根据文件后缀显示的话，这个变量会为true
  if (blockInfo.type === BlockType.FILE) {
    const { extName } = getSegmentsFileNameInfo(cache.blocks[uuid]?.data.segments);
    iconName = getFileIcon(extName);
  }

  if (iconName) {
    return (
      <Icon
        className={cx('text-grey3', className)}
        name={iconName as IconName}
        size={size}
        {...rest}
      />
    );
  }

  iconName = getBlockIconByType(blockInfo.type, !blockInfo.hasContent, isDriveIcon, isBold);

  return (
    <Icon
      className={cx('text-grey3', className)}
      name={iconName as IconName}
      size={size}
      {...rest}
    />
  );
};
