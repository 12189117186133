import { without } from 'lodash-es';
import { getCurrentSpace } from 'src/hooks/space';
import { getUpdatedAtAndUpdatedBy } from 'src/hooks/user/get-updated-user';
import * as TemplateManager from 'src/redux/managers/template';
import { LIST_REMOVE_BLOCK, UPDATE_BLOCK } from '../../actions';
import { dispatch, getState } from '../../store';
import { cleanupColumn } from './cleanup-column';

export const removeBlock = (uuid: string, ignoreOp?: boolean) => {
  const block = getState().blocks[uuid];
  if (!block) return;

  const space = getCurrentSpace();

  if (space.uuid === block.uuid) return;

  dispatch(
    UPDATE_BLOCK({
      uuid,
      patch: {
        status: -1,
        ...getUpdatedAtAndUpdatedBy(),
      },
      ignoreOp,
    })
  );

  if (block.isTemplate) {
    const collectionId = block.parentId;
    const collection = getState().blocks[collectionId];
    if (collection != null) {
      const oldTemplatePages = collection.templatePages ?? [];
      const templatePages = without(oldTemplatePages, uuid);
      dispatch(
        UPDATE_BLOCK({
          uuid: collectionId,
          patch: { templatePages, ...getUpdatedAtAndUpdatedBy() },
          ignoreOp,
        })
      );
    }
  } else {
    dispatch(LIST_REMOVE_BLOCK({ uuid, ignoreOp }));
  }

  /** 如果是自定义模板需要删掉 */
  const isCustomTemplate = space.customTemplates.includes(uuid);
  if (isCustomTemplate) {
    TemplateManager.remove(
      uuid,
      {
        parentId: space.uuid,
        ...getUpdatedAtAndUpdatedBy(),
      },
      ignoreOp
    );
  }
  cleanupColumn(uuid, block.parentId);
};
