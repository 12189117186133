import type { SegmentDTO } from '@next-space/fe-api-idl';
import { BlockType } from '@next-space/fe-api-idl';
import { first, last, omit } from 'lodash-es';
import { ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';
import { ColorPicker } from 'src/components/color-picker';
import { DEFAULT_COLOR_PICKER_DATA } from 'src/components/color-picker/default-data';
import { UpdateUserInfo } from 'src/components/update-user-info';
import { useDeleteSelectedTableCells } from 'src/hooks/block/use-delete-selected-table-calls';
import { useSelectedBlockHistory } from 'src/hooks/page/use-selected-block-history';
import { useTransaction } from 'src/hooks/use-transaction';
import { getUserName } from 'src/hooks/user/use-remark-name';
import { useUser } from 'src/hooks/user/use-user';
import { addBlock } from 'src/redux/managers/block/add';
import { archiveBlock } from 'src/redux/managers/block/archive';
import { removeBlock } from 'src/redux/managers/block/remove';
import { updateBlock } from 'src/redux/managers/block/update';
import { cache } from 'src/redux/store';
import { bizTracker } from 'src/utils/biz-tracker';
import { usePickBlock } from 'src/utils/pick-block';
import { ShortcutSystemSymbol } from 'src/utils/shortcut';
import { v4 as uuidV4 } from 'uuid';
import { cancelSelectedCells, getIndexInSelectedCells } from './helper';
import { useCopyTableItem } from './hook';

interface Props {
  tableId: string;
  isFirstRow: boolean;
  isFirstColumn: boolean;
  onCloseModal: () => void;
  type: 'row' | 'column' | 'contextmenu';
  recordId?: string;
  propertyId?: string;
}

export const TableCellMenu = ({
  type,
  onCloseModal,
  tableId,
  isFirstColumn,
  isFirstRow,
  recordId,
  propertyId,
}: Props) => {
  const table = usePickBlock(tableId, ['data', 'subNodes', 'updatedBy', 'updatedAt']);
  const transaction = useTransaction();
  const deleteSelectedTableCells = useDeleteSelectedTableCells();
  const copyTableItem = useCopyTableItem();
  const { selectedBlockHistory } = useSelectedBlockHistory();
  const user = useUser(table?.updatedBy ?? '');

  if (!table) return null;

  const { subNodes } = table;
  const format = table.data.format ?? {};
  const columns = format.tableBlockColumnOrder ?? [];
  const ranges = format.tableBlockRanges ?? [];

  const getPropertyId = (reverse?: boolean) => {
    if (propertyId) return propertyId;
    const index = getIndexInSelectedCells(columns, 'propertyId')(reverse);
    return columns[index] ?? '';
  };

  const getRecordId = (reverse?: boolean) => {
    if (recordId) return recordId;
    const index = getIndexInSelectedCells(subNodes, 'recordId')(reverse);
    return subNodes[index] ?? '';
  };

  const newColumnOrder = (newId: string, after = false) => {
    const index = columns.indexOf(getPropertyId(after));
    const pos = after ? index + 1 : index;
    return [...columns.slice(0, pos), newId, ...columns.slice(pos)];
  };

  const items: ListItem[] = [];

  const { selectedCells } = cache.ui;
  if (selectedCells.length) {
    const rowIds = selectedCells.map((cell) => cell.recordId);
    const columnIds = selectedCells.map((cell) => cell.propertyId);
    const includesStart = ({ start }: { start?: string[] }) => {
      if (!start) return false;
      const [startRowId, startColumnId] = start;
      if (!startRowId || !startColumnId) return false;
      return rowIds.includes(startRowId) && columnIds.includes(startColumnId);
    };
    const hasMergeCell = ranges.some(includesStart);

    if (hasMergeCell) {
      items.push({
        type: ListItemType.OPERATION,
        data: {
          title: '拆分单元格',
          icon: 'IcMenuSplitCells',
          onClick: () => {
            bizTracker.event('simpletable_merge_cell', { openclose: 'close' });

            const newRanges = ranges.reduce((result, { start, end }) => {
              if (!start || !end) return result;
              const [startRowId, startColumnId] = start;
              if (!startRowId || !startColumnId) return result;
              if (rowIds.includes(startRowId) && columnIds.includes(startColumnId)) return result;
              result.push({ start, end });
              return result;
            }, [] as { start: string[]; end: string[] }[]);

            transaction(() => {
              updateBlock(tableId, { data: { format: { tableBlockRanges: newRanges } } });
              selectedBlockHistory([]);
            });

            onCloseModal();
          },
        },
      });
    }

    if (selectedCells.length > 1) {
      items.push({
        type: ListItemType.OPERATION,
        data: {
          title: '合并单元格',
          icon: 'IcMenuMergeCells',
          onClick: () => {
            const firstRowId = first(rowIds);
            const firstColId = first(columnIds);
            const lastRowId = last(rowIds);
            const lastColId = last(columnIds);

            if (!firstRowId || !firstColId || !lastRowId || !lastColId) return;

            bizTracker.event('simpletable_merge_cell', { openclose: 'open' });

            transaction(() => {
              updateBlock(tableId, {
                data: {
                  format: {
                    tableBlockRanges: [
                      ...ranges.filter((range) => !includesStart(range)),
                      { start: [firstRowId, firstColId], end: [lastRowId, lastColId] },
                    ],
                  },
                },
              });

              const discussions: string[] = [];
              const segments = selectedCells.reduce((result, cell) => {
                const { recordId, propertyId } = cell;
                const row = cache.blocks[recordId];
                if (!row) return result;
                const { collectionProperties = {} } = row.data;
                const _segments = collectionProperties[propertyId];
                if (!_segments) return result;

                _segments.forEach((segment) => {
                  segment.discussions?.forEach((id) => discussions.push(id));
                });

                updateBlock(recordId, {
                  data: {
                    collectionProperties: omit(collectionProperties, propertyId),
                  },
                  discussions: (row.discussions ?? []).filter((id) => !discussions.includes(id)),
                });

                const lastSegment = last(result);
                if (lastSegment) {
                  return result
                    .slice(0, -1)
                    .concat({ ...lastSegment, text: `${lastSegment.text}\n` }, _segments);
                }

                return result.concat(_segments);
              }, [] as SegmentDTO[]);

              const firstRow = cache.blocks[firstRowId];
              if (firstRow) {
                updateBlock(firstRowId, {
                  data: {
                    collectionProperties: {
                      ...(firstRow.data.collectionProperties ?? {}),
                      [firstColId]: segments,
                    },
                  },
                  discussions: (firstRow.discussions ?? []).concat(discussions),
                });
              }

              selectedBlockHistory([]);
            });

            onCloseModal();
          },
        },
      });
    }

    if (hasMergeCell || selectedCells.length > 1) {
      items.push({ type: ListItemType.LINE });
    }
  }

  if (type !== 'contextmenu') {
    items.push({
      type: ListItemType.OPERATION,
      data: {
        icon: 'IcMenuColor',
        title: '颜色',
        hasArrow: true,
        renderSubMenu: () => (
          <ColorPicker
            items={DEFAULT_COLOR_PICKER_DATA}
            onColorSelect={(colorInfo) => {
              onCloseModal();
              transaction(() => {
                if (type === 'row') {
                  // eslint-disable-next-line no-unused-vars
                  const { textColor, backgroundColor, ...rest } = format;
                  const newFormat = colorInfo.isBgColor
                    ? {
                        ...rest,
                        backgroundColor: colorInfo.colorkey,
                      }
                    : { ...rest, textColor: colorInfo.colorkey };

                  updateBlock(getRecordId(), {
                    data: {
                      format: newFormat,
                    },
                  });
                }

                if (type === 'column') {
                  const propertyFormat = format.tableBlockColumnFormat?.[getPropertyId()] ?? {};
                  // eslint-disable-next-line no-unused-vars
                  const { textColor, backgroundColor, ...rest } = propertyFormat;
                  const newProperty = colorInfo.isBgColor
                    ? { ...rest, backgroundColor: colorInfo.colorkey }
                    : { ...rest, textColor: colorInfo.colorkey };

                  updateBlock(tableId, {
                    data: {
                      format: {
                        tableBlockColumnFormat: {
                          ...format.tableBlockColumnFormat,
                          [getPropertyId()]: newProperty,
                        },
                      },
                    },
                  });
                }
              });
            }}
          />
        ),
      },
    });
  }

  if (type !== 'column') {
    items.push({
      type: ListItemType.OPERATION,
      data: {
        title: '向上边插入行',
        icon: 'IcMenuInsertUp',
        onClick: () => {
          bizTracker.event('simpletable_add');
          onCloseModal();
          transaction(() => {
            addBlock({ type: BlockType.TABLE_ROW }, { parentId: tableId, before: getRecordId() });
          });
        },
      },
    });
    items.push({
      type: ListItemType.OPERATION,
      data: {
        title: '向下边插入行',
        icon: 'IcMenuInsertDown',
        onClick: () => {
          bizTracker.event('simpletable_add');
          onCloseModal();
          transaction(() => {
            addBlock(
              { type: BlockType.TABLE_ROW },
              { parentId: tableId, after: getRecordId(true) }
            );
          });
        },
      },
    });
  }

  if (type !== 'row') {
    items.push({
      type: ListItemType.OPERATION,
      data: {
        title: '向左侧插入列',
        icon: 'IcMenuInsertLeft',
        onClick: () => {
          bizTracker.event('simpletable_add');
          onCloseModal();
          const columnId = uuidV4();
          transaction(() => {
            updateBlock(tableId, {
              data: {
                format: {
                  tableBlockColumnOrder: newColumnOrder(columnId),
                },
              },
            });
          });
        },
      },
    });

    items.push({
      type: ListItemType.OPERATION,
      data: {
        title: '向右侧插入列',
        icon: 'IcMenuInsertRight',
        onClick: () => {
          bizTracker.event('simpletable_add');
          onCloseModal();
          const columnId = uuidV4();
          transaction(() => {
            updateBlock(tableId, {
              data: {
                format: {
                  tableBlockColumnOrder: newColumnOrder(columnId, true),
                },
              },
            });
          });
        },
      },
    });
  }

  if (type !== 'contextmenu') {
    items.push({
      type: ListItemType.OPERATION,
      data: {
        title: '拷贝副本',
        icon: 'IcMenuDuplicate',
        rightText: `${ShortcutSystemSymbol}+D`,
        onClick: () => {
          onCloseModal();
          copyTableItem();
        },
      },
    });
  }

  items.push({ type: ListItemType.LINE });

  items.push({
    type: ListItemType.OPERATION,
    data: {
      title: '清空内容',
      icon: 'IcUploadCancel',
      onClick: () => {
        onCloseModal();
        if (type === 'row') {
          transaction(() => {
            updateBlock(getRecordId(), {
              backgroundColor: '',
              textColor: '',
              data: {
                collectionProperties: {},
              },
            });
          });
        }

        if (type === 'column') {
          transaction(() => {
            table.subNodes.forEach((uuid) => {
              const row = cache.blocks[uuid];
              if (row) {
                const { [getPropertyId()]: _, ...restFormat } = row.data.collectionProperties ?? {};
                updateBlock(uuid, {
                  data: {
                    collectionProperties: restFormat,
                  },
                });
              }
            });
          });
        }

        if (type === 'contextmenu') {
          deleteSelectedTableCells();
        }

        cancelSelectedCells();
      },
    },
  });

  if (type !== 'column') {
    items.push({
      type: ListItemType.OPERATION,
      data: {
        title: '删除所选行',
        icon: 'IcMenuDelete',
        onClick: () => {
          onCloseModal();

          transaction(() => {
            if (subNodes.length === 1) {
              // 只剩一行时，删除表格
              removeBlock(tableId);
            } else {
              const { selectedCells } = cache.ui;
              if (selectedCells.length) {
                archiveBlock(selectedCells.map((o) => o.recordId));

                const tableBlockRanges = ranges.filter((range) => {
                  const { start } = range;
                  if (!start) return false;
                  const [startRow, startColumn] = start;
                  return !selectedCells.some(
                    (o) => startRow === o.recordId && startColumn === o.propertyId
                  );
                });

                if (tableBlockRanges.length !== ranges.length) {
                  updateBlock(tableId, { data: { format: { tableBlockRanges } } });
                }
              } else {
                const _recordId = getRecordId();
                removeBlock(_recordId);
                const index = subNodes.indexOf(_recordId);
                // 过滤掉 开始和结束 都是当前行的区域
                const tableBlockRanges = ranges.filter((range) => {
                  const { start, end } = range;
                  if (!start || !end) return false;
                  const [startRow] = start;
                  const [endRow] = end;
                  return !(startRow === _recordId && endRow === _recordId);
                });

                updateBlock(tableId, {
                  data: {
                    format: {
                      tableBlockRanges: tableBlockRanges.map((range) => {
                        const { start, end } = range;
                        if (!start || !end) return range;
                        const [startRow, startColumn] = start;
                        const [endRow, endColumn] = end;
                        if (startRow === _recordId) {
                          return {
                            ...range,
                            start: [subNodes[index + 1], startColumn] as string[],
                          };
                        }
                        if (endRow === _recordId) {
                          return { ...range, end: [subNodes[index - 1], endColumn] as string[] };
                        }
                        return range;
                      }),
                    },
                  },
                });
              }
            }
          });

          cancelSelectedCells();
        },
      },
    });
  }

  if (type !== 'row') {
    items.push({
      type: ListItemType.OPERATION,
      data: {
        title: `删除所选列`,
        icon: 'IcMenuDelete',
        onClick: () => {
          onCloseModal();

          transaction(() => {
            if (format.tableBlockColumnOrder?.length === 1) {
              removeBlock(tableId);
            } else {
              const { selectedCells } = cache.ui;
              const properties = selectedCells.length
                ? selectedCells.map((o) => o.propertyId)
                : [propertyId ?? ''];

              if (selectedCells.length) {
                const tableBlockRanges = ranges.filter((range) => {
                  const { start } = range;
                  if (!start) return false;
                  const [startRow, startColumn] = start;
                  return !selectedCells.some(
                    (o) => startRow === o.recordId && startColumn === o.propertyId
                  );
                });

                if (tableBlockRanges.length !== ranges.length) {
                  updateBlock(tableId, { data: { format: { tableBlockRanges } } });
                }
              } else {
                // 过滤掉 开始和结束 都是当前列的区域
                const tableBlockRanges = ranges.filter((range) => {
                  const { start, end } = range;
                  if (!start || !end) return false;
                  const [, startColumn] = start;
                  const [, endColumn] = end;
                  return !(startColumn === propertyId && endColumn === propertyId);
                });

                const index = columns.indexOf(propertyId ?? '') ?? -1;

                if (index > -1) {
                  updateBlock(tableId, {
                    data: {
                      format: {
                        tableBlockRanges: tableBlockRanges.map((range) => {
                          const { start, end } = range;
                          if (!start || !end) return range;
                          const [startRow, startColumn] = start;
                          const [endRow, endColumn] = end;
                          if (startColumn === propertyId) {
                            return {
                              ...range,
                              start: [startRow, columns[index + 1]] as string[],
                            };
                          }
                          if (endColumn === propertyId) {
                            return { ...range, end: [endRow, columns[index - 1]] as string[] };
                          }
                          return range;
                        }),
                      },
                    },
                  });
                }
              }

              updateBlock(tableId, {
                data: {
                  format: {
                    tableBlockColumnFormat: omit(format.tableBlockColumnFormat ?? {}, properties),
                    tableBlockColumnOrder: columns.filter((id) => !properties.includes(id)),
                  },
                },
              });
              table.subNodes.forEach((uuid) => {
                const row = cache.blocks[uuid];
                if (row) {
                  updateBlock(uuid, {
                    data: {
                      collectionProperties: omit(row.data.collectionProperties ?? {}, properties),
                    },
                  });
                }
              });
            }
          });

          cancelSelectedCells();
        },
      },
    });
  }

  items.push({
    type: ListItemType.LINE,
  });

  if (isFirstRow) {
    items.unshift({ type: ListItemType.LINE });
    items.unshift({
      type: ListItemType.TEXT_SWITCH,
      data: {
        title: '标题行',
        switch: format.tableBlockRowHeader,
        icon: 'IcTableHeaderRow',
        noCancelSelected: true,
        onSwitch: (status: boolean) => {
          bizTracker.event('simpletable_titlerow', { openclose: status ? 'open' : 'close' });
          transaction(() => {
            updateBlock(tableId, {
              data: { format: { tableBlockRowHeader: status } },
            });
          });
        },
      },
    });
  }

  if (isFirstColumn) {
    items.unshift({ type: ListItemType.LINE });
    items.unshift({
      type: ListItemType.TEXT_SWITCH,
      data: {
        title: '标题列',
        switch: format.tableBlockColumnHeader,
        icon: 'IcTableHeaderColumn',
        noCancelSelected: true,
        onSwitch: (status: boolean) => {
          bizTracker.event('simpletable_titlecolumn', { openclose: status ? 'open' : 'close' });
          transaction(() => {
            updateBlock(tableId, {
              data: { format: { ...format, tableBlockColumnHeader: status } },
            });
          });
        },
      },
    });
  }

  return (
    <ListView
      className="py-[5px] rounded-r w-60 next-modal"
      items={items}
      customFooter={
        <UpdateUserInfo
          name={getUserName(user?.uuid ?? '')}
          updatedAt={table.updatedAt}
          isByAI={table.data.isByAI}
        />
      }
    />
  );
};
