import { getSessionStorage, SessionKeys } from '@flowus/common/storage';
import Axios from 'axios';
import { VITE_PREFIX } from 'src/env';
import { ViewPath } from 'src/utils';
import { isFlowUsApp } from 'src/utils/electron-util';
import { getToken } from 'src/utils/get-next-auth';
import * as UUID from 'uuid';
import { currentVersion, TOKEN, USER_ID } from '../const';
import { checkViewPath, getDomain } from '../utils/url-utils';

export const SENDER_ID = UUID.v4();

const baseURL = isFlowUsApp.check && !__HOST_LOCAL__ ? `${VITE_PREFIX}/api` : '/api';

export const axios = Axios.create({
  baseURL,
  validateStatus() {
    return true;
  },
});

axios.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  config.headers['X-SENDER-ID'] = SENDER_ID;
  config.headers['x-platform'] = isFlowUsApp.check ? 'desktop' : 'web-cookie';
  config.headers['x-product'] = __BUILD_IN__ ? 'buildin' : 'flowus';
  config.headers['app_version_name'] = currentVersion;
  config.headers['x-app-origin'] = 'web';
  if (__BUILD_IN__) {
    config.headers['Accept-Language'] = 'en-US,en;q=0.9,zh;q=0.8';
  }

  const psw = getSessionStorage(SessionKeys.PASSWORD);
  if (psw) {
    config.headers['share-password'] = psw;
  }

  return config;
});

axios.interceptors.response.use((res) => {
  // 补丁，只有这个接口不自动跳/login
  if (res.config.url === '/users/me') {
    return res;
  }
  if (res.status === 401 || (res.data && res.data.code === 401)) {
    localStorage.removeItem(USER_ID);
    localStorage.removeItem(TOKEN);

    if (!checkViewPath(ViewPath.login)) {
      // 未登录也可以访问公开主页
      if (window.location.pathname === ViewPath.main && getDomain()) {
        return res;
      }
      if (checkViewPath(ViewPath.form, 'startsWith')) {
        return;
      }

      window.location.pathname = ViewPath.login;
    }
  } else {
    const token = res.headers['x-set-token'];
    if (token) {
      localStorage.setItem(TOKEN, token);
    }
  }

  return res;
});
