import { Role } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import { CollectionViewType, PermissionRole } from '@next-space/fe-api-idl';
import type { VirtualElement } from '@popperjs/core';
import type { FC, MouseEvent } from 'react';
import { useBitable } from 'src/bitable/context';
import { Icon } from 'src/common/components/icon';
import { ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';
import { useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { useOpenMoveTo } from 'src/components/move-to';
import { UpdateUserInfo } from 'src/components/update-user-info';
import { useSyncId } from 'src/editor/editor/plugin/sync-block-context';
import { useShortcutKeyInsertBlock } from 'src/editor/editor/uikit/hover-menu/hook';
import { PropertyList } from 'src/editor/editor/uikit/hover-menu/property-list';
import { useCopyRecord } from 'src/hooks/block/use-copy-record';
import { useReadonly } from 'src/hooks/page';
import { useOpenPage } from 'src/hooks/page/use-open-page';
import { useSelectedBlockHistory } from 'src/hooks/page/use-selected-block-history';
import { getPermissions, usePermissions } from 'src/hooks/share/use-permissions';
import { useTransaction } from 'src/hooks/use-transaction';
import { useUserName } from 'src/hooks/user/use-remark-name';
import { useUser } from 'src/hooks/user/use-user';
import { archiveBlock } from 'src/redux/managers/block/archive';
import { useCloseBlockMenuList } from 'src/redux/managers/ui';
import { uiActions } from 'src/redux/reducers/ui';
import { cache, dispatch } from 'src/redux/store';
import { setAppUiState } from 'src/services/app';
import { bizTracker } from 'src/utils/biz-tracker';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { judgeSharePage } from 'src/utils/getPageId';
import { getLocationOrigin } from 'src/utils/location-utils';
import { usePickBlock } from 'src/utils/pick-block';
import { idsToSelectedBlocks, selectedBlocksToIds } from 'src/utils/select-block-util';

interface Props {
  recordId: string;
}

export const CardMenu: FC<Props> = ({ recordId }) => {
  const { viewId, managerReadonly, readonly, viewType } = useBitable();
  const isGallery = viewType === CollectionViewType.GALLERY;
  const closeBlockMenuList = useCloseBlockMenuList();
  const openModal = useOpenModal();
  const syncId = useSyncId();
  const recordReadonly = useReadonly(recordId, false);

  if (readonly || managerReadonly || recordReadonly) return null;

  const openMenuList = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    const { selectedBlocks } = cache.ui;

    const rect = event.currentTarget.getBoundingClientRect();
    const popcorn = {
      getBoundingClientRect: () => rect,
    };
    const { modalId } = openModal.dropdown({
      modalId: recordId,
      popcorn,
      placement: 'right-start',
      closeBeforeCallBack: closeBlockMenuList,
      content: ({ onCloseModal }) => (
        <HoverMenuList
          popcorn={popcorn}
          onCloseModal={onCloseModal}
          blockId={recordId}
          viewId={viewId}
        />
      ),
    });
    const result =
      selectedBlocks.findIndex((v) => v.blockId === recordId) === -1
        ? idsToSelectedBlocks(recordId, viewId, syncId)
        : selectedBlocks;
    setAppUiState({ $blockMenuListId: modalId });
    dispatch(
      uiActions.update({
        selectedBlocks: result,
        selectedCells: [],
      })
    );
  };

  return (
    <div
      className={cx(
        'operator-button absolute right-1.5 z-10 flex opacity-0 transition duration-300',
        isGallery ? 'top-1.5' : 'top-3'
      )}
    >
      <Tooltip
        className="ml-2 flex items-center transition duration-300"
        placement="bottom"
        popup="点击打开菜单"
      >
        <button
          onClick={openMenuList}
          className="animate-hover-opaque flex h-6 w-6 cursor-pointer items-center justify-center rounded border border-grey6 bg-white1"
        >
          <Icon name="IcMore" size="middle" className="text-grey3" />
        </button>
      </Tooltip>
    </div>
  );
};

enum MenuListItemType {
  DELETE = 1,
  COPY,
  COPY_MOVE,
  MOVE,
  OPEN_RIGHT_PAGE,
}

interface HoverMenuListProps {
  viewId: string;
  blockId: string;
  popcorn: VirtualElement;
  onCloseModal: () => void;
}

export const HoverMenuList: FC<HoverMenuListProps> = (props) => {
  const { onCloseModal, blockId, popcorn, viewId } = props;
  const { illegal, role } = usePermissions(blockId);
  const block = usePickBlock(blockId, ['updatedBy', 'updatedAt', 'data'], ['isByAI']);
  const transaction = useTransaction();
  const copyRecord = useCopyRecord();
  const openMoveToPage = useOpenMoveTo();
  const { selectedBlockHistory } = useSelectedBlockHistory();
  const isSharePage = judgeSharePage();
  const user = useUser(block?.updatedBy || '');
  const shortcutKeyInsetBlock = useShortcutKeyInsertBlock();
  const userName = useUserName(user?.uuid ?? '');
  const openPage = useOpenPage();

  const editorUser = [PermissionRole.WRITER, PermissionRole.EDITOR].includes(role);

  if (!block) return null;

  const items: ListItem[] = [
    {
      type: ListItemType.OPERATION,
      data: {
        type: MenuListItemType.OPEN_RIGHT_PAGE,
        icon: 'IcOpenRight',
        title: '在侧边栏中打开',
      },
    },
    {
      type: ListItemType.OPERATION,
      data: {
        type: MenuListItemType.DELETE,
        icon: 'IcMenuDelete',
        title: '删除',
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: illegal,
      data: {
        type: MenuListItemType.COPY,
        icon: 'IcMenuDuplicate',
        title: '拷贝副本',
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden:
        illegal || !Role.contains(getPermissions(block.parentId).role, PermissionRole.WRITER),
      data: {
        type: MenuListItemType.COPY_MOVE,
        icon: 'IcMenuCopyToPage',
        title: '拷贝副本至页面',
      },
    },
    {
      type: ListItemType.OPERATION,
      data: {
        icon: 'IcUrl',
        title: '拷贝页面访问链接',
        onClick: () => {
          bizTracker.event('copy_page_link');
          void writeTextInClipboard(`${getLocationOrigin()}/${blockId}`);
        },
        rightText: `F`,
      },
    },
    {
      type: ListItemType.LINE,
      data: {},
      disableClick: true,
    },
    {
      type: ListItemType.OPERATION,
      data: {
        icon: 'IcEditProperty',
        title: '编辑属性',
        hasArrow: true,
        renderSubMenu: () => {
          return (
            <PropertyList
              popcorn={popcorn}
              blockId={block.uuid}
              collectionId={block.parentId}
              selectedBlocks={cache.ui.selectedBlocks}
            />
          );
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      data: {
        type: MenuListItemType.MOVE,
        icon: 'IcMenuMove',
        title: '移动到',
      },
    },
    {
      type: ListItemType.LINE,
      isHidden: !(editorUser && !isSharePage),
      data: {},
      disableClick: true,
    },
    {
      type: ListItemType.OPERATION,
      isHidden: !(editorUser && !isSharePage),
      data: {
        icon: 'IcMenuInsertUp',
        title: '在上方插入块',
        rightText: `A`,
        onClick: () => {
          shortcutKeyInsetBlock({ pos: 'before' });
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: !(editorUser && !isSharePage),
      data: {
        icon: 'IcMenuInsertDown',
        title: '在下方插入块',
        rightText: `B`,
        onClick: () => {
          shortcutKeyInsetBlock({ pos: 'after' });
        },
      },
    },
    {
      type: ListItemType.LINE,
      data: {},
      disableClick: true,
    },
  ];

  return (
    <ListView
      className="py-2 rounded next-modal w-[260px]"
      items={items}
      customFooter={
        editorUser &&
        !isSharePage && (
          <UpdateUserInfo name={userName} updatedAt={block.updatedAt} isByAI={block.data.isByAI} />
        )
      }
      onItemClick={async (item) => {
        const { type } = item.data;

        if (type === MenuListItemType.DELETE) {
          transaction(() => {
            archiveBlock(selectedBlocksToIds(cache.ui.selectedBlocks));
          });
        }

        if (type === MenuListItemType.COPY) {
          copyRecord(viewId, cache.ui.selectedBlocks, {
            effects: selectedBlockHistory,
          });
        }

        if (type === MenuListItemType.MOVE) {
          openMoveToPage({
            popcorn,
            uuid: blockId,
            placement: 'left',
            offset: [0, 20],
          });
        }

        if (type === MenuListItemType.COPY_MOVE) {
          openMoveToPage({
            popcorn,
            uuid: blockId,
            placement: 'left',
            offset: [0, 20],
            placeholder: '搜索拷贝到...的页面',
            copyAndMove: { viewId },
          });
        }

        if (type === MenuListItemType.OPEN_RIGHT_PAGE) {
          openPage(blockId, { forceOpenInRight: true });
        }

        onCloseModal();
      }}
    />
  );
};
