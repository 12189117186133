import { useSetAtom } from 'jotai';
import { throttle } from 'lodash-es';
import type { FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { request } from 'src/common/request';
import { getCurrentSpace } from 'src/hooks/space';
import type { Template } from 'src/hooks/template/types';
import { getTemplateFromId } from 'src/hooks/template/use-custom-templates-category';
import { getTemplateData } from 'src/hooks/template/use-fetch-templates';
import { blocksActions } from 'src/redux/reducers/blocks';
import { dispatch } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { searchResultState } from './template-context';

interface Props {
  keyword?: string;
  currentCategory: string;
}

export const TemplateSearch: FC<Props> = (props) => {
  const { keyword: initKeyword, currentCategory } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const [keyword, setKeyword] = useState('');
  const setSearchResult = useSetAtom(searchResultState);

  useEffect(() => {
    setSearchResult({});
    setKeyword('');
  }, [currentCategory, setSearchResult]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const search = useCallback(
    throttle(async (value: string) => {
      try {
        const { uuid } = getCurrentSpace();
        if (!value.trim()) {
          setSearchResult({});
          return;
        }

        const res = await request.editor.searchTemplates(value, uuid);
        const { recommendResult = [], recordMap, searchResult, hasSearchResult } = res;
        dispatch(blocksActions.update({ blocks: recordMap.blocks as Record<string, NextBlock> }));

        let templates: Template[] = [];
        if (hasSearchResult) {
          const { my = [], official = [] } = searchResult ?? {};

          const myTemplates = my
            .map((uuid) => getTemplateFromId(uuid))
            .filter((item): item is Template => !!item);

          const officialTemplates = official
            .map((uuid) => getTemplateData(uuid))
            .filter((item): item is Template => !!item);

          templates = myTemplates.concat(officialTemplates);
        } else {
          templates = recommendResult
            .map((uuid) => getTemplateData(uuid))
            .filter((item): item is Template => !!item);
        }

        setSearchResult({
          hasResult: hasSearchResult,
          keyword: value.trim(),
          templates,
        });

        // eslint-disable-next-line no-empty
      } catch (error) {}
    }, 1000),
    []
  );

  const handleChange = useCallback(
    (value: string) => {
      setKeyword(value);
      void search(value);
    },
    [search]
  );

  useEffect(() => {
    if (initKeyword) {
      handleChange(initKeyword);
    }
  }, [handleChange, initKeyword]);

  const clear = () => {
    handleChange('');
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className="h-full flex flex-shrink-0">
      {!__BUILD_IN__ && (
        <div className="px-2 flex items-center justify-between">
          <div className="relative">
            <Input
              className="h-8 pl-10 pr-5 rounded-sm"
              inputClassName="pl-0"
              placeholder="搜索模板"
              inputRef={inputRef}
              value={keyword}
              onChange={handleChange}
            />
            <Icon
              size="normal"
              className="absolute left-3 top-1.5 text-xl text-grey4 leading-none"
              name="IcSearchMiddle"
            />
            {keyword && (
              <Icon
                size="normal"
                name={'IcUploadCancel'}
                onClick={clear}
                className="absolute right-0 top-1.5 text-grey4 mr-2 w-[18px] h-[18px]"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
