import { cx } from '@flowus/common/cx';
import { BlockType } from '@next-space/fe-api-idl';
import { clone, forEachRight, last, throttle } from 'lodash-es';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { useTransaction } from 'src/hooks/use-transaction';
import { addBlock } from 'src/redux/managers/block/add';
import { removeBlock } from 'src/redux/managers/block/remove';
import { updateBlock } from 'src/redux/managers/block/update';
import { simpleTableActions } from 'src/redux/reducers/simple-table';
import { cache, dispatch } from 'src/redux/store';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { bizTracker } from 'src/utils/biz-tracker';
import { usePickBlock } from 'src/utils/pick-block';
import { v4 as uuidV4 } from 'uuid';
import type { TableProps } from './types';

const DRAG_INIT = {
  startX: 0,
  startY: 0,
  minX: 0,
  minY: 0,
  minRowIndex: 0,
  minColumnIndex: 0,
  /** 原始行 */
  originRows: [''],
  /** 拖拽中生成的最大行 */
  maxRows: [''],
  /** 原始列 */
  originColumns: [''],
  /** 拖拽中生成的最大列 */
  maxColumns: [''],
  localBlock: new Set(),
};

const COLUMN_WIDTH = 120;
const ROW_HEIGHT = 38;
const BUTTON_HEIGHT = 18;
const COLUMN_THRESHOLD = 40;

const TEXT = {
  row: ['加一行', '快速加/减行'],
  column: ['加一列', '快速加/减列'],
  corner: ['加一行和一列', '快速加/减行和列'],
};

const CURSOR = {
  row: 'dragging-row',
  column: 'dragging-col',
  corner: 'dragging-nwse',
};

export const DragBar = ({
  tableRef,
  tableId,
  type,
  indexRanges,
}: TableProps & { type: 'row' | 'column' | 'corner' }) => {
  const [{ width, height }, setSize] = useState({ width: 0, height: 0 });
  const show = useObservableStore(
    ({ blocks, simpleTable }) => {
      const table = blocks[tableId];
      if (!table) return false;
      const { hoveringCell, activityTableId } = simpleTable;
      if (!hoveringCell || activityTableId !== tableId) return false;
      const { subNodes, data } = table;
      const { format = {} } = data;

      const columns = format.tableBlockColumnOrder ?? [];
      const { rowIndex, columnIndex } = hoveringCell;
      const rowId = subNodes[rowIndex];
      const columnId = columns[columnIndex];
      if (!rowId || !columnId) return false;

      let colSpan = 0;
      let rowSpan = 0;

      const spans = format.tableBlockRanges ?? [];
      const span = spans.find(({ start }) => start && start[0] === rowId && start[1] === columnId);

      if (span) {
        const { start, end } = span;
        if (start && end && start[1] && end[1]) {
          const startColumnIndex = columns.indexOf(start[1]);
          const endColumnIndex = columns.indexOf(end[1]);
          colSpan = endColumnIndex - startColumnIndex + 1;
        }
        if (start && end && start[0] && end[0]) {
          const startRowIndex = subNodes.indexOf(start[0]);
          const endRowIndex = subNodes.indexOf(end[0]);
          rowSpan = endRowIndex - startRowIndex + 1;
        }
      }

      const isLastRow = hoveringCell.rowIndex + Math.max(0, rowSpan - 1) === subNodes.length - 1;
      const isLastColumn =
        hoveringCell.columnIndex + Math.max(0, colSpan - 1) === columns.length - 1;

      if (type === 'row') return isLastRow;
      if (type === 'column') return isLastColumn;
      return isLastRow && isLastColumn;
    },
    [type],
    { obsSimpleTable: true }
  );

  const [left, setLeft] = useState(0);
  const table = usePickBlock(tableId, ['data', 'subNodes'], ['format']);
  const [visible, setVisible] = useState(false);
  const { draggingInThisTable, draggingBar } = useObservableStore(
    ({ simpleTable }) => ({
      draggingInThisTable: simpleTable.activityTableId === tableId && !!simpleTable.draggingBarType,
      draggingBar: simpleTable.activityTableId === tableId && simpleTable.draggingBarType === type,
    }),
    [tableId, type],
    { obsSimpleTable: true }
  );

  const dragRef = useRef(clone(DRAG_INIT));
  const transaction = useTransaction();

  const columns = table?.data.format?.tableBlockColumnOrder ?? [];

  useLayoutEffect(() => {
    setTimeout(() => {
      if (!tableRef.current) return;
      const tableRect = tableRef.current.getBoundingClientRect();
      setSize({ width: tableRect.width, height: tableRect.height });
    });
  }, [tableRef, show, draggingBar, draggingInThisTable, table?.subNodes.length, columns.length]);

  useEffect(() => {
    if (!tableRef.current) return;
    const parentElement = tableRef.current.closest(
      '[data-virtuoso-scroller="true"]'
    )?.parentElement;

    if (type !== 'row' && parentElement) {
      const paddingLeft = parseInt(
        getComputedStyle(parentElement).getPropertyValue('padding-left'),
        10
      );
      setLeft(width + paddingLeft);
    }
  }, [show, tableRef, type, width]);

  useEffect(() => {
    if (!draggingBar) return;

    const mouseup = () => {
      dispatch(
        simpleTableActions.update({ activityTableId: undefined, draggingBarType: undefined })
      );
      const root = document.getElementById('root');
      root?.classList.remove(CURSOR[type]);

      const originRows = dragRef.current.originRows.slice();
      const originColumns = dragRef.current.originColumns.slice();

      dragRef.current = clone(DRAG_INIT);
      const _table = cache.blocks[tableId];
      if (!_table) return;

      const { subNodes, data } = _table;
      const { format } = data;
      const columns = format?.tableBlockColumnOrder ?? [];
      const columnFormat = format?.tableBlockColumnFormat ?? {};

      if (type === 'corner') {
        bizTracker.event('simpletable_add_drag');
      }

      // 为了撤销用
      if (type !== 'row') {
        updateBlock(
          tableId,
          {
            data: {
              format: {
                tableBlockColumnOrder: originColumns,
              },
            },
          },
          true
        );
      }

      transaction(() => {
        if (type !== 'column') {
          if (subNodes.length < originRows.length) {
            originRows
              .filter((uuid) => !subNodes.includes(uuid))
              .forEach((uuid) => {
                removeBlock(uuid);
              });
          } else if (subNodes.length > originRows.length) {
            // 本地更新回初始状态，不然 add 时会出现重复 uuid
            updateBlock(tableId, { subNodes: originRows }, true);
            subNodes
              .filter((uuid) => !originRows.includes(uuid))
              .reduce((pre, uuid) => {
                addBlock({ uuid, type: BlockType.TABLE_ROW }, { parentId: tableId, after: pre });
                return pre;
              }, last(originRows));
          }
        }

        if (type !== 'row') {
          if (columns.length < originColumns.length) {
            const newFormat = columns.reduce((obj, uuid) => {
              const f = columnFormat[uuid];
              if (f) obj[uuid] = f;
              return obj;
            }, {} as typeof columnFormat);

            updateBlock(tableId, {
              data: {
                format: {
                  tableBlockColumnOrder: columns,
                  tableBlockColumnFormat: newFormat,
                },
              },
            });
          } else if (columns.length > originColumns.length) {
            updateBlock(tableId, {
              data: {
                format: {
                  tableBlockColumnOrder: columns,
                },
              },
            });
          }
        }
      });
    };

    const _mousemove = (event: MouseEvent) => {
      const { minX, minY, startX, startY, maxRows, maxColumns, minRowIndex, minColumnIndex } =
        dragRef.current;

      if (startX === 0 && startY === 0) return;

      const _table = cache.blocks[tableId];

      if (!_table) return;

      const { subNodes, data } = _table;

      if (type !== 'column' && (event.y > startY + BUTTON_HEIGHT || event.y < startY)) {
        if (event.y < minY) {
          if (subNodes.length > minRowIndex + 1) {
            updateBlock(tableId, { subNodes: maxRows.slice(0, minRowIndex + 1) }, true);
          }
        } else {
          const rowsNum = Math.ceil((event.y + ROW_HEIGHT - minY) / ROW_HEIGHT);
          const diff = rowsNum + minRowIndex - maxRows.length;
          if (diff > 0) {
            for (let i = 0; i < diff; i++) {
              const id = addBlock(
                { type: BlockType.TABLE_ROW },
                { parentId: tableId, last: true },
                true
              );
              dragRef.current.localBlock.add(id);
              dragRef.current.maxRows = cache.blocks[tableId]?.subNodes.slice() ?? [];
            }
          } else {
            updateBlock(tableId, { subNodes: maxRows.slice(0, minRowIndex + rowsNum) }, true);
          }
        }
      }

      const { format } = data;
      const columnOrder = format?.tableBlockColumnOrder?.slice() ?? [];

      if (
        type !== 'row' &&
        (event.x > startX + COLUMN_THRESHOLD || event.x < startX - COLUMN_THRESHOLD)
      ) {
        if (event.x < minX) {
          if (columnOrder.length > minColumnIndex + 1) {
            updateBlock(
              tableId,
              {
                data: {
                  format: {
                    tableBlockColumnOrder: maxColumns.slice(0, minColumnIndex + 1),
                  },
                },
              },
              true
            );
          }
        } else {
          const columnsNum = Math.ceil((event.x - minX) / COLUMN_WIDTH);
          const diff = columnsNum + minColumnIndex - maxColumns.length;
          if (diff > 0) {
            const tableBlockColumnOrder = maxColumns.concat(
              Array.from(Array(diff)).map(() => uuidV4())
            );
            updateBlock(
              tableId,
              {
                data: {
                  format: {
                    tableBlockColumnOrder,
                  },
                },
              },
              true
            );
            dragRef.current.maxColumns = tableBlockColumnOrder.slice();
          } else {
            updateBlock(
              tableId,
              {
                data: {
                  format: {
                    tableBlockColumnOrder: maxColumns.slice(0, minColumnIndex + columnsNum),
                  },
                },
              },
              true
            );
          }
        }
      }
    };

    const mousemove = throttle(_mousemove, 32);

    document.addEventListener('mouseup', mouseup);
    document.addEventListener('mousemove', mousemove);

    return () => {
      document.removeEventListener('mouseup', mouseup);
      document.removeEventListener('mousemove', mousemove);
    };
  }, [draggingBar, tableId, tableRef, transaction, type]);

  if (!table) return null;

  const props = {} as any;

  if (type === 'row') {
    props.style = { width };
  }

  if (type === 'corner') {
    props.style = { left };
    props.key = left;
  }

  if (type === 'column') {
    props.style = { left, height };
    props.key = left;
  }

  return (
    <Tooltip
      placement="bottom"
      className={cx(
        'absolute items-end justify-end ml-3 opacity-0',
        !draggingInThisTable && 'transition-opacity duration-100 hover:opacity-100',
        (show || draggingBar) && 'opacity-100',
        type === 'corner' && 'w-[18px] h-[18px] bottom-2 pl-0.5',
        type === 'row' && 'h-[18px] bottom-2',
        type === 'column' && 'top-3 w-[18px] pl-0.5'
      )}
      visible={draggingBar || visible}
      popup={
        draggingBar ? (
          <div className="py-1 px-2 font-semibold">
            {`${columns.length} × ${table.subNodes.length}`}
          </div>
        ) : (
          <div className="py-1 px-2">
            <div className="text-center">
              <span className="text-white font-medium mr-1">点击</span>
              <span className="text-grey4">{TEXT[type][0]}</span>
            </div>
            <div className="text-center">
              <span className="text-white font-medium mr-1">拖拽</span>
              <span className="text-grey4">{TEXT[type][1]}</span>
            </div>
          </div>
        )
      }
      {...props}
    >
      <div
        data-disable-select
        className={cx(
          'flex-1 h-4 bg-grey7 flex items-center justify-center',
          draggingBar && 'bg-black/20',
          type === 'corner' && 'rounded-full h-4 cursor-nwse-resize',
          type === 'row' && 'rounded h-4 cursor-row-resize',
          type === 'column' && 'rounded h-full cursor-col-resize'
        )}
        onMouseEnter={() => {
          !draggingInThisTable && setVisible(true);
        }}
        onMouseLeave={() => setVisible(false)}
        onMouseDown={() => {
          dispatch(
            simpleTableActions.update({
              activityTableId: tableId,
              draggingBarType: type,
              chosenCells: undefined,
            })
          );

          const root = document.getElementById('root');
          root?.classList.add(CURSOR[type]);

          dragRef.current.originRows = table.subNodes.slice();
          dragRef.current.maxRows = table.subNodes.slice();

          dragRef.current.originColumns = columns.slice();
          dragRef.current.maxColumns = columns.slice();

          const tableInfo = tableRef.current?.getBoundingClientRect();
          dragRef.current.startX = tableInfo?.right ?? 0;
          dragRef.current.startY = tableInfo?.bottom ?? 0;

          forEachRight(table.subNodes, (uuid, i) => {
            if (dragRef.current.minY) return;
            const block = cache.blocks[uuid];
            if (block) {
              const { collectionProperties = {} } = block.data;
              const hasValue = Object.values(collectionProperties).some(
                (segments) => segments.length
              );
              if (hasValue) {
                const dom = tableRef.current?.querySelector(
                  `[data-table-row-index="${i}"]`
                ) as HTMLElement | null;
                dragRef.current.minY = dom?.getBoundingClientRect().bottom ?? 0;
                const index = Number(dom?.dataset.tableRowIndex);
                const minRowIndex = Math.max(
                  index,
                  ...indexRanges.map((range) => (range[0][0] === index ? range[1][0] : 0))
                );
                dragRef.current.minRowIndex = minRowIndex;
              }
            }
          });

          // 如果 所有行都没值 取第一行
          if (!dragRef.current.minY) {
            const dom = tableRef.current?.querySelector('tr') as HTMLElement | null;
            dragRef.current.minY = dom?.getBoundingClientRect().bottom ?? 0;
          }

          forEachRight(dragRef.current.originColumns, (propertyId, i) => {
            if (dragRef.current.minX) return;

            table.subNodes.some((uuid) => {
              const block = cache.blocks[uuid];
              if (!block) return false;

              const { collectionProperties = {} } = block.data;
              const hasValue = collectionProperties[propertyId]?.length;

              if (hasValue) {
                const dom = tableRef.current?.querySelector(
                  `[data-table-column-index="${i}"]`
                ) as HTMLElement | null;
                dragRef.current.minX = dom?.getBoundingClientRect().right ?? 0;
                const index = Number(dom?.dataset.tableColumnIndex);
                const minColumnIndex = Math.max(
                  index,
                  ...indexRanges.map((range) => (range[0][1] === index ? range[1][1] : 0))
                );
                dragRef.current.minColumnIndex = minColumnIndex;
              }

              return hasValue;
            });
          });

          // 如果 所有列都没值 取第一列
          if (!dragRef.current.minX) {
            const dom = tableRef.current?.querySelector('td') as HTMLElement | null;
            dragRef.current.minX = dom?.getBoundingClientRect().right ?? 0;
          }
        }}
        onClick={(event) => {
          event.stopPropagation();

          setVisible(false);

          if (type === 'corner') {
            bizTracker.event('simpletable_add_click');
          } else {
            bizTracker.event('simpletable_fastadd');
          }

          transaction(() => {
            if (type !== 'column') {
              addBlock({ type: BlockType.TABLE_ROW }, { parentId: tableId, last: true });
            }

            if (type !== 'row') {
              const { format } = table.data;
              const columns = format?.tableBlockColumnOrder ?? [];

              updateBlock(tableId, {
                data: {
                  format: {
                    tableBlockColumnOrder: columns.concat(uuidV4()),
                  },
                },
              });
            }
          });
        }}
      >
        <Icon
          name="IcBtnNew"
          size="xsmall"
          className={cx(draggingBar ? '!text-white' : 'text-grey3')}
        />
      </div>
    </Tooltip>
  );
};
