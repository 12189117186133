import { cx } from '@flowus/common/cx';
import type { ChangeEvent, FC } from 'react';
import { useState } from 'react';
import { Button } from 'src/common/components/button';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { message } from 'src/common/components/message';
import { useOpenModal } from 'src/common/components/next-modal';
import { request } from 'src/common/request';
import { useUpload } from 'src/hooks/space/use-upload';
import { AppealResult } from './appeal-result';
import { getCurrentUser, useCurrentUser } from 'src/hooks/user';

const reasons = [
  '请选择',
  '色情低俗',
  '垃圾广告',
  '造谣传谣',
  '政治不实信息',
  '涉嫌欺诈',
  '涉嫌赌博',
  '涉嫌非法集资',
  '涉暴涉恐',
  '邪教迷信',
  '宣扬不良价值观',
  '内容引人不适',
  '危险行为',
  '涉嫌侵权（交由我司人工审核）',
  '其他违法违规',
];

interface Props {
  // 申诉 justice | 举报 report
  type: 'justice' | 'report';
  blockType?: 'block' | 'form';
  uuid: string;
  onCloseModal(): void;
}

const MAX_TEXTAREA_LENGTH = 200;

export const ShareAudit: FC<Props> = ({ onCloseModal, uuid, type, blockType }) => {
  const openModal = useOpenModal();
  const [info, setInfo] = useState<Required<Parameters<typeof request.editor.shareComplain>[1]>>({
    email: '',
    phone: '',
    complainType: 0,
    description: '',
    pictureLinks: [],
    type: blockType ?? 'block',
  });
  const [pics, setPics] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const uploadFile = useUpload();
  const currentUser = useCurrentUser();

  const IS_JUSTICE = type === 'justice';
  const disabled = IS_JUSTICE
    ? !(info.email.trim() && info.description.trim())
    : !(info.email.trim() && info.complainType !== 0);

  const onChange = (key: keyof typeof info) => (value: string | number) => {
    if (key === 'email' && typeof value === 'string') {
      value = value.replace(/\s+/g, '');
    }
    setInfo((pre) => ({ ...pre, [key]: value }));
  };

  const upload = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList || !fileList[0]) return;
    const file = fileList[0];
    if (file.size >= 1024 * 1024) {
      message.error({ content: `文件已超过 1M 大小` });
      return;
    }
    void uploadFile({
      file,
      type: 'private',
      onComplete: (ret) => {
        if (ret.success) {
          setInfo((pre) => {
            const pics = pre.pictureLinks.slice();
            pics[index] = ret.ossName;
            return { ...pre, pictureLinks: pics };
          });

          setPics((pre) => {
            const newPics = pre.slice();
            newPics[index] = URL.createObjectURL(file);
            return newPics;
          });
        }
      },
    });
  };

  const openResult = (success: boolean) => {
    openModal.modal({
      content: ({ onCloseModal: onClose }) => (
        <AppealResult success={success} email={info.email} type={type} onCloseModal={onClose} />
      ),
    });
  };

  const onSubmit = async () => {
    const final = { ...info, pictureLinks: info.pictureLinks.filter(Boolean) };
    if (disabled || loading) return;
    setLoading(true);
    try {
      if (IS_JUSTICE) {
        const res = await request.editor.shareAppeal.raw(uuid, final);
        if (res.code === 200) {
          onCloseModal();
          openResult(true);
        } else if (res.code === 1111) {
          onCloseModal();
          openResult(false);
        }
      } else {
        const isLogin = !!getCurrentUser().uuid;
        const res = isLogin
          ? await request.editor.shareComplain.raw(uuid, final)
          : await request.editor.shareComplainUnSign.raw(uuid, final);

        if (res.code === 200) {
          onCloseModal();
          openResult(true);
        } else if (res.code === 1110) {
          onCloseModal();
          openResult(false);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-4 width-600 sm:fixed sm:inset-0 sm:w-auto sm:h-auto sm:rounded-none sm:border-none text-t2">
      <div className="text-grey3">{IS_JUSTICE ? '申诉服务' : '举报分享内容'}</div>
      <div className="my-4">针对本文档发起{IS_JUSTICE ? '申诉' : '举报'}</div>
      {!IS_JUSTICE && (
        <div className="flex relative items-center">
          <div className="text-red">*</div>
          <div className="mr-2 ml-1">举报理由</div>
          <select
            value={info.complainType}
            className={cx(
              'flex-1 py-1.5 px-3 border appearance-none rounded-sm border-grey06 bg-white1',
              { 'text-grey2': info.complainType === 0 }
            )}
            onChange={(e) => onChange('complainType')(+e.target.value)}
          >
            {reasons.map((s, i) => (
              <option key={i} value={i}>
                {s}
              </option>
            ))}
          </select>
          <Icon name="IcArrow" size="xxsmall" className="absolute right-3 text-grey4" />
        </div>
      )}

      {IS_JUSTICE ? (
        <div className="flex items-center">
          <div className="mr-1 text-red">*</div>
          <div>申诉理由（如平台发生误判情况，请简要描述误判内容、提供相应材料依据）</div>
        </div>
      ) : (
        <div>举报描述</div>
      )}

      <div className="relative my-2">
        <textarea
          className="py-2 px-3 w-full bg-grey9 rounded border border-grey6 focus-within-border"
          rows={8}
          maxLength={MAX_TEXTAREA_LENGTH}
          onChange={(e) => onChange('description')(e.target.value)}
        ></textarea>
        <div className="absolute right-3 bottom-2">
          {info.description.length} <span className="text-grey3">{`/ ${MAX_TEXTAREA_LENGTH}`}</span>
        </div>
      </div>
      {currentUser.uuid && (
        <>
          <div className="text-grey3">添加图片（单张小于1M，至多上传3张）</div>
          <div className="flex my-2 space-x-5">
            {Array.from(Array(3)).map((o, i) => (
              <label
                key={i}
                className="flex object-contain overflow-hidden justify-center items-center w-9 h-9 bg-grey8 border border-black_006 border-dashed animate-click"
              >
                {pics[i] ? (
                  <img src={pics[i]} className="w-full" />
                ) : (
                  <Icon name="IcBtnNew" size="normal" className="text-grey4" />
                )}
                <input type="file" accept="image/*" className="hidden" onChange={upload(i)} />
              </label>
            ))}
          </div>
        </>
      )}

      <div className="flex space-x-5 sm:block sm:space-x-0">
        <div className="flex-1 mt-4">
          <div>
            <span className="mr-1 text-red">*</span>
            联系邮箱
          </div>
          <Input value={info.email} onChange={onChange('email')} className="mt-2 h-9" />
        </div>
        <div className="flex-1 mt-4">
          <div>手机号</div>
          <Input maxLength={11} onChange={onChange('phone')} className="mt-2 h-9" />
        </div>
      </div>

      <div className="flex justify-end mt-2.5">
        <Button colorType="primary" className="mr-5" disable={disabled} onClick={onSubmit}>
          提交
        </Button>
        <Button onClick={() => onCloseModal()}>取消</Button>
      </div>
    </div>
  );
};
