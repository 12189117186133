import type { Timeout } from 'ahooks/lib/useRequest/src/types';
import { message } from 'src/common/components/message';
import { request } from 'src/common/request';

type Result = Awaited<ReturnType<typeof request.infra.getTasks>>['results'] extends Record<
  string,
  infer T
>
  ? T
  : never;

type Params = Result['result'] extends infer P | undefined ? P : never;

type Fn = (args: Omit<Params, 'status' | 'msg'>) => Promise<void> | void;

interface Callbacks {
  success?: Fn;
  fail?: Fn;
}

const taskIds = new Map<string, Callbacks>();

export const enqueueTask = async (
  body: Parameters<typeof request.infra.enqueueTask>[0],
  callbacks: Callbacks = {}
) => {
  const { taskId } = await request.infra.enqueueTask(body);

  taskIds.set(taskId, callbacks);

  getTask();

  return taskId;
};

let timer: Timeout | null = null;

const getTask = () => {
  if (!taskIds.size) return;

  if (timer) {
    clearTimeout(timer);
  }

  timer = setTimeout(async () => {
    const { results } = await request.infra.getTasks({ taskIds: [...taskIds.keys()] });

    Object.values(results).forEach(handleResult);

    getTask();
  }, 2000);
};

const handleResult = (value: Result) => {
  const { status, taskId, result } = value;

  if (status === 'success') {
    message.closeMessage(taskId);

    if (result) {
      const { status, msg, ...rest } = result;
      const callbacks = taskIds.get(taskId);
      if (status === 'success') {
        void callbacks?.success?.(rest);
      } else if (msg) {
        void callbacks?.fail?.(rest);
        message.error(msg);
      }
    }

    taskIds.delete(taskId);
  }
};
