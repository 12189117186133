import type { FC } from 'react';
import { Divider } from 'src/common/components/divider';
import { Icon } from 'src/common/components/icon';
import { SortableList } from 'src/common/components/sortable-list';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { useBitable } from '../../context';
import { useShowAddViewPanel } from './add-view';
import { ViewItem } from './view-item';

interface Props {
  onClose(): void;
}

export const ViewList: FC<Props> = ({ onClose }) => {
  const { viewParentId, managerReadonly, readonly, isLocked } = useBitable();
  const views = useObservableBlock(viewParentId, (view) => view?.views ?? []);
  const transaction = useTransaction();
  const showAddPanel = useShowAddViewPanel();

  return (
    <div className="w-[240px] next-modal-scroll">
      <SortableList
        disabled={isLocked || readonly || managerReadonly}
        items={views.map((viewId) => ({ id: viewId }))}
        renderItemContent={({ item }) => <ViewItem viewId={item.id} onClose={onClose} />}
        onChange={(views) => {
          transaction(() => {
            updateBlock(viewParentId, { views: views.map((item) => item.id) });
          });
        }}
      />

      {!managerReadonly && !isLocked && (
        <>
          <Divider />
          <button
            className="flex p-2 w-full items-center text-t2 animate-hover"
            onClick={(event) => showAddPanel(event.currentTarget, onClose)}
          >
            <Icon className="mr-1" name="IcAddSmallBold" size="small" />
            创建视图
          </button>
        </>
      )}
    </div>
  );
};
