import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import type { IconName } from 'src/common/components/icon';
import { Icon } from 'src/common/components/icon';
import { ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';
import { useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';

import { AnnotationType } from '../type';

const ShapeType: {
  name: string;
  value: AnnotationType;
  icon: IconName;
}[] = [
  { name: '矩形', value: AnnotationType.RECTANGLE, icon: 'IcPreviewRectangle' },
  { name: '圆形', value: AnnotationType.ELLIPSE, icon: 'IcPreviewEllipse' },
  { name: '不规则形状', value: AnnotationType.POLYGON, icon: 'IcPreviewShape' },
];

interface Props {
  uuid?: string;
  closeModal: () => void;
  operatorType: AnnotationType;
  onChangeOperator: (cursor: AnnotationType) => void;
}

export const ShapeSetting: FC<Props> = ({ operatorType, closeModal, onChangeOperator }) => {
  const items = ShapeType.map((item) => {
    return {
      type: ListItemType.OPERATION,
      data: {
        icon: item.icon,
        title: item.name,
        value: item.value,
        arrow: <Icon name="IcSelectedSpace" size="normal" />,
        hasArrow: item.value === operatorType,
      },
    };
  });

  const handleClick = ({ data }: ListItem<any>) => {
    onChangeOperator(data.value);
    closeModal();
  };

  return (
    <ListView className="next-modal w-[160px] py-[5px]" items={items} onItemClick={handleClick} />
  );
};

interface ShapeButtonProps {
  className?: string;
  operatorType: AnnotationType;
  onChangeOperator: (cursor: AnnotationType) => void;
}
export const ShapeButton: FC<ShapeButtonProps> = ({
  className,
  operatorType,
  onChangeOperator,
}) => {
  const openModal = useOpenModal();

  const openSetting = (event: React.MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom',
      offset: [0, 10],
      content: ({ onCloseModal }) => (
        <ShapeSetting
          closeModal={onCloseModal}
          operatorType={operatorType}
          onChangeOperator={onChangeOperator}
        />
      ),
    });
  };

  const operator = ShapeType.find((item) => item.value === operatorType);

  return (
    <Tooltip placement="bottom" popup="区域高亮" className={className}>
      <button
        className={cx('flex items-center animate-hover-black3 p-1', operator && 'bg-black_006')}
        onClick={openSetting}
      >
        <Icon size="middle" name={operator?.icon ?? 'IcPreviewSelection'} />
        <Icon size="xxsmall" name="IcArrow" className="ml-1" />
      </button>
    </Tooltip>
  );
};
