import { fastEqual } from '@flowus/common';
import { getPermissions as _getPermissions } from '@flowus/common/block/permissions';
import type { Share } from '@flowus/common/block/type';
import { useDeepCompareMemo } from '@react-hookz/web';
import type { FC } from 'react';
import { useMemo, useRef } from 'react';
import { segmentsToText } from 'src/editor/utils/editor';
import { SHARE_CACHES } from 'src/redux/middlewares/cache-lift';
import { cache, getState } from 'src/redux/store';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { $spacesCache } from 'src/services/spaces/spaces';
import { $currentUserCache } from 'src/services/user/current-user';
import { judgeSharePage } from 'src/utils/getPageId';
import { createContext, useContext } from 'use-context-selector';
// import { createContext, useContext } from 'use-context-selector';
import { v4 } from 'uuid';
import { getCurrentSpace } from '../space';
export { ROLE_WEIGHT } from '@flowus/common/block/type';

interface Opt {
  wait?: number;
  waitMode?: 'debounce' | 'throttle';
}

type UsePermissions = (uuid?: string, opt?: Opt) => Share;

const PickBlockContext = createContext<{
  uuid?: string;
  permissions?: Record<string, Share>;
}>({});

export const PermissionsProvider: FC<{ uuid?: string; opt?: Opt }> = ({ uuid = '', children }) => {
  // const curPermissions = useIPermissions(uuid);
  // const permissionContext = useContext(PickBlockContext);

  // const permissions = useMemo(() => {
  //   return { ...permissionContext.permissions, [uuid]: curPermissions };
  // }, [curPermissions, permissionContext, uuid]);

  return (
    // <PickBlockContext.Provider value={{ uuid, permissions }}>
    <>{children}</>
    // </PickBlockContext.Provider>
  );
};

export const usePermissions: UsePermissions = (uuid, opt) => {
  // 避免出现 uuid change 而导致 hook 调用链出错
  // const _uuid = useRef(uuid);
  // const permissionContext = useContext(PickBlockContext);

  // if (_uuid.current && uuid === _uuid.current && permissionContext.permissions?.[_uuid.current]) {
  //   // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  //   return permissionContext.permissions[_uuid.current]!;
  // }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useIPermissions(uuid, opt);
};

// uuid 为必填参数
const useIPermissions: UsePermissions = (uuid, opt) => {
  const { wait, waitMode = 'debounce' } = opt ?? {};

  const _opt = useDeepCompareMemo(() => {
    return {
      waitMode,
      equal: (a: any, b: any) => {
        if (a.cacheVersion && b.cacheVersion && a.cacheVersion === b.cacheVersion) {
          return true;
        }
        return fastEqual(a, b);
      },
      wait: wait ?? (uuid && cache.blocks[uuid] ? 5000 : 100),
      onlyPermissions: true,
    };
  }, [uuid, wait, waitMode]);

  return useObservableStore((state) => getPermissions(uuid, state), [uuid], _opt);
};

export const getPermissions = (uuid = '', state = getState()) => {
  const shareCache = SHARE_CACHES.getShare(uuid);
  if (uuid != null && shareCache) {
    return shareCache;
  }

  const result = _getPermissions(
    {
      ...state,
      user: $currentUserCache,
      permissionGroups: getCurrentSpace().permissionGroups,
      segmentsToText,
      isSharePage: judgeSharePage(),
      spaces: $spacesCache,
    },
    uuid
  );

  // @ts-ignore diff 优化
  result.cacheVersion = v4();

  if (uuid != null) {
    SHARE_CACHES.setShare(uuid, result);
  }

  return result;
};
