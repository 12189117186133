import type { SegmentDTO } from '@next-space/fe-api-idl';
import { TextType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { request } from 'src/common/request';
import { segmentsToText } from 'src/editor/utils/editor';
import { blocksActions } from 'src/redux/reducers/blocks';
import { cache, dispatch } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { isPageLike } from 'src/utils/block-type-utils';

import type { OtherPage, SuggestProps } from '../types';
import { CommonSuggest } from './common-suggest';
import { useSuggest } from './hooks';

export const MentionCurrent: FC<SuggestProps> = ({ uuid, setState }) => {
  const { block, setPages, spaceId, ...rest } = useSuggest({
    uuid,
    setState,
    type: 'mention',
  });

  const title = segmentsToText(block?.data.segments);

  const fetchSuggest = useCallback(async () => {
    if (!title) {
      setPages({});
      return;
    }

    const res = await request.infra.searchDocs(spaceId, {
      page: 1,
      perPage: 100,
      query: title,
    });

    const { recordMap, results } = res;

    const pageSet = new Set<string>();

    results.forEach(({ pageId }) => pageId && pageId !== uuid && pageSet.add(pageId));

    if (recordMap) {
      dispatch(blocksActions.update({ blocks: recordMap.blocks as Record<string, NextBlock> }));

      const other: OtherPage = {};

      [...pageSet].slice(0, rest.isPro ? Infinity : 2).forEach((pageId) => {
        const handleSegments = (_id: string, segments?: SegmentDTO[]) => {
          if (
            !segments ||
            segments.every(({ text, type }) => type === TextType.URL || text.indexOf(title) < 0)
          ) {
            return;
          }

          const data = {
            uuid: _id,
            segments: segments.reduce((_segments, _segment, _i) => {
              const handleText = (segment: SegmentDTO) => {
                const i = segment.text.indexOf(title);
                if (i > -1) {
                  const pre = segment.text.slice(0, i);
                  if (pre) {
                    _segments.push({ ...segment, text: pre });
                  }
                  _segments.push({
                    type: 'suggest' as any,
                    uuid, // 这里与 link-other 不同
                    text: '',
                    enhancer: {},
                  });
                  const last = segment.text.substring(i).replace(title, '');
                  if (last) {
                    handleText({ ...segment, text: last });
                  }
                } else {
                  _segments.push(segment);
                }
              };
              handleText(_segment);

              return _segments;
            }, [] as SegmentDTO[]),
          };

          if (Array.isArray(other[pageId])) {
            other[pageId]?.push(data);
          } else {
            other[pageId] = [data];
          }
        };

        const handleBlock = (_id: string) => {
          const _block = cache.blocks[_id];
          if (!_block || isPageLike(_block.type)) return;
          const { segments, collectionProperties = {} } = _block.data;
          handleSegments(_id, segments);
          Object.keys(collectionProperties).forEach((key) =>
            handleSegments(_id, collectionProperties[key])
          );
        };

        results.filter((o) => o.pageId === pageId).forEach(({ uuid }) => handleBlock(uuid));
      });

      setPages(other);
    }
  }, [rest.isPro, setPages, spaceId, title, uuid]);

  useEffect(() => {
    void fetchSuggest();
  }, [fetchSuggest]);

  return (
    <CommonSuggest type="mention" title="提及当前页面" fetchSuggest={fetchSuggest} {...rest} />
  );
};
