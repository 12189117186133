import type {
  CollectionSchema,
  CollectionViewProperties,
  SegmentDTO,
} from '@next-space/fe-api-idl';
import { BlockType, CollectionSchemaType, CollectionViewType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { segmentsToText, textToSegments } from 'src/editor/utils/editor';
import { useTransaction } from 'src/hooks/use-transaction';
import { addBlock } from 'src/redux/managers/block/add';
import { archiveBlock } from 'src/redux/managers/block/archive';
import { updateBlock } from 'src/redux/managers/block/update';
import * as CollectionViewManager from 'src/redux/managers/collection-view';
import { getState } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { v4 as uuid4 } from 'uuid';

import { selectCollectionGroups } from '../collection-view/table-groups/select-collection-groups';
import { sortRecords } from '../collection-view/use-get-sorted-records';
import { makePropertyName } from './use-create-property';

export const useTableConvert = () => {
  const transaction = useTransaction();

  const tableConvert = (blockId: string) => {
    const state = getState();
    const { blocks, collectionViews, users } = state;
    const block = blocks[blockId];
    if (!block) return;

    transaction(() => {
      if (block.type === BlockType.TABLE) {
        const { tableBlockColumnOrder: propertyIds, tableBlockRowHeader } =
          block?.data.format ?? {};
        if (!propertyIds) return;

        const firstRowId = block.subNodes[0];
        if (!firstRowId) return;
        const firstRowBlock = blocks[firstRowId];
        if (!firstRowBlock) return;

        const schema: Record<string, CollectionSchema> = {};
        const properties: CollectionViewProperties = [];

        propertyIds.forEach((uuid, index) => {
          let name = '';
          if (tableBlockRowHeader) {
            const segments = firstRowBlock.data.collectionProperties?.[uuid];
            name = segmentsToText(segments);
          }

          if (index === 0) {
            schema['title'] = {
              name: name || '标题',
              type: CollectionSchemaType.TITLE,
            };
          } else {
            schema[uuid] = {
              name: makePropertyName(schema, name || undefined),
              type: CollectionSchemaType.TEXT,
            };
          }

          properties.push({ property: index === 0 ? 'title' : uuid, visible: true });
        });

        const recordIds = tableBlockRowHeader ? block.subNodes.slice(1) : block.subNodes;

        // table 转换类型
        updateBlock(blockId, {
          data: { schema },
          type: BlockType.COLLECTION_VIEW,
        });

        // 显示 header 时删除 table 第一行
        if (tableBlockRowHeader) {
          archiveBlock(firstRowId);
        }

        // 记录转换类型
        recordIds.forEach((uuid) => {
          const record = blocks[uuid];
          if (!record) return;

          updateBlock(uuid, {
            type: BlockType.PAGE,
            data: {
              segments: record?.data.collectionProperties?.[propertyIds[0] ?? ''] ?? [],
            },
          });
        });

        CollectionViewManager.add(
          {
            title: '默认视图',
            format: { tableProperties: properties, tableWrap: true },
            pageSort: recordIds,
            type: CollectionViewType.TABLE,
          },
          { parentId: blockId }
        );
      }

      if (block.type === BlockType.COLLECTION_VIEW) {
        const viewId = (block.views ?? [])?.find(
          (uuid) => collectionViews[uuid]?.type === CollectionViewType.TABLE
        );
        const view = collectionViews[viewId ?? ''];
        if (!view || !viewId) return;

        const firstColId = uuid4();
        const tableBlockColumnOrder =
          view.format.tableProperties?.map(({ property }) =>
            property === 'title' ? firstColId : property
          ) ?? [];

        // 记录转换类型
        block.subNodes.forEach((uuid) => {
          const record = blocks[uuid];
          if (!record) return;

          updateBlock(uuid, {
            type: BlockType.TABLE_ROW,
            data: {
              collectionProperties: {
                ...record.data.collectionProperties,
                [firstColId]: record.data.segments ?? [],
              },
            },
          });
        });

        const sortedBlocks = sortRecords({
          collectionId: block.uuid,
          records: block.subNodes
            .map((uuid) => blocks[uuid])
            .filter((item): item is NextBlock => !!item),
          tableSchema: block.data.schema ?? {},
          users,
          sorters: view.format.sorters,
          sort: view.pageSort,
        });
        let ids = sortedBlocks.map((item) => item.uuid);

        const result = selectCollectionGroups({
          blocks,
          collectionViews,
          users,
          viewId,
          sortedRecordIds: ids,
        });
        if (result && !result.withoutValidGroup) {
          const groupIds = result.groups?.map((item) => item.recordIds) ?? [];
          ids = groupIds.flat();
        }

        // collection 转换类型
        updateBlock(blockId, {
          subNodes: ids,
          data: { format: { tableBlockColumnOrder, tableBlockRowHeader: true } },
          type: BlockType.TABLE,
        });

        // 属性添加为一行
        const collectionProperties: Record<string, SegmentDTO[]> = {};
        (view.format.tableProperties ?? [])?.forEach(({ property }) => {
          collectionProperties[property === 'title' ? firstColId : property] = textToSegments(
            block.data.schema?.[property]?.name
          );
        });
        addBlock(
          { data: { collectionProperties }, type: BlockType.TABLE_ROW },
          { first: true, parentId: blockId }
        );

        CollectionViewManager.remove(viewId);
      }
    });
  };

  return useCallback(tableConvert, [transaction]);
};
