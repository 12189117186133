import { cx } from '@flowus/common/cx';
import type { FC, ReactNode } from 'react';
import { Icon } from '../../icon';
import { listViewItemDefaultClassName } from '../helper';
import type { ElementItemProps } from '../types';

interface BlockTextScheme {
  title: string;
  titleSuffix?: ReactNode;
  icon?: ReactNode;
  className?: string;
}

interface OperableBlockTextProps {
  title: string;
  className?: string;
  textClassName?: string;
  selected?: boolean;
  selectColor?: string;
  followTitle?: boolean;
  desc?: string;
}

/**
 * 文字分割块
 */
export const BlockText: FC<ElementItemProps<BlockTextScheme>> = (props) => {
  const dataScheme = props.listItem.data;
  if (!dataScheme) return null;
  return (
    <div
      className={cx(
        listViewItemDefaultClassName,
        'flex items-center text-t2 text-grey3',
        dataScheme.className
      )}
    >
      <div className="flex justify-between items-center w-full">
        {dataScheme.titleSuffix ? (
          <div>
            <span>{dataScheme.title}</span>
            {dataScheme.titleSuffix}
          </div>
        ) : (
          <span>{dataScheme.title}</span>
        )}
        {dataScheme.icon}
      </div>
    </div>
  );
};

/**
 * 可点击文字
 */
export const OperableBlockText: FC<ElementItemProps<OperableBlockTextProps>> = (props) => {
  const dataScheme = props.listItem.data;
  if (!dataScheme) return null;

  return (
    <div
      {...props.attribute}
      className={cx(
        'flex items-center px-2 mx-2 h-9 text-t2 text-black rounded animate-hover cursor-pointer justify-between',
        dataScheme.className,
        { 'normal-bg': props.selected }
      )}
      onClick={(e) => props.onItemClick && props.onItemClick(props.listItem, props.index, e)}
    >
      <span>{dataScheme.title}</span>
      {dataScheme.selected && dataScheme.followTitle && (
        <Icon name="IcSelectedSpace" size="middle" style={{ color: dataScheme.selectColor }} />
      )}

      <span className={cx('text-grey4 text-t2 ml-auto', dataScheme?.textClassName)}>
        {dataScheme.desc}
      </span>

      {dataScheme.selected && !dataScheme.followTitle && (
        <Icon
          name="IcSelectedSpace"
          size="middle"
          className="ml-auto"
          style={{ color: dataScheme.selectColor }}
        />
      )}
    </div>
  );
};
