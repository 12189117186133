import { cx } from '@flowus/common/cx';
import type { FC, ReactNode } from 'react';
import type { BindDataTestId } from 'src/utils/qa-utils';
import { bindDataTestId } from 'src/utils/qa-utils';

import { Icon } from './icon';
import { Tooltip } from './tooltip';

type NextMenuItemPropsType = JSX.IntrinsicElements['div'] &
  BindDataTestId & {
    text: string;
    description?: string;
    active?: boolean;
    showPopup?: boolean;
    icon?: string | ReactNode;
    staticStyle?: boolean;
    activeStyle?: 'bg' | 'icon';
    addonAfter?: ReactNode;
    addonBefore?: ReactNode;
    badge?: ReactNode;
  };

export const NextMenuItem: FC<NextMenuItemPropsType> = (props) => {
  const {
    addonBefore,
    text,
    active,
    onClick,
    staticStyle,
    activeStyle = 'icon',
    addonAfter,
    className,
    description,
    showPopup,
    dataTestId,
    badge,
    ...reset
  } = props;

  return (
    <div
      onClick={onClick}
      {...bindDataTestId(dataTestId)}
      {...reset}
      className={cx(
        'flex relative items-center justify-between h-10 cursor-pointer px-2 rounded',
        staticStyle ? 'text-grey3' : 'text-black',
        activeStyle === 'bg' ? !active && 'animate-hover hover:bg-black_003' : 'animate-hover',
        active && activeStyle === 'bg' && 'normal-bg',
        className
      )}
    >
      <div className="flex items-center w-10/12">
        <span className="mr-2 flex items-center">{addonBefore}</span>
        <div className="w-full text-ellipsis">
          <Tooltip popup={showPopup ? text : ''} className="text-ellipsis block w-fit max-w-full">
            {text}
          </Tooltip>
          {description && (
            <div className="text-ellipsis text-grey3 text-t4 mt-px w-full">{description}</div>
          )}
        </div>
      </div>
      {active && activeStyle === 'icon' && <Icon name="IcSelectedSpace" size="middle" />}
      {addonAfter}
      {badge}
    </div>
  );
};
