import { cx } from '@flowus/common/cx';
import type { PlanCouponDto } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';
import { get } from 'lodash-es';
import type { FC, MouseEvent } from 'react';
import { memo } from 'react';
import { SettingDivider } from 'src/common/components/divider';
import { Icon } from 'src/common/components/icon';
import { useOpenModal } from 'src/common/components/next-modal';
import { Images } from 'src/image';
import type { SpacePlanType } from 'src/redux/types';
import { bizTracker } from 'src/utils/biz-tracker';
import { getCurrencySymbols } from 'src/utils/currency-format';
import type { OpenSettingFrom } from 'src/views/main/setting-modal/type';
import { v4 } from 'uuid';

export interface SelectUpgradePlanProps {
  selectPlanSpaceType: SpacePlanType;
  isFrom: OpenSettingFrom | '';
}

// #region 订单埋点
export const orderBizTracker = (props: {
  bizType:
    | 'order_finish'
    | 'pay_order'
    | 'payorder_show'
    | 'order_finish_file'
    | 'order_finish_ai'
    | 'pay_order_file'
    | 'pay_order_ai';
  order_amount?: number;
  order_month?: number;
  order_channel: string;
  is_from: string;
  order_new?: string;
  order_type?: string;
  coupon_type?: string;
  coupon_amount?: number;
  pay_point?: number;
  order_capacity?: number;
  space_type?: string;
  space_current_type?: string;
  space_target_type?: string;
}) => {
  const { bizType, ...reset } = props;

  bizTracker.event(bizType, { ...reset });
};

export const getCouponTypeByBizTracker = (type?: PlanCouponDto['type']) => {
  if (type) {
    return get(
      {
        firstBuyCycle: 'first',
        stuDiscountCash: 'education',
        limitTimeCycle: 'limit',
      },
      type
    );
  }
};
// #endregion

// #region UI 容器
interface UpgradePaddingUikitProps {
  height?: number;
  className?: string;
  onClick?: (e: MouseEvent) => void;
  hidden?: boolean;
}
export const UpgradePaddingUiKit: FC<UpgradePaddingUikitProps> = (props) => {
  const { children, className, height, ...reset } = props;
  return (
    <div className={cx('flex items-center py-2.5', className)} {...reset} style={{ height }}>
      {children}
    </div>
  );
};
// #endregion

// #region 优惠券
const CouponListModalId = v4();
interface UpgradeCouponProps {
  className?: string;
  couponList?: PlanCouponDto[];
  selectId?: string;
  onSelectId: (id?: string) => void;
}
/** 优惠券 */
export const UpgradeCouponList: FC<UpgradeCouponProps> = memo((props) => {
  const openModal = useOpenModal();
  const { className, couponList = [], selectId, onSelectId } = props;
  const curCoupon = couponList.find((it) => it.id === selectId);
  const couponCount = couponList.length;

  const openCouponList = (event: MouseEvent) => {
    openModal.dropdown({
      modalId: CouponListModalId,
      popcorn: event.currentTarget,
      offset: [0, 10],
      content: () => (
        <SelectCouponList couponList={couponList} selectId={selectId} onSelectId={onSelectId} />
      ),
    });
  };

  return (
    <div className={className}>
      <SettingDivider />
      <UpgradePaddingUiKit height={36} className="py-2 justify-between text-t2 space-x-2.5">
        <div className="flex items-center flex-shrink-0">
          <Icon name={'IcTicket'} size="middle" />
          <span className="mx-2">优惠券</span>
          <span
            hidden={couponCount <= 1}
            className={cx('text-t4 text-grey3', couponCount && 'text-red')}
          >{`${couponCount} 张可用`}</span>
        </div>
        <div onClick={openCouponList} className="flex items-center cursor-pointer h-full">
          {!curCoupon ? (
            <span className={cx(couponCount && 'text-red')}>
              {couponCount ? `${couponCount} 张可用` : '暂无可用'}
            </span>
          ) : (
            <div className="line-clamp-1 space-x-1">
              <span>{curCoupon.name}</span>
              <span className="bg-red px-1.5 rounded text-white-base">
                {curCoupon.resources?.prefix ? (
                  <span>{curCoupon.resources.prefix}</span>
                ) : (
                  <>
                    {curCoupon.headerShowType === CouponShowType.cash && (
                      <>{`-¥${curCoupon.salePrice}`}</>
                    )}
                    {curCoupon.headerShowType === CouponShowType.discount && (
                      <>{`${(curCoupon.saleDiscountRate ?? 0) * 10}折`}</>
                    )}
                    {curCoupon.headerShowType === CouponShowType.month && (
                      <>{`赠${curCoupon.monthNum}月`}</>
                    )}
                    {curCoupon.headerShowType === CouponShowType.days && (
                      <>{`赠${curCoupon.dayNum}天`}</>
                    )}
                  </>
                )}
              </span>
            </div>
          )}
          <Icon name="IcArrow" size="xxxsmall" className="ml-1" />
        </div>
      </UpgradePaddingUiKit>
      <SettingDivider />
    </div>
  );
});

// 优惠券列表
const CouponBgColor: Record<PlanCouponDto['showType'], string> = {
  integralRecord: 'linear-gradient(270deg, rgba(249, 139, 42, 0.5) 0%, rgba(249, 139, 42, 0) 100%)',
  education: 'linear-gradient(270deg, rgba(193, 50, 135, 0.1) 0%, rgba(193, 50, 135, 0) 100%)',
  limitTime: 'linear-gradient(270deg, rgba(0, 92, 175, 0.1) 0%, rgba(0, 92, 175, 0) 100%)',
  activity: 'linear-gradient(270deg, rgba(17, 187, 136, 0.1) 0%, rgba(17, 187, 136, 0) 100%)',
};
const SelectCouponList: FC<UpgradeCouponProps> = (props) => {
  const { onSelectId, couponList = [], selectId } = props;
  const openModal = useOpenModal();

  const close = () => {
    openModal.closeModal(CouponListModalId);
  };

  const selectIcon = (
    <Icon
      name="IcSelectedSpace"
      size="middle"
      className={cx(
        'absolute -top-px -right-px rounded-tr rounded-bl bg-black-base text-white-base',
        !selectId && 'top-2.5 right-4 rounded'
      )}
    />
  );

  return (
    <div className="next-modal py-1.5 w-[360px] text-t2-medium">
      <UpgradePaddingUiKit
        className="mx-2 px-2 cursor-pointer relative animate-click animate-hover"
        onClick={() => {
          onSelectId();
          close();
        }}
        height={40}
      >
        不使用优惠券
        {!selectId && selectIcon}
      </UpgradePaddingUiKit>
      <SettingDivider />
      <div className="overflow-y-auto max-h-56 px-4 pt-2.5">
        <CouponList
          couponList={couponList}
          selectId={selectId}
          onSelectId={(id: string) => {
            onSelectId(id);
            close();
          }}
        />
        {!couponList.length && (
          <UpgradePaddingUiKit height={40} className="cursor-not-allowed">
            暂无可用优惠券
          </UpgradePaddingUiKit>
        )}
      </div>
    </div>
  );
};

enum CouponShowType {
  cash,
  discount,
  month,
  days,
}
export const CouponList: FC<{
  couponList: PlanCouponDto[];
  onSelectId?: (id: string) => void;
  selectId?: string;
}> = (props) => {
  const { couponList = [], onSelectId, selectId } = props;

  const selectIcon = (
    <Icon
      name="IcSelectedSpace"
      size="middle"
      className={cx(
        'absolute -top-px -right-px rounded-tr rounded-bl bg-black-base text-white-base',
        !selectId && 'top-2.5 right-4 rounded'
      )}
    />
  );

  return (
    <>
      {couponList.map((item) => (
        <div
          key={item.id}
          className={
            'relative flex items-center py-2.5 px-4 border border-grey6 rounded cursor-pointer mb-2.5 min-h-[100px] animate-click'
          }
          style={{ background: CouponBgColor[item.showType] }}
          onClick={() => onSelectId?.(item.id)}
        >
          {selectId === item.id && selectIcon}
          <div className="min-w-[60px] text-center text-h2 whitespace-nowrap">
            {item.resources?.prefix ? (
              <span>{item.resources?.prefix}</span>
            ) : (
              <>
                {item.headerShowType === CouponShowType.cash && (
                  <>
                    <span className="text-t2-medium">{getCurrencySymbols(item.priceUnit)}</span>
                    <span>{item.salePrice}</span>
                  </>
                )}
                {item.headerShowType === CouponShowType.discount && (
                  <span>{(item.saleDiscountRate ?? 0) * 10}折</span>
                )}
                {item.headerShowType === CouponShowType.month && <span>{item.monthNum}月</span>}
                {item.headerShowType === CouponShowType.days && <span>{item.dayNum}天</span>}
              </>
            )}
          </div>

          <div className="ml-5 dark:text-white-base space-y-1">
            <div className="text-t2-medium">{item.name}</div>
            <div className="text-t4 text-grey3 dark:text-grey1 whitespace-pre-line">
              {item.description?.replace('\\n', '\n')}
            </div>
            <div className="text-t4-medium text-grey3 dark:text-grey1">{item.subtitle}</div>
            <div className="text-t4-medium text-grey3 dark:text-grey1">
              {'活动截止'}
              {dayjs(item.expirationDate).format('YYYY年MM月DD日')}
            </div>
          </div>
          {item.showType === 'integralRecord' && (
            <img src={Images.star} className="absolute right-0 opacity-30 pointer-events-none" />
          )}
        </div>
      ))}
    </>
  );
};

// #endregion

// #region UI 角标
export const UpgradeLabel: FC<{ className?: string; bgColor?: string }> = (props) => {
  const { children, className, bgColor } = props;
  return (
    <span
      className={cx(
        'flex absolute -top-0.5 -left-0.5 justify-center items-center py-0.5 px-1.5 text-white-base rounded-br-md rounded-tl-md text-t5 z-10',
        className
      )}
      style={{ background: bgColor || 'linear-gradient(270.32deg, #FF4E47 0.24%, #FC938F 96.14%)' }}
    >
      {children}
    </span>
  );
};
// #endregion
