import { cx } from '@flowus/common/cx';
import type { FC, MutableRefObject } from 'react';
import { useRef } from 'react';
import { Icon } from 'src/common/components/icon';
import { useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { TABLE_CELL_MENU } from 'src/common/const';
import { simpleTableActions } from 'src/redux/reducers/simple-table';
import { uiActions } from 'src/redux/reducers/ui';
import { dispatch } from 'src/redux/store';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { TableCellMenu } from './table-cell-menu';
import { useTableContext } from './table-context';

interface Props {
  propertyId: string;
  rowIndex: number;
  columnIndex: number;
  isRowHandle?: boolean;
}

export const TableCellHandle: FC<Props> = ({ propertyId, rowIndex, columnIndex, isRowHandle }) => {
  const { recordId, tableId } = useTableContext();

  const showRowHandle = isRowHandle || (rowIndex !== 0 && columnIndex === 0);

  const isHovering = useObservableStore(
    ({ simpleTable }) => {
      const { activityTableId, hoveringCell } = simpleTable;
      if (!hoveringCell) return false;
      if (tableId !== activityTableId) return false;
      return showRowHandle
        ? hoveringCell.rowIndex === rowIndex
        : hoveringCell.columnIndex === columnIndex;
    },
    [rowIndex, columnIndex],
    { obsSimpleTable: true }
  );

  const { isSelected, isDragging, isSelecting } = useObservableStore(
    ({ simpleTable }) => {
      const getIsSelected = () => {
        const { chosenCells } = simpleTable;
        if (!chosenCells || chosenCells.tableId !== tableId) return false;
        return showRowHandle
          ? chosenCells.recordId === recordId
          : chosenCells.propertyId === propertyId;
      };
      return {
        isSelected: getIsSelected(),
        isDragging: !!simpleTable.draggingStart,
        isSelecting: !!simpleTable.selecting,
      };
    },
    [tableId, propertyId, recordId],
    { obsSimpleTable: true }
  );
  const handleRef = useRef() as MutableRefObject<HTMLDivElement>;
  const openModal = useOpenModal();

  if (isSelecting) return null;
  if (showRowHandle ? columnIndex > 0 : rowIndex > 0) return null;
  if (!isHovering && !isSelected) return null;

  return (
    <div
      ref={handleRef}
      data-disable-select
      data-no-cancel-selected
      onClick={() => {
        openModal.dropdown({
          modalId: TABLE_CELL_MENU,
          popcorn: handleRef.current,
          placement: 'bottom-start',
          offset: [16, -2],
          content({ onCloseModal }) {
            return (
              <TableCellMenu
                type={showRowHandle ? 'row' : 'column'}
                recordId={recordId}
                propertyId={propertyId}
                isFirstRow={!!isRowHandle}
                isFirstColumn={columnIndex === 0 && rowIndex === 0 && !isRowHandle}
                tableId={tableId}
                onCloseModal={() => onCloseModal()}
              />
            );
          },
        });
      }}
      onMouseDown={(event) => {
        event.stopPropagation();
        dispatch(uiActions.update({ selectedCells: [], selectedBlocks: [] }));
        dispatch(
          simpleTableActions.update({
            activityTableId: tableId,
            chosenCells: showRowHandle ? { tableId, recordId } : { tableId, propertyId },
          })
        );
      }}
      className={cx(
        'table-handle shadow-button absolute z-10',
        showRowHandle ? 'top-1/2 -left-2.5 -mt-[13px]' : '-top-[13px] left-1/2 -ml-2 rotate-90',
        isSelected ? 'table-selector bg-black dark:bg-grey6' : 'bg-white1 hover:bg-grey8',
        isDragging && 'opacity-0'
      )}
    >
      <Tooltip
        placement="top"
        offset={[0, 8]}
        popup={
          <div className="py-1 px-2 text-center font-semibold">
            <div>长按拖拽</div>
            <div>点击打开菜单</div>
          </div>
        }
      >
        <Icon
          name="IcBlockHandle"
          className={cx(isSelected ? 'text-white' : 'text-grey4')}
          size="xxsmall"
        />
      </Tooltip>
    </div>
  );
};
