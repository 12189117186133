import { CollectionViewType } from '@next-space/fe-api-idl';
import { getSortedRecordIds } from 'src/hooks/collection-view/use-get-sorted-records';
import { uiActions } from 'src/redux/reducers/ui';
import { cache, dispatch, getState } from 'src/redux/store';
import type { SelectCell } from 'src/redux/types';

/** 当框选或者shift+click的时候，这个方法负责处理cell的多选 */
export const handleLastMouseEventInCells = (
  mouseEvent: MouseEvent,
  opts?: { onlyYAxis?: boolean; pointerCaptured?: boolean }
) => {
  // mouseEvent为最后一次点击或者鼠标框选移动的事件
  let target = mouseEvent.target as HTMLElement | null;
  if (!target) return;

  if (opts?.pointerCaptured) {
    target = document.elementFromPoint(
      mouseEvent.clientX,
      mouseEvent.clientY
    ) as HTMLElement | null;
    if (!target) return;
  }

  if (target.closest('[data-disable-select], [data-draggable]')) return;

  if (mouseEvent.type === 'mousedown') {
    window.getSelection().removeAllRanges();
    document.dispatchEvent(new Event('_clearTextSelection'));
  }

  const focusedCell = cache.ui.selectedCells[0];
  if (!focusedCell) return; // it's impossible
  const { recordId, propertyId, viewId, groupValue } = focusedCell;

  let selectorScope = `[data-view-id="${viewId}"]`;
  if (groupValue) {
    selectorScope += `[data-group-value="${groupValue}"]`;
  }

  let cellElement = target.closest(`${selectorScope} [data-property-id]`) as HTMLElement | null;
  let recordElement = cellElement?.closest('[data-block-id]') as HTMLElement | null;

  if (!cellElement || !recordElement) {
    const blockListContainer = document.querySelector(`.block-list${selectorScope}`);
    const recordNodes = blockListContainer?.querySelectorAll('[data-block-id]') as
      | NodeListOf<HTMLElement>
      | undefined;
    if (!recordNodes) return;

    // 如果光标移动到table外的话，需要通过xy算出距离最近的record和cell
    let diff = Number.MAX_SAFE_INTEGER;
    // 光标在table外时算出最后选中的record
    for (const recordNode of recordNodes) {
      const { top, bottom } = recordNode.getBoundingClientRect();
      const distance = Math.abs(top - mouseEvent.clientY);
      if (distance < diff) {
        diff = distance;
        recordElement = recordNode;
      }
      if (mouseEvent.clientY >= top && mouseEvent.clientY <= bottom) {
        recordElement = recordNode;
        break;
      }
    }

    if (!recordElement) return;
    diff = Number.MAX_SAFE_INTEGER;
    const propertyNodes = recordElement.querySelectorAll(
      '[data-property-id]'
    ) as NodeListOf<HTMLElement>;
    // 光标在table外时算出最后选中的property列
    for (const propertyNode of propertyNodes) {
      const { left, right } = propertyNode.getBoundingClientRect();
      const distance = Math.abs(left - mouseEvent.clientX);
      if (distance < diff) {
        diff = distance;
        cellElement = propertyNode;
      }
      if (mouseEvent.clientX >= left && mouseEvent.clientX <= right) {
        cellElement = propertyNode;
        break;
      }
    }
  }

  if (!cellElement || !recordElement) return;

  const sortedRecordIds = getSortedRecordIds({ viewId, groupValue, ignoreSearch: false });

  const { collectionViews } = getState();
  const view = collectionViews[viewId];
  const isTimeline = view?.type === CollectionViewType.TIMELINE;
  const propertyIds = (
    isTimeline ? view?.format.timelineTableProperties : view?.format.tableProperties
  )
    ?.filter((property) => property.visible)
    .map((item) => item.property);
  if (!propertyIds) return;

  const focusedCellPropertyIndex = propertyIds.findIndex((id) => id === propertyId);
  const focusedSelectedRecordIndex = sortedRecordIds.findIndex((rowId) => rowId === recordId);
  // 计算 cell 起始位置索引 结束

  const lastSelectedPropertyId = cellElement.dataset.propertyId;
  const { blockId: lastSelectedRecordId, syncId } = recordElement.dataset;
  const newSelectedPropertyIndex = opts?.onlyYAxis
    ? focusedCellPropertyIndex
    : propertyIds.findIndex((id) => id === lastSelectedPropertyId);
  const newSelectedRecordIndex = sortedRecordIds.findIndex(
    (rowId) => rowId === lastSelectedRecordId
  );
  if (newSelectedPropertyIndex === -1 || newSelectedRecordIndex === -1) return;
  // 从上到下选中record
  const isTopToBottom = newSelectedRecordIndex > focusedSelectedRecordIndex;
  // 从左到右选中cell
  const isLeftToRight = newSelectedPropertyIndex > focusedCellPropertyIndex;
  const selectedCells: SelectCell[] = [];
  // 算出多选时，从开始到结束的 行与列
  // 如果你对下面这段逻辑有疑惑，可以找我讨论一下 --> by lxw
  for (
    let recordIndex = focusedSelectedRecordIndex;
    isTopToBottom ? recordIndex <= newSelectedRecordIndex : recordIndex >= newSelectedRecordIndex;
    isTopToBottom ? recordIndex++ : recordIndex--
  ) {
    for (
      let propertyIndex = focusedCellPropertyIndex;
      isLeftToRight
        ? propertyIndex <= newSelectedPropertyIndex
        : propertyIndex >= newSelectedPropertyIndex;
      isLeftToRight ? propertyIndex++ : propertyIndex--
    ) {
      const propertyId = propertyIds[propertyIndex];
      const recordId = sortedRecordIds[recordIndex];
      if (propertyId && recordId) {
        selectedCells.push({
          propertyId,
          recordId,
          viewId,
          syncId,
          groupValue, // 选择 cell 时一定是同一组或者未分组
        });
      }
    }
  }

  dispatch(uiActions.update({ selectedCells }));
};
