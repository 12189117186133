import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { useBitable } from 'src/bitable/context';
import { Icon } from 'src/common/components/icon';
import { UNTITLED } from 'src/common/const';
import { TITLE_EDITOR_PLUGINS } from 'src/editor/editor/uikit/editable/plugins';
import { RichText } from 'src/editor/editor/uikit/editable/rich-text';
import { useShowTimelineTable } from 'src/hooks/collection-view/use-collection-view';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { NewCardPlaceholderId, UnitWidthMap } from '../const';
import { useTimeline } from '../context';
import { getUnitLength } from '../utils/get-timeline-dates';

interface Props {
  recordId: string;
  initDateRangeLength: number;
  movingStartDate?: number;
}
export const PlaceholderCard: FC<Props> = ({ initDateRangeLength, movingStartDate, recordId }) => {
  const { timelineDates, zoomLevel } = useTimeline();
  const { viewId } = useBitable();
  const isShowTable = useShowTimelineTable(viewId);
  const titleSegment = useObservableBlock(recordId, (block) => block?.data.segments);
  const width = UnitWidthMap[zoomLevel] * initDateRangeLength;

  if (recordId === NewCardPlaceholderId) {
    if (isShowTable && !movingStartDate) return null;

    return (
      <div
        className={cx(
          'h-[34px] top-0.5 rounded flex items-center whitespace-nowrap pr-2 pl-1',
          movingStartDate
            ? 'absolute bg-black_006 pointer-events-none'
            : 'sticky left-24 text-grey3'
        )}
        style={{
          width,
          left: movingStartDate
            ? getUnitLength(movingStartDate, timelineDates[0] as number, zoomLevel) *
              UnitWidthMap[zoomLevel]
            : undefined,
        }}
      >
        <Icon name="IcAddBoard" size="middle" className="mr-1.5" />
        <span className="text-t2">创建页面</span>
      </div>
    );
  }

  if (!movingStartDate) return null;

  return (
    <div
      className="absolute h-[34px] top-0.5 bg-black_006 rounded pointer-events-none px-1.5 flex items-center whitespace-nowrap"
      style={{
        width,
        left:
          getUnitLength(movingStartDate, timelineDates[0] as number, zoomLevel) *
          UnitWidthMap[zoomLevel],
      }}
    >
      <RichText
        className="inline text-t2-medium"
        plugins={TITLE_EDITOR_PLUGINS}
        segments={titleSegment}
        placeholderColor="var(--black)"
        placeholder={UNTITLED}
        interactable={false}
      />
    </div>
  );
};
