import { deepEqual } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import { CollectionViewType } from '@next-space/fe-api-idl';
import type { FC, MouseEvent, RefObject } from 'react';
import { memo, useMemo, useRef } from 'react';
import { useBitable } from 'src/bitable/context';
import { List } from 'src/common/components/virtual';
import { BlockDrop } from 'src/editor/editor/plugin/dnd/block-drop';
import { useFreezeColumnIndex } from 'src/hooks/collection-view/use-collection-view';
import { useReadonly } from 'src/hooks/page';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useGetPageId } from 'src/utils/getPageId';
import { useHScrollRef } from 'src/views/main/page-doc/context';
import type { CollectionProperty } from '../../bitable-manager/property-list';
import { DEFAULT_ROW_HEIGHT, getPropertyWidth } from '../../const';
import { Cell } from '../cell';
import { Site } from '../cell/types';
import { FreezeContext } from './freeze-context';
import { RowHiddenCheckBox } from './hidden-checkbox';
import { RowContext } from './row-context';

interface Props {
  index: number;
  recordId: string;
  groupProperty?: string;
  groupValue?: string;
  tableCellWrap?: boolean;
  properties: CollectionProperty[];
  onClick?: (recordId: string, event: MouseEvent) => void;
  settingIcon?: JSX.Element;
}

export const ListRow: FC<Props> = memo((props) => {
  const {
    recordId,
    groupProperty,
    groupValue,
    tableCellWrap = false,
    properties,
    onClick,
    settingIcon,
    index,
  } = props;
  const { viewId, viewType, relationEditor = false, embed, collectionId } = useBitable();
  const hScrollRef = useHScrollRef() as RefObject<HTMLElement>;
  const blockRef = useRef<HTMLDivElement>(null);
  const isTimeline = viewType === CollectionViewType.TIMELINE;
  const { illegal } = usePermissions(recordId);
  const readonly = useReadonly(recordId, !!embed);
  const collectionReadonly = useReadonly(collectionId, false);
  const pageId = useGetPageId();
  const freeColumnIndex = useFreezeColumnIndex(viewId, properties);
  const freezeProperties = useMemo(
    () => properties.slice(0, freeColumnIndex + 1),
    [freeColumnIndex, properties]
  );
  const restProperties = useMemo(
    () => properties.slice(freeColumnIndex + 1),
    [freeColumnIndex, properties]
  );
  const beforeAppendChild = useMemo(
    () => (
      <>
        {!isTimeline && !settingIcon && !collectionReadonly && (
          <RowHiddenCheckBox uuid={recordId} viewId={viewId} groupValue={groupValue} />
        )}
      </>
    ),
    [collectionReadonly, groupValue, isTimeline, recordId, settingIcon, viewId]
  );

  const contextValue = useMemo(() => {
    return { illegal, pageId, readonly: readonly || relationEditor, index };
  }, [illegal, index, pageId, readonly, relationEditor]);

  return (
    <BlockDrop
      id={recordId}
      blockRef={blockRef}
      groupValue={groupValue}
      viewId={viewId}
      className={cx(
        'relative group flex w-full min-w-max print:w-fit border-b',
        relationEditor && 'hover:bg-black_006 cursor-pointer pl-2'
      )}
      style={{
        minHeight: DEFAULT_ROW_HEIGHT,
        height: tableCellWrap ? undefined : DEFAULT_ROW_HEIGHT,
      }}
      onClick={(event) => {
        onClick?.(recordId, event);
      }}
    >
      <RowContext.Provider value={contextValue}>
        {settingIcon}
        {freezeProperties.length === 0 && beforeAppendChild}
        {tableCellWrap ? (
          <>
            {freezeProperties.length > 0 && (
              <>
                <FreezeContext.Provider value={true}>
                  <div className="sticky-column flex sticky left-0 bg-white1 z-[10]">
                    {beforeAppendChild}
                    {freezeProperties.map((property) => {
                      const width = getPropertyWidth(property);
                      return (
                        <Cell
                          key={property.property}
                          style={{ width }}
                          site={Site.CELL}
                          recordId={recordId}
                          propertyId={property.property}
                          groupProperty={groupProperty}
                          groupValue={groupValue}
                        />
                      );
                    })}
                    {/* <FreezeColumnLineTip /> */}
                  </div>
                </FreezeContext.Provider>
              </>
            )}
            {restProperties.map((property) => {
              const width = getPropertyWidth(property);

              return (
                <Cell
                  key={property.property}
                  style={{ width }}
                  site={Site.CELL}
                  recordId={recordId}
                  propertyId={property.property}
                  groupProperty={groupProperty}
                  groupValue={groupValue}
                />
              );
            })}
          </>
        ) : (
          <List
            beforeAppendChild={freeColumnIndex === -1 ? null : beforeAppendChild}
            stickyIndex={freeColumnIndex}
            virtualized={false}
            direction="horizontal"
            bufferSize={1}
            scrollRef={hScrollRef}
            count={properties.length}
            getItemSize={(index) => {
              const property = properties[index];
              return getPropertyWidth(property);
            }}
            getItemKey={(index) => properties[index]?.property ?? ''}
            renderItem={(index) => {
              const property = properties[index];
              if (!property) return;

              const width = getPropertyWidth(property);
              return (
                <Cell
                  key={property.property}
                  style={{ width }}
                  site={Site.CELL}
                  recordId={recordId}
                  propertyId={property.property}
                  groupProperty={groupProperty}
                  groupValue={groupValue}
                  isLastCell={index === properties.length - 1}
                />
              );
            }}
          />
        )}

        {!isTimeline && (
          <div className="flex-1 flex-shrink-0 print:hidden">
            <div className="w-12" />
          </div>
        )}
      </RowContext.Provider>
    </BlockDrop>
  );
}, deepEqual);
