/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Uuid } from '@flowus/common';
import { BlockType } from '@next-space/fe-api-idl';
import { message } from 'src/common/components/message';
import { useOpenModal } from 'src/common/components/next-modal';
import { request } from 'src/common/request';
import { downloadUrlFile } from 'src/common/utils/download-utils';
import { getUrlWithoutDomain } from 'src/common/utils/url-utils';
import { cache } from 'src/redux/store';
import { $downloadManager } from 'src/services/download-manager';
import { $currentUserCache } from 'src/services/user/current-user';
import { SearchParams, ViewPath } from 'src/utils';
import { getSpacePlanTypeName } from 'src/utils/block-utils';
import { isFlowUsApp } from 'src/utils/electron-util';
import { judgeSharePage } from 'src/utils/getPageId';
import { splicePathSearchParams } from 'src/utils/history-utils';
import { locationToUrl } from 'src/utils/location-utils';
import { OpenSettingFrom } from 'src/views/main/setting-modal/type';
import { useOpenUpgradeSpace } from 'src/views/main/setting-modal/use-open-setting-modal';
import { getLargeVersion, useAppVersion } from '../public/use-version';
import { getCurrentSpace } from '../space';
import { getSpaceBySpaceId } from '../space/get-space';
import { getIsFreeSpaceLimit } from '../space/use-is-pro-space';
import { useOpenDownloadDesktopDialog } from '../space/use-open-download-desktop-dialog';
import { useDownloadFile } from './use-download';

/** 下载文件 */
export const useMultipleDownload = () => {
  const openModal = useOpenModal();
  const openUpgradeSpace = useOpenUpgradeSpace();
  const downloadFile = useDownloadFile();
  const openDownloadDesktopDialog = useOpenDownloadDesktopDialog();
  const appVersion = useAppVersion();

  return async (ids: string[]) => {
    ids = ids.filter((i) => {
      const item = cache.blocks[i];
      if (!item) return false;
      if ([BlockType.FOLDER, BlockType.FILE, BlockType.EXTERNAL_FILE].includes(item.type)) {
        return true;
      }
      return false;
    });

    const first = ids[0];

    if (!first) {
      message.error('没有可下载的内容');
      return;
    }

    // 单个下载
    if (ids.length === 1 && cache.blocks[first]?.type !== BlockType.FOLDER) {
      void downloadFile(first);
      return;
    }

    const spaceId = cache.blocks[first ?? '']?.spaceId ?? getCurrentSpace().uuid;

    // 批量下载
    if (!getIsFreeSpaceLimit(spaceId)) {
      // 批量下载需要先登录
      if (!$currentUserCache.uuid) {
        message.warning('批量下载需要先登录');
        // 跳去登录
        const path = splicePathSearchParams(ViewPath.login, [
          {
            key: SearchParams.callbackPath,
            value: location.pathname,
          },
          {
            key: SearchParams.quickRegister,
            value: 'true',
          },
        ]);
        setTimeout(() => {
          locationToUrl(`${getUrlWithoutDomain()}${path}`);
        }, 2500);
        return;
      }

      if (isFlowUsApp.check) {
        const version = getLargeVersion(appVersion);
        // 小于1.15版本的客户端请求后端并没有报错，需要前端自己做判断
        if (!appVersion || version < 115) {
          openDownloadDesktopDialog(true);
          return;
        }

        const batchResult = await request.editor.blocksOssFileBatch({
          userId: $currentUserCache.uuid,
          spaceId,
          blockIds: ids,
        });
        const items = batchResult.blocks.flatMap((it) => {
          if (it.id == null || it.ossName == null || it.title == null) return [];
          return [
            {
              blockId: it.id,
              ossName: it.ossName,
              fileName: it.title,
            },
          ];
        });
        const urls = (await request.infra.createUrls({ batch: items })) as { url: string }[];
        const items2 = items.map((item, index) => {
          return {
            ...item,
            url: urls[index]!.url,
          };
        });
        await $downloadManager.downloadMany({ id: Uuid.v4(), list: items2 });
      } else {
        const { modalId } = openModal.loading({ title: '文件打包中' });
        const res = await request.editor.filesDownloadBlocksOssZip.raw({
          userId: $currentUserCache.uuid,
          spaceId,
          blockIds: ids,
        });

        if (res.code === 200) {
          const fileUrlRes = res.data;
          void downloadUrlFile(fileUrlRes.url, fileUrlRes.name);
          message.success('打包成功');
          openModal.closeModal(modalId);
          return;
        }

        // @ts-ignore code check
        if (res.code === 3223) {
          openDownloadDesktopDialog(false);
          openModal.closeModal(modalId);
          return;
        }
        message.success('失败');
        openModal.closeModal(modalId);
      }
    } else {
      if (judgeSharePage()) {
        // 分享页仅提示
        message.warning('批量下载仅面向个人专业版/团队版空间,你可联系空间所有者进行升级');
        return;
      }
      openModal.warning({
        maxWidth: 360,
        title: '批量下载仅面向个人专业版/团队版空间',
        content: `当前空间为${getSpacePlanTypeName(getSpaceBySpaceId(spaceId).planType)}`,
        confirm: () => openUpgradeSpace(OpenSettingFrom.bulk_download),
        colorType: 'active',
        confirmText: '立即升级',
        cancelText: '暂不考虑',
      });
    }
  };
};
