import type { FC } from 'react';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { $searchParams } from 'src/utils';
import { Content } from './content';
import type { Context } from './context';
import { ListProvider, useList } from './context';
import { cx } from '@flowus/common/cx';
import { FreezeContext } from 'src/bitable/table-view/body/freeze-context';

export type Props = Omit<Context, 'contentRef'> & {
  virtualized?: boolean;
  stickyIndex?: number;
  stickyClassName?: string;
  beforeAppendChild?: React.ReactNode;
  afterAppendChild?: React.ReactNode;
};
export type ListT = ReturnType<typeof useList> | undefined;

export const List: FC<Props> = forwardRef<ListT, Props>((props, ref) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { virtualized = true, stickyIndex = -1, stickyClassName } = props;
  return $searchParams.print || !virtualized ? (
    stickyIndex >= 0 ? (
      <>
        <FreezeContext.Provider value={true}>
          <div
            className={cx(
              'flex bg-white1 items-center sticky left-0 z-[10] h-full',
              stickyClassName
            )}
          >
            {props.beforeAppendChild}
            {Array.from(Array(stickyIndex + 1)).map((_, i) => props.renderItem(i))}
            {props.afterAppendChild}
          </div>
        </FreezeContext.Provider>
        {Array.from(Array(props.count - stickyIndex + 1)).map((_, i) =>
          props.renderItem(i + stickyIndex + 1)
        )}
      </>
    ) : (
      <>{Array.from(Array(props.count)).map((_, i) => props.renderItem(i))}</>
    )
  ) : (
    <ListProvider {...props} contentRef={contentRef}>
      <ListRef ref={ref} />
      <Content ref={contentRef}>{props.children}</Content>
    </ListProvider>
  );
});

const ListRef = forwardRef<ListT>((_props, ref) => {
  const list = useList();
  useImperativeHandle(ref, () => list);
  return null;
});
