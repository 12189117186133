import { debounce } from 'lodash-es';
import { observableStoreOp } from './map';

// 清空没地方监听的 obs
export const globalRxTimer = {
  run: debounce(() => {
    const map = observableStoreOp.getMap();
    map.forEach((item, key) => {
      const observed = item.observed.valueOf();
      if (!observed) {
        item.unsubscribe();
        observableStoreOp.deleteObs(key);
      }
    });
  }, 3000),
};

// 清空没地方监听的 obs
// 暂时没用了
// export const globalRxTimer = {
//   run: debounce(() => {
//     const map = observableStoreOp.getMap();
//     map.values.forEach((_, key) => {
//       const count = map.countMap.get(key);
//       if (!count) {
//         map.values.delete(key);
//         map.countMap.delete(key);
//       }
//     });
//   }, 3000),
// };
