import { fastEqual } from '@flowus/common/utils/tools';
import { createCache, createSetState, createStore } from '@flowus/common/zustand/utils';
import type { SpaceDTO } from '@next-space/fe-api-idl';
import produce from 'immer';
import { assign, keys } from 'lodash-es';
import { shareInvalidateAll, tocInvalidateAll } from 'src/redux/middlewares/cache-lift';

type SpacesStateType = Record<string, SpaceDTO>;
export const $spacesState = createStore<SpacesStateType>(() => ({}));
export const $spacesCache = createCache($spacesState);
const $setSpace = createSetState($spacesState);

export const useSpacesSelector = <T>(selector: (state: SpacesStateType) => T) => {
  return $spacesState(selector, fastEqual);
};

export const updateSpaces = (state: SpacesStateType) => {
  shareInvalidateAll();
  tocInvalidateAll();
  $setSpace(
    produce((preState) => {
      assign(preState, state);
      keys(state).forEach((key) => {
        const item = state[key]!;
        if (preState[key] && !preState[key]?.setting) {
          preState[key]!.setting = {
            // @ts-ignore public space
            inlinePageIcon: item.inlinePageIcon,
          };
        }
      });

      return preState;
    })
  );
};

export const clearSpaces = () => {
  shareInvalidateAll();
  tocInvalidateAll();
  $setSpace(() => ({}));
};

export const spacesOperation = {
  create: (space: SpaceDTO) => {
    $setSpace(
      produce((state) => {
        state[space.uuid] = space;
      })
    );
  },
  update: (payload: { uuid: string; patch: Partial<Omit<SpaceDTO, 'uuid'>> }) => {
    $setSpace(
      produce((state) => {
        const space = state[payload.uuid];
        if (!space) return;
        const { setting, ...rest } = payload.patch;
        Object.assign(space, rest);
        if (space.setting) {
          Object.assign(space.setting, setting);
        } else {
          space.setting = { ...setting };
        }
      })
    );
  },
  listRemoveTemplate: (payload: {
    parentId: string;
    uuid: string;
    ignoreOp?: boolean | undefined;
  }) => {
    $setSpace(
      produce((state) => {
        const space = state[payload.parentId];
        if (!space) return;
        const index = space.customTemplates.indexOf(payload.uuid);
        if (index > -1) {
          space.customTemplates.splice(index, 1);
        }
      })
    );
  },
  addMarkName: (payload: {
    uuid: string;
    patch: {
      userId: string;
      markName: string;
    }[];
  }) => {
    $setSpace(
      produce((state) => {
        const markNameInfos = payload.patch;
        const space = state[payload.uuid];
        if (!space) return;
        if (!space.userRemark) {
          space.userRemark = {};
        }
        for (const info of markNameInfos) {
          space.userRemark[info.userId] = info.markName;
        }
      })
    );
  },
  listBeforeTemplate: (payload: {
    /** 所属spaceId */
    parentId: string;
    /** 移动内容 */
    uuid: string;
    /** 锚点 ID */
    before?: string;
    ignoreOp?: boolean;
  }) => {
    $setSpace(
      produce((state) => {
        const space = state[payload.parentId];
        if (!space) return;
        if (payload.before) {
          const beforeIndex = space.customTemplates.indexOf(payload.before);
          if (beforeIndex < 0) {
            space.customTemplates.splice(0, 0, payload.uuid);
          } else {
            space.customTemplates.splice(beforeIndex, 0, payload.uuid);
          }
        } else {
          space.customTemplates.splice(0, 0, payload.uuid);
        }
      })
    );
  },
  listAfterTemplate: (payload: {
    parentId: string;
    uuid: string;
    after?: string | undefined;
    ignoreOp?: boolean | undefined;
  }) => {
    $setSpace(
      produce((state) => {
        const space = state[payload.parentId];
        if (!space) return;
        if (payload.after) {
          const afterIndex = space.customTemplates.indexOf(payload.after);
          if (afterIndex < 0) {
            space.customTemplates.splice(0, 0, payload.uuid);
          } else {
            space.customTemplates.splice(afterIndex + 1, 0, payload.uuid);
          }
        } else {
          space.customTemplates.splice(0, 0, payload.uuid);
        }
      })
    );
  },
};
