import { CollectionSchemaType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useBitable } from 'src/bitable/context';
import { useCreateDateProperty } from 'src/hooks/block/use-convert-collection-view';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import { useBiTableGroupsAndProvideRecordWalker } from 'src/hooks/collection-view/table-groups';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import './style.css';
import { TimeLineView } from './timeline';
import type { CollectionViewProps } from 'src/views/main/page-bitable/types';

export const TimelineView: FC<CollectionViewProps> = () => {
  const { viewId, collectionId, readonly, managerReadonly } = useBitable();
  const createDateProperty = useCreateDateProperty();

  const biTableGroups = useBiTableGroupsAndProvideRecordWalker(viewId);
  const needUpdate = useObservableStore(
    ({ blocks, collectionViews }) => {
      const collection = blocks[collectionId];
      const view = collectionViews[viewId];
      const schema = collection?.data.schema ?? {};
      if (!view) return;
      const { timelineBy = '', timelineByEnd = '' } = view.format;

      const needUpdate = {
        updateTimelineBy: false,
        updateTimelineByEnd: false,
      };

      const timelineByType = schema[timelineBy]?.type;
      const timelineByEndType = schema[timelineByEnd]?.type;

      if (
        timelineByType !== CollectionSchemaType.DATE &&
        timelineByType !== CollectionSchemaType.CREATED_AT &&
        timelineByType !== CollectionSchemaType.UPDATED_AT
      ) {
        needUpdate.updateTimelineBy = true;
      }

      if (
        timelineByEndType !== CollectionSchemaType.DATE &&
        timelineByEndType !== CollectionSchemaType.CREATED_AT &&
        timelineByEndType !== CollectionSchemaType.UPDATED_AT
      ) {
        needUpdate.updateTimelineByEnd = true;
      }

      return needUpdate;
    },
    [viewId, collectionId]
  );

  useEffect(() => {
    if (readonly || managerReadonly) return;
    if (!needUpdate) return;

    if (needUpdate.updateTimelineBy || needUpdate.updateTimelineByEnd) {
      createDateProperty(viewId);
    }
  }, [createDateProperty, managerReadonly, needUpdate, readonly, viewId]);

  useEffect(() => {
    if (!biTableGroups) return;

    if (biTableGroups.needUpdateGroups) {
      updateViewFormat(viewId, { collectionGroups: biTableGroups.newViewGroups });
    }
  }, [biTableGroups, viewId]);

  if (!needUpdate) return null;
  if (biTableGroups?.needUpdateGroups) return null;

  if (needUpdate.updateTimelineBy || needUpdate.updateTimelineByEnd) {
    if (readonly || managerReadonly) {
      return (
        <div className="text-t2 h-[35px] flex items-center pl-2">
          当前视图无有效日期属性，无法展示
        </div>
      );
    }
    return null;
  }

  if (!biTableGroups?.sortedRecordIds) return null;
  return <TimeLineView recordIds={biTableGroups.sortedRecordIds} />;
};
