import { cx } from '@flowus/common/cx';
import { FileRegex } from '@flowus/common/regex';
import { BlockType } from '@next-space/fe-api-idl';
import type { Placement } from '@popperjs/core';
import type { FC } from 'react';
import { useCallback } from 'react';
import { message } from 'src/common/components/message';
import type { ModalSchema } from 'src/common/components/next-modal';
import { useOpenModal } from 'src/common/components/next-modal';
import { getPreviewType } from 'src/components/file-preview/renderElements';
import { getOwnerPage } from 'src/hooks/block/use-get-owner-page';
import { useUploadMultiFiles } from 'src/hooks/space/use-upload';
import { useTransaction } from 'src/hooks/use-transaction';
import { getMediaInfo } from 'src/hooks/utils/use-get-media-info';
import { TRANSACTION_FIRE } from 'src/redux/actions';
import { addBlock } from 'src/redux/managers/block/add';
import { convertBlock } from 'src/redux/managers/block/convert';
import { updateBlock } from 'src/redux/managers/block/update';
import { dispatch, getState } from 'src/redux/store';
import type { ReadonlyProp } from 'src/redux/types';
import { ViewModel } from 'src/redux/types';
import {
  getFileNameInfo,
  getImageSizeInfo,
  getSegmentsFileNameInfo,
  getVideoSizeInfo,
} from 'src/utils/file';
import { AUDIO_FORMAT, VIDEO_FORMAT } from 'src/utils/file-type';
import { usePickBlock } from 'src/utils/pick-block';
import { v4 } from 'uuid';
import type { Resource } from '../../editor/plugin/types';
import { textToSegments } from '../../utils/editor';
import { BlockEmptyView } from '../block-empty-view';
import { UploadPanel } from '../upload-panel';

export const EmptyFile: FC<
  {
    defaultOpenPanel: boolean;
    ownerBlockId?: string;
    uuid: string;
    title?: string;
  } & ReadonlyProp
> = (props) => {
  const { defaultOpenPanel, readonly, ownerBlockId, uuid, title = '添加附件' } = props;
  const selectFile = useSelectFile({
    uuid,
    ownerBlockId,
  });
  const transaction = useTransaction();

  return (
    <BlockEmptyView
      className={cx(readonly ? 'cursor-default' : 'cursor-pointer')}
      readonly={readonly}
      defaultOpenPanel={defaultOpenPanel}
      iconProp={{ name: 'IcUploadFile', size: 'normal' }}
      text={title}
      onClick={(event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        transaction(() => {
          selectFile({ getBoundingClientRect: () => rect });
        });
      }}
    />
  );
};

interface SelectFileProps {
  ownerBlockId?: string;
  uuid: string;
}
export const useSelectFile = (props: SelectFileProps) => {
  const { uuid, ownerBlockId } = props;
  const block = usePickBlock(ownerBlockId || uuid, ['data'], ['display', 'segments']);
  const openModal = useOpenModal();
  const onUploadFileBlock = useUploadFileBlock(props);
  const transaction = useTransaction();

  return useCallback(
    (popcorn: ModalSchema.PopcornType, placement?: Placement, offset?: number[]) => {
      if (!block) return;
      let accept: string;
      let text = '文件';
      let hideLink = false;
      switch (block.data.display) {
        case 'image':
          accept = 'image/*,image/heic,image/heif';
          text = '图片';
          break;
        case 'video':
          accept = VIDEO_FORMAT;
          text = '视频';
          break;
        case 'audio':
          accept = AUDIO_FORMAT;
          text = '音频';
          break;
        default:
          hideLink = true; // 文件块不需要外链
      }
      openModal.dropdown({
        popcorn,
        placement: placement ?? 'bottom',
        offset: offset ?? [],
        content: ({ onCloseModal }) => {
          let newDisplay = block.data.display;
          if (block.data.display) {
            if (!['image', 'audio', 'video'].includes(block.data.display)) {
              newDisplay = undefined;
            }
          } else {
            const { extName } = getSegmentsFileNameInfo(block.data.segments);
            if (FileRegex.image.test(extName)) {
              newDisplay = 'image';
            }
            if (FileRegex.video.test(extName)) {
              newDisplay = 'video';
            }
            if (FileRegex.audio.test(extName)) {
              newDisplay = 'audio';
            }
          }
          return (
            <UploadPanel
              hideLink={hideLink}
              linkTitle={`${text}链接`}
              linkPlaceHolder={`粘贴来自于网络的${text}链接`}
              inputAccept={accept}
              title={`选择${text}(支持多选)`}
              searchLinkFilePlaceholder={`搜索${text}`}
              // @ts-ignore fileType
              fileType={newDisplay}
              onSelectFile={(resource) => {
                onCloseModal();
                void onUploadFileBlock(resource);
              }}
              onSelectPageInfo={(blockInfo) => {
                if (!blockInfo.uuid) return;
                transaction(() => {
                  convertBlock([block.uuid], {
                    type: BlockType.REFERENCE,
                    data: {
                      ref: { uuid: blockInfo.uuid },
                      display: (block.data.display ?? 'file') === 'file' ? 'file' : newDisplay,
                    },
                  });
                });

                onCloseModal();
              }}
            />
          );
        },
      });
    },
    [block, onUploadFileBlock, openModal, transaction]
  );
};

export const useUploadFileBlock = (props: SelectFileProps) => {
  const { uuid, ownerBlockId } = props;
  const block = usePickBlock(ownerBlockId || uuid, ['data'], ['viewMode', 'display']);
  const transaction = useTransaction();
  const uploadMultiFiles = useUploadMultiFiles();
  const viewMode = block?.data.viewMode;

  const getOtherAttributes = useCallback(
    (extName?: string) => {
      const fileViewType = getPreviewType(extName);
      let newViewModel = viewMode;
      const isPreviewViewModel = viewMode === ViewModel.preview;
      if (isPreviewViewModel && !fileViewType) {
        newViewModel = ViewModel.card;
        message.warning('当前格式不支持预览');
      }

      return { newViewModel };
    },
    [viewMode]
  );

  /** 上传文件跟上传图片调的接口是一致的 */
  return useCallback(
    async (resources: Resource) => {
      // external link
      if (typeof resources === 'string') {
        const linkOrFileName = resources;
        const fileName = getFileNameInfo(linkOrFileName);
        const { newViewModel } = getOtherAttributes(fileName.extName);
        transaction(() => {
          updateBlock(uuid, {
            type: BlockType.EXTERNAL_FILE,
            data: {
              link: resources,
              viewMode: newViewModel,
              extName: fileName.extName,
              segments: textToSegments(linkOrFileName),
            },
          });
        });
        return;
      }
      // 将会创建的file blockId
      const fileBlockIds: string[] = [ownerBlockId || uuid];
      resources.forEach((_, index) => {
        // 0的话占用了现有的文件块，其他菜需要另外创建
        if (index !== 0) {
          fileBlockIds.push(v4());
        }
      });
      void uploadMultiFiles({
        files: resources,
        inTransferList: true,
        blockIds: fileBlockIds,
        type: 'file',
        keys: fileBlockIds,
        onCapacitySuccess: async () => {
          // 创建其余文件对应的文件块
          transaction(() => {
            let after = uuid;
            for (let i = 1; i < resources.length; i++) {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              const blockId = fileBlockIds[i]!;
              // 创建文件块
              const newId = addBlock(
                {
                  uuid: blockId,
                  type: block?.type,
                  data: {
                    display: block?.data.display,
                  },
                },
                {
                  parentId: block?.parentId ?? getOwnerPage(block?.uuid ?? '') ?? '',
                  after,
                }
              );
              after = newId;
            }
          });
          dispatch(TRANSACTION_FIRE());
          if (block?.data.display && ['image', 'video'].includes(block?.data.display)) {
            // 更新本地信息
            for (let i = 0; i < resources.length; i++) {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              const file = resources[i]!;
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              const fileBlockId = fileBlockIds[i]!;
              try {
                const fileSizeInfo =
                  block?.data.display === 'video'
                    ? await getVideoSizeInfo(file)
                    : await getImageSizeInfo(file);
                const fileName = getFileNameInfo(file.name);
                updateBlock(
                  fileBlockId,
                  {
                    data: {
                      link: undefined,
                      segments: textToSegments(file.name),
                      extName: fileName.extName,
                      localUrl: URL.createObjectURL(file),
                      viewMode: getOtherAttributes(fileName.extName).newViewModel,
                      ...fileSizeInfo,
                    },
                  },
                  true
                );
              } catch {
                //
              }
            }
          }
        },
        onComplete: async (ret) => {
          if (ret.success) {
            const blockId = ret.key;
            const index = fileBlockIds.indexOf(blockId);
            const resource = resources[index];
            if (!resource || !blockId) return;
            const oldState = getState();
            const mediaInfo = await getMediaInfo(resource);
            const fileName = getFileNameInfo(resource.name);
            transaction(
              () => {
                updateBlock(blockId, {
                  type: BlockType.FILE,
                  data: {
                    link: undefined,
                    segments: textToSegments(resource.name),
                    ossName: ret.ossName,
                    extName: fileName.extName,
                    localUrl: ret.url,
                    viewMode: getOtherAttributes(fileName.extName).newViewModel,
                    ...mediaInfo,
                    size: resource.size,
                  },
                });
              },
              { state: oldState }
            );
          }
        },
      });
    },
    [
      getOtherAttributes,
      ownerBlockId,
      uuid,
      uploadMultiFiles,
      block?.uuid,
      block?.data.display,
      block?.type,
      block?.parentId,
      transaction,
    ]
  );
};
