import { CollectionViewType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';
import { useConvertCollectionView } from 'src/hooks/block/use-convert-collection-view';
import { useCollectionView } from 'src/hooks/collection-view/use-collection-view';

import { useBitable } from '../../context';
import { TableViewMap, ViewIconMap } from '../const';

export const ViewTypeList: FC<{ onChange: () => void; viewId: string }> = ({
  onChange,
  viewId,
}) => {
  const { viewType, collectionId, viewParentId } = useBitable();
  const view = useCollectionView(viewId);
  const convertCollectionView = useConvertCollectionView();
  const isLinkTable = collectionId !== viewParentId;

  const items: ListItem<any>[] = [];
  TableViewMap.forEach((item) => {
    if (isLinkTable && item.type === CollectionViewType.FORM) return;
    items.push({
      type: ListItemType.OPERATION,
      data: {
        title: item.name,
        type: item.type,
        icon: <Icon name={ViewIconMap[item.type]} size="middle" />,
        hasArrow: item.type === viewType,
        arrow: <Icon size="normal" name="IcSelectedSpace" />,
      },
    });
  });

  return (
    <ListView
      className="next-modal-scroll w-[160px] py-[5px]"
      items={items}
      onItemClick={(item) => {
        const { type } = item.data;
        if (viewType !== type) {
          convertCollectionView(viewId, view?.title ?? '', type);
        }
        onChange();
      }}
    />
  );
};
