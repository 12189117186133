import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from 'src/common/components/icon';
import { IconUiSizeBox } from 'src/components/icon-ui-size-box';
import { SelectViewBar } from '../select-view-bar';
import type { ViewModelToolBarProps } from './type';

export const ViewModelToolBar: FC<ViewModelToolBarProps> = memo((props) => {
  const {
    onPdfAI,
    onChangeViewModel,
    onReplace,
    onDownLoad,
    onAddComment,
    openLink,
    splitLine = [],
    className,
    viewMode,
    hiddenOpen,
    readonly,
    onOpen,
    small,
    hiddenPreview,
  } = props;

  const iconSize = small ? 20 : 30;

  let OpenBtn = <></>;

  if (openLink && !hiddenOpen) {
    if (onOpen) {
      OpenBtn = (
        <IconUiSizeBox
          popup="全屏查看"
          size={iconSize}
          className={'rounded animate-hover'}
          onClick={onOpen}
        >
          <Icon name="IcMenuOpen" size="middle" />
        </IconUiSizeBox>
      );
    } else {
      OpenBtn = (
        <NavLink to={openLink}>
          <IconUiSizeBox popup="全屏查看" size={iconSize} className={'rounded animate-hover'}>
            <Icon name="IcMenuOpen" size="middle" />
          </IconUiSizeBox>
        </NavLink>
      );
    }
  }

  const DownloadBtn = (
    <IconUiSizeBox
      popup="下载"
      size={iconSize}
      onClick={onDownLoad}
      className={cx('rounded animate-hover', small && 'ml-2.5')}
    >
      <Icon name="IcMenuDownload" size="middle" />
    </IconUiSizeBox>
  );

  if (readonly && openLink && !hiddenOpen) {
    return (
      <span className={cx('flex items-center h-full cursor-default', className)}>
        {onDownLoad && <>{DownloadBtn}</>}
        {OpenBtn}
      </span>
    );
  }

  if (readonly) return null;

  return (
    <span className={cx('flex items-center h-full cursor-default', className)}>
      {onChangeViewModel && (
        <SelectViewBar
          small={small}
          hiddenPreview={hiddenPreview}
          value={viewMode}
          onChange={onChangeViewModel}
        />
      )}
      {onPdfAI && (
        <>
          <IconUiSizeBox
            popup="PDF 助手"
            size={iconSize}
            onClick={onPdfAI}
            className={'rounded animate-hover'}
          >
            <Icon name="IcAi" size="middle" />
          </IconUiSizeBox>
        </>
      )}

      {onAddComment && (
        <>
          {splitLine.includes(1) && (
            <div className={cx('h-4 w-px bg-grey4 mx-4', small && 'mx-2.5')} />
          )}
          <IconUiSizeBox
            popup="添加评论"
            size={iconSize}
            onClick={onAddComment}
            className={'rounded animate-hover'}
          >
            <Icon name="IcCommentMiddle" size="middle" />
          </IconUiSizeBox>
        </>
      )}
      {splitLine.includes(1) && <div className={cx('h-4 w-px bg-grey4 mx-4', small && 'mx-2.5')} />}
      {onReplace && (
        <IconUiSizeBox
          popup="替换"
          size={iconSize}
          onClick={onReplace}
          className={'rounded animate-hover'}
        >
          <Icon name="IcMenuReplace" size="middle" />
        </IconUiSizeBox>
      )}

      {onDownLoad && DownloadBtn}
      {splitLine.includes(2) && (onReplace || onDownLoad) && !hiddenOpen && (
        <div className={cx('h-4 w-px bg-grey4 mx-4', small && 'mx-2.5')} />
      )}
      {OpenBtn}
    </span>
  );
});
