import type { CollectionViewDTO, CollectionViewProperties } from '@next-space/fe-api-idl';
import { CollectionSchemaType, CollectionViewType, CoverType } from '@next-space/fe-api-idl';
import { useEffect } from 'react';
import { createSelector } from 'reselect';
import { DEFAULT_COL_WIDTH } from 'src/bitable/const';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import { MemoizeByBlockVersion } from 'src/services/memoize/cache';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import type { ObservableStore } from 'src/services/rxjs-redux/types';
import { $searchParams } from 'src/utils';
import { getViewFormat } from '../block/get-view-format';
import { useCoverSetting } from './use-collection-view';

export interface CollectionProperty {
  property: string;
  visible: boolean;
  width?: number;
  name: string;
  type: CollectionSchemaType;
}

type PropertiesResult = [
  CollectionProperty[] | undefined,
  CollectionProperty[] | undefined,
  (
    | {
        newProperties: CollectionViewProperties;
        needUpdateProperties: boolean;
        sorted: CollectionProperty[];
        collectionId: string;
        visibleProperties: CollectionProperty[];
      }
    | undefined
  ),
  (
    | {
        newProperties: CollectionViewProperties;
        needUpdateProperties: boolean;
        sorted: CollectionProperty[];
        collectionId: string;
        visibleProperties: CollectionProperty[];
      }
    | undefined
  )
];
interface Opts {
  visible?: boolean;
  includeIdNumber?: boolean;
}

/**
 * @includeIdNumber 只有table view下才为true
 * 这个id number的属性是参照国内竞品的，但实现基本上是根据notion表现来实现，只是有一些本来该显示的给隐藏掉。
 * 默认拿到的property隐藏掉id number，除非includeIdNumber为true
 */
export const useProperties = (viewId: string, opts?: Opts): PropertiesResult => {
  const viewType = useObservableStore(
    ({ collectionViews }) => collectionViews[viewId]?.type,
    [viewId]
  );
  const { previewType } = useCoverSetting(viewId);

  return useObservableStore(
    (state) => {
      return propertiesSelector(viewId, opts || {}, previewType, state, viewType);
    },
    [viewId, opts, viewType, previewType]
  );
};

const propertiesSelector = MemoizeByBlockVersion.create(
  createSelector(
    (viewId: string) => viewId,
    (viewId: string, opts: Opts) => opts,
    (viewId: string, opts: Opts, previewType: CoverType) => previewType,
    (viewId: string, opts: Opts, previewType: CoverType, state: ObservableStore) => state,
    (
      viewId: string,
      opts: Opts,
      previewType: CoverType,
      state: ObservableStore,
      viewType?: CollectionViewType
    ) => viewType,
    (
      viewId: string,
      opts: Opts,
      previewType: CoverType,
      state: ObservableStore,
      viewType?: CollectionViewType
    ): PropertiesResult => {
      const getResult = () => {
        const viewInfo = getViewFormat(viewId, state.blocks, state.collectionViews);
        if (!viewInfo) return;

        const {
          view,
          collection,
          properties,
          timelineTableProperties = [],
          timelineShowTable,
        } = viewInfo;

        const isTable = view.type === CollectionViewType.TABLE;
        const isForm = view.type === CollectionViewType.FORM;
        const isTimeline = view.type === CollectionViewType.TIMELINE;

        const propertiesArr = [properties];
        if (isTimeline) {
          propertiesArr.push(timelineTableProperties);
        }

        const schemas = collection.data.schema ?? {};

        return propertiesArr.map((properties, index) => {
          const newProperties: CollectionViewProperties = [];
          const sorted: CollectionProperty[] = [];
          const sortedIds = new Set<string>();

          let needUpdateProperties = false;

          properties.forEach((item) => {
            const schema = schemas?.[item.property];
            if (!schema) {
              needUpdateProperties = true;
              return;
            }
            if (!opts.includeIdNumber && schema.type === CollectionSchemaType.ID_NUMBER) {
              return;
            }
            if (!sortedIds.has(item.property)) {
              // 去重
              newProperties.push({ ...item });
              sortedIds.add(item.property);
              sorted.push({
                ...item,
                name: schema.name,
                type: schema.type,
              });
            }
          });

          Object.entries(schemas).forEach(([property, schema]) => {
            if (sortedIds.has(property)) return;
            if (!opts.includeIdNumber && schema.type === CollectionSchemaType.ID_NUMBER) {
              return;
            }

            const newProperty = {
              property,
              visible: property === 'title' || isTable || isForm,
              width: isTable || (isTimeline && index === 1) ? DEFAULT_COL_WIDTH : undefined,
            };

            needUpdateProperties = true;
            sortedIds.add(property);
            newProperties.push(newProperty);
            sorted.push({
              ...newProperty,
              name: schema.name,
              type: schema.type,
            });
          });

          const visibleProperties = sorted.filter((it) => {
            if (it.type === CollectionSchemaType.ID_NUMBER && !opts.includeIdNumber) {
              return false;
            }
            return $searchParams.dataBaseRange === 'current'
              ? it.visible
              : $searchParams.print || it.visible;
          });

          if (index === 1 && timelineShowTable && visibleProperties.length === 0) {
            // timeline 显示表格时如果没有可见列，则应该显示 title
            needUpdateProperties = true;

            const title = newProperties.find((item) => item.property === 'title');
            if (title) {
              title.visible = true;
            }

            const titleProperty = sorted.find((item) => item.property === 'title');
            if (titleProperty) {
              titleProperty.visible = true;
              visibleProperties.push({ ...titleProperty, visible: true });
            }
          }

          if (index === 0) {
            // 标题禁止编辑的时候应该显示
            const title = newProperties.find((item) => item.property === 'title');
            if (title) {
              const disableTitle =
                viewType === CollectionViewType.TABLE ||
                viewType === CollectionViewType.LIST ||
                viewType === CollectionViewType.TIMELINE ||
                viewType === CollectionViewType.CALENDAR ||
                ((viewType === CollectionViewType.BOARD ||
                  viewType === CollectionViewType.GALLERY) &&
                  previewType === CoverType.NONE);

              if (disableTitle && !title.visible) {
                const titleProperty = sorted.find((item) => item.property === 'title');

                if (titleProperty) {
                  needUpdateProperties = true;

                  title.visible = true;
                  titleProperty.visible = true;
                  visibleProperties.push({ ...titleProperty, visible: true });
                }
              }
            }
          }

          return {
            newProperties,
            needUpdateProperties,
            sorted,
            collectionId: collection.uuid,
            visibleProperties,
          };
        });
      };

      const result = getResult();
      const [properties, timelineTableProperties] = result ?? [];

      return [
        opts.visible ? properties?.visibleProperties : properties?.sorted,
        opts.visible ? timelineTableProperties?.visibleProperties : timelineTableProperties?.sorted,
        properties,
        timelineTableProperties,
      ];
    }
  ),
  (viewId, opts, previewType, _, viewType) => [viewId, opts, previewType, viewType]
);

/**
 * 确保多维表视图在渲染之前 properties 中的数据是准确的
 * @returns
 */
export const useUpdateProperties = (viewId: string, managerReadonly: boolean) => {
  const [properties1, properties2, normalProperties, timelineTableProperties] =
    useProperties(viewId);

  useEffect(() => {
    if (managerReadonly) return;
    if (!normalProperties) return;

    if (normalProperties.needUpdateProperties || timelineTableProperties?.needUpdateProperties) {
      const newFormat: CollectionViewDTO['format'] = {};

      if (normalProperties.needUpdateProperties) {
        const { propertiesName } = getViewFormat(viewId) ?? {};
        if (propertiesName) {
          newFormat[propertiesName] = normalProperties.newProperties;
        }
      }

      if (timelineTableProperties?.needUpdateProperties) {
        newFormat.timelineTableProperties = timelineTableProperties.newProperties;
      }

      updateViewFormat(viewId, newFormat);
    }
  }, [
    managerReadonly,
    normalProperties,
    timelineTableProperties?.needUpdateProperties,
    timelineTableProperties?.newProperties,
    viewId,
  ]);

  if (
    !managerReadonly &&
    (normalProperties?.needUpdateProperties || timelineTableProperties?.needUpdateProperties)
  ) {
    return null;
  }

  return [properties1, properties2];
};
