import { sleep } from '@flowus/common/async';
import type { ErrorHandler, RequestFactory } from '@next-space/fe-api-idl';
import { createRequest } from '@next-space/fe-api-idl';
import type { AxiosRequestConfig } from 'axios';
import { message } from '../components/message';
import { axios } from './axios';

axios.interceptors.request.use((config: AxiosRequestConfig) => {
  let { url = '' } = config;
  const { method, params } = config;
  if (method === 'get' && params) {
    url += '?';
    Object.keys(params).forEach((k) => {
      const v = params[k];
      if (v !== undefined) {
        url += `${k}=${encodeURIComponent(v)}&`;
      }
    });
    config.url = url.slice(0, url.length - 1);
    config.params = undefined;
  }
  return config;
});

const factory: RequestFactory = async (options) => {
  const { method, path, headers, query, formData, body } = options;
  const url = path.replace(/[:]/g, '_');

  let data = body;
  if (formData) {
    data = new FormData();
    Object.entries(data).forEach(([k, v]) => {
      // undefined or null
      if (v != null) {
        data.append(k, v);
      }
    });
  }

  const res = await axios({
    method,
    url,
    params: query,
    data,
    headers,
  });

  if (res.data.code !== 200) {
    // eslint-disable-next-line no-console
    console.error(res.data);
  }

  return {
    url,
    ok: true,
    status: res.status,
    statusText: res.statusText,
    body: res.data,
  };
};

/**
 * 通过 {API}.raw() 调用的方法不会触发 onError
 */
const onError: ErrorHandler = (res) => {
  // eslint-disable-next-line no-console
  console.log('request 业务错误', res);

  if (res.code === 502) {
    return;
  }
  const msg: string = res.msg || (res as any).message;

  if (/服务器内部错误|数据库锁超时/.test(msg) && __HOST_PRODUCTION__) {
    return;
  }

  if (msg) {
    message.error({
      key: 'msg',
      content: msg,
    });
  }

  // 报错后 5 秒关闭所有 message。以免出现卡 loading 的情况
  // 先获取是因为避免关闭后来新弹出的 message
  const keys = message.getAll();
  void sleep(5000).then(() => {
    keys.forEach(message.closeMessage);
  });
};

export const request = createRequest(factory, onError);
