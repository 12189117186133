import { useIsDarkMode } from '@flowus/common';
import { message } from '@flowus/common/components/message';
import { useOpenModal } from '@flowus/common/next-modal';
import type { PreOrderDTO } from '@next-space/fe-api-idl';
import {
  Elements,
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import type { Layout, Stripe } from '@stripe/stripe-js';
import { useDebounceEffect } from 'ahooks';
import type { FC, FormEvent } from 'react';
import { useEffect, useState } from 'react';
import { colors } from 'src/colors';
import { createModel, useModel } from 'src/common/create-model';
import { VITE_STRIPE_PUBLIC_KEY } from 'src/env';
import { Modals } from 'src/modals';

// #region types
interface StripeFormProps {
  submit: () => void;
  className?: string;
}
interface StripeFormContextProps {
  getClientSecret: () => Promise<PreOrderDTO>;
  orderStateKey: string;
}
// #endregion

// #region checkout
const useStripeCheckOut = (props: { submit: () => void }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const openModal = useOpenModal();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements || isLoading) {
      return;
    }

    openModal.loading({ modalId: Modals.STRIPE_CHECKOUT, autoClose: false });
    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: location.origin,
      },
      redirect: 'if_required',
    });
    openModal.closeModal(Modals.STRIPE_CHECKOUT);

    if (!error) {
      props.submit();
    } else {
      message.error(error.message ?? 'An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  return { onSubmit, email, isLoading, setEmail };
};
const StripeCheckOutContext = createModel(useStripeCheckOut);
export const useStripeCheckOutContext = () => useModel(StripeCheckOutContext);
export const StripeCheckoutForm: FC = () => {
  const { setEmail } = useStripeCheckOutContext();
  return (
    <>
      <LinkAuthenticationElement
        id="link-authentication-element"
        onChange={(e) => setEmail(e.value.email)}
      />
      <PaymentElement id="payment-element" options={{ layout: 'tabs' as Layout }} />
    </>
  );
};
// #endregion

// #region form
const useStripeForm = (props: StripeFormContextProps) => {
  const { getClientSecret, orderStateKey } = props;
  const isDarkModel = useIsDarkMode();
  const [clientSecret, setClientSecret] = useState('');

  const appearance = {
    variables: isDarkModel
      ? {
          colorPrimary: colors.active_color,
          colorBackground: colors.dark.white2,
          colorText: colors.dark.white,
        }
      : undefined,
  };

  const options = {
    clientSecret,
    appearance,
    locale: 'en',
  };

  useDebounceEffect(
    () => {
      void getClientSecret().then((res) => {
        if (res.clientSecret) {
          setClientSecret(res.clientSecret);
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [orderStateKey],
    { wait: 200, leading: false, trailing: true }
  );

  return { options, clientSecret };
};

const StripeFormContext = createModel(useStripeForm);
const useStripeFormContext = () => useModel(StripeFormContext);

export const StripeForm: FC<StripeFormProps & StripeFormContextProps> = (props) => {
  const { getClientSecret, submit, children, orderStateKey, className } = props;
  return (
    <StripeFormContext orderStateKey={orderStateKey} getClientSecret={getClientSecret}>
      <StripeFormContent className={className} submit={submit}>
        {children}
      </StripeFormContent>
    </StripeFormContext>
  );
};

const StripeFormContent: FC<StripeFormProps> = (props) => {
  const { options } = useStripeFormContext();
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);

  useEffect(() => {
    void (async () => {
      const { loadStripe } = await import('@stripe/stripe-js');
      setStripePromise(loadStripe(VITE_STRIPE_PUBLIC_KEY));
    })();
  }, []);

  return (
    <>
      {options.clientSecret && (
        <Elements key={options.clientSecret} options={options as any} stripe={stripePromise}>
          <StripeCheckOutContext submit={props.submit}>
            <StripeFormSubmit className={props.className}>{props.children}</StripeFormSubmit>
          </StripeCheckOutContext>
        </Elements>
      )}
    </>
  );
};

const StripeFormSubmit: FC<{ className?: string }> = (props) => {
  const { className, children } = props;
  const { onSubmit } = useStripeCheckOutContext();

  return (
    <form
      id="payment-form"
      // className={cx('animate__animated animate__fadeIn', className)}
      className={className}
      onSubmit={onSubmit}
    >
      {children}
    </form>
  );
};
// #endregion
