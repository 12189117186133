import { getFileNameInfo } from '@flowus/common';
import { getBlockTextColor } from '@flowus/common/block/color/get-block-color';
import { cx } from '@flowus/common/cx';
import { getOrderFolderType } from '@flowus/common/utils/get-folder-sort-type';
import { fileUploader } from '@flowus/upload';
import { BlockType, OrderFolderType } from '@next-space/fe-api-idl';
import { keys } from 'lodash-es';
import type { FC, MouseEvent } from 'react';
import { memo, useMemo } from 'react';
import { ColorKey } from 'src/colors';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { useOpenModal } from 'src/common/components/next-modal';
import { ProgressBar } from 'src/common/components/progress-bar';
import { Tooltip } from 'src/common/components/tooltip';
import { formatFileTimestamp } from 'src/common/utils/formatter';
import { BlockDefaultIcon } from 'src/components/block-default-icon';
import { Checkbox } from 'src/components/check-box';
import { DriveDnd } from 'src/components/drive-dnd';
import { FileNameUiKit } from 'src/components/file-name-uikit';
import { FilePreViewIcon } from 'src/components/file-preview-icon';
import { IconTrigger } from 'src/components/icon-trigger';
import { getReadableFileSizeString } from 'src/editor/utils/size-utils';
import { useMultipleDownload } from 'src/hooks/drive/use-multiple-download';
import { useReadonly } from 'src/hooks/page';
import { UploadStatus } from 'src/redux/types';
import { FolderViewType } from 'src/services/app';
import { removeUploadInfoByKey, useUploadInfoById } from 'src/services/upload';
import { getFileIcon } from 'src/utils/file';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { judgeSharePage } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { bindDataTestId, TestIds } from 'src/utils/qa-utils';
import { getFileListPreViewIconSize } from '../common';
import { useChangeOrderFolder } from '../hook';
import { useDrive } from '../use-drive';

// #region 小图样式的item
interface Props {
  uuid: string;
  isPreView: boolean;
  canDownload: boolean;
  isDriveIcon?: boolean;
}
export const ThumbnailFileItem: FC<Props> = memo((props) => {
  const { uuid, isPreView, canDownload } = props;
  const block = usePickBlock(uuid, ['data', 'updatedAt', 'createdAt'], ['ossName', 'size']);
  const {
    selectCurrentBlock,
    openMenuList,
    handleClick,
    rename,
    setName,
    showRename,
    name,
    isDragging,
    selectBlock,
    illegal,
    readonly,
    isPage,
    fileName,
    isSelected,
  } = useDrive({
    uuid,
  });
  const multipleDownload = useMultipleDownload();
  const fileIconSizeInfo = getFileListPreViewIconSize(
    isPreView ? FolderViewType.PREVIEW : FolderViewType.THUMBNAIL
  );

  if (!block) return null;

  return (
    <DriveDnd
      uuid={block.uuid}
      onContextMenu={openMenuList}
      onClick={handleClick}
      className={cx(
        'flex relative m-2.5 ml-0 px-1.5 py-2.5 group cursor-pointer',
        isPreView ? 'w-44' : 'w-28',
        illegal && 'opacity-30',
        !isDragging && !isSelected && 'hover:bg-active_color_10'
      )}
    >
      {!isPage && canDownload && (
        <>
          <Tooltip
            popup={isSelected ? '取消选中' : '选中'}
            className={cx(
              'transition-opacity opacity-0 absolute left-2 top-2 z-10 group-hover:opacity-100',
              isSelected && 'opacity-100'
            )}
          >
            <Checkbox size="small" checkbox2 checked={!!isSelected} onClick={selectBlock} />
          </Tooltip>
          {!isPage && canDownload && judgeSharePage() && (
            <Tooltip
              popup="下载"
              className={
                'transition-opacity opacity-0 right-2 top-2 z-10 group-hover:opacity-100 absolute cursor-pointer animate-hover'
              }
            >
              <Icon
                name="IcMenuDownload"
                size="middle"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  void multipleDownload([uuid]);
                }}
              />
            </Tooltip>
          )}
          {!readonly && (
            <Tooltip
              popup="更多"
              className={
                'transition-opacity opacity-0 right-2 top-2 z-10 group-hover:opacity-100 absolute cursor-pointer animate-hover'
              }
            >
              <Icon
                {...bindDataTestId(TestIds.driveFileMore)}
                name="IcMore"
                size="small"
                onClick={(e: MouseEvent<HTMLElement>) => {
                  selectCurrentBlock(e);
                  openMenuList(e);
                }}
              />
            </Tooltip>
          )}
        </>
      )}
      <div className={'flex items-center justify-center flex-col w-full'}>
        <FilePreViewIcon
          ossName={block.data.ossName}
          className={fileIconSizeInfo.class}
          options={{ videoPlayIconSize: 'xxxlarge', resizeWidth: fileIconSizeInfo.size }}
          uuid={block.uuid}
          defaultIcon={
            <IconTrigger
              trigger={false}
              blockId={block.uuid}
              iconSize={isPreView ? 100 : 50}
              defaultIcon={
                <BlockDefaultIcon uuid={block.uuid} size={isPreView ? 'preview' : 'max'} />
              }
            />
          }
        />
        <div className="w-full text-center mt-2 text-t2 h-6">
          {showRename && (
            <Input
              selection={[0, name.length]}
              onEnter={rename}
              className="h-6 w-full"
              autoFocus
              placeholder={getUntitledName(block.type)}
              value={name}
              onBlur={rename}
              onChange={(value) => setName(value)}
            />
          )}
          {!showRename &&
            (fileName && !isPage ? (
              <FileNameUiKit className="flex justify-center" fileName={fileName} />
            ) : (
              <div className="text-ellipsis">{fileName || getUntitledName(block.type)}</div>
            ))}
        </div>
        <div className="text-t4 text-grey3 text-center">
          {illegal ? '-' : formatFileTimestamp(block.updatedAt || block.createdAt || 0)}
        </div>

        <div className="text-t4 text-center text-grey3">
          {block.data.size && !illegal ? getReadableFileSizeString(block.data.size) : '-'}
        </div>
      </div>
    </DriveDnd>
  );
});
// #endregion

// #region 表头
export const ThumbnailFileTitle: FC<{ uuid: string }> = memo(({ uuid }) => {
  enum SortTypeName {
    name = '文件名',
    time = '上次修改时间',
    size = '文件大小',
  }
  const openModal = useOpenModal();
  const block = usePickBlock(uuid, ['data'], ['format']);
  const changeOrderFolder = useChangeOrderFolder(uuid);
  const readonly = useReadonly(uuid);
  const { sortType, reverse } = getOrderFolderType(block?.data?.format?.orderFolder);

  const sortTitleInfo = useMemo(() => {
    const sortTitleInfo: {
      title: string;
      type: keyof typeof SortTypeName;
    } = {
      title: SortTypeName.time,
      type: 'time',
    };

    switch (sortType) {
      case OrderFolderType.NAME:
      case OrderFolderType.NAME_REVERSED: {
        sortTitleInfo.type = 'name';
        break;
      }
      case OrderFolderType.TIME:
      case OrderFolderType.TIME_REVERSED: {
        sortTitleInfo.type = 'time';
        break;
      }
      case OrderFolderType.SIZE:
      case OrderFolderType.SIZE_REVERSED: {
        sortTitleInfo.type = 'size';
        break;
      }
      default: {
        break;
      }
    }
    sortTitleInfo.title = SortTypeName[sortTitleInfo.type];
    return sortTitleInfo;
  }, [sortType, SortTypeName]);

  if (!block || readonly) return null;

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom-start',
      content: ({ onCloseModal }) => (
        <ListView
          className="next-modal py-2.5 w-40"
          onItemClick={() => onCloseModal()}
          // @ts-ignore name
          items={keys(SortTypeName).map((name: keyof typeof SortTypeName) => ({
            type: ListItemType.OPERATION,
            data: {
              title: SortTypeName[name],
              type: name,
              selected: sortTitleInfo.type === name,
              onClick: () => changeOrderFolder(name),
            },
          }))}
        />
      ),
    });
  };

  return (
    <div className={'text-grey3 text-t2-medium flex items-center h-10 justify-between w-full'}>
      <div className={'flex items-center h-full'}>
        排序方式：
        <span className={cx('flex items-center cursor-pointer h-full mr-2')} onClick={handleClick}>
          {sortTitleInfo.title}
        </span>
        <Icon
          size="middle"
          className={cx('animate-hover', reverse && 'rotate-180')}
          name="IcMenuInsertDown"
          onClick={() => changeOrderFolder(sortTitleInfo.type)}
        />
      </div>
    </div>
  );
});
// #endregion

// #region upload
export const ThumbnailStyleUploadItem: FC<{ uuid: string; isPreView: boolean }> = ({
  uuid,
  isPreView,
}) => {
  const info = useUploadInfoById(uuid);

  const cancelUploadFile = (id: string) => {
    void fileUploader.abort(id);
    info && removeUploadInfoByKey(info.key);
  };

  if (!info) return null;
  const fileName: string = info?.name ?? '';
  const fileInfo = getFileNameInfo(fileName);

  return (
    <div
      key={info.key}
      className={cx(
        'relative flex flex-col items-center justify-center m-2.5 px-1.5 py-2.5',
        isPreView ? 'w-52' : 'w-28'
      )}
    >
      <Icon
        size="middle"
        name="IcUploadCancel"
        className="absolute right-1 top-1 text-grey3"
        onClick={() => cancelUploadFile(info.uploadId ?? '')}
      />
      <Icon
        name={getFileIcon(fileInfo.extName)}
        size={isPreView ? 'preview' : 'max'}
        className={'flex-shrink-0'}
      />
      <FileNameUiKit
        className="w-full flex items-center justify-center mt-2 text-t2 h-6"
        fileName={fileName}
      />
      <div className="text-t4 text-grey3">{getReadableFileSizeString(info.size)}</div>
      <div
        className={'text-t4'}
        style={{
          color: getBlockTextColor(
            info.status === UploadStatus.uploading ? ColorKey.blue : ColorKey.red
          ),
        }}
      >
        {info.status === UploadStatus.uploading && `已上传${info.progress}%`}
        {info.status === UploadStatus.failure && '上传失败'}
      </div>
      <ProgressBar
        progress={info.progress ?? -1}
        fail={info.status === UploadStatus.failure}
        className="absolute h-[3px] left-0 bottom-0 w-full"
      />
    </div>
  );
};
// #endregion
