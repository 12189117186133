import { ChangeBindPhone, VerifyPasswordCode, VerifyPhoneCode } from '@flowus/login';

import { UserAuthorizationType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { message } from 'src/common/components/message';
import { useOpenModal } from 'src/common/components/next-modal';
import { useCurrentUser, useLogout } from 'src/hooks/user';
import { useFetchMe } from 'src/hooks/user/use-fetch-me';
import { SettingModalCommon } from '../common';
import { Button } from '@flowus/common/components';

/** 换绑手机号 */
export const AccountPhone: FC = () => {
  const openModal = useOpenModal();
  const currentUser = useCurrentUser();
  const fetchMe = useFetchMe();
  const logout = useLogout();

  const openChangePhoneDialog = () => {
    openModal.warning({
      title: '确认换绑当前手机号？',
      confirmText: '确认',
      cancelText: '取消',
      minWidth: 400,
      content: (
        <div className="mb-10">
          <div>换绑后，将无法继续通过以下手机号进行验证登录</div>
          <div>{currentUser.phone}</div>
        </div>
      ),
      confirm: openSelectVerification,
    });
  };

  const openSelectVerification = () => {
    openModal.warning({
      title: '身份验证',
      confirmText: '通过原手机号验证',
      cancelText: '通过登录密码验证',
      colorType: 'active',
      minWidth: 400,
      content: (
        <div className="mb-10">
          <div className="mb-1.5">为保障账号安全，请选择验证方式</div>
          <div className="text-grey3 text-t4">
            原手机号无法接收验证码、忘记密码？请联系人工客服处理
          </div>
        </div>
      ),
      confirm: () => {
        openVerifyPhoneCode();
      },
      cancel: () => {
        openVerifyPasswordCode();
      },
    });
  };

  const openVerifyPhoneCode = () => {
    openModal.modal({
      content: (_) => (
        <VerifyPhoneCode
          phone={currentUser.phone}
          onSuccess={(code) => {
            _.onCloseModal();
            openBindPhone(code, UserAuthorizationType.PHONE);
          }}
        />
      ),
    });
  };

  const openVerifyPasswordCode = () => {
    openModal.modal({
      content: (_) => (
        <VerifyPasswordCode
          userId={currentUser.uuid}
          onSuccess={(code) => {
            _.onCloseModal();
            openBindPhone(code, UserAuthorizationType.PASSWORD);
          }}
        />
      ),
    });
  };

  const openBindPhone = (code: string, type: UserAuthorizationType) => {
    openModal.modal({
      content: (_) => (
        <ChangeBindPhone
          type={type}
          authorizationCode={code}
          userId={currentUser.uuid}
          onSuccess={async () => {
            _.onCloseModal();
            await fetchMe();
            message.success('登录已失效，请重新登录');
            void logout();
          }}
        />
      ),
    });
  };

  return (
    <SettingModalCommon.Item
      leftChild={
        <div className="space-y-0.5">
          <div>手机号</div>
          <div className="text-t4 text-grey3">{currentUser.phone}</div>
        </div>
      }
      rightChild={<Button onClick={openChangePhoneDialog}>换绑手机号</Button>}
    />
  );
};
