import type { ModalSchema } from '@flowus/common/next-modal';
import { useOpenModal } from '@flowus/common/next-modal';
import { useCallback } from 'react';
import { useStore } from 'react-redux';
import { ListItemType } from 'src/common/components/list-view/types';
import { getAllProperties } from 'src/utils/collection-util';
import { ListView } from 'src/common/components/list-view';
import { cx } from '@flowus/common/cx';
import { CollectionSchemaType } from '@next-space/fe-api-idl';

/** 选择多维表属性字段 */
export const useSelectProperty = () => {
  const store = useStore();
  const openModal = useOpenModal();
  return useCallback(
    (
      collectionId: string,
      option: {
        popcorn: ModalSchema.PopcornType;
        callback: (propertyId: string) => void;
        excludeIds?: string[]; // 如果不需要的话select列表会去掉
        className?: string;
        offset?: number[];
      }
    ) => {
      let allProperties = getAllProperties(store, collectionId, option.excludeIds) ?? [];
      allProperties = allProperties.filter((v) => v.type !== CollectionSchemaType.ROLLUP);
      const listItems = allProperties?.map((item) => {
        return {
          type: ListItemType.OPERATION,
          data: {
            title: item.title,
            icon: item.icon,
            propertyId: item.value,
            onClick: () => {
              option.callback(item.value);
            },
          },
        };
      });
      openModal.dropdown({
        placement: 'bottom',
        offset: [0, 0],
        popcorn: option.popcorn,
        content: ({ onCloseModal }) => {
          return (
            <ListView
              className={cx(
                'next-modal min-w-[100px] max-h-[40vh] overflow-auto',
                option.className
              )}
              items={listItems}
              onItemClick={() => {
                onCloseModal();
              }}
            />
          );
        },
      });
    },
    [openModal, store]
  );
};
