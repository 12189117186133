import { Role } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import { BlockStatus, BlockType, CollectionViewType, PermissionRole } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { DEFAULT_ROW_HEIGHT } from 'src/bitable/const';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { ID_HOVER_MENU_CONTAINER } from 'src/hooks/page/use-dnd/helper';
import { getPermissions } from 'src/hooks/share/use-permissions';
import { cache } from 'src/redux/store';
import {
  $appUiStateCache,
  useAllowCommentInSharePage,
  useCreateBlockMenuListId,
} from 'src/services/app/hook';
import { getDropInfo } from 'src/services/app/hook/use-drop-info';
import { isCollection } from 'src/utils/block-type-utils';
import { supportComments } from 'src/utils/block-utils';
import { judgeSharePage } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { PageScene, usePageScene } from 'src/views/main/scene-context';
import { AddCommentButton } from './add-comment-button';
import { DragButton } from './drag-button';
import { HeaderTip } from './header-tip';
import { useCreateBlockAndOpenMenu, useIsFoldList } from './hook';
import { useHoverNode } from './use-hover-node';

export const HoverMenu: FC<{ readonly?: boolean }> = ({ readonly = false }) => {
  const createBlockAndOpenMenu = useCreateBlockAndOpenMenu();
  const hoverNode = useHoverNode();
  const isDragging = getDropInfo();
  const columnResizing = $appUiStateCache.$columnResizing;
  const isSelecting = $appUiStateCache.$isSelecting;
  const createBlockMenuListId = useCreateBlockMenuListId();
  const { collectionViews } = cache;
  const block = usePickBlock(hoverNode?.id, ['data', 'status'], ['segments']);
  const parentBlock = usePickBlock(block?.parentId, []);

  /** 判断是否为需要折叠列表，隐藏+号菜单 */
  const isFoldList = useIsFoldList(block?.uuid);

  const isRecord = block && hoverNode && isCollection(parentBlock?.type);
  const allowCommentInSharePage = useAllowCommentInSharePage();
  const isShare = judgeSharePage();
  const isInMain = usePageScene() === PageScene.MAIN;

  const showAddBlockMenu =
    !readonly && (isRecord || (!isFoldList && block?.type === BlockType.TEXTAREA));

  if (!block) {
    return null;
  }

  if (
    !hoverNode ||
    // 用clientHeight可以获取dom最新节点数据
    !hoverNode?.node.clientHeight ||
    isDragging ||
    columnResizing ||
    createBlockMenuListId ||
    isSelecting ||
    block?.status !== BlockStatus.NORMAL
  ) {
    return null;
  }

  // #region START-------------------------- 调整style位置 --------------------------------------
  const parent = block && parentBlock;
  const parentIsQuote = parent?.type === BlockType.QUOTE;

  const style = hoverNode
    ? {
        top: hoverNode.offsetTop + hoverNode.lineHeight / 2 + hoverNode.paddingTop,
        left: hoverNode.offsetLeft - 2 - (parentIsQuote ? 20 : 0),
      }
    : {};

  if (isRecord) {
    style.top = hoverNode.offsetTop + DEFAULT_ROW_HEIGHT / 2;
    const viewId = hoverNode.node.dataset.viewId ?? '';
    const view = collectionViews[viewId];
    const { role: viewParentRole } = getPermissions(view?.parentId ?? '');
    const { role: collectionRole } = getPermissions(block.parentId);
    if (viewParentRole === PermissionRole.READER || collectionRole === PermissionRole.READER) {
      return null;
    }

    if (view?.type === CollectionViewType.CALENDAR || view?.type === CollectionViewType.BOARD) {
      return null;
    }

    if (view?.type === CollectionViewType.LIST) {
      style.top = hoverNode.offsetTop + DEFAULT_ROW_HEIGHT / 2 - 5;
    }
  }

  if (hoverNode && block?.type) {
    if (
      [
        BlockType.COLLECTION_VIEW_PAGE,
        BlockType.COLLECTION_VIEW,
        BlockType.REFERENCE_COLLECTION,
        BlockType.MIND_MAPPING_PAGE,
      ].includes(block.type)
    ) {
      style.top = hoverNode.offsetTop + 16;
    }

    if ([BlockType.SYNC_CONTAINER, BlockType.TABLE].includes(block.type)) {
      style.top = hoverNode.offsetTop + 32;
    }

    if ([BlockType.CODE].includes(block.type)) {
      style.top = hoverNode.offsetTop + 19;
    }
  }

  // #endregion END-------------------------- 调整style位置 --------------------------------------

  const supportComment = supportComments(block);
  const roleCanComment = Role.contains(getPermissions(block?.uuid).role, PermissionRole.COMMENTER);
  const canCommentInNormal = !isShare && !isRecord && supportComment && roleCanComment && isInMain;
  const canCommentInShare =
    isShare && !isRecord && supportComment && roleCanComment && allowCommentInSharePage;

  const canComment = canCommentInNormal || canCommentInShare;

  if (readonly && !canComment) return null;

  // #region 只读权限return null
  if (block) {
    const { role } = getPermissions(block.uuid);
    if (role === PermissionRole.READER) {
      return null;
    }
  }
  // #endregion

  return (
    <div
      id={ID_HOVER_MENU_CONTAINER}
      className="absolute transition-all duration-75 animate-fade-in"
      style={style}
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      {showAddBlockMenu && (
        <Tooltip
          className={cx(
            'w-5 h-6 flex items-center justify-center animate-hover rounded text-grey4 hover:text-grey1 cursor-pointer absolute -top-3 -left-10'
          )}
          placement="bottom"
          popup="添加块"
        >
          <div
            id="addMenu"
            data-disable-select
            onClick={(event) => {
              if (block?.uuid) {
                // 阻止 PageDoc :: handleClick :: focusEditable
                event.stopPropagation();
                createBlockAndOpenMenu(block.uuid, {
                  syncId: hoverNode.node.dataset.syncId,
                });
              }
            }}
          >
            <Icon name="IcBlockAdd" className="pointer-events-none" size="small" />
          </div>
        </Tooltip>
      )}
      {!readonly && (
        <>
          <Tooltip
            className={cx('absolute -top-3 -left-5', isFoldList && '-left-10')}
            placement="bottom"
            popup={
              <span className="text-center">
                <div>长按拖拽</div>
                <div>点击打开菜单</div>
              </span>
            }
          >
            <DragButton hoverNode={hoverNode} />
          </Tooltip>
          <HeaderTip id={hoverNode.id} className="absolute -top-3 -left-10" />
        </>
      )}
      {readonly && canComment && (
        <Tooltip className={cx('absolute -top-3 -left-5 cursor-pointer', isFoldList && '-left-9')}>
          <AddCommentButton blockId={hoverNode.id} />
        </Tooltip>
      )}
    </div>
  );
};
