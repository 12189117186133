import type { PromptStore } from './types';

/** 系统预制指令 */
export const systemPromptData: Omit<PromptStore, 'alreadySaved'> = {
  editPrompts: [
    '9aab88e9-ba1b-4201-a9f3-483e814493db',
    '7aec37fa-f6be-4b6e-a870-6a54b430131a',
    'c0e29509-ca71-4bc0-9a96-fa338a2b05ce',
    '5e936d14-2331-468b-af24-31433992451d',
    '5e936d14-2331-468b-af24-31433994451d',
    '7edcb487-2041-4ade-8483-71b9e1caba35',
    '1a8812df-9395-4eed-84be-59b5e148e7c1',
    '63c58833-d02c-4a15-8039-949c78bcc9f2',
    '7613737d-eb65-4464-a596-167d1e3f0c00',
  ],
  emptyWithPrompts: [
    'bc56080a-edb8-46e2-8584-7be4c4cc5d05',
    '1ee4dcf6-b00d-49b3-b4d5-ec55d102fd40',
    '0fcb00ae-f47b-426f-9045-25798361ccc4',
    'a15c829b-8861-47d8-a085-10e7b36d1841',
    'b3d48333-ead2-4692-ab51-1f16019500cd',
    'e7e1df60-bd16-47d9-a2fa-e4fec4c114cf',
    'f2eb1bda-7e1f-4ece-91d1-4c2c2d1f06cf',
    'f2eb1bda-7e1f-4ece-91d1-4c2c2d1f06cc',
    'f2eb1bda-7e1f-4ece-91d1-4c2c2daf06cd',
    'f2eb1bda-7e1f-4ece-9121-4c2c1daf06cd',
  ],
  records: {
    '9aab88e9-ba1b-4201-a9f3-483e814493db': {
      icon: 'ic_continue_writing',
      name: '继续写作',
      id: '9aab88e9-ba1b-4201-a9f3-483e814493db',
      type: 'ContinueWrite',
      minimax:
        '我需要你延续这段内容继续写作，生成有逻辑性和连贯性的文本内容，每次续写内容不要过长。',
    },
    '7aec37fa-f6be-4b6e-a870-6a54b430131a': {
      icon: 'ic_summarize',
      name: '总结',
      id: '7aec37fa-f6be-4b6e-a870-6a54b430131a',
      type: 'Summarize',
      minimax: '总结文章内容。',
    },
    'c0e29509-ca71-4bc0-9a96-fa338a2b05ce': {
      icon: 'ic_summary',
      name: '摘要',
      id: 'c0e29509-ca71-4bc0-9a96-fa338a2b05ce',
      type: 'keySummary',
      minimax: '归纳文章内容，用列表列出重点摘要。',
    },
    '5e936d14-2331-468b-af24-31433992451d': {
      icon: 'ic_explain',
      name: '解释',
      id: '5e936d14-2331-468b-af24-31433992451d',
      type: 'explain',
      minimax: '解释文章内容。',
    },
    '5e936d14-2331-468b-af24-31433994451d': {
      icon: 'IcTranslate',
      name: '翻译',
      id: '5e936d14-2331-468b-af24-31433994451d',
      subNodes: [
        '3a36576b-1b95-42c5-b70a-5c36fc9e145d',
        'fb5a9fbd-c969-4bf4-baee-a0d0af4cb880',
        '8846070a-7630-42a0-9042-290ab143d389',
        '6ecd1645-fadb-4f56-bec5-cb1877d16330',
        '069f3964-62a0-4094-96f9-b993dae8cc47',
        '9151b1e0-e57f-45fa-b4b2-3942ed8437e3',
        '459fee87-5cfc-4166-8b49-4060d4c27e2f',
        '459fee87-5cfc-4166-8b49-4060d4c27e22',
      ],
    },
    '3a36576b-1b95-42c5-b70a-5c36fc9e145d': {
      name: '中文（简体）',
      id: '3a36576b-1b95-42c5-b70a-5c36fc9e145d',
      type: 'simplified chinese',
      minimax:
        '将这段单词或内容翻译成简体中文，保留原文 markdown 格式。只需要返回翻译结果，不返回与原文无关内容。',
    },
    'fb5a9fbd-c969-4bf4-baee-a0d0af4cb880': {
      name: '中文（文言文）',
      id: 'fb5a9fbd-c969-4bf4-baee-a0d0af4cb880',
      type: 'classical chinese',
      minimax:
        '将这段单词或内容翻译成文言文，保留原文 markdown 格式。只需要返回翻译结果，不返回与原文无关内容。',
    },
    '8846070a-7630-42a0-9042-290ab143d389': {
      name: '英语',
      id: '8846070a-7630-42a0-9042-290ab143d389',
      type: 'english',
      minimax:
        '将这段单词或内容翻译成英语，保留原文 markdown 格式。只需要返回翻译结果，不返回与原文无关内容。',
    },
    '6ecd1645-fadb-4f56-bec5-cb1877d16330': {
      name: '韩语',
      id: '6ecd1645-fadb-4f56-bec5-cb1877d16330',
      type: 'korean',
      minimax:
        '将这段单词或内容翻译成韩语，保留原文 markdown 格式。只需要返回翻译结果，不返回与原文无关内容。',
    },
    '069f3964-62a0-4094-96f9-b993dae8cc47': {
      name: '日语',
      id: '069f3964-62a0-4094-96f9-b993dae8cc47',
      type: 'japanese',
      minimax:
        '将这段单词或内容翻译成日语，保留原文 markdown 格式。只需要返回翻译结果，不返回与原文无关内容。',
    },
    '9151b1e0-e57f-45fa-b4b2-3942ed8437e3': {
      name: '法语',
      id: '9151b1e0-e57f-45fa-b4b2-3942ed8437e3',
      type: 'french',
      minimax:
        '将这段单词或内容翻译成法语，保留原文 markdown 格式。只需要返回翻译结果，不返回与原文无关内容。',
    },
    '459fee87-5cfc-4166-8b49-4060d4c27e2f': {
      name: '德语',
      id: '459fee87-5cfc-4166-8b49-4060d4c27e2f',
      type: 'german',
      minimax:
        '将这段单词或内容翻译成德语，保留原文 markdown 格式。只需要返回翻译结果，不返回与原文无关内容。',
    },
    '459fee87-5cfc-4166-8b49-4060d4c27e22': {
      name: '翻译成',
      id: '459fee87-5cfc-4166-8b49-4060d4c27e22',
      type: 'OtherLanguage',
      minimax:
        '将这段单词或内容翻译成{language}，保留原文 markdown 格式。只需要返回翻译结果，不返回与原文无关内容。',
    },
    '7edcb487-2041-4ade-8483-71b9e1caba35': {
      icon: 'ic_change_writing',
      name: '改进写作',
      id: '7edcb487-2041-4ade-8483-71b9e1caba35',
      type: 'Improve',
      minimax: '改写这段内容，纠正错别字和语法错误，保留原文 markdown 格式。',
    },
    '1a8812df-9395-4eed-84be-59b5e148e7c1': {
      icon: 'ic_simpler',
      name: '更简短',
      id: '1a8812df-9395-4eed-84be-59b5e148e7c1',
      type: 'makeShorter',
      minimax: '改写这段内容，纠正错别字和语法错误，用更简短的方式润色，保留原文 markdown 格式。',
    },
    '63c58833-d02c-4a15-8039-949c78bcc9f2': {
      icon: 'ic_more_detail',
      name: '更详细',
      id: '63c58833-d02c-4a15-8039-949c78bcc9f2',
      type: 'makeLonger',
      minimax:
        '改写这段内容，纠正错别字和语法错误，用更详细的方式润色，不改变原文含义，保留原文 markdown 格式。',
    },
    '7613737d-eb65-4464-a596-167d1e3f0c00': {
      icon: 'IcAiStyle',
      name: '切换风格',
      id: '7613737d-eb65-4464-a596-167d1e3f0c00',
      subNodes: [
        '32faf939-cb35-479f-95e8-3a234791a75d',
        '68dc99f8-ebda-440e-bd9d-b1d431b24e0a',
        'f57e6b90-d5c3-453a-87ab-e6ed9d2c175f',
        '06d15689-21c4-446d-89b0-fa5cc33c3d96',
        '6a8e29b8-e6ee-4af1-87da-212f01b7925a',
      ],
    },
    '32faf939-cb35-479f-95e8-3a234791a75d': {
      name: '更专业',
      id: '32faf939-cb35-479f-95e8-3a234791a75d',
      type: 'professional',
      minimax: '用更专业的方式，改写这段内容，保留原文 markdown 格式。',
    },
    '68dc99f8-ebda-440e-bd9d-b1d431b24e0a': {
      name: '非正式',
      id: '68dc99f8-ebda-440e-bd9d-b1d431b24e0a',
      type: 'casual',
      minimax: '用非正式的方式，改写这段内容，保留原文 markdown 格式。',
    },
    'f57e6b90-d5c3-453a-87ab-e6ed9d2c175f': {
      name: '更直接',
      id: 'f57e6b90-d5c3-453a-87ab-e6ed9d2c175f',
      type: 'straightforward',
      minimax: '用简明扼要的方式，改写这段内容，保留原文 markdown 格式。',
    },
    '06d15689-21c4-446d-89b0-fa5cc33c3d96': {
      name: '更自信',
      id: '06d15689-21c4-446d-89b0-fa5cc33c3d96',
      type: 'confident',
      minimax: '用更自信的方式，改写这段内容，保留原文 markdown 格式。',
    },
    '6a8e29b8-e6ee-4af1-87da-212f01b7925a': {
      name: '更友好',
      id: '6a8e29b8-e6ee-4af1-87da-212f01b7925a',
      type: 'friendly',
      minimax: '用更友好的方式，改写这段内容，保留原文 markdown 格式。',
    },
    'bc56080a-edb8-46e2-8584-7be4c4cc5d05': {
      icon: 'ic_continue_writing',
      placeholder: '头脑风暴，关于',
      name: '头脑风暴',
      id: 'bc56080a-edb8-46e2-8584-7be4c4cc5d05',
      type: 'Brainstorm',
      minimax: '我需要你结合以下内容头脑风暴，不少于 10 条。',
    },
    '1ee4dcf6-b00d-49b3-b4d5-ec55d102fd40': {
      icon: 'ic_continue_writing',
      placeholder: '写文章，关于',
      name: '写文章',
      id: '1ee4dcf6-b00d-49b3-b4d5-ec55d102fd40',
      type: 'Blog',
      minimax:
        '我需要你根据以下内容写一篇论文。每段论述配有标题，并为文章起一个名字。文章名字用# 显示，段落标题用### 显示。',
    },
    '0fcb00ae-f47b-426f-9045-25798361ccc4': {
      icon: 'ic_continue_writing',
      placeholder: '写大纲，关于',
      name: '写大纲',
      id: '0fcb00ae-f47b-426f-9045-25798361ccc4',
      type: 'Outline',
      minimax:
        '我需要你根据以下内容列出大纲，需要从多方面拆解，并为大纲起一个名字，大纲名字写在文章开头。大纲标题用# 显示，段落标题用### 显示，大纲内容用列表显示。如果结尾有总结，总结内容用正文显示，总结标题用### 显示。',
    },
    'a15c829b-8861-47d8-a085-10e7b36d1841': {
      icon: 'ic_continue_writing',
      placeholder: '写故事，关于',
      name: '写故事',
      id: 'a15c829b-8861-47d8-a085-10e7b36d1841',
      type: 'Story',
      minimax:
        '我需要你根据以下内容写一篇故事，不少于100字，并为故事起一个名字，故事名字写在文章开头用# 显示。',
    },
    'b3d48333-ead2-4692-ab51-1f16019500cd': {
      icon: 'ic_continue_writing',
      placeholder: '日常小记，关于',
      name: '日常小记',
      id: 'b3d48333-ead2-4692-ab51-1f16019500cd',
      type: 'DailyNotes',
      minimax:
        '我需要你根据以下内容写一篇日常小记，记录内容不超过100字，语言形式轻松，可以用 emoji 表达情绪。文章结尾另起一行显示 #tag。',
    },
    'e7e1df60-bd16-47d9-a2fa-e4fec4c114cf': {
      icon: 'ic_continue_writing',
      placeholder: '分析优缺点，关于',
      name: '分析优缺点',
      id: 'e7e1df60-bd16-47d9-a2fa-e4fec4c114cf',
      type: 'ProsAndConsList',
      minimax:
        '我需要你综合以下内容分析优缺点，用列表分别列出优点和缺点，不少于5条，如果有段落标题用### 显示。',
    },
    'f2eb1bda-7e1f-4ece-91d1-4c2c2d1f06cf': {
      icon: 'ic_continue_writing',
      placeholder: '列出执行步骤，关于',
      name: '列出执行步骤',
      id: 'f2eb1bda-7e1f-4ece-91d1-4c2c2d1f06cf',
      type: 'ActionItems',
      minimax: '我需要你根据以下内容列出执行步骤。',
    },
    'f2eb1bda-7e1f-4ece-91d1-4c2c2d1f06cc': {
      icon: 'ic_continue_writing',
      name: '解释一下',
      id: 'f2eb1bda-7e1f-4ece-91d1-4c2c2d1f06cc',
      type: 'explain',
      minimax: '解释一下,关于',
    },
    'f2eb1bda-7e1f-4ece-91d1-4c2c2daf06cd': {
      icon: 'IcTableMiddle',
      name: '用多维表起草',
      id: 'f2eb1bda-7e1f-4ece-91d1-4c2c2daf06cd',
      subNodes: [
        '5f4bf941-d35e-4d1e-96c3-976a4f8ab1d1',
        'c9f301a3-35ec-4ab9-8b2d-ef74340a4701',
        '6485520b-3dbc-469a-99b6-c7ecf4217a56',
        'b177392a-b4c8-45cf-b834-1378d317aa94',
      ],
    },
    '5f4bf941-d35e-4d1e-96c3-976a4f8ab1d1': {
      placeholder: '写一份计划，关于',
      name: '写计划',
      id: '5f4bf941-d35e-4d1e-96c3-976a4f8ab1d1',
      type: 'plan',
      minimax: '我需要你结合以下内容制作一份计划，用表格形式列出。',
    },
    'c9f301a3-35ec-4ab9-8b2d-ef74340a4701': {
      placeholder: '写一份清单，关于',
      name: '写清单',
      id: 'c9f301a3-35ec-4ab9-8b2d-ef74340a4701',
      type: 'list',
      minimax: '我需要你结合以下内容制作一份清单，用表格形式列出。',
    },
    '6485520b-3dbc-469a-99b6-c7ecf4217a56': {
      placeholder: '写一份任务管理，关于',
      name: '任务管理',
      id: '6485520b-3dbc-469a-99b6-c7ecf4217a56',
      type: 'task',
      minimax: '现在你是任务管理专家，我需要你结合以下内容制作一份任务管理模板，用表格形式列出。',
    },
    'b177392a-b4c8-45cf-b834-1378d317aa94': {
      icon: 'ic_table_24',
      placeholder: '',
      name: '其他...',
      id: 'b177392a-b4c8-45cf-b834-1378d317aa94',
      type: 'other',
      minimax:
        '现在你是Excel，我需要你结合以下内容，用表格形式制作一份模板，提炼重要线索作为表头，目的是长期跟踪与信息整理。',
    },
    'f2eb1bda-7e1f-4ece-9121-4c2c1daf06cd': {
      icon: 'IcMindmapMiddle',
      name: '用思维导图起草',
      id: 'f2eb1bda-7e1f-4ece-9121-4c2c1daf06cd',
      subNodes: [
        '0f74c3ef-efbc-41ae-b6fb-04dd8e429d3d',
        '6d5d4666-94f8-471e-84e9-ae80acb50b39',
        'bd6048f5-b856-42aa-968e-b93c781679a3',
      ],
    },
    '0f74c3ef-efbc-41ae-b6fb-04dd8e429d3d': {
      name: '写大纲',
      id: '0f74c3ef-efbc-41ae-b6fb-04dd8e429d3d',
      type: 'mindOutline',
      minimax:
        '结合内容写大纲， 需要从多方面拆解，并为大纲起一个名字。大纲标题用# 显示，段落标题用### 显示，大纲用列表显示。\n\n内容：咖啡\n\n# 咖啡\n\n  ### 1. 咖啡的历史与起源\n\n    - 咖啡的发现与传播\n\n    - 咖啡的起源地和种植地区\n\n  ### 2. 咖啡的种类与制作方法\n\n    - 咖啡豆的分类与特点\n\n    - 咖啡的烘焙过程和不同烘焙度的特色\n\n    - 常见的咖啡制作方法：滴滤、浸泡、意式浓缩等\n\n内容：',
    },
    '6d5d4666-94f8-471e-84e9-ae80acb50b39': {
      name: '写计划',
      id: '6d5d4666-94f8-471e-84e9-ae80acb50b39',
      type: 'mindPlan',
      minimax:
        '结合内容写计划，并为计划起一个名字。计划标题用# 显示，段落标题用### 显示，步骤用列表显示，示例如下:\n\n======\n内容：考研\n\n# 考研备战计划\n\n  ### 第一阶段：确定备考范围和目标\n\n    - 确定报考的学校和专业\n\n    - 查阅考研招生简章，了解报考要求和考试科目\n\n    - 制定总体备考进度和目标\n\n  ### 第二阶段：学习基础知识\n\n    - 复习本科阶段的核心知识点\n\n    - 构建自己的知识体系，填补知识盲点\n\n    - 认真学习教材，掌握基本概念和理论知识\n\n  ### 第三阶段：刷题和做模拟\n\n    - 针对每个科目制定刷题计划，逐步提高解题能力\n\n    - 定期参加模拟考试，熟悉考试形式和节奏\n\n    - 分析\n===\n\n内容：\n\n',
    },
    'bd6048f5-b856-42aa-968e-b93c781679a3': {
      name: '其他...',
      id: 'bd6048f5-b856-42aa-968e-b93c781679a3',
      type: 'mindOther',
      minimax:
        '结合内容写出特定格式的 markdown。标题用# 显示，段落标题用### 显示，详细情况用列表显示，示例如下:\n\n======\n内容：考研\n\n# 考研备战计划\n\n  ### 第一阶段：确定备考范围和目标\n\n    - 确定报考的学校和专业\n\n    - 查阅考研招生简章，了解报考要求和考试科目\n\n    - 制定总体备考进度和目标\n\n  ### 第二阶段：学习基础知识\n\n    - 复习本科阶段的核心知识点\n\n    - 构建自己的知识体系，填补知识盲点\n\n    - 认真学习教材，掌握基本概念和理论知识\n\n  ### 第三阶段：刷题和做模拟\n\n    - 针对每个科目制定刷题计划，逐步提高解题能力\n\n    - 定期参加模拟考试，熟悉考试形式和节奏\n\n    - 分析\n===\n\n内容：\n\n',
    },
  },
};

const fixIconName = (name?: string) => {
  if (!name) return;
  if (name === 'ic_table_24') {
    name = 'ic_table_ai';
  }
  if (name.includes('_')) {
    const splitArr = name.split('_');
    const fixed = splitArr.map((v) => firstToUpperCase(v)).join('');
    return fixed;
  }
  return name;
};
const firstToUpperCase = (text: string) => {
  if (text.length > 0) {
    const first = text.slice(0, 1).toUpperCase();
    const rest = text.slice(1);
    return first + rest;
  }
  return text;
};
const fixContent = (text?: string) => {
  if (!text) return;
  return `${text}\n"""\n{input}\n"""`;
};
// fix
const keys = Object.keys(systemPromptData.records);
for (const key of keys) {
  const v = systemPromptData.records[key]!;
  systemPromptData.records[key] = {
    ...v,
    icon: fixIconName(v.icon),
    minimax: fixContent(v.minimax),
  };
}
/** 是否是系统预置 */
export const isSystemPrompt = (promptId?: string) => {
  if (!promptId) return false;
  return !!systemPromptData.records[promptId];
};
