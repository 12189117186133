import { deepEqual } from '@flowus/common/utils/tools';
import isHotkey from 'is-hotkey';
import type { FC } from 'react';
import { memo, useEffect, useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { useBitable } from 'src/bitable/context';
import { ILLEGAL_TEXT, UNTITLED } from 'src/common/const';
import { BlockDefaultIcon } from 'src/components/block-default-icon';
import { IconTrigger } from 'src/components/icon-trigger';
import { BlockDrop } from 'src/editor/editor/plugin/dnd/block-drop';
import { RichTextEdit } from 'src/editor/editor/uikit/editable';
import { TITLE_EDITOR_PLUGINS } from 'src/editor/editor/uikit/editable/plugins';
import { RichText } from 'src/editor/editor/uikit/editable/rich-text';
import { HoverMenu } from 'src/editor/editor/uikit/hover-menu';
import { MembersCoordinate } from 'src/editor/editor/uikit/members-coordinate';
import { Selector } from 'src/editor/editor/uikit/selector';
import { convertContentToSegments } from 'src/editor/utils/segments';
import { useBlock } from 'src/hooks/block/use-block';
import { useUpdatePropertyValue } from 'src/hooks/block/use-update-property-value';
import { useBiTableGroupsAndProvideRecordWalker } from 'src/hooks/collection-view/table-groups';
import type { TableGroupData } from 'src/hooks/collection-view/table-groups/select-collection-groups';
import { useShowingTablePageIcon } from 'src/hooks/collection-view/use-collection-view';
import { useHoveringEmpty } from 'src/hooks/page/use-dnd/hooks';
import { useOpenPage } from 'src/hooks/page/use-open-page';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { setAppUiState, useNewCreatedRecord, useTableGroupIsUnFolded } from 'src/services/app';
import { usePickBlock } from 'src/utils/pick-block';
import { BlockDiscussionsBadge } from 'src/views/comments/block-discussions-badge';
import { usePageScrollRef } from 'src/views/main/page-doc/context';
import { AddGroup } from '../add-group';
import { AddRecord } from '../add-record';
import { PropertyValues } from '../board-view/card/property-values';
import { GroupHeader } from '../group-header';
import { BitableLoadMoreContext, useBitableLoadMoreContext } from '../hooks';
import { useUpdateGroups } from '../hooks/ues-check-group';
import { BitableLoadMore } from '../load-more';
import { useInitGroupFoldStatus } from '../table-view';
import { ToggleHiddenGroup } from '../toggle-hidden-group';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import { isEnableVirtuoso, OVERSCAN } from '../const';

export const TableListView: FC = () => {
  const { viewId } = useBitable();
  const biTableGroups = useBiTableGroupsAndProvideRecordWalker(viewId);

  return (
    <BitableLoadMoreContext recordIds={biTableGroups?.sortedRecordIds || []}>
      <TableListBody />
    </BitableLoadMoreContext>
  );
};

const TableListBody: FC = () => {
  const { viewId, readonly, embed } = useBitable();
  const biTableGroups = useBiTableGroupsAndProvideRecordWalker(viewId);
  const hoveringEmpty = useHoveringEmpty(viewId);
  const { recordIds: renderIds } = useBitableLoadMoreContext();

  useInitGroupFoldStatus();
  useUpdateGroups(biTableGroups);

  const HoverMenu0 = useMemo(
    () => (!embed ? <HoverMenu readonly={readonly} /> : <></>),
    [embed, readonly]
  );

  const pageRef = usePageScrollRef();
  const [container, setContainer] = useState(pageRef?.current);
  useEffect(() => {
    setContainer(pageRef?.current);
  }, [container, pageRef]);

  if (!container) return null;
  if (!biTableGroups) return null;

  const { withoutValidGroup, sortedRecordIds, visibleGroups, hiddenGroups } = biTableGroups;

  if (withoutValidGroup) {
    return (
      <div
        data-is-empty={sortedRecordIds.length === 0 ? true : null}
        data-view-id={viewId}
        className="block-list group relative border-t border-grey6 sm:w-max min-w-[500px]"
      >
        <Virtuoso
          customScrollParent={container}
          overscan={OVERSCAN}
          increaseViewportBy={OVERSCAN}
          skipAnimationFrameInResizeObserver
          computeItemKey={(index) => index}
          fixedItemHeight={38}
          totalCount={renderIds.length}
          itemContent={(index) => {
            const recordId = renderIds[index];
            if (!recordId) return null;
            return <RecordItem key={recordId} uuid={recordId} />;
          }}
        />

        {HoverMenu0}
        {!readonly && !embed && <Selector type="collection" />}
        {!readonly && !embed && <MembersCoordinate />}

        {hoveringEmpty && (
          <div className="absolute top-0 left-0 h-1 w-full bg-black/30 opacity-0 group-hover:opacity-100"></div>
        )}

        <BitableLoadMore />

        <AddRecord />
      </div>
    );
  }

  return (
    <div className="block-list relative sm:w-max min-w-[500px]">
      {isEnableVirtuoso(visibleGroups.length) ? (
        <Virtuoso
          customScrollParent={container}
          overscan={OVERSCAN}
          increaseViewportBy={OVERSCAN}
          skipAnimationFrameInResizeObserver
          computeItemKey={(index) => index}
          totalCount={visibleGroups.length}
          itemContent={(index) => {
            const tableGroup = visibleGroups[index];
            if (!tableGroup) return null;
            return (
              <BitableLoadMoreContext recordIds={tableGroup.recordIds}>
                <ListGroup key={tableGroup.value} tableGroup={tableGroup} />;
              </BitableLoadMoreContext>
            );
          }}
        />
      ) : (
        <>
          {visibleGroups.map((tableGroup) => (
            <BitableLoadMoreContext recordIds={tableGroup.recordIds}>
              <ListGroup key={tableGroup.value} tableGroup={tableGroup} />
            </BitableLoadMoreContext>
          ))}
        </>
      )}

      <ToggleHiddenGroup
        hiddenGroupsLength={hiddenGroups.length}
        hiddenGroups={
          isEnableVirtuoso(hiddenGroups.length) ? (
            <Virtuoso
              customScrollParent={container}
              overscan={OVERSCAN}
              increaseViewportBy={OVERSCAN}
              skipAnimationFrameInResizeObserver
              computeItemKey={(index) => index}
              totalCount={hiddenGroups.length}
              itemContent={(index) => {
                const tableGroup = hiddenGroups[index];
                if (!tableGroup) return null;
                return (
                  <BitableLoadMoreContext recordIds={tableGroup.recordIds}>
                    <ListGroup key={tableGroup.value} tableGroup={tableGroup} />
                  </BitableLoadMoreContext>
                );
              }}
            />
          ) : (
            <>
              {hiddenGroups.map((tableGroup) => (
                <BitableLoadMoreContext recordIds={tableGroup.recordIds}>
                  <ListGroup key={tableGroup.value} tableGroup={tableGroup} />
                </BitableLoadMoreContext>
              ))}
            </>
          )
        }
      />

      {HoverMenu0}
      {!readonly && !embed && <Selector type="collection" />}
      {!readonly && !embed && <MembersCoordinate />}

      <AddGroup />
    </div>
  );
};

interface ListGroupProps {
  tableGroup: TableGroupData;
}
const ListGroup: FC<ListGroupProps> = memo(({ tableGroup }) => {
  const { viewId } = useBitable();
  const { recordIds, value, groupProperty } = tableGroup;
  const hoveringEmpty = useHoveringEmpty(viewId);
  const isUnFolded = useTableGroupIsUnFolded(viewId, value);
  const { recordIds: renderIds } = useBitableLoadMoreContext();

  const pageRef = usePageScrollRef();
  if (!pageRef.current) return null;

  return (
    <div
      data-is-empty={recordIds.length === 0 ? true : null}
      data-view-id={viewId}
      data-group-value={value}
      data-group-property={groupProperty}
      className={'group relative pb-5'}
    >
      <GroupHeader tableGroup={tableGroup} />

      {hoveringEmpty && (
        <div className="absolute top-10 left-0 h-1 w-full bg-black/30 opacity-0 group-hover:opacity-100"></div>
      )}

      {isUnFolded && (
        <>
          <Virtuoso
            customScrollParent={pageRef.current}
            overscan={OVERSCAN}
            increaseViewportBy={OVERSCAN}
            skipAnimationFrameInResizeObserver
            fixedItemHeight={38}
            computeItemKey={(index) => index}
            totalCount={renderIds.length}
            itemContent={(index) => {
              const recordId = renderIds[index];
              if (!recordId) return null;
              return <RecordItem key={recordId} groupValue={value} uuid={recordId} />;
            }}
          />

          <BitableLoadMore />

          <AddRecord
            where={{ after: recordIds[recordIds.length - 1] }}
            groupName={tableGroup.value}
          />
        </>
      )}
    </div>
  );
}, deepEqual);

interface RecordItemProps {
  uuid: string;
  groupValue?: string;
}

const RecordItem: FC<RecordItemProps> = ({ uuid, groupValue }) => {
  const block = usePickBlock(uuid, ['data'], ['segments']);
  const { illegal } = usePermissions(uuid);
  const { viewId } = useBitable();
  const openPage = useOpenPage();

  if (!block) return null;

  return (
    <div className="py-px">
      <BlockDrop
        id={uuid}
        viewId={viewId}
        groupValue={groupValue}
        onClick={(event) =>
          openPage(uuid, {
            illegal,
            forceOpenInRight: event.altKey,
            forceOpenNewTab: event.ctrlKey || event.metaKey,
          })
        }
        className="animate-hover-black3 flex h-9 cursor-pointer items-center justify-between rounded pr-1"
      >
        {illegal && (
          <div className="flex min-w-[240px] items-center whitespace-nowrap opacity-30">
            <IconTrigger
              className="mr-1 inline-block h-5 w-5 min-w-[20px] p-0 align-middle"
              blockId={uuid}
              trigger={false}
              iconSize={20}
              defaultIcon={<BlockDefaultIcon uuid={uuid} size="middle" />}
            />
            <span className="text-t2 text-ellipsis border-b border-grey6 align-middle">
              {ILLEGAL_TEXT}
            </span>
          </div>
        )}

        {!illegal && (
          <>
            <ListTitle recordId={uuid} />
            <PropertyValues recordId={uuid} />
          </>
        )}
      </BlockDrop>
    </div>
  );
};

const ListTitle: FC<{ recordId: string }> = ({ recordId }) => {
  const { viewId } = useBitable();
  const updateValue = useUpdatePropertyValue();
  const block = useBlock(recordId);
  const newCreateRecord = useNewCreatedRecord();
  const isNewCreateRecordId = recordId === newCreateRecord?.id && viewId === newCreateRecord.viewId;
  const isShowPageIcon = useShowingTablePageIcon(viewId);

  const [showEditor, setShowEditor] = useState(isNewCreateRecordId);
  const getEditorModelByBlockId = useGetOrInitEditorModel();

  if (!block) return null;

  return (
    <div className={'flex min-w-[240px] items-center whitespace-nowrap max-w-[50%]'}>
      {isShowPageIcon && (
        <IconTrigger
          tooltipClassName="align-middle"
          className="mr-1 inline-block h-5 w-5 min-w-[20px] p-0 align-middle"
          blockId={recordId}
          // trigger={false}
          iconSize={20}
          defaultIcon={<BlockDefaultIcon uuid={recordId} size="middle" />}
        />
      )}
      <span className="text-t2 text-ellipsis border-b border-grey6 align-middle">
        {showEditor ? (
          <RichTextEdit
            uuid={recordId}
            className="text-t2-medium inline break-words align-middle"
            plugins={TITLE_EDITOR_PLUGINS}
            keydownOption={{ bracketRightKey: false }}
            placeholder={UNTITLED}
            autoFocus={true}
            autoFocusCaretToEnd={true}
            toolbar={false}
            segments={block.data.segments}
            interactable={isNewCreateRecordId}
            // onChange={(segment) => updateValue(recordId, 'title', segment)}
            onBlur={() => {
              const editorModel = getEditorModelByBlockId(recordId);
              if (editorModel) {
                updateValue(recordId, 'title', convertContentToSegments(editorModel.content));
              }
              setShowEditor(false);
              setAppUiState({ $newCreatedRecord: undefined });
            }}
            onKeyDown={(event) => {
              if (isHotkey('mod+a')(event)) {
                event.stopPropagation();
                return;
              }
              if (isHotkey('enter')(event) || isHotkey('esc')(event)) {
                (event.currentTarget as HTMLDivElement).blur();
              }
            }}
          />
        ) : (
          <RichText
            className="text-t2-medium inline break-words align-middle"
            plugins={TITLE_EDITOR_PLUGINS}
            segments={block?.data.segments}
            placeholder={UNTITLED}
            interactable={false}
          />
        )}
      </span>

      <BlockDiscussionsBadge
        className="inline-flex align-middle pointer-events-none"
        blockId={recordId}
      />
    </div>
  );
};
