import type { IElementComponent, IElementMeta } from '@next-space/fe-inlined';
import { normalizeFormat, registerElementMeta } from '@next-space/fe-inlined';
import type { FC } from 'react';
import { Tooltip } from 'src/common/components/tooltip';
import { useComputeCommentStyles } from 'src/editor/editor/inline/comment';
import { INLINE_LINK_SUGGEST_TAG } from 'src/editor/editor/inline/const';
import type { InlinePlugin } from 'src/editor/editor/inline/inline-plugin';
import { DEFAULT_PLUGINS } from 'src/editor/editor/uikit/editable/plugins';
import { RichText } from 'src/editor/editor/uikit/editable/rich-text';
import { segmentsToText } from 'src/editor/utils/editor';
import { useOpenPage } from 'src/hooks/page/use-open-page';
import { usePickBlock } from 'src/utils/pick-block';

import { BackLinkGroup } from '../components/back-link-group';
import type { SuggestState } from '../types';
import { CreateLink } from './create-link';
import type { useSuggest } from './hooks';
import { UpgradeSuggest } from './upgrade-suggest';

interface Props {
  uuid?: string;
  title: string;
  fetchSuggest: () => Promise<void>;
  type: keyof SuggestState;
}

export const CommonSuggest: FC<
  Props & Omit<ReturnType<typeof useSuggest>, 'setPages' | 'spaceId' | 'block'>
> = ({
  uuid,
  fetchSuggest,
  show,
  setShow,
  pages,
  pageIds,
  upgrade,
  isPro,
  linkAll,
  title,
  type,
  isLocked,
}) => {
  const openPage = useOpenPage();

  return (
    <div hidden={!pageIds.length}>
      <div className="flex justify-between text-t2 text-grey3 p-3">
        <div
          className="animate-click"
          onClick={() => {
            void fetchSuggest();
            setShow((pre) => !pre);
          }}
        >
          {title}
        </div>
        {!!pageIds.length && isPro && (
          <div className="animate-click" onClick={linkAll}>
            全部建立引用
          </div>
        )}
      </div>

      {show &&
        pageIds.map((id) => (
          <BackLinkGroup key={id} uuid={id}>
            {pages?.[id]?.map((o, i) => (
              <div
                key={i}
                onClick={(event) => {
                  openPage(uuid || id, {
                    hash: `#${o.uuid}`,
                    forceOpenInRight: event.altKey,
                    forceOpenNewTab: event.ctrlKey || event.metaKey,
                  });
                }}
                className="group flex items-center justify-between animate-hover"
              >
                <Tooltip className="flex items-center" popup={segmentsToText(o.segments)}>
                  <div className="flex-shrink-0 text-t2 text-right w-12 mr-1">{i + 1}.</div>
                  <RichText
                    segments={o.segments}
                    className="leading-7 pointer-events-none w-full"
                    plugins={[...DEFAULT_PLUGINS, SuggestPageInlinePlugin]}
                  />
                </Tooltip>

                {!isLocked && (
                  <CreateLink
                    type={type}
                    uuid={o.uuid}
                    segments={o.segments}
                    upgrade={upgrade}
                    callback={fetchSuggest}
                  />
                )}
              </div>
            ))}
          </BackLinkGroup>
        ))}

      {!isPro && <UpgradeSuggest onClick={upgrade} />}
    </div>
  );
};

const SuggestInlineLinkPage: IElementComponent = ({
  pageId,
  htmlDataProps,
  textFormat: textFormat0,
}) => {
  const block = usePickBlock(pageId as string, ['data'], ['segments']);
  const textFormat = normalizeFormat(textFormat0 as any);
  const commentStyles = useComputeCommentStyles(textFormat);

  if (!block) return null;

  const styles = { ...commentStyles };
  if (textFormat.lineThrough) {
    if (styles.textDecoration != null) {
      styles.textDecoration += ' line-through';
    } else {
      styles.textDecoration = 'line-through';
    }
  }

  return (
    <span className="mx-0.5 px-1 bg-grey5 rounded-sm" {...htmlDataProps} style={styles}>
      {segmentsToText(block.data.segments)}
    </span>
  );
};

const INLINE_SUGGEST_LINK_PAGE_META: IElementMeta = {
  tag: INLINE_LINK_SUGGEST_TAG,
  hasContent: false,
  setFormat: (element, format) => {
    element.props.textFormat = normalizeFormat(format, element.props.textFormat as any);
  },
};

registerElementMeta(INLINE_SUGGEST_LINK_PAGE_META);

const SuggestPageInlinePlugin: InlinePlugin = {
  elementMeta: INLINE_SUGGEST_LINK_PAGE_META,
  initialize(api) {
    api.setElementComponent(this.elementMeta.tag, SuggestInlineLinkPage);
  },
};
