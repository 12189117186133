import { cx } from '@flowus/common/cx';
import { useOpenModal } from '@flowus/common/next-modal';
import type { FC } from 'react';
import { Modals } from 'src/modals';
import { ChartConfigPanel } from './chart-config-panel';
import { useOpenNewDiscussionPopup } from 'src/views/comments/use-open-new-discussion-popup';
import { dispatch } from 'src/redux/store';
import { uiActions } from 'src/redux/reducers/ui';
import { updateBlock } from 'src/redux/managers/block/update';
import { useFocusEditableByBlockId } from 'src/hooks/editor/use-focus-by-id';
import { covertBlockToTitle } from '../menu-list/menu-item';
import { bizTracker } from 'src/utils/biz-tracker';
import { useReadonly } from 'src/hooks/page';
import { AlignPanel } from '../align-panel';
import { Tooltip } from '@flowus/common/tooltip';
import { Icon } from 'src/common/components/icon';
import { useOpenBlockRightMenuList } from 'src/editor/editor/uikit/hover-menu/block-menu-list/hook';

interface Props {
  blockId: string;
  className?: string;
}
export const ChartPageToolbar: FC<Props> = (props) => {
  const readonly = useReadonly(props.blockId);
  const openModal = useOpenModal();
  const openNewDiscussionPopup = useOpenNewDiscussionPopup();
  const focusEditableAt = useFocusEditableByBlockId();
  const openBlockRightMenuList = useOpenBlockRightMenuList();
  if (readonly) return null;
  return (
    <div className={cx('text-t3 flex items-center h-7.5', props.className)}>
      <Tooltip
        popup="配置"
        className="w-8 h-7.5 animate-hover animate-click shrink-0 rounded-none"
        onClick={() => {
          openModal.modal({
            autoClose: false,
            modalId: Modals.CHART_PANEL_ID,
            className: 'rounded-md',
            content: () => {
              return <ChartConfigPanel id={props.blockId} />;
            },
          });
        }}
      >
        <Icon size="middle" className="text-grey2" name={'IcSettingsMiddle'} />
      </Tooltip>

      <div className="h-full bg-grey8 w-px" />
      <Tooltip
        popup="对齐方式"
        className="w-8 h-7.5 animate-hover animate-click shrink-0 rounded-none"
        onClick={(e) => {
          openModal.dropdown({
            placement: 'top',
            popcorn: e.currentTarget,
            content: ({ onCloseModal }) => (
              <AlignPanel blockIds={[props.blockId]} closeModal={onCloseModal} />
            ),
          });
        }}
      >
        <Icon size="middle" className="text-grey2" name={'IcAlignLeft'} />
      </Tooltip>
      <div className="h-full bg-grey8 w-px" />
      <Tooltip
        popup="评论"
        className="w-8 h-7.5 animate-hover animate-click shrink-0 rounded-none"
        onClick={(e) => {
          openNewDiscussionPopup({ blockId: props.blockId, popcorn: e.currentTarget });
        }}
      >
        <Icon size="middle" className="text-grey2" name={'IcCommentSmall'} />
      </Tooltip>

      <div className="h-full bg-grey8 w-px" />
      <Tooltip
        popup="添加说明"
        className="w-8 h-7.5 animate-hover animate-click shrink-0  rounded-none"
        onClick={() => {
          dispatch(uiActions.updateSelectBlocks([]));
          updateBlock(props.blockId, {
            data: {
              showEmptyCaption: true,
              format: {
                isShowCaption: true,
              },
            },
          });

          focusEditableAt(props.blockId, 0, 'caption');
          bizTracker.event('block_caption', {
            block_name: covertBlockToTitle(props.blockId),
          });
        }}
      >
        <Icon size="middle" className="text-grey2" name={'IcMenuListDescription'} />
      </Tooltip>
      <Tooltip
        popup="更多"
        className="w-8 h-7.5 animate-hover animate-click shrink-0  rounded-none"
        onClick={(event) => {
          const blockNode = document.querySelector(`[data-block-id="${props.blockId}"]`);
          openBlockRightMenuList({
            menuPosition: event.currentTarget as HTMLElement,
            blockId: props.blockId,
            blockNode: blockNode as HTMLElement,
            placement: 'right-end',
            offset: [0, 8],
          });
        }}
      >
        <Icon size="middle" className="text-grey2" name={'IcMore'} />
      </Tooltip>
    </div>
  );
};
