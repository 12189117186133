import { cx } from '@flowus/common/cx';
import type { FormLogicRule } from '@next-space/fe-api-idl';
import { useUnmount } from 'ahooks';
import produce from 'immer';
import { useAtom } from 'jotai';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { Select } from 'src/common/components/select';
import { SortableList } from 'src/common/components/sortable-list';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import { useCollectionView } from 'src/hooks/collection-view/use-collection-view';
import { useProperties } from 'src/hooks/collection-view/use-properties';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { bizTracker } from 'src/utils/biz-tracker';
import { OperandValue, OperatorSelect } from '../bitable-manager/config-table-filter';
import type { SettingProps } from '../bitable-manager/type';
import { FILTER_PROPERTIES_FORM, ICON_MAP } from '../const';
import { useBitable } from '../context';
import { $highlightProperty } from './form-logic-atoms';
import { preprocessFormLogic } from './form-logic-utils';
import { updateFilterField } from '../table-view/body/filters';
import { SPECIAL_SPACE_ID } from 'src/common/const';

export const FormLogicSetting: FC<SettingProps> = ({ isFromViewSetting }) => {
  const { viewId, collectionId, isLocked } = useBitable();
  const view = useCollectionView(viewId);
  const [properties = []] = useProperties(viewId, { visible: true });
  const schema = useObservableBlock(collectionId, (block) => {
    return block?.data.schema ?? {};
  });
  const formLogic = preprocessFormLogic(
    schema,
    properties.map((it) => it.property),
    view?.format.formLogic ?? []
  );
  const [highlightProperty, setHighlightProperty] = useAtom($highlightProperty);

  const createRule = () => {
    const newFormLogic = formLogic.slice(0);
    if (properties.length === 0) return;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const property = properties[0]!;

    newFormLogic.push({
      condition: {
        property: property.property,
        propertyType: property.type,
        operator: 'isNotEmpty',
        type: 'filter',
        value: '',
      },
      gotoProperty: SPECIAL_SPACE_ID,
    });
    updateViewFormat(viewId, {
      formLogic: newFormLogic,
    });
    bizTracker.event('form_logic_manage', { manage_type: 'create' });
  };

  const updateRule = (index: number, rule: FormLogicRule) => {
    const newFormLogic = formLogic.slice(0);
    newFormLogic[index] = rule;
    updateViewFormat(viewId, {
      formLogic: newFormLogic,
    });
  };

  const deleteRule = (index: number) => {
    const newFormLogic = formLogic.slice(0);
    newFormLogic.splice(index, 1);
    updateViewFormat(viewId, {
      formLogic: newFormLogic,
    });
    bizTracker.event('form_logic_manage', { manage_type: 'delete' });
  };

  const saveSortedRules = (items: { id: string; rule: FormLogicRule }[]) => {
    updateViewFormat(viewId, {
      formLogic: items.map((it) => it.rule),
    });
  };

  useUnmount(() => {
    setHighlightProperty(null);
  });

  return (
    <div className={cx('min-w-[560px]', !isFromViewSetting && 'next-modal-scroll')}>
      {formLogic.length === 0 && (
        <div className="text-grey3 text-t2 mx-4 my-2 select-none">当前无跳题逻辑</div>
      )}
      <SortableList
        className="min-h-0 max-h-[calc(80vh-90px)] flex-1 overflow-y-auto"
        onlyHandleDraggable
        items={formLogic.map((it, index) => ({
          id: `${index}`,
          rule: it,
        }))}
        renderItemContent={({ item: { id, rule }, renderDragHandle }) => {
          const index = Number(id);
          return (
            <div
              className={cx(
                'px-4 py-2 space-y-2 border-grey6 text-t2',
                id !== '0' && 'border-t',
                (highlightProperty === rule.condition.property ||
                  highlightProperty === rule.gotoProperty) &&
                  'bg-light_yellow bg-opacity-20'
              )}
            >
              <div className="flex items-center">
                <div className="flex flex-1 min-w-0 items-center">
                  <div className="flex items-center justify-between w-[64px] text-grey3">
                    {renderDragHandle({ className: 'cursor-pointer' })}
                    <span className="select-none">若</span>
                  </div>
                  <PropertySelect
                    className="w-[160px] mx-2"
                    value={rule.condition.property}
                    onChange={(value) => {
                      updateRule(
                        index,
                        produce(rule, (draft) => {
                          updateFilterField(collectionId, draft.condition, 'property', value);
                        })
                      );
                    }}
                  />
                  <OperatorSelect
                    className="mr-2"
                    property={rule.condition.property}
                    value={rule.condition.operator}
                    onChange={(value) => {
                      updateRule(
                        index,
                        produce(rule, (draft) => {
                          updateFilterField(collectionId, draft.condition, 'operator', value);
                        })
                      );
                    }}
                  />
                  <OperandValue
                    path={[]}
                    className="w-[160px]"
                    property={rule.condition.property}
                    operator={rule.condition.operator}
                    value={rule.condition.value}
                    onChange={(value) => {
                      updateRule(
                        index,
                        produce(rule, (draft) => {
                          updateFilterField(collectionId, draft.condition, 'value', value);
                        })
                      );
                    }}
                  />
                </div>
                {!isLocked && (
                  <Icon
                    size="large"
                    className="ml-3 cursor-pointer text-grey3 hover:text-grey1"
                    name="IcToastClose"
                    onClick={() => {
                      deleteRule(index);
                    }}
                  />
                )}
              </div>
              <div className="flex items-center">
                <div className="flex justify-end w-[64px] text-grey3">
                  <span className="select-none">则跳转</span>
                </div>
                <PropertySelect
                  className="w-[160px] mx-2"
                  value={rule.gotoProperty}
                  mustGtValue={rule.condition.property}
                  allowEndPage
                  onChange={(value) => {
                    updateRule(Number(id), {
                      ...rule,
                      gotoProperty: value,
                    });
                  }}
                />
                <span className="text-grey3 select-none">，不符合条件将正常进入下一题</span>
              </div>
            </div>
          );
        }}
        onChange={saveSortedRules}
      />
      <button
        className={cx(
          'flex items-center w-full h-10 p-2 animate-hover pl-4 border-t border-grey6 text-grey3',
          isLocked && 'cursor-not-allowed'
        )}
        onClick={createRule}
        disabled={isLocked}
      >
        <Icon className="mr-1" name="IcAddSmallBold" size="small" />
        <span className="text-t2">创建跳题逻辑</span>
      </button>
    </div>
  );
};

const PropertySelect: FC<{
  className?: string;
  value: string;
  mustGtValue?: string;
  allowEndPage?: boolean;
  onChange?: (value: string) => void;
}> = ({ className, value, mustGtValue, onChange, allowEndPage = false }) => {
  const { viewId, isLocked } = useBitable();
  const [properties = []] = useProperties(viewId, { visible: true });
  const visibleProperties = properties.filter((it) => !FILTER_PROPERTIES_FORM.includes(it.type));
  const mustGtIndex = mustGtValue
    ? visibleProperties.findIndex((it) => it.property === mustGtValue)
    : -1;
  return (
    <Select
      readonly={isLocked}
      className={className}
      dropdownClassName="max-w-[260px]"
      value={value}
      onChange={onChange}
      options={[
        ...visibleProperties
          .map((it, index) => {
            const { type } = it;
            const key = it.property;
            return {
              value: key,
              title: `${index + 1}. ${it.name}`,
              icon: type && ICON_MAP[type],
            };
          })
          .filter((_it, index) => index > mustGtIndex),
        ...(allowEndPage
          ? [
              {
                value: SPECIAL_SPACE_ID,
                title: '结束页',
                icon: 'IcEndPage' as any,
              },
            ]
          : []),
      ]}
    />
  );
};
