import { EmbedType } from '@flowus/common/embed-website';
import { BlockType } from '@next-space/fe-api-idl';
import { ListItemType } from 'src/common/components/list-view';
import { getIsDarkMode } from 'src/hooks/public/use-theme';
import { ossImagesPath } from 'src/image';
import { MenuListActionType } from './types';

/** 第三方应用数据 */
export const getEmbedData = () => {
  return [
    {
      title: 'Figma',
      placeholder: 'https://www.figma.com/...',
      embedType: EmbedType.figma,
    },
    {
      title: 'Airtable',
      placeholder: 'https://www.airtable.com/...',
      embedType: EmbedType.airtable,
    },
    {
      title: 'Sketch',
      placeholder: 'https://www.sketch.com/...',
      embedType: EmbedType.sketch,
    },
    {
      title: '墨刀',
      placeholder: 'https://www.modao.cc/...',
      embedType: EmbedType.modao,
    },
    {
      title: '摹客',
      placeholder: 'https://app.mockplus.cn/...',
      embedType: EmbedType.mockplus,
      keywords: ['mockplus'],
    },
    {
      title: 'Excalidraw',
      placeholder: 'https://www.excalidraw.com/...',
      embedType: EmbedType.excalidraw,
    },
    {
      title: 'ProcessOn',
      placeholder: 'https://www.processon.com/...',
      embedType: EmbedType.processon,
    },
    {
      title: 'Canva',
      placeholder: 'https://www.canva.cn/...',
      embedType: EmbedType.canva,
    },
    {
      title: 'Bilibili',
      placeholder: 'https://www.bilibili.com/...',
      embedType: EmbedType.bilibili,
    },
    {
      title: '腾讯视频',
      placeholder: 'https://v.qq.com/...',
      embedType: EmbedType.vqq,
      keywords: ['tencentvideo'],
    },
    {
      title: '优酷视频',
      placeholder: 'https://v.youku.com/...',
      embedType: EmbedType.youku,
      keywords: ['youku'],
    },
    {
      title: '西瓜视频',
      placeholder: 'https://www.ixigua.com/...',
      embedType: EmbedType.xigua,
      keywords: ['ixigua'],
    },
    {
      title: '网易云音乐',
      placeholder: 'https://music.163.com/...',
      embedType: EmbedType.music163,
      keywords: ['neteasemusic'],
      py: 'wangyiyunyinyue',
      initials: 'wyyyy',
    },
    {
      title: 'CodeSandbox',
      placeholder: 'https://www.codesandbox.io/...',
      embedType: EmbedType.codesandbox,
    },
    {
      title: 'CodePen',
      placeholder: 'https://www.codepen.io/...',
      embedType: EmbedType.codepen,
    },
  ];
};

/** 第三方应用 */
export const getEmbedThirdParty = () => {
  const embedThirdParty: Record<
    string,
    {
      type: string;
      data: {
        type: BlockType.EMBED;
        image: string;
        title: string;
        // context: string;
        placeholder: string;
        keywords?: string[];
        actionType: MenuListActionType;
        py?: string;
        initials?: string;
        params: {
          data: { embedType: keyof typeof EmbedType };
          local: boolean;
        };
      };
    }
  > = {};

  const isDark = getIsDarkMode();

  getEmbedData().forEach(
    (item) =>
      (embedThirdParty[item.embedType] = {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.EMBED,
          actionType: MenuListActionType.CREATE,
          image: new URL(
            `${ossImagesPath}/embeds/${isDark ? 'dark' : 'light'}/ic_insert_${item.embedType}.png`
          ).href,
          title: item.title,
          // context: `嵌入 ${item.title}`,
          placeholder: item.placeholder,
          py: item.py,
          initials: item.initials,
          keywords: item.keywords,
          params: {
            data: { embedType: item.embedType },
            local: true,
          },
        },
      })
  );

  return embedThirdParty;
};
