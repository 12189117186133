import { formatCheckBoxValue } from '@flowus/common/block/checkbox-value';
import { OverlayContainerContext } from '@flowus/common/hooks';
import { useOpenModal } from '@flowus/common/next-modal';
import { Tooltip } from '@flowus/common/tooltip';
import { CollectionSchemaType, CollectionViewType } from '@next-space/fe-api-idl';
import type { CSSProperties, FC } from 'react';
import { Fragment, useContext, useRef } from 'react';
import { ICON_MAP } from 'src/bitable/const';
import { useBitable } from 'src/bitable/context';
import { CellEditor } from 'src/bitable/table-view/cell/editor';
import { Site } from 'src/bitable/table-view/cell/types';
import { useSyncId } from 'src/editor/editor/plugin/sync-block-context';
import { ToolbarButton } from 'src/editor/editor/uikit/editable-toolbar/button';
import { useOpenBlockRightMenuList } from 'src/editor/editor/uikit/hover-menu/block-menu-list/hook';
import { segmentsToText, textToSegments } from 'src/editor/utils/editor';
import { LockedContext } from 'src/hooks/block/use-block-locked';
import { usePropertySchema } from 'src/hooks/block/use-property-schema';
import { useUpdatePropertyValue } from 'src/hooks/block/use-update-property-value';
import { getSortedRecordIds } from 'src/hooks/collection-view/use-get-sorted-records';
import type { CollectionProperty } from 'src/hooks/collection-view/use-properties';
import { useProperties } from 'src/hooks/collection-view/use-properties';
import { useSelectedBlockHistory } from 'src/hooks/page/use-selected-block-history';
import { transaction } from 'src/hooks/use-transaction';
import { archiveBlock } from 'src/redux/managers/block/archive';
import { uiActions } from 'src/redux/reducers/ui';
import { cache, dispatch } from 'src/redux/store';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';
import { selectedBlocksToIds } from 'src/utils/select-block-util';

// notion也不显示这几个
const hideTypeSet = new Set([
  CollectionSchemaType.TITLE,
  CollectionSchemaType.ID_NUMBER,
  CollectionSchemaType.ROLLUP,
  CollectionSchemaType.FORMULA,
  CollectionSchemaType.CREATED_AT,
  CollectionSchemaType.CREATED_BY,
  CollectionSchemaType.UPDATED_AT,
  CollectionSchemaType.UPDATED_BY,
]);
export const SelectRecordToolbar: FC = () => {
  const { viewId, collectionId } = useBitable();
  let [properties = []] = useProperties(viewId, { visible: true });
  const view = useObservableStore(({ collectionViews }) => collectionViews[viewId], [viewId]);
  properties = properties.filter((v) => !hideTypeSet.has(v.type));
  const syncId = useSyncId();
  const openModal = useOpenModal();
  const containerRef = useRef<HTMLDivElement>(null);
  const updatePropertyValue = useUpdatePropertyValue();
  const recordIds = getSortedRecordIds({ viewId });
  const { selectedBlockHistory } = useSelectedBlockHistory();
  const openBlockRightMenuList = useOpenBlockRightMenuList();
  const isLocked = !!useContext(LockedContext);
  const selectRecordBlock = useObservableStore(
    (selector) => {
      if (!recordIds) return [];
      const recordIdSet = new Set(recordIds);
      return selector.ui.selectedBlocks.filter((v) => {
        return recordIdSet.has(v.blockId) && v.viewId === viewId;
      });
    },
    [recordIds],
    {
      obsSelectBlocks: [
        {
          blockId: collectionId,
          viewId,
          syncId,
        },
      ],
    }
  );

  if (selectRecordBlock.length === 0) return null;
  if (view?.type !== CollectionViewType.TABLE) return null;
  const totalWidth = properties.length * 70; // 70大概是一个按钮的宽度

  return (
    <div
      className="absolute next-modal flex items-center z-[1] -translate-x-0.5 overflow-hidden"
      data-no-cancel-selected
    >
      <ToolbarButton
        onClick={() => {
          dispatch(uiActions.updateSelectBlocks([]));
        }}
        highlight={true}
        label={
          <div className="flex items-center">
            <span>已选中</span>&nbsp;
            <span className="text-t2">{selectRecordBlock.length}</span>&nbsp;
            <span>个</span>
          </div>
        }
      />
      <div className="h-9 w-px bg-grey5" />
      <Tooltip popup={totalWidth > 500 ? '滑动可编辑更多属性' : ''} delay={{ open: 400 }}>
        <div
          data-disable-select
          className="flex items-center max-w-[500px] overflow-auto"
          style={{
            scrollbarWidth: 'none',
          }}
        >
          {properties.map((p, index) => {
            return (
              <Fragment key={index}>
                <Item
                  className="border-r"
                  collectionId={collectionId}
                  collectionProperty={p}
                  onClick={(e) => {
                    const recordIds = cache.ui.selectedBlocks.map((v) => v.blockId);
                    if (p.type === CollectionSchemaType.CHECKBOX) {
                      const value =
                        cache.blocks[cache.ui.selectedBlocks[0]?.blockId ?? '']?.data
                          .collectionProperties?.[p.property];
                      if (formatCheckBoxValue(segmentsToText(value))) {
                        updatePropertyValue(recordIds, p.property, textToSegments('NO'));
                      } else {
                        updatePropertyValue(recordIds, p.property, textToSegments('YES'));
                      }
                      return;
                    }

                    openModal.dropdown({
                      popcorn: e.currentTarget,
                      content({ onCloseModal }) {
                        const modalStyle = getModalStyle(p.type);
                        return (
                          <div
                            data-no-cancel-selected
                            className="next-modal max-h-[80vh] disable-short-cut"
                            style={modalStyle}
                            ref={containerRef}
                          >
                            <OverlayContainerContext.Provider value={containerRef}>
                              <CellEditor
                                site={Site.CELL}
                                viewId={viewId}
                                recordId={recordIds[0] ?? ''}
                                recordIds={recordIds}
                                propertyId={p.property}
                                onUpdate={() => {}}
                                onClose={onCloseModal}
                              />
                            </OverlayContainerContext.Provider>
                          </div>
                        );
                      },
                    });
                  }}
                />
              </Fragment>
            );
          })}
        </div>
      </Tooltip>
      {!isLocked && (
        <>
          <ToolbarButton
            icon={'IcMenuDelete'}
            onClick={() => {
              openModal.warning({
                content: `将删除选中的 ${selectRecordBlock.length} 项数据，删除后可在收回站中恢复。`,
                className: 'disable-cancel-selected',
                confirm() {
                  transaction(() => {
                    archiveBlock(selectedBlocksToIds(selectRecordBlock));
                    selectedBlockHistory([]);
                  });
                },
              });
            }}
          />
        </>
      )}
      <ToolbarButton
        className="border-l"
        icon="IcMore"
        onClick={(e) => {
          openBlockRightMenuList({
            noSelect: true,
            blockId: selectRecordBlock[0]?.blockId ?? '',
            blockNode: e.currentTarget,
          });
        }}
      />
    </div>
  );
};

const Item: FC<{
  collectionId: string;
  collectionProperty: CollectionProperty;
  onClick: (e: React.MouseEvent) => void;
  className?: string;
}> = (props) => {
  const { collectionId, collectionProperty } = props;
  const { propertySchema } = usePropertySchema(collectionId, collectionProperty.property);
  if (!propertySchema) return null;
  return (
    <ToolbarButton
      className={props.className}
      icon={ICON_MAP[propertySchema.type]}
      label={propertySchema.name}
      onClick={props.onClick}
    />
  );
};

export const getModalStyle = (schemaType: CollectionSchemaType) => {
  const modalStyle: CSSProperties = {};
  const fixedWidth = 250;
  const minHeight = 36;
  if (
    schemaType === CollectionSchemaType.TEXT ||
    schemaType === CollectionSchemaType.TITLE ||
    schemaType === CollectionSchemaType.NUMBER ||
    schemaType === CollectionSchemaType.EMAIL ||
    schemaType === CollectionSchemaType.URL ||
    schemaType === CollectionSchemaType.PHONE
  ) {
    modalStyle.width = fixedWidth;
    modalStyle.minHeight = minHeight;
  }

  if (
    schemaType === CollectionSchemaType.SELECT ||
    schemaType === CollectionSchemaType.MULTI_SELECT
  ) {
    modalStyle.minWidth = fixedWidth;
    modalStyle.maxWidth = 320;
    modalStyle.minHeight = minHeight;
  }
  return modalStyle;
};
