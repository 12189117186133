import { cx } from '@flowus/common/cx';
import type { PageActivityEditDTO } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';
import { uniq } from 'lodash-es';
import type { FC, ReactNode } from 'react';
import React, { createContext, useContext, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { TimeAgo } from 'src/components/time-ago';
import { UserAvatar } from 'src/components/user-avatar';
import { useUser } from 'src/hooks/user/use-user';
import { Modals } from 'src/modals';
import { useGetPageId } from 'src/utils/getPageId';
import { PageHistoryModal } from 'src/views/main/header/page-history';
import { PageScene, usePageScene } from 'src/views/main/scene-context';
import { Author } from '../subject';

export const SnapshotContext = createContext<string | undefined>(undefined);

export const FeedTemplate: FC<{
  // 用来计算 authors
  edits: PageActivityEditDTO[];
  action: ReactNode;
  subject?: ReactNode;
  subjectFirst?: boolean;
  timestamp: number;
  header?: ReactNode;
  badge?: ReactNode;
  footer?: ReactNode;
  changes: ReactNode[];
  snapshotId?: string;
}> = ({
  edits,
  action,
  subject,
  subjectFirst = false,
  timestamp,
  header,
  badge,
  footer,
  changes,
}) => {
  const pageId = useGetPageId();
  const snapshotId = useContext(SnapshotContext);
  const authorIds = uniq(edits.flatMap((it) => it.authors));
  const firstAuthor = useUser(authorIds[0]);
  const [expanded, setExpanded] = useState(false);
  const openModal = useOpenModal();
  const scene = usePageScene();
  return (
    <div
      className={cx(
        'flex items-start py-3 px-4 !cursor-default border-grey6',
        scene === PageScene.NOTIFICATION
          ? 'animate-hover-black3'
          : 'border-b last:border-b-0 border-b-grey6'
      )}
    >
      <UserAvatar
        user={firstAuthor}
        className={cx(scene === PageScene.NOTIFICATION ? 'mr-1 text-t2' : 'mr-1 w-8 h-8')}
      />
      <div className="flex-1 min-w-0 flex flex-col">
        <div className="flex items-center justify-between">
          <div className="text-t2 ml-1 break-words leading-5">
            <span className="text-t2-medium mr-1.5">
              {authorIds.map((authorId, index) => {
                return (
                  <React.Fragment key={authorId}>
                    <Author userId={authorId} />
                    {index !== authorIds.length - 1 && (__BUILD_IN__ ? ',' : '、')}
                  </React.Fragment>
                );
              })}
            </span>
            {subjectFirst ? (
              <>
                {subject && <span>在{subject}</span>}
                <span className="ml-1">{action}</span>
              </>
            ) : (
              <>
                <span className="mr-1">{action}</span>
                {subject && <span>{subject}</span>}
              </>
            )}
          </div>
          {badge}
        </div>
        <div className="ml-1 text-t4 text-grey3">
          <Tooltip popup={dayjs(timestamp).format('YYYY/M/D HH:mm:ss')} className="inline">
            <TimeAgo timestamp={timestamp} />
          </Tooltip>
        </div>
        {header}
        <div className="mt-1.5">
          {expanded ? changes : changes.slice(0, 5)}
          {changes.length > 5 && !expanded && (
            <div>
              <button
                className="px-1 mt-1 text-t2 text-grey4 animate-hover"
                onClick={() => {
                  setExpanded(true);
                }}
              >
                查看更多（{changes.length - 5}条）
              </button>
            </div>
          )}
        </div>
        {footer}
      </div>
      {snapshotId != null && (
        <Tooltip popup="查看此版本页面历史">
          <button
            className="animate-hover text-grey3"
            onClick={() => {
              openModal.modal({
                modalId: Modals.DOC_HISTORY_MODAL_ID,
                content: () => {
                  return <PageHistoryModal docId={pageId} initialSelectedSnapshotId={snapshotId} />;
                },
              });
            }}
          >
            <Icon name="IcMenuPageHistory" size="middle" />
          </button>
        </Tooltip>
      )}
    </div>
  );
};
