import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { cx } from '@flowus/common/cx';
import type { FC, MouseEvent } from 'react';
import { Icon } from 'src/common/components/icon';

import type { Option } from '../../types';
import { TagItem } from './tag-item';

interface OptionItemProps {
  option: Option;
  active?: boolean;
  readonly?: boolean;
  onMouseOver?(event: MouseEvent<HTMLElement>): void;
  onSelect?(event: MouseEvent<HTMLElement>): void;
  onMore?(event: MouseEvent<HTMLElement>): void;
}

export const SortableOptionItem: FC<OptionItemProps> = (props) => {
  const { option, readonly } = props;
  const { listeners, setNodeRef, transform, transition, active } = useSortable({
    id: option.id,
    disabled: readonly,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: active ? transition : undefined,
    opacity: active?.id === option.id ? 0 : 1,
  };

  return (
    <li ref={setNodeRef} style={style} {...listeners} data-option-id={option.id}>
      <OptionItem {...props} />
    </li>
  );
};

export const OptionItem: FC<OptionItemProps> = ({
  option,
  active,
  onSelect,
  onMore,
  onMouseOver,
  readonly,
}) => {
  return (
    <div
      className={cx('w-full flex px-2 h-8 cursor-pointer items-center animate-hover', {
        'bg-black_006': active,
      })}
      onMouseOver={onMouseOver}
      onClick={onSelect}
    >
      <div className="flex flex-1 items-center whitespace-nowrap overflow-hidden overflow-ellipsis">
        <Icon className="mr-1 text-grey4" size="small" name="IcBlockHandle" />
        <TagItem value={option.value} label={option.value} color={option.color} />
      </div>
      {!readonly && (
        <button
          className="w-5 h-5 text-grey3 animate-hover rounded flex items-center justify-center"
          onClick={(event) => {
            event.stopPropagation();
            onMore?.(event);
          }}
        >
          <Icon size="small" name="IcMore" />
        </button>
      )}
    </div>
  );
};
