import { last } from 'lodash-es';
import { isMobile } from 'react-device-detect';
import { Regex } from './url';

/**自动添加http头 */
export function fixUrl(text: string, https?: boolean): string {
  if (text.endsWith('/')) {
    text = text.slice(0, -1);
  }
  text = text.trim();
  if (isUrl(text)) {
    return text;
  }
  if (https) {
    return `https://${text}`;
  }

  return `http://${text}`;
}
export const shortUrl = (url: string) => {
  if (!url) return url;
  const ret = fixUrl(url);
  if (!isUrl(ret)) return url;
  if (Regex.Email.test(url)) return url;
  const finalUrl = new URL(ret);
  const hasWWW = finalUrl.hostname.startsWith('www');
  //如果有www就隐藏掉
  const prefix = hasWWW ? finalUrl.hostname.slice(4) : finalUrl.hostname;
  //如果url是中文的话，会出来一堆乱七八糟的东西，这里做二次交验
  if (!url.includes(prefix)) return url;
  if (finalUrl.pathname.length + finalUrl.search.length + finalUrl.hash.length < 12) {
    //去掉http和www
    return prefix + finalUrl.pathname + finalUrl.search + finalUrl.hash;
  }
  const suffix = finalUrl.pathname + finalUrl.search + finalUrl.hash;
  const path = `${suffix.slice(0, 4)}...${suffix.slice(-6)}`;
  const shortUrl = prefix + path;
  return shortUrl;
};

export function isUrl(text: string): boolean {
  try {
    const url = new URL(text);
    return Boolean(url);
  } catch {
    return false;
  }
}

/** 第三方应用类型 */
export enum EmbedType {
  'file' = 'file',
  'image' = 'image',
  'webpage' = 'webpage',
  'airtable' = 'airtable',
  'figma' = 'figma',
  'sketch' = 'sketch',
  'modao' = 'modao',
  'mockplus' = 'mockplus',
  'excalidraw' = 'excalidraw',
  'processon' = 'processon',
  'canva' = 'canva',
  'bilibili' = 'bilibili',
  'vqq' = 'vqq',
  'music163' = 'music163',
  'codesandbox' = 'codesandbox',
  'codepen' = 'codepen',
  'youku' = 'youku',
  'xigua' = 'xigua',
  'lusun' = 'lusun',
}

/** 特殊链接转化 */
export const getEmbedWebsiteUrl = (url?: string): { url: string; website?: EmbedType } => {
  try {
    if (!url) return { url: '' };
    const tranUrl = fixUrl(url || '', true);
    const urlLocation = new URL(tranUrl || '');
    let website: EmbedType | undefined = undefined;

    if (/figma\.com/.test(urlLocation.host)) {
      const nodeId = urlLocation.searchParams.get('node-id');
      const embed_host = urlLocation.searchParams.get('embed_host');
      const pathNames = urlLocation.pathname.split('/');
      const pageId = pathNames[2];

      if (pageId && embed_host !== 'nextspace') {
        website = EmbedType.file;
        return {
          url: `${urlLocation.origin}/${pathNames[1]}/${pageId}?embed_host=nextspace&kind=${
            nodeId ? `&node-id=${nodeId}` : ``
          }&viewer=1`,
          website,
        };
      }
    }

    if (/airtable\.com/.test(urlLocation.host) && !urlLocation.pathname.includes('/embed/')) {
      website = EmbedType.airtable;
      return {
        url: `${urlLocation.origin}/embed${urlLocation.pathname}`,
        website,
      };
    }

    if (/bilibili\.com/.test(urlLocation.host) && !/player\.bilibili\.com/.test(urlLocation.host)) {
      const pathNames = urlLocation.pathname.split('/');
      let videoId = pathNames[2];
      website = EmbedType.bilibili;
      if (pathNames[1] === 'video' && videoId) {
        const isBid = videoId.startsWith('BV');
        if (!isBid && videoId.startsWith('av')) {
          videoId = videoId.replace('av', '');
        }

        urlLocation.searchParams.set('autoplay', '0');

        return {
          url: `https://player.bilibili.com/player.html?${
            isBid ? 'bvid' : 'aid'
          }=${videoId}&page=1&high_quality=1&as_wide=1&allowfullscreen=true${urlLocation.search.replace(
            '?',
            '&'
          )}`,
          website,
        };
      }
    }

    if (/v\.qq\.com/.test(urlLocation.host)) {
      const pathNames = urlLocation.pathname.split('.html')[0]?.split('/');
      const vid = urlLocation.searchParams.get('vid') ?? pathNames?.[pathNames.length - 1];
      website = EmbedType.vqq;
      if (vid) {
        return {
          url: `https://v.qq.com/txp/iframe/player.html?vid=${vid}`,
          website,
        };
      }
    }

    if (/music\.163\.com/.test(urlLocation.host)) {
      const urlParams = urlLocation.href.split('?');
      const musicId = new URLSearchParams(urlParams[1]).get('id');
      website = EmbedType.music163;
      // 单曲
      if (urlParams[0]?.includes('song')) {
        return {
          url: `https://music.163.com${
            isMobile ? '/m' : ''
          }/outchain/player?type=2&id=${musicId}&auto=0&height=66`,
          website,
        };
      }
      // 歌单
      if (urlParams[0]?.includes('playlist')) {
        return {
          url: `https://music.163.com${
            isMobile ? '/m' : ''
          }/outchain/player?type=0&id=${musicId}&auto=1`,
          website,
        };
      }
    }

    if (/codesandbox\.io/.test(urlLocation.host) && !urlLocation.pathname.includes('/embed/')) {
      const pathname = urlLocation.pathname.replace('/s/', '/embed/');
      website = EmbedType.codesandbox;
      return {
        url: `${urlLocation.origin}${pathname}?fontsize=14&hidenavigation=1&theme=dark`,
        website,
      };
    }

    if (/codepen\.io/.test(urlLocation.host) && !urlLocation.pathname.includes('/embed/')) {
      const pathname = urlLocation.pathname.replace('/pen/', '/embed/');
      website = EmbedType.codepen;
      return {
        url: `${urlLocation.origin}${pathname}?default-tab=html%2Cresult`,
        website,
      };
    }

    if (/modao\.cc/.test(urlLocation.host) && !urlLocation.pathname.includes('/embed/')) {
      website = EmbedType.modao;
      return {
        url: `${tranUrl}/embed/v2`,
        website,
      };
    }

    if (/app\.mockplus\.cn/.test(urlLocation.host) && !urlLocation.pathname.includes('/s/')) {
      website = EmbedType.mockplus;
      return {
        url: tranUrl.replace('/app/', '/s/'),
        website,
      };
    }

    if (/canva\.cn/.test(urlLocation.host) && !urlLocation.search.includes('/embed/')) {
      const pathNames = urlLocation.pathname.split('/');
      website = EmbedType.canva;

      const newPath = pathNames.slice(0, pathNames.length - 1).join('/');

      return {
        url: `${urlLocation.origin}${newPath}/view?embed`,
        website,
      };
    }

    if (/youku\.com/.test(urlLocation.host) && !urlLocation.search.includes('/embed/')) {
      const pathNames = urlLocation.pathname.split('/');
      website = EmbedType.youku;
      if (pathNames[2]?.includes('id')) {
        return {
          url: `https://player.youku.com/embed/${pathNames[2]
            .replace('id_', '')
            .replace('.html', '')}`,
          website,
        };
      }
    }
    if (/ixigua\.com/.test(urlLocation.host) && !urlLocation.pathname.includes('/iframe/')) {
      const pathNames = urlLocation.pathname.split('/');
      if (pathNames.length > 0) {
        website = EmbedType.xigua;
        return {
          url: `https://www.ixigua.com/iframe/${pathNames[pathNames.length - 1]}`,
          website,
        };
      }
    }

    if (/lusun\.com/.test(urlLocation.host) && !urlLocation.pathname.includes('/embed/')) {
      const pathNames = urlLocation.pathname.split('/v/');
      const id = last(pathNames);
      website = EmbedType.lusun;
      // https://lusun.com/embed/?id=XXXXXXXXX
      if (id) {
        return {
          url: `https://lusun.com/embed/?id=${id}&${urlLocation.search.replace('?', '&')}`,
          website,
        };
      }
    }

    return { url, website };
  } catch {
    return {
      url: url ?? '',
    };
  }
};
