import { useMemo, type FC, useState, useEffect } from 'react';
import { SettingModalCommon } from './common';
import { Icon } from 'src/common/components/icon';
import { usePickBlock } from 'src/utils/pick-block';
import { segmentsToText } from 'src/editor/utils/editor';
import { Input } from 'src/common/components/input';
import { request } from 'src/common/request';
import { encryptionPhone } from '@flowus/common';
import { UNNAMED_USER } from 'src/common/const';
import { UserAvatar } from 'src/components/user-avatar';
import { FoldItemList } from './members-setting/fold-item-list';
import { useSearchSpaceMembers } from 'src/hooks/space/use-search-space-members';
import { cache, dispatch } from 'src/redux/store';
import type { LocalUser, StoreUsers } from 'src/redux/reducers/users';
import { usersActions } from 'src/redux/reducers/users';
import { cx } from '@flowus/common/cx';
import { useOpenModal } from '@flowus/common/next-modal';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { useGuestsList } from 'src/services/app';
import { useCurrentSpaceUsers } from 'src/hooks/space/use-current-space-users';

interface Props {
  pageId: string;
  goBack: () => void;
  onKickout: (userId: string) => void;
}
export const SpaceShareSubscribe: FC<Props> = (props) => {
  const { pageId } = props;
  const block = usePickBlock(pageId, ['data'], ['segments']);
  const title = useMemo(() => segmentsToText(block?.data.segments), [block?.data.segments]);
  const [searchKeywords, setSearchKeywords] = useState('');
  const searchSpaceMembers = useSearchSpaceMembers();
  const [users, setUsers] = useState<StoreUsers>({});
  const openModal = useOpenModal();
  const guestsList = useGuestsList();

  const currentSpaceUsers = useCurrentSpaceUsers();
  const guestMap = useMemo(() => {
    return guestsList.reduce((pre, cur) => {
      pre[cur.userId] = cur.userId;
      return pre;
    }, {} as Record<string, string>);
  }, [guestsList]);

  const openOption = (event: React.MouseEvent<HTMLDivElement>, uuid: string) => {
    if (!users) return;
    const subscribeUsers = Object.values(users);
    const clickUser = subscribeUsers.find((user) => user.uuid === uuid);
    if (!clickUser) return;

    openModal.dropdown({
      placement: 'bottom',
      offset: [0, 4],
      popcorn: event.currentTarget,
      content: ({ onCloseModal }) => (
        <ListView
          className="py-2 w-[270px] next-modal"
          onItemClick={() => onCloseModal()}
          items={[
            {
              type: ListItemType.TEXT_DESCRIPTION,
              data: {
                title: <div className="text-red">移除</div>,
                onClick: () => {
                  setUsers((users) => {
                    const state = { ...users };
                    delete state[uuid];
                    return state;
                  });
                  props.onKickout(uuid);
                  void request.infra.cancelSubscribe(pageId, { userId: uuid });
                },
              },
            },
          ]}
        />
      ),
    });
  };
  const userList = useMemo(() => {
    const result = searchSpaceMembers({ keywords: searchKeywords, users });
    return result;
  }, [searchKeywords, searchSpaceMembers, users]);

  useEffect(() => {
    if (!pageId) return;
    void request.infra.getSubscribeUserList(pageId).then((res) => {
      if (res.recordMap.users) {
        dispatch(usersActions.updateSpaceUsers({ users: res.recordMap.users }));
      }
      const users = res.results.reduce((pre, cur) => {
        const user = cache.users[cur.userId];
        if (user) {
          pre[user.uuid] = user;
        }
        return pre;
      }, {} as Record<string, LocalUser>);
      setUsers(users);
    });
  }, [pageId]);

  if (!pageId) return null;

  return (
    <>
      <div className="flex items-center">
        <div
          className="cursor-pointer flex items-center p-1 hover:bg-black_006 rounded"
          onClick={() => {
            props.goBack();
          }}
        >
          <Icon name={'IcArrowBack'} size="middle" />
        </div>
        <span className="text-t2 text-grey3 ml-2">{title}</span>
      </div>
      <SettingModalCommon.SettingDivider />
      <div className="flex top-0 shrink-0 items-center justify-between mt-2.5 h-9 text-t2">
        <div>{['订阅者信息（', userList.length, '订阅）'].join('')}</div>
        <Input
          className="pl-3 pr-1.5 w-full max-w-[200px] h-8 rounded"
          inputClassName="px-0"
          value={searchKeywords}
          onChange={setSearchKeywords}
          placeholder="搜索人员、手机号"
          addonBefore={<Icon size="middle" name="IcSearchMiddle" className="text-grey4" />}
          addonAfter={
            searchKeywords && (
              <Icon
                size="middle"
                name="IcUploadCancel"
                onClick={() => setSearchKeywords('')}
                className="text-grey4 cursor-pointer"
              />
            )
          }
        />
      </div>
      <FoldItemList
        moreContent="订阅者"
        foldLength={50}
        items={userList}
        renderItemContent={(user) => {
          const userName = user.nickname;
          const isSpaceMember = currentSpaceUsers[user.uuid];
          const isGuest = guestMap[user.uuid];
          return (
            <div className="group flex shrink-0 items-center h-[58px]" key={user.uuid}>
              <UserAvatar user={user} className="mr-2 w-[30px] h-[30px] !text-t1" />
              <div className="flex flex-col grow">
                <div className="flex items-center">
                  <span className="mb-0.5 text-t2">{userName || UNNAMED_USER}</span>
                </div>
                <div className="flex items-center">
                  <span className="text-grey3 text-t4">
                    {encryptionPhone(user.phone || user.email)}
                  </span>
                  {isSpaceMember && (
                    <span className="ml-1 p-0.5 rounded text-t4 bg-grey8">空间成员</span>
                  )}
                  {!isSpaceMember && isGuest && (
                    <span className="ml-1 p-0.5 rounded text-t4 bg-grey8">外部协作者</span>
                  )}
                </div>
              </div>
              <div
                className={cx('self-center flex items-center text-t2 cursor-pointer')}
                onClick={(e) => openOption(e, user.uuid)}
              >
                {'订阅者'}
                {<Icon name="IcArrow" className="ml-1" size="xxxsmall" />}
              </div>
            </div>
          );
        }}
      />
    </>
  );
};
