import { CaptchaSendType } from '@next-space/fe-api-idl';
import type { FC } from 'react';

import { useLogin } from '../../../hook/use-login';
import { useVerifySmsCode } from '../../../hook/use-verify-code';
import { Button } from '../../../uikit/web/button';
import { CaptchaComp } from '../../../uikit/web/captcha';
import { Input } from '../../../uikit/web/input';
import { Regex } from '../../../utils';

interface VerifyPhoneCodeProps {
  phone: string;
  onSuccess: (authorizationCode: string) => void;
}
export const VerifyPhoneCode: FC<VerifyPhoneCodeProps> = (props) => {
  const { phone, onSuccess } = props;
  const verifySmsCode = useVerifySmsCode();
  const { sendVerifyCode, verifyCode, setVerifyCode, verifyCodeError, setVerifyCodeError } =
    useLogin({ account: phone });

  const tryVerifySmsCode = async () => {
    if (!Regex.VerifyCode.test(verifyCode)) {
      setVerifyCodeError('验证码错误');
      return;
    }

    const res = await verifySmsCode({
      phone,
      code: verifyCode,
      type: CaptchaSendType.CHANGE_BIND,
      onError: setVerifyCodeError,
    });

    if (res?.authorizationCode) {
      onSuccess(res.authorizationCode);
    }
  };

  return (
    <div className="next-modal pt-10 pb-5 px-7 flex flex-col items-center min-w-[400px]">
      <div className="mb-5 text-t1-medium">验证手机号</div>
      <div className="mb-5 text-t2">已向注册手机号{phone}发送验证码</div>
      <Input
        value={verifyCode}
        maxLength={6}
        type="number"
        className="mb-10 w-full"
        placeholder="请输入验证码"
        inputClassName="px-3 h-8 text-t2"
        onEnter={() => void tryVerifySmsCode()}
        warning={verifyCodeError}
        onChange={(v) => setVerifyCode(v.toString())}
        addonAfter={
          <CaptchaComp
            autoClick
            className="mr-3"
            showIcon={false}
            onSendVerifyCode={() => {
              return sendVerifyCode(CaptchaSendType.LOGIN);
            }}
          />
        }
      />
      <Button className="text-t2 h-8" size="auto" onClick={() => void tryVerifySmsCode()}>
        下一步
      </Button>
    </div>
  );
};
