import type { UserAuthorizationType } from '@next-space/fe-api-idl';
import { CaptchaSendType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useLogin } from '../../hook/use-login';
import { loginInjector } from '../../utils/init';
import { Input } from '../../uikit/web/input';
import { CaptchaComp } from '../../uikit/web/captcha';
import { Button } from '../../uikit/web/button';

interface ChangeBindPhoneProps {
  onSuccess?: () => void;
  authorizationCode: string;
  userId: string;
  type: UserAuthorizationType;
}
export const ChangeBindPhone: FC<ChangeBindPhoneProps> = (props) => {
  const { onSuccess, authorizationCode, userId, type } = props;
  const {
    account,
    accountError,
    setVerifyCodeError,
    setAccount,
    verifyCode,
    verifyCodeError,
    setVerifyCode,
    sendVerifyCode,
  } = useLogin();

  const bindNewPhone = () => {
    void loginInjector.request.infra.changeBindPhone
      .raw(userId, {
        phone: account,
        captcha: verifyCode,
        type,
        code: authorizationCode,
      })
      .then((res) => {
        if (res.code === 200) {
          onSuccess?.();
        } else {
          setVerifyCodeError(res.msg);
        }
      });
  };

  return (
    <div className="next-modal pt-10 pb-5 px-7 flex flex-col items-center min-w-[400px]">
      <div className="mb-5 text-t1-medium">换绑手机号</div>
      <Input
        autoFocus
        type="number"
        maxLength={11}
        warning={accountError}
        value={account}
        className={'w-full mb-5 px-3'}
        inputClassName="h-8"
        onChange={(v) => setAccount(v.toString())}
      />
      <Input
        type="number"
        className={'w-full mb-8 px-3'}
        inputClassName="h-8"
        maxLength={6}
        value={verifyCode}
        warning={verifyCodeError}
        onChange={(v) => setVerifyCode(v.toString())}
        addonAfter={
          <CaptchaComp
            showIcon={false}
            onSendVerifyCode={() => {
              return sendVerifyCode(CaptchaSendType.LOGIN);
            }}
          />
        }
      />
      <Button
        className="self-start px-5"
        colorType="active"
        disable={!account || !verifyCode}
        onClick={() => bindNewPhone()}
      >
        完成绑定
      </Button>
    </div>
  );
};
