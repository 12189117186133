import { cx } from '@flowus/common/cx';
import { useOnceAnyDay as _useOnceAnyDay } from '@flowus/common/hooks';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { useToday } from 'src/services/app';
import { IconUiSizeBox } from './icon-ui-size-box';

export const useOnceAnyDay = _useOnceAnyDay;
interface Props {
  className?: string;
}
const Bar: FC<Props> = (props) => {
  const { children, className } = props;
  return (
    <div
      className={cx(
        'text-t2 flex items-center justify-between text-white-base py-2 px-6 text-ellipsis',
        className
      )}
    >
      {children}
    </div>
  );
};

const OnceOneDay: FC<ReturnType<typeof useOnceAnyDay>> = (props) => {
  const { setLastCloseTime } = props;
  const toDay = useToday();

  return (
    <IconUiSizeBox
      className="animate-click"
      onClick={() => {
        setLastCloseTime(dayjs(toDay).format('YYYY-MM-DD HH:mm'));
      }}
    >
      <Icon name="IcTitleClose" size="middle" />
    </IconUiSizeBox>
  );
};

export const HeaderTips = {
  Bar,
  OnceOneDay,
};
