import { getDateStrByMilliseconds } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import { calculateRemindTime } from '@flowus/common/utils/date-time-utils';
import type { SegmentDTO } from '@next-space/fe-api-idl';
import { TextType } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useBitable } from 'src/bitable/context';
import { Icon } from 'src/common/components/icon';
import { DATE_TIME_FORMAT } from 'src/common/const';
import { DateTimePicker } from 'src/editor/editor/plugin/date-picker';
import { buildDateSegment, readDateFromDateSegment } from 'src/editor/utils/segments';
import { usePropertySchema } from 'src/hooks/block/use-property-schema';
import { usePropertySegments } from 'src/hooks/block/use-property-segments';
import { useUpdatePropertySchema } from 'src/hooks/block/use-update-property-schema';
import { useUpdatePropertyValue } from 'src/hooks/block/use-update-property-value';
import { useCollectionId } from 'src/hooks/collection-view/use-collection-id';
import { useObservableBlock, useObservableStore } from 'src/services/rxjs-redux/hook';
import { getDatePropertyFromBlock } from './helpers';
import { useCellEditor } from './hooks';
import { TextValueView } from './text';
import type { CellEditorProps, CellViewProps } from './types';

export const UpdatedAtValue: FC<CellViewProps> = ({ className, recordId }) => {
  const value = useObservableBlock(recordId, (block) => {
    return block?.updatedAt;
  });

  return (
    <TextValueView className={cx('p-2 leading-5', className)}>
      {dayjs(value).format(DATE_TIME_FORMAT)}
    </TextValueView>
  );
};
export const CreatedAtValue: FC<CellViewProps> = ({ className, recordId }) => {
  const value = useObservableBlock(recordId, (block) => {
    return block?.createdAt;
  });

  return (
    <TextValueView className={cx('p-2 leading-5', className)}>
      {dayjs(value).format(DATE_TIME_FORMAT)}
    </TextValueView>
  );
};

export interface DateSegmentInfo {
  dateString: string;
  timestamp: number;
  textType: TextType.DATE | TextType.DATETIME;
}

export const DateValue: FC<CellViewProps> = ({ className, recordId, propertyId }) => {
  const { collectionId } = useBitable();
  const segment = useObservableStore(
    ({ blocks }) => {
      return blocks[recordId]?.data.collectionProperties?.[propertyId]?.find((item) => {
        return item.type === TextType.DATE || item.type === TextType.DATETIME;
      });
    },
    [recordId, propertyId]
  );
  const value = useObservableStore(
    ({ blocks }) => {
      const schema = blocks[collectionId]?.data.schema?.[propertyId];
      if (!schema) return;
      const dateValue = getDatePropertyFromBlock(blocks[recordId], propertyId);
      if (!dateValue) return;
      return getDateStrByMilliseconds(
        dateValue?.timestamp ?? 0,
        schema.dateFormat,
        dateValue?.textType === TextType.DATETIME,
        schema.timeFormat
      );
    },
    [recordId, propertyId, collectionId]
  );
  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    const time = calculateRemindTime(segment);
    if (!time) return;
    // 提醒时间到了需要改变ui
    const restTime = time - Date.now();
    if (restTime > 0) {
      setInvalid(false);
      let timer: any;
      // 一天内提醒的才设置定时器检查
      if (restTime < 1 * 24 * 60 * 60 * 1000) {
        timer = setTimeout(() => {
          setInvalid(true);
        }, restTime);
      }
      // timeout时间过大会立刻执行。。浏览器内部用了int64位类型来记录这个值
      return () => {
        timer && clearTimeout(timer);
      };
    }
    setInvalid(true);
  }, [segment]);

  if (!value) return null;
  if (!segment) return null;

  if (segment.reminder) {
    return (
      <TextValueView
        className={cx(
          'p-2 leading-5 text-red',
          {
            'line-through text-red/70': invalid,
          },
          className
        )}
      >
        <div className="inline-flex items-center">
          <span>
            {value}
            <Icon
              name={'IcMenuAlarm'}
              size="normal"
              className={cx('text-red ml-[2px]', {
                'text-red/70': invalid,
              })}
            />
          </span>
        </div>
      </TextValueView>
    );
  }

  return <TextValueView className={cx('p-2 leading-5', className)}>{value}</TextValueView>;
};

export const DateEditor: FC<CellEditorProps> = ({
  recordIds,
  recordId,
  propertyId,
  onUpdate,
  onClose,
}) => {
  const initialValue = usePropertySegments(recordId, propertyId) ?? [];
  const collectionId = useCollectionId(recordId);
  const updatePropertyValue = useUpdatePropertyValue();
  const saveValue = (value: SegmentDTO[]) => {
    updatePropertyValue(recordIds ?? recordId, propertyId, value);
  };
  const dateSegment = initialValue.find(
    (it) => it.type === TextType.DATE || it.type === TextType.DATETIME
  );
  const schema = usePropertySchema(collectionId ?? '', propertyId);
  const updatePropertySchema = useUpdatePropertySchema();
  const date = dateSegment ? readDateFromDateSegment(dateSegment) : null;

  const includeTime = dateSegment?.type === TextType.DATETIME;

  useCellEditor({
    onUpdate,
    onClose,
    onSave: () => {},
  });
  if (!collectionId) return null;
  if (!schema) null;

  return (
    <DateTimePicker
      uiOption={{ noShadow: true, hasRemind: true }}
      dateInfo={{
        from: date ?? new Date(),
        includeTime,
        dateFormat: schema.propertySchema?.dateFormat,
        timeFormat: schema.propertySchema?.timeFormat,
        reminder: dateSegment?.reminder,
      }}
      // closeDatePicker={onClose}
      onChange={(dateInfo) => {
        const { dateFormat, timeFormat, ...rest } = dateInfo;
        if (
          dateFormat !== schema.propertySchema?.dateFormat ||
          timeFormat !== schema.propertySchema?.timeFormat
        ) {
          updatePropertySchema(collectionId, propertyId, {
            dateFormat,
            timeFormat,
          });
        }
        saveValue([buildDateSegment(rest)]);
      }}
      onClear={() => {
        saveValue([]);
        onClose();
      }}
    />
  );
};
