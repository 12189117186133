import { BlockType } from '@next-space/fe-api-idl';
import { getPageWidthFixed } from 'src/hooks/public/get-page-width-fixed';
import { getCurrentSpace } from 'src/hooks/space';
import { getCurrentSpaceView } from 'src/hooks/space/get-space';
import { getUpdatedAtAndUpdatedBy } from 'src/hooks/user/get-updated-user';
import { checkIsMaximumCapacityDialog } from 'src/services/capacity/hook';
import { $currentUserCache } from 'src/services/user/current-user';
import { v4 as uuidV4 } from 'uuid';
import { CREATE_BLOCK, LIST_AFTER_BLOCK, LIST_BEFORE_BLOCK, UPDATE_BLOCK } from '../../actions';
import { cache, dispatch } from '../../store';
import type { NextBlock, NextWhere } from '../../types';

/**
 * 创建 block
 */
export const addBlock = (
  block: Partial<Omit<NextBlock, 'spaceId'>>,
  where: NextWhere & {
    templatePages?: true;
    inline?: boolean;
    spaceId?: string;
  },
  ignoreOp = false,
  local = true
) => {
  const uuid = block.uuid ?? uuidV4();
  const parent = cache.blocks[where.parentId];
  if (!parent && !where.spaceId) {
    throw new Error('parent not found');
  }

  checkIsMaximumCapacityDialog(where.spaceId || parent?.spaceId);

  const newBlock = {
    uuid,
    spaceId: where.spaceId ?? parent?.spaceId ?? '',
    parentId: parent?.uuid ?? where.spaceId ?? '',
    type: BlockType.TEXTAREA,
    textColor: '',
    backgroundColor: '',
    status: 1,
    version: 0,
    subNodes: [],
    permissions: [],
    ...block,
    createdAt: Date.now(),
    createdBy: $currentUserCache.uuid,
    ...getUpdatedAtAndUpdatedBy(),
    data: {
      ...(block.data ?? {}),
      pageFixedWidth: block.data?.pageFixedWidth ?? getPageWidthFixed(),
      directoryMenu: $currentUserCache.setting?.directoryMenu,
      format: {
        ...block.data?.format,
        pageLayout: block.data?.format?.pageLayout ?? getCurrentSpaceView()?.setting.pageLayout,
        fontFormat: block.data?.format?.fontFormat ?? getCurrentSpaceView()?.setting.fontFormat,
        commentAlignment: getCurrentSpace().setting?.commentAlignment,
      },
    },
  };

  if (!newBlock.spaceId) {
    throw new Error('not spaceId');
  }

  if (!newBlock.parentId) {
    throw new Error('not parentId');
  }

  dispatch(CREATE_BLOCK({ block: newBlock, ignoreOp, local }));

  if (where.inline) return uuid;

  if (where.templatePages) {
    const oldTemplatePages = cache.blocks[where.parentId]?.templatePages;
    const templatePages = [...(oldTemplatePages ?? []), uuid];
    // FIXME: 没有subNodes之外的list更新op，这可能导致编辑冲突的时候数据丢失
    dispatch(
      UPDATE_BLOCK({
        uuid: where.parentId,
        patch: {
          templatePages,
        },
      })
    );
  } else if (where.first || where.before) {
    dispatch(
      LIST_BEFORE_BLOCK({
        uuid,
        parentId: where.parentId,
        before: where.before,
        ignoreOp,
      })
    );
  } else {
    dispatch(
      LIST_AFTER_BLOCK({
        uuid,
        parentId: where.parentId,
        after: where.after,
        ignoreOp,
      })
    );
  }

  return uuid;
};
