import type { EChartsOption } from 'echarts';
import type { Series } from './types';
import { COMMON_CALCULATE_ITEMS, NUMBER_CALCULATE_ITEMS } from './types';
import type { BarChartMode, ChartFormat, LineChartMode, PieMode } from '@next-space/fe-api-idl';
import { CollectionSchemaType } from '@next-space/fe-api-idl';
import _, { isNumber } from 'lodash-es';
import { getIsDarkMode } from '@flowus/common';

const createPartialOption = (format: ChartFormat) => {
  const { legendAlignPosition, showDataLabel, xAxisTtile, yAxisTtile } = getBarFormatStyle(format);
  const partialOption: Partial<EChartsOption> = {};
  const showLabelOffset = showDataLabel ? 12 : 0;
  const titleHeight = 40;
  const isDark = getIsDarkMode();

  partialOption.grid = {
    left: 10,
    right: 40,
    bottom: 10,
    top: 30,
    containLabel: true,
  };
  if (legendAlignPosition !== 'none') {
    const isTopOrBottom = legendAlignPosition === 'top' || legendAlignPosition === 'bottom';
    partialOption.legend = {
      type: 'scroll',
      orient: isTopOrBottom ? 'horizontal' : 'vertical',
      textStyle: isTopOrBottom
        ? {
            color: isDark ? 'grey' : 'black',
          }
        : {
            width: 60,
            overflow: 'truncate',
            color: isDark ? 'grey' : 'black',
          },
      [legendAlignPosition]: '10',
    };
    partialOption.grid = {
      ...partialOption.grid,
      [legendAlignPosition]: isTopOrBottom ? 50 : 120,
    };
  }
  if (format.chartMode?.type === 'HorizontalBar') {
    partialOption.grid.right = parseInt(`${partialOption.grid.right}`, 10) + showLabelOffset;
  } else {
    partialOption.grid.top = parseInt(`${partialOption.grid.top}`, 10) + showLabelOffset;
  }
  if (xAxisTtile) {
    partialOption.grid.bottom = parseInt(`${partialOption.grid.bottom}`, 10) + titleHeight;
  }
  if (yAxisTtile) {
    if (format.chartMode?.type === 'HorizontalBar') {
      partialOption.grid.left = parseInt(`${partialOption.grid.left}`, 10) + 60;
    } else {
      partialOption.grid.left = parseInt(`${partialOption.grid.left}`, 10) + 40;
    }
  }
  return partialOption;
};

/**参考https://echarts.apache.org/zh/option.html */
/**最基础的工具类,输入通用数据 */
export const convertBarViewOption = (
  xAxis: string[],
  series: Series[],
  display: BarChartMode['display'],
  format: ChartFormat
) => {
  const {
    showDataLabel,
    showGridLine,
    showXAxisLabel,
    showXAxisLine,
    showXAxisCalibration,
    showYAxisLabel,
    showYAxisLine,
    showYAxisCalibration,
    xAxisTtile,
    yAxisTtile,
  } = getBarFormatStyle(format);
  const seriesAttribute: { stack?: string; step?: 'middle' } = {};
  const partialOption: Partial<EChartsOption> = createPartialOption(format);

  switch (display) {
    case 'stack': {
      seriesAttribute.stack = 'stack'; //可以是任意字符串
      break;
    }
    case 'percent': {
      seriesAttribute.stack = 'stack';
      const sumArray: number[] = [];
      series.forEach((s) => {
        s.data.forEach((v, index) => {
          const cur = sumArray[index] ?? 0;
          if (!isNumber(v)) {
            return;
          }
          sumArray[index] = cur + v;
        });
      });
      series.forEach((s) => {
        s.data = s.data.map((v, index) => {
          if (!isNumber(v)) {
            return v;
          }
          return parseFloat((v / sumArray[index]!).toFixed(3));
        });
      });
      break;
    }
    default:
  }
  const option: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      className: 'w-100',
      axisPointer: {
        type: 'shadow',
        label: {
          // width: 20,
        },
      },
      appendTo: isMobile()
        ? undefined
        : (_: HTMLElement) => {
            return document.body;
          },
    },
    grid: {
      left: '10',
      right: '40',
      bottom: '10',
      top: '50',
      containLabel: true,
    },

    yAxis: {
      type: 'value',
      nameLocation: 'middle',
      name: yAxisTtile,
      nameGap: 30,
      nameTextStyle: {
        fontSize: '16px',
      },
      axisLabel: {
        show: showYAxisLabel,
      },
      axisTick: {
        // show: showYAxisCalibration,
        show: showYAxisCalibration,
      },
      axisLine: {
        show: showYAxisLine,
      },
      //网格线
      splitLine: {
        show: showGridLine,
      },
    },
    xAxis: {
      nameLocation: 'middle',
      name: xAxisTtile,
      nameTextStyle: {
        fontSize: '16px',
      },
      nameGap: 30,
      axisLine: {
        show: showXAxisLine,
      },
      //刻度
      axisTick: {
        alignWithLabel: true,
        show: showXAxisCalibration,
      },
      axisLabel: {
        show: showXAxisLabel,
      },
      type: 'category',
      data: xAxis,
    },
    series: series.map((v) => {
      //圆角
      const data = v.data.map((v) => {
        return {
          value: v,
          //圆角在堆叠下不好搞
          // itemStyle: {
          //   borderRadius:
          //     display === 'base' || index === series.length - 1 ? [4, 4, 0, 0] : [0, 0, 0, 0],
          // },
        };
      });
      return {
        ...v,
        ...seriesAttribute,
        type: 'bar',
        label: { show: showDataLabel, position: 'top' },
        data,
      };
    }),
    ...partialOption,
  };
  return option;
};

export const convertToLineViewOption = (
  xAxis: string[],
  series: Series[],
  display: LineChartMode['display'],
  format: ChartFormat
) => {
  const seriesAttribute: { smooth?: boolean; step?: 'middle' } = {};
  const partialOption: Partial<EChartsOption> = createPartialOption(format);
  const {
    showDataLabel,
    showGridLine,
    showXAxisLabel,
    showXAxisLine,
    showXAxisCalibration,
    showYAxisLabel,
    showYAxisLine,
    showYAxisCalibration,
    xAxisTtile,
    yAxisTtile,
  } = getBarFormatStyle(format);

  switch (display) {
    case 'base': {
      break;
    }
    case 'smooth': {
      seriesAttribute['smooth'] = true;
      break;
    }
    case 'step': {
      seriesAttribute['step'] = 'middle';
      break;
    }
    default:
  }
  const option: EChartsOption = {
    tooltip: {
      className: 'w-100',
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      appendTo: (_: HTMLElement) => {
        return document.body;
      },
    },
    grid: {
      left: '10',
      right: '40',
      bottom: '10',
      top: '50',
      containLabel: true,
    },
    yAxis: {
      type: 'value',
      nameLocation: 'middle',
      name: yAxisTtile,
      nameGap: 30,
      nameTextStyle: {
        fontSize: '16px',
      },
      axisLabel: {
        show: showYAxisLabel,
      },
      axisTick: {
        show: showYAxisCalibration,
      },
      axisLine: {
        show: showYAxisLine,
      },
      //网格线
      splitLine: {
        show: showGridLine,
      },
    },
    xAxis: {
      type: 'category',
      data: xAxis,
      nameLocation: 'middle',
      name: xAxisTtile,
      nameGap: 30,
      nameTextStyle: {
        fontSize: '16px',
      },
      axisLine: {
        show: showXAxisLine,
      },
      //刻度
      axisTick: {
        show: showXAxisCalibration,
      },
      axisLabel: {
        show: showXAxisLabel,
      },
    },
    series: series.map((v) => {
      return {
        ...v,
        ...seriesAttribute,
        type: 'line',
        label: { show: showDataLabel, position: 'top' },
      };
    }),
    ...partialOption,
  };
  return option;
};
export const convertHorizontalBarViewOption = (
  xAxis: string[],
  series: Series[],
  display: BarChartMode['display'],
  format: ChartFormat
) => {
  const {
    showDataLabel,
    showGridLine,
    showXAxisLabel,
    showXAxisLine,
    showXAxisCalibration,
    showYAxisLabel,
    showYAxisLine,
    showYAxisCalibration,
    xAxisTtile,
    yAxisTtile,
  } = getBarFormatStyle(format);
  const seriesAttribute: { stack?: string; step?: 'middle' } = {};
  const partialOption: Partial<EChartsOption> = createPartialOption(format);

  switch (display) {
    case 'stack': {
      seriesAttribute.stack = 'stack'; //可以是任意字符串
      break;
    }
    case 'percent': {
      seriesAttribute.stack = 'stack';
      const sumArray: number[] = [];
      series.forEach((s) => {
        s.data.forEach((v, index) => {
          if (!isNumber(v)) {
            return;
          }
          const cur = sumArray[index] ?? 0;
          sumArray[index] = cur + v;
        });
      });
      series.forEach((s) => {
        s.data = s.data.map((v, index) => {
          if (!isNumber(v)) {
            return v;
          }
          return parseFloat((v / sumArray[index]!).toFixed(3));
        });
      });
      break;
    }
    default:
  }
  const option: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      appendTo: (_: HTMLElement) => {
        return document.body;
      },
    },
    xAxis: {
      type: 'value',
      nameLocation: 'middle',
      name: xAxisTtile,
      nameGap: 30,
      nameTextStyle: {
        fontSize: '16px',
      },
      axisLine: {
        show: showXAxisLine,
      },
      //刻度
      axisTick: {
        show: showXAxisCalibration,
      },
      axisLabel: {
        show: showXAxisLabel,
      },
      //网格线
      splitLine: {
        show: showGridLine,
      },
    },
    yAxis: {
      type: 'category',
      data: xAxis,
      nameLocation: 'middle',
      name: yAxisTtile,
      nameGap: 100,
      nameTextStyle: {
        fontSize: '16px',
      },
      axisLabel: {
        width: window.innerWidth * 0.15,
        overflow: 'truncate',
        show: showYAxisLabel,
      },
      axisTick: {
        alignWithLabel: true,
        show: showYAxisCalibration,
      },
      axisLine: {
        show: showYAxisLine,
      },
    },
    series: series.map((v) => {
      const data = v.data.map((v) => {
        return {
          value: v,
          // itemStyle: {
          //   borderRadius: [4, 4, 0, 0],
          // },
        };
      });
      return {
        ...v,
        ...seriesAttribute,
        type: 'bar',
        label: { show: showDataLabel, position: 'right' },
        data,
      };
    }),
    ...partialOption,
  };
  return option;
};
export const convertPieViewOption = (
  xAxis: string[],
  series: Series[],
  _: PieMode['display'],
  format: ChartFormat
) => {
  const { pieInsideRadius, pieOutsideRadius, showDataLabel, pieLabelPosition } =
    getPieFormatStyle(format);
  const fixScale = isMobile() ? 0.75 : 1;
  const partialOption = createPartialOption(format);
  const option: EChartsOption = {
    tooltip: {
      trigger: 'item',
    },

    // @ts-ignore nocheck
    series: series.map((v) => {
      const data = v.data.map((v, index) => {
        return { value: v, name: xAxis[index] };
      });
      return {
        radius: [`${pieInsideRadius * fixScale}%`, `${pieOutsideRadius * 0.85 * fixScale}%`],
        ...v,
        data,
        type: 'pie',
        label: { show: showDataLabel, formatter: '{b}: {c}', position: pieLabelPosition },
      };
    }),
    ...partialOption,
  };
  return option;
};

export const getChartTypeName = (format?: ChartFormat) => {
  if (!format || !format.chartMode) return '基础柱状图';
  switch (format.chartMode.type) {
    case 'Number': {
      return '统计数字';
    }
    case 'Bar': {
      if (format.chartMode.display === 'stack') {
        return '堆叠柱状图';
      } else if (format.chartMode.display === 'percent') {
        return '百分比堆叠柱状图';
      }
      return '基础柱状图';
    }
    case 'Line': {
      if (format.chartMode.display === 'smooth') {
        return '平滑折线图';
      } else if (format.chartMode.display === 'step') {
        return '阶梯折线图';
      }
      return '基础折线图';
    }
    case 'HorizontalBar': {
      if (format.chartMode.display === 'stack') {
        return '堆叠条形图';
      } else if (format.chartMode.display === 'percent') {
        return '百分比堆叠条形图';
      }
      return '基础条形图';
    }
    case 'Pie': {
      return '图表类型';
    }
    default:
  }
};

export const getCalculateItems = (type?: CollectionSchemaType) => {
  if (type === CollectionSchemaType.NUMBER) {
    return NUMBER_CALCULATE_ITEMS;
  }
  return COMMON_CALCULATE_ITEMS;
};

export const getBarFormatStyle = (format: ChartFormat | undefined) => {
  return {
    legendAlignPosition: format?.style?.legendAlignPosition ?? 'top',
    showDataLabel: format?.style?.showDataLabel ?? false,
    showGridLine: format?.style?.showGridLine ?? false,

    showXAxisLabel: format?.style?.showXAxisLabel ?? true,
    showXAxisLine: format?.style?.showXAxisLine ?? false,
    showXAxisCalibration: format?.style?.showXAxisCalibration ?? false,

    showYAxisLabel: format?.style?.showYAxisLabel ?? true,
    showYAxisLine: format?.style?.showYAxisLine ?? false,
    showYAxisCalibration: format?.style?.showYAxisCalibration ?? false,

    xAxisTtile: format?.style?.xAxisTtile ?? '',
    yAxisTtile: format?.style?.yAxisTtile ?? '',
  };
};
export const getPieFormatStyle = (format: ChartFormat | undefined) => {
  return {
    showDataLabel: format?.style?.showDataLabel ?? false,
    legendAlignPosition: format?.style?.legendAlignPosition ?? 'top',
    pieLabelPosition: format?.style?.pieLabelPosition ?? 'outside',
    pieInsideRadius: format?.style?.pieInsideRadius ?? 5,
    pieOutsideRadius: format?.style?.pieOutsideRadius ?? 75,
  };
};

export function isMobile() {
  //@ts-ignore nocheck
  if (navigator.userAgentData) return navigator.userAgentData.mobile;
  let check = false;
  const ua = navigator.userAgent;
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      ua
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      ua.substr(0, 4)
    )
  ) {
    check = true;
  }
  return check;
}
