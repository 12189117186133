import { cx } from '@flowus/common/cx';
import { Dropdown } from '@flowus/common/next-modal/dropdown';
import { PermissionType } from '@next-space/fe-api-idl';
import { debounce } from 'lodash-es';
import type { FC } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'src/common/components/button';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { LoadingIcon } from 'src/common/components/loading-icon';
import { Tooltip } from 'src/common/components/tooltip';
import { HOST_NAME } from 'src/common/const';
import { useModel } from 'src/common/create-model';
import { request } from 'src/common/request';
import { IconTrigger } from 'src/components/icon-trigger';
import { segmentsToText } from 'src/editor/utils/editor';
import { useGetBreadcrumb } from 'src/hooks/block/use-navs';
import { getSubPages } from 'src/hooks/page';
import { checkPageId } from 'src/hooks/page/check-page-id';
import { useCurrentSpace, useSpaceDomainPath } from 'src/hooks/space';
import { useIsFreeSpaceLimit } from 'src/hooks/space/use-is-pro-space';
import { blocksActions } from 'src/redux/reducers/blocks';
import { cache, dispatch } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { usePickBlock } from 'src/utils/pick-block';
import { SettingProvider } from '../common';
import { Option } from '../option';
import { OpenSettingFrom, SettingMenuType } from '../type';
import { useOpenAskUpgradeSpace } from '../use-open-ask-upgrade-space';
import { Switch } from '@flowus/common/components/switch';

export const SpaceHome: FC = () => {
  const {
    spaceSettingState: {
      domain = '',
      publicHomePage = '',
      setPublicHomePage,
      setShowPublicHomePage,
      showPublicHomePage,
    },
  } = useModel(SettingProvider);
  const currentSpace = useCurrentSpace();
  const [value, setValue] = useState('');
  const [placeholder, setPlaceholder] = useState('设置任意公开分享中的页面为公开主页');
  const [focus, setFocus] = useState(false);
  const [visible, setVisible] = useState(false);
  const { setCurrentSettingMenu } = useModel(SettingProvider);
  const [list, setList] = useState<NextBlock[]>([]);
  const initList = useRef<NextBlock[]>([]);
  const block = usePickBlock(publicHomePage, ['data'], ['segments']);
  const hasBlock = Boolean(block);
  const [loading, setLoading] = useState(false);
  const isFreeSpaceLimit = useIsFreeSpaceLimit();
  const domainPath = useSpaceDomainPath(block?.spaceId);
  const getBreadcrumb = useGetBreadcrumb();

  const _domain = (domain || '').trim();
  const disabled = focus && !_domain;
  const url = [_domain || '英文域名', HOST_NAME].join('.');
  const openAskUpgradeSpace = useOpenAskUpgradeSpace();

  const copyLink = () => {
    void writeTextInClipboard(url);
  };

  const searchBlocks = useMemo(
    () =>
      debounce(async (value: string) => {
        if (!value) {
          setList(initList.current);
          return;
        }

        setLoading(true);

        const { results, recordMap } = await request.infra.searchDocs(currentSpace.uuid, {
          query: value,
          filters: {
            isPublic: true,
            isTitleOnly: true,
          },
          perPage: 50,
          page: 1,
        });

        setLoading(false);

        if (results.length && recordMap) {
          const blocks = recordMap.blocks as Record<string, NextBlock> | undefined;
          if (blocks) {
            dispatch(blocksActions.update({ blocks }));
            setList(results.map(({ uuid }) => blocks[uuid] as NextBlock));
          }
        } else {
          setList([]);
        }
      }, 200),
    [currentSpace.uuid]
  );

  useEffect(() => {
    void searchBlocks(value);
  }, [searchBlocks, value]);

  useEffect(() => {
    if (focus && initList.current.length === 0) {
      const rootPages = getSubPages(cache.blocks, currentSpace.uuid);
      const publicPageId = rootPages.filter((id) => {
        const page = cache.blocks[id];
        if (!page) return false;
        return page.permissions.some((p) => p.type === PermissionType.PUBLIC);
      });
      initList.current = publicPageId.map((id) => cache.blocks[id] as NextBlock);
      setList(initList.current);
    }
  }, [focus, _domain, currentSpace.uuid, value]);

  useEffect(() => {
    if (publicHomePage && !hasBlock) {
      void checkPageId(publicHomePage).then((res) => {
        if (res.code === 200) {
          dispatch(blocksActions.update({ blocks: { [res.data.uuid]: res.data as NextBlock } }));
        }
      });
    }
  }, [hasBlock, publicHomePage]);

  return (
    <>
      <Option
        title="公开主页"
        showSpacePlan
        from={OpenSettingFrom.homepage}
        description={
          <div className="flex items-center">
            访问
            {_domain ? (
              <Tooltip
                popup="点击复制链接"
                className="animate-hover mx-1 px-0.5 underline"
                onClick={copyLink}
              >
                {url}
              </Tooltip>
            ) : (
              ` ${url} `
            )}
            即可查看当前空间的公开主页
          </div>
        }
      />

      {block ? (
        <div className="mt-4 flex items-center justify-between">
          <div className="flex items-center space-x-1">
            <IconTrigger className="mr-1" blockId={publicHomePage} trigger={false} />

            <NavLink to={`${domainPath}/${block.uuid}`} className="text-t1 animate-hover px-2">
              {segmentsToText(block.data.segments) || getUntitledName(block.type)}
            </NavLink>
          </div>

          <Button onClick={() => setPublicHomePage('')}>移除</Button>
        </div>
      ) : (
        <Dropdown
          visible={visible}
          onChange={setVisible}
          placement="bottom-start"
          content={
            !_domain ? (
              <></>
            ) : (
              <div className="next-modal mt-1 w-60 py-2">
                {loading ? (
                  <div className="text-t3 text-grey3 flex items-center px-2">
                    <LoadingIcon size="middle" />
                  </div>
                ) : list.length === 0 ? (
                  <div className="text-t3 text-grey3 px-2">
                    {value ? '无相关结果' : '暂无公开分享中的页面'}
                  </div>
                ) : (
                  <ListView
                    onItemClick={(item) => {
                      setPublicHomePage(item.data.uuid);
                      setVisible(false);
                      setValue('');
                    }}
                    items={list.map((block: NextBlock) => ({
                      type: ListItemType.SEARCH,
                      data: {
                        uuid: block.uuid,
                        title: segmentsToText(block.data.segments) || getUntitledName(block.type),
                        icon: block.data.icon,
                        type: block.type,
                        backgroundColor: block.backgroundColor,
                        navs: getBreadcrumb(block.uuid),
                      },
                    }))}
                  />
                )}
              </div>
            )
          }
        >
          <div className="bg-grey8 text-t2 border-grey6 mt-4 flex w-full items-center rounded-sm border">
            {focus && _domain && (
              <Icon size="normal" name="IcSearchMiddle" className="text-grey4 ml-3" />
            )}
            <input
              spellCheck={false}
              onFocus={() => {
                if (isFreeSpaceLimit) {
                  openAskUpgradeSpace(() => {
                    setCurrentSettingMenu(SettingMenuType.upgrade);
                  });
                  return;
                }

                if (_domain) {
                  setPlaceholder('搜索公开分享中的页面');
                } else {
                  setPlaceholder('请先设置空间域名👆');
                }
                setFocus(true);
                setVisible(true);
              }}
              onBlur={() => {
                setPlaceholder('设置任意公开分享中的页面为公开主页');
                setFocus(false);
              }}
              placeholder={placeholder}
              className={cx(
                'text-t2 h-7.5 placeholder-grey4 w-full flex-grow cursor-text bg-transparent px-2 align-middle text-black outline-none',
                { 'placeholder:text-red caret-transparent': disabled }
              )}
              value={value}
              onChange={(e) => {
                if (disabled) return;
                const text = e.target.value.trim();
                setValue(text);
              }}
            />
          </div>
        </Dropdown>
      )}
      <div className="flex items-center justify-between mt-3.5">
        <div className="text-t3 text-grey3">允许从任意分享页面访问公开主页</div>
        <Switch
          open={!!showPublicHomePage}
          onSwitch={(status) => {
            setShowPublicHomePage(status);
          }}
        />
      </div>
    </>
  );
};
