import { CollectionSchemaType } from '@next-space/fe-api-idl';
import type { Store } from 'redux';
import { ICON_MAP } from 'src/bitable/const';
import type { RootState } from 'src/redux/types';

export const getAllProperties = (store: Store, collectionId: string, exculdeIds: string[] = []) => {
  const state: RootState = store.getState() as RootState;
  const schema = state.blocks[collectionId]?.data.schema;
  if (!schema) return;
  return Object.keys(schema)
    .filter((key) => {
      if (exculdeIds.includes(key)) return false;
      return schema[key]?.type !== CollectionSchemaType.ID_NUMBER;
    })
    .map((key) => {
      const type = schema[key]?.type;
      return {
        value: key,
        title: schema[key]?.name ?? '',
        icon: type && ICON_MAP[type],
        type,
      };
    });
};

export const getProperty = (store: Store, collectionId: string | undefined, propertyId: string) => {
  if (!collectionId) return;
  const state: RootState = store.getState() as RootState;
  const schema = state.blocks[collectionId]?.data.schema;
  if (!schema) return;
  const property = schema[propertyId];
  if (!property) return;
  return {
    value: propertyId,
    title: property.name ?? '',
    icon: property.type && ICON_MAP[property.type],
    type: property.type,
  };
};
