import type { SegmentDTO } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useOpenModal } from 'src/common/components/next-modal';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { sequence } from 'src/utils/async-utils';
import { bizTracker } from 'src/utils/biz-tracker';

import { convertSegmentTypeFromSuggest } from '../components/helper';
import type { SuggestState } from '../types';

interface Props {
  uuid: string;
  segments?: SegmentDTO[];
  callback: () => void;
  upgrade: () => boolean;
  type: keyof SuggestState;
}

export const CreateLink: FC<Props> = ({ uuid, segments, callback, upgrade, type }) => {
  const openModal = useOpenModal();
  const transaction = useTransaction();

  return (
    <div
      className="flex-shrink-0 text-grey3 bg-black_006 rounded-xl text-t2 px-2 mr-3 animate-click hidden group-hover:block"
      onClick={(event) => {
        event.preventDefault();

        if (upgrade()) return;

        openModal.warning({
          title: '确定建立该引用？',
          content: '建立后，原纯文字位置将变为引用页面',
          confirm: () => {
            bizTracker.event(
              type === 'mention'
                ? 'sug_reference_current_page_link'
                : 'sug_current_reference_others_link'
            );

            transaction(() => {
              updateBlock(uuid, {
                data: { segments: convertSegmentTypeFromSuggest(segments) },
              });
            });
            void sequence(callback);
          },
        });
      }}
    >
      建立引用
    </div>
  );
};
