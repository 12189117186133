import { cx } from '@flowus/common/cx';
import type { FC, ReactNode } from 'react';
import { useFinalValue } from '@flowus/common/hooks/react-utils';
import { v4 as uuid4 } from 'uuid';

import type { IconName } from './icon';
import { Icon } from './icon';
import { useCloseModal, useOpenModal } from './next-modal';
import { Tooltip } from './tooltip';

export interface SelectViewProps<T extends string | number, P extends Record<string, unknown>> {
  className?: string;
  value?: T;
  icon?: IconName;
  title?: string;
  shortTitle?: string;
  content?: ReactNode;
  onChange?: (value: T) => void;
  readonly?: boolean;
  placeholder?: string;
  Dropdown: FC<DropdownProps<T, P>>;
  dropdownProps: P;
}

type DropdownProps<T, P extends Record<string, unknown>> = P & {
  value?: T;
  onCloseModal: () => void;
  onChange?: (value: T) => void;
};

export function SelectView<
  T extends string | number = string,
  P extends Record<string, unknown> = {}
>({
  className,
  value,
  icon,
  title,
  shortTitle,
  content,
  placeholder,
  onChange,
  readonly,
  Dropdown,
  dropdownProps,
}: SelectViewProps<T, P>) {
  const openModal = useOpenModal();
  const closeModal = useCloseModal();
  const modalId = useFinalValue(uuid4);
  const openDropdown = (event: React.MouseEvent) => {
    if (readonly) return;
    openModal.dropdown<DropdownProps<T, P>>({
      modalId,
      popcorn: event.currentTarget as Element,
      content: Dropdown,
      placement: 'bottom-start',
      contentProps: {
        ...dropdownProps,

        value,
        onCloseModal: () => closeModal(modalId),
        onChange: (value) => onChange?.(value),
      },
    });
  };
  return (
    <div
      className={cx(
        'flex h-8 cursor-pointer border-grey5 group hover:border-grey1 border rounded px-2 items-center text-black',
        className
      )}
      onClick={(event) => {
        openDropdown(event);
      }}
    >
      <div className="flex flex-1 overflow-hidden overflow-ellipsis whitespace-nowrap">
        {content ? (
          content
        ) : value != null ? (
          <Tooltip popup={shortTitle ? title : null}>
            <div className="flex items-center">
              {icon && <Icon size="middle" className="mr-1" name={icon} />}
              <span className="whitespace-nowrap">{shortTitle ?? title}</span>
            </div>
          </Tooltip>
        ) : (
          <span className="text-grey4">{placeholder}</span>
        )}
      </div>
      <Icon size="xxsmall" className="text-grey4 group-hover:text-grey1 ml-1" name="IcArrow" />
    </div>
  );
}

export function LabelSelectView<
  T extends string | number = string,
  P extends Record<string, unknown> = {}
>({
  className,
  value,
  icon,
  title,
  content,
  placeholder,
  onChange,
  readonly,
  Dropdown,
  dropdownProps,
}: SelectViewProps<T, P>) {
  const openModal = useOpenModal();
  const closeModal = useCloseModal();
  const modalId = useFinalValue(uuid4);
  const openDropdown = (event: React.MouseEvent) => {
    if (readonly) return;

    openModal.dropdown<DropdownProps<T, P>>({
      modalId,
      popcorn: event.currentTarget as Element,
      content: Dropdown,
      placement: 'bottom-start',
      contentProps: {
        ...dropdownProps,
        value,
        onCloseModal: closeModal,
        onChange: (value) => {
          onChange?.(value);
          closeModal(modalId);
        },
      },
    });
  };
  return (
    <div
      className={cx(
        'flex text-t2 px-[6px] h-5 cursor-pointer hover:bg-black_006 rounded-sm items-center text-black',
        className
      )}
      onClick={(event) => {
        openDropdown(event);
      }}
    >
      <div className="flex flex-1 overflow-hidden overflow-ellipsis">
        {content ? (
          content
        ) : value != null ? (
          <div className="flex items-center">
            {icon && <Icon size="middle" className="mr-1" name={icon} />}
            <span className="text-grey3 whitespace-nowrap">{title}</span>
          </div>
        ) : (
          <span className="text-grey4">{placeholder}</span>
        )}
      </div>
      {!readonly && <Icon size="xxsmall" className="ml-2 text-grey4" name="IcArrow" />}
    </div>
  );
}
