/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { cx } from '@flowus/common/cx';
import { Select } from './select';
import type { FC } from 'react';
import { useChartBlockId } from './chart-config-context';
import { getProperty } from 'src/utils/collection-util';
import { useStore } from 'react-redux';
import { Icon } from 'src/common/components/icon';
import { useSelectProperty } from './use-select-property';
import { useUpdateChartFormat, useChartFormat } from 'src/hooks/chart';
import { Switch } from '@flowus/common/components';
import { MultiSwitchText } from './multi-switch-text';
import { CollectionSchemaType, GroupSortType, type ChartFormat } from '@next-space/fe-api-idl';
import { invalidProperty } from './data';

const ORDER_ACCORDING: { orderBy: ChartFormat['orderBy']; desc: string; mapping: string }[] = [
  {
    orderBy: 'xAxis',
    desc: '横轴值',
    mapping: '扇区字段值',
  },
  {
    orderBy: 'yAxis',
    desc: '纵轴值',
    mapping: '扇区数值',
  },
];
const orderRules: { orderRule: ChartFormat['orderRule']; desc: string }[] = [
  {
    orderRule: GroupSortType.ASCENDING,
    desc: '升序',
  },
  {
    orderRule: GroupSortType.DESCENDING,
    desc: '降序',
  },
];

interface Props {}
export const XAxisField: FC<Props> = () => {
  const store = useStore();
  const chartBlockId = useChartBlockId();
  const format = useChartFormat(chartBlockId);
  const collectionId = format?.ref?.uuid;
  const xAxisPropertyId = format?.xAxisPropertyId ?? 'title';
  const includeEmpty = format?.includeEmpty ?? true;
  const splitOption = format?.splitOption ?? true;
  const orderBy = format?.orderBy ?? 'xAxis';
  const orderAccording = ORDER_ACCORDING.map((v) => {
    return { ...v, desc: format?.chartMode?.type === 'Pie' ? v.mapping : v.desc };
  });
  const orderByValue = orderBy === 'xAxis' ? orderAccording[0]! : orderAccording[1]!;

  const orderRule = format?.orderRule ?? GroupSortType.ASCENDING;
  const orderRuleValue = orderRule === GroupSortType.ASCENDING ? orderRules[0] : orderRules[1];

  const property =
    getProperty(store, collectionId, xAxisPropertyId) ??
    (format?.xAxisPropertyId ? invalidProperty : undefined);
  const showSplitOption =
    property?.type === CollectionSchemaType.MULTI_SELECT ||
    property?.type === CollectionSchemaType.FILE ||
    property?.type === CollectionSchemaType.RELATION ||
    property?.type === CollectionSchemaType.PERSON;
  const selectProperty = useSelectProperty();
  const updateChartFormat = useUpdateChartFormat();
  const isMoreYAxisProperties =
    (format?.yAxisPropertyIds ?? []).length > 1 || format?.groupAggregationPropertyId;
  const isPieChart = format?.chartMode?.type === 'Pie';
  if (format?.chartMode?.type === 'Number') return null;

  const xAxisPropertyNode = property ? (
    <div className="flex items-center">
      {property.icon && <Icon name={property.icon} size="middle" />}
      <div
        className={cx('text-ellipsis max-w-[124px] ml-0.5', {
          'text-red': invalidProperty === property,
        })}
      >
        {property?.title}
      </div>
    </div>
  ) : undefined;

  return (
    <>
      <div className="mt-5 text-t2-medium">横轴</div>
      <div className="text-t2 mt-2 space-y-3.5">
        <div className="flex items-center justify-between">
          <div>字段</div>
          <Select
            className={cx({ 'cursor-not-allowed': !collectionId })}
            title={xAxisPropertyNode}
            showBorder={false}
            placeHolder="请选择字段"
            onClick={(e) => {
              collectionId &&
                selectProperty(collectionId, {
                  popcorn: e.currentTarget,
                  callback: (propertyId) => {
                    updateChartFormat(chartBlockId, {
                      xAxisPropertyId: propertyId,
                    });
                  },
                });
            }}
          />
        </div>
        {showSplitOption && (
          <div className="flex items-center justify-between">
            <div>多选项拆分统计</div>
            <Switch
              open={splitOption}
              onSwitch={(open) => {
                updateChartFormat(chartBlockId, {
                  splitOption: open,
                });
              }}
            />
          </div>
        )}
        <div className="flex items-center justify-between">
          <div>包含空数据</div>
          <Switch
            open={includeEmpty}
            onSwitch={(open) => {
              updateChartFormat(chartBlockId, {
                includeEmpty: open,
              });
            }}
          />
        </div>

        <div className="flex items-center justify-between">
          <div>排序依据</div>
          <MultiSwitchText
            onlyOne={isMoreYAxisProperties ? orderAccording[0]! : undefined}
            active={orderByValue!}
            values={orderAccording}
            onSwicth={(value) => {
              updateChartFormat(chartBlockId, {
                orderBy: value.orderBy,
              });
            }}
          />
        </div>
        {!isPieChart && (
          <div className="flex items-center justify-between">
            <div>排序规则</div>
            <MultiSwitchText
              active={orderRuleValue!}
              values={orderRules}
              onSwicth={(v) => {
                updateChartFormat(chartBlockId, {
                  orderRule: v.orderRule,
                });
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};
