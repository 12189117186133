import type { FC } from 'react';
import { SortableList } from 'src/common/components/sortable-list';
import { useBiTableGroups } from 'src/hooks/collection-view/table-groups';
import {
  useCardImageDir,
  useCardSize,
  useManualSortGroup,
} from 'src/hooks/collection-view/use-collection-view';
import { useGroupPropertySchema } from 'src/hooks/collection-view/use-group-option';

import { useHandleGroupSort } from '../bitable-manager/group-list';
import { getCardSize } from '../const';
import { useBitable } from '../context';
import { GroupName, GroupNameScene } from '../group-header/group-name';
import { GroupMore } from '../group-more';

export const HiddenGroups = () => {
  const { viewId, readonly, managerReadonly, isLocked } = useBitable();
  const { hiddenGroups = [] } = useBiTableGroups(viewId) ?? {};
  const cardSize = useCardSize(viewId);
  const imageDir = useCardImageDir(viewId);

  const handleGroupSort = useHandleGroupSort();
  const manualSortGroup = useManualSortGroup();

  if (readonly && managerReadonly) return null;
  if (hiddenGroups.length === 0) return null;

  return (
    <div
      className="ml-2.5 flex-shrink-0 bg-white1"
      style={{ width: getCardSize(cardSize, imageDir) }}
      data-disable-select
    >
      <div className="rounded-md bg-grey/5 p-[5px] px-1">
        <div className="text-t2 flex h-[38px] w-full items-center pl-[4px] text-grey4">
          隐藏分组
        </div>

        <SortableList
          disabled={readonly || managerReadonly || isLocked || !manualSortGroup}
          items={hiddenGroups.map((group) => ({ id: group.value, ...group }))}
          onChange={(_, event) => handleGroupSort(viewId, event)}
          renderItemContent={({ item }) => {
            return <GroupItem groupName={item.value} recordIds={item.recordIds} />;
          }}
        />
      </div>
    </div>
  );
};

interface GroupItemProps {
  groupName: string;
  recordIds: string[];
}

export const GroupItem: FC<GroupItemProps> = ({ groupName, recordIds }) => {
  const { viewId } = useBitable();
  const groupSchema = useGroupPropertySchema(viewId);
  if (!groupSchema) return null;

  return (
    <div className="group animate-hover-black3 flex h-[38px] cursor-pointer items-center justify-between rounded-sm px-1">
      <div className="flex items-center" style={{ maxWidth: 'calc(100% - 40px)' }}>
        <GroupName groupName={groupName} scene={GroupNameScene.HIDDEN_GROUP} />
      </div>

      <span className="mx-1.5 text-grey4">{recordIds.length}</span>

      <GroupMore
        visible={false}
        className="ml-auto mr-2 text-grey3 opacity-0 group-hover:opacity-100"
        groupName={groupName}
      />
    </div>
  );
};
