import { keys } from 'lodash-es';
import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { render } from 'react-dom';
import { v4 as uuid } from 'uuid';
import { cx } from '../../cx';
import './index.css';

/** message 类型 */
enum OpenMessageType {
  'loading' = 'loading',
  'warning' = 'warning',
  'error' = 'error',
  'success' = 'success',
}

/** message列表 */
const messageList: {
  [prop: string]: Element;
} = {};

const gap = 8;

/** 位置排序 */
const resort = () => {
  let totalHeight = 0;
  Object.values(messageList).forEach((item, index) => {
    const height = item.clientHeight;
    if (index === 0) {
      totalHeight += 40;
    }
    totalHeight += height + gap; // 加上间距
    const top = totalHeight - height; // 减去当前元素的高度
    item.classList.add('animate__animated', 'animate__faster', 'animate__fadeInDown', 'mt-4');
    item.setAttribute(
      'style',
      `position:fixed; transition: all .15s; z-index:8849; top:-40px; left:0; right:0; width:fit-content; margin:0 auto; margin-top: ${top}px; animation-duration: 0.2s; max-width:90%;`
    );
  });
};

/** 关闭message */
const closeMessage = (itemId: string) => {
  messageList[itemId]?.classList.add('animate__fadeOutUp');
  setTimeout(() => {
    messageList[itemId]?.remove();
    delete messageList[itemId];
    resort();
  }, 300);
};

/** 创建一个message */
const createMessage = (props: {
  key?: string;
  content: ReactNode;
  duration?: number;
  icon: string;
  messageType?: OpenMessageType;
}) => {
  const { content, duration, icon, key, messageType } = props;
  const itemId = key || uuid();

  // 防止同个key多次触发，loading的话只会更新内容
  if (messageList[itemId]) {
    if (messageType === OpenMessageType.loading) {
      render(
        <RenderMessage {...{ icon, content, duration, itemId, messageType }} />,
        messageList[itemId] as HTMLDivElement
      );
    }
    return itemId;
  }

  const messageItem = document.createElement('div');
  messageItem.id = itemId;
  (document.getElementById('root') as HTMLElement).appendChild(messageItem);
  messageList[itemId] = messageItem;
  resort();

  render(<RenderMessage {...{ icon, content, duration, itemId, messageType }} />, messageItem);

  return itemId;
};

/** 渲染message信息 */
const RenderMessage = (props: {
  content: ReactNode;
  duration?: number;
  icon?: string;
  itemId: string;
  messageType?: OpenMessageType;
}) => {
  const { icon, content, duration = 3000, itemId, messageType } = props;

  useEffect(() => {
    if (duration !== 0) {
      setTimeout(() => {
        closeMessage(itemId);
      }, duration);
    }
  }, [duration, itemId]);

  return (
    <div className={'flex items-center px-3 py-2 next-modal bg-white4 min-h-[40px] text-t3 w-full'}>
      <span
        className={cx('mr-2 pointer-events-none text-[12px]', {
          gelatine: messageType === OpenMessageType.loading,
        })}
      >
        {icon}
      </span>
      <span className="break-words">{content}</span>
    </div>
  );
};

/** messageContentProps */
type MessageType =
  | string
  | {
      icon?: string;
      content: ReactNode;
      duration?: number;
      key?: string;
    };
const openMessage = (type: OpenMessageType, messageContent: MessageType) => {
  const typeIcon = {
    loading: '⌛️',
    warning: '😮',
    error: '😱',
    success: '🥳',
  };

  if (typeof messageContent === 'object') {
    return createMessage({
      content: messageContent.content,
      duration: messageContent.duration,
      key: messageContent.key,
      messageType: type,
      icon: messageContent.icon ?? typeIcon[type],
    });
  }
  return createMessage({
    content: messageContent,
    icon: typeIcon[type],
  });
};

/** 出口 弹出message提示 */
export const message = {
  /** 完成 */
  success: (props: MessageType) => openMessage(OpenMessageType.success, props),
  /** 报错 */
  error: (props: MessageType) => openMessage(OpenMessageType.error, props),
  /** 警告 */
  warning: (props: MessageType) => openMessage(OpenMessageType.warning, props),
  /** 加载 */
  loading: (props: MessageType) =>
    openMessage(
      OpenMessageType.loading,
      typeof props === 'string' ? props : { duration: 0, ...props }
    ),
  /** 关闭某个message 需要 key */
  closeMessage,
  exists: (id: string) => !!messageList[id],
  getAll: () => keys(messageList),
};
