import { cx } from '@flowus/common/cx';
import dayjs from 'dayjs';
import { parseInt } from 'lodash-es';
import type { FC } from 'react';
import { memo, useMemo } from 'react';
import { Icon } from 'src/common/components/icon';
import type { InputProps } from 'src/common/components/input';
import { Input } from 'src/common/components/input';
import { LoadingContainer } from 'src/common/components/loading-container';
import { message } from 'src/common/components/message';
import { Tooltip } from 'src/common/components/tooltip';
import { getDateUnit } from 'src/components/pay-cycle-button';
import { StripeCheckoutForm, StripeForm } from 'src/components/stripe';
import { useCurrentSpace } from 'src/hooks/space';
import { SpacePlanType } from 'src/redux/types';
import { getSpacePlanTypeName } from 'src/utils/block-utils';
import { getCurrencySymbols } from 'src/utils/currency-format';
import { TransitionNumber } from '../../transition-number';
import type { SelectUpgradePlanProps } from '../common';
import { UpgradeCouponList, UpgradeLabel, UpgradePaddingUiKit } from '../common';
import { useStripeSuccess } from '../hook/use-upgrade-support';
import { UpgradeComponents } from '../upgrade-components';
import { RenewalType, UpgradeTeamPlanContext, useUpgradeTeamPlanContext } from './hook';
import { publicLink } from 'src/common/const';
import { PayType } from '@flowus/common/utils/pay-info';

// #region 壳子
export const UpgradeTeamPlan: FC<SelectUpgradePlanProps> = (props) => {
  return (
    <UpgradeTeamPlanContext {...props}>
      {__BUILD_IN__ ? <UpgradeTeamPlanBuildInContent /> : <UpgradeTeamPlanContent />}
    </UpgradeTeamPlanContext>
  );
};
// #endregion

// #region FlowUs
// #region 核心
export const UpgradeTeamPlanContent: FC = memo(() => {
  const {
    state,
    patchState,
    calculateQuote,
    completionTime,
    loading,
    getOrderParams,
    payOrder,
    couponList,
    upgradeTitle,
    close,
    currentCoupon,
  } = useUpgradeTeamPlanContext();

  return (
    <>
      {/* 用户信息 */}
      <UpgradeComponents.Header close={close} />
      <UpgradeComponents.BODY className={cx('px-8', loading && 'opacity-60')}>
        <UpgradePaddingUiKit height={54} className="text-h2">
          {upgradeTitle}
        </UpgradePaddingUiKit>
        {/* 付费内容规格 */}
        <PlanList />
        {/* 优惠券 */}
        <UpgradeCouponList
          onSelectId={(id) => patchState('couponId', id)}
          couponList={couponList}
          selectId={state.couponId}
          className={'my-2.5'}
        />
        {/* 付费方式 */}
        <SelectPay />
      </UpgradeComponents.BODY>
      <UpgradeComponents.PayBar
        price={calculateQuote?.amount}
        priceUnit={calculateQuote?.amountUnit}
        disablePay={loading}
        onPay={payOrder}
        priceAfter={
          <Tooltip
            className="ml-1.5 text-grey4 inline"
            placement="bottom"
            maxWidth="none"
            interactive
            popup={
              <div>
                <div>{'计算方式'}</div>
                <div>
                  {'总价 = 人数 * 购买时长 * 当前版本每人价格'}
                  <span hidden={!currentCoupon}> + {'优惠券'}</span>
                </div>
                <div>
                  <span>{getCurrencySymbols(calculateQuote?.amountUnit)}</span>
                  <span>{calculateQuote?.amount}&nbsp;=&nbsp;</span>
                  <span>
                    {getOrderParams().addPeople}
                    {'人'}&nbsp;*&nbsp;
                  </span>
                  <span>
                    {state.selectedRenewalType === RenewalType.time ? (
                      <>
                        {getOrderParams().date}
                        {'年'}
                      </>
                    ) : (
                      <>
                        {!!completionTime.year && (
                          <>
                            {completionTime.year}
                            {'年'}
                          </>
                        )}
                        {!!completionTime.day && (
                          <>
                            {completionTime.day}
                            {'天'}
                          </>
                        )}
                      </>
                    )}
                    &nbsp;*&nbsp;
                  </span>
                  <span>
                    {getCurrencySymbols(calculateQuote?.amountUnit)}
                    {
                      getOrderParams({ ...state, selectedRenewalType: RenewalType.time })
                        .currentPlan?.currentPrice
                    }
                    {'/人/年'}
                  </span>
                  <span hidden={!currentCoupon}> + {currentCoupon?.name}</span>
                </div>
              </div>
            }
          >
            <Icon name="IcInviteHelp" size="middle" />
          </Tooltip>
        }
      />
    </>
  );
});
// #endregion

// #region 付费规格选项
const PlanList: FC = memo(() => {
  const { selectPlanSpaceType } = useUpgradeTeamPlanContext();
  const currentSpace = useCurrentSpace();
  /** 付费团队 */
  const isTeam = currentSpace.planType === SpacePlanType.team;
  /** 免费小组 */
  // const isFreeTeam = currentSpace.planType === SpacePlanType.freeTeam;

  /** 续费 -> 付费团队 */
  if (isTeam) {
    return <SelectPlanType />;
  }

  /** 升级 -> 付费团队 */
  if (selectPlanSpaceType === SpacePlanType.team) {
    return (
      <>
        <SelectPayYear />
        <SelectPayPeople />
      </>
    );
  }

  /** 升级 -> 付费小组 */
  if (selectPlanSpaceType === SpacePlanType.smallTeam) {
    return <SelectPayYear />;
  }

  return <></>;
});
// #endregion

// #region 组件库

/** 购买时长 */
const SelectPayYear: FC = memo(() => {
  const { couponList, state, patchState, calculateQuote, isPersonalToSmallTeam } =
    useUpgradeTeamPlanContext();
  const customPayTime = useMemo(
    () => (
      <div className="grid grid-cols-3">
        {[{ num: 1 }, { num: 2 }].map((item) => {
          const coupon = couponList.find((i) => i.id === state.couponId);
          const label = coupon?.label;

          return (
            <div
              key={item.num}
              onClick={() => patchState('selectedTime', { type: 'fixed', value: item.num })}
              className={cx(
                'relative animate-hover mr-4 col-span-1 flex h-10 items-center justify-center border-[1.5px] border-grey5',
                {
                  'pointer-events-none': isPersonalToSmallTeam,
                  'border-black':
                    state.selectedTime.type === 'fixed' && state.selectedTime.value === item.num,
                }
              )}
            >
              {label && <UpgradeLabel>{label}</UpgradeLabel>}
              {item.num}年
            </div>
          );
        })}
        <div
          className={cx('animate-hover col-span-1 border border-grey6', {
            'border-black': state.selectedTime.type === 'custom',
          })}
        >
          <CustomNumberInput
            readonly={isPersonalToSmallTeam}
            onFocus={() => {
              state.customTime && patchState('selectedTime', { type: 'custom' });
            }}
            onClick={() => {
              state.customTime && patchState('selectedTime', { type: 'custom' });
            }}
            afterText="年"
            value={state.customTime}
            onChange={(num) => {
              patchState('customTime', Math.min(Number(num), 99));
              if (num) {
                patchState('selectedTime', { type: 'custom' });
              } else {
                patchState('selectedTime', { type: 'fixed', value: 1 });
              }
            }}
          />
        </div>
      </div>
    ),
    [
      couponList,
      isPersonalToSmallTeam,
      patchState,
      state.couponId,
      state.customTime,
      state.selectedTime.type,
      state.selectedTime.value,
    ]
  );
  const fixedPayTime = useMemo(
    () => (
      <div className="grid text-center grid-cols-2 w-full h-10 gap-2.5">
        {[
          { title: '每年', value: 12 },
          { title: '每月', value: 1 },
        ].map((item) => (
          <div
            className={cx(
              'flex items-center justify-center animate-click border border-grey5 rounded',
              state.selectedTime.value === item.value && 'border-black'
            )}
            onClick={() => patchState('selectedTime', { type: 'fixed', value: item.value })}
          >
            {item.title}
          </div>
        ))}
      </div>
    ),
    [patchState, state.selectedTime.value]
  );
  return (
    <>
      <div className="text-t2 flex h-9 items-center">购买时长</div>
      <div
        className={cx('text-t2 py-2.5', isPersonalToSmallTeam && 'cursor-not-allowed opacity-30')}
        onClick={() => {
          isPersonalToSmallTeam && message.warning('升级到小组版后延用目前截止日期');
        }}
      >
        {__BUILD_IN__ ? fixedPayTime : customPayTime}
      </div>
      <div className="text-t2 py-2 text-grey3">
        {'购买后有效期至'}
        {dayjs(calculateQuote?.desiredExpireTime).format('YYYY年MM月DD日')}
      </div>
    </>
  );
});

/** 购买人数 */
const SelectPayPeople = memo(() => {
  const { state, patchState, inputCustomPeopleTimer, spaceMaxPeople } = useUpgradeTeamPlanContext();

  return (
    <>
      <div className="text-t2 mt-2.5 flex h-9 items-center">购买人数</div>
      <div className={cx('py-2.5 pr-4', __BUILD_IN__ ? 'w-full' : 'w-1/3')}>
        <CustomNumberInput
          showBorder
          className="border-[1.5px] border-grey5"
          value={state.customPeople}
          afterText="人"
          onChange={(num) => {
            patchState('customPeople', Math.min(Math.max(num, 0), 99999));
            inputCustomPeopleTimer.current && clearTimeout(inputCustomPeopleTimer.current);
            inputCustomPeopleTimer.current = setTimeout(() => {
              inputCustomPeopleTimer.current && clearTimeout(inputCustomPeopleTimer.current);
              const minNum = Math.max(num, Math.max(spaceMaxPeople, 2));
              if (minNum > num) {
                message.warning(`最少购买${minNum}人`);
              }
              patchState('customPeople', Math.min(minNum, 99999));
            }, 1000);
          }}
        />
      </div>
      <div className="text-t2 py-2 text-grey3">
        {`购买后上限${state.customPeople}人，当前上限${spaceMaxPeople}人。`}
      </div>
    </>
  );
});

/** 增加人数 */
const SelectAddPeople: FC = memo(() => {
  const { state, patchState, spaceMaxPeople } = useUpgradeTeamPlanContext();
  return (
    <>
      <div className="text-t2 mt-2.5 flex h-9 items-center">增加人数</div>
      <div className="w-1/2 py-2.5 pr-4">
        <CustomNumberInput
          showBorder
          className="border-[1.5px] border-grey5"
          value={state.addPeople}
          afterText="人"
          onChange={(num) => {
            patchState('addPeople', Math.min(Math.max(num, 1), 99999 - spaceMaxPeople));
          }}
        />
      </div>
      <div className="text-t2 py-2 text-grey3">
        {`购买后上限${spaceMaxPeople + state.addPeople}人，当前上限${spaceMaxPeople}人。`}
      </div>
    </>
  );
});

/** 续费类型 */
const SelectPlanType: FC = memo(() => {
  const { state, patchState, completionTime, calculateQuote } = useUpgradeTeamPlanContext();

  return (
    <>
      <div className="text-t2 flex h-9 items-center">购买类型</div>
      <div className="text-t2 flex items-center justify-between py-2.5">
        {[
          { type: RenewalType.people, text: '人数' },
          { type: RenewalType.time, text: '时长' },
        ].map((item) => (
          <div
            key={item.type}
            onClick={() => patchState('selectedRenewalType', item.type)}
            className={cx(
              'text-t2-medium animate-hover flex h-10 w-[calc(50%-10px)] items-center justify-center border-[1.5px] border-grey5',
              state.selectedRenewalType === item.type && 'border-black'
            )}
          >
            增加 {item.text}
          </div>
        ))}
      </div>
      <div className="text-t2 py-2 text-grey3">
        {state.selectedRenewalType === RenewalType.people
          ? '只增加当前空间的人数，不调整到期时间。'
          : '只增加当前空间的到期时间，不调整人数。'}
      </div>
      {state.selectedRenewalType === RenewalType.people ? (
        <>
          <div className="text-t2 mt-2.5 flex h-9 items-center">购买时长</div>
          <div className="text-t2 my-2.5 mr-4 flex h-10 w-2/4 items-center justify-center rounded border-[1.5px] border-grey6 text-black/30 space-x-0.5">
            {!!completionTime.year && (
              <>
                <TransitionNumber value={completionTime.year} />
                <span>年</span>
              </>
            )}
            {!!completionTime.day && (
              <>
                <TransitionNumber value={completionTime.day} />
                <span>{__BUILD_IN__ ? 'days' : '天'}</span>
              </>
            )}
          </div>
          <div className="text-t2 py-2 text-grey3">
            {'为保证所有空间成员到期时间均为'}&nbsp;
            {dayjs(calculateQuote?.desiredExpireTime).format('YYYY年MM月DD日')}
            {'，新成员需购买到期剩余时长。'}
          </div>
          <SelectAddPeople />
        </>
      ) : (
        <SelectPayYear />
      )}
    </>
  );
});

/** 付费方式 */
const SelectPay: FC = memo(() => {
  const { state, patchState, getOrderParams, calculateQuote, isSmallTeamToTeam } =
    useUpgradeTeamPlanContext();
  const currentSpace = useCurrentSpace();
  const { currentPlan } = getOrderParams();
  const payList = currentPlan?.payList;

  const renderPayList = [
    {
      type: PayType.alipay,
      enable: payList?.includes(PayType.alipay),
      text: (
        <div className="flex items-center">
          <Icon name="IcAlipayPay" size="middle" className="mr-2 text-[#1677FF]" />
          支付宝支付
        </div>
      ),
    },
    {
      type: PayType.wxpay,
      enable: payList?.includes(PayType.wxpay),
      text: (
        <span className="flex">
          <Icon name="IcWechatPay" size="large" className="mr-2 text-[#07C160]" />
          微信支付
        </span>
      ),
    },
    {
      type: PayType.offline,
      enable: payList?.includes(PayType.offline),
      text: '对公转账',
    },
  ].filter((i) => i.enable);

  return (
    <>
      <div className="text-h2 mt-2.5 py-2.5">支付方式</div>
      <div
        className="grid py-2.5 gap-4"
        style={{
          gridTemplateColumns: `repeat(${renderPayList.length}, minmax(0, 1fr))`,
        }}
      >
        {renderPayList.map((item) => (
          <div
            key={item.type}
            onClick={() => patchState('selectedPayType', item.type)}
            className={cx(
              'text-t2-medium animate-hover flex h-10 items-center justify-center border-[1.5px] border-grey5',
              state.selectedPayType === item.type && 'border-black'
            )}
          >
            {item.text}
          </div>
        ))}
      </div>
      <UpgradePaddingUiKit
        className="justify-end text-t2"
        hidden={(calculateQuote?.diffDays || 0) + (calculateQuote?.dailyAverageAmount || 0) <= 0}
      >
        <p>
          {getSpacePlanTypeName(currentSpace.planType)}空间权益剩余
          <span className="text-active_color mx-1 whitespace-nowrap">
            {calculateQuote?.diffDays}
          </span>
          {isSmallTeamToTeam
            ? `天，可抵扣金额${calculateQuote?.diffAmount}元；抵扣金额按每日${calculateQuote?.dailyAverageAmount}元计算。抵扣金额上限不超过本次实际支付金额。`
            : `天，升级到小组版后需要补缴剩余天数差额；补缴金额按每日${calculateQuote?.dailyAverageAmount}元计算。`}
        </p>
      </UpgradePaddingUiKit>
    </>
  );
});
// #endregion

// #region 输入框组件
interface CustomNumberInputProps extends InputProps<'number'> {
  value?: number;
  afterText: string;
}
const CustomNumberInput: FC<CustomNumberInputProps> = memo((props) => {
  const { value, afterText, inputClassName, onChange, ...reset } = props;
  return (
    <Input
      focusHiddenPlaceholder
      inputType="number"
      placeholder="自定义"
      showBorder={false}
      value={value || ''}
      inputClassName={cx('h-9 text-center', inputClassName)}
      onChange={(num) => {
        const str = Math.max(0, Number(parseInt(`${num}`)));
        onChange?.(Number.isNaN(str) ? 0 : str);
      }}
      addonAfter={
        <>
          <span className="h-4 w-px bg-black_006" />
          <span className="flex h-full min-w-10 px-2 items-center justify-center">{afterText}</span>
        </>
      }
      {...reset}
    />
  );
});
// #endregion
// #endregion

// #region BuildIn
const OrderInfo: FC = memo(() => {
  const { upgradePlanName, calculateQuote, state, getOrderParams, completionTime, isRenew } =
    useUpgradeTeamPlanContext();
  const orderInfo = getOrderParams();
  const unitPrice = orderInfo.currentPlan?.currentPrice;

  const isPayPeople = isRenew && state.selectedRenewalType === RenewalType.people;
  const orderDateUnit = isPayPeople ? 'day' : getDateUnit(orderInfo.currentPlan?.monthNum || 1);
  const priceUnit = getCurrencySymbols(calculateQuote?.amountUnit);
  const dateUnit = orderDateUnit;
  const yearText = completionTime.year ? `${completionTime.year} year` : '';
  const dayText = completionTime.day ? `${completionTime.day} day` : '';
  const peopleNum = orderInfo.addPeople;

  const payPeople = [
    `${priceUnit}${unitPrice}/member/${orderDateUnit} ✖️ ${peopleNum} seats ✖️ ${dateUnit}`,
  ];

  const payTime = [
    `${priceUnit}${unitPrice}/member/${orderDateUnit} ✖️ ${peopleNum} seats ✖️ ${yearText} ${dayText} remaining`,
  ];

  return (
    <UpgradeComponents.OrderInfo
      planName={upgradePlanName}
      price={calculateQuote?.amount}
      priceUnit={calculateQuote?.amountUnit}
      info={isPayPeople ? payTime : payPeople}
      desc={
        isPayPeople ? 'Add seats' : `Billed ${getDateUnit(state.selectedTime.value, { ly: true })}`
      }
      protocols={[
        {
          title: 'Marketing Program Participant Terms',
          link: publicLink.marketingProgramParticipantTerms,
        },
      ]}
    />
  );
});

export const UpgradeTeamPlanBuildInContent: FC = memo(() => {
  const { loading, upgradeTitle, close, checkoutOrder, orderStateKey } =
    useUpgradeTeamPlanContext();
  const stripeSuccess = useStripeSuccess();

  return (
    <div className="min-h-[600px] flex flex-col">
      <UpgradeComponents.Header close={close} />
      {loading && <LoadingContainer className="h-full flex-1 pb-10" />}
      {!loading && (
        <StripeForm
          submit={() => {
            close();
            void stripeSuccess();
          }}
          orderStateKey={orderStateKey}
          getClientSecret={checkoutOrder}
        >
          <div className="grid grid-cols-2 max-h-[70vh] overflow-auto relative">
            <UpgradeComponents.BODY
              className={cx('px-8 mt-2.5 max-h-[none]', loading && 'opacity-60')}
            >
              <UpgradePaddingUiKit height={54} className="text-h2">
                {upgradeTitle}
              </UpgradePaddingUiKit>
              {/* 付 费内容规格 */}
              <PlanList />
              <OrderInfo />
            </UpgradeComponents.BODY>
            <div className="border-l py-5 px-4 w-full">
              <StripeCheckoutForm />
            </div>
          </div>
          <UpgradeComponents.StripePayBar />
        </StripeForm>
      )}
    </div>
  );
});
// #endregion
