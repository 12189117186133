import { encryptionPhone } from '@flowus/common';
import { RoleLevel } from '@flowus/common/block/type';
import { cx } from '@flowus/common/cx';
import { PermissionRole } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';
import type { FC, MouseEvent } from 'react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/common/components/button';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { message } from 'src/common/components/message';
import { useOpenModal } from 'src/common/components/next-modal';
import { UNNAMED_USER } from 'src/common/const';
import { useModel } from 'src/common/create-model';
import { request } from 'src/common/request';
import { temporaryBatchAndUpdateUsers } from 'src/components/share/search-user/utils';
import { UserAvatar } from 'src/components/user-avatar';
import { useFetchRootPages } from 'src/hooks/page';
import {
  fetchSpaces,
  jumpAfterFetchSpaces,
  useCurrentSpace,
  useDeleteSpace,
} from 'src/hooks/space';
import { useCurrentSpaceUsers } from 'src/hooks/space/use-current-space-users';
import { useGetSpaceRolePermission } from 'src/hooks/space/use-get-space-role-permission';
import { useIsProSpace } from 'src/hooks/space/use-is-pro-space';
import { useSearchSpaceMembers } from 'src/hooks/space/use-search-space-members';
import { useShareSpaceLink } from 'src/hooks/space/use-share-space-link';
import { useCurrentUser } from 'src/hooks/user';
import { Images } from 'src/image';
import { ADD_MARK_NAME } from 'src/redux/actions';
import { dispatch } from 'src/redux/store';
import { SpacePlanType } from 'src/redux/types';
import { fetchCurrentShareInfo, useCurrentSpacePlanInfo } from 'src/services/capacity/space-plans';
import { ViewPath } from 'src/utils';
import { getSpacePlanTypeName } from 'src/utils/block-utils';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { SettingModalCommon, SettingProvider, useSetting } from '../common';
import { OpenSettingFrom } from '../type';
import { useCloseSettingModal, useOpenUpgradeSpace } from '../use-open-setting-modal';
import { FoldItemList } from './fold-item-list';
import { ImportMember } from './import-member';
import { MarkNameEditButton } from './mark-name-edit-button';

export const Members: FC = () => {
  const { acquisitionTeamRecord } = useSetting();
  const { spacePlan, spaceShareInfo } = useCurrentSpacePlanInfo();
  const closeSettingModal = useCloseSettingModal();
  const isProSpace = useIsProSpace();
  const users = useCurrentSpaceUsers();
  const fetchSpaceRootPages = useFetchRootPages();
  const spaceUsers = Object.values(users);
  const openModal = useOpenModal();
  const currentSpace = useCurrentSpace();
  const currentUser = useCurrentUser();
  const getSpaceRolePermission = useGetSpaceRolePermission();
  const deleteSpace = useDeleteSpace();
  const spaceEditor = getSpaceRolePermission(currentUser.uuid).editor;
  const history = useHistory();
  const space = useCurrentSpace();
  const openUpgradeSpace = useOpenUpgradeSpace();
  const isFreeTeam = currentSpace.planType === SpacePlanType.freeTeam;
  const searchSpaceMembers = useSearchSpaceMembers();
  const [searchKeywords, setSearchKeywords] = useState('');
  /** 点击升级空间 */
  const { checkoutUpgraded } = useModel(SettingProvider);
  // 有editorMarkNameId的时候表示正在编辑备注
  const [editorMarkNameId, setEditorMarkNameId] = useState<string>();
  // 记录一下备注名，blur的时候保存
  const lastEditMarkNameRef = useRef<string>();

  const userList = useMemo(() => {
    const result = searchSpaceMembers({ keywords: searchKeywords });

    result.sort((a, b) => {
      if (a.spaceRole && b.spaceRole) {
        return RoleLevel[b.spaceRole] - RoleLevel[a.spaceRole];
      }
      return 0;
    });
    return result;
  }, [searchKeywords, searchSpaceMembers]);

  const spaceShareLink = useShareSpaceLink();

  // 除了这个 uuid 之外，还有没有其他管理员
  const getIsLastEditorUser = (uuid: string) => {
    return spaceUsers.some(
      (user) => user.spaceRole === PermissionRole.EDITOR && user.uuid !== uuid
    );
  };

  /** 获取邀请链接 */
  useEffect(() => {
    void fetchCurrentShareInfo();
  }, [spaceEditor]);

  /** 重置邀请链接 */
  const resetSpareShareLink = async () => {
    openModal.warning({
      title: '确认重置吗？',
      content: '重置后，之前的邀请链接将失效',
      confirm: async () => {
        const res = await request.infra.resetSpaceInvitation(currentSpace.uuid);
        message.success('重置成功');
        void fetchCurrentShareInfo({
          inviterId: res.uuid,
          createdAt: res.createdAt,
          expirationAt: res.expirationAt,
        });
      },
    });
  };

  const openMemberCapacityDialog = async () => {
    if (__PRIVATE__) {
      openModal.warning({
        title: '成员达到上限',
        content: '请联系管理员',
      });
      return;
    }
    openModal.warning({
      title: '成员达到上限',
      content: [
        '本空间为',
        getSpacePlanTypeName(currentSpace.planType),
        '人上限，如需增加空间成员，请升级空间',
      ],
      confirm: async () => {
        openUpgradeSpace(OpenSettingFrom.importMember);
      },
    });
  };

  /** 点击复制链接 */
  const clickCopy = () => {
    void writeTextInClipboard(spaceShareLink, { message: 'none' });

    if (spacePlan?.maxPeople && spacePlan.maxPeople <= spaceUsers.length) {
      openModal.warning({
        title: (
          <div className="mb-4">当前已达团队版购买成员数上限，如需增加成员，请付费后再邀请。</div>
        ),
        cancelText: '知道了',
        confirmText: '增加人数',
        confirm: () => {
          checkoutUpgraded(OpenSettingFrom.members);
        },
      });
    } else {
      message.success('复制成功，快去分享给同事吧');
    }
  };

  /** 删除成员 */
  const deleteMember = (uuid: string) => {
    const isMe = uuid === currentUser.uuid;

    // 如果我要离开，并且自己是唯一的管理员，则只能删除空间
    if (isMe && spaceEditor && !getIsLastEditorUser(uuid)) {
      if (isProSpace) {
        openModal.warning({
          title: '当前空间还未到期，并且你是本空间唯一的管理员，无法离开空间',
          confirmText: '知道了',
        });
        return;
      }
      openModal.warning({
        title: '确定删除空间？',
        content: '你是本空间唯一的管理员，无法离开，只能删除空间',
        countTime: 10,
        confirmText: '确认',
        confirm: async () => {
          await deleteSpace(currentSpace.uuid);
          closeSettingModal();
          if (!jumpAfterFetchSpaces()) {
            history.push(ViewPath.main);
          }
        },
      });
      return;
    }

    openModal.warning({
      title: isMe ? '确定离开吗？' : '确定移出吗？',
      content: isMe ? '离开后将不能再访问当前空间' : '移出后该用户将不能再访问当前空间',
      confirmText: isMe ? '确认离开' : '确认移出',
      confirm: async () => {
        await request.infra.removeSpaceMembers(currentSpace.uuid, {
          userId: uuid,
        });
        if (isMe) {
          message.success('你已离开该空间');
          await fetchSpaces(true);
          if (!jumpAfterFetchSpaces()) {
            history.push(ViewPath.main);
          }
        } else {
          await fetchSpaceRootPages(currentSpace.uuid);
        }
      },
    });
  };

  /** 修改成员权限 */
  const changeUserRol = async (uuid: string, type: PermissionRole) => {
    const isMe = uuid === currentUser.uuid;
    if (isMe) {
      message.error('不能修改自己的权限');
      return;
    }
    await request.infra.setSpaceMemberRole(currentSpace.uuid, {
      userId: uuid,
      role: type,
    });

    void fetchSpaceRootPages(currentSpace.uuid);
    message.success('修改成功');
  };

  /** 用户权限修改弹窗 */
  const openOption = (event: MouseEvent<HTMLDivElement>, uuid: string) => {
    const isMe = uuid === currentUser.uuid;
    const clickUser = spaceUsers.find((user) => user.uuid === uuid);
    if (!clickUser) return;

    openModal.dropdown({
      placement: 'bottom',
      offset: [0, 4],
      popcorn: event.currentTarget,
      content: ({ onCloseModal }) => (
        <ListView
          className="py-2 w-[270px] next-modal"
          onItemClick={() => onCloseModal()}
          items={
            spaceEditor
              ? [
                  {
                    type: ListItemType.TEXT_DESCRIPTION,
                    data: {
                      title: '管理员',
                      description: '仅管理员可以设置空间和邀请新成员',
                      selected: clickUser.spaceRole === PermissionRole.EDITOR,
                      onClick: () => void changeUserRol(uuid, PermissionRole.EDITOR),
                    },
                  },
                  {
                    type: ListItemType.TEXT_DESCRIPTION,
                    data: {
                      title: '成员',
                      selected: clickUser.spaceRole !== PermissionRole.EDITOR,
                      onClick: () => void changeUserRol(uuid, PermissionRole.WRITER),
                    },
                  },
                  { type: ListItemType.LINE },
                  {
                    type: ListItemType.TEXT_DESCRIPTION,
                    data: {
                      title: <div className="text-red">{isMe ? '离开团队' : '移出团队'}</div>,
                      onClick: () => deleteMember(uuid),
                    },
                  },
                ]
              : [
                  {
                    type: ListItemType.TEXT_DESCRIPTION,
                    data: {
                      title: <div className="text-red">离开团队</div>,
                      onClick: () => deleteMember(uuid),
                    },
                  },
                ]
          }
        />
      ),
    });
  };

  return (
    <div className="mt-3.5">
      {spaceEditor && spacePlan?.maxPeople && (
        <>
          {isFreeTeam &&
            (spaceUsers.length < spacePlan.maxPeople ? (
              <div className="flex items-center px-4 my-2.5 min-h-[60px] border border-grey6 text-t2">
                <div>
                  {'📢 本空间为'}
                  {getSpacePlanTypeName(currentSpace.planType)}
                  {'，成员上限是'}&nbsp;
                  {spacePlan?.maxPeople}&nbsp;{'人，如需增加空间成员，请'}
                  <span
                    className="border-b border-black cursor-pointer text-t2-medium"
                    onClick={() => checkoutUpgraded(OpenSettingFrom.members)}
                  >
                    升级空间
                  </span>
                  。
                </div>
              </div>
            ) : (
              <div className="flex shrink-0 justify-between items-center px-4 my-2.5 h-[60px] bg-yellow/20">
                <div className="text-t2">
                  {'📢 本空间为'}
                  {getSpacePlanTypeName(currentSpace.planType)}
                  {'，成员已达'}
                  {spacePlan.maxPeople} {'人上限，如需增加空间成员，请升级空间。'}
                </div>
                <Button onClick={() => checkoutUpgraded(OpenSettingFrom.members)} colorType="black">
                  升级空间
                </Button>
              </div>
            ))}
          <div
            hidden={acquisitionTeamRecord?.success || !acquisitionTeamRecord}
            className="text-t2 flex text-white-base p-2.5 rounded"
            style={{
              background: 'linear-gradient(272.19deg, #9E6CF7 6.24%, #CAACFF 102.91%)',
            }}
          >
            <img className="h-10" src={Images.s102SettingHornIcon} />
            <div>
              <p className="text-t1-medium">活动进行时...</p>
              <p className="text-t3">{`仅需邀请 ${acquisitionTeamRecord?.left} 人，本空间即可享受免费升级【小组版】，畅享专属权益60天！马上行动 👇`}</p>
            </div>
          </div>
          <div className="flex items-center h-9 mt-2.5 justify-between">
            <div className="text-black text-t2">邀请空间成员</div>
            <div
              hidden={__PRIVATE__}
              className="text-active_color text-t3 cursor-pointer"
              onClick={async (e) => {
                openModal.dropdown({
                  popcorn: e.currentTarget,
                  placement: 'left',
                  content: ({ onCloseModal }) => {
                    return (
                      <ImportMember
                        isGuest={false}
                        onReceiveGuestList={async (list) => {
                          if (
                            spacePlan?.maxPeople &&
                            spaceUsers.length + list.length > spacePlan?.maxPeople
                          ) {
                            void openMemberCapacityDialog();
                            return;
                          }
                          const { userIds, markNames } = await temporaryBatchAndUpdateUsers(list);
                          await request.infra.setSpaceMemberRoleBatch(space.uuid, userIds);
                          if (Object.entries(markNames).length > 0) {
                            await request.infra.addSpaceUserRemark(space.uuid, markNames);
                            await fetchSpaceRootPages(space.uuid);
                            message.success('导入成功');
                            onCloseModal();
                            // //本地更改一下space数据
                            // const markNameArray = Object.entries(markNames).map(
                            //   ([userId, markName]) => {
                            //     return { userId, markName };
                            //   }
                            // );
                            // dispatch(
                            //   ADD_MARK_NAME({
                            //     uuid: space.uuid,
                            //     patch: markNameArray,
                            //   })
                            // );
                          }
                        }}
                      />
                    );
                  },
                });
              }}
            >
              批量导入空间成员
            </div>
          </div>
          <div
            className={cx(
              'mt-2 mb-[18px] text-t4 text-grey3',
              !spaceShareInfo?.expirationAt && 'opacity-0'
            )}
          >
            {dayjs(spaceShareInfo?.expirationAt).format('YYYY年MM月DD日')}
            前，任何通过此链接邀请的人都可以进入当前空间，到达空间人数上限后将无法加入
          </div>
          <Input
            readonly
            inputClassName="h-8 text-black pr-0"
            value={spaceShareLink}
            addonAfter={
              <>
                <span
                  onClick={resetSpareShareLink}
                  className="flex justify-center items-center px-4 h-8 text-center text-black rounded-r-sm border-l border-grey6 animate-click text-t2-medium"
                >
                  重置链接
                </span>
                <span
                  onClick={clickCopy}
                  className="flex justify-center items-center px-4 h-8 text-center text-black rounded-r-sm border-l border-grey6 animate-click text-t2-medium"
                >
                  复制邀请链接
                </span>
              </>
            }
          />
          <SettingModalCommon.SettingDivider className="my-2.5" />
        </>
      )}
      <div className="flex top-0 shrink-0 items-center justify-between mt-2.5 h-9 text-t2">
        <div>人员信息</div>
        <Input
          className="pl-3 pr-1.5 w-full max-w-[300px] h-8 rounded-full"
          inputClassName="px-0"
          value={searchKeywords}
          onChange={setSearchKeywords}
          placeholder="搜索人员、手机号"
          addonBefore={<Icon size="middle" name="IcSearchMiddle" className="text-grey4" />}
          addonAfter={
            searchKeywords && (
              <Icon
                size="middle"
                name="IcUploadCancel"
                onClick={() => setSearchKeywords('')}
                className="text-grey4 cursor-pointer"
              />
            )
          }
        />
      </div>
      <FoldItemList
        foldLength={50}
        items={userList}
        renderItemContent={(user) => {
          const userEditorRole = user.uuid === currentUser.uuid || spaceEditor;
          const markName = space.userRemark?.[user.uuid];
          let userName = user.nickname;
          if (markName) {
            userName = `${markName} (${user.nickname || UNNAMED_USER})`;
          }
          return (
            <div className="group flex shrink-0 items-center h-[58px]" key={user.uuid}>
              <UserAvatar user={user} className="mr-2 w-[30px] h-[30px] !text-t1" />
              <div className="flex flex-col grow">
                {editorMarkNameId === user.uuid ? ( // 编辑备注
                  <Input
                    autoFocus={true}
                    maxLength={20}
                    className="pl-3 pr-1.5 w-full max-w-[300px] h-8 rounded-sm"
                    inputClassName="px-0"
                    defaultValue={markName}
                    onChange={(v) => {
                      lastEditMarkNameRef.current = v;
                    }}
                    placeholder="请输入备注"
                    onEnter={(e: React.KeyboardEvent) => {
                      if (e.target instanceof HTMLElement) {
                        e.target.blur();
                      }
                    }}
                    onBlur={async () => {
                      const markName = lastEditMarkNameRef.current;
                      lastEditMarkNameRef.current = undefined;
                      setEditorMarkNameId(undefined);
                      if (markName !== undefined) {
                        await request.infra.addSpaceUserRemark(space.uuid, {
                          [user.uuid]: markName,
                        });
                        // 本地更改一下space数据
                        dispatch(
                          ADD_MARK_NAME({
                            uuid: space.uuid,
                            patch: [
                              {
                                userId: user.uuid,
                                markName,
                              },
                            ],
                          })
                        );
                      }
                    }}
                  />
                ) : (
                  <>
                    <div className="flex items-center">
                      <span className="mb-0.5 text-t2">{userName || UNNAMED_USER}</span>
                      {userEditorRole && (
                        <MarkNameEditButton
                          className="ml-1 cursor-pointer transition-opacity group-hover:opacity-100 opacity-0"
                          onClick={() => {
                            setEditorMarkNameId(user.uuid);
                          }}
                        />
                      )}
                    </div>
                    <span className="text-grey3 text-t4">
                      {encryptionPhone(user.phone || user.email)}
                    </span>
                  </>
                )}
              </div>
              <div
                className={cx(
                  'self-center flex items-center text-t2',
                  userEditorRole && 'cursor-pointer'
                )}
                onClick={(e) => userEditorRole && openOption(e, user.uuid)}
              >
                {user.spaceRole === PermissionRole.EDITOR ? '管理员' : '成员'}
                {userEditorRole && <Icon name="IcArrow" className="ml-1" size="xxxsmall" />}
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};
