import type {
  CollectionFilter,
  CollectionFilterGroup,
  CollectionSchema,
  SegmentDTO,
} from '@next-space/fe-api-idl';
import { CollectionSchemaType, TextType } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash-es';
import { useCallback } from 'react';
import { COMPUTED_PROPERTIES, READONLY_PROPERTIES } from 'src/bitable/const';
import { isFilterValid, RELATIVE_DATE_RANGES } from 'src/bitable/table-view/body/filters';
import type { RecordCreatedFrom } from 'src/bitable/table-view/types';
import { useOpenModal } from 'src/common/components/next-modal';
import { DATE_FORMAT } from 'src/common/const';
import { getViewFormat } from 'src/hooks/block/get-view-format';
import { useInsertRecord } from 'src/hooks/block/use-insert-record';
import { updateCollectionView } from 'src/hooks/block/use-update-collection-view';
import { getSortedRecordIds } from 'src/hooks/collection-view/use-get-sorted-records';
import { getState } from 'src/redux/store';
import type { CollectionWhere } from 'src/redux/types';
import { $currentUserCache } from 'src/services/user/current-user';
import { bizTracker } from 'src/utils/biz-tracker';
import { fixCollectionId } from 'src/utils/fix-collection-id';
import { segmentsToText, textToSegments } from '../../utils/editor';
import {
  buildDateSegment,
  buildPersonSegment,
  convertSegmentsToNumber,
  readDateFromDateSegment,
} from '../../utils/segments';
import { formatCheckBoxValue } from '@flowus/common/block/checkbox-value';

export const useConfirmDisableSortersUI = () => {
  const openModal = useOpenModal();

  return (viewId: string) => {
    return new Promise((resolve) => {
      openModal.warning({
        title: <div className="mb-3">是否禁用排序规则</div>,
        content: (
          <div className="text-t2">
            当前已设置排序规则，这可能导致你找不到插入后的记录，是否将记录顺序调整为当前排序结果并禁用排序规则？
          </div>
        ),
        confirm: () => {
          bizTracker.event('bitable_view_sort_manage', { manage_type: 'popoff' });
          const viewInfo = getViewFormat(viewId);
          if (!viewInfo) {
            resolve(true);
            return;
          }

          const sortedIds = getSortedRecordIds({ viewId, ignoreSearch: true });

          const newSorters = cloneDeep(viewInfo.view.format.sorters) ?? [];
          for (const sorter of newSorters) {
            sorter.disable = true;
          }
          updateCollectionView(viewId, {
            pageSort: sortedIds,
            format: { sorters: newSorters },
          });
          resolve(true);
        },
        cancel: () => {
          resolve(false);
        },
      });
    });
  };
};

const filterValueToSegments = (schema: CollectionSchema, value: string) => {
  switch (schema.type) {
    case CollectionSchemaType.CHECKBOX:
      return formatCheckBoxValue(value) ? textToSegments('YES') : undefined;
    case CollectionSchemaType.CREATED_AT:
    case CollectionSchemaType.UPDATED_AT:
    case CollectionSchemaType.DATE: {
      const d = dayjs(value, DATE_FORMAT);
      return d.isValid() ? [buildDateSegment({ from: d.toDate() })] : undefined;
    }
    case CollectionSchemaType.CREATED_BY:
    case CollectionSchemaType.UPDATED_BY:
    case CollectionSchemaType.PERSON: {
      const userId = value === 'me' ? $currentUserCache.uuid : value;
      return [buildPersonSegment(userId)];
    }
    case CollectionSchemaType.RELATION: {
      if (value === '') return;
      return value
        .split(',')
        .filter(Boolean)
        .map((uuid) => {
          return {
            type: TextType.LINK_PAGE,
            enhancer: {},
            text: '',
            uuid,
          };
        });
    }
    case CollectionSchemaType.SELECT:
    case CollectionSchemaType.MULTI_SELECT:
      return textToSegments(schema.options?.find((it) => it.id === value)?.value);
    case CollectionSchemaType.EMAIL:
    case CollectionSchemaType.URL:
    default:
      return textToSegments(value);
  }
};

function resolvePropertyValues(
  schema: Record<string, CollectionSchema>,
  filter: CollectionFilter | CollectionFilterGroup | undefined,
  propertyValues: Record<string, SegmentDTO[] | undefined>
) {
  const fixRelDate = (value: string) => {
    const rel = RELATIVE_DATE_RANGES[value];
    if (rel != null) {
      return rel.fn()[0].format(DATE_FORMAT);
    }
    return value;
  };
  const processFilter = (filter: CollectionFilter | CollectionFilterGroup | undefined): boolean => {
    if (filter == null) return true;
    if (filter.disable) return true;
    if (filter.type === 'group') {
      if (filter.operator === 'and') {
        return filter.filters
          .filter((filter) => isFilterValid(filter, schema))
          .every((filter) => processFilter(filter));
      }

      if (filter.filters.length === 0) return true;
      return filter.filters
        .filter((filter) => isFilterValid(filter, schema))
        .some((filter) => processFilter(filter));
    }

    const { property } = filter;
    const propSchema = schema[property];
    if (propSchema == null) return true;

    // 产品要求这类属性一律右侧栏打开
    if (
      READONLY_PROPERTIES.includes(propSchema.type) ||
      COMPUTED_PROPERTIES.includes(propSchema.type)
    ) {
      return false;
    }

    const propValue = propertyValues[property];
    const resolveTo = (filterValue: string) => {
      if (propSchema.type === CollectionSchemaType.DATE) {
        filterValue = fixRelDate(filterValue);
      }
      propertyValues[property] = filterValueToSegments(propSchema, filterValue);
      return true;
    };
    const resolveIsEmpty = () => {
      if (propValue !== undefined) {
        return false;
      }
      return true;
    };
    const resolveIsNotEmpty = () => {
      if (propValue !== undefined) {
        return true;
      }
      switch (propSchema.type) {
        case CollectionSchemaType.DATE:
          return resolveTo(dayjs().format(DATE_FORMAT));
        case CollectionSchemaType.PERSON:
          return resolveTo('me');
        case CollectionSchemaType.SELECT:
        case CollectionSchemaType.MULTI_SELECT: {
          const tag = propSchema.options?.[0]?.id;
          return tag != null ? resolveTo(tag) : false;
        }
        case CollectionSchemaType.CHECKBOX:
          return resolveTo('YES');
        default:
          return false;
      }
    };
    const resolveEqNeq = (op: 'eq' | 'neq') => {
      const invertIfNeq = (value: boolean) => {
        if (op === 'neq') return !value;
        return value;
      };
      const resolveIfEq = (value: string) => {
        if (op === 'eq') return resolveTo(value);
        return true;
      };
      switch (propSchema.type) {
        case CollectionSchemaType.DATE: {
          const filterValue = dayjs(fixRelDate(filter.value), DATE_FORMAT);
          const dateSegment = propValue?.find(
            (it) => it.type === TextType.DATE || it.type === TextType.DATETIME
          );
          if (dateSegment != null) {
            const date = dayjs(readDateFromDateSegment(dateSegment)).startOf('day');
            return invertIfNeq(date.isSame(filterValue));
          }
          return resolveIfEq(filter.value);
        }
        case CollectionSchemaType.NUMBER: {
          const filterValue = Number(filter.value);
          const num = convertSegmentsToNumber(propValue);
          if (num !== undefined) {
            return invertIfNeq(num === filterValue);
          }
          return resolveIfEq(filter.value);
        }
        default:
          if (propValue !== undefined) {
            return invertIfNeq(segmentsToText(propValue) === filter.value);
          }
          return resolveIfEq(filter.value);
      }
    };
    const resolveEq = () => {
      return resolveEqNeq('eq');
    };
    const resolveNeq = () => {
      return resolveEqNeq('neq');
    };
    const resolveLtLteGtGte = (op: 'lt' | 'lte' | 'gt' | 'gte') => {
      switch (propSchema.type) {
        case CollectionSchemaType.NUMBER: {
          const filterValue = Number(filter.value);
          const num = convertSegmentsToNumber(propValue);
          if (num !== undefined) {
            switch (op) {
              case 'lt':
                return num < filterValue;
              case 'gt':
                return num > filterValue;
              case 'lte':
                return num <= filterValue;
              case 'gte':
                return num >= filterValue;
              default:
                return false;
            }
          }
          switch (op) {
            case 'lt':
              return resolveTo(`${filterValue - 1}`);
            case 'gt':
              return resolveTo(`${filterValue + 1}`);
            default:
              return resolveTo(filter.value);
          }
        }
        case CollectionSchemaType.DATE: {
          const filterValue = dayjs(fixRelDate(filter.value), DATE_FORMAT);
          const dateSegment = propValue?.find(
            (it) => it.type === TextType.DATE || it.type === TextType.DATETIME
          );
          if (dateSegment != null) {
            const date = dayjs(readDateFromDateSegment(dateSegment)).startOf('day');
            switch (op) {
              case 'lt':
                return date.isBefore(filterValue);
              case 'gt':
                return date.isAfter(filterValue);
              case 'lte':
                return date.isBefore(filterValue) || date.isSame(filterValue);
              case 'gte':
                return date.isAfter(filterValue) || date.isSame(filterValue);
              default:
                return false;
            }
          }
          switch (op) {
            case 'lt':
              return resolveTo(filterValue.subtract(1, 'day').format(DATE_FORMAT));
            case 'gt':
              return resolveTo(filterValue.add(1, 'day').format(DATE_FORMAT));
            default:
              return resolveTo(filterValue.format(DATE_FORMAT));
          }
        }
        default:
          return false;
      }
    };
    const resolveLt = () => {
      return resolveLtLteGtGte('lt');
    };
    const resolveGt = () => {
      return resolveLtLteGtGte('gt');
    };
    const resolveLte = () => {
      return resolveLtLteGtGte('lte');
    };
    const resolveGte = () => {
      return resolveLtLteGtGte('gte');
    };
    const resolveInNin = (op: 'in' | 'nin') => {
      const invertIfNin = (value: boolean) => {
        if (op === 'nin') return !value;
        return value;
      };
      const resolveIfIn = (value: string) => {
        if (op === 'in') return resolveTo(value);
        return true;
      };
      switch (propSchema.type) {
        case CollectionSchemaType.TEXT:
        case CollectionSchemaType.TITLE:
        case CollectionSchemaType.URL:
        case CollectionSchemaType.EMAIL:
        case CollectionSchemaType.PHONE: {
          if (propValue !== undefined) {
            return invertIfNin(segmentsToText(propValue).includes(filter.value));
          }
          return resolveIfIn(filter.value);
        }
        case CollectionSchemaType.MULTI_SELECT:
          if (propValue !== undefined) {
            return invertIfNin(
              segmentsToText(propValue)
                .split(/\s*,\s*/g)
                .findIndex((it) => it === filter.value) !== -1
            );
          }
          return resolveIfIn(filter.value);
        case CollectionSchemaType.PERSON:
          if (propValue !== undefined) {
            return invertIfNin(
              propValue.findIndex((it) => it.type === TextType.USER && it.uuid === filter.value) !==
                -1
            );
          }
          return resolveIfIn(filter.value);
        case CollectionSchemaType.RELATION:
          if (propValue !== undefined) {
            return invertIfNin(
              propValue.findIndex(
                (it) => it.type === TextType.LINK_PAGE && it.uuid === filter.value
              ) !== -1
            );
          }
          return resolveIfIn(filter.value);
        case CollectionSchemaType.DATE:
          if (propValue !== undefined) {
            return invertIfNin(
              propValue.findIndex(
                (it) => it.type === TextType.DATE || it.type === TextType.DATETIME
              ) !== -1
            );
          }
          return resolveIfIn(filter.value);
        default:
          return false;
      }
    };
    const resolveIn = () => {
      return resolveInNin('in');
    };
    const resolveNin = () => {
      return resolveInNin('nin');
    };
    const resolveStartsOrEndsWith = (op: 'startsWith' | 'endsWith') => {
      switch (propSchema.type) {
        case CollectionSchemaType.TEXT:
        case CollectionSchemaType.TITLE:
        case CollectionSchemaType.URL:
        case CollectionSchemaType.EMAIL:
        case CollectionSchemaType.PHONE:
          if (propValue !== undefined) {
            return segmentsToText(propValue)[op](filter.value);
          }
          return resolveTo(filter.value);
        default:
          return false;
      }
    };
    const resolveStartsWith = () => {
      return resolveStartsOrEndsWith('startsWith');
    };
    const resolveEndsWith = () => {
      return resolveStartsOrEndsWith('endsWith');
    };
    switch (filter.operator) {
      case 'isEmpty':
        return resolveIsEmpty();
      case 'isNotEmpty':
        return resolveIsNotEmpty();
      case 'eq':
        return resolveEq();
      case 'neq':
        return resolveNeq();
      case 'gt':
        return resolveGt();
      case 'gte':
        return resolveGte();
      case 'lt':
        return resolveLt();
      case 'lte':
        return resolveLte();
      case 'in':
        return resolveIn();
      case 'nin':
        return resolveNin();
      case 'startsWith':
        return resolveStartsWith();
      case 'endsWith':
        return resolveEndsWith();
      default:
        return true;
    }
  };
  return processFilter(filter);
}

interface InsertRecordParams {
  viewId: string;
  where?: CollectionWhere;
  propertyValues?: Record<string, SegmentDTO[] | undefined>;
  from?: RecordCreatedFrom;
  openInRight?: boolean;
  templateId?: string;
}

export const useInsertRecordUI = (opts?: { ignoreSorters?: boolean }) => {
  const confirmDisableSortersUI = useConfirmDisableSortersUI();
  const insertRecord = useInsertRecord();

  return useCallback(
    async ({
      viewId,
      where,
      propertyValues: propertyValues0,
      from,
      openInRight,
      templateId,
    }: InsertRecordParams) => {
      const view = getState().collectionViews[viewId];
      if (!view) return;
      const collectionId = fixCollectionId(viewId);
      if (!collectionId) return;

      const propertyValues: Record<string, SegmentDTO[] | undefined> = {};

      const doInsertRecord = async (opts?: { openInRight?: boolean }) => {
        const isOpenInRight = opts?.openInRight || openInRight;
        void insertRecord({
          viewId,
          where,
          propertyValues,
          templateId,
          isOpenInRight,
          from,
        });
      };

      let resolved = false;
      if (templateId != null) {
        // 产品要求模板创建记录不走自动根据筛选填值逻辑
      } else {
        const schema = getState().blocks[collectionId]?.data.schema ?? {};
        resolved = resolvePropertyValues(schema, view.format.filter, propertyValues);
        // FIXME: check if overlaps then show in right
        Object.assign(propertyValues, propertyValues0);
      }

      if (!resolved) {
        await doInsertRecord({ openInRight: true });
        return;
      }

      const sorters = view.format.sorters ?? [];
      const sortersEnabled = sorters.filter((it) => !it.disable).length > 0;
      if (!(opts?.ignoreSorters ?? false) && sortersEnabled) {
        bizTracker.event('bitable_cancel_sort_dialog', { from_scene: 'insert_record' });

        const confirmed = await confirmDisableSortersUI(viewId);
        await doInsertRecord({ openInRight: !confirmed });
        return;
      }

      await doInsertRecord();
    },
    [confirmDisableSortersUI, insertRecord, opts?.ignoreSorters]
  );
};
