import { BlockType } from '@next-space/fe-api-idl';
import { sampleSize } from 'lodash-es';
import { ColorKey } from 'src/colors';
import { isBlockNoText } from 'src/editor/utils/editor';
import { isInBlock } from 'src/utils/block-utils';
import { v4 as uuidV4 } from 'uuid';
import {
  CREATE_BLOCK,
  LIST_AFTER_BLOCK,
  LIST_BEFORE_BLOCK,
  LIST_REMOVE_BLOCK,
} from '../../actions';
import { dispatch, getState } from '../../store';
import { addBlock } from './add';
import { cleanupColumn } from './cleanup-column';
import { removePageSortRecord } from './helper';

const DEFAULT_BLOCK = {
  backgroundColor: '',
  data: {
    segments: [],
  },
  status: 1,
  subNodes: [],
  textColor: '',
  version: 0,
  permissions: [],
};

/**
 * uuid 与 targetId 组成分栏
 * position 表示组成分栏时, uuid 相对 target 的位置
 * 返回最新的一个columnId
 */
export const addColumn = (uuids: string[], targetId: string, position: 'left' | 'right') => {
  const { blocks } = getState();
  const target = blocks[targetId];
  if (!target) return;

  // 如果 target 是 COLUMN_LIST 则插入对应的位置
  if (target.type === BlockType.COLUMN) {
    // 新添加的columnRatio系数是1
    const columnRatio = 1;

    const colId = uuidV4();
    dispatch(
      CREATE_BLOCK({
        block: {
          ...DEFAULT_BLOCK,
          type: BlockType.COLUMN,
          uuid: colId,
          parentId: targetId,
          spaceId: target.spaceId,
          data: { ...DEFAULT_BLOCK.data, columnRatio },
        },
      })
    );
    if (position === 'left') {
      dispatch(
        LIST_BEFORE_BLOCK({
          uuid: colId,
          parentId: target.parentId,
          before: targetId,
        })
      );
    } else {
      dispatch(
        LIST_AFTER_BLOCK({
          uuid: colId,
          parentId: target.parentId,
          after: targetId,
        })
      );
    }

    uuids.forEach((uuid) => {
      const block = blocks[uuid];
      if (!block) return;

      removePageSortRecord(uuid);

      dispatch(LIST_REMOVE_BLOCK({ uuid, parentId: block.parentId }));
      dispatch(LIST_AFTER_BLOCK({ uuid, parentId: colId }));

      cleanupColumn(block.uuid, block.parentId);
    });

    return colId;
  }

  // 如果目标是普通 Block, 则先构造分栏容器
  const containerId = uuidV4();
  dispatch(
    CREATE_BLOCK({
      block: {
        ...DEFAULT_BLOCK,
        uuid: containerId,
        type: BlockType.COLUMN_LIST,
        parentId: target.parentId,
        spaceId: target.spaceId,
      },
    })
  );
  dispatch(
    LIST_AFTER_BLOCK({
      uuid: containerId,
      parentId: target.parentId,
      after: targetId,
    })
  );

  const leftColId = uuidV4();
  dispatch(
    CREATE_BLOCK({
      block: {
        ...DEFAULT_BLOCK,
        type: BlockType.COLUMN,
        uuid: leftColId,
        parentId: containerId,
        spaceId: target.spaceId,
        data: {
          ...DEFAULT_BLOCK.data,
          columnRatio: 1,
        },
      },
    })
  );
  dispatch(
    LIST_AFTER_BLOCK({
      uuid: leftColId,
      parentId: containerId,
    })
  );

  const rightColId = uuidV4();
  dispatch(
    CREATE_BLOCK({
      block: {
        ...DEFAULT_BLOCK,
        type: BlockType.COLUMN,
        uuid: rightColId,
        parentId: containerId,
        spaceId: target.spaceId,
        data: {
          ...DEFAULT_BLOCK.data,
          columnRatio: 1,
        },
      },
    })
  );
  dispatch(
    LIST_AFTER_BLOCK({
      uuid: rightColId,
      parentId: containerId,
      after: leftColId,
    })
  );

  uuids.forEach((uuid) => {
    const block = blocks[uuid];
    if (!block) return;

    removePageSortRecord(uuid);

    dispatch(LIST_REMOVE_BLOCK({ uuid }));
    dispatch(LIST_REMOVE_BLOCK({ uuid: targetId }));

    if (position === 'left') {
      dispatch(
        LIST_AFTER_BLOCK({
          uuid,
          parentId: leftColId,
        })
      );
      dispatch(
        LIST_AFTER_BLOCK({
          uuid: targetId,
          parentId: rightColId,
        })
      );
    } else {
      dispatch(
        LIST_AFTER_BLOCK({
          uuid: targetId,
          parentId: leftColId,
        })
      );
      dispatch(
        LIST_AFTER_BLOCK({
          uuid,
          parentId: rightColId,
        })
      );
    }

    cleanupColumn(block.uuid, block.parentId);
  });
  return rightColId;
};

/**
 *
 * @param posId 当前选中block或者光标定位的block，
 * @param count 创建列的个数
 * @returns
 */
export const addNewColumns = (
  posId: string,
  count: number,
  opt: {
    /** 是否需要创建默认的text块，默认为true */
    createEmptyTextBlock?: boolean;
    /** 如果当前块为空块，则转成分栏块，默认为true */
    autoTrim?: boolean;
    convertBlock: (uuids: string[], patch: any) => void; // 解决循环引用
  }
) => {
  const { blocks } = getState();
  const createEmptyTextBlock = opt?.createEmptyTextBlock ?? true;
  const autoTrim = opt?.autoTrim ?? true;
  const convertBlock = opt?.convertBlock;
  const target = blocks[posId];
  if (!target) return;
  const parent = blocks[target.parentId];
  if (!parent) return;
  const result = sampleSize(
    [
      ColorKey.grey,
      ColorKey.brown,
      ColorKey.orange,
      ColorKey.yellow,
      ColorKey.green,
      ColorKey.blue,
      ColorKey.purple,
      ColorKey.pink,
      ColorKey.red,
    ],
    count
  );
  const blockNoText = isBlockNoText(target.data);
  const inColumnListBlock = isInBlock(posId, [BlockType.COLUMN_LIST]);
  let containerId = target.uuid;
  if (blockNoText && !inColumnListBlock && autoTrim) {
    // 如果没有文本并且不在分栏下就转成分栏
    convertBlock([containerId], {
      type: BlockType.COLUMN_LIST,
      backgroundColor: '',
    });
  } else {
    // 先构造分栏容器
    containerId = addBlock(
      {
        type: BlockType.COLUMN_LIST,
      },
      { parentId: target.parentId, after: target.uuid }
    );
  }

  for (let i = 0; i < count; i++) {
    const columnId = addBlock(
      {
        type: BlockType.COLUMN,
        data: {
          columnRatio: 1,
        },
      },
      { parentId: containerId }
    );
    if (createEmptyTextBlock) {
      addBlock({ type: BlockType.TEXTAREA, backgroundColor: result[i] }, { parentId: columnId });
    }
  }
  return containerId;
};
