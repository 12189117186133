import { message } from '@flowus/common/components/message';
import { cx } from '@flowus/common/cx';
import { deepEqual } from '@flowus/common/utils/tools';
import { SpacePlanType } from '@flowus/shared';
import type {
  NewUserAcquisitionTeamRecord,
  SpaceDTO,
  SpaceSettingDTO,
} from '@next-space/fe-api-idl';
import { DaySetting, PermissionRole } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';
import { split } from 'lodash-es';
import type { FC, ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/common/components/button';
import { SettingDivider } from 'src/common/components/divider';
import { Icon } from 'src/common/components/icon';
import { useOpenModal } from 'src/common/components/next-modal';
import { DATE_TIME_FORMAT } from 'src/common/const';
import { createModel, useModel } from 'src/common/create-model';
import { request } from 'src/common/request';
import { useAcquisitionTeam } from 'src/components/activities/dialog';
import { ScrollLoadList } from 'src/components/scroll-load-list';
import type { UpgradeSupportParams } from 'src/components/select-upgrade-plan/hook/use-upgrade-support';
import { updateExChangeCodeRedBadge } from 'src/hooks/activities/use-has-new-activity';
import { useFetchSpaceCapacity } from 'src/hooks/drive/use-fetch-space-capacity';
import { useIsGuest } from 'src/hooks/share/use-permission-utils';
import {
  fetchSpaces,
  jumpAfterFetchSpaces,
  useCurrentSpace,
  useCurrentSpaceView,
  useOpenDeleteSpace,
  useSpaceInlinePageIcon,
  useUpdateSpace,
} from 'src/hooks/space';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { useCurrentSpaceUsers } from 'src/hooks/space/use-current-space-users';
import { getSpaceRolePermission } from 'src/hooks/space/use-get-space-role-permission';
import { useUpdateSpaceView } from 'src/hooks/space/use-update-space-view';
import { useTransaction } from 'src/hooks/use-transaction';
import { useCurrentUser } from 'src/hooks/user/use-current-user';
import { Modals } from 'src/modals';
import { fetchAiCapacity } from 'src/services/capacity';
import { fetchCurrentSpacePlan, useCurrentSpacePlanInfo } from 'src/services/capacity/space-plans';
import { ViewPath } from 'src/utils';
import { TestIds, bindDataTestId } from 'src/utils/qa-utils';
import { DomainStatus, domainErrorMsg } from './space-setting/helper';
import type { SettingModelProps } from './type';
import { OpenSettingFrom, SettingMenuType } from './type';
import { useCloseSettingModal } from './use-open-setting-modal';
import { useSpaceSecurityState } from 'src/hooks/space/use-space-security-state';

// #region useSettingModal
interface SpaceSettingProps {
  setClickMaskClose: (status: boolean) => void;
}

const useSpaceSetting = (props: SpaceSettingProps) => {
  const { setClickMaskClose } = props;
  const isGuest = useIsGuest();
  const closeSettingModal = useCloseSettingModal();
  const openModal = useOpenModal();
  const currentUser = useCurrentUser();
  const openDeleteSpace = useOpenDeleteSpace();
  const history = useHistory();
  const updateSpace = useUpdateSpace();
  const users = useCurrentSpaceUsers();
  const spaceUser = Object.values(users);
  const transaction = useTransaction();
  const currentSpace = useCurrentSpace();
  const currentSpaceView = useCurrentSpaceView();
  const freeTeam = currentSpace.planType === SpacePlanType.freeTeam;
  const [spaceName, setSpaceName] = useState(currentSpace.title);
  const [domain, setDomain] = useState(currentSpace.domain);
  const [finalDomain, setFinalDomain] = useState(currentSpace.domain);
  const [publicHomePage, setPublicHomePage] = useState(currentSpace.publicHomePage);
  const [showPublicHomePage, setShowPublicHomePage] = useState(
    currentSpace?.setting?.showPublicHomePage ?? true
  );
  const [icon, setIcon] = useState<typeof currentSpace.icon>(currentSpace.icon);
  const [domainStatus, setDomainStatus] = useState(DomainStatus.INIT);
  const checkDomainTimerRef = useRef<ReturnType<typeof setTimeout>>();
  const [showBackLink, setShowBackLink] = useState(
    currentSpace.setting === null ||
      typeof currentSpace.setting === 'undefined' ||
      !!currentSpace.setting.isShowBacklink
  );
  const [enableRecommend, setRecommend] = useState(
    !currentSpaceView?.setting?.closeTemplateRecommend ?? true
  );
  const defaultInlinePageIcon = useSpaceInlinePageIcon();
  const [inlinePageIcon, setInlinePageIcon] = useState(defaultInlinePageIcon);

  const [enableAI, setAI] = useState(currentSpace?.setting?.enableAI ?? true);
  const [disableFormNotification, setDisableFormNotification] = useState(
    currentSpaceView?.setting?.closePostFormNotification ?? false
  );
  const [startDay, setStartDay] = useState<DaySetting>(
    currentSpace?.setting?.daySetting ?? DaySetting.MON
  );
  const spaceSecurityEnable = currentSpace.planType === 'team';
  const abandonedPublicShare =
    spaceSecurityEnable && (currentSpace?.setting?.abandonedPublicShare ?? false);
  const abandonedGuest = spaceSecurityEnable && (currentSpace?.setting?.abandonedGuest ?? false);
  const abandonedExport = spaceSecurityEnable && (currentSpace?.setting?.abandonedExport ?? false);
  const openWatermark = spaceSecurityEnable && (currentSpace?.setting?.openWatermark ?? false);

  const commentAlignment = currentSpace?.setting?.commentAlignment ?? 'top';

  const { editor: spaceEditor } = getSpaceRolePermission(currentUser.uuid);
  const updateSpaceView = useUpdateSpaceView();

  const checkDomain = (domain: string) => {
    setDomain(domain);
    if (!domain) {
      setFinalDomain(domain);
    }
    if (checkDomainTimerRef.current) {
      clearTimeout(checkDomainTimerRef.current);
      checkDomainTimerRef.current = undefined;
    }
    checkDomainTimerRef.current = setTimeout(() => {
      if (!domain) {
        setDomainStatus(DomainStatus.INIT);
        return;
      }
      if (domain === currentSpace.domain && domainStatus === DomainStatus.INIT) {
        return;
      }
      if (domain.length < 4 || domain.length > 30) {
        setDomainStatus(DomainStatus.LEN_NOT_RIGHT);
        return;
      }
      if (domain === currentSpace.domain) {
        setDomainStatus(DomainStatus.CAN_USE);
        return;
      }
      const param = domain;
      setDomainStatus(DomainStatus.CHECKING);
      void request.infra.postSpaceDomainCheck
        .raw(getCurrentSpaceId(), {
          domain,
        })
        .then((res) => {
          setTimeout(() => {
            // 由于接口太快，这里setTimeout，搞个loading
            if (param !== domain) {
              return;
            }
            if (res.code === 200 && res.data) {
              setDomainStatus(DomainStatus.CAN_USE);
              setFinalDomain(domain);
            } else {
              setDomainStatus(DomainStatus.CAN_NOT_USE);
            }
          }, 500);
        });
    }, 800);
  };

  useEffect(() => {
    setClickMaskClose(spaceName === currentSpace.title && deepEqual(icon, currentSpace.icon));
  }, [currentSpace.icon, currentSpace.title, icon, setClickMaskClose, spaceName]);

  // 自己是最后一个管理员
  const isLastEditorUser =
    spaceEditor &&
    !spaceUser.some(
      (user) => user.spaceRole === PermissionRole.EDITOR && user.uuid !== currentUser.uuid
    );

  const saveSpace = (spaceSettingData: Partial<SpaceDTO> = currentSpace) => {
    transaction(() => {
      void updateSpace(currentSpace.uuid, {
        ...{ ...currentSpace, ...spaceSettingData },
        setting: {
          ...currentSpace.setting,
          ...spaceSettingData.setting,
        },
      });
    });
  };

  const saveSpaceSetting = (closeModal = true, showMessage = true) => {
    const newSpaceName = spaceName.trim();
    const errMsg = domainErrorMsg[domainStatus];
    if (errMsg && showMessage) {
      message.error(errMsg);
      return;
    }

    if (split(newSpaceName, '').length >= 2 && split(newSpaceName, '').length <= 20) {
      transaction(() => {
        saveSpace({
          title: newSpaceName,
          icon,
          domain: finalDomain,
          publicHomePage,
          setting: {
            isShowBacklink: showBackLink,
            daySetting: startDay,
            enableAI,
            commentAlignment,
            showPublicHomePage,
            inlinePageIcon,
            abandonedPublicShare,
            abandonedGuest,
            abandonedExport,
            openWatermark,
          },
        });

        currentSpaceView &&
          updateSpaceView(currentSpaceView.uuid, {
            setting: {
              closeTemplateRecommend: !enableRecommend,
              closePostFormNotification: disableFormNotification,
            },
          });
      });
      closeModal && closeSettingModal();
      showMessage && message.success('保存成功');
    } else {
      showMessage && message.error('名称长度应为2-20个字符');
    }
  };

  const handleDeleteSpace = async () => {
    await openDeleteSpace();
    closeSettingModal();
  };

  const leavingSpace = async () => {
    openModal.warning({
      title: '确定离开吗？',
      content: '离开后将不能再访问当前空间',
      confirmText: '确定离开',
      confirm: async () => {
        await request.infra.removeSpaceMembers(currentSpace.uuid, {
          userId: currentUser.uuid,
        });
        message.success('你已离开该空间');
        await fetchSpaces(true);
        if (!jumpAfterFetchSpaces()) {
          history.push(ViewPath.main);
        }
      },
    });
  };

  return {
    isGuest,
    spaceEditor,
    leavingSpace,
    handleDeleteSpace,
    saveSpaceSetting,
    isLastEditorUser,
    checkDomain,
    spaceName,
    setSpaceName,
    domain,
    setDomain,
    finalDomain,
    setFinalDomain,
    publicHomePage,
    setPublicHomePage,
    showPublicHomePage,
    setShowPublicHomePage,
    icon,
    setIcon,
    domainStatus,
    setDomainStatus,
    showBackLink,
    setShowBackLink,
    enableRecommend,
    setRecommend,
    inlinePageIcon,
    setInlinePageIcon,
    enableAI,
    setAI,
    disableFormNotification,
    setDisableFormNotification,
    startDay,
    setStartDay,
    commentAlignment,
    freeTeam,
    abandonedPublicShare,
    abandonedGuest,
    abandonedExport,
    openWatermark,
  };
};

const useSettingModel = (props: SettingModelProps) => {
  const {
    defaultUpgradeAiPanel = false,
    defaultMenuType = SettingMenuType.account,
    from = OpenSettingFrom.package,
  } = props;
  const fetchSpaceCapacity = useFetchSpaceCapacity();
  useCurrentSpacePlanInfo();

  /** 当前选中的setting tab */
  const [currentSettingMenu, setCurrentSettingMenu] = useState(defaultMenuType);
  /** 是否可以点击mask关闭弹窗 */
  const [clickMaskClose, setClickMaskClose] = useState(true);
  /** 打开窗口的途径，用于埋点 */
  const [isFrom, setIsFrom] = useState(from);
  /** 是否展示 AI 购买面板 */
  const [upgradeAiPanel, setUpgradeAiPanel] = useState(defaultUpgradeAiPanel);

  const acquisitionTeam = useAcquisitionTeam();

  const [acquisitionTeamRecord, setAcquisitionTeamRecord] = useState<
    NewUserAcquisitionTeamRecord | undefined
  >(undefined);

  /** 跳转到升级tab */
  const checkoutUpgraded = (fromType: OpenSettingFrom) => {
    setIsFrom(fromType);
    setCurrentSettingMenu(SettingMenuType.upgrade);
    void fetchCurrentSpacePlan();
  };

  const toUpgradeAi = () => {
    setUpgradeAiPanel(true);
    setCurrentSettingMenu(SettingMenuType.upgrade);
  };

  useEffect(() => {
    void acquisitionTeam({
      needOpenModal: false,
      getIsAcquisitionTeam: setAcquisitionTeamRecord,
    });
    void fetchCurrentSpacePlan();
    void fetchAiCapacity();
    void fetchSpaceCapacity();
    updateExChangeCodeRedBadge();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const spaceSettingState = useSpaceSetting({ setClickMaskClose });

  return {
    toUpgradeAi,
    checkoutUpgraded,
    currentSettingMenu,
    setCurrentSettingMenu,
    clickMaskClose,
    setClickMaskClose,
    isFrom,
    setIsFrom,
    upgradeAiPanel,
    setUpgradeAiPanel,
    acquisitionTeamRecord,
    spaceSettingState,
  };
};

export const SettingProvider = createModel(useSettingModel);
export const useSetting = () => useModel(SettingProvider);

/** 升级团队的tab切换类型 */
export enum SettingUpdateTabType {
  team = 'team',
  personal = 'personal',
}

/** 共用样式 */
const commonStyles = {
  container: 'px-[60px] flex-1 cursor-default flex flex-col overflow-y-auto pb-10',
  name: 'mb-3.5 text-black',
  input: 'w-full mb-3 h-9 flex-shrink-0',
};

/** 底部操作按钮 */
const FooterBar: FC<{
  confirm?: () => void;
  cancel?: () => void;
  confirmText?: string;
  cancelText?: string;
}> = (props) => {
  const { confirm, cancel, confirmText, cancelText } = props;
  return (
    <div className="flex self-end py-3 px-[60px] w-full h-14 border-t border-grey7">
      {confirm && (
        <Button
          colorType="active"
          {...bindDataTestId(TestIds.settingSave)}
          onClick={confirm}
          className="mr-[20px] text-t2-medium animate-click"
        >
          {confirmText ?? '保存'}
        </Button>
      )}
      {cancel && (
        <Button
          {...bindDataTestId(TestIds.settingCancel)}
          onClick={cancel}
          className="text-t2-medium animate-click"
        >
          {cancelText ?? '取消'}
        </Button>
      )}
    </div>
  );
};

// 标题栏
const Header: FC<{ title: ReactNode; name?: string; addonAfter?: ReactNode }> = ({
  title,
  name,
  addonAfter,
}) => {
  return (
    <>
      <div className="flex-shrink-0 flex items-center mb-1 text-grey3 h-9 text-t2 justify-between">
        {title}
        {addonAfter}
      </div>
      <SettingDivider className={cx(name && 'mb-2.5')} />
      {name && <div className="flex items-center h-9 text-black text-t2">{name}</div>}
    </>
  );
};

/** 套餐支付方式 */
export enum PaymentType {
  cash = 'cash',
  integralRecord = 'integralRecord',
}

/** 设置项 */
const Item: FC<{ leftChild: ReactNode; rightChild?: ReactNode; className?: string }> = (props) => {
  const { leftChild, rightChild, children, className } = props;
  return (
    <>
      <div
        className={cx('py-2 flex items-center justify-between text-t2 flex-shrink-0', className)}
      >
        <div>{leftChild}</div>
        <div>{rightChild}</div>
      </div>
      {children}
    </>
  );
};

/** 设置项的按钮，用户唤起dropdown */
const DropdownButton: FC<
  {
    content?: ReactNode;
    className?: string;
    disabled?: boolean;
  } & Omit<JSX.IntrinsicElements['div'], 'content'>
> = ({ content, className, disabled, ...reset }) => {
  return (
    <div
      className={cx(
        'flex items-center h-full text-t3',
        disabled ? 'opacity-60 cursor-not-allowed' : 'animate-click',
        className
      )}
      {...reset}
    >
      <span className="mr-2">{content}</span>
      <Icon className="text-grey3" name="IcArrow" size="xxxsmall" />
    </div>
  );
};

/** 容量补充套餐列表 */
interface OrderListSupportProps {
  title: string;
  subTitle?: string;
  openUpgradeModal?: (params?: UpgradeSupportParams) => void;
  orderType: 'aiUsage';
}
export const OrderListSupport: FC<OrderListSupportProps> = (props) => {
  const { title, subTitle, openUpgradeModal, orderType } = props;
  const openModal = useOpenModal();
  const container = useRef<HTMLDivElement>(null);
  const currentSpace = useCurrentSpace();

  const handleOpenUpgradeCapacity = () => {
    openModal.closeModal(Modals.ORDER_LIST_SUPPORT);
    openUpgradeModal?.({ isFrom: OpenSettingFrom.dashboard_ai });
  };

  const getApi = async (params: { pageIndex: number; pageSize: number }) => {
    const res = await request.infra.getOrderList(
      params.pageIndex,
      params.pageSize,
      currentSpace.uuid,
      orderType
    );

    const list = res.list ?? [];

    return { list, more: res.more };
  };

  return (
    <div className="w-125 py-1">
      {/* title */}
      <div className="h-12 flex items-center justify-between px-7">
        <span className="text-h4">{title}</span>
        <span
          className="text-t2 animate-click text-active_color"
          onClick={() => handleOpenUpgradeCapacity()}
        >
          {subTitle}
        </span>
      </div>
      <SettingModalCommon.SettingDivider />
      <div className="px-7 text-t2">
        {/* 表头 */}
        <div className="grid grid-cols-6 h-10 items-center">
          <span className="col-span-2">套餐类型</span>
          <span className="col-span-2">使用状态</span>
          <span className="col-span-2">到期时间</span>
        </div>
        <SettingModalCommon.SettingDivider />
        <div ref={container} className="max-h-120 overflow-y-auto">
          <ScrollLoadList
            container={container}
            getApi={getApi}
            placeholder={'无购买记录'}
            renderItem={(item) => (
              <div
                key={item.id}
                className={cx(
                  'grid grid-cols-6 h-10 items-center',
                  item.status !== 20 && 'opacity-30'
                )}
              >
                <span className="col-span-2">{item.description}</span>
                <span className="col-span-2">
                  {item.status === 20 || (item.expirationTime && item.expirationTime < Date.now())
                    ? '使用中'
                    : '已过期'}
                </span>
                <span className="col-span-2">
                  {dayjs(item.subscriptionEndDate).format(DATE_TIME_FORMAT)}
                </span>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

const Arrow: FC<{ className?: string }> = ({ className }) => {
  return <Icon className={cx('text-grey3', className)} name="IcArrowDateNext" size="small" />;
};

export const SettingModalCommon = {
  commonStyles,
  FooterBar,
  SettingDivider,
  Header,
  Item,
  DropdownButton,
  Arrow,
};
