import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { useCallback } from 'react';
import { LoadingContainer } from 'src/common/components/loading-container';
import { useOpenModal } from 'src/common/components/next-modal';
import { useModel } from 'src/common/create-model';
import { Modals } from 'src/modals';
import { getCurrencySymbols } from 'src/utils/currency-format';
import { getDateUnit } from '../pay-cycle-button';
import { StripeCheckoutForm, StripeForm } from '../stripe';
import { UpgradeCouponList, UpgradePaddingUiKit } from './common';
import type { UpgradeSupportParams } from './hook/use-upgrade-support';
import { createUpgradeSupportModel, useStripeSuccess } from './hook/use-upgrade-support';
import { UpgradeComponents } from './upgrade-components';
import { publicLink } from 'src/common/const';
import { PRODUCT_AI_TITLE_BUILDIN } from 'src/const/title';

const UpgradeAiContext = createUpgradeSupportModel();
const useUpgradeAi = () => useModel(UpgradeAiContext);

const UpgradeAi: FC = () => {
  const {
    couponId,
    selectCoupon,
    onPay,
    close,
    payMethod,
    setPayMethod,
    planId,
    onSelectPlan,
    planList,
    curCalculateQuote,
    loading,
    lastDate,
    couponList,
  } = useUpgradeAi();

  return (
    <>
      <UpgradeComponents.Header close={close} />
      <div className="px-7">
        <UpgradePaddingUiKit height={54} className="text-h2">
          购买 AI 权益套餐
        </UpgradePaddingUiKit>
        <UpgradePaddingUiKit height={36} className="text-grey3 text-t3">
          按月计费，仅限在当前空间使用
        </UpgradePaddingUiKit>
      </div>
      {/* 选择套餐 */}
      <UpgradeComponents.SelectPlan
        items={planList}
        loading={loading}
        selectId={planId}
        itemClick={onSelectPlan}
        itemRender={(item) => {
          return (
            <>
              <div className="mb-2.5 text-t4">
                <span className="text-h4">{item.name}</span>
                <span>次/日｜30天</span>
              </div>
              <div className="text-h3">
                {getCurrencySymbols(item.currentPriceUnit)}
                {item.currentPrice}
              </div>
            </>
          );
        }}
      />
      {/* 购买后的失效日期 */}
      <UpgradeComponents.PlanEndTime endTime={lastDate} />
      {/* 优惠券 */}
      <UpgradeCouponList
        onSelectId={selectCoupon}
        couponList={couponList}
        selectId={couponId}
        className={'px-8 my-2.5'}
      />
      {/* 购买方式 */}
      <UpgradeComponents.PayMethod payMethod={payMethod} switchPayMethod={setPayMethod} />
      {/* 付费 */}
      <UpgradeComponents.PayBar
        price={curCalculateQuote?.amount}
        priceUnit={curCalculateQuote?.amountUnit}
        disablePay={loading}
        onPay={onPay}
      />
    </>
  );
};

const UpgradeAiBuildIn: FC = () => {
  const {
    close,
    planId,
    onSelectPlan,
    orderStateKey,
    planList,
    loading,
    lastDate,
    getClientSecret,
    currentPlan,
    curCalculateQuote,
  } = useUpgradeAi();
  const stripeSuccess = useStripeSuccess();

  if ((loading && !orderStateKey) || !currentPlan) {
    return <LoadingContainer className="h-[400px]" />;
  }

  const price = `$${currentPlan.currentPrice}`;
  const dateUnit = getDateUnit(currentPlan.monthNum);

  return (
    <div>
      <UpgradeComponents.Header close={close} />
      <StripeForm
        submit={() => {
          close();
          void stripeSuccess();
        }}
        getClientSecret={getClientSecret}
        orderStateKey={orderStateKey}
      >
        <div className="grid grid-cols-2 max-h-[50vh] overflow-auto">
          <div className="px-8 py-2.5">
            <UpgradePaddingUiKit height={54} className="text-h2">
              购买 AI 权益套餐
            </UpgradePaddingUiKit>
            <UpgradePaddingUiKit height={36} className="text-grey3 text-t3">
              按月计费，仅限在当前空间使用
            </UpgradePaddingUiKit>
            {/* 选择套餐 */}
            <UpgradeComponents.SelectPlan
              items={planList}
              loading={loading}
              selectId={planId}
              itemClick={onSelectPlan}
              className="grid grid-cols-2 gap-5 space-x-0 px-0"
              itemClassName="h-10 text-t2 w-auto"
              itemRender={(item) => {
                return <>{item.name}</>;
              }}
            />
            {/* 购买后的失效日期 */}
            <UpgradeComponents.PlanEndTime className="px-0" endTime={lastDate} />
            <UpgradeComponents.OrderInfo
              planName={PRODUCT_AI_TITLE_BUILDIN}
              info={[`${price}/space/${dateUnit}`]}
              desc={['Billed', getDateUnit(currentPlan.monthNum, { ly: true })].join(' ')}
              price={curCalculateQuote?.amount}
              priceUnit={curCalculateQuote?.amountUnit}
              protocols={[
                { title: 'AI Supplementary Terms', link: publicLink.aiSupplementaryTerms },
              ]}
            />
            {/* </StripeForm> */}
          </div>
          <div className="border-l py-5 px-4 w-full">
            <StripeCheckoutForm />
          </div>
        </div>
        <UpgradeComponents.StripePayBar />
      </StripeForm>
    </div>
  );
};

export const useOpenUpgradeAiModal = () => {
  const openModal = useOpenModal();

  return useCallback(
    (params?: UpgradeSupportParams) =>
      openModal.modal({
        modalId: Modals.UPGRADE_SUPPORT,
        className: cx(
          'min-h-[400px] max-h-[80vh] next-modal-scroll',
          __BUILD_IN__ ? 'w-[800px]' : 'w-[600px]'
        ),
        content: () => (
          <UpgradeAiContext planId={params?.planId} isFrom={params?.isFrom} upgradeType="ai">
            {__BUILD_IN__ ? <UpgradeAiBuildIn /> : <UpgradeAi />}
          </UpgradeAiContext>
        ),
      }),
    [openModal]
  );
};
