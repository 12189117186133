import { useBindAccount } from '@flowus/login/hook/use-bind-account';
import { CaptchaComp } from '@flowus/login/uikit/web/captcha';
import { CaptchaSendType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useState } from 'react';
import { Button } from 'src/common/components/button';
import { Input } from 'src/common/components/input';
import { request } from 'src/common/request';
import { $currentUserCache } from 'src/services/user/current-user';
import { Regex } from 'src/utils';
import { initLogin } from 'src/utils/init-login';

interface Props {
  onSuccess: () => void;
}

export const BindEmail: FC<Props> = (props) => {
  const {
    email,
    setEmail,
    emailError,
    verifyCode,
    setVerifyCode,
    verifyCodeError,
    bind,
    sendVerifyCode,
  } = useBindEmail();
  return (
    <div className="next-modal p-7 w-[400px]">
      <div className="text-t1-medium ">绑定邮箱</div>
      <Input
        autoFocus
        warning={emailError}
        className="w-full h-9 mt-5"
        inputClassName="opacity-50"
        value={email}
        placeholder="请输入邮箱"
        onChange={setEmail}
      />
      <Input
        type="number"
        value={verifyCode}
        maxLength={6}
        warning={verifyCodeError}
        className="w-full h-9 mt-5 px-3"
        inputClassName="opacity-50 px-0"
        placeholder="请输入验证码"
        onChange={(v) => setVerifyCode(v.toString())}
        addonAfter={
          <CaptchaComp
            onSendVerifyCode={() => {
              return sendVerifyCode();
            }}
          />
        }
      />
      <Button
        onClick={async () => {
          const success = await bind();
          if (success) props.onSuccess();
        }}
        colorType="active"
        className="mt-6"
      >
        完成绑定
      </Button>
    </div>
  );
};
// 需要初始化一些数据
initLogin();
const useBindEmail = () => {
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>();
  const [verifyCode, setVerifyCode] = useState('');
  const [verifyCodeError, setVerifyCodeError] = useState('');
  const { bindEmail } = useBindAccount();

  const checkEmail = () => {
    if (!Regex.Email.test(email)) {
      setEmailError('邮箱格式不正确');
      return false;
    }
    return true;
  };
  const checkVerifyCode = () => {
    if (!Regex.VerifyCode.test(verifyCode)) {
      setVerifyCodeError('验证码错误');
      return false;
    }
    return true;
  };
  const sendVerifyCode = async () => {
    if (!checkEmail()) return;
    return request.infra.emailCaptcha({
      email,
      type: CaptchaSendType.BIND_NEW,
    });
  };
  /** 设置邮箱 */
  const bind = async () => {
    if (!checkEmail()) return;
    if (!checkVerifyCode()) return;
    return bindEmail($currentUserCache.uuid, email, verifyCode);
  };
  return {
    email,
    emailError,
    verifyCode,
    verifyCodeError,
    setEmail,
    setVerifyCode,
    sendVerifyCode,
    bind,
  };
};
