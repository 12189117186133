import { BlockStatus, BlockType, PermissionRole } from '@next-space/fe-api-idl';
import type { ReactNode } from 'react';
import { memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/common/components/button';
import { Icon } from 'src/common/components/icon';
import { useOpenModal } from 'src/common/components/next-modal';
import { useRestorePage } from 'src/hooks/page';
import { useParentIsOk } from 'src/hooks/share/use-permission-utils';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { getState } from 'src/redux/store';
import { $spacesCache } from 'src/services/spaces/spaces';
import { bizTracker } from 'src/utils/biz-tracker';
import { getFileClass } from 'src/utils/file-type';
import { usePickBlock } from 'src/utils/pick-block';
import { TestIds, bindDataTestId } from 'src/utils/qa-utils';
import { useCloseRight } from 'src/utils/right-utils';
import { useOpenMoveTo } from './move-to';
import { useOpenCompleteDelete } from './open-complete-delete';

interface Props {
  uuid: string;
  onDelete?: () => void;
  onRestore?: () => void;
}

export const DeleteBar = memo(({ uuid, onDelete }: Props) => {
  const block = usePickBlock(uuid, ['status', 'moved']);
  const history = useHistory();
  const restorePage = useRestorePage();
  const openRestoreTip = useOpenRestoreTip();
  const openCompleteDelete = useOpenCompleteDelete(uuid);
  const closeRight = useCloseRight();
  // 按钮的展示目前还不能按可移动来判断
  const parentIsOk = useParentIsOk(uuid);
  const { role, accessFee } = usePermissions(uuid);

  // TODO需要判断当前page有没有被彻底删除
  if (!block || block.status === BlockStatus.DELETED_COMPLETE) return null;

  const handleClickDelete = () => {
    openCompleteDelete(() => {
      if (
        [
          BlockType.PAGE,
          BlockType.COLLECTION_VIEW_PAGE,
          BlockType.COLLECTION_VIEW,
          BlockType.FOLDER,
          BlockType.MIND_MAPPING,
          BlockType.MIND_MAPPING_PAGE,
        ].includes(block.type)
      ) {
        closeRight();
      }
      bizTracker.event('completely_delete', { page_source: 'fromDeleteBar' });
      onDelete?.();
    });
  };

  const handleRestore = () => {
    bizTracker.event('recycle_recover', { page_source: 'fromDeleteBar' });
    const { blocks } = getState();

    if (block.type === BlockType.FILE) {
      const parentFolder = blocks[block.parentId];
      if (
        parentFolder?.status === BlockStatus.NORMAL &&
        (parentFolder.type === BlockType.FOLDER || parentFolder.type === BlockType.PAGE)
      ) {
        history.push(`/${parentFolder.uuid}`);
        void restorePage(block.uuid);
      } else {
        openRestoreTip({ uuid: block.uuid });
      }

      return;
    }

    if (
      (block.type === BlockType.FOLDER ||
        block.type === BlockType.COLLECTION_VIEW_PAGE ||
        block.type === BlockType.COLLECTION_VIEW ||
        block.type === BlockType.MIND_MAPPING ||
        block.type === BlockType.MIND_MAPPING_PAGE) &&
      blocks[block.parentId]?.type === BlockType.COLLECTION_VIEW_PAGE
    ) {
      openRestoreTip({ uuid: block.uuid });
      return;
    }

    void restorePage(block.uuid);
  };

  let deleteStatus: ReactNode;
  if (block.moved) {
    const { movedToSpaceId } = block.moved;
    const space = $spacesCache[movedToSpaceId];
    if (space) {
      deleteStatus = <span className="text-t2">已移动到 {space.title}</span>;
    }
  } else {
    const fileType = getFileClass(block.type);
    deleteStatus = <span className="text-t2">{`当前${fileType}已删除`}</span>;
  }

  return (
    <div className="h-[60px] w-full bg-red flex-shrink-0 text-white flex items-center justify-center sticky cursor-default top-0 left-0 z-20">
      <Icon name="IcDeleteMiddle" size="middle" className="mr-2" />
      {deleteStatus}
      {accessFee !== undefined && accessFee > 0 && (
        <span className="text-t2">{'。该页面正在付费分享，删除后将影响已购买用户体验。'}</span>
      )}
      {parentIsOk && (role === PermissionRole.EDITOR || role === PermissionRole.WRITER) && (
        <span className="absolute flex items-center right-2">
          <Button
            {...bindDataTestId(TestIds.deleteBarRestore)}
            colorType="white"
            size="large"
            className="mr-2"
            onClick={handleRestore}
          >
            恢复
          </Button>
          {!block.moved && (
            <Button
              {...bindDataTestId(TestIds.deleteBarDelete)}
              colorType="white"
              onClick={handleClickDelete}
            >
              彻底删除
            </Button>
          )}
        </span>
      )}
    </div>
  );
});

export const useOpenRestoreTip = () => {
  const openModal = useOpenModal();
  const openMoveTo = useOpenMoveTo();
  const history = useHistory();

  return useCallback(
    (params: { uuid: string }) => {
      openModal.modal({
        content: ({ onCloseModal }) => {
          return (
            <div className="next-modal w-[400px] h-[288px] py-[30px] flex flex-col justify-center items-center">
              <p className="text-[70px] leading-[80px]">😔</p>
              <p className="mt-2.5 text-t1">当前页面无法恢复至原路径</p>
              <p className="text-t2 mt-2.5 text-center">
                因原路径已被彻底删除或无法移入，可尝试将该页面 <br />
                移动至指定页面
              </p>
              <Button
                onClick={() => {
                  openMoveTo({
                    uuid: params.uuid,
                    mode: 'modal',
                    callback: (pageId) => {
                      history.push(pageId);
                    },
                  });
                  onCloseModal();
                }}
                colorType="active"
                className="w-[340px] border-none text-t2 text-white mt-8 py-1.5 flex-shrink-0"
              >
                尝试移动
              </Button>
              <Button
                onClick={() => {
                  onCloseModal();
                }}
                colorType="white"
                className="w-[340px] border-none text-t2 text-white mt-2.5 py-1.5 flex-shrink-0"
              >
                知道了
              </Button>
            </div>
          );
        },
      });
    },
    [history, openModal, openMoveTo]
  );
};
