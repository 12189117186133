import en from './en.json';
import zhHans from './zh-hans.json';

export const translationResources = {
  en: {
    translation: en,
    ...en,
  },
  zhHans: {
    translation: zhHans,
    ...zhHans,
  },
};
