import type { CSSProperties, FC, ReactElement } from 'react';
import { cloneElement } from 'react';
import { Img } from 'react-image';
import colors from '@flowus/tailwind-config/colors.json';
import { generateCustomIconUrlByPath } from '..';
import { cx } from '../cx';
import type { IconData } from '@next-space/fe-api-idl';
import { darken } from '../utils/color';
import { getBlankImage } from '../hooks/file';
import { split } from 'lodash-es';

export interface AvatarType {
  name?: string;
  className?: string;
  style?: CSSProperties;
  color?: string;
  imgClassName?: string; // 这个是用于设置上传图片img标签的className
  iconSize?: number;
  icon?: IconData;
  onClick?: (e: React.MouseEvent) => void;
  isDark?: boolean;
  getImageCdnUrl: (ossName: string) => string;
  IconWidget?: ReactElement;
}
/**
 * 有几种显示类型:
 * 1.自定义上传图片的
 * 2.emoji
 * 3.默认背景+文字
 */
export const Avatar: FC<AvatarType> = (props) => {
  const {
    name = '',
    color = colors.grey3,
    icon,
    imgClassName,
    iconSize = 24,
    isDark = false,
    getImageCdnUrl,
    IconWidget,
  } = props;
  const value = split(name, '')[0];
  const isUpload = icon?.type === 'upload' && icon.value;
  const isEmoji = icon?.type === 'emoji' && icon.value;
  const isCustomIcon = icon?.type === 'icon' && icon.value;

  const loaderClassName = cx('h-full w-full rounded object-cover', imgClassName);

  return (
    <span
      className={cx(
        'text-h4 flex flex-shrink-0 select-none items-center justify-center rounded uppercase leading-none text-black2',
        props.className
      )}
      style={{
        width: iconSize,
        height: iconSize,
        ...props.style,
        backgroundColor:
          isEmoji || isUpload || isCustomIcon || !color ? '' : darken(color, isDark ? 0.15 : 0),
      }}
      onClick={props.onClick}
    >
      {!isUpload &&
        !isCustomIcon &&
        (isEmoji && IconWidget
          ? cloneElement(IconWidget, {
              iconSize,
              emojiStr: icon?.value || value,
              disableHover: true,
            })
          : value)}

      {isUpload && (
        <Img
          unloader={<img src={getBlankImage()} className={loaderClassName} />}
          src={icon.value?.startsWith('http') ? icon.value : getImageCdnUrl(icon.value ?? '')}
          className={loaderClassName}
        />
      )}
      {isCustomIcon && icon && icon.value && (
        <Img
          unloader={<img src={getBlankImage()} className={loaderClassName} />}
          src={generateCustomIconUrlByPath(isDark, icon.value)}
          className={loaderClassName}
        />
      )}
    </span>
  );
};
