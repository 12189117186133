import { formatCheckBoxValue } from '@flowus/common/block/checkbox-value';
import { fastEqual } from '@flowus/common/utils/tools';
import type {
  CollectionFilter,
  CollectionFilterGroup,
  CollectionSchema,
  CollectionViewDTO,
  UserDTO,
} from '@next-space/fe-api-idl';
import { BlockType, CollectionSchemaType, PermissionRole, TextType } from '@next-space/fe-api-idl';
import { createSelector } from 'reselect';
import { useBitable } from 'src/bitable/context';
import { buildFilterFunc } from 'src/bitable/table-view/body/filters';
import { buildCompareFunc } from 'src/bitable/table-view/body/sorters';
import { readUrlFromSegments } from 'src/bitable/table-view/cell/helpers';
import { getRelationRecords } from 'src/bitable/table-view/cell/relation/get-relation-records';
import { segmentsToText } from 'src/editor/utils/editor';
import { getState } from 'src/redux/store';
import type { NextBlock, RootState } from 'src/redux/types';
import { MemoizeByBlockVersion } from 'src/services/memoize/cache';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';
import { $currentUserCache } from 'src/services/user/current-user';
import { $searchParams } from 'src/utils';
import { judgeSharePage } from 'src/utils/getPageId';
import { stringToLowerCaseAndRemoveSpace } from 'src/utils/string-util';
import { $appUiStateCache, useCollectionSearchById } from '../../services/app/hook';
import { getViewFormat } from '../block/get-view-format';
import { getFormulaTool } from '../block/use-formula-tool';
import { getPermissions } from '../share/use-permissions';
import { useCurrentUserId } from '../user';
import { getUserName } from '../user/use-remark-name';
import { selectCollectionGroups } from './table-groups/select-collection-groups';

export const useGetSortedRecordIds = (viewId: string, deps?: any[]) => {
  // 图表也用到了这个方法，不一定是多维表用到
  // const { collectionId } = useBitable();
  const collectionId = useObservableStore(
    (s) => {
      return s.collectionViews[viewId]?.parentId;
    },
    [viewId]
  );
  const keyword = useCollectionSearchById(viewId)?.trim();
  const currentUserId = useCurrentUserId();

  return useObservableStore(
    (state) => {
      return sortedRecordIdsSelector(viewId, state, false, currentUserId, keyword);
    },
    [viewId, collectionId, currentUserId, keyword, ...(deps || [])],
    { allUser: true, waitMode: 'throttle', wait: 200, ignoreOtherData: false }
  );
};

export const sortedRecordIdsSelector = MemoizeByBlockVersion.create(
  createSelector(
    (viewId: string) => viewId,
    (_viewId: string, state: ReturnType<typeof getState>) => state,
    (_viewId: string, _state: ReturnType<typeof getState>, ignoreSearch: boolean) => ignoreSearch,
    (
      _viewId: string,
      _state: ReturnType<typeof getState>,
      ignoreSearch: boolean,
      currentUserId: string
    ) => currentUserId,
    (
      _viewId: string,
      _state: ReturnType<typeof getState>,
      ignoreSearch: boolean,
      currentUserId: string,
      keyword?: string
    ) => keyword,
    (
      viewId: string,
      state: ReturnType<typeof getState>,
      ignoreSearch: boolean,
      currentUserId: string,
      keyword?: string
    ) => {
      const { users } = state;
      const viewInfo = getViewFormat(viewId, state.blocks, state.collectionViews);
      if (!viewInfo) return [];

      const { view, collection } = viewInfo;

      if ($searchParams.dataBaseRange === 'all') {
        return collection.subNodes;
      }

      const collectionRecords = getCollectionRecords(collection.uuid, state, ignoreSearch, keyword);
      const filteredRecords = filterRecords({
        collectionId: collection.uuid,
        records: collectionRecords,
        schemas: collection.data.schema,
        userId: currentUserId,
        filter: view.format.filter,
        state,
      });
      const sortedRecords = sortRecords({
        collectionId: collection.uuid,
        records: filteredRecords,
        tableSchema: collection.data.schema ?? {},
        users,
        sorters: view.format.sorters,
        sort: view.pageSort ?? [],
        state,
      });
      return sortedRecords.map((it) => it.uuid);
    }
  ),
  (viewId, _, flag, currentUserId, keyword) => [viewId, flag, currentUserId, keyword]
);

interface Params {
  viewId: string;
  state?: RootState;
  ignoreSearch?: boolean;
  groupValue?: string;
}

export const getSortedRecordIds = ({
  viewId,
  state = getState(),
  ignoreSearch = false,
  groupValue,
}: Params) => {
  const sortedRecordIds = sortedRecordIdsSelector(
    viewId,
    state,
    ignoreSearch,
    $currentUserCache.uuid,
    $appUiStateCache.$collectionSearch[viewId]
  );
  if (!groupValue) return sortedRecordIds;

  const { blocks, users, collectionViews } = state;
  const groups = selectCollectionGroups({
    blocks,
    collectionViews,
    users,
    viewId,
    sortedRecordIds,
  });

  const group = groups?.groups?.find((group) => group.value === groupValue);
  return group?.recordIds ?? [];
};

const searchBlock = (
  schemas: Record<string, CollectionSchema>,
  block: NextBlock,
  keyword: string
): boolean => {
  const allKeywords = keyword.split(/\s+/).map((s) => stringToLowerCaseAndRemoveSpace(s));

  for (const [propertyId, schema] of Object.entries(schemas)) {
    let str = '';

    switch (schema.type) {
      case CollectionSchemaType.TEXT: {
        const segments = block.data.collectionProperties?.[propertyId];
        str = segmentsToText(segments);
        break;
      }
      case CollectionSchemaType.EMAIL:
      case CollectionSchemaType.PHONE:
      case CollectionSchemaType.URL: {
        const segments = block.data.collectionProperties?.[propertyId];
        str = readUrlFromSegments(segments);
        break;
      }

      case CollectionSchemaType.TITLE: {
        str = segmentsToText(block.data.segments);
        break;
      }

      case CollectionSchemaType.SELECT:
      case CollectionSchemaType.MULTI_SELECT: {
        const text = segmentsToText(block.data.collectionProperties?.[propertyId]).trim();
        const tags = text
          .split(/\s*,\s*/g)
          .map((item) => schema.options?.find((option) => option.value === item)?.value)
          .filter((item): item is string => !!item);

        str = tags.join('');
        break;
      }

      case CollectionSchemaType.NUMBER: {
        const value = segmentsToText(block.data.collectionProperties?.[propertyId]);
        let num = parseFloat(value);
        if (Number.isNaN(num)) {
          num = parseFloat(value.match(/[+-]?\d+(?:\.?\d+)?/)?.[0] ?? '');
        }

        if (!Number.isNaN(num)) {
          str = String(num);
        }
        break;
      }

      case CollectionSchemaType.RELATION: {
        const result = getRelationRecords(block.uuid, propertyId).relationRecords;
        const { blocks } = getState();
        const titles = result.map((uuid) => {
          const block = blocks[uuid];
          if (!block) return '';
          return segmentsToText(block.data.segments);
        });
        str = titles.join('');
        break;
      }

      case CollectionSchemaType.CHECKBOX: {
        const value = segmentsToText(block.data.collectionProperties?.[propertyId]);
        str = formatCheckBoxValue(value) ? 'yes' : 'no';
        break;
      }

      case CollectionSchemaType.FILE: {
        const segments = block.data.collectionProperties?.[propertyId] ?? [];
        const result = segments
          .filter((item) => item.type === TextType.URL && item.fileStorageType === 'internal')
          .map((segment) => segment.text);

        str = result.join('');
        break;
      }

      case CollectionSchemaType.PERSON: {
        const segments = block.data.collectionProperties?.[propertyId] ?? [];
        segments.forEach((item) => {
          if (item.type === TextType.USER && item.uuid) {
            const userName = getUserName(item.uuid);
            if (userName) {
              str += userName;
            }
          }
        });

        break;
      }

      default:
        break;
    }
    str = stringToLowerCaseAndRemoveSpace(str);
    if (allKeywords.find((keyword) => str.includes(keyword))) {
      return true;
    }
  }

  return false;
};

export const getCollectionRecords = (
  collectionId: string,
  state = getState(),
  ignoreSearch = false,
  keyword = ''
) => {
  const { blocks } = state;
  const collection = blocks[collectionId];
  if (!collection) return [];

  return collection.subNodes.reduce<NextBlock[]>((records, id) => {
    const record = blocks[id];
    if (record?.type !== BlockType.PAGE) return records;
    // 临时的多维表页面不进行展示
    if (record?.hidden) return records;

    const schemas = collection.data.schema;
    if (!ignoreSearch && keyword && schemas) {
      if (record && !searchBlock(schemas, record, keyword)) {
        return records;
      }
    }

    const { role } = getPermissions(id, state);
    if (record && (judgeSharePage() || role !== PermissionRole.NONE)) {
      records.push(record);
    }
    return records;
  }, []);
};

export const getCurrentGroupRecordIds = (
  sortedIds: string[],
  groupProperty: string,
  currentRecordId: string
) => {
  const { blocks } = getState();
  const currentGroupName =
    segmentsToText(blocks[currentRecordId]?.data.collectionProperties?.[groupProperty] ?? []).split(
      ','
    )[0] ?? '';

  return sortedIds.filter((uuid: string) => {
    const block = blocks[uuid];
    if (!block) return false;

    const segment = block.data.collectionProperties?.[groupProperty];
    const text = segmentsToText(segment).split(',')[0] ?? '';

    return currentGroupName === text;
  });
};

interface SortRecordsArguments {
  // state: RootState;
  collectionId: string;
  records: NextBlock[];
  tableSchema: Record<string, CollectionSchema> | undefined;
  users: Record<string, UserDTO>;
  sorters: CollectionViewDTO['format']['sorters'];
  sort: string[];
  state?: RootState;
}

export const sortRecords = createSelector(
  [
    ({ collectionId }: SortRecordsArguments) => collectionId,
    ({ records }: SortRecordsArguments) => records,
    ({ tableSchema }: SortRecordsArguments) => tableSchema,
    ({ users }: SortRecordsArguments) => users,
    ({ sorters }: SortRecordsArguments) =>
      (sorters ?? []).filter((it) => {
        if (it.disable) return false;
        return true;
      }),
    ({ sort }: SortRecordsArguments) => sort,
    ({ state }: SortRecordsArguments) => state,
  ],
  (collectionId, records, tableSchema, users, sorters, sort, state) => {
    if (sorters.length === 0 && sort.length === 0) return records;
    const ranking = new Map<string, number>();
    sort.forEach((recordId, index) => {
      ranking.set(recordId, index);
    });

    let nextRank = sort.length;
    for (const record of records) {
      if (!ranking.has(record.uuid)) {
        ranking.set(record.uuid, nextRank);
        nextRank += 1;
      }
    }

    const formulaTool = getFormulaTool(collectionId);
    const compareFunc = buildCompareFunc(sorters, {
      collectionId,
      schemas: tableSchema,
      ranking,
      users,
      formulaTool,
      state,
    });
    const newRecords = records.slice(0);
    newRecords.sort(compareFunc);
    return newRecords;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: fastEqual,
    },
  }
);

interface FilterRecordsArguments {
  collectionId: string;
  records: NextBlock[];
  schemas: Record<string, CollectionSchema> | undefined;
  userId: string;
  filter: CollectionFilter | CollectionFilterGroup | undefined;
  state?: RootState;
}

export const filterRecords = createSelector(
  [
    ({ records }: FilterRecordsArguments) => records,
    ({ schemas }: FilterRecordsArguments) => schemas,
    ({ userId }: FilterRecordsArguments) => userId,
    ({ filter }: FilterRecordsArguments) => filter,
    ({ collectionId }: FilterRecordsArguments) => collectionId,
    ({ state }: FilterRecordsArguments) => state,
  ],
  (inBlocks, schemas, userId, filter, collectionId, state) => {
    const filterFunc = buildFilterFunc(filter, {
      collectionId,
      schemas,
      userId,
      state,
    });
    return inBlocks.filter(filterFunc);
  },
  {
    memoizeOptions: {
      equalityCheck: fastEqual,
      resultEqualityCheck: fastEqual,
    },
  }
);
