import type { FC } from 'react';
import { useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';
import { message } from 'src/common/components/message';
import { useUpdatePDFAnnotation } from 'src/hooks/block/use-update-PDFAnnotation';
import { useTransaction } from 'src/hooks/use-transaction';
import { archiveBlock } from 'src/redux/managers/block/archive';
import { bizTracker } from 'src/utils/biz-tracker';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { dataURItoBlob } from 'src/utils/data-url-to-blob';
import { getLocationOrigin } from 'src/utils/location-utils';
import { urlFetcher } from 'src/utils/url-fetcher';
import { ColorMap } from '../buttons/color-button';
import type { PDFViewApplication } from '../service/pdf-viewer-application';
import type { PDFAnnotation } from '../type';
import { AnnotationType } from '../type';
import { findFirstPoint } from '../utils';

interface Props {
  pdfId: string;
  annotation: PDFAnnotation;
  application: PDFViewApplication;
  closeModal?: () => void;
}
export const AnnotationRightMenu: FC<Props> = ({ pdfId, annotation, application, closeModal }) => {
  const transaction = useTransaction();
  const isImageAnnotation = annotation.type !== AnnotationType.HIGHLIGHT;
  const items = [
    {
      type: ListItemType.OPERATION,
      data: {
        icon: 'IcMenuColor',
        title: '高亮颜色',
        hasArrow: true,
        renderSubMenu: () => {
          return <ColorSetting application={application} annotation={annotation} />;
        },
      },
    },
    { type: ListItemType.LINE },
    {
      type: ListItemType.OPERATION,
      isHidden: !isImageAnnotation,
      data: {
        title: '复制为图片块引用',
        onClick: () => {
          const text = `{"annotationId": "${annotation.uuid}", "type": "imageBlock"}`;
          void writeTextInClipboard(text);
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      data: {
        title: isImageAnnotation ? '复制为行内图片引用' : '复制为行内引用',
        onClick: () => {
          bizTracker.event('copy_pdf_quote', { file_id: pdfId });

          const text = `{"annotationId": "${annotation.uuid}"}`;
          void writeTextInClipboard(text);
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      data: {
        title: '复制标注内容',
        onClick: async () => {
          bizTracker.event('copy_highlight_content', { file_id: pdfId });

          if (annotation.type === AnnotationType.HIGHLIGHT) {
            void writeTextInClipboard(annotation.text ?? '');
          } else {
            if (annotation.url?.startsWith('data:')) {
              const blob = dataURItoBlob(annotation.url);
              if (!blob) return;

              const clipboardItem = new ClipboardItem({ 'image/png': blob });
              void navigator.clipboard.write([clipboardItem]);
            } else {
              if (!annotation.ossName) return;

              const imageUrl = await urlFetcher.fetchDownloadUrl({
                blockId: annotation.uuid,
                ossName: annotation.ossName,
              });

              const res = await fetch(imageUrl);
              const imageBlob = await res.blob();
              const clipboardItem = new ClipboardItem({ 'image/png': imageBlob });
              void navigator.clipboard.write([clipboardItem]);
            }
          }

          message.success('复制成功！');
          closeModal?.();
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      data: {
        title: '复制访问链接',
        onClick: async () => {
          bizTracker.event('copy_highlight_link', { file_id: pdfId });

          const firstPoint = findFirstPoint(annotation);
          if (!firstPoint) return;

          void writeTextInClipboard(
            `${getLocationOrigin()}/preview/${pdfId}?annotationPos=${annotation.pageNumber},${
              firstPoint.point?.x
            },${firstPoint.point?.y}`
          );
          closeModal?.();
        },
      },
    },
    { type: ListItemType.LINE },

    {
      type: ListItemType.OPERATION,
      data: {
        title: '清除高亮',
        onClick: () => {
          transaction(() => {
            archiveBlock(annotation.uuid);
            application.deleteAnnotation(annotation);
          });
          closeModal?.();
        },
      },
    },
  ];

  const handleClick = () => {};

  return (
    <ListView className="next-modal w-[210px] py-[5px]" items={items} onItemClick={handleClick} />
  );
};

interface ColorProps {
  annotation: PDFAnnotation;
  application: PDFViewApplication;
}

export const ColorSetting: FC<ColorProps> = ({ annotation, application }) => {
  const transaction = useTransaction();
  const updatePDFAnnotation = useUpdatePDFAnnotation();

  const [color, setColor] = useState(annotation.color);

  const items = ColorMap.map((item) => {
    return {
      type: ListItemType.OPERATION,
      data: {
        title: item.name,
        value: item.value,
        icon: (
          <span
            className="rounded-full w-[18px] h-[18px]"
            style={{ backgroundColor: item.value }}
          />
        ),
        arrow: <Icon name="IcSelectedSpace" size="normal" />,
        hasArrow: color === item.value,
      },
    };
  });

  const handleClick = ({ data }: ListItem<any>) => {
    setColor(data.value);

    application.updateAnnotation({ ...annotation, color: data.value });
    transaction(() => {
      updatePDFAnnotation(annotation.uuid, { color: data.value as string });
    });
  };

  return (
    <ListView className="next-modal w-[160px] py-[5px]" items={items} onItemClick={handleClick} />
  );
};
