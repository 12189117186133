import { cx } from '@flowus/common/cx';
import dayjs from 'dayjs';
import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';
import { Button } from 'src/common/components/button';
import { DATE_FORMAT } from 'src/common/const';
import { useModel } from 'src/common/create-model';
import { useCurrentSpace } from 'src/hooks/space';
import { SpacePlanType } from 'src/redux/types';
import { useLimitConfig } from 'src/services/app/hook/subscription-data';
import { useCurrentSpacePlanInfo } from 'src/services/capacity/space-plans';
import { getSpacePlanTypeName } from 'src/utils/block-utils';
import { SettingModalCommon, SettingProvider } from '../common';
import { Option } from '../option';
import { OpenSettingFrom, SettingMenuType } from '../type';
import { useOpenUpgradePlan } from '../use-open-upgrade-plan';

const Tag: FC<{
  planType?: string;
  color?: 'purple' | 'green' | 'black' | 'pink';
  right?: ReactNode;
}> = ({ planType, color, right }) => (
  <div className="flex items-center">
    <div
      className={cx(
        'text-t3-medium py-px px-1.5 mr-2 rounded text-white',
        !color && 'text-grey3-base bg-[#A2A4A633]',
        color === 'green' && 'bg-green',
        color === 'purple' && 'bg-purple',
        color === 'pink' && 'bg-pink_purple',
        color === 'black' && ' bg-[#3D3D3D]'
      )}
    >
      {getSpacePlanTypeName(planType)}
    </div>
    <div className="text-t3 text-grey3">{right}</div>
  </div>
);

export const SpaceInfoPlan: FC = () => {
  const { spacePlan } = useCurrentSpacePlanInfo();
  const { setCurrentSettingMenu, setIsFrom } = useModel(SettingProvider);
  const currentSpace = useCurrentSpace();
  const openUpgradePlan = useOpenUpgradePlan();
  // const { isNovice } = useSpaceCapacity();
  // const openNewUserTask = useOpenNewUserTask();
  const maxBlockLimit = useLimitConfig();

  const [leftChild, rightChild] = useMemo(() => {
    const dayFc = () => {
      const days = dayjs(spacePlan?.endAt).diff(dayjs(), 'day');
      return (
        <>
          有效期至 {dayjs(spacePlan?.endAt).format(DATE_FORMAT)}，
          <span className={days <= 5 ? 'text-red' : ''}>剩余 {days} 天</span>
        </>
      );
    };

    // 新手，个人
    // if (isNovice) {
    //   return [
    //     <Tag planType={currentSpace.planType} right="新手期，做任务解锁2G容量" />,
    //     <Button className="text-active_color" onClick={openNewUserTask}>
    //       解锁免费扩容
    //     </Button>,
    //   ];
    // }

    // 免费个人
    if (currentSpace.planType === 'freePersonal') {
      return [
        <Tag planType={currentSpace.planType} />,
        <Button
          onClick={() => {
            setCurrentSettingMenu(SettingMenuType.upgrade);
            setIsFrom(OpenSettingFrom.dashboard_plan);
          }}
        >
          升级使用更多功能
        </Button>,
      ];
    }

    // 个人专业版
    if (currentSpace.planType === 'personal') {
      return [
        <Tag
          color="green"
          planType={currentSpace.planType}
          right={
            <>
              {dayFc()}
              <span
                hidden={__PRIVATE__}
                className="text-active_color ml-2 animate-click"
                onClick={() =>
                  openUpgradePlan(SpacePlanType.personal, OpenSettingFrom.dashboard_plan)
                }
              >
                立即续费
              </span>
            </>
          }
        />,
      ];
    }

    // 免费小组
    if (currentSpace.planType === 'freeTeam') {
      return [
        <Tag
          planType={currentSpace.planType}
          color="pink"
          right={`块数限制 ${maxBlockLimit.blocksMaxLimit}，免费块剩余 ${maxBlockLimit.blocksStock} 个`}
        />,
        <Button
          onClick={() => {
            setCurrentSettingMenu(SettingMenuType.upgrade);
            setIsFrom(OpenSettingFrom.dashboard_plan);
          }}
        >
          升级使用更多功能
        </Button>,
      ];
    }

    // 小组版
    if (currentSpace.planType === SpacePlanType.smallTeam) {
      return [
        <Tag
          color="purple"
          planType={currentSpace.planType}
          right={
            <>
              {dayFc()}
              <span
                className="text-active_color ml-2 animate-click"
                onClick={() =>
                  openUpgradePlan(SpacePlanType.smallTeam, OpenSettingFrom.dashboard_plan)
                }
              >
                立即续费
              </span>
            </>
          }
        />,
      ];
    }

    // 团队版
    if (currentSpace.planType === SpacePlanType.team) {
      return [
        <Tag
          color="black"
          planType={currentSpace.planType}
          right={
            <>
              {dayFc()}
              <span
                hidden={__PRIVATE__}
                className="text-active_color ml-2 animate-click"
                onClick={() => openUpgradePlan(SpacePlanType.team, OpenSettingFrom.dashboard_plan)}
              >
                立即续费
              </span>
            </>
          }
        />,
      ];
    }

    return [];
  }, [
    currentSpace.planType,
    spacePlan?.endAt,
    setCurrentSettingMenu,
    setIsFrom,
    openUpgradePlan,
    maxBlockLimit.blocksMaxLimit,
    maxBlockLimit.blocksStock,
  ]);

  return (
    <>
      <Option title="空间计划" />
      <SettingModalCommon.Item leftChild={leftChild} rightChild={rightChild} />
    </>
  );
};
