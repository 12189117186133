import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import React from 'react';
import type { IconName } from '../../icon';
import { Icon } from '../../icon';
import { Tooltip } from '../../tooltip';
import { listViewItemDefaultClassName } from '../helper';
import type { ElementItemProps } from '../types';

interface OperationDataScheme {
  title: string;
  context: string;
  icon?: IconName;
  hasArrow?: boolean;
  selected?: boolean;
  image?: string;
  onClick?: (e: React.MouseEvent) => void;
}
/**
 * block item
 */
export const BlockTransform: FC<ElementItemProps<OperationDataScheme>> = (props) => {
  const dataScheme = props.listItem.data;
  if (!dataScheme) return null;
  return (
    <div
      {...props.attribute}
      onPointerDown={(e) => {
        e.preventDefault();
      }}
      onClick={(e) => {
        dataScheme.onClick?.(e);
        props.onItemClick?.(props.listItem, props.index, e);
      }}
      className={cx(
        listViewItemDefaultClassName,
        ' rounded cursor-pointer active-bg animate-hover',
        {
          'normal-bg': props.selected,
        }
      )}
    >
      <Tooltip
        className={'flex items-center justify-between h-full'}
        placement="right"
        theme="none"
        offset={[0, 16]}
        popup={dataScheme.context}
      >
        <div className="flex items-center">
          {dataScheme.icon ? (
            <Icon name={dataScheme.icon} size="xlarge" className="mr-2" />
          ) : (
            dataScheme.image && (
              <img
                className="flex-shrink-0 object-fill w-[26px] h-[26px] rounded border-grey4 mr-2"
                src={dataScheme.image}
              />
            )
          )}
          <div className="flex flex-col">
            <span className="text-black text-t2">{dataScheme.title}</span>
          </div>
        </div>
        {dataScheme.selected && <Icon name="IcSelectedSpace" size="middle" />}
      </Tooltip>
    </div>
  );
};
