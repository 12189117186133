import { cx } from '@flowus/common/cx';
import { CollectionViewType } from '@next-space/fe-api-idl';
import type { FC, MutableRefObject } from 'react';
import { useEffect, useRef } from 'react';
import { Divider } from 'src/common/components/divider';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { useOpenModal } from 'src/common/components/next-modal';
import { useBlock } from 'src/hooks/block/use-block';
import { useCopyCollectionView } from 'src/hooks/block/use-copy-collection-view';
import { useRemoveCollectionView } from 'src/hooks/block/use-remove-collection-view';
import { updateCollectionView } from 'src/hooks/block/use-update-collection-view';
import type { NextCollectionView } from 'src/redux/types';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { bizTracker } from 'src/utils/biz-tracker';
import { useBitable } from '../../context';
import { ViewIconMap, ViewNameMap } from '../const';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { getLocationOrigin } from 'src/utils/location-utils';
import { ViewTypeList } from '../view-setting/view-type-list';

interface Props {
  viewId: string;
  readonly?: boolean;
  dragOverlay?: boolean;
  onClose?(): void;
}

export const ViewItem: FC<Props> = (props) => {
  const { changeView, managerReadonly, viewId: activeViewId, isLocked } = useBitable();
  const { viewId, onClose, dragOverlay } = props;
  const view = useObservableStore(({ collectionViews }) => collectionViews[viewId], [viewId]);
  const openModal = useOpenModal();

  const viewTypeRef = useRef(view?.type);
  useEffect(() => {
    viewTypeRef.current = view?.type;
  }, [view?.type]);

  const viewTitleRef = useRef(view?.title ?? '');
  useEffect(() => {
    viewTitleRef.current = view?.title ?? '';
  }, [view?.title]);

  const showMorePanel = (popcorn: HTMLElement, view: NextCollectionView) => {
    openModal.dropdown({
      popcorn,
      placement: 'right',
      closeBeforeCallBack() {
        const newTitle = viewTitleRef.current;

        if (newTitle !== view.title) {
          updateCollectionView(viewId, { title: newTitle });
        }
      },
      content({ onCloseModal }) {
        return (
          <ViewsItemManager
            viewId={viewId}
            onCloseModal={onCloseModal}
            // onClose={onClose}
            viewTitleRef={viewTitleRef}
          />
        );
      },
    });
  };

  if (!view) return null;

  return (
    <div
      className={cx(
        'px-2 h-8 text-t2 cursor-pointer animate-hover flex items-center justify-between',
        dragOverlay && 'cursor-[grabbing]'
      )}
      onClick={() => {
        changeView(viewId);
        onClose?.();
      }}
    >
      <div className="flex overflow-hidden pr-1">
        <div className="flex flex-shrink-0 justify-center items-center w-5 h-5 mr-1 cursor-[grab]">
          <Icon name="IcBlockHandle" size="normal" className="text-grey4" />
        </div>
        <div className="flex items-center">
          <Icon name={ViewIconMap[view.type]} size="middle" />
          <span
            className={cx('flex-1 overflow-hidden whitespace-nowrap overflow-ellipsis ml-1', {
              'text-t2-bold': view.uuid === activeViewId,
            })}
          >
            {view.title || ViewNameMap[view.type]}
          </span>
        </div>
      </div>
      {!managerReadonly && !isLocked && (
        <button
          className="w-5 h-5 flex-shrink-0 animate-hover rounded-sm flex items-center justify-center"
          onClick={(event) => {
            event.stopPropagation();
            showMorePanel(event.currentTarget, view);
          }}
        >
          <Icon name="IcMore" size="normal" className="text-grey3" />
        </button>
      )}
    </div>
  );
};

export const ViewsItemManager: FC<{
  viewId: string;
  onCloseModal: () => void;
  onClose?: () => void;
  viewTitleRef: MutableRefObject<string | undefined>;
}> = ({ viewId, onCloseModal, onClose, viewTitleRef }) => {
  const dupOnCloseModal = onCloseModal;
  const { changeView, embed, collectionId, viewParentId, isLocked } = useBitable();
  const view = useObservableStore(({ collectionViews }) => collectionViews[viewId], [viewId]);
  const openModal = useOpenModal();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const viewType = view?.type ?? CollectionViewType.TABLE;
  const removeCollectionView = useRemoveCollectionView();
  const copyCollectionView = useCopyCollectionView();
  const { views = [] } = useBlock(viewParentId) ?? {};

  const showTypeList = (event: React.MouseEvent) => {
    if (isLocked) return;
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom-end',
      content: ({ onCloseModal }) => (
        <ViewTypeList
          viewId={viewId}
          onChange={() => {
            onCloseModal();
            dupOnCloseModal();
            onClose?.();
          }}
        />
      ),
    });
  };
  if (!view) return null;

  return (
    <div className="w-[240px] space-y-1 next-modal p-2" onClick={() => inputRef.current?.focus()}>
      <Input
        autoFocus
        inputRef={inputRef}
        placeholder="请输入名称"
        className="h-8 flex-1 mb-2"
        defaultValue={view.title}
        onEnter={onCloseModal}
        onChange={(text) => (viewTitleRef.current = text)}
      />

      {/* <div className="w-full py-2 text-t2 text-grey3 mb-[4px]">视图类型</div> */}
      <div
        className="w-full text-t2 py-1 text-grey3 mb-1 px-2 flex justify-between animate-hover cursor-pointer"
        onClick={showTypeList}
      >
        <p className="text-black">视图设置</p>
        <button className="text-t2 text-grey3 flex items-center">
          {ViewNameMap[view.type]}
          <Icon name="IcArrow" className="ml-2" size="xxxsmall" />
        </button>
      </div>

      {/* {(isLinkTable
        ? TableViewMap.filter((i) => i.type !== CollectionViewType.FORM)
        : TableViewMap
      ).map((view) => (
        <div
          key={view.type}
          className="flex justify-between items-center animate-hover px-1"
          onClick={() => {
            setViewType(view.type);
            viewTypeRef.current = view.type;
          }}
        >
          <div className="flex items-center h-8">
            <Icon name={ViewIconMap[view.type]} size="middle" />
            <span className="text-t2 ml-1">{view.name}</span>
          </div>
          <span className="flex items-center">
            {viewType === view.type && <Icon name="IcSelectedSpace" size="small" />}
            <HelperIcon popup="查看视图帮助" link={view.helperLink} />
          </span>
        </div>
      ))} */}

      <Divider className="my-2" />

      {viewType !== CollectionViewType.FORM && (
        <button
          className="h-8 px-1 flex w-full items-center animate-hover rounded"
          onClick={() => {
            void writeTextInClipboard(`${getLocationOrigin()}/${collectionId}#${viewId}`);
            onCloseModal();
            onClose?.();
          }}
        >
          <Icon className="mr-1" size="middle" name="IcUrl" />
          <span className="text-t2">复制引用视图链接</span>
        </button>
      )}
      <button
        className="h-8 px-1 flex w-full items-center animate-hover rounded"
        onClick={() => {
          bizTracker.event('bitable_view_create', {
            from_scene: embed ? 'inline' : 'inthepage',
            view_type: viewType,
          });

          const newViewId = copyCollectionView(viewId);
          if (newViewId) {
            changeView(newViewId);
          }

          onCloseModal();
          onClose?.();
        }}
      >
        <Icon className="mr-1" size="middle" name="IcMenuDuplicate" />
        <span className="text-t2">拷贝视图</span>
      </button>

      {views.length > 1 && (
        <button
          className="h-8 px-1 flex w-full items-center animate-hover rounded"
          onClick={() => {
            openModal.warning({
              title: <>是否确认删除该视图？</>,
              confirm: () => {
                removeCollectionView(viewId);
                onCloseModal();
                onClose?.();
              },
            });
          }}
        >
          <Icon className="mr-1" size="middle" name="IcMenuDelete" />
          <span className="text-t2">删除视图</span>
        </button>
      )}
    </div>
  );
};
