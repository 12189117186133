import { base64ToBlob } from '@flowus/common/url';
import dayjs from 'dayjs';
import type { FC, MouseEvent } from 'react';
import type { OverlayRenderProps } from 'react-photo-view-kcsx/dist/types';
import { Icon } from 'src/common/components/icon';
import { message } from 'src/common/components/message';
import { Tooltip } from 'src/common/components/tooltip';
import { downloadUrlFile } from 'src/common/utils/download-utils';
import { isDeleted } from 'src/hooks/block/use-block-status';
import { useFileDownLimit } from 'src/hooks/limit/file-down-limit';
import { usePickBlock } from 'src/utils/pick-block';
import { usePageScene } from 'src/views/main/scene-context';
import { DeleteBar } from '../delete-bar';
import { PreviewHeader } from '../file-preview/header';
import { IconUiSizeBox } from '../icon-ui-size-box';
import { usePermissions } from 'src/hooks/share/use-permissions';

interface Props {
  overlayProps: OverlayRenderProps;
  previewId?: string;
  hiddenImageToolbar?: boolean;
}

export const ImagesOverlayRender: FC<Props> = (props) => {
  const { overlayProps, previewId, hiddenImageToolbar } = props;
  const pageScene = usePageScene();
  const current = overlayProps.images[overlayProps.index];
  const showSwitchBtn = overlayProps.images.length > 1;
  const block = usePickBlock(previewId, ['status']);
  const { allowDownload } = usePermissions(previewId);
  const checkDownLoad = !!current?.src?.startsWith('data:') && allowDownload;
  const fileDownLimit = useFileDownLimit();

  const pre = () => {
    if (overlayProps.index === 0) {
      message.warning('当前为第一张');
      return;
    }
    overlayProps.onIndexChange(overlayProps.index - 1);
  };

  const next = () => {
    if (overlayProps.index + 1 === overlayProps.images.length) {
      message.warning('当前为最后一张');
      return;
    }
    overlayProps.onIndexChange(overlayProps.index + 1);
  };

  const zoom = (op: 'in' | 'out', event: MouseEvent) => {
    const num = event.metaKey || event.ctrlKey ? 0.1 : 0.5;
    if (op === 'in') {
      overlayProps.onScale(overlayProps.scale - num);
    } else {
      overlayProps.onScale(overlayProps.scale + num);
    }
  };

  const rotate = (event: MouseEvent) => {
    if (event.metaKey || event.ctrlKey) {
      overlayProps.onRotate(overlayProps.rotate + 45);
    } else {
      overlayProps.onRotate(overlayProps.rotate + 90);
    }
  };

  const handleDownloadFile = async () => {
    if (!current?.src) return;
    await fileDownLimit();
    const blob = base64ToBlob(current.src);
    const url = URL.createObjectURL(blob);
    void downloadUrlFile(url, dayjs().format('YYYY-MM-DD-HH-mm-ss'));
  };

  return (
    <>
      {block ? (
        <PreviewHeader
          onClose={overlayProps.onClose}
          uuid={block.uuid}
          downloadUrl={`${current?.src}`}
          pageScene={pageScene}
        />
      ) : (
        <div
          className="fixed top-4 right-4 z-20 animate-click text-grey3"
          onClick={overlayProps.onClose}
        >
          关闭
        </div>
      )}
      {previewId && isDeleted(previewId) && (
        <div className="absolute z-50 w-full top-[55px] h-14">
          <DeleteBar uuid={previewId} onDelete={overlayProps.onClose} />
        </div>
      )}
      <>
        {showSwitchBtn && (
          <>
            <div className="fixed z-10 left-0 h-full flex items-center">
              <Tooltip popup="上一张">
                <Icon name={'MIcBtnPre'} size="xxxlarge" onClick={pre} />
              </Tooltip>
            </div>
            <div className="fixed z-10 right-0 h-full flex items-center">
              <Tooltip popup="下一张">
                <Icon name={'MIcBtnNext'} size="xxxlarge" onClick={next} />
              </Tooltip>
            </div>
          </>
        )}
        <div className="fixed z-10 flex items-center px-1 py-1 -translate-x-1/2 bg-black-base/70 left-1/2 bottom-5 rounded backdrop-blur-sm">
          {!hiddenImageToolbar && (
            <>
              <IconUiSizeBox
                onClick={(e) => zoom('in', e)}
                size={40}
                className="mr-1 animate-hover hover:bg-white1/10 dark:hover:bg-grey6"
              >
                <Icon name="MIcZoomIn" size="large" />
              </IconUiSizeBox>
              <IconUiSizeBox
                size={40}
                onClick={(e) => zoom('out', e)}
                className="mr-1 animate-hover hover:bg-white1/10 dark:hover:bg-grey6"
              >
                <Icon name="MIcZoomOut" size="large" />
              </IconUiSizeBox>
              <IconUiSizeBox
                size={40}
                onClick={rotate}
                className="mr-1 text-grey3-base animate-hover hover:bg-white1/10 dark:hover:bg-grey6"
              >
                <Icon name="IcMenuRelink" size="large" className="-scale-x-100" />
              </IconUiSizeBox>
              {checkDownLoad && (
                <IconUiSizeBox
                  size={40}
                  onClick={handleDownloadFile}
                  className="mr-1 text-grey3-base animate-hover hover:bg-white1/10 dark:hover:bg-grey6"
                >
                  <Icon name="IcMenuDownload" size="large" className="-scale-x-100" />
                </IconUiSizeBox>
              )}
            </>
          )}
          <IconUiSizeBox
            size={40}
            onClick={overlayProps.onClose}
            className="text-grey3-base animate-hover hover:bg-white1/10 dark:hover:bg-grey6"
          >
            <Icon name="IcBannerClose" size="xxlarge" />
          </IconUiSizeBox>
        </div>
      </>
    </>
  );
};
