import { debounce, escape } from 'lodash-es';
import { loginInjector } from './init';
import { isDebugUrl } from './is-debug';

export enum WeappPagePath {
  webview = `/pages/webview/index`,
  index = `/pages/index/index`,
  share = `/pages/middle/share`,
  pay = `/pages/pay/index`,
}

export enum WeappSearchParamsKey {
  title = 't',
  code = 'code',
  type = 'type',
  payAI = 'pai',
  spaceId = 'sid',
  subscribePage = 'sp',
  planId = 'pid',
  pageId = 'pageId',
}

export const getWx = () => (window as any).wx;

export const wxPostMessage = (params = {}) => {
  getWx()?.miniProgram.postMessage(params);
};

export const wxPostPageTitle = (
  pageTitle = document.title,
  href = location.href,
  time = Date.now()
) => {
  wxPostMessage({ data: { pageTitle, href, time, postMessageType: 'title' } });
};

export const wxNavigateTo = (params: { path: WeappPagePath; search?: string }) => {
  const { path } = params;
  let { search = '' } = params;
  if (search && !search.startsWith('?')) {
    search = `?${search}`;
  }

  getWx().miniProgram.navigateTo({ url: `${path}${search}` });
};

export interface WeAppPayParams {
  type:
    | WeappSearchParamsKey.spaceId
    | WeappSearchParamsKey.subscribePage
    | WeappSearchParamsKey.payAI;
  pageId?: string;
  spaceId?: string;
  code?: string;
  planId?: string;
}

export const weAppPay = (params: WeAppPayParams) => {
  const search = [`${WeappSearchParamsKey.type}=${params.type}`];

  if (params.type === WeappSearchParamsKey.subscribePage) {
    search.push(`${WeappSearchParamsKey.pageId}=${params.pageId}`);
  }

  if ([WeappSearchParamsKey.payAI, WeappSearchParamsKey.spaceId].includes(params.type)) {
    search.push(`${WeappSearchParamsKey.planId}=${params.planId}`);
    search.push(`${WeappSearchParamsKey.spaceId}=${params.spaceId}`);
  }

  if (params.code) {
    search.push(`${WeappSearchParamsKey.code}=${params.code}`);
  }

  wxNavigateTo({
    path: WeappPagePath.pay,
    search: search.join('&'),
  });
};

export const wxToWeappSharePage = (params?: { code?: string }) => {
  const { code } = params || {};

  const paths: string[] = [];
  paths.push(`url=${location.origin}${location.pathname}`);
  paths.push(
    `${WeappSearchParamsKey.title}=${decodeURIComponent(
      escape(document.title.replace(/&|\s/g, '').substring(0, 10))
    )}`
  );
  if (code) {
    paths.push(`${WeappSearchParamsKey.code}=${code}`);
  }

  wxNavigateTo({
    path: WeappPagePath.share,
    search: paths.join('&'),
  });
};

export const previewWxImage = (url?: string) => {
  if (!url) return;
  getWx()?.previewImage({
    current: url,
    urls: [url],
  });
};

export const onMenuShareTimelineImage = async (params?: { imgUrl?: string; desc?: string }) => {
  // @ts-ignore link
  const link = params?.imgUrl;
  const wx = getWx();
  if (!wx) return;
  const _res = await loginInjector.request.infra.getWechatSign.raw();
  if (_res.code !== 200) return;
  const res = _res.data;
  wx.config({
    debug: isDebugUrl(), // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    appId: import.meta.env.VITE_WECHAT_ACCOUNT, // 必填，公众号的唯一标识
    timestamp: res.timestamp, // 必填，生成签名的时间戳
    nonceStr: res.nonceStr, // 必填，生成签名的随机串
    signature: res.signature, // 必填，签名，见附录1
    jsApiList: ['checkJsApi', 'updateAppMessageShareData', 'updateTimelineShareData'], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
  });

  if (link) {
    const obj = {
      title: document.title, // 分享标题
      desc: params?.desc, // 分享描述
      link: location.href, // 分享链接，该链接域名必须与当前企业的可信域名一致
      imgUrl: link,
    };
    wx.ready(() => {
      wx.updateAppMessageShareData(obj);
      wx.updateTimelineShareData(obj);

      // // 2.1 获取“转发”按钮点击状态及自定义分享内容接口
      // wx.onMenuShareAppMessage(obj);
      // // 2.2 获取“微信”按钮点击状态及自定义分享内容接口
      // wx.onMenuShareWechat(obj);
      // // 2.3 获取“分享到朋友圈”按钮点击状态及自定义分享内容接口
      // wx.onMenuShareTimeline(obj);
      // // 2.4 监听“分享到微博”按钮点击、自定义分享内容及分享结果接口
      // wx.onMenuShareWeibo(obj);
      // // 2.5 监听“分享到QZone”按钮点击、自定义分享内容及分享接口
      // wx.onMenuShareQZone(obj);
    });
  }
};

export const onMenuShareTimelineImageDebounce = debounce(onMenuShareTimelineImage, 3000, {
  leading: false,
  trailing: false,
});
