import { cx } from '@flowus/common/cx';
import { BlockType, CollectionViewType } from '@next-space/fe-api-idl';
import type { ChangeEvent, FC, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/common/components/button';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { listViewNormalClassName } from 'src/common/components/list-view/helper';
import { useCloseModal, useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { makeInitialCollection } from 'src/editor/component/menu-list';
import { ActivityIds } from 'src/hooks/activities/activity-ids';
import { ActivitiesListType } from 'src/hooks/activities/use-activity';
import { useUpdateTask } from 'src/hooks/activities/use-update-task';
import { useMultipleDownload } from 'src/hooks/drive/use-multiple-download';
import { useCreateMindMap } from 'src/hooks/mind-map/use-create-mind-map';
import { useCreateDocPage, useReadonly } from 'src/hooks/page';
import { useSelectedBlockHistory } from 'src/hooks/page/use-selected-block-history';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { useUploadDriveFiles } from 'src/hooks/space/use-upload';
import { useTransaction } from 'src/hooks/use-transaction';
import { Modals } from 'src/modals';
import { archiveBlock } from 'src/redux/managers/block/archive';
import { cache } from 'src/redux/store';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';
import { $currentUserCache } from 'src/services/user/current-user';
import { bizTracker } from 'src/utils/biz-tracker';
import { judgeSharePage } from 'src/utils/getPageId';
import { bindDataTestId, TestIds } from 'src/utils/qa-utils';
import { selectedBlocksToIds } from 'src/utils/select-block-util';
import { PageHeader } from '../../page-doc/page-header';
import { ListViewTypeIcon } from './common';
import { getSelectBlockHasNoFile } from './hook';
import { useDrive } from './use-drive';

interface Props {
  uuid: string;
}
export const PageDriveHeader: FC<Props> = ({ uuid }) => {
  const readonly = useReadonly(uuid);
  const { allowDownload } = usePermissions(uuid);
  const hiddenDownload = getSelectBlockHasNoFile();
  const showOptionBar = (allowDownload && judgeSharePage()) || !readonly;
  const history = useHistory();
  const createPage = useCreateDocPage();
  const openModal = useOpenModal();
  const uploadDrive = useUploadDriveFiles();
  const closeModal = useCloseModal();
  const selectBlockId = useObservableStore((state) => state.ui.selectedBlocks[0]?.blockId, [], {
    obsSelectBlocks: [{ all: true }],
  });
  const updateTask = useUpdateTask();
  const createMindMap = useCreateMindMap();

  const uploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList?.length) {
      void uploadDrive({
        ownerPageId: uuid,
        files: Array.from(fileList),
        type: 'file',
        spaceId: getCurrentSpaceId(),
        userId: $currentUserCache.uuid,
        onComplete: () => {},
        inTransferList: true,
      });
      event.target.value = '';

      // 积分任务
      void updateTask(ActivityIds.CREATE_FOLDER_AND_UPLOAD, ActivitiesListType.basicList, {
        step2: true,
      });
    }
    closeModal(Modals.DRIVE_UPLOAD_MENU);
  };

  const uploadFolder = async (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    const files: File[] = [];
    for (const file of fileList) {
      files.push(file);
    }
    void uploadDrive({
      inTransferList: true,
      ownerPageId: uuid,
      files,
      type: 'file',
      spaceId: getCurrentSpaceId(),
      userId: $currentUserCache.uuid,
      onComplete: () => {},
    });

    // void uploadDrive(uuid, files);
    closeModal(Modals.DRIVE_UPLOAD_MENU);
  };

  const createFolder = () => {
    const id = createPage(BlockType.FOLDER, { parentId: uuid, last: true });
    if (!id) return;
    history.push(`/${id}`);
  };

  const openCreatePage = (event: MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom',
      content: (_) => (
        <ListView
          className={listViewNormalClassName}
          onItemClick={(item) => {
            _.onCloseModal();
            switch (item.data.type) {
              case BlockType.PAGE: {
                const newId = createPage(BlockType.PAGE, { parentId: uuid, last: true });
                history.push(`/${newId}`);
                break;
              }
              case BlockType.MIND_MAPPING_PAGE: {
                createMindMap({ parentId: uuid, openType: 'push' });
                break;
              }
              case BlockType.COLLECTION_VIEW_PAGE: {
                const newBlockId = createPage(BlockType.COLLECTION_VIEW_PAGE, {
                  parentId: uuid,
                  last: true,
                });
                if (newBlockId) {
                  makeInitialCollection(newBlockId, CollectionViewType.TABLE);
                }
                history.push(`/${newBlockId}`);

                break;
              }
              default:
            }
          }}
          items={[
            {
              type: ListItemType.OPERATION,
              data: {
                type: BlockType.PAGE,
                title: '新建页面',
                icon: 'IcPageMiddle',
              },
            },
            {
              type: ListItemType.OPERATION,
              data: {
                type: BlockType.MIND_MAPPING_PAGE,
                title: '新建思维导图页面',
                icon: 'IcMindmapMiddle',
              },
            },
            {
              type: ListItemType.OPERATION,
              data: {
                type: BlockType.COLLECTION_VIEW_PAGE,
                title: '新建多维表页面',
                icon: 'IcTableMiddle',
              },
            },
          ]}
        />
      ),
    });
  };

  const items = [
    {
      type: ListItemType.CUSTOM_RENDER,
      render: () => (
        <label className="flex items-center h-10 px-1.5 animate-hover cursor-pointer">
          <Icon name="IcUploadDocMenu" size="middle" className="mr-2" />
          上传文件
          <input multiple type="file" className="hidden" onChange={uploadFile} />
        </label>
      ),
    },
    {
      type: ListItemType.CUSTOM_RENDER,
      render: () => (
        <label className="flex items-center h-10 px-1.5 animate-hover cursor-pointer">
          <Icon name="MIcUploadFolderMenu" size="middle" className="mr-2" />
          上传文件夹
          <input
            // @ts-ignore 非标准属性但浏览器基本都支持
            webkitdirectory=""
            mozdirectory=""
            multiple
            type="file"
            className="hidden"
            onChange={uploadFolder}
          />
        </label>
      ),
    },
  ];

  const openUploadDropdown = (event: MouseEvent<HTMLButtonElement>) => {
    bizTracker.event('content_add');
    openModal.dropdown({
      popcorn: event.currentTarget,
      modalId: Modals.DRIVE_UPLOAD_MENU,
      offset: [0, 10],
      content: () => (
        <ListView className="w-[200px] py-1.5 px-2 next-modal text-t2" items={items} />
      ),
    });
  };

  return (
    <div className="relative text-h0 sm:text-h1 sm:flex-col text-black px-24 sm:px-10">
      <PageHeader className="flex flex-col" uuid={uuid} />
      <div
        className={cx(
          'flex items-center justify-between py-1.5 sm:mt-2 mt-5',
          !showOptionBar && 'hidden pl-0'
        )}
      >
        <ListViewTypeIcon uuid={uuid} />
        <div className="flex items-center h-8">
          {selectBlockId && (
            <MultipleSelectFileButton
              uuid={selectBlockId}
              canDownload={showOptionBar && !hiddenDownload}
              readonly={readonly}
            />
          )}
          {!readonly && (
            <>
              <div
                className="whitespace-nowrap flex items-center text-t2 text-grey3 animate-hover px-2 h-8"
                onClick={createFolder}
              >
                <Icon name="IcAddSmallBold" className="mr-1" size="small" />
                <span>新建文件夹</span>
              </div>
              <div
                className="whitespace-nowrap flex items-center ml-2 text-t2 text-grey3 animate-hover px-2 h-8"
                onClick={openCreatePage}
              >
                <Icon name="IcAddSmallBold" className="mr-1" size="small" />
                <span>新建页面</span>
              </div>
              <Button
                className="text-t2-medium ml-5"
                colorType="active"
                onClick={openUploadDropdown}
                {...bindDataTestId(TestIds.driveHeaderUpload)}
              >
                <Icon name="IcBtnUpload" className="mr-1" size="middle" />
                上传
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

// #region 多选后出现的菜单
const MultipleSelectFileButton: FC<{ uuid: string; canDownload: boolean; readonly: boolean }> = ({
  uuid,
  canDownload,
  readonly,
}) => {
  const { openMenuList } = useDrive({ uuid, formHeaderMenu: true });
  const multipleDownload = useMultipleDownload();
  const transaction = useTransaction();
  const { selectedBlockHistory } = useSelectedBlockHistory();

  const onDownload = () => {
    void multipleDownload(cache.ui.selectedBlocks.map((i) => i.blockId));
  };

  const onDelete = () => {
    transaction(() => {
      archiveBlock(selectedBlocksToIds(cache.ui.selectedBlocks));
      selectedBlockHistory([]);
    });
  };
  const isShare = judgeSharePage();
  return (
    <div className="flex items-center text-t2 text-grey3" data-no-cancel-selected>
      {canDownload && (
        <Tooltip
          popup={isShare ? '批量下载' : '下载'}
          className={cx('flex whitespace-nowrap items-center px-2 h-8 animate-hover', {
            'mr-2.5': !isShare,
          })}
          onClick={onDownload}
        >
          <Icon name="IcMenuDownload" className="mr-1" size="middle" />
          {isShare ? '批量下载' : '下载'}
        </Tooltip>
      )}
      {!readonly && (
        <>
          <Tooltip
            popup="删除"
            className="flex whitespace-nowrap items-center mr-2.5 px-2 h-8 animate-hover"
            onClick={onDelete}
          >
            <Icon name="IcMenuDelete" className="mr-1" size="middle" />
            删除
          </Tooltip>
          <Tooltip className="animate-hover" popup="更多">
            <Icon size="middle" name="IcMore" onClick={openMenuList} />
          </Tooltip>
        </>
      )}
      {canDownload && !isShare && <span className="w-px h-5 bg-grey6 mx-2.5" />}
    </div>
  );
};
// #endregion
