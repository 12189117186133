import {
  getDataBaseProgressBarBgColor,
  getDataBaseProgressBarColor,
} from '@flowus/common/block/color/get-block-color';
import { cx } from '@flowus/common/cx';
import { CollectionSchemaType, NumberShowType } from '@next-space/fe-api-idl';
import { clamp, sample } from 'lodash-es';
import type { FC } from 'react';
import React, { useState } from 'react';
import { COLORS } from 'src/bitable/const';
import { useBitable } from 'src/bitable/context';
import { ColorKey } from 'src/colors';
import { Bar } from 'src/common/components/bar';
import { Button } from 'src/common/components/button';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { LevelScore } from 'src/common/components/level-score';
import { useOpenModal } from 'src/common/components/next-modal';
import { Ring } from 'src/common/components/ring';
import { Switch } from 'src/common/components/switch';
import { useUpdatePropertySchema } from 'src/hooks/block/use-update-property-schema';
import { ossImagesPath } from 'src/image';
import { bizTracker } from 'src/utils/biz-tracker';
import { usePickBlock } from 'src/utils/pick-block';
import { formatNumber } from '../cell/num';
import { ColorSelector } from '../cell/select/color-selector';
import { isPercentAggregation } from '../footer/helper';

interface Props {
  propertyId: string;
  onCloseModal?: () => void;
}

export const NumberShowStyle: FC<Props> = (props) => {
  const { propertyId, onCloseModal } = props;

  const { collectionId } = useBitable();
  const collection = usePickBlock(collectionId, ['data'], ['schema']);
  const {
    showAs = {},
    numberFormat,
    type: schemaType,
    aggregation,
  } = collection?.data.schema?.[propertyId] ?? {};

  const [maxValue, setMaxValue] = useState<number>(showAs?.maxValue ?? 5);
  const [showNumber, setShowNumber] = useState<boolean>(showAs?.showNumber ?? true);
  const colorKeys = COLORS.map((color) => color.key);
  const [colorKey, setColor] = useState<string>(
    showAs?.color ?? sample(colorKeys) ?? ColorKey.grey
  );

  const [levelSymbol, setLevelSymbol] = useState<string>(showAs.levelSymbol ?? '⭐️');
  const [showType, setShowType] = useState<NumberShowType | undefined>(showAs.type);

  const updatePropertySchema = useUpdatePropertySchema();
  const openModal = useOpenModal();

  const list = [
    {
      title: __BUILD_IN__ ? 'Number' : '默认',
    },
    {
      title: '进度条',
      type: NumberShowType.BAR,
      example: (
        <Bar
          width={50}
          height={3}
          percent={0.25}
          bgColor={getDataBaseProgressBarBgColor(colorKey)}
          progressColor={getDataBaseProgressBarColor(colorKey)}
        />
      ),
    },
    {
      title: '环形图',
      type: NumberShowType.RING,
      example: (
        <Ring
          bgColor={getDataBaseProgressBarBgColor(colorKey)}
          ringColor={getDataBaseProgressBarColor(colorKey)}
          percent={0.25}
          className="border-grey7"
          ringColorClassName="border-active_color"
        />
      ),
    },
    {
      title: '等级评分',
      type: NumberShowType.LEVEL,
      example: <LevelScore totalScore={3} score={1.5} />,
    },
  ];

  const handleClick = (event: React.MouseEvent) => {
    openModal.dropdown({
      className: 'w-[240px] next-modal pb-2',
      popcorn: event.currentTarget,
      placement: 'bottom-end',
      content: ({ onCloseModal }) => (
        <ColorSelector
          onSelect={(color) => {
            setColor(color);
            onCloseModal();
          }}
          hideHeader
          value={colorKey}
          selectedIcon={
            <Icon size="normal" name="IcSelectedSpace" className={'text-[18px] text-white'} />
          }
          bgColorFn={getDataBaseProgressBarColor}
        />
      ),
    });
  };

  const selectLevelSymbol = (event: React.MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom-end',
      content: ({ onCloseModal }) => (
        <SelectEmoji
          onSelect={(emoji) => {
            setLevelSymbol(emoji);
            onCloseModal();
          }}
        />
      ),
    });
  };

  const changeShowType = (type?: NumberShowType) => {
    setShowType(type);
    if (type === NumberShowType.BAR || type === NumberShowType.RING) {
      if (!isBarOrRing) {
        setMaxValue(100);
      }
    } else {
      setMaxValue(5);
    }
  };

  const submit = () => {
    if (showType === NumberShowType.LEVEL && !/^([1-9]|10)$/.test(maxValue.toString())) {
      return;
    }

    if (isBarOrRing && !/^[1-9][0-9]*$/.test(maxValue.toString())) {
      return;
    }

    const eventName =
      schemaType === CollectionSchemaType.FORMULA
        ? 'prop_formula_style'
        : schemaType === CollectionSchemaType.ROLLUP
        ? 'prop_rollup_style'
        : 'prop_num_style';
    bizTracker.event(eventName, {
      style_type: showType === NumberShowType.LEVEL ? 'star' : !showType ? 'default' : showType,
    });

    updatePropertySchema(collectionId, propertyId, {
      showAs: showType
        ? {
            type: showType,
            maxValue,
            color: colorKey,
            showNumber,
            levelSymbol,
          }
        : undefined,
    });

    onCloseModal?.();
  };

  const isBarOrRing = showType === NumberShowType.BAR || showType === NumberShowType.RING;
  let showAllValue = true;
  if (isBarOrRing) {
    if (numberFormat?.includes('percent')) {
      showAllValue = false;
    }
    if (schemaType === CollectionSchemaType.ROLLUP && isPercentAggregation(aggregation)) {
      showAllValue = false;
    }
  }

  return (
    <div className="next-modal py-2.5 px-4 w-[334px]">
      <div className="flex justify-between my-2.5">
        {list.map((item) => {
          return (
            <div
              key={item.title}
              onClick={() => changeShowType(item.type)}
              className={cx(
                'w-[70px] h-[64px] py-2 flex justify-center items-center flex-col text-grey3 text-t4 rounded border-grey6 border cursor-pointer',
                showType === item.type && 'border border-active_color bg-active_color_10'
              )}
            >
              {item.example && <div className="h-6 flex items-center">{item.example}</div>}
              {item.title}
            </div>
          );
        })}
      </div>

      {showType && (
        <>
          {showType === NumberShowType.LEVEL ? (
            <div
              className="flex h-10 items-center justify-between cursor-pointer"
              onClick={selectLevelSymbol}
            >
              <span className="text-t2 flex-shrink-0 mr-auto">图标</span>

              {levelSymbol}
              <Icon size="normal" name={'IcMenuNext'} className="w-5 h-5 text-grey4" />
            </div>
          ) : (
            <div
              className="flex h-10 items-center justify-between cursor-pointer"
              onClick={handleClick}
            >
              <span className="text-t2 flex-shrink-0 mr-auto">颜色</span>

              <div
                className="w-5 h-5 rounded-full border border-black/5"
                style={{ backgroundColor: getDataBaseProgressBarColor(colorKey) }}
              />
              <Icon size="normal" name={'IcMenuNext'} className="w-5 h-5 text-grey4" />
            </div>
          )}

          {showAllValue && (
            <div className="flex h-10 items-center justify-between cursor-pointer relative">
              <span className="text-t2 flex-shrink-0 mr-auto">
                {showType === NumberShowType.LEVEL ? '分级(1-10)' : '总值'}
              </span>

              <Input
                className="h-8 w-[120px]"
                style={{ backgroundColor: 'transparent' }}
                onChange={(value) => setMaxValue(value)}
                type="number"
                value={String(maxValue)}
                inputType="number"
              />
            </div>
          )}

          {showType === NumberShowType.LEVEL && !/^([1-9]|10)$/.test(maxValue.toString()) && (
            <div className="right-0 text-red text-t4 text-right">仅支持1~10的整数</div>
          )}

          {isBarOrRing && !/^[1-9][0-9]*$/.test(maxValue.toString()) && (
            <div className="right-0 text-red text-t4 text-right">仅支持不小于1的整数</div>
          )}

          <div className="flex h-10 items-center justify-between cursor-pointer">
            <span className="text-t2 flex-shrink-0 mr-auto">显示数字</span>
            <Switch open={showNumber} onSwitch={(open) => setShowNumber(open)} />
          </div>

          {showType && (
            <div className="border border-grey6 rounded flex justify-center items-center h-[100px]">
              {showNumber && (
                <span className="mr-1">
                  {formatNumber(
                    showType === NumberShowType.LEVEL ? clamp(maxValue, 1, 10) * 0.25 : 0.25,
                    numberFormat
                  )}
                </span>
              )}

              {showType === NumberShowType.BAR && (
                <Bar
                  width={100}
                  height={5}
                  percent={0.25}
                  bgColor={getDataBaseProgressBarBgColor(colorKey) ?? ''}
                  progressColor={getDataBaseProgressBarColor(colorKey) ?? ''}
                />
              )}

              {showType === NumberShowType.RING && (
                <Ring
                  percent={0.25}
                  bgColor={getDataBaseProgressBarBgColor(colorKey) ?? ''}
                  ringColor={getDataBaseProgressBarColor(colorKey) ?? ''}
                />
              )}

              {showType === NumberShowType.LEVEL && (
                <LevelScore
                  levelSymbol={levelSymbol}
                  totalScore={clamp(maxValue, 1, 10)}
                  score={clamp(maxValue, 1, 10) * 0.25}
                />
              )}
            </div>
          )}
        </>
      )}

      <p className="text-grey3 text-t4 my-3">将在所有视图下生效</p>

      <Button onClick={submit} className="w-full h-8" colorType="active">
        提交
      </Button>
    </div>
  );
};

const SelectEmoji: FC<{
  onSelect: (emoji: string) => void;
}> = ({ onSelect }) => {
  return (
    <div className="text-[24px] w-[194px] next-modal py-2 pr-4 pl-2 flex justify-between flex-wrap">
      {['⭐️', '❤️', '💡', '🔥', '👍', '😀', '☘️', '🟡️', '🔷', '⬛'].map((text, index) => {
        return (
          <img
            src={`${ossImagesPath}/score/${index + 1}.png`}
            className="w-6 h-6 ml-2 mt-2 cursor-pointer"
            onClick={() => onSelect(text)}
          />
        );
      })}
    </div>
  );
};
