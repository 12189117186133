/**
 * 引导程序
 */
import * as Sentry from '@sentry/react';
import { getCurrentUser } from './hooks/user';
// import { Integrations } from '@sentry/tracing';
// import { getState } from './redux/store';

if (!__PRIVATE__) {
  if (__HOST_STAGING__ || __HOST_PRODUCTION__) {
    const environment = __HOST_STAGING__ ? 'staging' : 'production';

    Sentry.init({
      dsn: __BUILD_IN__
        ? 'https://4047a0274da24e839a08d370000be713@sentry.buildin.ai/2'
        : 'https://1cc4406cb8a38b141a2fab1f100ec022@sentry.allflow.cn:443/5',
      autoSessionTracking: true,
      tracesSampleRate: 0.5,
      ignoreErrors: [
        // https://stackoverflow.com/a/50387233/3382144
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications.',
        //
        "Unexpected token '<'",
      ],
      integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
      // integrations: [new Integrations.BrowserTracing()],
      environment,
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      release: import.meta.env.VITE_GIT_COMMIT,
      beforeSend(event) {
        const user = getCurrentUser();
        event.user = { id: user.uuid, username: user.nickname };
        return event;
      },
    });
  }
}
