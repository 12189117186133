import {
  AggregationAction,
  GroupSortType,
  type BlockDTO,
  type ChartFormat,
} from '@next-space/fe-api-idl';
import _ from 'lodash-es';
import { Compare } from '@flowus/common';
import type { Series } from './types';
import type { EChartsOption } from 'echarts';
import {
  convertBarViewOption,
  convertHorizontalBarViewOption,
  convertPieViewOption,
  convertToLineViewOption,
} from './chart-util';
import { CALCULATE_FUNS } from './math-util';
//字符串分割
/**
 * 把flowus的数据转换为chart通用数据的方法
 */
export const convertToData = (
  collection: BlockDTO,
  records: BlockDTO[], //只显示出来的records
  format: ChartFormat,
  getCellValue: (recordId: string, propertyId: string) => string[] | undefined
): EChartsOption | undefined => {
  const {
    chartMode,
    xAxisPropertyId = 'title',
    statistics = 'record',
    includeEmpty = true,

    orderRule = GroupSortType.ASCENDING,
    groupAggregationPropertyId,
    splitOption = true,
  } = format;
  let { yAxisPropertyIds = [{ propertyId: 'title' }], orderBy = 'xAxis' } = format;

  //如果统计类型是字段但字段没有值，则返回(一般不会发生，默认用title的字段)
  if (statistics === 'property' && yAxisPropertyIds.length === 0) {
    console.warn('统计字段缺失');
    return;
  }
  //如果超过两个颜色的柱，则不允许纵轴排序（因为太难算了）
  if (yAxisPropertyIds.length > 1 || groupAggregationPropertyId) {
    orderBy = 'xAxis';
  }
  //只要是统计记录总数，就默认用title，否则有bug（wolai是直接重置yAxisPropertyIds为空，但这样来回切换一次原来的flag就没了）
  if (statistics === 'record') {
    yAxisPropertyIds = [{ propertyId: 'title' }];
  }
  //是否分割选项
  const isSplitOptions = splitOption;
  const seriesArray: Series[] = [];
  //统计记录总数
  if (chartMode?.type === 'Number') {
    //这个特殊对待
    const p = yAxisPropertyIds[0]!;
    const yAxisPropertyValues: string[] = [];
    records.forEach((record) => {
      const yAxisValue = getCellValue(record.uuid, p.propertyId);
      yAxisValue?.forEach((v) => {
        yAxisPropertyValues.push(v);
      });
    });
    let aggregation = AggregationAction.COUNT_ALL;
    if (statistics === 'property') {
      aggregation = p.aggregation ?? AggregationAction.COUNT_ALL;
    }
    const calFun = CALCULATE_FUNS[aggregation];
    const ret = calFun?.(yAxisPropertyValues) ?? '-';
    const option: EChartsOption = {
      total: ret,
    };
    return option;
  }
  /**折线用0表示，柱状图用"-"表示 */
  const zeroSymbol = chartMode?.type === 'Line' ? 0 : '-';

  //根据字段统计
  yAxisPropertyIds.forEach((p) => {
    const property = collection?.data?.schema?.[p.propertyId ?? ''];
    const xAxisValueSet = new Set<string>();

    const combineKeySet = new Set<string>();
    const yAxisPropertyValues: {
      xAxisValue: string;
      combineKey: string;
      value: string;
    }[] = [];
    records.forEach((record) => {
      //横坐标分割
      const xAxisValue = getCellValue(record.uuid, xAxisPropertyId) || ['空值'];

      const xValues: string[] = [];
      if (isSplitOptions) {
        xAxisValue.forEach((v) => {
          xValues.push(v);
        });
      } else {
        xValues.push(xAxisValue.join(','));
      }
      xValues.forEach((v) => {
        xAxisValueSet.add(v);
      });
      const combineKeyArray: string[] = [];
      if (groupAggregationPropertyId) {
        const combineKey = getCellValue(record.uuid, groupAggregationPropertyId) || ['空值'];
        combineKey.forEach((ck) => {
          combineKeyArray.push(ck);
        });
      }
      combineKeyArray.forEach((v) => {
        combineKeySet.add(v);
      });
      const value = getCellValue(record.uuid, p.propertyId!) ?? ['空值'];
      if (combineKeyArray.length === 0) {
        xValues.forEach((xv) => {
          value.forEach((v) => {
            const a = { xAxisValue: xv, combineKey: '', value: v };
            yAxisPropertyValues.push(a);
          });
        });
      } else {
        combineKeyArray.forEach((ck) => {
          xValues.forEach((xv) => {
            value.forEach((v) => {
              const a = { xAxisValue: xv, combineKey: ck, value: v };
              yAxisPropertyValues.push(a);
            });
          });
        });
      }
    });
    const aggregation = p.aggregation ?? AggregationAction.COUNT_ALL;
    if (!groupAggregationPropertyId) {
      //没有分组聚合的情况下的统计逻辑
      const record: Record<string, string[]> = {};
      yAxisPropertyValues.forEach((v) => {
        const valueArray = record[v.xAxisValue] ?? [];
        valueArray.push(v.value);
        record[v.xAxisValue] = valueArray;
      });
      xAxisValueSet.forEach((xAxis) => {
        const valueArray = record[xAxis] ?? [];
        if (valueArray) {
          const calFun = CALCULATE_FUNS[aggregation];
          let ret = calFun?.(valueArray) ?? '-';
          if (typeof ret === 'number') {
            ret = ret.toFixed(2);
          }
          record[xAxis] = [ret];
        } else {
          record[xAxis] = ['-'];
        }
      });

      const series: Series = {
        name: property?.name ?? '',
        data: [],
        xAxisValueToMap: {},
      };
      Object.keys(record).forEach((key) => {
        const count = _.first(record[key]) ?? '-';
        series.xAxisValueToMap[key] = count;
      });
      seriesArray.push(series);
    } else {
      //有分组聚合情况下的统计逻辑
      //分类
      const record: Record<string, Record<string, string[]>> = {};
      yAxisPropertyValues.forEach((v) => {
        const xAxisRecord = record[v.xAxisValue] ?? ({} as Record<string, string[]>);
        const valueArray = xAxisRecord[v.combineKey] ?? ([] as string[]);
        valueArray.push(v.value);
        xAxisRecord[v.combineKey] = valueArray;
        record[v.xAxisValue] = xAxisRecord;
      });
      xAxisValueSet.forEach((xAxis) => {
        combineKeySet.forEach((combineKey) => {
          const xAxisRecord = record[xAxis] ?? {};
          const arr = xAxisRecord?.[combineKey];
          if (xAxisRecord) {
            if (arr) {
              const calFun = CALCULATE_FUNS[aggregation];
              let ret = calFun?.(arr) ?? zeroSymbol;
              if (typeof ret === 'number') {
                ret = ret.toFixed(2);
              }
              xAxisRecord[combineKey] = [ret];
            } else {
              xAxisRecord[combineKey] = [`${zeroSymbol}`];
            }
          }
        });
      });
      const seriesRecord: Record<string, Series> = {};
      Object.keys(record).forEach((xAxis) => {
        const xAxisRecord = record[xAxis];
        if (xAxisRecord) {
          Object.keys(xAxisRecord).forEach((aggregationKey) => {
            const value = _.first(xAxisRecord[aggregationKey]);
            let series = seriesRecord[aggregationKey];
            if (!series) {
              series = {
                name: aggregationKey,
                data: [],
                xAxisValueToMap: {},
              };
              seriesRecord[aggregationKey] = series;
            }
            series.xAxisValueToMap[xAxis] = value === '-' ? value : parseFloat(value!);
            series.data.push(value === '-' ? value : parseFloat(value!));
          });
        }
      });
      Object.keys(seriesRecord).forEach((k) => {
        seriesArray.push(seriesRecord[k]!);
      });
    }
  });
  //横坐标排序
  let xAxis: string[] = [];
  const series: Series[] = [];
  if (orderBy === 'xAxis') {
    seriesArray.forEach((s) => {
      const array = _.map(_.entries(s.xAxisValueToMap), ([xAxis, value]) => ({ xAxis, value }));
      const emptySeries = array.filter((v) => v.xAxis === '空值');
      const otherSeries = array.filter((v) => v.xAxis !== '空值');
      const result = otherSeries.sort((a, b) => {
        return orderRule === GroupSortType.ASCENDING
          ? Compare.pinyin(a.xAxis, b.xAxis)
          : Compare.pinyin(b.xAxis, a.xAxis);
      });
      if (includeEmpty) {
        result.push(...emptySeries);
      }
      if (xAxis.length === 0) {
        xAxis = result.map((v) => v.xAxis);
      }
      const valueData = result.map((v) => v.value);
      series.push({
        name: statistics === 'record' && !groupAggregationPropertyId ? '计数' : s.name,
        data: valueData,
        xAxisValueToMap: {},
      });
    });
  } else {
    //纵轴排序
    seriesArray.forEach((s) => {
      const array = _.map(_.entries(s.xAxisValueToMap), ([xAxis, value]) => ({ xAxis, value }));

      let result = array.sort((a, b) => {
        if (typeof a.value === 'string' && typeof b.value === 'string') {
          const av = parseFloat(a.value);
          const bv = parseFloat(b.value);
          return GroupSortType.ASCENDING === orderRule ? av - bv : bv - av;
        } else if (typeof a.value === 'number' && typeof b.value === 'number') {
          return GroupSortType.ASCENDING === orderRule ? a.value - b.value : b.value - a.value;
        }
        return 0;
      });
      if (!includeEmpty) {
        result = result.filter((v) => v.xAxis !== '空值');
      }
      if (xAxis.length === 0) {
        xAxis = result.map((v) => v.xAxis);
      }
      const valueData = result.map((v) => v.value);
      series.push({
        name: statistics === 'record' && !groupAggregationPropertyId ? '计数' : s.name,
        data: valueData,
        xAxisValueToMap: {},
      });
    });
  }
  /**if (format.chartMode?.type === 'Number') {
    return convertNumber(series);
  } else*/ if (format.chartMode?.type === 'Bar') {
    return convertBarViewOption(xAxis, series, format.chartMode.display ?? 'base', format);
  } else if (format.chartMode?.type === 'Line') {
    return convertToLineViewOption(xAxis, series, format.chartMode.display ?? 'base', format);
  } else if (format.chartMode?.type === 'HorizontalBar') {
    return convertHorizontalBarViewOption(
      xAxis,
      series,
      format.chartMode.display ?? 'base',
      format
    );
  } else if (format.chartMode?.type === 'Pie') {
    return convertPieViewOption(xAxis, series, format.chartMode.display ?? 'base', format);
  }
  return convertBarViewOption(xAxis, series, 'base', format);
};

export const checkDataIntegrity = (collection: BlockDTO | undefined, format: ChartFormat) => {
  if (!collection) return true;
  const { schema } = collection.data;
  if (format.xAxisPropertyId) {
    const property = schema?.[format.xAxisPropertyId];
    if (!property) return false;
  }
  if (format.yAxisPropertyIds) {
    const someOneNotFound = format.yAxisPropertyIds.some((s) => {
      const property = schema?.[s.propertyId];
      if (!property) return true;
      return false;
    });
    if (someOneNotFound) {
      return false;
    }
  }
  if (format.groupAggregationPropertyId) {
    const property = schema?.[format.groupAggregationPropertyId];
    if (!property) return false;
  }
  return true;
};
