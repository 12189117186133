import { cx } from '@flowus/common/cx';
import { BlockType, PermissionRole, PermissionType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { message } from 'src/common/components/message';
import { useOpenModal } from 'src/common/components/next-modal';
import { Switch } from 'src/common/components/switch';
import { Tooltip } from 'src/common/components/tooltip';
import { DATE_FORMAT, PUBLIC_SHARE } from 'src/common/const';
import { createMenuDataContext } from 'src/editor/component/menu-list/menu-item';
import { segmentsToText } from 'src/editor/utils/editor';
import { ActivityIds } from 'src/hooks/activities/activity-ids';
import { ActivitiesListType } from 'src/hooks/activities/use-activity';
import { useUpdateTask } from 'src/hooks/activities/use-update-task';
import { useReadonly } from 'src/hooks/page';
import { getPermissions, usePermissions } from 'src/hooks/share/use-permissions';
import { useCurrentSpace } from 'src/hooks/space/use-current-space';
import { useIsFreeSpaceLimit } from 'src/hooks/space/use-is-pro-space';
import { TRANSACTION_FIRE } from 'src/redux/actions';
import {
  removeBlockPermission,
  setBlockPermission,
} from 'src/redux/managers/block/update-permission';
import { dispatch } from 'src/redux/store';
import { bizTracker } from 'src/utils/biz-tracker';
import { isPageLike } from 'src/utils/block-type-utils';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { judgeSharePage } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { ProIcon } from 'src/views/main/header/pro_icon';
import { OpenSettingFrom, SettingMenuType } from 'src/views/main/setting-modal/type';
import { useOpenAskUpgradeSpace } from 'src/views/main/setting-modal/use-open-ask-upgrade-space';
import { useOpenSettingModal } from 'src/views/main/setting-modal/use-open-setting-modal';
import { setPermission, useGetSharePageUrl } from '../helper';
import { ShareButtons } from '../share-buttons';
import type { ShareProps } from '../types';
import { Panel } from '../types';
import { ShareMainFooter } from './share-main-footer';
import { ShareMainInvite } from './share-main-invite';
import { DateTimePicker } from 'src/editor/editor/plugin/date-picker';
import dayjs from 'dayjs';
import { elementToGetBoundingClientRect } from 'src/utils/virtualElement';
import { SearchParams } from '@flowus/login/const';
import { useClipboard } from '@flowus/common/hooks/use-clipboard';
import { usePublicShare } from 'src/hooks/page/use-public-share';
import { transaction } from 'src/hooks/use-transaction';
import { computePeriod } from '../share-pay';

type Props = Pick<ShareProps, 'uuid' | 'onChangeTab'>;

enum ShowType {
  UserListTab,
  ShareTab,
}

const ShareMainList = [
  { title: '公开分享', type: ShowType.ShareTab },
  { title: '邀请协作', type: ShowType.UserListTab },
];

export const ShareMain: FC<Props> = (props) => {
  const [showTab, setShowTab] = useState(ShareMainList[0]?.type);

  return (
    <>
      <div className="text-t2 flex items-center px-4 space-x-2 py-2 border-b border-grey6">
        {ShareMainList.map((item) => (
          <div
            key={item.title}
            onClick={() => setShowTab(item.type)}
            className={cx(
              'relative animate-hover px-1.5 py-1 mb-px',
              item.type === showTab && 'bg-black_006'
            )}
          >
            {item.title}
          </div>
        ))}
      </div>
      {showTab === ShowType.UserListTab && <UserListTab {...props} />}
      {showTab === ShowType.ShareTab && <ShareTab {...props} />}
    </>
  );
};

const UserListTab: FC<Props> = (props) => {
  const { onChangeTab, uuid } = props;

  return <ShareMainInvite onChangeTab={onChangeTab} uuid={uuid} />;
};

const ShareTab: FC<Props> = (props) => {
  const { uuid, onChangeTab } = props;
  const openModal = useOpenModal();
  const updateTask = useUpdateTask();
  const { set, permissionsState, roleStatus } = usePublicShare(uuid);
  const {
    permissions,
    shared,
    illegal,
    role,
    publicRole,
    allowDuplicate,
    allowShowBreadcrumb,
    isRestricted,
    parentId,
    allowShowSidebar,
    allowShowAIChat,
    allowSeo,
    password,
    allowSubscribe,
    allowDownload,
    linkValidDate,
    accessFee,
    allowSelectionCopy,
  } = permissionsState;

  const currentSpace = useCurrentSpace();

  let { abandonedPublicShare } = currentSpace.setting ?? {};
  if (currentSpace.planType !== 'team') {
    abandonedPublicShare = false;
  }
  const block = usePickBlock(uuid, ['data'], ['segments']);
  // 如果已经禁止分享，已经分享过的页面允许关闭，但一旦关闭就无法再打开
  const disableSwitchPublicShare = abandonedPublicShare && !shared;

  /** issue: https://flowus.cn/eadc6101-5a96-4ed5-83fc-cb89a2c80675 */
  // const parentPermission = usePermissions(block?.parentId);
  // const isRoot = block?.parentId === block?.spaceId;
  const readonly = useReadonly(uuid);
  const isPDFFile =
    block?.type === BlockType.FILE &&
    segmentsToText(block.data.segments).toLowerCase().endsWith('.pdf');

  const isFreeSpaceLimit = useIsFreeSpaceLimit();
  // 打开了分享或者修改了可查看/可评论选项
  const openShareOrSwitchViewType = useRef(false);

  const { url, text, textAndUrl } = useGetSharePageUrl(uuid)();
  const openSettingModal = useOpenSettingModal();
  const openAskUpgradeSpace = useOpenAskUpgradeSpace();

  // usePublicShare(uuid);

  const copyShareId = useClipboard({ noMessage: true });

  const copyLink = password ? [`${url}${text}\n`, `访问密码${password}`].join('') : textAndUrl;

  const onCopyLink = async () => {
    await writeTextInClipboard(copyLink, { message: password ? '已复制链接和密码' : '已复制链接' });
    bizTracker.event('share_copylink', { is_share: shared });
  };

  const copyEmbedLink = async () => {
    const urlObj = new URL(url);
    urlObj.searchParams.set(SearchParams.embed, 'true');
    await writeTextInClipboard(urlObj.href, { message: '已复制链接' });
    bizTracker.event('click_share_btn', {
      type: 'embedded',
    });
  };

  useEffect(() => {
    return () => {
      if (openShareOrSwitchViewType.current) {
        const { publicRole } = getPermissions(uuid);
        bizTracker.event('share_authority', {
          authority_type: publicRole === PermissionRole.COMMENTER ? 'can_comment' : 'can_read',
        });
      }
    };
  }, [uuid]);

  if (!block) return null;

  const isEditor = role === PermissionRole.EDITOR;

  const onClickSwitch = async () => {
    if (readonly || !isEditor || disableSwitchPublicShare) return;
    if (accessFee && shared) {
      const goOn = await new Promise((resolve) => {
        openModal.warning({
          title: '当前页面正在付费分享。关闭公开分享后，任何人都无法访问分享页面，确认关闭吗？',
          confirm: () => {
            resolve(true);
          },
          cancel: () => {
            resolve(false);
          },
          closeBeforeCallBackForClickOutSide: () => {
            resolve(false);
          },
        });
      });
      if (!goOn) return;
    }

    if (!isRestricted && shared && parentId) {
      openModal.warning({
        title: (
          <div className="px-5 mb-5 w-80">
            <div>确定关闭该分享?</div>
            <div>关闭后将无法从分享的上级页面继续访问此页面</div>
          </div>
        ),
        confirmText: '关闭分享',
        confirm: () => {
          bizTracker.event('share_switch', { is_open: false });

          // 非顶层(即有 parent)时添加受限
          if (parentId) {
            permissions.forEach((o) => setPermission(uuid, o));
            setBlockPermission(uuid, { type: PermissionType.RESTRICTED });
          }
          removeBlockPermission(uuid, { type: PermissionType.PUBLIC });
          dispatch(TRANSACTION_FIRE());
        },
      });
    } else {
      bizTracker.event('share_switch', { is_open: !shared });

      if (shared) {
        removeBlockPermission(uuid, { type: PermissionType.PUBLIC });
      } else {
        openShareOrSwitchViewType.current = true;
        setBlockPermission(uuid, {
          role: PermissionRole.READER,
          type: PermissionType.PUBLIC,
        });

        // 积分任务
        if (isPageLike(block.type)) {
          void updateTask(ActivityIds.GUIDE_FIRST_TIME_SHARE, ActivitiesListType.advancedList);
          void updateTask(ActivityIds.OPEN_SHARE, ActivitiesListType.advancedList);
        }
      }

      dispatch(TRANSACTION_FIRE());
    }
  };

  const onClickAllDuplicate = () => {
    transaction(() => {
      void set().setRole('allowDuplicate');
    });
  };

  const onClickAllowSelectionCopy = () => {
    transaction(() => {
      void set().setRole('allowSelectionCopy');
    });
  };

  const onClickAllowDownload = () => {
    transaction(() => {
      void set().setRole('allowDownload');
    });
  };

  const onClickOpenToc = () => {
    transaction(() => {
      void set().setShareShowSidebar();
    });
  };

  const onClickOpenBreadcrumb = () => {
    transaction(() => {
      void set().setRole('allowShowBreadcrumb');
    });
  };

  const onClickAIChat = () => {
    transaction(() => {
      void set().setRole('allowShowAIChat');
    });
  };
  const onClickAllowSubscribe = () => {
    transaction(() => {
      void set().setAllowSubscribe();
    });
  };

  const onClickOpenSeo = () => {
    // 如果设置了密码，需要把seo开关关掉，并且item disable掉，两者不能共存
    if (isFreeSpaceLimit) {
      openAskUpgradeSpace(() => {
        openSettingModal({
          defaultMenuType: SettingMenuType.upgrade,
          from: OpenSettingFrom.share_seo,
        });
      });
      return;
    }

    transaction(() => {
      void set().setShareSeo();
    });
  };
  const onClickPasswordSetting = () => {
    bizTracker.event('share_pw');
    onChangeTab(Panel.PASSWORD);
  };
  const onClickLinkValid = (e: React.MouseEvent) => {
    if (!isEditor) return;
    if (isFreeSpaceLimit) {
      openAskUpgradeSpace(() => {
        openSettingModal({
          defaultMenuType: SettingMenuType.upgrade,
          from: OpenSettingFrom.link_valid,
        });
      });
      return;
    }

    if (roleStatus.isRestricted) {
      message.error('继承上级页面的设置，不支持单独关闭');
    } else {
      openModal.dropdown({
        placement: 'bottom-end',
        popcorn: e.currentTarget,
        content: (_) => {
          return (
            <ListView
              className="w-[200px] next-modal py-2"
              items={[
                {
                  type: ListItemType.OPERATION,
                  data: {
                    title: '永久有效',
                    onClick: () => {
                      _.onCloseModal();
                      transaction(() => {
                        void set().setLinkValidTime(undefined);
                      });
                    },
                    selected: !linkValidDate,
                  },
                },
                {
                  type: ListItemType.LINE,
                  data: {},
                  disableClick: true,
                },
                {
                  type: ListItemType.OPERATION,
                  data: {
                    title: (
                      <div>
                        <div>自定义失效日期</div>
                        {linkValidDate && (
                          <div className="mt-1 text-t4 text-grey3">{`${dayjs(linkValidDate).format(
                            DATE_FORMAT
                          )}`}</div>
                        )}
                      </div>
                    ),
                    onClick: (e: React.MouseEvent) => {
                      openModal.dropdown({
                        popcorn: elementToGetBoundingClientRect(e.currentTarget),
                        placement: 'top',
                        content: ({ onCloseModal }) => {
                          const date = dayjs(linkValidDate);
                          const today = new Date();
                          today.setDate(today.getDate());
                          return (
                            <DateTimePicker
                              uiOption={{
                                hasSwitchTimeOption: false,
                                hasFormatSetting: false,
                                disabledDays: {
                                  before: today,
                                },
                                footer: (
                                  <div className="px-4 text-t4 text-grey3 text-center">
                                    选中日期23:59链接失效，失效后页面公开分享状态自动关闭
                                  </div>
                                ),
                              }}
                              closeDatePicker={onCloseModal}
                              dateInfo={{
                                from: date.isValid() ? date.toDate() : new Date(),
                                includeTime: false,
                              }}
                              onChange={({ from }) => {
                                _.onCloseModal();
                                from.setHours(23);
                                from.setMinutes(59);
                                transaction(() => {
                                  void set().setLinkValidTime(from.getTime());
                                });
                              }}
                            />
                          );
                        },
                      });
                    },
                    selected: linkValidDate,
                  },
                },
              ]}
            />
          );
        },
      });
    }
  };

  const onClickOpenDomainSetting = () => {
    openSettingModal({
      from: OpenSettingFrom.homepage,
      defaultMenuType: SettingMenuType.space,
    });
  };

  // 权限继承，受限逻辑跟分享开关一样
  const disableSettingShareViewOrComment = !isEditor || (!isRestricted && !!parentId);
  // 分享是否可评论
  const openShareLinkOption = (e: React.MouseEvent) => {
    if (!isEditor) return;
    if (!isRestricted && parentId) {
      message.error('继承上级页面的设置，不支持单独关闭');
      return;
    }
    openModal.dropdown({
      offset: [0, 5],
      popcorn: e.currentTarget as Element,
      placement: 'bottom',
      content: ({ onCloseModal }) => {
        return (
          <ListView
            className="next-modal py-1 w-72"
            items={[
              {
                type: ListItemType.TEXT_DESCRIPTION,
                data: {
                  title: (
                    <>
                      任何获得此链接的人都
                      <span className={cx('ml-1 text-t2-medium')}>
                        <span>可查看</span>
                      </span>
                    </>
                  ),
                  selected: publicRole !== PermissionRole.COMMENTER,
                  onClick: () => {
                    if (publicRole === PermissionRole.COMMENTER) {
                      openShareOrSwitchViewType.current = true;
                      transaction(() => {
                        void set().setCommon(PermissionRole.READER);
                      });
                    }
                    onCloseModal();
                  },
                },
              },
              {
                type: ListItemType.TEXT_DESCRIPTION,
                data: {
                  title: (
                    <>
                      任何获得此链接的人都<span className="ml-1 text-t2-medium">可评论</span>
                    </>
                  ),
                  selected: publicRole === PermissionRole.COMMENTER,
                  onClick: () => {
                    if (publicRole !== PermissionRole.COMMENTER) {
                      openShareOrSwitchViewType.current = true;
                      transaction(() => {
                        void set().setCommon(PermissionRole.COMMENTER);
                      });
                    }
                    onCloseModal();
                  },
                },
              },
            ]}
          />
        );
      },
    });
  };

  return (
    <div className="text-t2 overflow-auto max-h-[80vh]">
      <Tooltip
        delay={5000}
        className="w-full"
        disabled={!(isEditor && disableSwitchPublicShare)}
        popup="已禁止分享，可到空间设置里打开"
      >
        <div
          className={cx(
            'w-full flex items-center justify-between px-4 py-3 animate-click animate-hover rounded-none',
            (!isEditor || disableSwitchPublicShare) && 'opacity-60 cursor-not-allowed'
          )}
          onClick={onClickSwitch}
        >
          <div className="flex">{PUBLIC_SHARE}</div>
          {!judgeSharePage() && (
            <Switch
              disabled={disableSwitchPublicShare}
              bgClassName="pointer-events-none"
              open={illegal ? false : shared}
            />
          )}
        </div>
      </Tooltip>
      {shared && (
        <>
          <div className="flex items-center h-8 bg-grey9 rounded border-[1px] border-grey6 mx-4 my-1">
            <div className="relative ml-3 w-full animate-click" onClick={openShareLinkOption}>
              任何获得此链接的人都
              <span
                className={cx('ml-1 text-t2-medium', {
                  'opacity-20': disableSettingShareViewOrComment,
                  'cursor-not-allowed': disableSettingShareViewOrComment,
                })}
              >
                {publicRole === PermissionRole.COMMENTER ? '可评论' : '可查看'}
              </span>
              <Icon
                name={'IcArrow'}
                size="xxxsmall"
                className="absolute right-2 top-[5px] text-grey3"
              />
            </div>
            <div className="w-[1.5px] h-full bg-grey6" />
            <div
              onClick={onCopyLink}
              id={copyShareId}
              data-clipboard-text={copyLink}
              className="flex h-full  flex-shrink-0 items-center px-3 font-medium bg-white2 animate-click"
            >
              <div className="text-t2-medium">{password ? '复制链接和密码' : '复制链接'}</div>
            </div>
          </div>
          <div hidden={__PRIVATE__} className="py-2 mx-4 text-t3 text-grey3 space-x-1">
            <span>自定义你的空间域名</span>
            <span className="animate-click text-active_color" onClick={onClickOpenDomainSetting}>
              {currentSpace.domain ? currentSpace.domain : '设置'}
            </span>
          </div>
          {!illegal && <PaySetting onChangeTab={onChangeTab} uuid={uuid} />}
          <div
            onClick={onClickPasswordSetting}
            className="animate-hover rounded-none animate-click w-full flex justify-between items-center px-4 py-3"
          >
            <div className="relative">
              <div>{password ? '已启用密码' : '未启用密码'}</div>
            </div>
            <button className="text-grey3 flex items-center">
              {password ? '修改密码' : '设置密码'}
            </button>
          </div>
          <div
            onClick={onClickLinkValid}
            className={cx(
              'w-full flex justify-between items-center px-4 py-3',
              !password && !readonly && isEditor && 'animate-hover rounded-none animate-click'
            )}
          >
            <div className="relative">
              <div>链接有效期</div>
              {isFreeSpaceLimit && <ProIcon className="right-[-32px] top-0.5" />}
            </div>
            <button className="text-grey3 flex items-center">
              {linkValidDate ? `${dayjs(linkValidDate).format(DATE_FORMAT)}到期` : '永久有效'}
              <Icon name="IcArrow" size="xxxsmall" className="ml-1" />
            </button>
          </div>
          <div
            hidden={__PRIVATE__}
            onClick={onClickAllowSubscribe}
            className={cx(
              'flex justify-between items-center px-4 py-3',
              !readonly && isEditor && 'animate-hover rounded-none animate-click',
              ((accessFee && accessFee > 0) || roleStatus.isRestricted) && 'opacity-60'
            )}
          >
            <div>允许查看者订阅分享页面</div>
            <Switch bgClassName="pointer-events-none" open={allowSubscribe} />
          </div>
          <div
            onClick={onClickAllDuplicate}
            className={cx(
              'flex justify-between items-center px-4 py-3',
              !readonly && isEditor && 'animate-hover rounded-none animate-click',
              (!isEditor || roleStatus.isRestricted) && 'opacity-60'
            )}
          >
            <div>允许查看者拷贝副本</div>
            <Switch bgClassName="pointer-events-none" open={allowDuplicate} />
          </div>
          <div
            onClick={onClickAllowSelectionCopy}
            className={cx(
              'flex justify-between items-center px-4 py-3',
              !readonly && isEditor && 'animate-hover rounded-none animate-click',
              (!isEditor || roleStatus.isRestricted) && 'opacity-60'
            )}
          >
            <div>允许查看者复制内容</div>
            <Switch bgClassName="pointer-events-none" open={allowSelectionCopy} />
          </div>
          <div
            onClick={onClickAllowDownload}
            className={cx(
              'flex justify-between items-center px-4 py-3',
              !readonly && isEditor && 'animate-hover rounded-none animate-click',
              (!isEditor || roleStatus.isRestricted) && 'opacity-60'
            )}
          >
            <div>允许查看者下载和打印页面副本</div>
            <Switch bgClassName="pointer-events-none" open={allowDownload} />
          </div>

          <div
            onClick={onClickOpenToc}
            className={cx(
              'flex justify-between items-center px-4 py-3',
              !readonly && isEditor && 'animate-hover rounded-none animate-click',
              (!isEditor || roleStatus.isRestricted) && 'opacity-60'
            )}
          >
            <div>分享页显示侧边导航目录</div>
            <Switch bgClassName="pointer-events-none" open={allowShowSidebar} />
          </div>
          <div
            onClick={onClickOpenBreadcrumb}
            className={cx(
              'flex justify-between items-center px-4 py-3',
              !readonly && isEditor && 'animate-hover rounded-none animate-click',
              (!isEditor || roleStatus.isRestricted) && 'opacity-60'
            )}
          >
            <div>分享页显示顶部导航栏</div>
            <Switch bgClassName="pointer-events-none" open={allowShowBreadcrumb} />
          </div>
          <div
            hidden={!isPDFFile || !__PRIVATE__}
            onClick={onClickAIChat}
            className={cx(
              'flex justify-between items-center px-4 py-3',
              !readonly && isEditor && 'animate-hover rounded-none animate-click',
              (!isEditor || roleStatus.isRestricted) && 'opacity-60'
            )}
          >
            <div>分享页显示 AI 会话记录</div>
            <Switch bgClassName="pointer-events-none" open={allowShowAIChat} />
          </div>

          {!__PRIVATE__ && (
            <Tooltip
              placement={password ? undefined : 'right'}
              className="w-full"
              popup={
                password
                  ? '分享密码已开启,无法进行搜索引擎推广'
                  : createMenuDataContext({
                      title: '开启后，将在百度搜索引擎中进行排名推广，增加页面访问量.',
                      image: __BUILD_IN__ ? 'google_search' : 'baidu_search',
                    })
              }
            >
              <div
                onClick={onClickOpenSeo}
                className={cx(
                  'w-full flex justify-between items-center px-4 py-3',
                  !password && !readonly && isEditor && 'animate-hover rounded-none animate-click',
                  (!!password || !isEditor || roleStatus.isRestricted) && 'opacity-60'
                )}
              >
                <div className="relative">
                  <div>搜索引擎推广</div>
                  {isFreeSpaceLimit && <ProIcon className="right-[-32px] top-0.5" />}
                </div>
                <Switch bgClassName="pointer-events-none" open={allowSeo} />
              </div>
            </Tooltip>
          )}
          <ShareButtons
            uuid={uuid}
            url={url}
            onCopyLink={onCopyLink}
            onCopyEmbedLink={copyEmbedLink}
          />
        </>
      )}
      <ShareMainFooter uuid={uuid} />
    </div>
  );
};

const PaySetting: FC<Props> = (props) => {
  const { uuid, onChangeTab } = props;
  const { accessFee = 0, accessFeeValidDays } = usePermissions(uuid);
  if (__BUILD_IN__ || __PRIVATE__) return null;

  return (
    <div
      className="px-4 flex items-center justify-between text-t2 py-3 animate-hover animate-click rounded-none"
      onClick={(e) => {
        e.stopPropagation();
        bizTracker.event('share_pay');
        onChangeTab(Panel.PAY);
      }}
    >
      <div>付费订阅</div>
      <div className={cx('flex items-center', accessFee ? 'text-active_color' : 'text-grey3')}>
        <span>{accessFee ? `¥${accessFee},${computePeriod(accessFeeValidDays)}` : '未设置'}</span>
      </div>
    </div>
  );
};
