import type { FC } from 'react';
import React, { useState } from 'react';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { Icon } from '../icon';
import { Input } from '../input';
import { Button } from '../button';
import { Tooltip } from '@flowus/common/tooltip';

export enum LinkWidgetType {
  external = 0,
  internal = 1,
}

export interface LinkWidgetProp {
  linkType?: LinkWidgetType;
  onLinkChange?: (value: string) => void;
  onSetLink?: (linkUrl?: string) => void;
  onClearLink?: (isFocus?: boolean) => void;
  onCloseModal: () => void;
  inLink?: boolean;
  link: string;
  isInternalLink?: boolean;
}

interface UniversalLinkProps {
  isInternalLink: boolean;
}

const ExternalLinkPanel: FC<Omit<LinkWidgetProp, 'linkType'> & UniversalLinkProps> = ({
  inLink,
  link,
  onLinkChange,
  onSetLink,
  onCloseModal,
  isInternalLink,
  onClearLink,
}) => {
  const [editable, setEditable] = useState(isInternalLink);
  return (
    <>
      {inLink && !isInternalLink && !editable ? (
        <>
          <div className="flex items-center w-full justify-between space-x-1">
            <div className="flex items-center space-x-1 min-w-[140px]">
              <Icon name={'IcUploadWeb'} size="middle" />
              <span className="text-ellipsis">{link}</span>
            </div>
            <div className="shrink-0 flex items-center space-x-1">
              <Tooltip
                className="p-1 animate-hover"
                popup="编辑链接"
                onClick={() => {
                  setEditable(true);
                }}
              >
                <Icon name={'IcBtnEdit'} size="small" />
              </Tooltip>

              <Tooltip
                className="p-1 animate-hover"
                popup="复制链接"
                onClick={() => {
                  void writeTextInClipboard(link, { message: '已复制链接' });
                }}
              >
                <Icon name={'IcMenuDuplicate'} size="small" />
              </Tooltip>
              <Tooltip
                className="p-1 animate-hover"
                popup="清除链接"
                onClick={() => {
                  onClearLink?.();
                  onCloseModal();
                }}
              >
                <Icon name={'IcMenuDelete'} size="small" />
              </Tooltip>
            </div>
          </div>
        </>
      ) : (
        <div className="w-full flex items-center justify-between">
          <Input
            autoFocus
            placeholder={`输入网页链接`}
            value={isInternalLink ? '' : link}
            className="w-full mx-2.5 my-2.5 h-8"
            onEnter={() => {
              onSetLink?.(isInternalLink ? '' : link);
              onCloseModal();
            }}
            onChange={(value) => {
              onLinkChange?.(value);
            }}
          />
          <Button
            colorType="active"
            size="large"
            onClick={() => {
              onSetLink?.(isInternalLink ? '' : link);
              onCloseModal();
            }}
          >
            确定
          </Button>
        </div>
      )}
    </>
  );
};

export const LinkWidget: FC<LinkWidgetProp> = ({
  // linkType = LinkWidgetType.external,
  isInternalLink = false,
  ...props
}) => {
  return (
    <div className="next-modal w-[360px] p-1 flex items-center text-t2 text-grey3">
      <ExternalLinkPanel {...props} isInternalLink={isInternalLink} />
    </div>
  );
};
