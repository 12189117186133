import { AggregationAction, CollectionSchemaType } from '@next-space/fe-api-idl';
import { getFormulaTool } from 'src/hooks/block/use-formula-tool';
import { getPropertySchema } from 'src/hooks/block/use-property-schema';

import { getRollupAggregation, MenuItems } from './helper';

export const getAvailableAggregations = (collectionId: string, propertyId: string) => {
  const { propertySchema, relationPropertySchema, targetPropertySchema } = getPropertySchema(
    collectionId,
    propertyId
  );

  if (!propertySchema) return [];
  const { type: propertySchemaType, targetProperty, aggregation } = propertySchema;

  const formulaTool = getFormulaTool(collectionId);

  let validAggregations: {
    type: AggregationAction | undefined;
    title: string;
  }[] = [];

  if (propertySchemaType === CollectionSchemaType.ROLLUP) {
    let targetPropertyType = targetPropertySchema?.type;

    if (targetPropertyType === CollectionSchemaType.FORMULA) {
      const relationFormulaTool = getFormulaTool(relationPropertySchema?.collectionId ?? '');

      targetPropertyType = relationFormulaTool.getTypeAsCollectionSchemaType(targetProperty ?? '');
    }
    if (!targetPropertyType) return [];

    validAggregations = getRollupAggregation(targetPropertyType, aggregation);
  } else if (propertySchemaType === CollectionSchemaType.FORMULA) {
    const schemaType = formulaTool.getTypeAsCollectionSchemaType(propertyId);
    validAggregations = MenuItems[schemaType] ?? [];
  } else {
    validAggregations = MenuItems[propertySchemaType] ?? [];
  }

  return [...validAggregations]; // 避免在 getRollupAggregations 中(引用)修改 MenuItems
};

export const getRollupAggregations = (collectionId: string, propertyId: string, type?: string) => {
  const validAggregations = getAvailableAggregations(collectionId, propertyId);
  validAggregations.shift();

  if (type !== CollectionSchemaType.CHECKBOX) {
    validAggregations.unshift({ type: AggregationAction.SHOW_UNIQUE, title: '显示 唯一值' });
  }

  validAggregations.unshift({ type: AggregationAction.SHOW_ORIGIN, title: '显示 原始值' });

  return validAggregations;
};
