import { restrictToHorizontalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { getBoardGroupBackgroundColor } from '@flowus/common/block/color/get-block-color';
import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { memo, useEffect, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { SortableList } from 'src/common/components/sortable-list';
import { deepEqual } from '@flowus/common/utils/tools';
import { Selector } from 'src/editor/editor/uikit/selector';
import { useBiTableGroups } from 'src/hooks/collection-view/table-groups';
import type {
  SubTableGroupData,
  TableGroupData,
} from 'src/hooks/collection-view/table-groups/select-collection-groups';
import { useCardImageDir, useCardSize } from 'src/hooks/collection-view/use-collection-view';
import { useEnableBoardColor } from 'src/hooks/collection-view/use-enable-board-color';
import { useGroupPropertySchema } from 'src/hooks/collection-view/use-group-option';
import { useHoveringEmpty } from 'src/hooks/page/use-dnd/hooks';
import { useTableGroupIsUnFolded } from 'src/services/app';
import { usePageScrollRef } from 'src/views/main/page-doc/context';
import { AddGroup } from '../add-group';
import { AddRecord } from '../add-record';
import { useHandleGroupSort } from '../bitable-manager/group-list';
import { getCardSize, isEnableVirtuoso, OVERSCAN } from '../const';
import { useBitable } from '../context';
import { GroupHeader } from '../group-header';
import { BitableLoadMoreContext, useBitableLoadMoreContext } from '../hooks';
import { BitableLoadMore } from '../load-more';
import { ToggleHiddenGroup } from '../toggle-hidden-group';
import { BoardGroupHeader } from './board-group-header';
import { GroupCard } from './card';
import { HiddenGroups } from './hidden-groups';

interface BoardSubGroup {
  tableGroup: SubTableGroupData;
  className?: string;
}

export const BoardSubGroups = () => {
  const { viewId, embed, readonly, managerReadonly, isLocked } = useBitable();
  const tableGroups = useBiTableGroups(viewId);
  const handleGroupSort = useHandleGroupSort();

  const pageRef = usePageScrollRef();
  const [scrollContainer, setScrollContainer] = useState(pageRef?.current);
  useEffect(() => {
    setScrollContainer(pageRef?.current);
  }, [scrollContainer, pageRef]);

  if (!scrollContainer) return null;

  if (!tableGroups) return null;

  const { visibleGroups, visibleSubGroups = [], hiddenSubGroups = [] } = tableGroups;
  return (
    <div className="flex border-t border-grey6 pt-2">
      <div>
        <SortableList
          className={'flex pb-2'}
          isHorizontalAxis
          appendChild={<AddGroup />}
          disabled={managerReadonly || isLocked}
          strategy={horizontalListSortingStrategy}
          modifiers={[restrictToHorizontalAxis, restrictToParentElement]}
          items={visibleGroups.map((group) => ({ id: group.value, ...group }))}
          onChange={(_, event) => handleGroupSort(viewId, event)}
          renderItemContent={({ item }) => {
            return (
              <BoardGroupHeader groupName={item.value} recordIds={item.recordIds} isSubGroup />
            );
          }}
        />

        {isEnableVirtuoso(visibleSubGroups.length) ? (
          <Virtuoso
            customScrollParent={scrollContainer}
            overscan={OVERSCAN}
            increaseViewportBy={OVERSCAN}
            skipAnimationFrameInResizeObserver
            computeItemKey={(index) => index}
            totalCount={visibleSubGroups.length}
            itemContent={(index) => {
              const tableGroup = visibleSubGroups[index];
              if (!tableGroup) return null;
              return <BoardHorizontalGroup key={tableGroup.value} tableGroup={tableGroup} />;
            }}
          />
        ) : (
          <>
            {visibleSubGroups.map((tableGroup) => (
              <BoardHorizontalGroup key={tableGroup.value} tableGroup={tableGroup} />
            ))}
          </>
        )}

        <ToggleHiddenGroup
          hiddenGroupsLength={hiddenSubGroups.length}
          hiddenGroups={
            hiddenSubGroups.length > 100 ? (
              <Virtuoso
                customScrollParent={scrollContainer}
                overscan={OVERSCAN}
                increaseViewportBy={OVERSCAN}
                skipAnimationFrameInResizeObserver
                computeItemKey={(index) => index}
                totalCount={hiddenSubGroups.length}
                itemContent={(index) => {
                  const tableGroup = hiddenSubGroups[index];
                  if (!tableGroup) return null;
                  return <BoardHorizontalGroup key={tableGroup.value} tableGroup={tableGroup} />;
                }}
              />
            ) : (
              <>
                {hiddenSubGroups.map((tableGroup) => (
                  <BoardHorizontalGroup key={tableGroup.value} tableGroup={tableGroup} />
                ))}
              </>
            )
          }
        />

        <AddGroup isSubGroup={true} />

        {!readonly && !embed && <Selector type="collection" />}
      </div>

      <HiddenGroups />
    </div>
  );
};

const BoardHorizontalGroup: FC<BoardSubGroup> = memo((props) => {
  const { tableGroup } = props;
  const { viewId } = useBitable();
  const isUnFolded = useTableGroupIsUnFolded(viewId, tableGroup.value);

  return (
    <div className={'pb-5'}>
      <GroupHeader isSubGroup={true} tableGroup={tableGroup} />

      {isUnFolded && <SubGroupList tableGroup={tableGroup} />}
    </div>
  );
}, deepEqual);

const SubGroupList: FC<{ tableGroup: SubTableGroupData }> = ({ tableGroup }) => {
  return (
    <div className="flex">
      {tableGroup.groups.map((item) => {
        return (
          <BitableLoadMoreContext recordIds={item.recordIds}>
            <Group
              key={item.value}
              groups={item}
              subGroupValue={tableGroup.value}
              subGroupProperty={tableGroup.groupProperty}
            />
          </BitableLoadMoreContext>
        );
      })}
    </div>
  );
};

const Group: FC<{ groups: TableGroupData; subGroupValue: string; subGroupProperty: string }> = ({
  groups,
  subGroupValue,
  subGroupProperty,
}) => {
  const { viewId } = useBitable();
  const groupSchema = useGroupPropertySchema(viewId);
  const pageRef = usePageScrollRef();
  const { recordIds: renderIds } = useBitableLoadMoreContext();
  const isEnableBoardColor = useEnableBoardColor(viewId);
  const cardSize = useCardSize(viewId);
  const imageDir = useCardImageDir(viewId);
  const hoveringEmpty = useHoveringEmpty(viewId);
  const { recordIds, value, visible, groupProperty } = groups;

  if (!pageRef.current) return null;
  if (!visible) return null;

  const option = groupSchema?.options?.find((option) => option.value === value);

  return (
    <div
      key={value}
      data-is-empty={recordIds.length === 0 ? true : null}
      data-is-board
      data-group-property={groupProperty}
      data-group-value={value}
      data-subgroup-property={subGroupProperty}
      data-sub-group-value={subGroupValue}
      data-view-id={viewId}
      className={cx(
        'relative group flex flex-col items-center rounded-md p-[5px] mr-2.5 min-h-[45px]',
        ((isEnableBoardColor && !option?.color) || !isEnableBoardColor) && 'bg-grey/5'
      )}
      style={{
        width: getCardSize(cardSize, imageDir) + 10,
        backgroundColor: isEnableBoardColor
          ? getBoardGroupBackgroundColor(option?.color)
          : undefined,
      }}
    >
      <Virtuoso
        className="w-full"
        customScrollParent={pageRef.current}
        overscan={OVERSCAN}
        increaseViewportBy={OVERSCAN}
        skipAnimationFrameInResizeObserver
        computeItemKey={(index) => index}
        totalCount={renderIds.length}
        itemContent={(index) => {
          const recordId = renderIds[index];
          if (!recordId) return null;
          return (
            <div className="py-1" key={recordId} data-card-id={recordId}>
              <GroupCard recordId={recordId} groupValue={value} subGroupValue={subGroupValue} />
            </div>
          );
        }}
      />

      {hoveringEmpty && (
        <div className="absolute top-0 left-0 w-full h-1 bg-black/30 opacity-0 group-hover:opacity-100"></div>
      )}

      <BitableLoadMore />

      <AddRecord
        where={{ after: recordIds[recordIds.length - 1] }}
        groupName={value}
        subGroupName={subGroupValue}
        color={isEnableBoardColor ? option?.color : undefined}
      />
    </div>
  );
};
