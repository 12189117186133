import type { FC } from 'react';
import { useState } from 'react';
import { SearchUser } from './search-user';
import { ShareMain } from './share-main';
import { SharePassword } from './share-password';
import { ShareUserPermissionList } from './share-user-permission-list';
import type { ShareProps } from './types';
import { Panel } from './types';
import { SharePay } from './share-pay';

const SHARE_PANEL_OFFSET = 400;

export const SharePanel: FC<Pick<ShareProps, 'uuid'>> = ({ uuid }) => {
  const [x, setX] = useState(0);
  const [panel, setPanel] = useState<Panel>(Panel.SEARCH_USER);

  const onChangeTab = (panel: Panel) => {
    setPanel(panel);
    setX(-SHARE_PANEL_OFFSET);
  };

  return (
    <div
      className="sm:mx-3 sm:w-[calc(100vw-24px)] next-modal overflow-hidden"
      style={{ width: SHARE_PANEL_OFFSET }}
    >
      <div className="flex duration-100 ease-in-out" style={{ transform: `translateX(${x}px)` }}>
        <Tab active={x === 0}>
          <ShareMain uuid={uuid} onChangeTab={onChangeTab} />
        </Tab>
        <Tab active={x === -SHARE_PANEL_OFFSET}>
          {panel === Panel.SEARCH_USER && (
            <SearchUser uuid={uuid} backToMainPanel={() => setX(0)} />
          )}
          {panel === Panel.ALL_USERS && (
            <ShareUserPermissionList uuid={uuid} backToMainPanel={() => setX(0)} />
          )}
          {panel === Panel.PASSWORD && (
            <SharePassword uuid={uuid} backToMainPanel={() => setX(0)} />
          )}
          {panel === Panel.PAY && <SharePay uuid={uuid} backToMainPanel={() => setX(0)} />}
        </Tab>
      </div>
    </div>
  );
};

interface Props {
  active: boolean;
}

export const Tab: FC<Props> = ({ active, children }) => {
  return (
    <div className="flex-shrink-0" style={{ width: SHARE_PANEL_OFFSET }}>
      {active && children}
    </div>
  );
};
