import { useCallback, useEffect, useState } from 'react';
import { useOpenModal } from 'src/common/components/next-modal';
import { useBlockLocked } from 'src/hooks/block/use-block-locked';
import { useCurrentSpace } from 'src/hooks/space';
import { useIsFreeSpaceLimit } from 'src/hooks/space/use-is-pro-space';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { bizTracker } from 'src/utils/biz-tracker';
import { usePickBlock } from 'src/utils/pick-block';
import { OpenSettingFrom, SettingMenuType } from 'src/views/main/setting-modal/type';
import { useOpenSettingModal } from 'src/views/main/setting-modal/use-open-setting-modal';
import { convertSegmentTypeFromSuggest } from '../components/helper';
import type { OtherPage, SuggestProps, SuggestState } from '../types';

export const useSuggest = ({
  uuid,
  setState,
  type,
}: SuggestProps & { type: keyof SuggestState }) => {
  const openSettingModal = useOpenSettingModal();
  const currentSpace = useCurrentSpace();
  const [pages, setPages] = useState<OtherPage>();
  const block = usePickBlock(uuid, ['subNodes', 'data'], ['segments']);
  const [show, setShow] = useState(true);
  const isPro = !useIsFreeSpaceLimit();
  const isLocked = useBlockLocked(uuid);
  const openModal = useOpenModal();
  const transaction = useTransaction();

  const upgrade = useCallback(() => {
    if (isPro) return false;
    openSettingModal({
      defaultMenuType: SettingMenuType.upgrade,
      from: OpenSettingFrom.link_suggest,
    });
    return true;
  }, [isPro, openSettingModal]);

  const pageIds = Object.keys(pages ?? {}).slice(0, isPro ? Infinity : 2);

  useEffect(() => {
    if (!pages) return;
    setState((pre) => ({
      ...pre,
      [type]: !!pageIds.length,
    }));
  }, [pages, pageIds.length, setState, type]);

  const linkAll = useCallback(() => {
    bizTracker.event(
      type === 'mention'
        ? 'sug_reference_current_page_link_all'
        : 'sug_current_reference_others_link_all'
    );

    openModal.warning({
      title: '确定建立全部引用？',
      content: '建立后，原纯文字位置将变为引用页面',
      confirm: () => {
        transaction(() => {
          pageIds.forEach((id) => {
            const list = pages?.[id];
            if (list) {
              list.forEach(({ uuid, segments }) => {
                updateBlock(uuid, {
                  data: { segments: convertSegmentTypeFromSuggest(segments) },
                });
              });
            }
          });
        });
        setPages({});
      },
    });
  }, [type, openModal, pageIds, pages, transaction]);

  return {
    upgrade,
    spaceId: currentSpace.uuid,
    show,
    setShow,
    block,
    setPages,
    pages,
    pageIds,
    isPro,
    linkAll,
    isLocked,
  };
};
