import { globalBaseImage, globalDarkImage } from '@flowus/common/images';
import { VITE_CDN_HOST } from 'src/env';
export const ossImagesPath = `${VITE_CDN_HOST}fe-web-app-images`;

const darkImage = {
  ...globalDarkImage,
};

const baseImage = {
  ...globalBaseImage,
  activityDialogDiver: new URL('./activity/diver-line.svg', import.meta.url).href,
  activityDialogDesc: new URL('./activity/desc.svg', import.meta.url).href,
  activityDialogDescTeam: new URL('./activity/desc-small-team.svg', import.meta.url).href,
  s102SettingHornIcon: new URL('./activity/s102SettingHornIcon.png', import.meta.url).href,
};

/** 私有化部署调整 */
if (__PRIVATE__) {
  const logoUrl = `${ossImagesPath}/private/logo.png`;
  const loadingUrl = `${ossImagesPath}/private/loading.gif`;

  Object.assign(baseImage, {
    logo: logoUrl,
    logoWhite: logoUrl,
    logoBlackWide: logoUrl,
    logoWhiteWide: logoUrl,
    loadingGif: loadingUrl,
  });
}

export const Images = {
  reload: (isDarkMode: boolean) => {
    if (isDarkMode) {
      Object.assign(Images, darkImage);
    } else {
      Object.assign(Images, baseImage);
    }
  },
  ...baseImage,
};
