import { sleep } from '@flowus/common/async';
import { BlockType } from '@next-space/fe-api-idl';
import type { Timeout } from 'ahooks/lib/useRequest/src/types';
import { useCallback, useRef } from 'react';
import { isWindows } from 'react-device-detect';
import { message } from 'src/common/components/message';
import { getAncestorsToPageLike } from 'src/hooks/block/use-get-ancestors';
import { isLikeFoldBlockAndInFold, patchExpand } from 'src/redux/managers/ui/use-fold';
import { Deferred } from 'src/utils/async-utils';
import { getElementToBodyDistance, querySelectorFromMainContent } from 'src/utils/dom';
import { useIsInRight } from 'src/utils/right-utils';
import { usePageLazyListLoading, usePageVirtualListMode } from './context';

export const useJumpTop = () => {
  const pageLazyListLoading = usePageLazyListLoading();
  const pageVirtualListMode = usePageVirtualListMode();
  const isInRight = useIsInRight();
  const isDeepScrolling = useRef(false);
  const deepTimer = useRef<Timeout | null>(null);

  return useCallback(
    async function jumpTop(
      blockId: string,
      options?: {
        animation?: boolean;
        num?: number;
        callback?: (type: 'animateStart' | 'animateScrollStart' | 'animateScrollEnd') => void;
      }
    ) {
      const { num = 0, callback, animation = true } = options || {};

      if (num > 5) {
        if (pageLazyListLoading) {
          message.warning('页面尚未加载完成，请稍后重试');
        }
        return;
      }

      /** 获取blockNode */
      const getBlockNode = (id: string) => {
        const node = querySelectorFromMainContent(
          `.next-space-page [data-block-id="${id}"]`,
          isInRight
        ) as HTMLElement | null;
        return node;
      };

      /** 获取block Node到body的距离 */
      const getBlockNodeTop = () => {
        const node = getBlockNode(blockId);
        if (node) {
          return getElementToBodyDistance(node).top;
        }
        return null;
      };

      /** 是不是虚拟列表的滚动，采取对应措施防止跳转抽搐眼花 */
      let isVirtualScrollBy = false;

      if (
        pageVirtualListMode.isVirtualListMode &&
        pageVirtualListMode.virtualRef &&
        !getBlockNode(blockId)
      ) {
        const ancestors = getAncestorsToPageLike(blockId);
        const idx = pageVirtualListMode.subNodes.findIndex((i) => {
          return ancestors.find((b) => i === b.uuid);
        });

        if (idx === -1) {
          return;
        }
        callback?.('animateScrollStart');

        const deferred = new Deferred();
        pageVirtualListMode.virtualRef.current?.scrollIntoView({
          index: idx,
          align: 'start',
          behavior: 'auto',
          done: () => {
            deferred.resolve();
          },
        });
        await deferred.promise;
        await sleep(10);

        isVirtualScrollBy = true;
      }

      isDeepScrolling.current = false;
      deepTimer.current && clearTimeout(deepTimer.current);

      const highlightDomAnimate = () => {
        const bg = document.querySelector(
          `[data-block-id="${blockId}"] .block-background`
        ) as HTMLElement | null;
        const blockDom = document.querySelector(
          `[data-block-id="${blockId}"]:not([data-toc-type])`
        ) as HTMLElement | null;

        // 一般情况下，不存在这个情况
        // 背景色dom存在，直接高亮闪烁动画
        if (bg) {
          bg.setAttribute('style', 'opacity: 100');
          bg.classList.add('animate__flash');
          void sleep(1300).then(() => {
            bg.classList.remove('animate__flash');
            bg.style.opacity = '';
          });
          callback?.('animateStart');
          // dom不存在，就插一个进去
        } else if (blockDom) {
          const _div = document.createElement('div');
          _div.setAttribute(
            'class',
            'absolute inset-0 block-background animate__animated pointer-events-none bg-active_color_10 transition-opacity rounded-sm animate__flash'
          );
          void sleep(1300).then(() => blockDom.removeChild(_div));
          void sleep(1000).then(() => callback?.('animateScrollEnd'));
          blockDom.appendChild(_div);
          callback?.('animateScrollStart');
        }
      };

      const nodeTop = getBlockNodeTop();
      const spacePage = querySelectorFromMainContent(`.next-space-page`, isInRight) as HTMLElement;

      if (nodeTop !== null) {
        const runJump = async (toTop: number, deep: number) => {
          if (deep > 2) return;
          if (typeof spacePage.scrollTo === 'function') {
            if (isWindows) {
              spacePage.scrollTo({ top: toTop, behavior: 'auto' });
            } else if (Math.abs(toTop - spacePage.scrollTop) > 800 && !isVirtualScrollBy) {
              let _nodeTop = toTop;
              if (spacePage.scrollTop > toTop) {
                _nodeTop = _nodeTop + 50;
              } else {
                _nodeTop = _nodeTop - 50;
              }
              spacePage.scrollTo({ top: _nodeTop, behavior: 'auto' });
              spacePage.scrollTo({ top: toTop, behavior: 'smooth' });
            } else {
              spacePage.scrollTo({ top: toTop, behavior: isVirtualScrollBy ? 'auto' : 'smooth' });
            }
          } else if (spacePage.scrollTop) {
            spacePage.scrollTop = toTop;
          } else {
            return;
          }

          await sleep(200);

          if (animation) {
            highlightDomAnimate();
          }

          if (isDeepScrolling.current) return;

          const correctNodeTop = getBlockNodeTop();
          if (correctNodeTop) {
            const difference = Math.abs(correctNodeTop - spacePage.scrollTop);
            if (difference > 30 && spacePage.scrollTop !== correctNodeTop && correctNodeTop > 0) {
              isDeepScrolling.current = true;
              void runJump(correctNodeTop - 100, deep++);
              deepTimer.current = setTimeout(() => {
                isDeepScrolling.current = false;
              }, 1000);
            }
          }
        };
        void runJump(nodeTop - 100, 0);
      } else {
        const ancestors = getAncestorsToPageLike(blockId);
        const folds = ancestors.filter(
          (b) => [BlockType.TEMPLATE].includes(b.type) || isLikeFoldBlockAndInFold(b.uuid)
        );
        if (folds.length !== 0) {
          patchExpand(
            folds.map((i) => i.uuid),
            true
          );
          setTimeout(() => {
            void jumpTop(blockId, {
              num: num + 1,
              ...options,
            });
          }, 500);
        }
      }
    },
    [
      isInRight,
      pageLazyListLoading,
      pageVirtualListMode.isVirtualListMode,
      pageVirtualListMode.subNodes,
      pageVirtualListMode.virtualRef,
    ]
  );
};
