import { deepEqual, Role } from '@flowus/common';
import { getIconsColor } from '@flowus/common/block/color/get-block-color';
import { cx } from '@flowus/common/cx';
import { useMindMapId } from '@flowus/mind-map';
import { BlockType, PermissionRole } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { memo, useEffect, useMemo, useState } from 'react';
import type { MetaColor } from 'src/bitable/const';
import { COLORS } from 'src/bitable/const';
import { ColorSelector } from 'src/bitable/table-view/cell/select/color-selector';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { useScrollerChange } from 'src/common/hooks/use-scroller-change';
import { ColorPicker } from 'src/components/color-picker';
import { DEFAULT_COLOR_PICKER_DATA } from 'src/components/color-picker/default-data';
import { MindNodeTurnWhiteList } from 'src/editor/component/menu-list/const';
import { TransformBlockList } from 'src/editor/component/menu-list/transform-block-list';
import { AIEditorFrom, AIEditorScene } from 'src/editor/editor/uikit/ai-editor/const';
import { useOpenAIEditor } from 'src/editor/editor/uikit/ai-editor/use-open-ai-editor';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import { textToSegments } from 'src/editor/utils/editor';
import { FeatureFlags } from 'src/feature-flags';
import { useEnableAI } from 'src/hooks/block/use-enable-AI';
import { useOpenUpgradeSpaceIfMindNodeLimit } from 'src/hooks/block/use-open-upgrade-space-if-mind-node-limit';
import { useReadonly } from 'src/hooks/page';
import { useIsDragging } from 'src/hooks/page/use-dnd/hooks';
import { getPermissions } from 'src/hooks/share/use-permissions';
import { useTransaction } from 'src/hooks/use-transaction';
import { getMediaInfo } from 'src/hooks/utils/use-get-media-info';
import { addBlock } from 'src/redux/managers/block/add';
import { convertBlock } from 'src/redux/managers/block/convert';
import { updateBlock } from 'src/redux/managers/block/update';
import { cache } from 'src/redux/store';
import { useAllowCommentInSharePage, useIsSelecting } from 'src/services/app';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';
import { bizTracker } from 'src/utils/biz-tracker';
import { isCollection } from 'src/utils/block-type-utils';
import { supportComments } from 'src/utils/block-utils';
import { querySelectorFromMainContent } from 'src/utils/dom';
import { parseEditorKeyToBlockId } from 'src/utils/editor-key-to-id';
import { getDynamicPageId, judgeSharePage } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { useOpenNewDiscussionPopup } from 'src/views/comments/use-open-new-discussion-popup';
import { usePageScrollRef } from 'src/views/main/page-doc/context';
import { PageScene, usePageScene } from 'src/views/main/scene-context';
import { useMindNodeColor } from '../hook/use-mind-node-style';
import { CanAppendAttachmentBlockList } from '../utils/const';
import { findEditorModel } from '../utils/mind-node-util';
import { ColorIcon } from './color-icon';
import { MindNodeEditorToolbar } from './mind-node-editor-toolbar';
import { MoreNodeMenu } from './more-node-menu';
import { ToolButtonWrapper } from './tool-button-wrapper';
import { UploadIcon } from './upload-icon';

const PADDING = 10;
const BOTTOM_TOOLBAR_HEIGHT = 42;
interface Props {
  id: string;
}
/** 导图底部toolbar */
export const BottomToolBar: FC<Props> = memo((props) => {
  const selectedBlocks = useObservableStore((state) => state.ui.selectedBlocks, [], {
    obsSelectBlocks: [{ all: true }],
  });
  const transaction = useTransaction();
  const isRootNode =
    selectedBlocks.length === 1 && selectedBlocks.some((s) => s.blockId === props.id);
  const getEditorModel = useGetOrInitEditorModel();
  const [controlTooltipVisible, setControlTooltipVisible] = useState<boolean | undefined>(
    undefined
  );
  const { enableAI } = useEnableAI();
  const isInMain = usePageScene() === PageScene.MAIN;

  const mindMapId = useMindMapId();
  const readonly = useReadonly(mindMapId);
  const disabledAttachmentBtn = useObservableStore(
    (state) => {
      if (state.ui.selectedBlocks.length === 1) {
        const blockId = state.ui.selectedBlocks[0]?.blockId ?? '';
        const block = state.blocks[blockId];
        if (block) return !CanAppendAttachmentBlockList.includes(block.type);
      }
      return true;
    },
    [],
    { hasSelectBlock: true }
  );

  const onlyOneSelected = selectedBlocks.length === 1;
  const maybeOpenUpgradeSpace = useOpenUpgradeSpaceIfMindNodeLimit();
  const openNewDiscussionPopup = useOpenNewDiscussionPopup();

  const block = usePickBlock(selectedBlocks[0]?.blockId, []);
  const nodeColor = useMindNodeColor(selectedBlocks[0]?.blockId ?? '');
  const allowCommentInSharePage = useAllowCommentInSharePage();
  const supportComment = supportComments(block);
  const roleCanComment = Role.contains(getPermissions(block?.uuid).role, PermissionRole.COMMENTER);
  const isShare = judgeSharePage();
  const canCommentInNormal = !isShare && supportComment && roleCanComment && isInMain;
  const canCommentInShare = isShare && supportComment && roleCanComment && allowCommentInSharePage;
  const pageScroll = usePageScrollRef();
  const isSelecting = useIsSelecting();
  const isDragging = useIsDragging();
  const canComment = canCommentInNormal || canCommentInShare;
  const scrollIfo = useScrollerChange(pageScroll);
  const [update, setUpdate] = useState(0); // 某些情况下需要强制刷新
  const style = useMemo(() => {
    // 单个节点选中的时候，底部栏需要显示在节点下方，多个几点选中则显示在屏幕下方
    if (isSelecting || !onlyOneSelected) return;
    const selectedElement = pageScroll.current?.querySelector(`[data-block-id="${block?.uuid}"]`);
    const scrollerRect = pageScroll.current?.getBoundingClientRect();
    if (selectedElement instanceof HTMLElement && scrollerRect) {
      const rect = selectedElement?.getBoundingClientRect();
      return {
        left: rect.left - (scrollerRect?.left ?? 0),
        top: rect.top - BOTTOM_TOOLBAR_HEIGHT - PADDING,
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block?.uuid, isSelecting, onlyOneSelected, pageScroll, scrollIfo, update]);

  // const childrenIds = useMindMapSelector((state) => state.mindNodes[state.rootId]?.childrenIds);
  // // 是否显示跟随分支，有选中一级节点的就不显示
  // const showColorFooter = useMemo(() => {
  //   if (selectedBlocks.length === 0) return false;
  //   const set = new Set(childrenIds);
  //   set.add(props.id);
  //   return !selectedBlocks.some((s) => set.has(s.blockId));
  // }, [childrenIds, props.id, selectedBlocks]);
  const showColorFooter = useMemo(() => {
    if (selectedBlocks.length === 0) return false;
    return !selectedBlocks.some((s) => s.blockId === props.id);
  }, [props.id, selectedBlocks]);
  const openAIEditor = useOpenAIEditor();

  useEffect(() => {
    // fix:[选中节点，连按tab，菜单会跳到左下角]
    // 新建一个tab无法立刻获取到对应的元素，需要等会儿再更新style
    if (onlyOneSelected && block?.uuid) {
      setTimeout(() => {
        setUpdate((v) => {
          return ++v;
        });
      }, 100);
    }
  }, [block?.uuid, onlyOneSelected]);

  useEffect(() => {
    // 选择颜色后要隐藏掉color picker
    if (controlTooltipVisible === undefined) return;
    setControlTooltipVisible(undefined);
  }, [controlTooltipVisible]);

  const model = findEditorModel(selectedBlocks[0], getEditorModel);
  const [isSelectedText, setSelectedText] = useState(false);
  const writerOrEditorRole = useMemo(
    // copy from block menu
    () =>
      selectedBlocks.every((selectBlock) => {
        const { role, isRestricted } = getPermissions(selectBlock.blockId);
        // 转化为 如果是受限的块需要先恢复权限，所以必须是全部权限的才可以
        return isRestricted
          ? role === PermissionRole.EDITOR
          : role === PermissionRole.EDITOR || role === PermissionRole.WRITER;
      }),
    [selectedBlocks]
  );
  const allSelectedBlockTypeIsOk = (types: BlockType[]) => {
    return selectedBlocks.every((selectBlock) => {
      const _block = cache.blocks[selectBlock.blockId];
      if (!_block) return false;

      if (isCollection(cache.blocks[_block.parentId]?.type)) {
        return false;
      }

      return types.includes(_block.type);
    });
  };

  useEffect(() => {
    const subscribe = model?.onSelectionChange.subscribe(() => {
      setSelectedText(Boolean(model.selection && model.selection.length));
    });
    return () => {
      setSelectedText(false);
      subscribe?.unsubscribe();
    };
  }, [getEditorModel, model, selectedBlocks]);

  if (isSelectedText) return null;
  if (isSelecting) return null;
  if (isDragging) return null;
  if (selectedBlocks.length === 0) return null;
  if (onlyOneSelected && !style) return null;

  const hideTurnBlockBtn = !allSelectedBlockTypeIsOk(MindNodeTurnWhiteList) || !writerOrEditorRole;
  const hideColorBtn = block?.type === BlockType.CODE;

  const editorId =
    parseEditorKeyToBlockId(selectedBlocks[0]?.editorKey) || selectedBlocks[0]?.blockId || '';
  // 只有评论权限
  const onlyCanComment = readonly && canComment;

  if (onlyCanComment) {
    if (onlyOneSelected) {
      return (
        <div
          className={cx('absolute bottom-7 h-9', {
            'w-full': !style,
          })}
          data-no-cancel-selected
          style={style}
        >
          <div className="w-full h-full flex justify-center">
            <div className="flex items-center h-full next-modal">
              {enableAI && FeatureFlags.AI_MIND_MAP && onlyOneSelected && (
                <Tooltip appendTo="parent" placement="right" popup={`AI助手`}>
                  <ToolButtonWrapper
                    className="w-10 h-9"
                    onClick={() => {
                      const isInRight = getDynamicPageId('right') === props.id;
                      const node = querySelectorFromMainContent(
                        `[data-block-id="${block?.uuid}"]`,
                        isInRight
                      );
                      node &&
                        openAIEditor({
                          popcorn: node,
                          blockId: block?.uuid ?? props.id,
                          from: AIEditorFrom.mindMap,
                          editorScene: AIEditorScene.BlockSelected,
                          isMindMap: true,
                          showInput: false,
                        });
                    }}
                  >
                    <Icon name={'IcAi'} size="middle" />
                  </ToolButtonWrapper>
                </Tooltip>
              )}
              <ToolButtonWrapper
                className="w-10 h-full"
                onClick={() => {
                  openNewDiscussionPopup({ blockId: selectedBlocks[0]?.blockId ?? '' });
                }}
              >
                <Icon name={'IcCommentSmall'} size="small" />
              </ToolButtonWrapper>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
  const hideRichTextBtn = selectedBlocks.some((s) =>
    [BlockType.CODE, BlockType.EQUATION].includes(cache.blocks[s.blockId]?.type ?? 0)
  );

  return (
    <div
      className={cx('absolute bottom-7 h-9', {
        'w-full': !style,
      })}
      data-no-cancel-selected
      style={style}
    >
      <div className="w-full h-full flex justify-center">
        <div className="flex items-center h-full next-modal">
          {enableAI && FeatureFlags.AI_MIND_MAP && (
            <Tooltip appendTo="parent" placement="right" popup={`AI助手`}>
              <ToolButtonWrapper
                className="w-10 h-9"
                onClick={() => {
                  const node = document.querySelector(`[data-block-id="${block?.uuid}"]`);
                  node &&
                    openAIEditor({
                      popcorn: node,
                      blockId: block?.uuid ?? props.id,
                      from: AIEditorFrom.mindMap,
                      editorScene: AIEditorScene.BlockSelected,
                      isMindMap: true,
                      showInput: false,
                    });
                }}
              >
                <Icon name={'IcAi'} size="middle" />
              </ToolButtonWrapper>
            </Tooltip>
          )}
          {!isRootNode && (
            <Tooltip
              lazyLoad={true}
              delay={0}
              popupClass="next-modal"
              disabled={hideTurnBlockBtn}
              className="h-full"
              interactive={true}
              maxWidth={1000}
              theme="none"
              animation="shift-away"
              popup={
                <TransformBlockList showBg={false} blockId={selectedBlocks[0]?.blockId ?? ''} />
              }
            >
              <ToolButtonWrapper className="w-10" disabled={hideTurnBlockBtn}>
                <Icon name={'IcMenuTurnInto'} size="middle" />
              </ToolButtonWrapper>
            </Tooltip>
          )}
          {!isRootNode && (
            <Tooltip
              lazyLoad={true}
              delay={0}
              popupClass="next-modal"
              disabled={!model || hideRichTextBtn}
              className="h-full"
              interactive={true}
              maxWidth={1000}
              theme="none"
              animation="shift-away"
              popup={model && <MindNodeEditorToolbar uuid={editorId} model={model} />}
            >
              <ToolButtonWrapper className="w-10" disabled={!model || hideRichTextBtn}>
                <Icon name={'IcMenuTextstyle'} size="middle" />
              </ToolButtonWrapper>
            </Tooltip>
          )}

          <Tooltip
            delay={0}
            lazyLoad={true}
            disabled={hideColorBtn}
            className={cx('h-full', hideColorBtn && 'pointer-events-none opacity-30')}
            popupClass="next-modal"
            visible={controlTooltipVisible}
            interactive={true}
            maxWidth={1000}
            theme="none"
            animation="shift-away"
            popup={
              <>
                <div
                  onPointerDown={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <ColorPicker
                    showBg={false}
                    items={DEFAULT_COLOR_PICKER_DATA}
                    onColorSelect={(colorInfo) => {
                      setControlTooltipVisible(false);
                      transaction(() => {
                        selectedBlocks.forEach((b) => {
                          updateBlock(b.blockId, {
                            backgroundColor: colorInfo.isBgColor ? colorInfo.colorkey : '',
                            textColor: colorInfo.isBgColor ? '' : colorInfo.colorkey,
                          });
                        });
                      });
                      bizTracker.event('node_color');
                    }}
                    footer={
                      showColorFooter ? (
                        <div className="w-full p-4">
                          <div
                            className="cursor-pointer flex items-center rounded justify-center h-8 text-t2 border border-grey5 text-center w-full"
                            onClick={() => {
                              setControlTooltipVisible(false);
                              //  清空文字，背景颜色以及本身的主题颜色
                              transaction(() => {
                                selectedBlocks.forEach((b) => {
                                  const sb = cache.blocks[b.blockId];
                                  updateBlock(b.blockId, {
                                    backgroundColor: '',
                                    textColor: '',
                                    data: {
                                      format: {
                                        mindMappingFormat: {
                                          ...sb?.data.format?.mindMappingFormat,
                                          nodeThemeColor: '',
                                        },
                                      },
                                    },
                                  });
                                });
                              });
                            }}
                          >
                            跟随分支主题
                          </div>
                        </div>
                      ) : null
                    }
                  />
                </div>
              </>
            }
          >
            <ToolButtonWrapper className="w-9">
              <ColorIcon blockId={block?.uuid ?? ''} />
            </ToolButtonWrapper>
          </Tooltip>
          <Tooltip
            delay={0}
            lazyLoad={true}
            animation="shift-away"
            popupClass="next-modal"
            visible={controlTooltipVisible}
            className="h-full"
            interactive={true}
            maxWidth={1000}
            theme="none"
            popup={
              <>
                <div
                  className="w-[240px]"
                  onPointerDown={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <ColorSelector
                    className="pb-2"
                    header="分支颜色"
                    colors={IconColors}
                    value={nodeColor}
                    selectedIcon={
                      <Icon
                        name="IcSelectedSpace"
                        size="normal"
                        className={'text-[18px] text-white'}
                      />
                    }
                    onSelect={(color) => {
                      setControlTooltipVisible(false);
                      bizTracker.event('node_theme');
                      transaction(() => {
                        selectedBlocks.forEach((s) => {
                          updateBlock(s.blockId, {
                            data: {
                              format: {
                                mindMappingFormat: {
                                  ...cache.blocks[s.blockId]?.data.format?.mindMappingFormat,
                                  nodeThemeColor: color,
                                },
                              },
                            },
                          });
                        });
                      });
                    }}
                    bgColorFn={getIconsColor}
                  />
                </div>
              </>
            }
          >
            <ToolButtonWrapper className="w-9">
              <Icon
                name={'IcMenuBranch'}
                size="small"
                style={{
                  color: onlyOneSelected ? getIconsColor(nodeColor) : undefined,
                }}
              />
            </ToolButtonWrapper>
          </Tooltip>
          {/* 图片上传 */}
          {!isRootNode && onlyOneSelected && (
            <Tooltip
              data-no-cancel-selected
              popup={
                disabledAttachmentBtn ? '' : '上传图片（当前节点无法上传时，将创建同级节点上传）'
              }
              className="h-full"
            >
              <ToolButtonWrapper className="w-10" disabled={disabledAttachmentBtn}>
                <UploadIcon
                  blockId={selectedBlocks[0]?.blockId}
                  disabled={disabledAttachmentBtn}
                  accept="image/*,image/heic,image/heif"
                  iconName={'IcMenuImage'}
                  onSuccess={async (file, ossName) => {
                    const mediaInfo = await getMediaInfo(file);
                    const blockId = selectedBlocks[0]?.blockId;
                    if (!blockId) return;
                    const block = cache.blocks[blockId ?? ''];
                    if (!block) return;
                    transaction(() => {
                      if (block.type !== BlockType.FILE) {
                        convertBlock([blockId], {
                          type: BlockType.FILE,
                          data: {
                            display: 'image',
                            ossName,
                            caption: cache.blocks[blockId]?.data.segments,
                            ...mediaInfo,
                            size: file.size,
                            segments: textToSegments(file.name),
                          },
                        });
                      } else {
                        if (maybeOpenUpgradeSpace(mindMapId)) return;
                        addBlock(
                          {
                            type: BlockType.FILE,
                            data: {
                              display: 'image',
                              ossName,
                              caption: [],
                              ...mediaInfo,
                              size: file.size,
                              segments: textToSegments(file.name),
                            },
                          },
                          {
                            parentId: cache.blocks[blockId]?.parentId ?? '',
                            after: blockId,
                          }
                        );
                      }
                      bizTracker.event('node_image');
                    });
                  }}
                />
              </ToolButtonWrapper>
            </Tooltip>
          )}
          {/* 文件上传 */}
          {!isRootNode && onlyOneSelected && (
            <Tooltip
              popup={
                disabledAttachmentBtn ? '' : '上传附件(当前节点无法上传时，将创建同级节点上传)'
              }
              className="h-full"
            >
              <ToolButtonWrapper className="w-10" disabled={disabledAttachmentBtn}>
                <UploadIcon
                  blockId={selectedBlocks[0]?.blockId}
                  disabled={disabledAttachmentBtn}
                  iconName={'IcBlockFile'}
                  onSuccess={async (file, ossName) => {
                    const mediaInfo = await getMediaInfo(file);
                    const blockId = selectedBlocks[0]?.blockId;
                    if (!blockId) return;
                    const block = cache.blocks[blockId ?? ''];
                    if (!block) return;
                    transaction(() => {
                      if (block.type !== BlockType.FILE) {
                        if (block.subNodes.length === 0) {
                          convertBlock([blockId], {
                            type: BlockType.FILE,
                            data: {
                              display: 'file',
                              ossName,
                              caption: cache.blocks[blockId]?.data.segments,
                              ...mediaInfo,
                              size: file.size,
                              segments: textToSegments(file.name),
                            },
                          });
                          bizTracker.event('node_file');
                          return;
                        }
                      }
                      // 同级节点添加
                      if (maybeOpenUpgradeSpace(mindMapId)) return;
                      addBlock(
                        {
                          type: BlockType.FILE,
                          data: {
                            display: 'file',
                            ossName,
                            caption: [],
                            ...mediaInfo,
                            size: file.size,
                            segments: textToSegments(file.name),
                          },
                        },
                        {
                          parentId: cache.blocks[blockId]?.parentId ?? '',
                          after: blockId,
                        }
                      );
                      bizTracker.event('node_file');
                    });
                  }}
                />
              </ToolButtonWrapper>
            </Tooltip>
          )}
          {!isRootNode && <div className="h-full w-px bg-grey6" />}

          {!isRootNode && (
            <Tooltip
              lazyLoad={true}
              delay={0}
              popupClass="next-modal"
              interactive={true}
              maxWidth={1000}
              theme="none"
              className="h-full"
              animation="shift-away"
              popup={<MoreNodeMenu />}
            >
              <ToolButtonWrapper className="w-10 h-full">
                <Icon name={'IcMoreSmallBold'} size="small" />
              </ToolButtonWrapper>
            </Tooltip>
          )}
          {onlyOneSelected && (
            <>
              <div className="h-full w-px bg-grey6" />
              <ToolButtonWrapper
                className="w-10 h-full"
                onClick={() => {
                  openNewDiscussionPopup({ blockId: selectedBlocks[0]?.blockId ?? '' });
                }}
              >
                <Icon name={'IcCommentSmall'} size="small" />
              </ToolButtonWrapper>
            </>
          )}
        </div>
      </div>
    </div>
  );
}, deepEqual);

const IconColors = COLORS.map((c) => {
  return { ...c, name: '' } as MetaColor;
});
IconColors.unshift({
  // @ts-ignore type check
  key: '',
  name: '',
});
