import { CaptchaComp } from '@flowus/login/uikit/web/captcha';
import { md5Sum, Regex } from '@flowus/login/utils';
import { CaptchaSendType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useState } from 'react';
import { Button } from 'src/common/components/button';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { request } from 'src/common/request';
import { useCurrentUser } from 'src/hooks/user';

export enum SettingPasswordType {
  BY_PHONE = 0,
  BY_OLD_PASSWORD = 1,
}
interface Props {
  type: SettingPasswordType;
  phone?: string;
  onSuccess: () => void;
}

export const SettingPassword: FC<Props> = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const {
    password,
    passwordError,
    newPassword,
    newPasswordError,
    verifyCode,
    setVerifyCode,
    verifyCodeError,
    setPassword,
    setNewPassword,
    settingPassword,
  } = useSettingPassword(props.type, props.phone);

  if (props.type === SettingPasswordType.BY_PHONE && props.phone) {
    return (
      <div className="next-modal p-7">
        <div className="text-t1-medium ">设置密码</div>
        <Input
          inputType={showPassword ? 'text' : 'password'}
          warning={passwordError}
          className="w-full h-9 mt-5"
          inputClassName="opacity-50"
          value={password}
          placeholder="请输入新的密码"
          onChange={setPassword}
          addonAfter={
            <Icon
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowPassword(!showPassword);
              }}
              name={showPassword ? 'IcMenuItemHide' : 'IcMenuItemShow'}
              className="cursor-pointer absolute right-2.5 text-grey3"
              size="middle"
            />
          }
        />
        <div className="text-t2 text-grey3 mt-2.5">
          6-16位密码，需包含数字、英文大写字母和小写字母
        </div>
        <Input
          readonly={true}
          className="w-full h-9 mt-5"
          inputClassName="opacity-50"
          value={props.phone}
        />
        <Input
          autoFocus
          type="number"
          value={verifyCode}
          maxLength={6}
          warning={verifyCodeError}
          className="w-full h-9 mt-5 px-3"
          inputClassName="opacity-50 px-0"
          placeholder="请输入验证码"
          onChange={(v) => setVerifyCode(v.toString())}
          addonAfter={
            <CaptchaComp
              onSendVerifyCode={() => {
                return request.infra.createPhoneCaptcha(props.phone ?? '', {
                  type: CaptchaSendType.RESET_PASSWORD,
                });
              }}
            />
          }
        />
        <Button
          onClick={async () => {
            const success = await settingPassword();
            if (success) props.onSuccess();
          }}
          colorType="active"
          className="mt-6"
        >
          完成绑定
        </Button>
      </div>
    );
  }
  // 已经设置过的，需要用老密码新密码重新设置
  return (
    <div className="px-7 pb-7">
      <div className="text-t4 text-grey3 mt-9">原密码</div>
      <Input
        inputType={showPassword ? 'text' : 'password'}
        warning={passwordError}
        className="w-full h-9 mt-5"
        inputClassName="opacity-50"
        value={password}
        placeholder="请输入原密码"
        onChange={setPassword}
        addonAfter={
          <Icon
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowPassword(!showPassword);
            }}
            name={showPassword ? 'IcMenuItemHide' : 'IcMenuItemShow'}
            className="cursor-pointer absolute right-2.5 text-grey3"
            size="middle"
          />
        }
      />
      <div className="text-t4 text-grey3 mt-6">新密码</div>
      <Input
        inputType={showNewPassword ? 'text' : 'password'}
        warning={newPasswordError}
        className="w-full h-9 mt-5"
        inputClassName="opacity-50"
        value={newPassword}
        placeholder="请输入新的密码"
        onChange={setNewPassword}
        addonAfter={
          <Icon
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowNewPassword(!showNewPassword);
            }}
            name={showNewPassword ? 'IcMenuItemHide' : 'IcMenuItemShow'}
            className="cursor-pointer absolute right-2.5 text-grey3"
            size="middle"
          />
        }
      />
      <div className="text-t2 text-grey3 mt-2.5">
        6-16位密码，需包含数字、英文大写字母和小写字母
      </div>
      <Button
        onClick={async () => {
          const success = await settingPassword();
          if (success) props.onSuccess();
        }}
        colorType="active"
        className="mt-6"
      >
        设置密码
      </Button>
    </div>
  );
};

const useSettingPassword = (type: SettingPasswordType, mobile?: string) => {
  const [phone, _] = useState<string>(mobile ?? '');
  const [verifyCode, setVerifyCode] = useState('');
  const [verifyCodeError, setVerifyCodeError] = useState('');
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const currentUser = useCurrentUser();

  const checkVerifyCode = () => {
    if (!Regex.VerifyCode.test(verifyCode)) {
      setVerifyCodeError('验证码错误');
      return false;
    }
    return true;
  };
  const checkPassword = () => {
    if (!password || !Regex.Password.test(password)) {
      setPasswordError('密码格式有误');
      return false;
    }
    return true;
  };
  const checkNewPassword = () => {
    if (!newPassword || !Regex.Password.test(newPassword)) {
      setNewPasswordError('密码格式有误');
      return false;
    }
    return true;
  };
  /** 设置密码 */
  const settingPassword = async () => {
    if (!checkPassword()) return;
    if (type === SettingPasswordType.BY_PHONE) {
      if (!checkVerifyCode()) return;
      return request.infra.resetUserPasswordByPhone(phone, {
        captcha: verifyCode,
        password: md5Sum(password),
      });
    } else if (checkNewPassword()) {
      const res = await request.infra.resetUserPassword(currentUser.uuid, {
        old: md5Sum(password),
        new: md5Sum(newPassword),
      });
      return res;
    }
  };
  return {
    phone,
    password,
    passwordError,
    newPassword,
    newPasswordError,
    verifyCode,
    verifyCodeError,
    setPassword,
    setNewPassword,
    settingPassword,
    setVerifyCode,
  };
};
