import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { useMemo } from 'react';
import { Button } from 'src/common/components/button';
import { useModel } from 'src/common/create-model';
import { useCurrentSpace } from 'src/hooks/space';
import { useCurrentSpaceUsers } from 'src/hooks/space/use-current-space-users';
import { useIsProSpace } from 'src/hooks/space/use-is-pro-space';
import { useIsTeamSpace } from 'src/hooks/space/use-is-team-space';
import { useGuestsList } from 'src/services/app';
import { useCurrentSpacePlanInfo } from 'src/services/capacity/space-plans';
import { SettingModalCommon, SettingProvider } from '../common';
import { Option } from '../option';
import { OpenSettingFrom, SettingMenuType } from '../type';

export const SpaceInfoMembers: FC = () => {
  const { spacePlan } = useCurrentSpacePlanInfo();
  const { setCurrentSettingMenu, setIsFrom } = useModel(SettingProvider);
  const isProSpace = useIsProSpace();
  const users = useCurrentSpaceUsers();
  const currentSpace = useCurrentSpace();
  const spaceUser = Object.values(users);
  const guests = useGuestsList();
  const isTeamSpace = useIsTeamSpace();
  const diver = <span className="mx-4 text-grey3 text-t2">|</span>;

  const [isMaxGuests, isMaxPeople] = useMemo(() => {
    const maxPeople = isTeamSpace ? spaceUser.length >= (spacePlan?.maxPeople || 1) : false;

    if (isProSpace) {
      return [false, maxPeople];
    }
    return [guests.length >= 5, maxPeople];
  }, [spacePlan?.maxPeople, guests.length, isProSpace, isTeamSpace, spaceUser.length]);

  if (__PRIVATE__) return null;

  return (
    <>
      <Option
        title="空间成员与外部协作者"
        description={
          <SettingModalCommon.Item
            leftChild={
              <div className="flex whitespace-nowrap mr-2">
                <div>
                  {'空间成员'}&nbsp;
                  <span className={cx(isMaxPeople && 'text-red')}>
                    {spaceUser.length}/{spacePlan?.maxPeople || 1}
                  </span>
                </div>
                {diver}
                {isTeamSpace && (
                  <>
                    <div className="flex">
                      {'空间成员组'}&nbsp;
                      <span>{currentSpace.permissionGroups?.length || 0}</span>
                    </div>
                    {diver}
                  </>
                )}
                <div>
                  {'外部协作者'}&nbsp;
                  <span className={cx(isMaxGuests && 'text-red')}>
                    {guests.length}
                    {isProSpace ? '' : '/5人'}
                  </span>
                </div>
              </div>
            }
            rightChild={
              <Button
                onClick={() => {
                  setCurrentSettingMenu(SettingMenuType.upgrade);
                  setIsFrom(OpenSettingFrom.dashboard_member);
                }}
              >
                升级扩容成员席位
              </Button>
            }
          />
        }
      />
    </>
  );
};
