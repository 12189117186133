import type { FC } from 'react';
import { useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { useCurrentUser } from 'src/hooks/user/use-current-user';
import { setLocalStorage } from 'src/utils/local-storage';

interface Props {
  describe: string;
  tip: string;
  link: string;
  type: 'LINK_TABS_CURRENT' | 'LINK_TABS_MENTION' | 'LINK_TABS_SUGGEST';
}

export const Tip: FC<Props> = ({ describe, tip, link, type }) => {
  const currentUser = useCurrentUser();
  const KEY = `TIPS::${currentUser.uuid}::${type}`;
  const [hidden, setHidden] = useState(!!localStorage.getItem(KEY));

  return (
    <div
      hidden={hidden}
      className="flex justify-between items-center space-x-8 text-t2 text-orange p-2 rounded bg-orange/10 mb-2"
    >
      <div>{describe}</div>
      <div className="flex flex-shrink-0 items-center space-x-4 text-t3">
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          onClick={(event) => {
            event.stopPropagation();
          }}
          className="flex items-center animate-click"
        >
          <Icon name="IcInviteHelp" size="xsmall" className="mr-1" />
          {tip}
        </a>
        <div className="w-px bg-orange/20 h-4"></div>
        <Icon
          name="IcTitleClose"
          size="xsmall"
          className="animate-click"
          onClick={() => {
            setLocalStorage(KEY, '1');
            setHidden(true);
          }}
        />
      </div>
    </div>
  );
};
