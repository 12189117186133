import { getDateStrBySegment } from '@flowus/common';
import { formatCheckBoxValue } from '@flowus/common/block/checkbox-value';
import { LOCAL_LNG } from '@flowus/common/const';
import { cx } from '@flowus/common/cx';
import { CollectionSchemaType, CollectionViewType, TextType } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';
import type { FC, ReactNode } from 'react';
import React from 'react';
import { useBitable } from 'src/bitable/context';
import { readUrlFromSegments } from 'src/bitable/table-view/cell/helpers';
import { renderNumProgress } from 'src/bitable/table-view/cell/num/render-num-process';
import { getRelationRecords } from 'src/bitable/table-view/cell/relation/get-relation-records';
import { Icon } from 'src/common/components/icon';
import { DATE_TIME_FORMAT } from 'src/common/const';
import { RichText } from 'src/editor/editor/uikit/editable/rich-text';
import { segmentsToText } from 'src/editor/utils/editor';
import { getViewFormat } from 'src/hooks/block/get-view-format';
import { getFormulaTool } from 'src/hooks/block/use-formula-tool';
import { useUpdatePropertyValue } from 'src/hooks/block/use-update-property-value';
import {
  useCollectionView,
  useShowPropertyName,
} from 'src/hooks/collection-view/use-collection-view';
import { useReadonly } from 'src/hooks/page';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { usePickBlock } from 'src/utils/pick-block';
import { getCellValueComponent } from '../../table-view/cell';
import { formatNumber } from '../../table-view/cell/num';
import { Tags } from '../../table-view/cell/select/tags';
import { Site } from '../../table-view/cell/types';

interface Props {
  recordId: string;
}

export const PropertyValues: FC<Props> = ({ recordId }) => {
  const { viewId, collectionId, viewType, tableCellWrap } = useBitable();
  const readonly = useReadonly(recordId);
  const updateValue = useUpdatePropertyValue();
  const view = useCollectionView(viewId);
  const block = usePickBlock(
    recordId,
    ['data', 'createdAt', 'updatedAt'],
    ['schema', 'collectionProperties']
  );
  const schema = useObservableBlock(collectionId, (block) => block?.data.schema);
  const formulaTool = getFormulaTool(collectionId);
  const showPropertyName = useShowPropertyName(viewId);
  if (!view || !block || !schema) return null;

  const { properties } = getViewFormat(view);
  const results: React.ReactNode[] = [];
  const isListView = viewType === CollectionViewType.LIST;
  const isTimelineView = viewType === CollectionViewType.TIMELINE;
  const isCalendarView = viewType === CollectionViewType.CALENDAR;
  const site =
    isListView || isTimelineView ? Site.LIST : isCalendarView ? Site.CALENDAR : Site.CARD;
  const collectionProperties = block.data.collectionProperties ?? {};

  const propertyNoWrap = !tableCellWrap || isListView || isTimelineView || isCalendarView;

  const propertyNames: React.ReactNode[] = [];
  properties.forEach((property) => {
    if (!property.visible) return;

    const propertyId = property.property;
    if (propertyId === 'title') return;

    const propertySchema = schema[propertyId];
    if (!propertySchema) return;

    let propertyType = propertySchema.type;
    if (propertyType === CollectionSchemaType.ID_NUMBER) return;
    let segments = collectionProperties[propertyId] ?? [];
    if (propertyType === CollectionSchemaType.FORMULA) {
      propertyType = formulaTool.getTypeAsCollectionSchemaType(propertyId);
      segments = formulaTool.getValueAsSegments(recordId, propertyId) ?? [];
    }

    const propertyName = (
      <span className={'mr-1.5 text-t4 text-grey3 text-ellipsis h-full flex-shrink-0 w-1/5 pt-0.5'}>
        {propertySchema.name}
      </span>
    );
    propertyNames.push(propertyName);

    const emptyValueProperty =
      site === Site.CARD && showPropertyName ? (
        <div className={cx('text-t4 min-w-[20px] break-words py-0.5 whitespace-nowrap')}>
          <span className="text-t4 text-grey4">{LOCAL_LNG.isEmpty}</span>
        </div>
      ) : null;

    switch (propertyType) {
      case CollectionSchemaType.TEXT: {
        const text = segmentsToText(segments);

        if (text) {
          results.push(
            <div
              className={cx(
                'text-t4 min-w-[20px] break-words py-0.5',
                !propertyNoWrap && 'whitespace-pre-wrap'
              )}
            >
              <RichText
                className={cx(propertyNoWrap && 'text-ellipsis')}
                segments={segments}
                interactable={true}
              />
            </div>
          );
        } else {
          results.push(emptyValueProperty);
        }

        break;
      }
      case CollectionSchemaType.NUMBER: {
        let text: string | undefined;
        let numberRightValue: ReactNode;

        text = segmentsToText(segments);
        let num = parseFloat(text);
        if (Number.isNaN(num)) {
          num = parseFloat(text.match(/[+-]?\d+(?:\.?\d+)?/)?.[0] ?? '');
        }

        const { numberFormat, showAs } = propertySchema;
        text = formatNumber(num, numberFormat)?.toString();

        const { showNumber } = showAs ?? {};
        if (typeof num !== 'undefined') {
          numberRightValue = renderNumProgress({
            num: Math.max(0, num),
            showAs,
            numberFormat,
            from: 'property-value',
          });
        }

        if (text) {
          results.push(
            <div
              className={cx(
                'text-t4 flex min-w-[20px] items-center break-words py-0.5 min-h-[22px]'
              )}
            >
              {((showAs && showNumber) || !showAs) && (
                <span className={cx(propertyNoWrap && 'text-ellipsis', 'flex-shrink-0')}>
                  {text}
                </span>
              )}
              {numberRightValue}
            </div>
          );
        } else {
          results.push(emptyValueProperty);
        }
        break;
      }
      case CollectionSchemaType.CREATED_AT:
      case CollectionSchemaType.UPDATED_AT:
      case CollectionSchemaType.DATE: {
        let text: string | undefined;

        if (
          propertyType === CollectionSchemaType.CREATED_AT ||
          propertyType === CollectionSchemaType.UPDATED_AT
        ) {
          text = dayjs(
            propertyType === CollectionSchemaType.CREATED_AT ? block.createdAt : block.updatedAt
          ).format(DATE_TIME_FORMAT);
        } else if (propertyType === CollectionSchemaType.DATE) {
          const segment = segments?.find((item) => {
            return item.type === TextType.DATE || item.type === TextType.DATETIME;
          });

          if (segment) {
            text = getDateStrBySegment(
              segment,
              propertySchema.dateFormat,
              segment?.type === TextType.DATETIME,
              propertySchema.timeFormat
            );
          }
        }
        if (text) {
          results.push(
            <div className={cx('text-t4 flex min-w-[20px] items-center break-words py-0.5')}>
              <span className={cx(propertyNoWrap && 'text-ellipsis')}>{text}</span>
            </div>
          );
        } else {
          results.push(emptyValueProperty);
        }

        break;
      }
      case CollectionSchemaType.CHECKBOX: {
        const text = segmentsToText(segments);
        const checked = formatCheckBoxValue(text);
        results.push(
          <div
            className={cx(
              'text-t4 pointer-events-auto flex min-w-[20px] items-center justify-start break-words py-0.5'
            )}
          >
            <button
              className="flex items-center"
              disabled={readonly}
              onClick={(e) => {
                e.stopPropagation();
                updateValue(recordId, propertyId, checked ? 'NO' : 'YES');
              }}
            >
              <Icon
                size="small"
                className={checked ? 'text-active_color' : ''}
                name={checked ? 'IcCheckbox2Check' : 'IcCheckbox2'}
              />
            </button>

            {(site !== Site.CARD || !showPropertyName) && (
              <span className={cx('ml-[7px]', propertyNoWrap && 'text-ellipsis')}>
                {propertySchema.name}
              </span>
            )}
          </div>
        );

        break;
      }
      case CollectionSchemaType.SELECT:
      case CollectionSchemaType.MULTI_SELECT: {
        const text = segmentsToText(segments);
        if (text) {
          results.push(
            <div className={cx('flex-1 text-t4 break-words')}>
              <Tags
                site={site}
                recordId={recordId}
                multiple={propertyType === CollectionSchemaType.MULTI_SELECT}
                propertyId={propertyId}
                className={cx(propertyNoWrap && 'whitespace-nowrap')}
                itemClassName={cx(
                  'text-t4 mr-1',
                  isCalendarView ? 'my-[1px]' : 'my-0.5',
                  !propertyNoWrap && 'max-w-[calc(100%-6px)]'
                )}
              />
            </div>
          );
        } else {
          results.push(emptyValueProperty);
        }

        break;
      }
      case CollectionSchemaType.PHONE:
      case CollectionSchemaType.EMAIL:
      case CollectionSchemaType.URL: {
        if (readUrlFromSegments(segments)) {
          const CellValueComponent = getCellValueComponent(propertyType);
          results.push(
            <div
              className={cx(
                'text-t4 relative min-w-[20px] break-words py-0.5',
                propertyNoWrap && 'text-ellipsis',
                isCalendarView && 'py-[1px]'
              )}
            >
              <CellValueComponent site={site} recordId={recordId} propertyId={propertyId} />
            </div>
          );
        } else {
          results.push(emptyValueProperty);
        }

        break;
      }

      case CollectionSchemaType.FILE: {
        const tags = segments.filter(
          (it) => it.type === TextType.URL && it.fileStorageType === 'internal'
        );
        if (tags.length > 0) {
          const CellValueComponent = getCellValueComponent(propertyType);
          results.push(
            <div
              className={cx(
                'text-t4 relative min-w-[20px] text-ellipsis break-words',
                !propertyNoWrap && 'max-w-[calc(100%-6px)]'
              )}
            >
              <CellValueComponent site={site} recordId={recordId} propertyId={propertyId} />
            </div>
          );
        } else {
          results.push(emptyValueProperty);
        }

        break;
      }

      case CollectionSchemaType.CREATED_BY:
      case CollectionSchemaType.UPDATED_BY:
      case CollectionSchemaType.PERSON: {
        if (
          (propertyType === CollectionSchemaType.CREATED_BY && !block.createdAt) ||
          (propertyType === CollectionSchemaType.UPDATED_BY && !block.updatedAt) ||
          (propertyType === CollectionSchemaType.PERSON &&
            segments.filter((it) => it.type === TextType.USER).length === 0)
        ) {
          results.push(emptyValueProperty);
          break;
        }

        const CellValueComponent = getCellValueComponent(propertyType);
        results.push(
          <div
            className={cx(
              'text-t4 relative min-w-[20px] text-ellipsis break-words',
              !propertyNoWrap && 'max-w-[calc(100%-6px)]'
            )}
          >
            <CellValueComponent site={site} recordId={recordId} propertyId={propertyId} />
          </div>
        );
        break;
      }

      case CollectionSchemaType.RELATION: {
        const CellValueComponent = getCellValueComponent(propertyType);
        const relationIds = getRelationRecords(recordId, propertyId);
        if (relationIds.relationRecords.length === 0 && isCalendarView) return;

        results.push(
          <div
            className={cx(
              'text-t4 flex min-w-[20px] items-center break-words py-0.5',
              propertyNoWrap && 'whitespace-nowrap'
            )}
          >
            <CellValueComponent site={site} recordId={recordId} propertyId={propertyId} />
          </div>
        );

        break;
      }

      case CollectionSchemaType.ROLLUP: {
        const CellValueComponent = getCellValueComponent(propertyType);
        results.push(
          <div
            className={cx(
              'text-t4 flex min-w-[20px] items-center break-words overflow-hidden',
              !isCalendarView && 'py-0.5',
              propertyNoWrap && 'text-ellipsis whitespace-nowrap'
            )}
          >
            <CellValueComponent site={site} recordId={recordId} propertyId={propertyId} />
          </div>
        );
        break;
      }

      default:
        break;
    }
  });

  if (results.length <= 0) return null;

  const propertyValues = results.map((node, index) => {
    if (!node) return null;

    return (
      <div
        className={cx('flex', (isListView || isTimelineView) && 'ml-5 items-center')}
        key={index}
      >
        {site === Site.CARD && showPropertyName && propertyNames[index]}
        <div
          className={cx(
            'flex-1',
            site === Site.CARD ? 'w-0' : '',
            isCalendarView && 'overflow-hidden'
          )}
        >
          {node}
        </div>
      </div>
    );
  });

  if (isListView) {
    return <div className="flex overflow-hidden">{propertyValues}</div>;
  }
  if (isTimelineView || isCalendarView) {
    return <>{propertyValues}</>;
  }
  return <div className="mt-[5px] mb-2 px-2">{propertyValues}</div>;
};

/**
 * list：超出省略显示，元素间间距固定(20)，超出外部容器则隐藏
 * timeline: 同 list, 属性外部无需容器包裹，超出外部容器继续展示
 * board & gallery：超出换行(checkbox 超出隐藏)，
 * calendar: 每个属性占据一行，超出隐藏，高度相同，都为 22
 *
 * 无实际内容则不展示，否则会占据高度，也会有额外的 node 节点。(所以展示属性的时候都需要检测是否有内容，如：text,number,select)
 */
