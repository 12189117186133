import { getIconsColor } from '@flowus/common/block/color/get-block-color';
import type { FC } from 'react';
import React, { useState } from 'react';
import type { MetaColor } from 'src/bitable/const';
import { COLORS } from 'src/bitable/const';
import { ColorSelector } from 'src/bitable/table-view/cell/select/color-selector';
import { ColorKey } from 'src/colors';
import { IS_APPLE } from '../../utils/environment';
import { Icon } from '../icon';
import { useOpenModal } from '../next-modal';
import { Tooltip } from '../tooltip';
import { ByteIcon } from './byte-icon';
import type { EmojiData, PickerProps } from './emoji-mart';
import { Picker } from './emoji-mart';
import data from './emoji-mart/data/custom-icon.json';

export interface EmojiPickerProps extends PickerProps {
  onSelect?(
    emoji: EmojiData,
    e?: React.MouseEvent<HTMLElement>,
    fromFrequently?: boolean
  ): boolean | void;
  customHeader?: JSX.Element;
}

/**
 * 这个picker是开源的picker，我们自定义的在emoji-panel.tsx里面
 * 2.盲人模式
 * 3.二次定制，改page图标的时候是支持多种方式的，包括emoji，上传图片和直接输入图片地址，这个需要加上tab
 */
export const EmojiPicker: FC<EmojiPickerProps> = (props) => {
  const isApple = IS_APPLE;
  const openModal = useOpenModal();
  const [color, setColor] = useState(() => {
    return localStorage.getItem('last-icon-color') || ColorKey.grey;
  });
  return (
    <Picker
      customColorKey={color}
      data={props.useCustomIcon ? data : undefined}
      perLine={12}
      showSkinTones={false}
      showPreview={false}
      theme={'light'}
      set={isApple ? 'apple' : 'google'}
      native={isApple}
      emojiTooltip={true}
      onSelect={props.onSelect}
      fallback={(emoji, emojiProps) => {
        if (!props.useCustomIcon) {
          // emoji的fallback，不是自定义的一律返回null
          return null;
        }
        return <ByteIcon emoji={emoji} emojiProps={emojiProps} color={emojiProps.customColorKey} />;
      }}
      searchBtn={
        props.useCustomIcon ? (
          <div
            className="flex items-center justify-center w-8 h-8 hover:bg-black_003 cursor-pointer shrink-0 mr-2.5 ml-1.5 rounded"
            onClick={(e) => {
              openModal.dropdown({
                popcorn: e.currentTarget,
                placement: 'right',
                content: ({ onCloseModal }) => {
                  return (
                    <div className="next-modal w-[250px] px-1 pt-1.5 pb-2.5">
                      <ColorSelector
                        colors={IconColors}
                        hideHeader={true}
                        value={localStorage.getItem('last-icon-color') ?? ColorKey.black}
                        selectedIcon={
                          <Icon
                            name="IcSelectedSpace"
                            size="normal"
                            className={'text-[18px] text-white'}
                          />
                        }
                        onSelect={(color) => {
                          setColor(color);
                          localStorage.setItem('last-icon-color', color);
                          onCloseModal();
                        }}
                        bgColorFn={getIconsColor}
                      />
                    </div>
                  );
                },
              });
            }}
          >
            <Tooltip popup="选择图标颜色">
              <div
                className="w-7 h-7 rounded-full border border-black/5 "
                style={{ backgroundColor: getIconsColor(color) }}
              />
            </Tooltip>
          </div>
        ) : null
      }
      {...props}
    />
  );
};

const IconColors = COLORS.map((c) => {
  return { ...c, name: '' } as MetaColor;
});
IconColors.unshift({
  key: ColorKey.black,
  name: '',
});
