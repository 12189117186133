import { BlockType } from '@next-space/fe-api-idl';
import { EXPAND_FOLD_RECORD } from 'src/common/const';
import { getBlockMenu } from 'src/editor/component/menu-list/menu-item';
import { cache } from 'src/redux/store';
import { $appUiStateCache, setExpandFoldRecord, useExpandFoldRecord } from 'src/services/app';
import { bizTracker } from 'src/utils/biz-tracker';
import { foldListExpandInfo } from './fold-list-expand-info';
import { keys } from 'lodash-es';

// 替换成lruCache,兼容老数据
const compatOldData = () => {
  const oldData: Record<string, boolean> = JSON.parse(
    localStorage.getItem(EXPAND_FOLD_RECORD) || '{}'
  );
  Object.entries(oldData).forEach(([id, expanded]) => {
    foldListExpandInfo.put(id, expanded);
  });
  localStorage.removeItem(EXPAND_FOLD_RECORD);
};
compatOldData();

/** 监听展开 */
export const useExpand = (uuid: string) => {
  const expand = useExpandFoldRecord(uuid);
  const setExpand = (expand: boolean, saveLocal = true) => {
    patchExpand(uuid, expand, saveLocal);
  };

  return [expand, setExpand] as const;
};

/** 收缩或展开列表 */
export const patchExpand = (uuid: string | string[], expand: boolean, saveLocal = true) => {
  const uuids = Array.isArray(uuid) ? uuid : [uuid];
  setExpandFoldRecord(uuids, expand);

  // 埋点
  if (!Array.isArray(uuid)) {
    const block = cache.blocks[uuid];
    const title = getBlockMenu(block?.type, {
      level: block?.data.level,
    })?.title;
    if (title) {
      bizTracker.event('block_fold', {
        block_name: title,
      });
    }
  }
  if (saveLocal) {
    foldListExpandInfo.clear();
    foldListExpandInfo.batch(() => {
      keys($appUiStateCache.$expandFoldRecord).forEach((key) => {
        foldListExpandInfo.put(key, !!$appUiStateCache.$expandFoldRecord[key]);
      });
    });
  }
};

/** 可折叠，并已折叠 */
export const isLikeFoldBlockAndInFold = (uuid?: string) => {
  if (!uuid) return false;
  const block = cache.blocks[uuid];
  if (!block) return false;
  const isExpand = $appUiStateCache.$expandFoldRecord[uuid] ?? false;
  return isLikeFoldBlock(block.type) && isExpand === false;
};

export const isLikeFoldBlock = (type: BlockType) => {
  return [
    BlockType.TODO,
    BlockType.ORDER_LIST,
    BlockType.LIST,
    BlockType.FOLD_LIST,
    BlockType.TOGGLE_HEADER,
  ].includes(type);
};
