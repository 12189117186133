import { CaptchaSendType, UserAuthorizationType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';

import { loginInjector } from '../utils/init';

/**绑定已有的账号 */
export const useBindAccount = () => {
  const bindEmail = useCallback(async (userId: string, email: string, captcha: string) => {
    const res = await loginInjector.request.infra.emailAuthorization({
      email,
      captcha,
      type: CaptchaSendType.BIND_NEW,
    });
    if (res.nickname) {
      loginInjector.message.success(`该账号已被昵称 ${res.nickname} 的账户绑定`);
      return;
    }
    return loginInjector.request.infra.bindThirdAccount(userId, {
      type: UserAuthorizationType.EMAIL,
      code: res.authorizationCode ?? '',
    });
  }, []);

  const bindWechat = useCallback(async (userId: string, code: string) => {
    const res = await loginInjector.request.infra.wechatAuthorization({
      code,
      type: 'web',
    });
    return loginInjector.request.infra.bindThirdAccount.raw(userId, {
      type: UserAuthorizationType.WECHAT,
      code: res.authorizationCode ?? '',
    });
  }, []);
  return {
    bindEmail,
    bindWechat,
  };
};
