/** 支付类型 微信 支付宝 积分 */
export enum PayType {
  wxpay = 'wxpay',
  wxjsapi = 'wxjsapi',
  alipay = 'alipay',
  irpay = 'irpay',
  offline = 'offline',
  /** 海外支付 */
  webpay = 'webpay',
}
