import { cx } from '@flowus/common/cx';
import { emitter } from '@flowus/common/utils/emitter';
import isHotkey from 'is-hotkey';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { colors } from 'src/colors';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { ILLEGAL_TEXT } from 'src/common/const';
import { FileNameUiKit } from 'src/components/file-name-uikit';
import { Share } from 'src/components/share';
import { segmentsToText } from 'src/editor/utils/editor';
import { getReadableFileSizeString } from 'src/editor/utils/size-utils';
import { useEnableAI } from 'src/hooks/block/use-enable-AI';
import { useFileDownLimit } from 'src/hooks/limit/file-down-limit';
import { useReadonly } from 'src/hooks/page';
import { useOfficialSpaceShare } from 'src/hooks/public/use-official-space-share';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useIsFavoritePage } from 'src/hooks/user';
import { HISTORY_REDO, HISTORY_UNDO } from 'src/redux/actions';
import { dispatch } from 'src/redux/store';
import { usePDFHistorySideBar } from 'src/services/app';
import { useIsMobileSize } from 'src/services/app/hook';
import { useIsFullscreen } from 'src/services/desktop';
import { isMacElectron } from 'src/utils/electron';
import { judgeSharePage } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { urlFetcher } from 'src/utils/url-fetcher';
import { TogglePdfAISidebar } from 'src/views/file-preview/toggle-sidebar';
import { Favorite } from 'src/views/main/header/favorite';
import { PageScene, usePageScene } from 'src/views/main/scene-context';
import { AIButton } from './buttons/ai-button';
import { ColorButton } from './buttons/color-button';
import { MoreButton } from './buttons/more';
import { PresentationButton } from './buttons/presentation-button';
import { PrintButton } from './buttons/print-button';
import { ScaleButton } from './buttons/scale-button';
import { Search } from './buttons/search-button';
import { ShapeButton } from './buttons/shape-button';
import { SideBarButton } from './buttons/sidebar-button';
import type { HistoryStack } from './service/history';
import type { PDFViewApplication } from './service/pdf-viewer-application';
import { AnnotationType } from './type';

interface Props {
  application: PDFViewApplication;
  pdfViewer: any;
  uuid: string;
  embed?: boolean;
  downloadUrl?: string;
}

export const PDFHeader: FC<Props> = (props) => {
  const { pdfViewer, uuid, embed, application, downloadUrl } = props;

  const isFullscreen = useIsFullscreen();
  const { illegal, allowDuplicate, allowDownload, allowShowAIChat } = usePermissions(uuid);
  const block = usePickBlock(uuid, ['data'], ['ossName', 'segments', 'size']);
  const isMobileSize = useIsMobileSize();
  const isSharePage = judgeSharePage();
  const pageScene = usePageScene();
  const fileDownLimit = useFileDownLimit();
  const pdfHistorySideBar = usePDFHistorySideBar();

  const fileName = illegal ? ILLEGAL_TEXT : segmentsToText(block?.data.segments);
  const officialSpaceShare = useOfficialSpaceShare(uuid ?? '');
  const isFavorite = useIsFavoritePage(uuid ?? '');
  const isDesktopMode = isMacElectron && !isFullscreen;
  const isShowDuplicate = allowDuplicate && !officialSpaceShare && !illegal;
  const isShowDownLoad = allowDownload && !officialSpaceShare && !illegal;
  const { spaceAiOpen, enableAI } = useEnableAI();

  let showAiButton = pageScene !== PageScene.PAGE_LITE_PREVIEW && !embed;
  if (isSharePage) {
    showAiButton = showAiButton && allowShowAIChat;
  } else {
    showAiButton = showAiButton && spaceAiOpen;
  }

  const download = async () => {
    if (!uuid) return;
    if (!block) return;
    await fileDownLimit();
    if (downloadUrl) {
      window.open(downloadUrl);
      return;
    }
    const url = await urlFetcher.fetchDownloadUrl({
      blockId: uuid,
      ossName: block.data.ossName ?? '',
      fileName,
    });
    window.open(url);
  };

  return (
    <div className="flex items-center justify-between h-[55px] border-grey6 bg-white3 text-black px-4 relative overflow-x-auto next-scrollbar scrollbar-visible">
      <div className={cx('flex items-center mr-1', isDesktopMode && 'ml-16')}>
        {enableAI && !pdfHistorySideBar && <TogglePdfAISidebar showTitle={false} />}

        <SideBarButton pdfViewer={pdfViewer} uuid={uuid} />

        {!embed && block && (
          <FileNameUiKit
            fileName={fileName}
            className="text-t2 ml-2 max-w-[250px]"
            addonAfter={
              !isMobileSize ? (
                <span className="ml-2 text-grey3">
                  {getReadableFileSizeString(block.data.size)}
                </span>
              ) : null
            }
          />
        )}
      </div>

      <CenterButtons {...props} />

      <div className="flex h-full items-center">
        <Search uuid={uuid} pdfViewer={pdfViewer} className="ml-2" />

        {!embed && uuid && (
          <>
            {!isSharePage && !illegal && !officialSpaceShare && (
              <Share showType="button" className="ml-2" uuid={uuid} color="text-white" />
            )}

            {isShowDownLoad && (
              <Tooltip popup="下载" className="ml-2">
                <button
                  className="animate-hover-black3 p-1 flex justify-center items-center"
                  onClick={download}
                >
                  <Icon name="IcMenuDownload" size="middle" />
                </button>
              </Tooltip>
            )}

            {!isSharePage && !illegal && (
              <Favorite
                uuid={uuid}
                style={{ color: isFavorite ? colors.yellow : undefined }}
                className="ml-2"
              />
            )}
          </>
        )}

        <PresentationButton uuid={uuid} pdfViewer={pdfViewer} className="ml-2" />
        {isShowDuplicate && <PrintButton pdfViewer={pdfViewer} uuid={uuid} className="ml-2" />}

        {showAiButton && <AIButton className="ml-2" application={application} />}

        <MoreButton uuid={uuid} pdfViewer={pdfViewer} className="ml-2" />
      </div>
    </div>
  );
};

const CenterButtons: FC<Props> = (props) => {
  const { pdfViewer, application, embed, uuid } = props;

  const readonly = useReadonly(uuid);

  const inputRef = useRef<HTMLInputElement>(null);
  const [hasRedoList, setHasRedoList] = useState(false);
  const [hasUndoList, setHasUndoList] = useState(false);

  const [currentPage, setCurrentPage] = useState<string>(pdfViewer.currentPageNumber);
  const [annotationType, setAnnotationType] = useState<AnnotationType>(AnnotationType.NONE);

  useEffect(() => {
    application.onHistoryChange = (history: HistoryStack) => {
      setHasRedoList(history.redoStack.length > 0);
      setHasUndoList(history.undoStack.length > 0);
    };
  }, [application]);

  useEffect(() => {
    pdfViewer.eventBus.on('pagechanging', () => {
      const { pdfThumbnailViewer } = pdfViewer.renderingQueue;
      pdfThumbnailViewer.scrollThumbnailIntoView(pdfViewer.currentPageNumber);

      setCurrentPage(pdfViewer.currentPageNumber);
    });
  }, [pdfViewer]);

  const jumpPage: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (isHotkey('enter')(event)) {
      const pageNumber = Number(currentPage);
      if (pageNumber && pageNumber <= pdfViewer.pagesCount && pageNumber > 0) {
        pdfViewer.linkService.goToPage(pageNumber);
      } else {
        setCurrentPage(pdfViewer.currentPageNumber);
      }
    }
  };

  const handleChangeCursor = (cursor: AnnotationType) => {
    emitter.emit('pdfCursor', cursor);

    setAnnotationType(cursor);

    if (cursor === AnnotationType.MOVE) {
      application.pdfCursorTools.switchTool(application.pdfCursorTools.active === 0 ? 1 : 0);
    } else {
      application.pdfCursorTools.switchTool(0);
    }
  };

  const zoomIn = () => pdfViewer.increaseScale();
  const zoomOut = () => pdfViewer.decreaseScale();
  const previousPage = () => pdfViewer.previousPage();
  const nextPage = () => pdfViewer.nextPage();
  const redo = () => {
    dispatch(HISTORY_REDO());
    application.redo();
  };
  const undo = () => {
    dispatch(HISTORY_UNDO());
    application.undo();
  };

  return (
    <div className="flex h-full items-center overflow-auto">
      <div className="flex">
        <Tooltip placement="bottom" popup="上一页">
          <button
            onClick={previousPage}
            className={cx('animate-hover-black3 p-1 flex justify-center items-center')}
          >
            <Icon size="middle" name="IcArrowDateBack" />
          </button>
        </Tooltip>

        <div className="flex rounded-sm bg-black/5 items-center pr-2 mx-2 text-t4">
          <input
            ref={inputRef}
            type="number"
            className="w-[30px] mx-0.5 overflow-hidden h-full border-none bg-transparent px-0 text-center focus:border-0"
            style={{ backgroundColor: 'transparent' }}
            value={currentPage}
            onKeyDown={jumpPage}
            onChange={(e) => setCurrentPage(e.target.value)}
          />
          <span className="w-1.5 flex justify-center">/</span>
          <span className="w-[30px] flex justify-center">{pdfViewer.pagesCount}</span>
        </div>

        <Tooltip placement="bottom" popup="下一页">
          <button
            onClick={nextPage}
            className={cx('animate-hover-black3 p-1 flex justify-center items-center')}
          >
            <Icon size="middle" name="IcArrowDateNext" />
          </button>
        </Tooltip>
      </div>

      <span className="min-w-[1px] mx-2 bg-grey6 h-4" />

      <div className="flex items-center">
        <Tooltip placement="bottom" popup="放大">
          <button
            onClick={zoomIn}
            className={cx('animate-hover-black3 p-1 flex justify-center items-center')}
          >
            <Icon size="middle" name="IcPreviewZoomIn" />
          </button>
        </Tooltip>

        <Tooltip placement="bottom" className="ml-2" popup="缩小">
          <button
            onClick={zoomOut}
            className={cx('animate-hover-black3 p-1 flex justify-center items-center')}
          >
            <Icon size="middle" name="IcPreviewZoomOut" />
          </button>
        </Tooltip>
      </div>

      <span className="min-w-[1px] mx-2 bg-grey6 h-4" />

      <ScaleButton pdfViewer={pdfViewer} />

      <span className="min-w-[1px] mx-2 bg-grey6 h-4" />

      <Tooltip placement="bottom" popup="文本选择">
        <button
          onClick={() => handleChangeCursor(AnnotationType.NONE)}
          className={cx(
            'animate-hover-black3 p-1 flex justify-center items-center',
            annotationType === AnnotationType.NONE && 'bg-black_006'
          )}
        >
          <Icon size="middle" name="IcPreviewCursor" />
        </button>
      </Tooltip>

      <Tooltip placement="bottom" className="ml-2" popup="抓手工具">
        <button
          onClick={() => handleChangeCursor(AnnotationType.MOVE)}
          className={cx(
            'animate-hover-black3 p-1 flex justify-center items-center',
            annotationType === AnnotationType.MOVE && 'bg-black_006'
          )}
        >
          <Icon size="middle" name="IcPreviewMove" />
        </button>
      </Tooltip>

      {uuid && !readonly && !embed && (
        <>
          <span className="min-w-[1px] mx-2 bg-grey6 h-4" />

          <Tooltip placement="bottom" popup="文字高亮">
            <button
              onClick={() => handleChangeCursor(AnnotationType.HIGHLIGHT)}
              className={cx(
                'animate-hover-black3 p-1 flex justify-center items-center',
                annotationType === AnnotationType.HIGHLIGHT && 'bg-black_006'
              )}
            >
              <Icon size="middle" name="IcPreviewCursorText" />
            </button>
          </Tooltip>

          <ShapeButton
            operatorType={annotationType}
            onChangeOperator={handleChangeCursor}
            className="ml-2"
          />

          <ColorButton pdfViewer={pdfViewer} className="ml-2" />

          <Tooltip placement="bottom" className="ml-2" popup="橡皮擦">
            <button
              onClick={() => handleChangeCursor(AnnotationType.ERASER)}
              className={cx(
                'animate-hover-black3 p-1 flex justify-center items-center',
                annotationType === AnnotationType.ERASER && 'bg-black_006'
              )}
            >
              <Icon size="middle" name="IcPreviewEraser" />
            </button>
          </Tooltip>

          <span className="min-w-[1px] mx-2 bg-grey6 h-4" />

          <Tooltip placement="bottom" popup="撤销">
            <button
              className={cx(
                'animate-hover-black3 p-1 flex justify-center items-center',
                !hasUndoList && 'opacity-30 cursor-not-allowed'
              )}
              disabled={!hasUndoList}
              onClick={undo}
            >
              <Icon size="middle" name="IcMenuUndo" />
            </button>
          </Tooltip>

          <Tooltip placement="bottom" className="ml-2" popup="重做">
            <button
              className={cx(
                'animate-hover-black3 p-1 flex justify-center items-center',
                !hasRedoList && 'opacity-30 cursor-not-allowed'
              )}
              disabled={!hasRedoList}
              onClick={redo}
            >
              <Icon size="middle" name="IcMenuRedo" />
            </button>
          </Tooltip>
        </>
      )}
    </div>
  );
};
