import type { SegmentDTO } from '@next-space/fe-api-idl';
import { BlockType, CardColorSource, CollectionViewType } from '@next-space/fe-api-idl';
import { isUndefined, omitBy, sample } from 'lodash-es';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { COLORS } from 'src/bitable/const';
import type { RecordCreatedFrom } from 'src/bitable/table-view/types';
import { message } from 'src/common/components/message';
import { request } from 'src/common/request';
import { useTransaction } from 'src/hooks/use-transaction';
import {
  LIST_AFTER_COLLECTION_VIEW_PAGESORT,
  LIST_BEFORE_COLLECTION_VIEW_PAGESORT,
  TRANSACTION_FIRE,
} from 'src/redux/actions';
import { addBlock } from 'src/redux/managers/block/add';
import { removeBlock } from 'src/redux/managers/block/remove';
import { updateBlock } from 'src/redux/managers/block/update';
import * as CollectionViewManager from 'src/redux/managers/collection-view';
import { dispatch, getState } from 'src/redux/store';
import type { CollectionWhere, NextBlock } from 'src/redux/types';
import { $appUiStateCache, setAppUiState } from 'src/services/app';
import { sequence } from 'src/utils/async-utils';
import { isPageLike } from 'src/utils/block-type-utils';
import { useFetchPage } from '../page';
import { useOpenInRight } from '../page/use-open-in-right';
import { useCurrentUser } from '../user';
import { getViewFormat } from './get-view-format';
import { copyBlocks } from './use-copy-block';

/**
 * bitable
 * 以排序的形式, 插入新记录
 */

export const useInsertRecord = () => {
  const fetchPage = useFetchPage();
  const transaction = useTransaction();
  const openInRight = useOpenInRight();
  const { setting = {} } = useCurrentUser();
  const history = useHistory();

  return useCallback(
    async (params: {
      viewId: string;
      where?: CollectionWhere;
      propertyValues?: Record<string, SegmentDTO[] | undefined>;
      templateId?: string;
      isOpenInRight?: boolean;
      from?: RecordCreatedFrom;
    }) => {
      const { viewId, where, propertyValues, templateId, isOpenInRight, from } = params;
      if (templateId) {
        await fetchPage(templateId);
      }
      const defers: (() => Promise<void>)[] = [];
      const recordId = transaction(
        () => {
          const recordId = insertRecord(viewId, where, propertyValues);
          if (recordId != null && templateId != null) {
            fillRecordWithTemplate(recordId, templateId, defers);
          }

          const collectionSearch = $appUiStateCache.$collectionSearch;
          setAppUiState({
            $collectionSearch: {
              ...collectionSearch,
              [viewId]: undefined,
            },
          });

          if (recordId) {
            if (isOpenInRight) {
              if (setting.useRightPageByNew) {
                openInRight(recordId);
              } else {
                history.push(`/${recordId}`);
              }
            } else {
              setAppUiState({ $newCreatedRecord: { id: recordId, from, viewId } });
            }
          }

          return recordId;
        },
        { noThrottle: true }
      );
      void sequence(async () => {
        await Promise.all(defers.map((it) => it()));
      });
      return recordId;
    },
    [transaction, fetchPage, setting.useRightPageByNew, openInRight, history]
  );
};

/**
 * 插入 记录
 * @param viewId CollectionView ID
 * @param after 插入锚点
 */
export const insertRecord = (
  viewId: string,
  where?: CollectionWhere,
  propertyValues?: Record<string, SegmentDTO[] | undefined>
) => {
  const viewInfo = getViewFormat(viewId);
  if (!viewInfo) return;

  const { view, collection } = viewInfo;

  const blockInfo: Partial<NextBlock> = { type: BlockType.PAGE };
  blockInfo.data = {};

  if (propertyValues !== undefined) {
    const { title, ...properties } = propertyValues;
    blockInfo.data.collectionProperties = omitBy(properties, isUndefined) as Record<
      string,
      SegmentDTO[]
    >;
    blockInfo.data.segments = title;
  }

  let isCustomColor = false;
  if (view.type === CollectionViewType.CALENDAR) {
    isCustomColor = view.format.calendarColorSetting?.type === CardColorSource.CUSTOM;
  } else if (view.type === CollectionViewType.TIMELINE) {
    isCustomColor = view.format.timelineColorSetting?.type === CardColorSource.CUSTOM;
  }
  if (isCustomColor) {
    const pool = COLORS.map((item) => item.key);
    const colorKey = sample(pool) ?? COLORS[0].key;
    blockInfo.data.collectionCardColor = colorKey;
  }

  const newRecordId = addBlock(blockInfo, {
    parentId: collection.uuid,
    // 多维表 collection subNodes 的排序永远都是插入排序
    // 具体的页面顺序通过 CollectionView.pageSort
    last: true,
  });

  const { newPageSort, needUpdate } = checkPageSort(view.pageSort, collection.subNodes);
  if (needUpdate) {
    CollectionViewManager.update(viewId, {
      pageSort: newPageSort,
    });
  }
  if (where?.first || where?.before) {
    dispatch(
      LIST_BEFORE_COLLECTION_VIEW_PAGESORT({ uuid: newRecordId, viewId, before: where.before })
    );
  } else {
    dispatch(
      LIST_AFTER_COLLECTION_VIEW_PAGESORT({ uuid: newRecordId, viewId, after: where?.after })
    );
  }

  collection.views?.forEach((uuid) => {
    if (uuid === viewId) return;
    dispatch(LIST_AFTER_COLLECTION_VIEW_PAGESORT({ uuid: newRecordId, viewId: uuid }));
  });

  return newRecordId;
};

export const checkPageSort = (pageSort: string[], subNodes: string[]) => {
  const pageSortSet = new Set(pageSort);
  const subNodesSet = new Set(subNodes);

  let needUpdate = false;
  // 少了的补上
  for (const blockId of subNodes) {
    if (!pageSortSet.has(blockId)) {
      needUpdate = true;
      pageSortSet.add(blockId);
    }
  }

  // 多了的拿掉
  for (const blockId of pageSort) {
    if (!subNodesSet.has(blockId)) {
      needUpdate = true;
      pageSortSet.delete(blockId);
    }
  }

  return {
    needUpdate,
    newPageSort: [...pageSortSet],
  };
};

export const fillRecordWithTemplate = (
  recordId: string,
  templateId: string,
  outDefers: (() => Promise<void>)[] = []
) => {
  const record = getState().blocks[recordId];
  if (record == null) {
    // eslint-disable-next-line no-console
    console.warn(`找不到记录${recordId}`);
    return;
  }
  const template = getState().blocks[templateId];
  if (template == null) {
    // eslint-disable-next-line no-console
    console.warn(`找不到模板记录${templateId}`);
    return;
  }

  const patch: Partial<Omit<NextBlock, 'uuid'>> = {};
  const props = { ...record.data.collectionProperties };
  for (const [prop, value] of Object.entries(template.data.collectionProperties ?? {})) {
    if (prop !== 'title' && (props[prop] === undefined || props[prop]?.length === 0)) {
      props[prop] = value;
    }
  }
  patch.data = {
    collectionProperties: props,
  };

  patch.data.pageFixedWidth = template.data.pageFixedWidth;
  patch.data.directoryMenu = template.data.directoryMenu;

  if (record.data.cover == null) {
    patch.data.cover = template.data.cover;
  }
  if (record.data.coverPos == null) {
    patch.data.coverPos = template.data.coverPos;
  }
  if (record.data.icon == null) {
    patch.data.icon = template.data.icon;
  }
  if (record.data.iconUrl == null) {
    patch.data.iconUrl = template.data.iconUrl;
  }
  // 新建的记录需要使用模板的设置
  if (template.data.format?.pageLayout) {
    if (!patch.data.format) {
      patch.data.format = {};
    }
    patch.data.format.pageLayout = template.data.format?.pageLayout;
  }
  if (template.data.format?.fontFormat) {
    if (!patch.data.format) {
      patch.data.format = {};
    }
    patch.data.format.fontFormat = template.data.format?.fontFormat;
  }

  updateBlock(recordId, patch);
  if (record.subNodes.length === 0 && template.subNodes.length === 0) {
    addBlock({ type: BlockType.TEXTAREA }, { parentId: recordId, last: true });
  }

  if (template.subNodes.length > 0) {
    // FIXME: 这里没有和useCopyBlock一样做容量检测，因为会增加异步操作。
    // 容量检测应该后端在transaction里失败，然后store这边做回退。
    const seedUuids = new Set(template.subNodes);
    const total = [...copyBlocks(template.subNodes, { parent: recordId, prefixName: false })];
    const list = total
      .filter(
        ({ block }) =>
          block.type !== BlockType.COLLECTION_VIEW &&
          block.type !== BlockType.REFERENCE_COLLECTION &&
          isPageLike(block.type)
      )
      .map(({ uuid, block }) => ({
        uuid,
        targetId: block.uuid,
        isDescendant: !seedUuids.has(uuid),
      }));
    if (list.length > 0) {
      outDefers.push(async () => {
        const res = await request.editor.copySubNodes.raw({ list });
        // 如果复制接口失败需把其他创建的块也删除
        if (res.code !== 200) {
          total.forEach(({ block }) => removeBlock(block.uuid));
          dispatch(TRANSACTION_FIRE());
          message.error(res.msg);
        }
      });
    }
  }
};
