import type { FC } from 'react';
import type { ModalSchema } from '.';
import { LoadingIcon } from '../components/loading-icon';
import { cx } from '../cx';
import { RenderModal } from './modal';

// #region loading
export const RenderLoading: FC<ModalSchema.RenderLoading> = (props) => {
  const { modalId, title, customIcon, className, ...reset } = props;
  return (
    <RenderModal
      center
      autoClose={false}
      {...reset}
      modalId={modalId}
      className={cx(
        'flex flex-col items-center justify-center min-w-80 min-h-28 py-6 px-8',
        className
      )}
      content={() => (
        <div className="text-t3 space-y-4 flex flex-col justify-center items-center">
          {customIcon ?? <LoadingIcon />}
          <span className="text-grey3">{title}</span>
        </div>
      )}
    />
  );
};
// #endregion
