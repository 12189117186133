/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Role } from '@flowus/common';
import { Button } from '@flowus/common/components';
import { cx } from '@flowus/common/cx';
import { useFinalValue } from '@flowus/common/hooks/react-utils';
import type { SegmentDTO } from '@next-space/fe-api-idl';
import { PermissionRole } from '@next-space/fe-api-idl';
import type { MouseEvent, RefObject } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useStore } from 'react-redux';
import { Subject } from 'rxjs';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useTransaction } from 'src/hooks/use-transaction';
import { useCurrentSpaceUser } from 'src/hooks/user';
import { $appUiStateCache, setAppUiState } from 'src/services/app';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { judgeSharePage } from 'src/utils/getPageId';
import type { RemoteAction } from './comment-editor';
import { CommentEditor } from './comment-editor';
import { CommentItem } from './comment-item';
import { useCommentOperations } from './comment-operations';
import { PopupCommentEditor } from './popup-comment-editor';

export const Discussion = (props: {
  discussionId: string;
  draftKey?: string;
  autoFocus?: boolean;
  className?: string;
  onResolve?: () => void;
  readonly?: boolean;
  allowCommentInSharePage?: boolean;
  // 是否默认显示回复，评论列表默认不显示
  showReplyEditor?: boolean;
  // 是否隐藏并引用块内容，默认展示，但在页面的评论不展示
  hideBlockContext?: boolean;
}) => {
  const discussion = useObservableStore((state) => state.discussions[props.discussionId], []);
  const comments = discussion?.comments ?? [];
  const transaction = useTransaction();
  const commentOps = useCommentOperations();
  const editorActions = useFinalValue(() => new Subject<RemoteAction>());
  const { role } = usePermissions(discussion?.uuid);
  const { showReplyEditor = true } = props;
  const [showReply, setShowReply] = useState(showReplyEditor);

  const doComment = (text: SegmentDTO[] | undefined) => {
    transaction(() => {
      commentOps.appendComment({
        discussionId: props.discussionId,
        text,
      });
    });
  };
  useEffect(() => {
    const subscription = editorActions.subscribe((act) => {
      if (act.type === 'resolve') {
        props.onResolve?.();
      }
      if (act.type === 'reply') {
        setShowReply(true);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [editorActions, props]);

  if (discussion == null) return null;
  if (discussion.resolved) {
    return <ResolvedDiscussion discussionId={props.discussionId} readonly={props.readonly} />;
  }
  const isShare = judgeSharePage();
  const canComment = Role.contains(role, PermissionRole.COMMENTER);
  // 分享页面的分享可评论
  const commentInSharePage = isShare && props.allowCommentInSharePage && canComment;
  // 普通页面的可评论权限
  const commentInNormalPage = !isShare && canComment;
  const showCommentEditor = showReply && (commentInSharePage || commentInNormalPage);

  return (
    <div className={cx('relative py-2 px-3 group text-t2', props.className)}>
      <div className="absolute z-10 top-2 right-2.5 border rounded opacity-0 group-hover:opacity-100 flex">
        <CommentBtns
          editorActions={editorActions}
          commentId={comments[0]!}
          readonly={props.readonly}
        />
      </div>
      <div className="space-y-3">
        {comments.map((it, index) => (
          <div key={it} className="relative group-scope">
            {index >= 1 && (
              <div className="absolute z-10 top-0 right-0 border rounded opacity-0 group-scope-hover:opacity-100 flex">
                <CommentBtns
                  editorActions={editorActions}
                  commentId={it}
                  readonly={props.readonly}
                />
              </div>
            )}
            <CommentItem
              hideBlockContext={props.hideBlockContext}
              discussion={discussion}
              commentId={it}
            />
          </div>
        ))}
        {showCommentEditor && (
          <CommentEditor
            draftKey={props.draftKey}
            autoFocus={props.autoFocus}
            remoteAction={editorActions}
            onComment={doComment}
          />
        )}
      </div>
    </div>
  );
};

const CommentMenu = (props: {
  commentId: string;
  btnRef: RefObject<HTMLButtonElement>;
  onCloseModal: () => void;
}) => {
  const store = useStore();
  const transaction = useTransaction();
  const commentOps = useCommentOperations();
  const comment = useObservableStore((state) => {
    return state.comments[props.commentId];
  }, []);
  const openModal = useOpenModal();
  const { role } = usePermissions(props.commentId);
  const currentUser = useCurrentSpaceUser();

  if (currentUser == null) return null;

  const canEdit =
    // @ts-ignore type
    !comment?.illegal &&
    Role.contains(role, PermissionRole.COMMENTER) &&
    comment?.createdBy === currentUser.uuid;
  const canDelete =
    (currentUser.spaceRole === PermissionRole.EDITOR &&
      Role.contains(role, PermissionRole.READER)) ||
    (Role.contains(role, PermissionRole.COMMENTER) && comment?.createdBy === currentUser.uuid);

  const doOpenPopupEditor = () => {
    if (props.btnRef.current == null) return;
    props.onCloseModal();
    const text = store.getState().comments[props.commentId]?.text;
    openModal.dropdown({
      popcorn: props.btnRef.current,
      placement: 'bottom-end',
      content: (props2: { text: SegmentDTO[] | undefined; onCloseModal: () => void }) => {
        return (
          <PopupCommentEditor
            initialText={props2.text}
            onComment={(value) => {
              props2.onCloseModal();
              transaction(() => {
                commentOps.editComment({
                  commentId: props.commentId,
                  newText: value,
                });
              });
            }}
          />
        );
      },
      contentProps: { text },
    });
  };
  const doDelete = () => {
    openModal.warning({
      title: <>确定删除该条评论吗？</>,
      confirmText: '删除',
      confirm() {
        props.onCloseModal();
        transaction(() => {
          commentOps.deleteComment({ commentId: props.commentId });
        });
      },
    });
  };
  return (
    <ListView
      className="next-modal w-[180px] py-1"
      items={[
        {
          type: ListItemType.OPERATION,
          isHidden: !canEdit,
          data: {
            title: '编辑',
            icon: 'IcMenuRename',
            onClick: doOpenPopupEditor,
          },
        },
        {
          type: ListItemType.OPERATION,
          isHidden: !canDelete,
          data: {
            title: '删除',
            icon: 'IcMenuDelete',
            onClick: doDelete,
          },
        },
      ]}
    />
  );
};

const CommentBtns = (props: {
  editorActions: Subject<RemoteAction>;
  commentId: string;
  readonly?: boolean;
}) => {
  const openModal = useOpenModal();
  const transaction = useTransaction();
  const commentOps = useCommentOperations();
  const btnRef = useRef<HTMLButtonElement>(null);
  const comment = useObservableStore((state) => {
    return state.comments[props.commentId];
  }, []);
  const isShare = judgeSharePage();

  const { role } = usePermissions(comment?.uuid);
  const currentUser = useCurrentSpaceUser();

  const discussion = useObservableStore(
    (state) => {
      if (comment?.parentId == null) return undefined;
      return state.discussions[comment.parentId];
    },
    [comment?.parentId]
  );

  const doOpenMenu = (event: MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      content: CommentMenu,
      contentProps: { commentId: props.commentId, btnRef },
    });
  };
  const doResolve = () => {
    if (discussion == null) return;

    transaction(() => {
      commentOps.resolveDiscussion({
        discussionId: discussion.uuid,
      });
    });
    props.editorActions.next({
      type: 'resolve',
    });
  };
  if (props.readonly) return null;
  if (currentUser == null) return null;
  // if (isShare) return null;
  if (comment == null) return null;
  if (discussion == null) return null;

  // REMARK: 产品确认过，仅可评论权限的用户，自己是没有重新打开和解决的权限的
  // 只要有可编辑权限，即拥有解决和重新打开权限
  return (
    <>
      {Role.contains(role, PermissionRole.COMMENTER) && (
        <Tooltip popup="回复">
          <button
            className="px-1 border-r last:border-r-0 animate-hover rounded-none"
            onClick={() => {
              if (isShare && !$appUiStateCache.$allowCommentInSharePage) {
                setAppUiState({ $allowCommentInSharePage: true });
              }
              // 在分享页需要等commentEditor显示出来再发消息
              setTimeout(() => {
                const replyTo = comment?.createdBy;
                if (replyTo == null) return;
                props.editorActions.next({
                  type: 'reply',
                  payload: {
                    to: replyTo,
                  },
                });
              }, 10);
            }}
          >
            <Icon size="normal" name="IcMenuReply" className="text-grey3" />
          </button>
        </Tooltip>
      )}
      {props.commentId === discussion?.comments[0] && Role.contains(role, PermissionRole.WRITER) && (
        <Tooltip popup="解决并隐藏评论">
          <button
            className="px-1 border-r last:border-r-0 animate-hover rounded-none"
            onClick={doResolve}
          >
            <Icon size="normal" name="IcResolve" className="text-grey3" />
          </button>
        </Tooltip>
      )}
      {((currentUser.spaceRole === PermissionRole.EDITOR &&
        Role.contains(role, PermissionRole.READER)) ||
        (Role.contains(role, PermissionRole.COMMENTER) &&
          comment?.createdBy === currentUser.uuid)) && (
        <button
          ref={btnRef}
          className="px-1 border-r last:border-r-0 animate-hover rounded-none"
          onClick={doOpenMenu}
        >
          <Icon size="normal" name="IcMore" className="text-grey3" />
        </button>
      )}
    </>
  );
};

export const ResolvedDiscussion = (props: {
  discussionId: string;
  className?: string;
  readonly?: boolean;
  hideBlockContext?: boolean;
}) => {
  const discussion = useObservableStore(
    (state) => state.discussions[props.discussionId],
    [props.discussionId]
  );
  const comments = discussion?.comments ?? [];
  const [expanded, setExpanded] = useState(false);
  const transaction = useTransaction();
  const { role } = usePermissions(discussion?.uuid);

  const commentOps = useCommentOperations();
  const doReopen = () => {
    transaction(() => {
      commentOps.reopenDiscussion({ discussionId: props.discussionId });
    });
  };

  if (discussion == null) return null;

  return (
    <div className={cx('relative py-4 group', props.className)}>
      <div className="absolute z-10 top-4 right-4 flex">
        {Role.contains(role, PermissionRole.WRITER) && !props.readonly && (
          <Button
            className="text-t4 border-none opacity-0 group-hover:opacity-100 p-1"
            size="auto"
            onClick={doReopen}
          >
            重新打开
          </Button>
        )}
      </div>
      <div className="space-y-[15px]">
        {comments.length <= 5 || expanded ? (
          comments.map((it) => (
            <CommentItem
              hideBlockContext={props.hideBlockContext}
              discussion={discussion}
              key={it}
              commentId={it}
            />
          ))
        ) : (
          <>
            <CommentItem
              hideBlockContext={props.hideBlockContext}
              discussion={discussion}
              commentId={comments[0]!}
            />
            <div className="text-t2 text-grey4 ml-6" onClick={() => setExpanded(true)}>
              <span className="inline-block animate-hover py-1 px-2">
                查看中间{comments.length - 2}条评论
              </span>
            </div>
            <CommentItem
              hideBlockContext={props.hideBlockContext}
              discussion={discussion}
              commentId={comments[comments.length - 1]!}
            />
          </>
        )}
      </div>
    </div>
  );
};
