export enum Site {
  // 单元格
  CELL,
  // 属性页
  FIELD,
  // 卡片
  CARD,
  // 列表
  LIST,
  // 日历
  CALENDAR,
}

export interface CellViewProps {
  site: Site;
  recordId: string;
  propertyId: string;
  groupValue?: string;
  className?: string;
  groupProperty?: string;
  isLastCell?: boolean;
}

export interface CellEditorProps {
  site: Site;
  recordId: string;
  propertyId: string;
  viewId?: string;
  onUpdate(): void;
  onClose(): void;
  width?: number;
  recordIds?: string[];
}
