import { BlockType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useCallback } from 'react';
import { createSelector } from 'reselect';
import type { RootState } from 'src/redux/types';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';
import { isPageLike } from 'src/utils/block-type-utils';

interface Props {
  blockId: string;
  mindMapId: string;
}

// 算出某个节点的总和
export const CollapseCount: FC<Props> = (props) => {
  const count = useSelectCount(props.blockId, props.mindMapId);

  return <>{count}</>;
};

export const useSelectCount = (blockId: string, mindMapId: string) => {
  const selector = useCallback(
    (state: RootState) => {
      return selectCount(state, blockId, mindMapId);
    },
    [blockId, mindMapId]
  );
  return useObservableStore(selector, [blockId, mindMapId]);
};

export const selectCount = createSelector(
  (state: RootState) => state.blocks,
  (_: RootState, blockId: string) => blockId,
  (_: RootState, __: string, mindMapId: string) => mindMapId,
  (blocks, blockId, mindMapId) => {
    let count = -1; // 不算blockId自己
    const loop = (id: string) => {
      const block = blocks[id];
      if (!block) return;
      if (block.type === BlockType.PDF_ANNOTATION) return;
      count++;
      // 页面subnodes不计算节点
      if (isPageLike(block.type) && mindMapId !== blockId) {
        return;
      }
      // table下不计算节点
      if ([BlockType.TABLE].includes(block.type)) return;
      blocks[id]?.subNodes.forEach(loop);
    };
    loop(blockId);
    return count;
  }
);
