import { BlockType, PermissionRole, PermissionType } from '@next-space/fe-api-idl';
import { TurnWhiteList } from 'src/editor/component/menu-list/const';
import { checkPageSort } from 'src/hooks/block/use-insert-record';
import { getValuesFromGroupValue } from 'src/hooks/collection-view/get-values-from-groupvalue';
import {
  LIST_AFTER_BLOCK,
  LIST_AFTER_COLLECTION_VIEW_PAGESORT,
  LIST_BEFORE_BLOCK,
  LIST_BEFORE_COLLECTION_VIEW_PAGESORT,
  LIST_REMOVE_BLOCK,
  LIST_REMOVE_COLLECTION_VIEW_PAGESORT,
} from 'src/redux/actions';
import * as CollectionViewManager from 'src/redux/managers/collection-view';
import { dispatch, getState } from 'src/redux/store';
import type { NextWhere } from 'src/redux/types';
import { $currentUserCache } from 'src/services/user/current-user';
import { cleanupColumn } from './cleanup-column';
import type { convertBlock as convertBlock0 } from './convert';
import { removePageSortRecord } from './helper';
import { updateBlock } from './update';
import { setBlockPermission } from './update-permission';

export let _convertBlock: typeof convertBlock0;
export const antiCycleSet_convertBlock_0 = (convertBlock: typeof _convertBlock) => {
  _convertBlock = convertBlock;
};
const convertBlock: typeof _convertBlock = (...args) => _convertBlock(...args);

/** 移动 block */
export const moveBlock = (
  uuids: string[],
  where: NextWhere & { ignoreOp?: boolean },
  viewId?: string
) => {
  uuids.reduce((newWhere, uuid) => {
    helper(uuid, newWhere, { viewId, ignoreOp: where.ignoreOp });
    const { parentId } = newWhere;
    return {
      parentId,
      after: uuid,
      groupValue: newWhere.groupValue,
      subGroupValue: newWhere.subGroupValue,
    };
  }, where);
};

const helper = (uuid: string, where: NextWhere, opt: { viewId?: string; ignoreOp?: boolean }) => {
  if (uuid === where.parentId) return;
  const { viewId, ignoreOp } = opt;
  const { blocks } = getState();
  const block = blocks[uuid];
  if (!block) return;

  const target = blocks[where.parentId];
  if (!target) return;

  removePageSortRecord(uuid);

  // 移动到 collection 需要对 view 排序
  let needConvertBlock = false;
  if (
    (target.type === BlockType.COLLECTION_VIEW || target.type === BlockType.COLLECTION_VIEW_PAGE) &&
    viewId
  ) {
    if (!TurnWhiteList.includes(block.type)) return;

    const { collectionViews } = getState();
    const collection = blocks[target.uuid];
    const view = collectionViews[viewId];
    if (!collection || !view) return;

    const { newPageSort, needUpdate } = checkPageSort(view.pageSort, collection.subNodes);
    if (needUpdate) {
      CollectionViewManager.update(viewId, { pageSort: newPageSort });
    }

    if (newPageSort.includes(uuid)) {
      dispatch(LIST_REMOVE_COLLECTION_VIEW_PAGESORT({ uuid, viewId }));
    }
    if (where.first || where.before) {
      dispatch(LIST_BEFORE_COLLECTION_VIEW_PAGESORT({ uuid, viewId, before: where.before }));
    } else {
      dispatch(LIST_AFTER_COLLECTION_VIEW_PAGESORT({ uuid, viewId, after: where.after }));
    }

    // 如果移动到某个分组
    const newCollectionProperties = getValuesFromGroupValue({
      viewId,
      groupValue: where.groupValue,
      subGroupValue: where.subGroupValue,
      blockId: uuid,
    });

    if (Object.keys(newCollectionProperties).length > 0) {
      updateBlock(
        uuid,
        {
          data: {
            segments: newCollectionProperties.title ?? block.data.segments,
            collectionProperties: {
              ...block.data.collectionProperties,
              ...newCollectionProperties,
            },
          },
        },
        ignoreOp
      );
    }

    if (block.type !== BlockType.PAGE) {
      needConvertBlock = true;
    }
  }

  if (target.uuid === block.spaceId) {
    if (block.type === BlockType.COLLECTION_VIEW) {
      updateBlock(
        uuid,
        {
          type: BlockType.COLLECTION_VIEW_PAGE,
        },
        ignoreOp
      );
    } else if (block.type === BlockType.REFERENCE_COLLECTION) {
      updateBlock(
        uuid,
        {
          type: BlockType.REFERENCE_COLLECTION_PAGE,
        },
        ignoreOp
      );
    } else if (block.type === BlockType.MIND_MAPPING) {
      updateBlock(
        uuid,
        {
          type: BlockType.MIND_MAPPING_PAGE,
        },
        ignoreOp
      );
    }
  }

  dispatch(LIST_REMOVE_BLOCK({ uuid, parentId: where.parentId, ignoreOp }));
  if (where.first || where.before) {
    dispatch(LIST_BEFORE_BLOCK({ uuid, parentId: where.parentId, before: where.before, ignoreOp }));
  } else {
    dispatch(LIST_AFTER_BLOCK({ uuid, parentId: where.parentId, after: where.after, ignoreOp }));
  }

  if (needConvertBlock) {
    convertBlock([uuid], { type: BlockType.PAGE });
  }

  const targetId = where.before || where.after;
  if (targetId) {
    const targetBlock = blocks[targetId];
    // 移动到根
    if (targetBlock && targetBlock.parentId === targetBlock.spaceId) {
      const { permissions } = targetBlock;
      const targetIsInSpace = permissions.some((o) => o.type === PermissionType.SPACE);
      if (targetIsInSpace) {
        // 移动到空间页面时，如果没有空间权限需添加空间权限
        if (!block.permissions.some((p) => p.type === PermissionType.SPACE)) {
          setBlockPermission(uuid, {
            type: PermissionType.SPACE,
            role: PermissionRole.EDITOR,
          });
        }
      } else {
        // 移动到 个人页面 根目录需重置权限
        updateBlock(
          uuid,
          {
            permissions: [
              {
                type: PermissionType.USER,
                role: PermissionRole.EDITOR,
                userId: $currentUserCache.uuid,
              },
            ],
          },
          ignoreOp
        );
      }
    }
  }

  cleanupColumn(block.uuid, block.parentId);
};
