import { cx } from '@flowus/common/cx';
import { PermissionRole } from '@next-space/fe-api-idl';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { useCloseModal, useOpenModal } from 'src/common/components/next-modal';
import { SortableList } from 'src/common/components/sortable-list';
import { BlockDefaultIcon } from 'src/components/block-default-icon';
import { IconTrigger } from 'src/components/icon-trigger';
import { useInsertRecordUI } from 'src/editor/editor/uikit/use-insert-record-ui';
import { useCopyBlock } from 'src/hooks/block/use-copy-block';
import { useDeleteTemplateRecord } from 'src/hooks/block/use-delete-template-record';
import { useInsertTemplateRecord } from 'src/hooks/block/use-insert-template-record';
import { useOpenInRight } from 'src/hooks/page/use-open-in-right';
import { getPermissions } from 'src/hooks/share/use-permissions';
import { useTransaction } from 'src/hooks/use-transaction';
import { Modals } from 'src/modals';
import { updateBlock } from 'src/redux/managers/block/update';
import { useObservableBlock, useObservableStore } from 'src/services/rxjs-redux/hook';
import { SegmentPlainText } from 'src/views/main/aside/toc/helper';
import { useBitable } from '../context';
import { ViewIconMap, ViewNameMap } from './const';

export const TemplatePages = () => {
  const { collectionId, viewId, readonly, isLocked } = useBitable();
  const openModal = useOpenModal();
  const closeModal = useCloseModal();
  const view = useObservableStore((state) => state.collectionViews[viewId], [viewId]);
  const insertTemplatePage = useInsertTemplateRecord();
  const openInRight = useOpenInRight();
  const insertRecord = useInsertRecordUI({ ignoreSorters: true });
  const transaction = useTransaction();

  const templatePages = useObservableBlock(collectionId, (collection) => {
    return (collection?.templatePages ?? [])
      .map((pageId) => {
        const { role } = getPermissions(pageId);
        return { id: pageId, role };
      })
      .filter((it) => it.role !== PermissionRole.NONE);
  });

  const createRecord = (templateId?: string) => {
    closeModal(Modals.BITABLE_TEMPLATE_RECORDS, { noAnimation: true });
    void insertRecord({
      viewId,
      where: { first: true },
      templateId,
      openInRight: true,
    });
  };

  if (view == null) return null;
  return (
    <div className="w-[370px] next-modal">
      <div className="h-10 px-4 flex items-center text-t2">
        <Icon className="mr-1" name={ViewIconMap[view.type]} size="middle" />
        <span className="mr-2 font-medium">{ViewNameMap[view.type]}</span>
        <span className="max-w-[140px]">模板记录</span>
      </div>
      <div className={cx('max-h-[80vh] overflow-auto', templatePages.length >= 1 && 'm-2')}>
        <SortableList
          items={templatePages}
          onChange={(items) => {
            transaction(() => {
              updateBlock(collectionId, {
                templatePages: items.map((it) => it.id),
              });
            });
          }}
          renderItemContent={({ item, renderDragHandle }) => {
            return (
              <div
                className="flex items-center h-10 px-2 text-t2 animate-hover"
                onClick={() => createRecord(item.id)}
              >
                {renderDragHandle({
                  className:
                    'cursor-pointer flex items-center justify-center w-5 h-5 mr-1 text-grey4',
                })}
                <div className="flex items-center flex-1">
                  <IconTrigger
                    iconSize={18}
                    blockId={item.id}
                    className="w-5 h-5 mr-1"
                    placement="bottom-start"
                    defaultIcon={<BlockDefaultIcon uuid={item.id} size="middle" />}
                  />
                  <SegmentPlainText uuid={item.id} />
                </div>
                {!readonly && !isLocked && (
                  <button
                    className={cx(
                      'flex items-center p-1 text-t2 text-grey3 rounded animate-hover whitespace-nowrap'
                    )}
                    onClick={(event) => {
                      event.stopPropagation();
                      openModal.dropdown({
                        modalId: OPTION_MENU_MODAL_ID,
                        popcorn: event.currentTarget,
                        content: () => <OptionMenu templatePageId={item.id} role={item.role} />,
                      });
                    }}
                  >
                    <Icon size="middle" name="IcMore" />
                  </button>
                )}
              </div>
            );
          }}
        />
        {templatePages.length >= 1 && (
          <div
            className="flex items-center flex-1 h-10 pl-8 animate-hover space-x-1"
            onClick={() => createRecord()}
          >
            <Icon name="IcPageMiddleBold" size="middle" className="text-grey3" />
            <span className="text-t2">空白页面</span>
          </div>
        )}
      </div>
      {!isLocked && (
        <button
          className={cx(
            'flex items-center w-full h-10 p-2 animate-hover text-grey3',
            templatePages.length >= 1 && 'border-t border-grey6'
          )}
          onClick={() => {
            closeModal(Modals.BITABLE_TEMPLATE_RECORDS, { noAnimation: true });
            const pageId = insertTemplatePage(collectionId);
            openInRight(pageId, {
              state: {
                noticeBannerShowBackButton: true,
              },
            });
          }}
        >
          <Icon className="mr-1" name="IcAddSmallBold" size="small" />
          <span className="text-t2">创建模板记录</span>
        </button>
      )}
    </div>
  );
};

const OPTION_MENU_MODAL_ID = '57250b19-0854-498f-a71e-2ce59075cbd5';

const OptionMenu = (props: { templatePageId: string; role: PermissionRole }) => {
  const { templatePageId, role } = props;
  const openModal = useOpenModal();
  const closeModal = useCloseModal();
  const openInRight = useOpenInRight();
  const copyBlock = useCopyBlock();
  const deleteTemplatePage = useDeleteTemplateRecord();
  return (
    <div className="w-[200px] py-2 next-modal">
      <ListView
        items={[
          {
            type: ListItemType.OPERATION,
            isHidden: ![PermissionRole.WRITER, PermissionRole.EDITOR].includes(role),
            data: {
              title: '编辑',
              icon: 'IcMenuEditor',
              onClick: () => {
                closeModal(Modals.BITABLE_TEMPLATE_RECORDS, { noAnimation: true });
                closeModal(OPTION_MENU_MODAL_ID, { noAnimation: true });
                openInRight(templatePageId, {
                  state: {
                    noticeBannerShowBackButton: true,
                  },
                });
              },
            },
          },
          {
            type: ListItemType.OPERATION,
            data: {
              title: '拷贝副本',
              icon: 'IcMenuDuplicate',
              onClick: async () => {
                await copyBlock([templatePageId]);
                closeModal(OPTION_MENU_MODAL_ID);
              },
            },
          },
          {
            type: ListItemType.OPERATION,
            isHidden: ![PermissionRole.WRITER, PermissionRole.EDITOR].includes(role),
            data: {
              title: '删除',
              icon: 'IcMenuDelete',
              onClick: () => {
                closeModal(OPTION_MENU_MODAL_ID);
                openModal.warning({
                  title: <p className="mt-5 mb-10 w-60">确定删除这个模板记录吗？</p>,
                  confirmText: '确定删除',
                  confirm() {
                    deleteTemplatePage(templatePageId);
                  },
                });
              },
            },
          },
        ]}
      />
    </div>
  );
};
