import type { FC } from 'react';
import { useEffect } from 'react';
import { Button } from 'src/common/components/button';
import { publicLink } from 'src/common/const';
import { useAuthentication, useRefreshAuthentication } from 'src/services/user/authentication';
import { createAnchorDomClick } from 'src/utils/dom';
import { SettingModalCommon } from '../common';

export const Authentication: FC = () => {
  const authentication = useAuthentication();
  const isAuthentication = authentication?.status === 2;
  const refreshAuthentication = useRefreshAuthentication();

  useEffect(() => {
    void refreshAuthentication();
  }, [refreshAuthentication]);

  const openAuthentication = () => {
    createAnchorDomClick(publicLink.authentication);
  };

  return (
    <SettingModalCommon.Item
      leftChild={
        <div className="space-y-0.5">
          <div>
            {isAuthentication
              ? [
                  'Hi, ',
                  authentication.name,
                  authentication.type === 'student' ? '同学' : '老师',
                ].join('')
              : '学生教师认证'}
          </div>
          <div className="text-t4 text-grey3">
            {isAuthentication ? `${authentication.schoolName}` : '立即认证，解锁更多福利'}
          </div>
        </div>
      }
      rightChild={
        <Button onClick={openAuthentication} disable={isAuthentication}>
          {isAuthentication ? '已认证' : '立即认证'}
        </Button>
      }
    />
  );
};
