import { CollectionSchemaType } from '@next-space/fe-api-idl';
import type { VirtualElement } from '@popperjs/core';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { useCloseModal, useOpenModal } from 'src/common/components/next-modal';
import { useUpdatePropertySchema } from 'src/hooks/block/use-update-property-schema';
import { Modals } from 'src/modals';
import { bizTracker } from 'src/utils/biz-tracker';

import { ID_NUMBER_DEFAULT_WIDTH, PROPERTIES, PROPERTIES_FORM } from '../../const';
import { RollupSetting } from './rollup-setting';
import { SelectCollection } from './select-collection';
import { useUpdateColumnWidth } from 'src/hooks/block/use-update-column-width';
import { assign } from 'lodash-es';

const BASE_PROPERTY_INDEX = 11;
export const PropertyTypeList: FC<{
  collectionId: string;
  viewId: string;
  propertyId: string;
  propertyType: CollectionSchemaType;
  popcorn: VirtualElement;
  isFormView?: boolean;
}> = ({ collectionId, propertyId, propertyType, popcorn, isFormView, viewId }) => {
  const openModal = useOpenModal();
  const closeModal = useCloseModal();
  const updatePropertySchema = useUpdatePropertySchema();
  const updateWidth = useUpdateColumnWidth(viewId, propertyId);

  const handleItemClick = (selectedPropertyType: CollectionSchemaType) => {
    if (
      selectedPropertyType === propertyType &&
      selectedPropertyType !== CollectionSchemaType.RELATION
    ) {
      return;
    }

    bizTracker.event('bitalble_property_turn_type', {
      from_type: propertyType,
      target_type: selectedPropertyType,
    });

    if (selectedPropertyType === CollectionSchemaType.RELATION) {
      closeModal(Modals.BITABLE_PROPERTY_WIDGET);
      openModal.dropdown({
        popcorn,
        placement: 'bottom',
        content: ({ onCloseModal }) => (
          <SelectCollection
            collectionId={collectionId}
            propertyId={propertyId}
            closeSelf={onCloseModal}
            popcorn={popcorn}
          />
        ),
      });
    } else {
      const otherAttribute = {};
      if (selectedPropertyType === CollectionSchemaType.ID_NUMBER) {
        updateWidth(ID_NUMBER_DEFAULT_WIDTH);
        assign(otherAttribute, { name: 'ID' });
      }
      // 由于外面的属性名在dropdown消失的时候发现scheme name变了，会把输入框的name重新保存，这导致无法改变属性名，所以这里延迟一下
      setTimeout(() => {
        updatePropertySchema(collectionId, propertyId, {
          type: selectedPropertyType,
          ...otherAttribute,
        });
      }, 200);

      if (selectedPropertyType === CollectionSchemaType.ROLLUP) {
        openModal.dropdown({
          popcorn,
          placement: 'bottom',
          content: () => <RollupSetting collectionId={collectionId} propertyId={propertyId} />,
        });
      }

      // FIXME: onClose doesn't work. Use closeModal as workaround
      closeModal(Modals.BITABLE_PROPERTY_WIDGET);
    }
  };

  const systemProperties = isFormView
    ? PROPERTIES_FORM
    : PROPERTIES.filter((v) => v.type !== CollectionSchemaType.ID_NUMBER);
  const items = systemProperties.map((property) => {
    return {
      type: ListItemType.OPERATION,
      data: {
        ...property,
        hasArrow: property.type === propertyType,
        arrow: <Icon size="normal" name="IcSelectedSpace" />,
      },
    };
  });

  return (
    <div className="flex flex-col w-[200px] py-[6px] next-modal-scroll text-black">
      <div className="text-t2 text-grey3 ml-4 h-9 flex items-center">基础属性</div>
      <ListView
        items={!isFormView ? items.slice(0, BASE_PROPERTY_INDEX) : items}
        onItemClick={(res) => handleItemClick(res.data.type)}
      />
      {!isFormView && (
        <>
          <div className="text-t2 text-grey3 h-9 ml-4 flex items-center">高级属性</div>
          <ListView
            items={items.slice(BASE_PROPERTY_INDEX)}
            onItemClick={(res) => handleItemClick(res.data.type)}
          />
        </>
      )}
    </div>
  );
};
