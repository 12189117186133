import { cx } from '@flowus/common/cx';
import type { ReactNode, Ref } from 'react';
import { useState } from 'react';
import type { VirtuosoHandle } from 'react-virtuoso';
import { Virtuoso } from 'react-virtuoso';
import { sleep } from 'src/utils/async-utils';

interface Props<T> {
  items: T[];
  renderItem: (item: T, index: number) => ReactNode;
  computeItemKey: (index: number) => string;
  virtuosoRef?: Ref<VirtuosoHandle>;
  maxHeight?: number | string;
  className?: string;
  defaultHeight?: number;
}

const id = 'virtuoso-list-id';

export const VirtuosoList = <T extends {}>({
  items,
  renderItem,
  computeItemKey,
  virtuosoRef,
  maxHeight = '50vh',
  className,
  defaultHeight,
}: Props<T>): JSX.Element => {
  const [height, setHeight] = useState(defaultHeight || document.body.clientHeight / 2);

  const resetHeight = async (node: HTMLElement | Window | null) => {
    if (!node) return;
    await sleep(200);

    const virtuosoDom = document.querySelector(`.${id} [data-testid="virtuoso-item-list"]`);
    if (virtuosoDom) {
      const rect = virtuosoDom.getBoundingClientRect();
      setHeight(rect.height || 40);
    }
  };

  const itemContent = (index: number) => {
    const item = items[index];
    if (!item) return null;
    return renderItem(item, index);
  };

  return (
    <Virtuoso
      className={cx(id, 'overflow-y-auto', className)}
      data={items}
      scrollerRef={resetHeight}
      overscan={100}
      style={{ height, maxHeight }}
      ref={virtuosoRef}
      computeItemKey={computeItemKey}
      itemContent={itemContent}
    />
  );
};
