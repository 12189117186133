import { clamp } from 'lodash-es';
import type React from 'react';
import { useCallback, useContext } from 'react';
import { useSyncId } from 'src/editor/editor/plugin/sync-block-context';
import { EditableNamespace } from 'src/editor/editor/uikit/editable-context';
import { getEditorModelByEditorKey } from 'src/editor/editor/uikit/editable-models';
import { makeEditorKey } from 'src/editor/editor/uikit/editable/helper';
import type { SegmentType } from 'src/redux/types';
import { focusEditableAtByEditorKey } from '../../utils/focus-by-editor-key';

export interface EditorKeyOption {
  syncId?: string;
  namespace?: string;
}
/**
 * 如果是在block外调用这个hook，在同步块内需要传递option参数确保syncId
 * PS:一般情况是在dropdown，hoverMenu这些地方用到
 */
export const useFocusEditableByBlockId = () => {
  const namespace = useContext(EditableNamespace);
  const syncId = useSyncId();

  const focusEditableByBlockId = (
    id: string | undefined,
    at: number,
    type?: SegmentType,
    option?: EditorKeyOption
  ) => {
    if (!id) {
      return;
    }
    let editorKey: string;
    if (option) {
      // 如果option存在，则需要用option的参数生产editorKey,namespace这个参数后面如果用到的话就要补一下
      editorKey = makeEditorKey(id, option?.namespace ?? namespace, option?.syncId ?? syncId);
    } else {
      editorKey = makeEditorKey(id, namespace, syncId);
    }
    focusEditableAtByEditorKey(editorKey, at, type);
  };
  return useCallback(focusEditableByBlockId, [namespace, syncId]);
};

export type FocusEditableFun = ReturnType<typeof useFocusEditableByBlockId>;

export const useFocusEditableByMouseEvent = () => {
  const namespace = useContext(EditableNamespace);
  const syncId = useSyncId();

  const focusEditableByMouseEvent = (
    id: string | undefined,
    event: MouseEvent | React.MouseEvent,
    type?: SegmentType,
    option?: EditorKeyOption
  ) => {
    if (!id) {
      return;
    }
    let editorKey: string;
    if (option) {
      // 如果option存在，则需要用option的参数生产editorKey,namespace这个参数后面如果用到的话就要补一下
      editorKey = makeEditorKey(id, option?.namespace, option?.syncId);
    } else {
      editorKey = makeEditorKey(id, namespace, syncId);
    }
    let x = event.clientX;
    let y = event.clientY;
    requestAnimationFrame(async () => {
      if (!editorKey) return;
      const editorModel = getEditorModelByEditorKey(editorKey);
      if (!editorModel) return;

      const offset = await (async () => {
        if (x === 0 && y === 0) return 0;
        if (x === Infinity && y === Infinity) return editorModel.content.length;

        // editorModel.performChange((ctx) => {
        //   ctx.select(y === Infinity ? editorModel.content.length : 0);
        // });
        await editorModel.scrollCaretIntoViewIfNeeded();
        await new Promise((resolve) => setTimeout(resolve, 0));

        const firstRect = editorModel.getBoundingClientRectOfRange(0, 0);
        const lastRect = editorModel.getBoundingClientRectOfRange(
          editorModel.content.length,
          editorModel.content.length
        );
        if (!firstRect || !lastRect) return;

        x = clamp(x, firstRect.left + 1, lastRect.right - 1);
        y = clamp(y, firstRect.top + 1, lastRect.bottom - 1);
        return editorModel.hitTest(x, y);
      })();
      if (offset != null) {
        focusEditableAtByEditorKey(editorKey, offset, type);
      }
    });
  };
  return useCallback(focusEditableByMouseEvent, [namespace, syncId]);
};
