import { useOpenModal } from '@flowus/common/next-modal';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { message } from 'src/common/components/message';
import { request } from 'src/common/request';
import { CYCLE_STATUS_BUTTON } from 'src/feature-flags_';
import { fetchSpaceCapacity } from 'src/hooks/drive/use-fetch-space-capacity';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { useSpaceCapacity } from 'src/services/capacity';
import { bizTracker } from 'src/utils/biz-tracker';
import { v4 } from 'uuid';

export const CycleStatusButton: FC = () => {
  const { autoRenewal } = useSpaceCapacity();
  const openModal = useOpenModal();

  const handleClick = async () => {
    openModal.warning({
      title: '确认关闭自动续费？',
      async confirm() {
        const id = v4();
        message.loading({ key: id, content: '正在关闭', duration: 3000 });
        await request.infra.unSignRenewal(getCurrentSpaceId());
        await fetchSpaceCapacity();
        message.closeMessage(id);
        message.success('关闭成功');
      },
    });
  };

  // if (!autoRenewal || !CYCLE_STATUS_BUTTON) return null;

  // return (
  //   <div
  //     className="text-t2 text-grey3 px-1 inline-flex items-center space-x-1 animate-hover mx-1"
  //     onClick={handleClick}
  //   >
  //     <span>已开通自动续费</span>
  //     <Icon name="IcMenuSettings" size="small" />
  //   </div>
  // );
  return null;
};
bizTracker;
