import { createSetState, createStore } from '@flowus/common/zustand/utils';
import { useCallback } from 'react';
import { request } from 'src/common/request';
import { blocksActions } from 'src/redux/reducers/blocks';
import { setAppUiState } from 'src/services/app';
import { addFetchPageCompleteId } from 'src/services/app-support';
import { updatePageMetas } from 'src/services/page-metas';
import { sequence } from 'src/utils/async-utils';
import { judgeSharePage } from 'src/utils/getPageId';
import type { AsyncReturnType } from 'type-fest';
import { useRemoveLocalBlock } from './use-delete-page-completely';
import { useFetchBlock } from './use-fetch-block';
import { fetchSharePreviewContent } from './use-fetch-record-content';
import { usePayAccessPreviewSpaceId } from 'src/components/empty/pay-access-fee-context';
import { useDispatch } from 'react-redux';

/**
 * 获取页面内所有 block
 * - 访问页面时候使用此行为
 * - 左侧页面展开时候也用此行为
 */
const fetchers: Record<string, Promise<void> | undefined> = {};
export const useFetchPage = (checkIllegal?: boolean) => {
  const removeLocalBlock = useRemoveLocalBlock();
  const dispatch = useDispatch();
  const fetchBlock = useFetchBlock();
  const payAccessPreviewSpaceId = usePayAccessPreviewSpaceId();

  const fetchPage = (
    pageId: string,
    opts?: {
      force?: boolean;
      prefetch?: boolean;
      callback?: (res: AsyncReturnType<typeof request.editor.getDoc.raw>) => void;
    }
  ) => {
    let task = fetchers[pageId];
    const force = opts?.force ?? false;
    const prefetch = opts?.prefetch ?? false;

    if (!task) {
      task = sequence(async () => {
        if (judgeSharePage() && pageId === location.pathname.slice(1)) {
          // TODO：分享页面加上协同后可以清除这段代码
          dispatch(blocksActions.clear());
        }
        // 需要重置一下
        setAppUiState({ $fileIllegal: false });
        const res = await fetchBlock(pageId, { force });
        if (res.code !== 200) {
          if (payAccessPreviewSpaceId) {
            await fetchSharePreviewContent(pageId, payAccessPreviewSpaceId);
            return;
          }
        }

        if (!prefetch) {
          fetchers[pageId] = undefined;
        }

        if ([1407].includes(res.code)) {
          const ret = await fetchDocPublicData(pageId);
          // ret &&
          updatePageMetas({
            [pageId]: {
              ...ret,
              tag: 'NEED_PAY',
            },
          });
        } else if ([1406, 1405].includes(res.code)) {
          updatePageMetas({ [pageId]: { tag: 'NEED_PASSWORD' } });
        } else if (res.code === 1403) {
          removeLocalBlock(pageId);
          updatePageMetas({ [pageId]: { tag: 'NO_PERMISSION' } });
        } else if (res.code === 3005) {
          updatePageMetas({ [pageId]: { tag: 'NOT_FOUND' } });
          // @ts-ignore idl
        } else if (res.code === 1600) {
          if (checkIllegal ?? true) {
            // 默认check
            setAppUiState({ $fileIllegal: true });
          }
        } else if (res.code === 200) {
          updatePageMetas({ [pageId]: undefined });
        }

        if (![1407].includes(res.code) && judgeSharePage()) {
          // 非分享页面调用这个方法似乎没有什么意义,不知道分享页面调这个接口是为啥
          void fetchDocPublicData(pageId);
        }

        opts?.callback?.(res);
        addFetchPageCompleteId(pageId);
      });

      fetchers[pageId] = task;
      task.catch(() => {
        // 错了就要重置，否则每次都返回错误的promise
        fetchers[pageId] = undefined;
      });
    }

    if (!prefetch) {
      return task.then(() => {
        fetchers[pageId] = undefined;
      });
    }

    return task;
  };

  return useCallback(fetchPage, [
    checkIllegal,
    dispatch,
    fetchBlock,
    payAccessPreviewSpaceId,
    removeLocalBlock,
  ]);
};

const $docPublic = createStore<
  Record<
    string,
    AsyncReturnType<typeof request.editor.getDocPublicData> & {
      openPreview: boolean;
      accessFee: number;
      accessPageId: string;
    }
  >
>(() => ({}));

const $setDocPublic = createSetState($docPublic);

const fetchDocPublicData = async (uuid: string) => {
  const _state = $docPublic.getState()[uuid];

  if (_state) {
    return _state;
  }

  const ret = await request.editor.getDocPublicData.raw(uuid);
  if (ret.code === 200) {
    const accessFee = ret.data.publishAccessFee ?? 0;
    const accessPageId = ret.data.publishAccessPageId ?? '';

    const openPreview = ret.data.openPreview ?? false;
    if (openPreview && accessFee) {
      // 拉取部分块（预览需求） 内部已经更新到 store
      await fetchSharePreviewContent(uuid, ret.data.spaceId);
    }

    const obj = {
      ...ret.data,
      openPreview,
      accessFee,
      accessPageId,
    };

    $setDocPublic((pre) => {
      return {
        ...pre,
        [uuid]: obj,
      };
    });

    return obj;
  }
};

export const useDocPublicData = (uuid: string) => {
  return $docPublic((state) => state[uuid]);
};
