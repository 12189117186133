import { cx } from '@flowus/common/cx';
import { CollectionSchemaType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useBiTableGroupsAndProvideRecordWalker } from 'src/hooks/collection-view/table-groups';
import type { CollectionViewProps } from 'src/views/main/page-bitable/types';
import { useBitable } from '../context';
import { useUpdateGroups } from '../hooks/ues-check-group';
import { BoardGroups } from './groups';
import './style.css';
import { BoardSubGroups } from './sub-group';
import { useCardPageContent } from './use-card-page-content';

export const BoardView: FC<CollectionViewProps> = () => {
  const { viewId, readonly, managerReadonly, embed } = useBitable();
  const biTableGroups = useBiTableGroupsAndProvideRecordWalker(viewId);
  useUpdateGroups(biTableGroups);

  const container = useRef<HTMLDivElement | null>(null);
  const [boardContainer, setContainer] = useState(container.current);
  useCardPageContent(boardContainer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!boardContainer) {
      setContainer(container.current);
    }
  });

  if (!biTableGroups) return null;

  if (biTableGroups.withoutValidGroup || biTableGroups.needUpdateGroups) {
    const groupType = biTableGroups.newViewGroups?.[0]?.propertyType;
    const isSelectType = groupType === CollectionSchemaType.SELECT;

    if (isSelectType) {
      if (readonly || managerReadonly) {
        return (
          <div className="text-t2 h-[35px] flex items-center pl-2">
            当前视图无有效分组属性，无法展示
          </div>
        );
      }
      return null;
    }
  }

  if (biTableGroups.needUpdateSubGroups && !(readonly || managerReadonly)) {
    return null;
  }

  return (
    <div
      ref={container}
      style={{ width: 'fit-content', minWidth: '100%' }}
      className={cx(!embed && '-mr-24 pr-24 box-content sm:-mr-12 sm:pr-12')}
    >
      {biTableGroups.hasValidSubGroup ? <BoardSubGroups /> : <BoardGroups />}
    </div>
  );
};
