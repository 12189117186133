import type { FC } from 'react';

export const UpgradeSuggest: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <div className="text-grey3 text-t2 p-3">
      升级「个人专业版/团队版」可体验完整功能
      <span className="ml-1 text-blue_cobalt animate-click" onClick={onClick}>
        立即升级
      </span>
    </div>
  );
};
