import { BlockType } from '@next-space/fe-api-idl';
import type { IElementComponent, IElementMeta } from '@next-space/fe-inlined';
import { normalizeFormat, registerElementMeta } from '@next-space/fe-inlined';
import { lookupEditorElement } from '@next-space/fe-inlined/dom-utils';
import { useAtomValue } from 'jotai';
import { useCallback, useContext, useRef } from 'react';
import { NoAccess } from 'src/components/no-access';
import { $blockId, $rowId, BLOCK_SCOPE, ROW_SCOPE } from 'src/editor/editor/uikit/block-states';
import { usePickBlock } from 'src/utils/pick-block';
import { $pageId, PAGE_SCOPE } from 'src/views/main/page-states';
import { EditableContext } from '../uikit/editable-context';
import { getEditorModelByEditorKey } from '../uikit/editable-models';
import { INLINE_LINK_PAGE_TAG, INLINE_MENTION_BLOCK_TAG } from './const';
import { InlinePage } from './inline-page';
import type { InlinePlugin } from './inline-plugin';
import { useComputeTextStyle } from './utils';

const INLINE_LINK_PAGE_META: IElementMeta = {
  tag: INLINE_LINK_PAGE_TAG,
  hasContent: false,
  setFormat: (element, format) => {
    element.props.textFormat = normalizeFormat(format, element.props.textFormat as any);
  },
};
const INLINE_MENTION_BLOCK_META: IElementMeta = {
  tag: INLINE_MENTION_BLOCK_TAG,
  hasContent: false,
  setFormat: (element, format) => {
    element.props.textFormat = normalizeFormat(format, element.props.textFormat as any);
  },
};

registerElementMeta(INLINE_LINK_PAGE_META);
registerElementMeta(INLINE_MENTION_BLOCK_META);

/** 行内引用页面 */
const InlineLinkPage: IElementComponent = ({
  pageId,
  /** 块访问链接 */
  blockId,
  htmlDataProps,
  textFormat: textFormat0,
  baseOffset,
  text,
}) => {
  const block = usePickBlock(pageId as string, []);
  const blockParent = usePickBlock(block?.parentId, []);
  const parentId = useAtomValue($blockId, BLOCK_SCOPE);
  const rowId = useAtomValue($rowId, ROW_SCOPE);
  const realPageId = useAtomValue($pageId, PAGE_SCOPE);
  const { interactable } = useContext(EditableContext);
  const textFormat = normalizeFormat(textFormat0 as any);
  const textStyle = useComputeTextStyle(textFormat);
  const blockRef = useRef<HTMLLIElement>(null);

  const getEditorModel = useCallback(() => {
    const editorElement = lookupEditorElement(blockRef.current);
    if (!editorElement) return;
    const editorKey = editorElement.getAttribute('data-editor');
    if (!editorKey) return;
    return getEditorModelByEditorKey(editorKey);
  }, []);

  if (!block) {
    // 未分享的引用页面展示无访问权限
    return (
      <span className="mx-[2px]" {...htmlDataProps} ref={blockRef}>
        <NoAccess id={pageId as string} inline={true} />
      </span>
    );
  }

  // 这个逻辑我看不太懂，看懂的请加一下注释。谢谢
  let isLinkPage = parentId ? block.parentId !== parentId : block.parentId !== realPageId;

  if (blockParent?.type === BlockType.TABLE_ROW) {
    isLinkPage = block.parentId !== rowId;
  }

  const styles = { ...textStyle };
  if (textFormat.lineThrough) {
    if (styles.textDecoration != null) {
      styles.textDecoration += ' line-through';
    } else {
      styles.textDecoration = 'line-through';
    }
  }

  return (
    <span className="mx-[2px]" {...htmlDataProps} style={styles} ref={blockRef}>
      <InlinePage
        uuid={pageId as string}
        blockId={blockId as string}
        isLinkPage={isLinkPage}
        interactable={interactable}
        autoIconSize={true}
        format={textFormat0}
        getEditorModal={getEditorModel}
        baseOffset={baseOffset}
        aliasText={text as string}
      />
    </span>
  );
};

export const LinkPageInlinePlugin: InlinePlugin = {
  elementMeta: INLINE_LINK_PAGE_META,
  initialize(api) {
    api.setElementComponent(this.elementMeta.tag, InlineLinkPage);
  },
};

export const LinkBlockInlinePlugin: InlinePlugin = {
  elementMeta: INLINE_MENTION_BLOCK_META,
  initialize(api) {
    api.setElementComponent(this.elementMeta.tag, InlineLinkPage);
  },
};
