import { getFormatImageUrl, NEED_CONVERT_IMAGE_FORMAT } from '@flowus/common';
import { fixUrl } from '@flowus/common/embed-website';
import { BlockType } from '@next-space/fe-api-idl';
import { useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { segmentsToText } from 'src/editor/utils/editor';
import { cache } from 'src/redux/store';
import { sequence, sleep } from 'src/utils/async-utils';
import { getFileNameInfo } from 'src/utils/file';
import { FileRegex } from '@flowus/common/regex';
import { usePickBlock } from 'src/utils/pick-block';
import { urlFetcher } from 'src/utils/url-fetcher';
import { $searchParams } from 'src/utils';
import { getPermissions, usePermissions } from '../share/use-permissions';
import { afterExpire } from '@flowus/common/hooks/url-fetcher';

interface Option {
  disableLocalUrl?: boolean;
  isImage?: boolean;
  ossName?: string;
}
/**
 * 仅适用于fileBlock 和externalBlock
 * setUrl(undefined);是为了兼容undo情况
 */
export const useResource = (uuid: string, option?: Option) => {
  const block = usePickBlock(uuid, ['data'], ['segments', 'caption', 'ossName', 'localUrl']);
  const isAnnotation = block?.type === BlockType.PDF_ANNOTATION;
  const ossName = option?.ossName ?? block?.data.ossName;
  const [loading, setLoading] = useState(Boolean(ossName));
  const [url, setUrl] = useState<string>();
  const [isOssNameUrl, setIsOssNameUrl] = useState(false);
  const ossFileName = ossName ?? segmentsToText(block?.data.segments);
  const { extName } = getFileNameInfo(ossFileName);
  const isOffice = FileRegex.office.test(extName);
  const isImage = option?.isImage || FileRegex.image.test(extName);
  const { shared, accessFee, password } = usePermissions(uuid);
  const isShare = !accessFee && shared && !password;
  const disableLocalUrl =
    option?.disableLocalUrl || isOffice || NEED_CONVERT_IMAGE_FORMAT.includes(extName);

  useEffect(() => {
    if (url) return;
    const run = () => {
      const block = cache.blocks[uuid];
      if (!block) {
        setLoading(false);
        return;
      }

      if (block.type === BlockType.EXTERNAL_FILE) {
        batch(() => {
          setLoading(false);
          if (block.data.link) {
            setUrl(fixUrl(block.data.link));
          } else {
            setUrl(undefined);
          }
        });
        return;
      }

      if (isAnnotation) {
        setLoading(true);

        const fileName = segmentsToText(block?.data.segments) || ossFileName;
        void sequence(async () => {
          if (!block.data.pdfAnnotation?.ossName) return;
          const res = await urlFetcher.fetchDownloadUrl({
            blockId: uuid,
            ossName: block.data.pdfAnnotation?.ossName,
            fileName,
            opt: { isShare },
          });
          if (res) {
            setUrl(res);
          }
          setIsOssNameUrl(true);
          setLoading(false);
        }, 'oss');

        return;
      }

      if (!ossName && block.data.localUrl && !disableLocalUrl) {
        batch(() => {
          setLoading(false);
          setUrl(undefined);
        });
        return;
      }

      if (!block?.data.localUrl) {
        setLoading(true);
      }

      void (async () => {
        if (!ossName) {
          setLoading(false);
          return;
        }
        // 因为新创建的块(特殊图片格式无法本地查看)立刻拉地址可能拉不到，报错的话需要sleep再拉一次
        for (let i = 0; i < 2; i++) {
          if (!$searchParams.print && block?.data.localUrl) {
            await sleep(1000);
          }
          let res = '';
          if (isImage) {
            res = await urlFetcher.fetchImageUrl({
              blockId: block.uuid,
              ossName,
              opt: { isShare },
            });
          } else {
            const fileName = segmentsToText(block?.data.segments) || ossFileName;
            res = await urlFetcher.fetchDownloadUrl({
              blockId: block.uuid,
              ossName,
              fileName,
              opt: { isShare },
            });
          }
          if (res) {
            setUrl(res);
            setIsOssNameUrl(true);
            break;
          }
        }
        setLoading(false);
      })();
    };

    run();
    return afterExpire(() => {
      setIsOssNameUrl(false);
      setUrl(undefined);
    });
  }, [disableLocalUrl, isAnnotation, isImage, isShare, ossFileName, ossName, shared, url, uuid]);

  return {
    imageUrl: url ? getFormatImageUrl(url, extName) : block?.data.localUrl ?? '',
    url: url || block?.data.localUrl || '', // 保证返回的至少是空串而不是undefined
    loading,
    isOssNameUrl,
  };
};

export const getDownloadUrl = async (uuid: string) => {
  const block = cache.blocks[uuid];
  if (!block || !block.data.ossName) return;
  const fileName = segmentsToText(block?.data.segments);

  const res = await urlFetcher.fetchDownloadUrl({
    blockId: block.uuid,
    ossName: block.data.ossName,
    fileName,
    opt: {
      isShare: getPermissions(uuid).shared,
    },
  });

  return res;
};
