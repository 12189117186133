import { without } from 'lodash-es';
import { batch } from 'react-redux';
import { getAncestors } from 'src/hooks/block/use-get-ancestors';
import { getCurrentSpace } from 'src/hooks/space';
import { isFavoritePage } from 'src/hooks/user';
import { getUpdatedAtAndUpdatedBy } from 'src/hooks/user/get-updated-user';
import * as TemplateManager from 'src/redux/managers/template';
import { $currentUserCache } from 'src/services/user/current-user';
import { LIST_REMOVE_BLOCK, LIST_REMOVE_FAVORITE, UPDATE_BLOCK } from '../../actions';
import { dispatch, getState } from '../../store';
import { cleanupColumn } from './cleanup-column';
import { removePageSortRecord } from './helper';
import { updateTime } from './update-time';

/** 删除 Block */

// collectionId
export const archiveBlock = (uuid: string | string[]) => {
  const ids = Array.isArray(uuid) ? uuid : [uuid];
  const { blocks } = getState();
  const user = $currentUserCache;

  batch(() => {
    ids.forEach((id) => {
      const block = blocks[id];
      if (!block) return;
      const space = getCurrentSpace();
      if (space.uuid === block.uuid) return;

      removePageSortRecord(id);

      dispatch(
        UPDATE_BLOCK({
          uuid: id,
          patch: { status: -1, ...getUpdatedAtAndUpdatedBy() },
        })
      );
      if (block.isTemplate) {
        const collectionId = block.parentId;
        const collection = getState().blocks[collectionId];
        if (collection != null) {
          const oldTemplatePages = collection.templatePages ?? [];
          const templatePages = without(oldTemplatePages, id);
          dispatch(
            UPDATE_BLOCK({
              uuid: collectionId,
              patch: { templatePages },
            })
          );
        }
      } else {
        dispatch(LIST_REMOVE_BLOCK({ uuid: id }));
      }
      if (isFavoritePage(id)) {
        dispatch(LIST_REMOVE_FAVORITE({ parentId: user.currentSpaceViewId, uuid: id }));
      }
      /** 如果是自定义模板需要删掉 */
      space?.customTemplates?.forEach((templateId) => {
        /**
         * case:如果模板的祖先被删掉的话，该模板会被取消
         */
        const ancestors = getAncestors(templateId);

        if (ancestors.has(id)) {
          TemplateManager.remove(templateId, {
            parentId: space.uuid,
          });
        }
      });
      updateTime(id);
      cleanupColumn(block.uuid, block.parentId);
    });
  });
};
