import isHotkey from 'is-hotkey';
import type { ComponentProps, ReactNode } from 'react';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { cx } from '../cx';

const ButtonSize = {
  auto: 'h-full w-full min-h-[32px] text-t2-medium',
  middle: 'w-80 h-12 text-t1-medium',
  large: 'h-8 px-3 text-t2-medium',
};

const ColorType = {
  primary: 'bg-black border-black text-white1',
  secondary: 'bg-white dark:bg-transparent text-black border-grey5',
  red: 'text-red border-red',
  white: 'bg-transparent text-white-base border-white',
  black: 'bg-transparent text-black border-black',
  base_white: 'bg-white text-black-base border-white',
  active: 'bg-active_color text-white border-transparent',
  active_line: 'bg-transparent text-active_color border-active_color',
  grey: 'bg-grey9 text-black border-transparent',
};

const ColorHover = {
  primary: 'hover:opacity-90',
  secondary: 'hover:brightness-90 dark:hover:bg-black_003',
  red: 'hover:bg-red/10',
  white: 'hover:bg-black_003',
  black: 'hover:bg-black_003',
  base_white: 'hover:brightness-90',
  active: 'hover:brightness-90 dark:hover:brightness-110',
  active_line: 'hover:brightness-90 dark:hover:brightness-110',
  grey: 'hover:brightness-90 dark:hover:brightness-110',
};

export interface ButtonProps
  extends Pick<ComponentProps<'button'>, 'onClick' | 'style' | 'ref' | 'type' | 'id'> {
  ref?: ComponentProps<'button'>['ref'];
  autoFocus?: boolean;
  disable?: boolean;
  className?: string;
  colorType?: keyof typeof ColorType;
  size?: keyof typeof ButtonSize;
  children?: ReactNode | object;
  hidden?: boolean;
}

/**
 * 通用button，默认是nextSpace设计样式，默认width: 100%
 * 自定义宽度需要填写className，自定义text样式需要textStyle
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    colorType = 'secondary',
    disable,
    onClick,
    autoFocus = false,
    size = 'large',
    className,
    children,
    hidden,
    ...args
  } = props;
  const divRef = useRef<HTMLButtonElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  useImperativeHandle(ref, () => divRef.current!, []);

  if (hidden) return null;

  return (
    <button
      {...args}
      ref={divRef}
      data-color-type={colorType}
      onClick={onClick}
      autoFocus={autoFocus}
      disabled={disable}
      onKeyDown={(e) => {
        if (isHotkey('enter')(e)) {
          e.currentTarget.click();
        }
      }}
      className={cx(
        'flex justify-center items-center rounded border outline-none text-ellipsis select-none',
        ButtonSize[size],
        ColorType[colorType],
        disable ? 'opacity-30 cursor-not-allowed' : ColorHover[colorType],
        !disable && 'animate-click',
        className
      )}
    >
      {<>{children}</>}
    </button>
  );
});
