import { cx } from '@flowus/common/cx';
import type { PDFViewer } from 'pdfjs-dist/web/pdf_viewer';
import type { FC } from 'react';
import { useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';
import { useOpenModal } from 'src/common/components/next-modal';
import { emitter } from '@flowus/common/utils/emitter';

import { AnnotationColor } from '../type';

export const ColorMap = [
  { name: '蓝色', value: AnnotationColor.BLUE },
  { name: '绿色', value: AnnotationColor.GREEN },
  { name: '黄色', value: AnnotationColor.YELLOW },
  { name: '橙色', value: AnnotationColor.ORANGE },
  { name: '粉红色', value: AnnotationColor.PINK },
  { name: '粉紫色', value: AnnotationColor.PURPLE },
];

interface Props {
  uuid?: string;
  closeModal: () => void;
  pdfViewer: PDFViewer;
  color: string;
  setColor: React.Dispatch<React.SetStateAction<string>>;
}

export const ColorSetting: FC<Props> = ({ closeModal, color, setColor }) => {
  const items = ColorMap.map((item) => {
    return {
      type: ListItemType.OPERATION,
      data: {
        title: item.name,
        value: item.value,
        icon: (
          <span
            className="rounded-full w-[18px] h-[18px]"
            style={{ backgroundColor: item.value }}
          />
        ),
        arrow: <Icon name="IcSelectedSpace" size="normal" />,
        hasArrow: color === item.value,
      },
    };
  });

  const handleClick = ({ data }: ListItem<any>) => {
    emitter.emit('pdfCursorColor', data.value);
    setColor(data.value);
    closeModal();
  };

  return (
    <ListView className="next-modal w-[160px] py-[5px]" items={items} onItemClick={handleClick} />
  );
};

interface ColorButtonProps {
  className?: string;
  pdfViewer: PDFViewer;
}
export const ColorButton: FC<ColorButtonProps> = ({ className, pdfViewer }) => {
  const openModal = useOpenModal();
  const [color, setColor] = useState(AnnotationColor.YELLOW);

  const openSetting = (event: React.MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom',
      offset: [0, 10],
      content: ({ onCloseModal }) => (
        <ColorSetting
          closeModal={onCloseModal}
          pdfViewer={pdfViewer}
          color={color}
          setColor={setColor}
        />
      ),
    });
  };

  return (
    <div className={cx('flex items-center animate-hover p-1', className)} onClick={openSetting}>
      <span className="rounded-full w-[18px] h-[18px]" style={{ backgroundColor: color }} />
      <Icon size="xxsmall" name={'IcArrow'} className="ml-1" />
    </div>
  );
};
