import { Button } from '@flowus/common/components/button';
import { VITE_CONTACT_ADMIN_LINK } from '@flowus/common/const';
import { cx } from '@flowus/common/cx';
import type { PlanDTO } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { useModel } from 'src/common/create-model';
import { request } from 'src/common/request/request';
import { PayCycleButton, dateKey2Unit, usePayCycleButton } from 'src/components/pay-cycle-button';
import { useOpenUpgradeAiModal } from 'src/components/select-upgrade-plan/upgrade-ai';
import { useEnableAI } from 'src/hooks/block/use-enable-AI';
import { useCurrentSpace } from 'src/hooks/space';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { useIsAiPlan } from 'src/services/capacity';
import { bizTracker } from 'src/utils/biz-tracker';
import { SettingModalCommon, SettingProvider } from '../common';
import { OpenSettingFrom } from '../type';
import { useCloseSettingModal } from '../use-open-setting-modal';
import { useOpenWelfareCode } from '../use-open-welfare-code';
import { PriceTable } from './price-data';
import { UpgradeSettingContent } from './upgrade-setting-content';
import { UpgradeSettingAiHeader } from './upgrade-setting-header';

/** 升级 */
export const UpgradeSetting: FC = () => {
  const { upgradeAiPanel, setUpgradeAiPanel } = useModel(SettingProvider);
  const openWelfareCode = useOpenWelfareCode();
  const closeSettingModal = useCloseSettingModal();

  const content = useMemo(() => {
    if (__PRIVATE__) {
      if (VITE_CONTACT_ADMIN_LINK) {
        return (
          <div className="py-2.5">
            如需扩展空间配置，请
            <a href={VITE_CONTACT_ADMIN_LINK} target="_blank" className="text-active_color mx-2">
              联系管理员
            </a>
          </div>
        );
      }
      return '如需扩展空间配置，请联系管理员';
    }

    return upgradeAiPanel ? <AiUpgradeSetting /> : <SpaceUpgradeSetting />;
  }, [upgradeAiPanel]);

  return (
    <>
      <div className={cx(SettingModalCommon.commonStyles.container, 'pb-0 px-[30px]')}>
        <SettingModalCommon.Header
          title={
            <div
              className={cx('flex items-center', upgradeAiPanel && 'animate-click')}
              onClick={() => setUpgradeAiPanel(false)}
            >
              {upgradeAiPanel && <Icon name={'IcArrowDateBack'} size="middle" />}
              <span>空间升级</span>
            </div>
          }
          addonAfter={
            !__BUILD_IN__ && (
              <div className="text-active_color animate-click" onClick={openWelfareCode}>
                空间激活码
              </div>
            )
          }
        />
        {content}
      </div>
      <SettingModalCommon.FooterBar cancelText="关闭" cancel={() => closeSettingModal()} />
    </>
  );
};

/** 升级 空间 面板*/
const SpaceUpgradeSetting: FC = () => {
  const currentSpace = useCurrentSpace();
  const { isFrom } = useModel(SettingProvider);
  const { enableAI } = useEnableAI();

  useEffect(() => {
    // 打开次弹窗tab的途径，空或者package则视为切换setting时打开的
    bizTracker.event('package_show', {
      is_from: isFrom === OpenSettingFrom.package ? 'set' : isFrom,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSpace.uuid]);

  return (
    <>
      {enableAI && <UpgradeSettingAiHeader />}
      <div className="border border-grey5 mb-5">
        <div className="overflow-x-auto">
          <UpgradeSettingContent />
        </div>
      </div>
    </>
  );
};

/** 升级 AI 面板 BuildIn专属 */
const AiUpgradeSetting: FC = () => {
  const { switchPayType, setSwitchPayType } = usePayCycleButton({ defaultTimeType: 'month' });
  const isAiPlan = useIsAiPlan();
  const openUpgradeAiModal = useOpenUpgradeAiModal();
  const [planList, setPlanList] = useState<PlanDTO[]>([]);

  const dateUnit = dateKey2Unit(switchPayType);
  const getPlan = useCallback(
    (monthNum: number) => {
      return planList.find((i) => i.monthNum === monthNum);
    },
    [planList]
  );

  const price = useMemo(
    () => getPlan(switchPayType === 'year' ? 12 : 1)?.currentPrice,
    [switchPayType, getPlan]
  );

  useEffect(() => {
    void request.infra.aiUsagePlanList(getCurrentSpaceId()).then((res) => {
      if (res.list) {
        setPlanList(res.list);
      }
    });
  }, []);

  const priceData = useMemo(() => {
    return [
      {
        title: '免费权益',
        price: '0',
        unit: `/空间/${dateUnit}`,
        btn: isAiPlan ? null : (
          <Button disable className="w-full">
            当前版本
          </Button>
        ),
      },
      {
        title: '专业权益',
        price,
        unit: `/空间/${dateUnit}`,
        btn: (
          <Button colorType="active" className="w-full" onClick={() => openUpgradeAiModal()}>
            {isAiPlan ? '续费' : '升级'}
          </Button>
        ),
      },
    ];
  }, [dateUnit, isAiPlan, price, openUpgradeAiModal]);

  return (
    <div className="border mt-2.5">
      <div
        className={cx(
          'grid grid-cols-3 h-44 divide-x border-b opacity-0 transition-opacity',
          planList.length && 'opacity-100'
        )}
      >
        <PayCycleButton payType={switchPayType} onSwitch={setSwitchPayType} />
        {priceData.map((item, index) => (
          <div
            key={item.title}
            className={cx('p-2.5 space-y-5', index % 2 === 0 && 'bg-black_003')}
          >
            <div className="text-h4 text-black">{item.title}</div>
            <div>
              <span className="text-h2 text-black">${item.price}</span>
              <span className="text-grey4 ml-1">{item.unit}</span>
            </div>
            {item.btn}
          </div>
        ))}
      </div>
      <PriceTable type="ai" />
    </div>
  );
};
