import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { useContext, useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { Icon } from 'src/common/components/icon';
import { message } from 'src/common/components/message';
import { useOpenModal } from 'src/common/components/next-modal';
import type { request } from 'src/common/request';
import { useOpenBlockRightMenuList } from 'src/editor/editor/uikit/hover-menu/block-menu-list/hook';
import { useGetBackLink } from 'src/hooks/block/use-get-backlink';
import { Modals } from 'src/modals';
import { useCloseBlockMenuList } from 'src/redux/managers/ui';
import {
  setAppUiState,
  useIsEditingPage,
  useSetIsEditingPage,
  useShowToolbarInfoById,
} from 'src/services/app/hook';
import { copyBlockToClipBoard } from 'src/utils/block-utils';
import { IsInRightContext } from 'src/utils/right-utils';
import { SyncBackList } from './sync-back-list';

interface Props {
  syncBlockId: string; // 同步块id
  blockId: string; // 同步块或者影子块
  backLinks: Awaited<ReturnType<typeof request.editor.getDocBacklinks>>['results'];
  total: number;
  closeSyncMenu: () => void;
  updateSyncInfo: (backLinks: Props['backLinks'], total: number) => void;
}
export const SyncMenu: FC<Props> = (props) => {
  const { blockId, syncBlockId } = props;
  const [backLinks, setBackLinks] = useState(props.backLinks);
  const [totalPageCount, setTotalPageCount] = useState(props.total);
  const closeBlockMenuList = useCloseBlockMenuList();
  const openBlockRightMenuList = useOpenBlockRightMenuList();
  const openModal = useOpenModal();
  const isEditingPage = useIsEditingPage();
  const getBackLink = useGetBackLink();
  const rightHistory = useContext(IsInRightContext);
  const setIsEditingPage = useSetIsEditingPage();

  useEffect(() => {
    // 需要重置这个值，否则刚粘贴过来的会认为正在编辑中。
    setIsEditingPage(false);
    if (backLinks.length === 0) {
      setTimeout(async () => {
        const res = await getBackLink(blockId, syncBlockId);
        if (!res) return;
        batch(() => {
          setBackLinks(res.result);
          setTotalPageCount(res.pageCount);
        });
        props.updateSyncInfo(res.result, res.pageCount);
      }, 1000);
    }
  }, [backLinks.length, blockId, getBackLink, props, setIsEditingPage, syncBlockId]);

  useEffect(() => {
    return () => {
      openModal.closeModal(Modals.SYNC_BLACK_LINK);
    };
  }, [openModal]);

  // 是否是要隐藏自己（1.编辑的时候 2.toolbar显示的时候）
  const hidden = useShowToolbarInfoById(blockId) || isEditingPage;
  return (
    <div
      data-no-cancel-selected
      className={cx(
        'flex items-center border border-grey6 rounded-sm shadow-modal bg-white1 text-t2 h-9 cursor-pointer',
        `${hidden ? 'hidden' : 'duration-300 opacity-100'}`
      )}
    >
      <div
        className="flex items-center p-2 animate-hover"
        onClick={async (e) => {
          // 需要每次都拿最新的，notion也差不多如此
          const rect = e.currentTarget.getBoundingClientRect();
          const res = await getBackLink(blockId, syncBlockId);
          if (!res) return;
          batch(() => {
            setBackLinks(res.result);
            setTotalPageCount(res.pageCount);
          });
          props.updateSyncInfo(res.result, res.pageCount);
          openModal.dropdown({
            modalId: Modals.SYNC_BLACK_LINK,
            offset: [-19, 4],
            placement: 'bottom',
            popcorn: {
              getBoundingClientRect: () => rect,
            },
            content() {
              return (
                <IsInRightContext.Provider value={rightHistory}>
                  <SyncBackList backLinks={res.result} total={res.pageCount} />
                </IsInRightContext.Provider>
              );
            },
          });
        }}
      >
        {totalPageCount <= 0 && <div>暂无页面同步</div>}
        {!(totalPageCount <= 0) && `${totalPageCount}个页面正在同步`}
        <Icon name={'IcArrow'} size="normal" className="text-grey4 pl-1"></Icon>
      </div>
      <div className="w-[1px] h-9 bg-grey6"></div>

      <div
        className="animate-hover p-2"
        onClick={() => {
          void copyBlockToClipBoard(blockId);
          message.success({
            content: '已复制，你可以粘贴到任意页面生成同步块',
          });
          setIsEditingPage(true);
          setAppUiState({ $focusedInSyncBlockId: '' });
          closeBlockMenuList();
        }}
      >
        复制并同步
      </div>
      <div className="w-[1px] h-9 bg-grey6"></div>
      <div
        className="animate-hover flex items-center p-2"
        onClick={(e) => {
          openBlockRightMenuList({
            blockId,
            blockNode: e.currentTarget,
            placement: 'bottom-start',
            offset: [0, -5],
          });
        }}
      >
        <Icon name={'IcMore'} size="middle"></Icon>
      </div>
    </div>
  );
};
