import { BlockType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { listViewNormalClassName } from 'src/common/components/list-view/helper';
import type { ListItem } from 'src/common/components/list-view/types';
import { PRIORITY_DIALOG } from 'src/common/utils/global-listener-helper';
import { FeatureFlags } from 'src/feature-flags';
import { cache } from 'src/redux/store';
import { $appUiStateCache } from 'src/services/app';
import { BitableMenu } from './bitable-menu';
import { AiBitableEntry, AIEditorScene, AIEditType, AiMindMapEntry, isAiMindMap } from './const';
import { MindMapMenu } from './mindmap-menu';
import { checkMindMap } from './utils';
import { PRODUCT_AI_TITLE, PRODUCT_NAME } from 'src/const/title';
import { usePromptStore } from '../prompt-editor/store';

export const SpecialWriteType = [
  AIEditType.Blog,
  AIEditType.Brainstorm,
  AIEditType.Outline,
  AIEditType.Story,
  AIEditType.DailyNotes,
  AIEditType.ProsAndConsList,
  AIEditType.OtherLanguage,
];

interface EditTypeInfo {
  prompt: string;
  type?: string;
  promptList?: {
    title: string;
    placeholder: string;
  }[];
}
// temp1.filter(item => item.scene === 'draft')
const EditTypeMapPrompt: Record<string, EditTypeInfo | undefined> = {
  [AIEditType.Blog]: { prompt: '写文章，关于', type: 'blog' },
  [AIEditType.Brainstorm]: { prompt: '头脑风暴，关于', type: 'brainstorm' },
  [AIEditType.Outline]: { prompt: '写大纲，关于', type: 'outline' },
  [AIEditType.Story]: { prompt: '写故事，关于', type: 'story' },
  [AIEditType.DailyNotes]: { prompt: '日常小记，关于', type: 'daily' },
  [AIEditType.ProsAndConsList]: { prompt: '分析优缺点，关于', type: 'prosConsList' },
  [AIEditType.ActionItems]: { prompt: '列出执行步骤，关于' },
  [AIEditType.Explain]: { prompt: '解释一下，关于' },
  [AIEditType.OtherLanguage]: { prompt: '把当前语言翻译成：' },
  [AIEditType.MindMap_Outline]: { prompt: '写大纲，关于', type: AIEditType.MindMap_Outline },
  // [AIEditType.Bitable_Plan]: {
  //   prompt: '写一份计划，关于',
  //   promptList: [
  //     {
  //       title: '旅行计划',
  //       placeholder: '上海旅行计划。首列为景点名称，包含评分、图片、门票、简介、开放时间、交通线路',
  //     },
  //     {
  //       title: '备考计划',
  //       placeholder: '雅思备考计划。首列为阶段内容，包含各科计划、时间周期',
  //     },
  //     {
  //       title: '工作计划',
  //       placeholder: '小程序产品研发计划。首列为任务名称',
  //     },
  //   ],
  // },
  // [AIEditType.Bitable_List]: {
  //   prompt: '写一份清单，关于',
  //   promptList: [
  //     {
  //       title: '读书清单',
  //       placeholder: '2022年度必读书单。首列为书名，包含封面、评分、阅读状态',
  //     },
  //     {
  //       title: '观影清单',
  //       placeholder:
  //         '2022年高分悬疑、科幻电影。首列为电影名，包含海报、评分、观影状态。评分以纯数字显示',
  //     },
  //     {
  //       title: '购物清单',
  //       placeholder: '新房电器采购清单。首列为电器名称，包含购买状态',
  //     },
  //   ],
  // },
  // [AIEditType.Bitable_Task_Manage]: {
  //   prompt: '写一份任务管理，关于',
  //   promptList: [
  //     {
  //       title: '待办事项',
  //       placeholder: '研发部门待办事项，包含优先级，优先级以P0、P1...格式呈现，P0为最高优先级',
  //     },
  //     {
  //       title: '项目管理',
  //       placeholder: '项目进度管理，以百分比形式展现进度',
  //     },
  //     {
  //       title: '缺陷池管理',
  //       placeholder:
  //         '缺陷池管理，列举一些bug示例，包含优先级，优先级以P0、P1...格式呈现，P0为最高优先级',
  //     },
  //   ],
  // },
  // [AIEditType.Bitable_Other]: { prompt: '告诉AI写什么...' },
};

// $aiCommands
const bitableIds: Record<string, string> = {
  plan: '5f4bf941-d35e-4d1e-96c3-976a4f8ab1d1',
  list: 'c9f301a3-35ec-4ab9-8b2d-ef74340a4701',
  task: '6485520b-3dbc-469a-99b6-c7ecf4217a56',
  other: 'b177392a-b4c8-45cf-b834-1378d317aa94',
};

export const getEditTypeMapPrompt = (editType: string) => {
  const editMap: Record<string, EditTypeInfo> = {
    plan: { prompt: '写一份计划，关于' },
    list: { prompt: '写一份清单，关于' },
    task: { prompt: '写一份任务管理，关于' },
    other: { prompt: `告诉 ${PRODUCT_NAME} 写什么...` },
    [AIEditType.MindMap_Outline]: { prompt: '写一份大纲，关于' },
    [AIEditType.MindMap_Plan]: { prompt: '写一份计划，关于' },
    [AIEditType.MindMap_Other]: { prompt: `告诉 ${PRODUCT_NAME} 写什么...` },
  };

  $appUiStateCache.$aiCommands.forEach((item) => {
    for (const type of ['plan', 'list', 'task', 'other']) {
      if (item.parentId === bitableIds[type]) {
        const editType = editMap[type];
        if (!editType) return;

        let promptList = editMap[type]?.promptList;
        if (!promptList) {
          promptList = [];
          editType.promptList = promptList;
        }

        promptList.push({
          title: item.name,
          placeholder: item.placeholder ?? '',
        });

        break;
      }
    }
  });

  for (const type of ['plan', 'list', 'task', 'other', 'mindOutline', 'mindPlan', 'mindOther']) {
    EditTypeMapPrompt[type] = editMap[type];
  }

  return EditTypeMapPrompt[editType];
};

export const TRANSLATE_LANGUAGE = [
  { editType: AIEditType.Chinese, title: '中文（简体）', en: 'simplified chinese' },
  {
    editType: AIEditType.ClassicalChinese,
    title: '中文（文言文）',
    en: 'classical chinese',
  },
  { editType: AIEditType.English, title: '英语', en: 'english' },
  { editType: AIEditType.Korean, title: '韩语', en: 'korean' },
  { editType: AIEditType.Japanese, title: '日语', en: 'japanese' },
  { editType: AIEditType.French, title: '法语', en: 'french' },
  { editType: AIEditType.German, title: '德语', en: 'german' },
  { editType: AIEditType.OtherLanguage, title: '翻译成...', en: 'other' },
];

export const AI_WRITE_DATABASE_ITEMS = [
  {
    icon: 'IcTableMiddle',
    title: '写计划',
    editType: AIEditType.Bitable_Plan,
    uuid: '788f06ec-1ed2-47f9-be37-a9a908ca8c7e',
  },
  {
    icon: 'IcTableMiddle',
    title: '写清单',
    editType: AIEditType.Bitable_List,
    uuid: '788f06ec-1ed2-47f9-be37-a9a908ca8c7e',
  },
  {
    icon: 'IcTableMiddle',
    title: '任务管理',
    editType: AIEditType.Bitable_Task_Manage,
    uuid: '788f06ec-1ed2-47f9-be37-a9a908ca8c7e',
  },
  {
    icon: 'IcTableMiddle',
    title: '其他',
    editType: AIEditType.Bitable_Other,
    uuid: '788f06ec-1ed2-47f9-be37-a9a908ca8c7e',
  },
];
export const AI_WRITE_MIND_MAP_ITEMS = [
  {
    icon: 'IcMindmapMiddle',
    title: '写大纲',
    editType: AIEditType.MindMap_Outline,
    uuid: '0f74c3ef-efbc-41ae-b6fb-04dd8e429d3d',
  },
  {
    icon: 'IcMindmapMiddle',
    title: '写计划',
    editType: AIEditType.MindMap_Plan,
    uuid: '6d5d4666-94f8-471e-84e9-ae80acb50b39',
  },
  {
    icon: 'IcMindmapMiddle',
    title: '其他',
    editType: AIEditType.MindMap_Other,
    uuid: '6d5d4666-94f8-471e-84e9-ae80acb50b39',
  },
];

export const getCustomAIPrompsWithEmpty = (onItemClick: (item: any) => void) => {
  const store = usePromptStore.getState();
  const items = store.emptyWithPrompts.map((v) => {
    const prompt = store.records[v];
    return {
      type: ListItemType.OPERATION,
      isHidden: prompt?.hidden,
      data: {
        icon: prompt?.icon || 'IcContinueWriting',
        title: prompt?.name || (prompt?.subNodes ? '未命名指令目录' : '未命名指令'),
        uuid: v,
        hasArrow: !!prompt?.subNodes,
        prompt: prompt?.minimax,
        editType: prompt?.type,
        renderSubMenu: prompt?.subNodes
          ? () => <SubMenu subNodes={prompt?.subNodes ?? []} onItemClick={onItemClick} />
          : undefined,
      },
    };
  });
  return items;
};

interface SubMenuProps {
  subNodes: string[];
  onItemClick: (item: any) => void;
}
const SubMenu: FC<SubMenuProps> = (props) => {
  const store = usePromptStore.getState();
  const items = props.subNodes.map((v) => {
    const prompt = store.records[v];
    return {
      type: ListItemType.OPERATION,
      isHidden: prompt?.hidden,
      data: {
        icon: prompt?.icon || 'IcContinueWriting',
        title: prompt?.name || (prompt?.subNodes ? '未命名指令目录' : '未命名指令'),
        uuid: v,
        editType: prompt?.type,
        hasArrow: !!prompt?.subNodes,
        placeHolder: prompt?.placeholder,
        prompt: prompt?.minimax,
      },
    };
  });
  return (
    <ListView
      className={listViewNormalClassName}
      keyDownPriority={PRIORITY_DIALOG + 1}
      items={items}
      onItemClick={props.onItemClick}
    />
  );
};

export const AI_WRITE_TYPE = [
  {
    icon: 'IcContinueWriting',
    title: '头脑风暴...',
    editType: AIEditType.Brainstorm,
    uuid: 'bc56080a-edb8-46e2-8584-7be4c4cc5d05',
  },
  {
    icon: 'IcContinueWriting',
    title: '写文章',
    editType: AIEditType.Blog,
    uuid: '1ee4dcf6-b00d-49b3-b4d5-ec55d102fd40',
  },
  {
    icon: 'IcContinueWriting',
    title: '写大纲',
    editType: AIEditType.Outline,
    uuid: '0fcb00ae-f47b-426f-9045-25798361ccc4',
  },
  {
    icon: 'IcContinueWriting',
    title: '写故事',
    editType: AIEditType.Story,
    uuid: 'a15c829b-8861-47d8-a085-10e7b36d1841',
  },
  {
    icon: 'IcContinueWriting',
    title: '日常小记',
    editType: AIEditType.DailyNotes,
    uuid: 'b3d48333-ead2-4692-ab51-1f16019500cd',
  },
  {
    icon: 'IcContinueWriting',
    title: '分析优缺点',
    editType: AIEditType.ProsAndConsList,
    uuid: 'e7e1df60-bd16-47d9-a2fa-e4fec4c114cf',
  },
  {
    icon: 'IcContinueWriting',
    title: '列出执行步骤',
    editType: AIEditType.ActionItems,
    uuid: 'f2eb1bda-7e1f-4ece-91d1-4c2c2d1f06cf',
  },
  {
    icon: 'IcContinueWriting',
    title: '解释一下',
    editType: AIEditType.Explain,
    uuid: '788f06ec-1ed2-47f9-be37-a9a908ca8c7e',
  },
  {
    icon: 'IcTableMiddle',
    title: '用多维表起草',
    hasArrow: true,
    editType: AIEditType.Bitable,
    uuid: '788f06ec-1ed2-47f9-be37-a9a908ca8c71',
  },
];
if (FeatureFlags.AI_MIND_MAP) {
  AI_WRITE_TYPE.push({
    icon: 'IcMindmapMiddle',
    title: '用思维导图起草',
    hasArrow: true,
    editType: AIEditType.MindMap,
    uuid: '788f06ec-1ed2-47f9-be37-a9a908ca8c72',
  });
}

// 当页面有内容的时候
export const ItemsWithPageHasContent: ListItem<any>[] = [
  {
    type: ListItemType.BLOCK_TEXT,
    data: { title: '按页面的内容' },
  },
  {
    type: ListItemType.OPERATION,
    data: {
      icon: 'IcContinueWriting',
      title: '继续写作',
      editType: AIEditType.ContinueWrite,
      uuid: '9aab88e9-ba1b-4201-a9f3-483e814493db',
    },
  },
  { type: ListItemType.LINE },
  {
    type: ListItemType.OPERATION,
    data: {
      icon: 'IcSummarize',
      title: '总结',
      editType: AIEditType.Summarize,
      uuid: '7aec37fa-f6be-4b6e-a870-6a54b430131a',
    },
  },
  {
    type: ListItemType.OPERATION,
    data: {
      icon: 'IcSummary',
      title: '摘要',
      editType: AIEditType.Summary,
      uuid: 'c0e29509-ca71-4bc0-9a96-fa338a2b05ce',
    },
  },
  {
    type: ListItemType.OPERATION,
    data: {
      icon: 'IcExplain',
      title: '解释',
      editType: AIEditType.Explain,
      uuid: '5e936d14-2331-468b-af24-31433992451d',
    },
  },
  {
    type: 'Translate',
    data: {
      uuid: '4767a567-da89-4781-92ab-8a8d5faf8e0e',
    },
  },
];

// 多维表的描述处唤起的菜单
export const ItemsWithPageDescription: ListItem<any>[] = [
  {
    type: ListItemType.BLOCK_TEXT,
    data: { title: '按页面的内容' },
  },
  { type: ListItemType.LINE },
  {
    type: ListItemType.OPERATION,
    data: {
      icon: 'IcSummarize',
      title: '总结',
      editType: AIEditType.Summarize,
      uuid: '7aec37fa-f6be-4b6e-a870-6a54b430131a',
    },
  },
  {
    type: ListItemType.OPERATION,
    data: {
      icon: 'IcExplain',
      title: '解释',
      editType: AIEditType.Explain,
      uuid: '5e936d14-2331-468b-af24-31433992451d',
    },
  },
];

// 当空页面写入了内容，或者 “继续写入” 写入了内容
export const ItemsWithPageContentWroteByAI = [
  {
    type: ListItemType.OPERATION,
    data: { icon: 'IcSelectedSpace', title: '完成', editType: AIEditType.Close },
  },
  {
    type: ListItemType.OPERATION,
    data: { icon: 'IcContinueWriting', title: '继续写作', editType: AIEditType.ContinueWrite },
  },
  { type: ListItemType.LINE },
  {
    type: ListItemType.OPERATION,
    data: { icon: 'IcTitleClose', title: '关闭', editType: AIEditType.Close },
  },
];

// 当有块或者文本被选中的时候
export const ItemsWithTextSelected: ListItem<any>[] = [
  {
    type: ListItemType.BLOCK_TEXT,
    data: { title: '按选择的内容' },
  },
  {
    type: ListItemType.OPERATION,
    data: {
      icon: 'IcContinueWriting',
      title: '继续写作',
      editType: AIEditType.ContinueWrite,
      uuid: '9aab88e9-ba1b-4201-a9f3-483e814493db',
    },
  },
  { type: ListItemType.LINE },
  {
    type: ListItemType.OPERATION,
    data: {
      icon: 'IcSummarize',
      title: '总结',
      editType: AIEditType.Summarize,
      uuid: '7aec37fa-f6be-4b6e-a870-6a54b430131a',
    },
  },
  {
    type: ListItemType.OPERATION,
    data: {
      icon: 'IcSummary',
      title: '摘要',
      editType: AIEditType.Summary,
      uuid: 'c0e29509-ca71-4bc0-9a96-fa338a2b05ce',
    },
  },
  {
    type: ListItemType.OPERATION,
    data: {
      icon: 'IcExplain',
      title: '解释',
      editType: AIEditType.Explain,
      uuid: '5e936d14-2331-468b-af24-31433992451d',
    },
  },
  { type: 'Translate' },
  { type: 'Code' },
  { type: ListItemType.LINE },
  {
    type: ListItemType.OPERATION,
    data: {
      icon: 'IcChangeWriting',
      title: '改进写作',
      editType: AIEditType.Improve,
      uuid: '7edcb487-2041-4ade-8483-71b9e1caba35',
    },
  },
  {
    type: ListItemType.OPERATION,
    data: {
      icon: 'IcSimpler',
      title: '更简短',
      editType: AIEditType.Simpler,
      uuid: '1a8812df-9395-4eed-84be-59b5e148e7c1',
    },
  },
  {
    type: ListItemType.OPERATION,
    data: {
      icon: 'IcMoreDetail',
      title: '更详细',
      editType: AIEditType.Longer,
      uuid: '63c58833-d02c-4a15-8039-949c78bcc9f2',
    },
  },
  { type: 'AiStyle', data: { uuid: '7613737d-eb65-4464-a596-167d1e3f0c00' } },
];

// 当有块或者文本被选中的时候，ask AI 之后
export const ItemsWithTextSelectedAndWroteByAI = [
  {
    type: ListItemType.OPERATION,
    data: { icon: 'IcMenuReplace', title: '替换', editType: AIEditType.Replace },
  },
  {
    type: ListItemType.OPERATION,
    data: { icon: 'IcBtnNew', title: '插入', editType: AIEditType.Insert },
  },
  {
    type: ListItemType.OPERATION,
    data: { icon: 'IcContinueWriting', title: '继续写作', editType: AIEditType.ContinueWrite },
  },
  { type: ListItemType.LINE },
  {
    type: ListItemType.OPERATION,
    data: { icon: 'IcTryAgain', title: '重试', editType: AIEditType.TryAgain },
  },
  {
    type: ListItemType.OPERATION,
    data: { icon: 'IcMenuDelete', title: '放弃', editType: AIEditType.Discard },
  },
];

interface Props {
  onItemClick: (item: any) => void;
}
export const Translate: FC<Props> = ({ onItemClick }) => {
  const items = TRANSLATE_LANGUAGE.map((language) => ({
    type: ListItemType.BLOCK_ITEM,
    data: language,
  }));

  return (
    <ListView
      className={listViewNormalClassName}
      items={items}
      keyDownPriority={PRIORITY_DIALOG + 1}
      onItemClick={onItemClick}
    />
  );
};

export const AiStyle = [
  {
    title: '更专业的',
    editType: AIEditType.Professional,
    uuid: '32faf939-cb35-479f-95e8-3a234791a75d',
  },
  { title: '非正式的', editType: AIEditType.Casual, uuid: '68dc99f8-ebda-440e-bd9d-b1d431b24e0a' },
  {
    title: '更直接的',
    editType: AIEditType.Straightforward,
    uuid: 'f57e6b90-d5c3-453a-87ab-e6ed9d2c175f',
  },
  {
    title: '更自信的',
    editType: AIEditType.Confident,
    uuid: '06d15689-21c4-446d-89b0-fa5cc33c3d96',
  },
  {
    title: '更友好的',
    editType: AIEditType.Friendly,
    uuid: '6a8e29b8-e6ee-4af1-87da-212f01b7925a',
  },
];
export const MoreAiStyle: FC<Props> = ({ onItemClick }) => {
  const items = AiStyle.map((language) => ({
    type: ListItemType.BLOCK_ITEM,
    data: language,
  }));

  return (
    <ListView
      className={listViewNormalClassName}
      items={items}
      keyDownPriority={PRIORITY_DIALOG + 1}
      onItemClick={onItemClick}
    />
  );
};

export const CodeLanguage = [
  { title: 'C++', editType: AIEditType.CPlus, uuid: '1d408267-b814-4a47-9202-8e7e3e3fe228' },
  { title: 'Java', editType: AIEditType.Java, uuid: '69adc1be-750b-4770-a543-c505e3673c07' },
  {
    title: 'JavaScript',
    editType: AIEditType.JavaScript,
    uuid: '059f0231-2c1b-499c-91fd-75bacafe756e',
  },
  {
    title: 'TypeScript',
    editType: AIEditType.TypeScript,
    uuid: 'f402a9c0-d0af-4cd6-8d5d-1d68e90e27ad',
  },
  { title: 'Python', editType: AIEditType.Python, uuid: '9d2d1638-becc-4b74-9f08-26dd5d873c23' },
  {
    title: '改写成',
    editType: AIEditType.OtherCodeLanguage,
    uuid: '2ed3ed7c-6867-437d-8da5-4f36300b44d2',
  },
];
export const OtherCodeLanguage: FC<Props> = ({ onItemClick }) => {
  const items = CodeLanguage.map((language) => ({
    type: ListItemType.BLOCK_ITEM,
    data: language,
  }));

  return (
    <ListView
      className={listViewNormalClassName}
      items={items}
      keyDownPriority={PRIORITY_DIALOG + 1}
      onItemClick={onItemClick}
    />
  );
};

const filterWithoutCommand = (items: ListItem<any>[]) => {
  const { $aiCommands } = $appUiStateCache;
  const set = new Set($aiCommands.map((item: any) => item.uuid));
  return items.filter((item) => {
    if (item.type === 'line') return true;
    return set.has(item?.data?.uuid);
  });
};

interface AIMenuItemsParams {
  scene: AIEditorScene;
  onItemClick: (item: any) => void;
  selectedBlockIds: React.MutableRefObject<string[]>;
  editTypeRef: React.MutableRefObject<AIEditType | undefined>;
  isBitable?: boolean;
  isBitablePage?: boolean;
  isMindMap?: boolean;
  isDescription?: boolean;
}
export const getAIMenuItems = (params: AIMenuItemsParams) => {
  const {
    scene,
    onItemClick,
    selectedBlockIds,
    isBitable = false,
    isBitablePage = false,
    isMindMap = false,
    isDescription = false,
    editTypeRef,
  } = params;
  const translate = {
    type: ListItemType.OPERATION,
    data: {
      icon: 'IcTranslate',
      title: '翻译',
      uuid: '4767a567-da89-4781-92ab-8a8d5faf8e0e',
      hasArrow: true,
      renderSubMenu: () => <Translate onItemClick={onItemClick} />,
    },
  };
  const aiStyle = {
    type: ListItemType.OPERATION,
    data: {
      icon: 'IcAiStyle',
      title: '切换风格',
      uuid: '7613737d-eb65-4464-a596-167d1e3f0c00',
      hasArrow: true,
      renderSubMenu: () => <MoreAiStyle onItemClick={onItemClick} />,
    },
  };

  switch (scene) {
    case AIEditorScene.PageEmpty: {
      const bitableAlreadyClick = localStorage.getItem(AiBitableEntry);
      const mindMapAlreadyClick = localStorage.getItem(AiMindMapEntry);
      const writeItems = getCustomAIPrompsWithEmpty(onItemClick);
      const itemsWithPageEmpty: ListItem<any>[] = writeItems.map((item) => {
        const writeType = item.data;
        let attribute = {};
        if (writeType.editType === AIEditType.Bitable) {
          attribute = {
            textClassName: bitableAlreadyClick ? undefined : 'text-red',
            rightText: bitableAlreadyClick ? undefined : 'NEW',
            renderSubMenu: () => {
              return <BitableMenu onItemClick={onItemClick} />;
            },
          };
        } else if (writeType.editType === AIEditType.MindMap) {
          attribute = {
            textClassName: mindMapAlreadyClick ? undefined : 'text-red',
            rightText: mindMapAlreadyClick ? undefined : 'NEW',
            renderSubMenu: () => {
              return <MindMapMenu onItemClick={onItemClick} />;
            },
          };
        }

        return {
          type: ListItemType.OPERATION,
          data: {
            ...writeType,
            ...attribute,
          },
        };
      });
      itemsWithPageEmpty.unshift({
        type: ListItemType.BLOCK_TEXT,
        data: { title: `${PRODUCT_AI_TITLE} 助手` },
      });
      return filterWithoutCommand(itemsWithPageEmpty);
    }

    case AIEditorScene.PageHasContent: {
      if (cache.ui.selectedBlocks.length === 1 || isMindMap) {
        const blockId = cache.ui.selectedBlocks[0]?.blockId ?? '';
        const { isInMindMap, isMindMapPage } = checkMindMap(blockId);
        if (isInMindMap) {
          return ItemsWithPageHasContent.map((item) =>
            item.type === 'Translate' ? translate : item
          ).filter((v) => {
            return v.data?.editType === AIEditType.ContinueWrite || v.data?.icon === 'IcTranslate';
          });
        } else if (isMindMapPage || isMindMap) {
          return ItemsWithPageHasContent.filter(
            (v) => v.data?.editType === AIEditType.ContinueWrite
          );
        }
      }

      return filterWithoutCommand(
        ItemsWithPageHasContent.map((item) => (item.type === 'Translate' ? translate : item))
      );
    }

    case AIEditorScene.PageContentWroteByAI: {
      if (editTypeRef.current && isAiMindMap.has(editTypeRef.current)) {
        return ItemsWithPageContentWroteByAI.filter(
          (v) => v.data?.editType !== AIEditType.ContinueWrite
        );
      }
      if (isMindMap) {
        return ItemsWithPageContentWroteByAI.filter(
          (v) => v.data?.editType !== AIEditType.ContinueWrite
        );
      }

      return ItemsWithPageContentWroteByAI.slice(0);
    }

    case AIEditorScene.TextSelected:
    case AIEditorScene.BlockSelected: {
      const result: ListItem<any>[] = [];
      if (isDescription && scene === AIEditorScene.BlockSelected) {
        // 当多维表的说明是空的时候
        return ItemsWithPageDescription.slice(0);
      }
      // 这里是导图页面的逻辑
      const blockId = cache.ui.selectedBlocks[0]?.blockId ?? '';
      const { isInMindMap, isMindMapPage } = checkMindMap(blockId);
      if (cache.ui.selectedBlocks.length === 1 && isMindMap) {
        if (isInMindMap) {
          return ItemsWithPageHasContent.map((item) =>
            item.type === 'Translate' ? translate : item
          ).filter((v) => {
            return v.data?.icon === 'IcTranslate';
          });
        } else if (isMindMapPage) {
          return ItemsWithPageHasContent.map((item) =>
            item.type === 'Translate' ? translate : item
          ).filter((v) => {
            return v.data?.icon === 'IcTranslate' || v.data?.editType === AIEditType.ContinueWrite;
          });
        }
      }
      // 这里是选择内嵌思维导图块的逻辑
      if (cache.ui.selectedBlocks.length === 1) {
        if (isMindMapPage) {
          return ItemsWithPageHasContent.map((item) =>
            item.type === 'Translate' ? translate : item
          );
        }
      }

      if (isBitable && !isDescription) {
        ItemsWithTextSelected.slice(3, 8).forEach((item) => {
          if (item.data?.editType === AIEditType.Summary) return;
          if (item.type === 'Translate') return result.push(translate);
          result.push(item);
        });
        return filterWithoutCommand(result);
      }

      const { blocks } = cache;
      const codeBlock = selectedBlockIds.current.find(
        (uuid) => blocks[uuid]?.type === BlockType.CODE
      );

      ItemsWithTextSelected.forEach((item) => {
        if (item.type === 'AiStyle') return result.push(aiStyle);
        if (item.type === 'Translate') return result.push(translate);
        if (item.type === 'Code') {
          if (codeBlock) {
            result.push(
              { type: ListItemType.LINE },
              {
                type: ListItemType.OPERATION,
                data: {
                  icon: 'IcNote',
                  title: '代码注释',
                  editType: AIEditType.Comment,
                  uuid: '67f734b6-020d-47db-b6f1-81d718af72ad',
                },
              },
              {
                type: ListItemType.OPERATION,
                data: {
                  icon: 'IcRepair',
                  title: '程序修复',
                  editType: AIEditType.BugFix,
                  uuid: '9c0fa67f-39b7-472c-9114-55736adab50e',
                },
              },
              {
                type: ListItemType.OPERATION,
                data: {
                  icon: 'IcCodeRewriting',
                  title: '代码改写',
                  uuid: '6b4887d4-2f0f-4cbc-a005-3c6ca9a009c5',
                  hasArrow: true,
                  renderSubMenu: () => <OtherCodeLanguage onItemClick={onItemClick} />,
                },
              }
            );
          }
          return;
        }

        result.push(item);
      });

      return filterWithoutCommand(result);
    }

    case AIEditorScene.TextSelectedAndWroteByAI:
    case AIEditorScene.BlockSelectedAndWroteByAI: {
      if (isBitable) {
        let result: ListItem<any>[] = [];

        const isSummarizeOrExplain =
          editTypeRef.current === AIEditType.Summarize ||
          editTypeRef.current === AIEditType.Explain;
        if (isBitablePage) {
          if (isSummarizeOrExplain || isDescription) {
            result = [
              {
                type: ListItemType.OPERATION,
                data: {
                  icon: 'IcBtnNew',
                  title: '插入至多维表说明',
                  editType: AIEditType.InsertToBitableDesc,
                },
              },
              {
                type: ListItemType.OPERATION,
                data: {
                  icon: 'IcContinueWriting',
                  title: '继续写作',
                  editType: AIEditType.ContinueWrite,
                },
              },
            ];
          }
        } else {
          if (isSummarizeOrExplain) {
            result = [
              {
                type: ListItemType.OPERATION,
                data: { icon: 'IcBtnNew', title: '插入', editType: AIEditType.Insert },
              },
              {
                type: ListItemType.OPERATION,
                data: {
                  icon: 'IcBtnNew',
                  title: '插入至多维表说明',
                  editType: AIEditType.InsertToBitableDesc,
                },
              },
              {
                type: ListItemType.OPERATION,
                data: {
                  icon: 'IcContinueWriting',
                  title: '继续写作',
                  editType: AIEditType.ContinueWrite,
                },
              },
            ];
          } else {
            result = [
              {
                type: ListItemType.OPERATION,
                data: { icon: 'IcBtnNew', title: '插入', editType: AIEditType.Insert },
              },
            ];
          }
        }

        result.unshift({
          type: ListItemType.OPERATION,
          data: { icon: 'IcMenuReplace', title: '替换', editType: AIEditType.Replace },
        });
        result.push(
          { type: ListItemType.LINE },
          {
            type: ListItemType.OPERATION,
            data: { icon: 'IcTryAgain', title: '重试', editType: AIEditType.TryAgain },
          },
          {
            type: ListItemType.OPERATION,
            data: { icon: 'IcMenuDelete', title: '放弃', editType: AIEditType.Discard },
          }
        );

        return result;
      }
      const blockId = cache.ui.selectedBlocks[0]?.blockId ?? '';
      const { isInMindMap, isMindMapPage } = checkMindMap(blockId);
      if (isInMindMap) {
        // 生成内容后，去掉继续写作
        return ItemsWithTextSelectedAndWroteByAI.filter(
          (v) => v.data?.editType !== AIEditType.ContinueWrite
        );
      }
      // 如果是页面根节点翻译，则需要去掉插入，保持替换
      // 如果是内嵌维导图翻译，则需要保留插入，去掉替换
      const isEmbed = !isMindMap && isMindMapPage;
      if (isEmbed) {
        // 生成内容后(翻译)，去掉继续写作和替换
        return ItemsWithTextSelectedAndWroteByAI.filter(
          (v) =>
            v.data?.editType !== AIEditType.ContinueWrite && v.data?.editType !== AIEditType.Replace
        );
      } else if (isMindMapPage) {
        // 生成内容后(翻译)，去掉继续写作和插入
        return ItemsWithTextSelectedAndWroteByAI.filter(
          (v) =>
            v.data?.editType !== AIEditType.ContinueWrite && v.data?.editType !== AIEditType.Insert
        );
      }
      return ItemsWithTextSelectedAndWroteByAI.slice(0);
    }
    default:
      return [];
  }
};

export const translateEditType = new Set([
  AIEditType.Chinese,
  AIEditType.ClassicalChinese,
  AIEditType.English,
  AIEditType.Korean,
  AIEditType.Japanese,
  AIEditType.French,
  AIEditType.German,
  AIEditType.OtherLanguage,
]);
