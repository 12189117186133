import { Deferred, useIsDarkMode } from '@flowus/common';
import type { ActivityDialogConfig, CheckActivityIdIsRead } from '@flowus/common/activity-queue';
import {
  completeGlobalModalQueue,
  useCheckActivityIdIsRead,
  useOpenActivityDialog,
} from '@flowus/common/activity-queue';
import { sleep } from '@flowus/common/async';
import { Button } from '@flowus/common/components/button';
import { SessionKeys, getSessionStorage, removeSessionStorage } from '@flowus/common/storage';
import type {
  AIWelfareToGiveDTO,
  LaunchScreenPopup,
  NewUserAcquisitionTeamRecord,
} from '@next-space/fe-api-idl';
import { LaunchScreenPopupShowType } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'src/common/components/message';
import { useCloseModal, useOpenModal } from 'src/common/components/next-modal';
import { DATE_FORMAT, publicLink } from 'src/common/const';
import { request } from 'src/common/request/request';
import { PRODUCT_AI_TITLE } from 'src/const/title';
import { useEnableAI } from 'src/hooks/block/use-enable-AI';
import { getIsDarkMode } from 'src/hooks/public/use-theme';
import { getCurrentSpace } from 'src/hooks/space';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { useShareSpaceLink } from 'src/hooks/space/use-share-space-link';
import { getCurrentUser } from 'src/hooks/user';
import { Images } from 'src/image';
import { Modals } from 'src/modals';
import { ViewPath } from 'src/utils';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { useSetDrawerOpenState } from 'src/views/main/drawer/shared';
import { OpenSettingFrom, SettingMenuType } from 'src/views/main/setting-modal/type';
import {
  useOpenSettingModal,
  useOpenUpgradeSpace,
} from 'src/views/main/setting-modal/use-open-setting-modal';
import { createActivityModalAnchor } from './create-activity-modal-anchor';

// #region 通用活动弹窗
const ActivityDialog: FC<ActivityDialogConfig & { modalId: string }> = (props) => {
  const {
    activityLink,
    bodyImage: _bodyImage,
    darkBodyImage,
    headerImage: _headerImage,
    darkHeaderImage,
    confirmText = '立即参与',
    onConfirm,
    modalId,
    noCancel = false,
    confirmType = 'openUpgrade',
    upgradeType = 'all',
  } = props;
  const isDarkMode = useIsDarkMode();
  const bodyImage = isDarkMode ? darkBodyImage : _bodyImage;
  const headerImage = isDarkMode ? darkHeaderImage : _headerImage;

  const openModal = useOpenModal();
  const openUpgradeSpace = useOpenUpgradeSpace();

  const close = () => {
    openModal.closeModal(modalId);
  };

  const handleClick = () => {
    if (onConfirm) {
      onConfirm();
      close();
    } else {
      switch (confirmType) {
        case 'none': {
          break;
        }
        case 'openLink': {
          window.open(activityLink);
          break;
        }
        case 'openUpgrade':
        default: {
          openUpgradeSpace(OpenSettingFrom.activity);
          break;
        }
      }
    }
  };

  const descImage = useMemo(() => {
    if (upgradeType === 'smallTeam') {
      return Images.activityDialogDescTeam;
    }
    return Images.activityDialogDesc;
  }, [upgradeType]);

  return (
    <>
      <img hidden={!headerImage} className="w-full" src={headerImage} alt="" />
      <div className="pt-5 pb-8 px-8 h-full space-y-5">
        <img hidden={!bodyImage} src={bodyImage} className="rounded-lg" alt="" />
        <img src={Images.activityDialogDiver} className="w-full dark:invert" />
        <img src={descImage} className="w-full dark:invert" />
        <div className="flex space-x-5" onClick={close}>
          <Button hidden={noCancel} size="auto" className="h-10">
            残忍拒绝
          </Button>
          <Button size="auto" className="h-10" colorType="active" onClick={handleClick}>
            {confirmText}
          </Button>
        </div>
        <a
          hidden={!activityLink}
          href={activityLink}
          target="_blank"
          className="w-full text-active_color text-center block text-t4 underline underline-offset-4"
        >
          了解活动详情
        </a>
      </div>
    </>
  );
};

const HotSpotModal: FC<ActivityDialogConfig> = (props) => {
  const { bodyImage, title } = props;
  const closeModal = useCloseModal();
  const openUpgradeSpace = useOpenUpgradeSpace();
  return (
    <div className="w-[320px] pt-8">
      <div className="mb-2.5 text-t1-bold">{title}</div>
      <img src={bodyImage} className="rounded-md w-full" alt="" />
      <div className="space-x-2.5 mt-7 flex items-center">
        <Button onClick={() => closeModal(Modals.HOT_SPOT)}>残忍拒绝</Button>
        <Button
          colorType="active"
          onClick={() => {
            closeModal(Modals.HOT_SPOT);
            openUpgradeSpace(OpenSettingFrom.activity);
          }}
        >
          立即下单
        </Button>
      </div>
    </div>
  );
};

const RewardTask: FC<ActivityDialogConfig> = (props) => {
  // @ts-ignore idl
  const {
    title,
    subTitle,
    activityLink,
    bodyImage: _bodyImage,
    darkBodyImage,
    confirmType = 'none',
    confirmText,
  } = props;
  const openModal = useOpenModal();
  const openUpgradeSpace = useOpenUpgradeSpace();
  const history = useHistory();
  const setDrawerState = useSetDrawerOpenState();
  const enableAi = useEnableAI();
  const hiddenCancel = ['none', 'openAiChat'].includes(confirmType);
  const isDarkMode = useIsDarkMode();
  const bodyImage = isDarkMode ? darkBodyImage : _bodyImage;

  const onCancel = () => {
    openModal.closeModal(Modals.REWARD_TASK);
  };

  const onConfirmText = () => {
    openModal.closeModal(Modals.REWARD_TASK);
    switch (confirmType) {
      case 'none': {
        break;
      }
      case 'openAiChat': {
        if (enableAi) {
          setDrawerState('ai-chat');
        } else {
          message.error('当前空间没有 AI 权限');
        }
        break;
      }
      case 'openLink': {
        window.open(activityLink);
        break;
      }
      case 'createSpace': {
        history.push(ViewPath.create);
        break;
      }
      case 'openUpgrade':
      default: {
        // 产品要求埋点
        if (bodyImage?.includes('web-ai-knowledge')) {
          openUpgradeSpace(OpenSettingFrom.dialog_ai_rights);
        } else {
          openUpgradeSpace(OpenSettingFrom.activity);
        }
        break;
      }
    }
  };

  return (
    <div className="pt-10 pb-5 px-7 whitespace-pre-wrap text-center space-y-2.5">
      {title && <div className="text-t1-medium">{title}</div>}
      {subTitle && <div className="text-t2">{subTitle}</div>}
      <img src={bodyImage} className="w-full" alt={title} />
      <Button className="w-full" colorType="active" onClick={onConfirmText}>
        {confirmText}
      </Button>
      {!hiddenCancel && (
        <Button className="w-full" onClick={onCancel}>
          残忍拒绝
        </Button>
      )}
    </div>
  );
};

export const useFetchActivityData = () => {
  const openActivityDialog = useOpenActivityDialog();
  const openModal = useOpenModal();

  const openDialog = useCallback(
    async (info: LaunchScreenPopup, callback: () => void) => {
      const config: CheckActivityIdIsRead = {
        ...info,
        ...info.resources,
        bodyImage: info.resources.light?.body,
        darkBodyImage: info.resources.dark?.body,
        headerImage: info.resources.light?.header,
        darkHeaderImage: info.resources.dark?.header,
        confirmType: info.resources.confirmType as ActivityDialogConfig['confirmType'],
        request,
        spaceId: getCurrentSpaceId(),
        userId: getCurrentUser().uuid,
      };

      const openStatus = await openActivityDialog(config);

      if (!openStatus) {
        return;
      }

      if (info.showType === LaunchScreenPopupShowType.FULL_SEARCH) {
        openModal.modal({
          modalId: Modals.HOT_SPOT,
          className: 'rounded-lg w-125 overflow-hidden',
          content: () => <ActivityDialog {...config} modalId={Modals.HOT_SPOT} />,
          closeAfterCallBack: callback,
        });
        return true;
      }

      if (info.showType === LaunchScreenPopupShowType.HOT_SPOT) {
        const activityModalAnchor = createActivityModalAnchor();
        openModal.dropdown({
          modalId: Modals.HOT_SPOT,
          popcorn: activityModalAnchor.popcorn,
          placement: 'top-start',
          className: 'next-modal',
          offset: [0, 10],
          closeAfterCallBack: () => {
            activityModalAnchor.remove();
            callback();
          },
          content: () => <HotSpotModal {...config} />,
        });
        return true;
      }

      if (info.showType === LaunchScreenPopupShowType.REWARD_TASK) {
        openModal.modal({
          modalId: Modals.REWARD_TASK,
          className: 'w-96 overflow-hidden',
          content: () => <RewardTask {...config} />,
          closeAfterCallBack: callback,
        });
        return true;
      }
    },
    [openModal]
  );

  const getLaunchScreenInfo = async () => {
    const spaceId = getCurrentSpaceId();
    const resMap = await request.infra.launchScreenInfoV2.raw(spaceId);
    if (resMap.code === 200 && !resMap.data) return;
    if (resMap.code !== 200 || !resMap.data.length) return;
    return resMap.data;
  };

  return useCallback(async () => {
    const resMap = await getLaunchScreenInfo();

    if (!resMap) return;

    /** 判断这个活动列表是否有任意一个是正常弹起的情况 */
    let isOpen = false;
    const processArrayData = async (currentIndex = 0): Promise<boolean> => {
      const info = resMap[currentIndex];

      if (currentIndex >= resMap.length || !info) {
        completeGlobalModalQueue(Modals.HOT_SPOT);
        return isOpen;
      }

      const defer = new Deferred();
      const status = await openDialog(info, async () => {
        await sleep(300);
        defer.resolve();
      });

      if (!status) {
        defer.resolve();
      } else {
        // 弹起了弹窗
        isOpen = true;
      }

      await defer.promise;
      return processArrayData(currentIndex + 1);
    };

    return processArrayData();
  }, [openDialog]);
};

// #endregion

// #region S102 活动，创建空间后活动弹窗
export const useCreateSpaceAfter = () => {
  const openActivityDialog = useOpenActivityDialog();
  const openModal = useOpenModal();
  const spaceShareLink = useShareSpaceLink();
  const openSettingModal = useOpenSettingModal();

  return useCallback(async () => {
    if (__BUILD_IN__ || __PRIVATE__) return;
    const fromSpaceId = getSessionStorage(SessionKeys.FROM_CREATE_SPACE);
    const currentUserId = getCurrentUser().uuid;

    const currentSpace = getCurrentSpace();
    const currentSpaceId = currentSpace.uuid;

    if (currentSpace.createdBy !== currentUserId) return;

    if (fromSpaceId !== currentSpaceId) return;

    const isAcquisitionTeam = await request.infra.getUserAcquisitionTeamRecords(currentUserId);

    if (!isAcquisitionTeam.spaceId) return;

    removeSessionStorage(SessionKeys.FROM_CREATE_SPACE);

    const config: CheckActivityIdIsRead = {
      request,
      spaceId: getCurrentSpaceId(),
      userId: getCurrentUser().uuid,
      activityLink: 'https://flowus.cn/share/4c279a79-6cc8-4120-aa0f-46cbe90afd5d',
      dailyPopupLimit: 1,
      totalPopupLimit: 1,
      isSpaceSensitive: true,
      showType: LaunchScreenPopupShowType.FULL_SEARCH,
      activityId: '4c279a79-6cc8-4120-aa0f-46cbe90afd5d',
      confirmText: '立即邀请',
      headerImage: Images.s102Head,
      bodyImage: Images.s102Body,
      upgradeType: 'smallTeam',
      onConfirm: () => {
        void writeTextInClipboard(spaceShareLink, {
          message: '复制成功，快去邀请成员注册FlowUs并加入本空间吧！',
        });
        openSettingModal({
          from: OpenSettingFrom.activity,
          defaultMenuType: SettingMenuType.members,
        });
      },
    };

    const openStatus = await openActivityDialog(config);
    if (!openStatus) {
      return;
    }

    openModal.modal({
      modalId: Modals.CREATE_SPACE_AFTER,
      className: 'rounded-lg w-125 overflow-hidden',
      content: () => <ActivityDialog {...config} modalId={Modals.CREATE_SPACE_AFTER} />,
      closeAfterCallBack: () => completeGlobalModalQueue(Modals.CREATE_SPACE_AFTER),
    });
    return true;
  }, [openActivityDialog, openModal, openSettingModal, spaceShareLink]);
};

export const useAcquisitionTeam = () => {
  const openModal = useOpenModal();
  const openActivityDialog = useOpenActivityDialog();

  return useCallback(
    async (params?: {
      getIsAcquisitionTeam?: (props: NewUserAcquisitionTeamRecord) => void;
      needOpenModal?: boolean;
    }) => {
      const { getIsAcquisitionTeam, needOpenModal } = params || {};
      if (__BUILD_IN__ || __PRIVATE__) return;
      const currentUserId = getCurrentUser().uuid;
      const isAcquisitionTeam = await request.infra.getUserAcquisitionTeamRecords(currentUserId);
      const currentSpace = getCurrentSpace();
      const currentSpaceId = currentSpace.uuid;

      if (isAcquisitionTeam.spaceId !== currentSpaceId) return;
      if (currentSpace.createdBy !== currentUserId) return;

      getIsAcquisitionTeam?.(isAcquisitionTeam);

      if (!isAcquisitionTeam.success) return;

      if (!needOpenModal) return;

      const config: CheckActivityIdIsRead = {
        request,
        spaceId: getCurrentSpaceId(),
        userId: getCurrentUser().uuid,
        activityLink: '',
        dailyPopupLimit: 1,
        totalPopupLimit: 1,
        isSpaceSensitive: true,
        showType: LaunchScreenPopupShowType.FULL_SEARCH,
        activityId: Modals.ACQUISITION_TEAM_DIALOG,
        confirmText: '立即开展高效协作吧！',
        headerImage: Images.s102TeamHead,
        noCancel: true,
        upgradeType: 'smallTeam',
        confirmType: 'none',
      };

      const openStatus = await openActivityDialog(config);

      if (!openStatus) return;

      openModal.modal({
        modalId: Modals.ACQUISITION_TEAM_DIALOG,
        className: 'rounded-lg w-125 overflow-hidden',
        content: () => <ActivityDialog {...config} modalId={Modals.ACQUISITION_TEAM_DIALOG} />,
        closeAfterCallBack: () => completeGlobalModalQueue(Modals.ACQUISITION_TEAM_DIALOG),
      });

      return true;
    },
    [openActivityDialog, openModal]
  );
};
// #endregion

// #region AI 公测活动
const activityAiNoviceDialogModalId = 'ActivityAiNoviceDialog';
const ActivityAiNoviceDialog = (props: AIWelfareToGiveDTO & { isNoviceAccount: boolean }) => {
  const { count, expireTime, welfareName, isNoviceAccount } = props;
  const currentSpace = getCurrentSpace();
  const openModal = useOpenModal();

  const onConfirm = async () => {
    const id = openModal.loading({ title: '处理中' }).modalId;
    if (isNoviceAccount) {
      await request.ai.postAiApply();
    } else {
      await request.ai.postWelfareToGive({ spaceId: getCurrentSpaceId() });
    }
    openModal.closeModal(id);
    message.success('成功');
    await sleep(300);
    location.reload();
  };

  return (
    <div className="w-96 space-y-7 px-7 pt-10 mb-5 text-center select-none">
      <div className="space-y-2.5">
        <div className="text-t1-medium">🎁 邀请你体验 {PRODUCT_AI_TITLE}</div>
        <div className="text-t2">
          「{currentSpace.title}」将获得{count}张 {welfareName}
        </div>
      </div>
      <div className="relative h-24 flex flex-col items-center">
        <div
          className="relative z-[3] h-[72px] items-center grid grid-cols-4 px-4 space-x-2 shadow-light border border-grey6 rounded-md text-left w-full"
          style={{
            background:
              'linear-gradient(to right, var(--white1), #C132871A),linear-gradient(to right, var(--white1), var(--white1))',
          }}
        >
          <div className="col-span-1 space-x-0.5 text-center">
            <span className="text-h2">{count}</span>
            <span className="text-t2-medium">张</span>
          </div>
          <div className="col-span-3 space-y-1">
            <div className="text-t2-medium">{welfareName}</div>
            <div className="text-t4 text-grey3">
              有效期至 {dayjs(expireTime).format(DATE_FORMAT)}
            </div>
          </div>
        </div>
        <div
          className="absolute w-[90%] z-[2] top-2 h-[72px] shadow-light rounded-md"
          style={{
            background:
              'linear-gradient(to right, var(--white1), #C132871A),linear-gradient(to right, var(--white1), var(--white1))',
          }}
        />
        <div
          className="absolute w-[80%] z-[1] top-4 h-[72px] shadow-light rounded-md"
          style={{
            background:
              'linear-gradient(to right, var(--white1), #C132871A),linear-gradient(to right, var(--white1), var(--white1))',
          }}
        />
      </div>
      <div className="space-y-2.5">
        <Button colorType="active" className="w-full" onClick={onConfirm}>
          前往使用
        </Button>
      </div>
    </div>
  );
};
export const useActivityAiNoviceDialog = () => {
  const openModal = useOpenModal();
  const checkActivityIdIsRead = useCheckActivityIdIsRead();

  return useCallback(
    async (params: { isNoviceAccount: boolean }) => {
      const { isNoviceAccount } = params;
      const currentSpace = getCurrentSpace();
      const res = await request.ai.getWelfareToGive(currentSpace.uuid);

      if (!res.expireTime) return;

      const isRead = await checkActivityIdIsRead({
        totalPopupLimit: 1,
        dailyPopupLimit: 1,
        isSpaceSensitive: true,
        activityLink: publicLink.aiTerms,
        validate: __HOST_PRODUCTION__ || __HOST_STAGING__ ? 'user' : 'local',
        activityId: activityAiNoviceDialogModalId,
        userId: getCurrentUser().uuid,
        spaceId: currentSpace.uuid,
        request,
      });

      if (isRead) {
        return;
      }

      openModal.modal({
        modalId: activityAiNoviceDialogModalId,
        closeAfterCallBack: () => {
          completeGlobalModalQueue(Modals.AI_NOVICE);
        },
        content: () => <ActivityAiNoviceDialog isNoviceAccount={isNoviceAccount} {...res} />,
      });

      return true;
    },
    [checkActivityIdIsRead, openModal]
  );
};
// #endregion
