import { cx } from '@flowus/common/cx';
import { PermissionRole } from '@next-space/fe-api-idl';
import type { FC, MouseEvent } from 'react';
import { Icon } from 'src/common/components/icon';
import { ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';
import { useOpenModal } from 'src/common/components/next-modal';

export const permissionName = {
  [PermissionRole.EDITOR]: '所有权限',
  [PermissionRole.WRITER]: '可编辑',
  [PermissionRole.COMMENTER]: '可评论',
  [PermissionRole.READER]: '可查看',
  [PermissionRole.NONE]: '无权限',
};

export interface Props {
  showRight?: boolean;
  role?: PermissionRole;
  isSpaceType?: boolean;
  noRemove?: boolean;
  onChange?: (role: PermissionRole) => void;
  isGuest?: boolean;
  isForm?: boolean;
}

export const Permissions: FC<Props> = ({
  isGuest,
  showRight,
  role,
  onChange,
  isSpaceType,
  noRemove,
  isForm,
}) => {
  const openModal = useOpenModal();

  let items: ListItem[] = [];

  if (!isGuest) {
    items.push({
      type: ListItemType.TEXT_DESCRIPTION,
      data: {
        title: '所有权限',
        description: '可编辑，可管理协作及分享',
        selected: role === PermissionRole.EDITOR,
        onClick: () => onChange?.(PermissionRole.EDITOR),
      },
    });
  }

  items.push({
    type: ListItemType.TEXT_DESCRIPTION,
    data: {
      title: '可编辑',
      description: '仅可编辑，不可管理协作及分享',
      selected: role === PermissionRole.WRITER,
      onClick: () => onChange?.(PermissionRole.WRITER),
    },
  });

  items.push({
    type: ListItemType.TEXT_DESCRIPTION,
    data: {
      title: '可评论',
      description: '仅可查看及评论，不可编辑',
      selected: role === PermissionRole.COMMENTER,
      onClick: () => onChange?.(PermissionRole.COMMENTER),
    },
  });

  items.push({
    type: ListItemType.TEXT_DESCRIPTION,
    data: {
      title: '可查看',
      description: '仅可查看，不可编辑及评论',
      selected: role === PermissionRole.READER,
      onClick: () => onChange?.(PermissionRole.READER),
    },
  });

  if (!noRemove) {
    items.push({ type: ListItemType.LINE });
    items.push({
      type: ListItemType.TEXT_DESCRIPTION,
      data: {
        title: <div className="text-red">{isSpaceType ? ' 无权限' : '移除'}</div>,
        selected: isSpaceType && (!role || role === PermissionRole.NONE),
        onClick: () => onChange?.(PermissionRole.NONE),
      },
    });
  }
  if (isForm) {
    items = [
      {
        type: ListItemType.TEXT_DESCRIPTION,
        data: {
          title: <div className="text-red">移除</div>,
          onClick: () => onChange?.(PermissionRole.NONE),
        },
      },
    ];
  }

  /** 用户权限修改弹窗 */
  const openOption = (event: MouseEvent<HTMLDivElement>) => {
    openModal.dropdown({
      placement: 'bottom',
      offset: [0, 4],
      popcorn: event.currentTarget,
      content: ({ onCloseModal }) => (
        <ListView
          className="py-2 w-[270px] next-modal"
          onItemClick={() => onCloseModal()}
          items={items}
        />
      ),
    });
  };

  return (
    <div
      hidden={!onChange && !showRight}
      className={cx(
        'self-center shrink-0 flex items-center text-t4 text-grey3',
        onChange ? 'cursor-pointer' : 'cursor-not-allowed',
        onChange && 'animate-click'
      )}
      onClick={(e) => onChange && openOption(e)}
    >
      {isForm ? '可填写' : <>{role ? permissionName[role] : permissionName[PermissionRole.NONE]}</>}
      {onChange && <Icon name="IcArrow" className="ml-1" size="xxxsmall" />}
    </div>
  );
};
