import { getViewFormat } from 'src/hooks/block/get-view-format';
import { UPDATE_COLLECTION_VIEW } from 'src/redux/actions/collection-view';
import type { NextCollectionView } from 'src/redux/types';
import { dispatch } from '../../store';
import { updateBlock } from '../block/update';

export const update = (uuid: string, patch: Partial<NextCollectionView>, ignoreOp?: boolean) => {
  const viewInfo = getViewFormat(uuid);
  if (!viewInfo) return;
  const { view } = viewInfo;

  if (!ignoreOp) {
    // 触发collection的更新updateAt和updateBy
    updateBlock(view.parentId, {});
  }

  dispatch(
    UPDATE_COLLECTION_VIEW({
      uuid,
      patch: {
        ...patch,
        updatedAt: Date.now(),
      },
      ignoreOp,
    })
  );
};
