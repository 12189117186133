import type {
  Active,
  DragEndEvent,
  DraggableSyntheticListeners,
  DragStartEvent,
  Modifiers,
} from '@dnd-kit/core';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import type { SortingStrategy } from '@dnd-kit/sortable';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { cx } from '@flowus/common/cx';
import { clamp } from 'lodash-es';
import type { MouseEvent, MutableRefObject, ReactElement, ReactNode } from 'react';
import { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import type { ItemStyle } from 'react-tiny-virtual-list';
import VirtualList from 'react-tiny-virtual-list';
import type { IconSizeStyle } from './icon';
import { Icon } from './icon';
import { Tooltip } from './tooltip';

interface RenderDragHandleProps {
  className?: string;
  popup?: string;
  onClick?: (event: MouseEvent) => void;
  onPointerDownCapture?: (event: MouseEvent) => void;
  listeners?: DraggableSyntheticListeners;
  size?: keyof typeof IconSizeStyle;
}

interface RenderItemContentProps<T> {
  item: T;
  isDragging?: boolean;
  active?: Active | null;
  isPreview?: boolean;
  renderDragHandle: (props: RenderDragHandleProps) => ReactNode;
  listeners?: DraggableSyntheticListeners;
}

export type SortableListRenderDragHandle = (props: {
  className?: string;
  popup?: string;
  onClick?: (event: MouseEvent) => void;
}) => ReactNode;
interface ListProps<T> {
  items: T[];
  renderItemContent: (props: RenderItemContentProps<T>) => ReactNode;
  onlyHandleDraggable?: boolean;
  onChange?: (items: T[], event: DragEndEvent) => void;
  disabled?: boolean;
  virtual?: boolean;
  strategy?: SortingStrategy | undefined;
  onDragStart?: (event: DragStartEvent) => void;
  onDragEnd?: (event: DragEndEvent) => void;
  modifiers?: Modifiers;
  className?: string;
  isHorizontalAxis?: boolean;
  appendChild?: ReactNode;
  beforeAppendChild?: ReactNode;
  listRef?: MutableRefObject<HTMLElement | null>;
  appendTo?: HTMLElement;
  customRenderItems?: () => ReactNode;
}

export const useSortSensor = () => {
  return useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 3 } }));
};

export const SortableList = <T extends { id: string }>({
  items,
  renderItemContent,
  onChange,
  onlyHandleDraggable = false,
  disabled = false,
  onDragStart,
  onDragEnd,
  modifiers = [restrictToParentElement, restrictToVerticalAxis],
  strategy = verticalListSortingStrategy,
  className,
  appendChild,
  beforeAppendChild,
  listRef,
  isHorizontalAxis,
  virtual: visual,
  appendTo = document.body,
  customRenderItems,
}: ListProps<T>): ReactElement => {
  const containerRef = useRef<HTMLElement | null>(null);
  const [draggingItem, setDraggingItem] = useState<T | undefined>(undefined);
  const sensors = useSortSensor();

  const setNodeRef = (node: HTMLElement | null) => {
    containerRef.current = node;
    if (listRef) {
      listRef.current = node;
    }
  };

  return (
    <DndContext
      modifiers={modifiers}
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={(event) => {
        onDragEnd?.(event);
        setDraggingItem(undefined);
        const { active, over } = event;
        if (!over) return;
        if (active.id !== over.id) {
          const oldIndex = items.findIndex((item) => item.id === active.id);
          const newIndex = items.findIndex((item) => item.id === over.id);
          const newItems = arrayMove(items, oldIndex, newIndex);
          onChange?.(newItems, event);
        }
      }}
      onDragStart={(event) => {
        onDragStart?.(event);
        const item = items.find((item) => item.id === event.active.id);
        setDraggingItem(item);
      }}
    >
      <div className={className} ref={setNodeRef}>
        {beforeAppendChild}
        <SortableContext items={items} strategy={strategy}>
          {customRenderItems ? (
            customRenderItems()
          ) : visual ? (
            <VirtualList
              height={clamp(items.length * 32, 0, 400)}
              itemCount={items.length}
              className="w-full"
              overscanCount={5}
              itemSize={32}
              stickyIndices={draggingItem ? [items.indexOf(draggingItem)] : undefined}
              renderItem={({ index, style }) => {
                const recordId = items[index];
                if (!recordId) return null;

                return (
                  <SortableItem
                    key={recordId.id}
                    renderItemContent={renderItemContent}
                    item={recordId}
                    wrapperStyle={style}
                    disabled={disabled}
                    onlyHandleDraggable={onlyHandleDraggable}
                  />
                );
              }}
            />
          ) : (
            <>
              {items.map((item) => (
                <SortableItem
                  key={item.id}
                  renderItemContent={renderItemContent}
                  item={item}
                  disabled={disabled}
                  onlyHandleDraggable={onlyHandleDraggable}
                />
              ))}
            </>
          )}
        </SortableContext>
        {appendChild}
      </div>

      {createPortal(
        <DragOverlay dropAnimation={null} className="!z-[8848]">
          {draggingItem && (
            <ItemPreview
              height={isHorizontalAxis ? containerRef.current?.clientHeight : undefined}
              renderItemContent={renderItemContent}
              item={draggingItem}
            />
          )}
        </DragOverlay>,
        appendTo
      )}
    </DndContext>
  );
};

interface ItemProps<T> {
  item: T;
  wrapperStyle?: ItemStyle;
  disabled?: boolean;
  height?: number;
  renderItemContent: (props: RenderItemContentProps<T>) => ReactNode;
}

const ItemPreview = <T extends { id: string }>({
  item,
  height,
  renderItemContent,
}: ItemProps<T>) => {
  const renderDragHandle = (props: { className?: string; size?: keyof typeof IconSizeStyle }) => {
    return (
      <div className={props.className}>
        <Icon name="IcBlockHandle" size={props.size ?? 'normal'} />
      </div>
    );
  };
  return (
    <div className="opacity-[0.9]" style={{ height }}>
      {renderItemContent({ item, isPreview: true, renderDragHandle })}
    </div>
  );
};

export const SortableItem = <T extends { id: string }>({
  item,
  renderItemContent,
  onlyHandleDraggable,
  disabled,
  wrapperStyle,
}: ItemProps<T> & { onlyHandleDraggable: boolean }): ReactElement => {
  const { listeners, setNodeRef, transform, transition, active, isDragging } = useSortable({
    id: item.id,
    disabled,
  });
  const style = {
    ...wrapperStyle,
    transform: CSS.Transform.toString(transform),
    transition: active ? transition : undefined,
    opacity: isDragging ? 0 : 1,
  };

  const renderDragHandle = (props: RenderDragHandleProps) => {
    return (
      <div
        {...listeners}
        onClick={(event) => props.onClick?.(event)}
        onPointerDownCapture={props.onPointerDownCapture}
        className={cx(props.className, isDragging ? 'cursor-[grabbing]' : 'cursor-[grab]')}
      >
        <Tooltip popup={props.popup}>
          <Icon name="IcBlockHandle" size="normal" />
        </Tooltip>
      </div>
    );
  };
  return (
    <div {...(onlyHandleDraggable ? {} : listeners)} ref={setNodeRef} style={style}>
      {renderItemContent({
        item,
        isPreview: false,
        renderDragHandle,
        listeners,
        active,
        isDragging,
      })}
    </div>
  );
};
