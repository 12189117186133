import { sleep } from '@flowus/common/async';
import { MoreButtonList } from '@flowus/common/components/more-button-list';
import { cx } from '@flowus/common/cx';
import { EmbedType, getEmbedWebsiteUrl } from '@flowus/common/embed-website';
import { BlockType } from '@next-space/fe-api-idl';
import type { FC, MouseEvent } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'src/common/components/button';
import { IconWidget } from 'src/common/components/emoji-widget';
import { Input } from 'src/common/components/input';
import { LoadingContainer } from 'src/common/components/loading-container';
import { useOpenModal } from 'src/common/components/next-modal';
import { DEFAULT_PAGE_WIDTH } from 'src/common/const';
import { useVisible } from 'src/common/hooks/use-visible';
import { useDoneLocalStatus } from 'src/hooks/block/use-local-status';
import { useResize } from 'src/hooks/block/use-resize';
import { useReadonly } from 'src/hooks/page';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import type { ReadonlyProp } from 'src/redux/types';
import { useIsMobileSize } from 'src/services/app/hook';
import { $searchParams } from 'src/utils';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { getLinkInfo, isBookmarkLink } from 'src/utils/embed';
import { usePickBlock } from 'src/utils/pick-block';
import { BlockEmptyView } from '../../component/block-empty-view';
import { Caption } from '../../component/caption';
import { getEmbedThirdParty } from '../../component/menu-list/embeds-data';
import { ResizeElement } from '../../component/resize-element';
import { textToSegments } from '../../utils/editor';
import type { BlockElement } from '../core/type';
import { BlockDrop } from './dnd/block-drop';
import { PrintMedium } from './print';

// #region 入口
export const EmbedBlockElement: BlockElement = ({ id, root }) => {
  const block = usePickBlock(id, ['data', 'local'], ['link']);

  if (!block) return null;

  const isPrint = $searchParams.print;

  return block.data.link && !block.local ? (
    isPrint ? (
      <PrintMedium uuid={id} url={block.data.link} />
    ) : (
      <EmbedResizeContent id={id} root={root} />
    )
  ) : (
    <EmptyEmbed id={id} root={root} />
  );
};
// #endregion

// #region 空白占位
interface EmptyEmbedProps extends ReadonlyProp {
  id: string;
  root?: boolean;
}
const EmptyEmbed: FC<EmptyEmbedProps> = (props) => {
  const block = usePickBlock(props.id, ['local', 'data'], ['link', 'embedType']);
  const openModal = useOpenModal();
  const [loading, setLoading] = useState(false);
  const inputRef = useRef('');
  const transaction = useTransaction();
  const readonly = useReadonly(props.id);
  const embedType = block?.data.embedType;
  const embedData = embedType ? getEmbedThirdParty()[embedType]?.data : null;
  useDoneLocalStatus({
    uuid: props.id,
    callback: () => block?.data.link && void saveLink(block.data.link),
  });

  useEffect(() => {
    if (block && block.local && block.data.link) {
      void saveLink(block.data.link);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveLink = useCallback(
    async (link: string) => {
      if (!link.trim()) return;
      setLoading(true);
      const res = await getLinkInfo(link, true);

      transaction(() => {
        if (
          res?.headerMap?.['X-Frame-Options'] !== undefined &&
          (res.headerMap['X-Frame-Options'].toLocaleLowerCase() === 'allow' ||
            res.headerMap['X-Frame-Options'] === '') &&
          res.link.match(/(https):\/\/([\w.]+\/?)\S*/gi) &&
          !isBookmarkLink(res.link)
        ) {
          updateBlock(props.id, { data: { link } });
        } else {
          updateBlock(props.id, {
            type: BlockType.BOOKMARK,
            data: {
              link,
              linkInfo: textToSegments(res?.title),
              cover: res?.cover,
              icon: {
                type: 'http',
                value: res?.icon,
              },
              description: textToSegments(res?.description),
              headers: res?.headerMap,
            },
          });
        }
      });

      setLoading(false);
    },
    [props.id, transaction]
  );

  const openUploadPanel = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (loading) return;

      openModal.dropdown({
        popcorn: event.currentTarget,
        placement: 'bottom',
        content: ({ onCloseModal }) => {
          return (
            <div className="flex flex-col w-[370px] next-modal px-2.5 py-3.5">
              <Input
                autoFocus
                className="h-8 rounded-sm"
                placeholder={embedData ? embedData.placeholder : 'https://www.example.com'}
                onChange={(v) => (inputRef.current = v)}
                onEnter={() => {
                  void saveLink(inputRef.current);
                  onCloseModal();
                }}
              />
              <Button
                size="large"
                colorType="active"
                className="mt-3.5 text-t4-medium"
                onClick={() => {
                  void saveLink(inputRef.current);
                  onCloseModal();
                }}
              >
                提交
              </Button>
            </div>
          );
        },
      });
    },
    [embedData, loading, openModal, saveLink]
  );

  return (
    <BlockDrop id={props.id} horizontal={props.root} className="my-1 print:hidden">
      <BlockEmptyView
        readonly={readonly}
        iconElement={embedData && <img src={embedData.image} className="w-6 h-6" />}
        defaultOpenPanel={Boolean(block?.local && !block.data.link)}
        iconProp={{ name: 'IcUploadWeb', size: 'normal' }}
        text={loading ? '正在嵌入内容...' : embedData ? `嵌入${embedData.title}` : '添加嵌入内容'}
        onClick={openUploadPanel}
        className={readonly ? 'cursor-default' : 'cursor-pointer'}
      />
    </BlockDrop>
  );
};
// #endregion

// #region 内容
export const EmbedResizeContent: FC<{ id: string; root?: boolean }> = ({ id, root }) => {
  const [loading, setLoading] = useState(false);
  const [loadFail, setLoadFail] = useState(false);
  const isMobileSize = useIsMobileSize();
  const loadRef = useRef(setTimeout(() => {}));
  const block = usePickBlock(id, ['data'], ['link']);
  const readonly = useReadonly(id);
  const [visible, iframeRef, ref] = useVisible<HTMLIFrameElement>();
  /** 拿到转化后的link */
  const [currentLink, setCurrentLink] = useState('');
  /** 特殊网站需要特殊配置 */
  const specialWebsiteConfig = useMemo(() => {
    const { website, url } = getEmbedWebsiteUrl(currentLink);
    if (!website) return {};
    if (website === EmbedType.music163) {
      if (url.includes('?type=2')) {
        return {
          resizeHeight: false,
          defaultHeight: 86,
        };
      }
    }
    return {};
  }, [currentLink]);

  const {
    renderSize,
    onRenderSize,
    changeSize,
    containerWidth,
    containerRef,
    isDisable,
    defaultWidth,
    defaultHeight,
    defaultBlockFullWidth,
    widthBreakPoints,
  } = useResize({
    id,
    defaultWidth: 500,
    defaultHeight: 400,
    root,
  });

  useEffect(() => {
    if (visible && !currentLink) {
      void getLinkInfo(block?.data.link ?? '', true).then((linkInfo) => {
        setCurrentLink(linkInfo?.link || block?.data.link || '');
      });
    }
  }, [block?.data.link, currentLink, visible]);

  /** 复制原链接 */
  const copyUrl = useCallback(() => {
    void writeTextInClipboard(block?.data.link ?? '');
  }, [block?.data.link]);

  /** 全屏查看 */
  const fullPage = useCallback(() => {
    void ref.current?.requestFullscreen();
  }, [ref]);

  /** 重新load */
  const reloadIframe = useCallback(() => {
    setCurrentLink('');
    setLoading(true);
    setLoadFail(false);
    void sleep(1000).then(() => {
      setCurrentLink(currentLink);
    });
  }, [currentLink]);

  if (!block) return null;

  return (
    <BlockDrop
      blockRef={containerRef}
      id={id}
      horizontal={root}
      fullWidth={!root || isMobileSize}
      className="my-2.5 self-center"
    >
      <ResizeElement
        resizeHeight
        widthBreakPoints={widthBreakPoints}
        readonly={readonly}
        doubleResize={root}
        defaultWidth={defaultWidth || DEFAULT_PAGE_WIDTH}
        defaultHeight={defaultHeight}
        defaultBlockFullWidth={defaultBlockFullWidth}
        maxWidth={containerWidth}
        minHeight={80}
        onChange={changeSize}
        onRenderSize={onRenderSize}
        {...specialWebsiteConfig}
      >
        {loadFail && !loading && (
          <div
            className="flex flex-col items-center justify-center w-full h-full cursor-pointer bg-grey8"
            onClick={reloadIframe}
          >
            <IconWidget emojiStr="🕸️" iconSize={70} className="mb-2 pointer-events-none" />
            <div className="text-grey4 text-t2">网页加载失败，点击刷新</div>
          </div>
        )}
        {!loadFail && loading && <LoadingContainer />}
        <div className="w-full h-full">
          <MoreButtonList
            renderList={[
              {
                icon: 'IcMenuOpen',
                title: '全屏查看',
                click: fullPage,
              },
              {
                icon: 'IcUrl',
                title: '复制链接',
                click: copyUrl,
              },
            ]}
          />
          <div className="relative w-full h-full overflow-hidden">
            <iframe
              referrerPolicy="origin"
              allowFullScreen
              className={cx('w-full h-full absolute inset-0', isDisable && 'pointer-events-none', {
                hidden: loadFail,
              })}
              ref={iframeRef}
              src={currentLink}
              sandbox="allow-scripts allow-popups allow-top-navigation-by-user-activation allow-forms allow-same-origin"
              onLoad={() => {
                clearTimeout(loadRef.current);
                setLoadFail(false);
                setLoading(false);
              }}
            />
          </div>
        </div>
      </ResizeElement>
      <Caption blockId={id} style={{ width: renderSize.width }} />
    </BlockDrop>
  );
};
// #endregion
