import { cx } from '@flowus/common/cx';
import { useCallback } from 'react';
import { useOpenModal } from 'src/common/components/next-modal';
import { SelectUpgradePlan } from 'src/components/select-upgrade-plan';
import { Modals } from 'src/modals';
import type { SpacePlanType } from 'src/redux/types';
import { bizTracker } from 'src/utils/biz-tracker';
import { OpenSettingFrom } from './type';

// 打开升级弹窗
export const useOpenUpgradePlan = () => {
  const openModal = useOpenModal();

  return useCallback(
    (type: SpacePlanType, $is_from?: OpenSettingFrom) => {
      const isForm = $is_from || OpenSettingFrom.package;
      bizTracker.event('pay_show', {
        is_from: isForm,
      });

      openModal.modal({
        modalId: Modals.SELECT_UPDATE_PLAN,
        className: cx('max-h-[90vh] overflow-auto', __BUILD_IN__ ? 'w-[960px]' : 'w-[670px]'),
        keyboard: false,
        content: () => <SelectUpgradePlan selectPlanSpaceType={type} isFrom={isForm} />,
      });
    },
    [openModal]
  );
};
