import { cx } from '@flowus/common/cx';
import { EmbedType, getEmbedWebsiteUrl } from '@flowus/common/embed-website';
import { useOpenModal } from '@flowus/common/next-modal';
import { useMindMapId } from '@flowus/mind-map';
import { BlockType } from '@next-space/fe-api-idl';
import type { SegmentDTO } from '@next-space/fe-api-idl/lib/models/SegmentDTO';
import type { IContent, ISelection } from '@next-space/fe-inlined/types';
import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'src/common/components/button';
import { IconWidget } from 'src/common/components/emoji-widget/icon-widget';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { LoadingContainer } from 'src/common/components/loading-container';
import { DEFAULT_PAGE_WIDTH } from 'src/common/const';
import { useDetectIframeClick } from 'src/common/hooks/use-detected-iframe-click';
import { BlockEmptyView } from 'src/editor/component/block-empty-view';
import { getEmbedThirdParty } from 'src/editor/component/menu-list/embeds-data';
import { ResizeElement } from 'src/editor/component/resize-element';
import { textToSegments } from 'src/editor/utils/editor';
import { useDoneLocalStatus } from 'src/hooks/block/use-local-status';
import { useResize } from 'src/hooks/block/use-resize';
import { useThrottleUpdateSegments } from 'src/hooks/block/use-throttle-update-block';
import { useReadonly } from 'src/hooks/page/use-read-only';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { useIsSelected } from 'src/redux/managers/ui';
import { uiActions } from 'src/redux/reducers/ui';
import { cache, dispatch } from 'src/redux/store';
import { useTextareaPlaceHolder } from 'src/services/app';
import { sleep } from 'src/utils/async-utils';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { getLinkInfo, isBookmarkLink } from 'src/utils/embed';
import { usePickBlock } from 'src/utils/pick-block';
import { NodeWrapper } from '../component/node-wrapper';
import { useMonitorFocused } from '../hook/use-monitor-focused';
import type { MindNodeElement } from './all-node-renderer';
import { MindMapRichText } from './editor/mind-map-rich-text';
export const EmbedNode: MindNodeElement = React.memo((props) => {
  const block = usePickBlock(props.id, ['data'], ['link']);
  return block?.data.link ? (
    <EmbedResizeContent id={props.id} level={props.level} />
  ) : (
    <EmptyEmbed id={props.id} level={props.level} />
  );
});

export const EmbedResizeContent: FC<{ id: string; level: number }> = ({ id, level }) => {
  const root = level === 0;
  const mindMapId = useMindMapId();
  const [loading, setLoading] = useState(true);
  const [loadFail, setLoadFail] = useState(false);
  const loadRef = useRef(setTimeout(() => {}));
  const block = usePickBlock(id, ['data'], ['segments', 'caption', 'link']);
  const { alwaysShowPlaceHolder, placeHolder } = useTextareaPlaceHolder(id, {
    defaultPlaceholder: `输入文字`,
  });
  const updateSegments = useThrottleUpdateSegments(id, 'caption');
  const isSelected = useIsSelected(id);
  const readonly = useReadonly(id);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  /** 拿到转化后的link */
  const [currentLink, setCurrentLink] = useState('');
  /** 特殊网站需要特殊配置 */
  const specialWebsiteConfig = useMemo(() => {
    const { website, url } = getEmbedWebsiteUrl(currentLink);
    if (!website) return {};
    if (website === EmbedType.music163) {
      if (url.includes('?type=2')) {
        return {
          resizeHeight: false,
          defaultHeight: 86,
        };
      }
    }
    return {};
  }, [currentLink]);
  const { onMouseOver, onMouseOut } = useDetectIframeClick(() => {
    if (isSelected) return;
    // 这里多选的情况无法判断，只能不处理
    if (cache.ui.selectedBlocks.length >= 1) return;
    dispatch(
      uiActions.updateSelectBlocks([
        {
          blockId: id,
          mindMapId,
        },
      ])
    );
  });

  const {
    onRenderSize,
    changeSize,
    containerRef,
    isDisable,
    defaultWidth,
    defaultHeight,
    defaultBlockFullWidth,
    widthBreakPoints,
  } = useResize({
    id,
    defaultWidth: 500,
    defaultHeight: 400,
    root,
  });
  useMonitorFocused(containerRef, id, 'caption');
  useEffect(() => {
    void getLinkInfo(block?.data.link ?? '', true).then((linkInfo) => {
      setCurrentLink(linkInfo?.link || block?.data.link || '');
    });
  }, [block?.data.link]);

  /** 复制原链接 */
  const copyUrl = useCallback(() => {
    void writeTextInClipboard(block?.data.link ?? '');
  }, [block?.data.link]);

  /** 重新load */
  const reloadIframe = useCallback(() => {
    if (iframeRef.current) {
      iframeRef.current.src = '';
      setLoading(true);
      setLoadFail(false);
      loadRef.current = setTimeout(() => {
        setLoadFail(true);
      }, 10000);
      iframeRef.current.src = currentLink;
    }
  }, [currentLink]);

  /**
   * 初始化时开始倒数10秒，超时没有触发onLoad判断为加载失败
   * - 是否真的需要loading?
   */
  useEffect(() => {
    void sleep(1000).then(() => {
      setLoadFail(false);
    });
    loadRef.current = setTimeout(() => {
      setLoadFail(true);
      setLoading(false);
    }, 10000);
  }, []);
  useEffect(() => {
    iframeRef.current?.addEventListener;
  }, []);

  if (!block) return null;

  return (
    <NodeWrapper id={id} ref={containerRef} level={level}>
      <div className="w-[520px] px-3">
        <MindMapRichText
          placeholder={placeHolder}
          uuid={id}
          alwaysShowPlaceholder={alwaysShowPlaceHolder}
          className="whitespace-pre-wrap break-words"
          segments={block.data.caption ?? []}
          segmentType="caption"
          onChange={(
            segments: SegmentDTO[],
            prevContent: IContent,
            prevSelection: ISelection | null
          ) => {
            updateSegments(segments, [prevContent, prevSelection]);
          }}
        />
        <ResizeElement
          resizeHeight
          resizeWidth
          widthBreakPoints={widthBreakPoints}
          readonly={readonly}
          doubleResize={root}
          defaultWidth={defaultWidth || DEFAULT_PAGE_WIDTH}
          defaultHeight={defaultHeight}
          defaultBlockFullWidth={defaultBlockFullWidth}
          maxWidth={DEFAULT_PAGE_WIDTH}
          minWidth={36}
          minHeight={80}
          outerLayoutClassName="w-full"
          className="w-full"
          onChange={changeSize}
          onRenderSize={onRenderSize}
          {...specialWebsiteConfig}
        >
          {loadFail && !loading && (
            <div
              className="flex flex-col items-center justify-center w-full h-full cursor-pointer bg-grey8"
              onClick={reloadIframe}
            >
              <IconWidget emojiStr="🕸️" iconSize={70} className="mb-2 pointer-events-none" />
              <div className="text-grey4 text-t2">网页加载失败，点击刷新</div>
            </div>
          )}
          {!loadFail && loading && <LoadingContainer />}
          <div className="w-full h-full">
            {isSelected && (
              <div
                onClick={copyUrl}
                className={cx(
                  'flex absolute top-2.5 right-3 z-10 cursor-pointer bg-black/75 opacity-0 group-hover:opacity-100 text-white1 transition-opacity text-t4 px-1 py-0.5 rounded-sm animate-click items-center'
                )}
              >
                <Icon name="IcUrl" className="mr-1" size="middle" />
                <span>复制链接</span>
              </div>
            )}
            <div
              className="relative w-full h-full overflow-hidden"
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
            >
              <iframe
                referrerPolicy="origin"
                allowFullScreen
                className={cx(
                  'w-full h-full absolute inset-0',
                  isDisable && 'pointer-events-none',
                  {
                    hidden: loadFail,
                  }
                )}
                ref={iframeRef}
                src={currentLink}
                sandbox="allow-scripts allow-popups allow-top-navigation-by-user-activation allow-forms allow-same-origin"
                onLoad={() => {
                  clearTimeout(loadRef.current);
                  setLoadFail(false);
                  setLoading(false);
                }}
              />
            </div>
          </div>
        </ResizeElement>
      </div>
    </NodeWrapper>
  );
};

const EmptyEmbed: FC<{ id: string; level: number }> = ({ id, level }) => {
  const block = usePickBlock(id, ['data', 'local'], ['link', 'embedType']);
  const openModal = useOpenModal();
  const [loading, setLoading] = useState(false);
  const inputRef = useRef('');
  const transaction = useTransaction();
  const readonly = useReadonly(id);
  const embedType = block?.data.embedType;
  const embedData = embedType ? getEmbedThirdParty()[embedType]?.data : null;
  useDoneLocalStatus({ uuid: id });

  useEffect(() => {
    if (block && block.local && block.data.link) {
      void saveLink(block.data.link);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveLink = useCallback(
    async (link: string) => {
      if (!link.trim()) return;
      setLoading(true);
      const res = await getLinkInfo(link, true);

      transaction(() => {
        if (
          res?.headerMap?.['X-Frame-Options'] !== undefined &&
          (res.headerMap['X-Frame-Options'].toLocaleLowerCase() === 'allow' ||
            res.headerMap['X-Frame-Options'] === '') &&
          res.link.match(/(https):\/\/([\w.]+\/?)\S*/gi) &&
          !isBookmarkLink(res.link)
        ) {
          updateBlock(id, { data: { link } });
        } else {
          updateBlock(id, {
            type: BlockType.BOOKMARK,
            data: {
              link,
              linkInfo: textToSegments(res?.title),
              cover: res?.cover,
              icon: {
                type: 'http',
                value: res?.icon,
              },
              description: textToSegments(res?.description),
              headers: res?.headerMap,
            },
          });
        }
      });

      setLoading(false);
    },
    [id, transaction]
  );

  const openUploadPanel = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (loading) return;

      openModal.dropdown({
        popcorn: event.currentTarget,
        placement: 'bottom',
        content: ({ onCloseModal }) => {
          return (
            <div className="flex flex-col w-[370px] next-modal px-2.5 py-3.5">
              <Input
                autoFocus
                className="h-8 rounded-sm"
                placeholder={embedData ? embedData.placeholder : 'https://www.example.com'}
                onChange={(v) => (inputRef.current = v)}
                onEnter={() => {
                  void saveLink(inputRef.current);
                  onCloseModal();
                }}
              />
              <Button
                size="large"
                colorType="active"
                className="mt-3.5 text-t4-medium"
                onClick={() => {
                  void saveLink(inputRef.current);
                  onCloseModal();
                }}
              >
                提交
              </Button>
            </div>
          );
        },
      });
    },
    [embedData, loading, openModal, saveLink]
  );

  return (
    <NodeWrapper id={id} level={level} className="max-w-[380px]">
      <BlockEmptyView
        readonly={readonly}
        iconElement={embedData && <img src={embedData.image} className="w-6 h-6" />}
        defaultOpenPanel={Boolean(block?.local && !block.data.link)}
        iconProp={{ name: 'IcUploadWeb', size: 'normal' }}
        text={loading ? '正在嵌入内容...' : embedData ? `嵌入${embedData.title}` : '添加嵌入内容'}
        onClick={openUploadPanel}
        className={readonly ? 'cursor-default' : 'cursor-pointer'}
      />
    </NodeWrapper>
  );
};
