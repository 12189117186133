import { useMemo, type FC } from 'react';
import { Select } from './select';
import {
  AggregationAction,
  CollectionSchemaType,
  type YAxisProperty,
} from '@next-space/fe-api-idl';
import { getProperty } from 'src/utils/collection-util';
import { useStore } from 'react-redux';
import { Icon } from 'src/common/components/icon';
import { useSelectProperty } from './use-select-property';
import { useChartFormat, useUpdateChartFormat } from 'src/hooks/chart';
import { useChartBlockId } from './chart-config-context';
import { getCalculateItems } from '@flowus/chart/chart-util';
import { ALL_CALCULATE_ITEMS, isChartNumberAggregation } from '@flowus/chart/types';
import { useOpenModal } from '@flowus/common/next-modal';
import { ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';
import { defaultYAxisProperty } from './y-axis-field';
import { invalidProperty } from './data';
import { cx } from '@flowus/common/cx';
import { getState } from 'src/redux/store';
import { getFormulaTool } from 'src/hooks/block/use-formula-tool';

interface Props {
  yAxisIndex: number;
}
export const CalculateItem: FC<Props> = (props) => {
  const store = useStore();
  const chartBlockId = useChartBlockId();
  const format = useChartFormat(chartBlockId);
  const collectionId = format?.ref?.uuid;
  const selectProperty = useSelectProperty();
  const updateChartFormat = useUpdateChartFormat();
  const yAxisProperty = format?.yAxisPropertyIds?.[props.yAxisIndex] ?? defaultYAxisProperty;
  const openModal = useOpenModal();
  const propertyInfo =
    useMemo(() => {
      if (!yAxisProperty?.propertyId) return;
      return getProperty(store, collectionId, yAxisProperty?.propertyId ?? '');
    }, [collectionId, yAxisProperty, store]) ?? invalidProperty;

  const calculateItems = useMemo(() => {
    if (propertyInfo?.type === CollectionSchemaType.FORMULA && collectionId) {
      const formulaTool = getFormulaTool(collectionId, getState());
      const schemaType = formulaTool.getTypeAsCollectionSchemaType(yAxisProperty?.propertyId);
      return getCalculateItems(schemaType);
    }
    return getCalculateItems(propertyInfo?.type);
  }, [propertyInfo?.type, collectionId, yAxisProperty?.propertyId]);

  const propertyNode = propertyInfo ? (
    <div className="flex items-center">
      {propertyInfo.icon && <Icon name={propertyInfo.icon} size="middle" />}
      <div
        className={cx('text-ellipsis max-w-[124px] ml-0.5', {
          'text-red': invalidProperty === propertyInfo,
        })}
      >
        {propertyInfo?.title}
      </div>
    </div>
  ) : undefined;

  const updateYAxisPropertyId = (propertyId: string, yAxisProperty: YAxisProperty) => {
    if (!format) return;
    // 如果没有就加一个
    if (!format.yAxisPropertyIds) {
      updateChartFormat(chartBlockId, {
        yAxisPropertyIds: [yAxisProperty],
      });
      return;
    }
    // 如果已经存在就替换一下
    const index = format.yAxisPropertyIds.findIndex((v) => v.propertyId === propertyId);
    const pre = format.yAxisPropertyIds.slice(0, index);
    const next = format.yAxisPropertyIds.slice(index + 1);
    // 替换掉原来的
    updateChartFormat(chartBlockId, {
      yAxisPropertyIds: [...pre, yAxisProperty, ...next],
    });
  };

  return (
    <div className="flex items-center space-x-1">
      <Select
        className="w-[180px]"
        title={propertyNode}
        placeHolder={'请选择字段'}
        onClick={(e) => {
          if (!collectionId) return;
          selectProperty(collectionId, {
            className: 'w-[180px]',
            popcorn: e.currentTarget,
            callback: (propertyId) => {
              if (propertyId === yAxisProperty?.propertyId) return;
              const currentSelectProperty = getProperty(store, collectionId, propertyId);
              // 如果上一个是数字但当前选中了非数字，且aggregation是数字的aggregation，就还原会默认的。
              const isNumberAggregation = isChartNumberAggregation(yAxisProperty.aggregation);
              if (
                propertyInfo?.type === CollectionSchemaType.NUMBER &&
                currentSelectProperty?.type !== CollectionSchemaType.NUMBER &&
                isNumberAggregation
              ) {
                updateYAxisPropertyId(yAxisProperty?.propertyId ?? '', {
                  propertyId,
                  aggregation: AggregationAction.COUNT_ALL,
                });
              } else {
                // 否则就保持默认的aggregation
                updateYAxisPropertyId(yAxisProperty?.propertyId ?? '', {
                  propertyId,
                  aggregation: yAxisProperty.aggregation,
                });
              }
            },
          });
        }}
      />
      <Select
        title={ALL_CALCULATE_ITEMS[yAxisProperty?.aggregation ?? '']}
        className="cursor-pointer w-[100px] shrink-0"
        placeHolder={'计数'}
        onClick={(e) => {
          openModal.dropdown({
            popcorn: e.currentTarget,
            content: ({ onCloseModal }) => {
              if (!yAxisProperty) return null;
              const items = Object.keys(calculateItems).map((k) => {
                const title = calculateItems[k];
                const item: ListItem = {
                  type: ListItemType.OPERATION,
                  data: {
                    title,
                    onClick: () => {
                      updateYAxisPropertyId(yAxisProperty.propertyId!, {
                        propertyId: yAxisProperty.propertyId,
                        aggregation: k as AggregationAction,
                      });
                    },
                  },
                };
                return item;
              });
              return (
                <ListView
                  className="next-modal w-[100px]"
                  items={items}
                  onItemClick={() => {
                    onCloseModal();
                  }}
                />
              );
            },
          });
        }}
      />
      {(format?.yAxisPropertyIds ?? [])?.length > 1 && (
        <div
          className="animate-hover p-1"
          onClick={() => {
            updateChartFormat(chartBlockId, {
              yAxisPropertyIds:
                format?.yAxisPropertyIds?.filter(
                  (v) => v.propertyId !== yAxisProperty.propertyId
                ) ?? [],
            });
          }}
        >
          <Icon name={'IcTitleClose'} size="small" />
        </div>
      )}
    </div>
  );
};
