import { cx } from '@flowus/common/cx';
import { CollectionSchemaType, TextType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import type { IconName } from 'src/common/components/icon';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { useUpdatePropertyValue } from 'src/hooks/block/use-update-property-value';
import { useReadonly } from 'src/hooks/page';
import { getState } from 'src/redux/store';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { $searchParams } from 'src/utils';
import { normalizeHref } from 'src/utils/embed';
import { isPhoneNumber } from 'src/utils/number';
import isEmail from 'validator/lib/isEmail';
import { useOpenFile } from './files';
import { readUrlFromSegments } from './helpers';
import { TextArea } from './text-area';
import { genericTextEditor } from './text-editor';
import type { CellViewProps } from './types';
import { Site } from './types';
import { shortUrl } from '@flowus/common/embed-website';
import { useBitable } from 'src/bitable/context';

export const genericLinkValue =
  (opts: {
    icon: IconName;
    tooltip: string;
    validate: (url: string) => boolean;
    getLinkUrl: (url: string) => string;
  }): FC<CellViewProps> =>
  ({ className, recordId, propertyId, site }) => {
    const { collectionId } = useBitable();
    const openFile = useOpenFile();
    const url = useObservableStore(
      ({ blocks }) => {
        const segments = blocks[recordId]?.data.collectionProperties?.[propertyId];
        return readUrlFromSegments(segments);
      },
      [recordId, propertyId]
    );
    const fullUrl = useObservableStore(
      ({ blocks }) => {
        return blocks[collectionId]?.data.schema?.[propertyId]?.fullUrl;
      },
      [recordId, propertyId]
    );
    const readonly = useReadonly(recordId, false);

    if (!url) return null;

    return (
      <div
        className={cx(
          'group-scope w-full overflow-hidden leading-[20px]',
          site === Site.LIST && 'flex',
          (site === Site.CELL || site === Site.FIELD) && 'p-2 break-all',
          (site === Site.LIST || site === Site.CALENDAR) && 'overflow-ellipsis',
          className
        )}
      >
        {$searchParams.print ? (
          <a href={normalizeHref(url)} target="_blank" className="underline">
            {fullUrl ? url : shortUrl(url)}
          </a>
        ) : (
          <span
            className={cx(
              'border-grey5 cursor-pointer border-b text-black',
              (site === Site.CARD || site === Site.LIST || site === Site.CALENDAR) &&
                'animate-hover hover:rounded-sm'
            )}
            onClick={(e) => {
              if (readonly || site === Site.CARD || site === Site.LIST) {
                e.stopPropagation();
                window.open(opts.getLinkUrl(url));
              }
            }}
          >
            {fullUrl ? url : shortUrl(url)}
          </span>
        )}

        {(site === Site.CELL || site === Site.FIELD) && opts.validate(url) && (
          <a
            className={cx(
              'group-scope-hover:opacity-100 animate-hover-opaque absolute right-[5px] top-[5px] rounded border bg-white1 p-0.5 opacity-0 transition-opacity'
            )}
            href={opts.getLinkUrl(url)}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(event) => {
              event.stopPropagation();
              // NOTE: 针对文件链接的特殊处理
              const { blocks } = getState();
              const segments = blocks[recordId]?.data.collectionProperties?.[propertyId];
              const segment = segments?.find((item) => {
                return item.type === TextType.URL;
              });
              if (segment && segment.fileStorageType === 'internal') {
                event.preventDefault();
                void openFile(recordId, propertyId, segment);
              }
            }}
          >
            <Tooltip popup={opts.tooltip}>
              <Icon size="middle" name={opts.icon} className="text-grey3" />
            </Tooltip>
          </a>
        )}
      </div>
    );
  };

export const generateGetLinkUrl = (type: CollectionSchemaType) => {
  return (url: string) => {
    if (type === CollectionSchemaType.EMAIL) {
      return `mailto:${url}`;
    } else if (type === CollectionSchemaType.PHONE) {
      return `tel:${url}`;
    }

    return normalizeHref(url);
  };
};

export const UrlValue = genericLinkValue({
  icon: 'IcTitleUrl',
  tooltip: '访问链接',
  validate: (url) => {
    return url.trim() !== '';
  },
  getLinkUrl: generateGetLinkUrl(CollectionSchemaType.URL),
});
export const EmailValue = genericLinkValue({
  icon: 'IcTitleEmail',
  tooltip: '发送邮件',
  validate: (url) => {
    return isEmail(url);
  },
  getLinkUrl: generateGetLinkUrl(CollectionSchemaType.EMAIL),
});
export const PhoneValue = genericLinkValue({
  icon: 'IcTitlePhone',
  tooltip: '拨打电话',
  validate: (url) => {
    return isPhoneNumber(url);
  },
  getLinkUrl: generateGetLinkUrl(CollectionSchemaType.PHONE),
});

export const UrlEditor = genericTextEditor<string>({
  useValue: ({ recordId, propertyId }) => {
    const value = useObservableStore(
      ({ blocks }) => {
        const segments = blocks[recordId]?.data.collectionProperties?.[propertyId];
        return readUrlFromSegments(segments);
      },
      [recordId, propertyId]
    );
    return value;
  },
  useSaveValue: ({ recordId, propertyId }) => {
    const updatePropertyValue = useUpdatePropertyValue();
    return (value) => {
      updatePropertyValue(
        recordId,
        propertyId,
        value
          ? [
              {
                type: TextType.URL,
                enhancer: {},
                text: value,
                url: value,
              },
            ]
          : undefined
      );
    };
  },
  ValueEditor: ({ value, onChange }) => {
    return (
      <TextArea
        autoFocus
        singleLine
        value={value}
        fontClassName={'whitespace-nowrap'}
        onChange={(event) => {
          onChange(event.target.value);
        }}
      />
    );
  },
});
