import { BlockType, PermissionRole } from '@next-space/fe-api-idl';
import type { FC, MouseEvent } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { ListItemType, ListView } from 'src/common/components/list-view';
import type { ModalSchema } from 'src/common/components/next-modal';
import { useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { request } from 'src/common/request';
import { useOpenMoveTo } from 'src/components/move-to';
import { useOpenCompleteDelete } from 'src/components/open-complete-delete';
import { segmentsToText, textToSegments } from 'src/editor/utils/editor';
import { ActivityIds } from 'src/hooks/activities/activity-ids';
import { ActivitiesListType } from 'src/hooks/activities/use-activity';
import { useUpdateTask } from 'src/hooks/activities/use-update-task';
import { useCopyBlock } from 'src/hooks/block/use-copy-block';
import { useNormalizePathFun, useReadonly } from 'src/hooks/page';
import { useOpenPage } from 'src/hooks/page/use-open-page';
import { useCanMove, useIsGuest, useParentIsOk } from 'src/hooks/share/use-permission-utils';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useTransaction } from 'src/hooks/use-transaction';
import { getCurrentUser, isFavoritePage, useCurrentUser } from 'src/hooks/user';
import { DESTROY_BLOCK } from 'src/redux/actions';
import { archiveBlock } from 'src/redux/managers/block/archive';
import { updateBlock } from 'src/redux/managers/block/update';
import * as FavoriteManager from 'src/redux/managers/favorite';
import { cache, dispatch } from 'src/redux/store';
import { $currentUserCache } from 'src/services/user/current-user';
import { bizTracker } from 'src/utils/biz-tracker';
import { isLinkPageBlock, isLinkTableBlock } from 'src/utils/block-type-utils';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { judgeSharePage, judgeSubscribePage } from 'src/utils/getPageId';
import { getLocationOrigin } from 'src/utils/location-utils';
import { usePickBlock } from 'src/utils/pick-block';
import { bindDataTestId, TestIds } from 'src/utils/qa-utils';
import { getVirtualElement } from 'src/utils/virtualElement';
import { useTocGroupType } from './toc-group-header';
import { TocType } from './types';
import { findFirstAllowSubscribePage } from 'src/utils/block-utils';
import { addSubscribe } from '@flowus/common';

export const leaveDoc = async (uuid: string) => {
  await request.editor.leaveDoc(uuid);
  dispatch(
    DESTROY_BLOCK({
      uuid,
      ignoreOp: true,
    })
  );
};

interface Props {
  /** page ID */
  uuid: string;
  close(): void;
  popcorn: ModalSchema.PopcornType;
  isInFavoriteTop: boolean;
  groupType: TocType;
  isRoot: boolean;
}
const unSubscribeSet = new Set<string>();

const TocMenuList: FC<Props> = ({ uuid, popcorn, close, isInFavoriteTop, groupType, isRoot }) => {
  const isFavorite = isFavoritePage(uuid);
  const currentUser = useCurrentUser();
  const block = usePickBlock(uuid, ['data', 'createdBy'], ['segments', 'ref']);
  const isLinkPage = isLinkPageBlock(block);
  const transaction = useTransaction();
  const openMoveTo = useOpenMoveTo();
  const openModal = useOpenModal();
  const copyBlock = useCopyBlock();
  const openCompleteDelete = useOpenCompleteDelete(uuid);
  const isFileBlock = block?.type === BlockType.FILE;
  // 被风控了
  const { illegal, role } = usePermissions(uuid);
  const readonly = useReadonly(uuid);
  const canMove = useCanMove(uuid);
  const parentIsOk = useParentIsOk(uuid);
  const history = useHistory();
  const isSharePage = judgeSharePage();
  const isGuest = useIsGuest();
  const openPage = useOpenPage();
  const updateTask = useUpdateTask();
  const normalizePath = useNormalizePathFun();
  const targetId = useMemo(() => findFirstAllowSubscribePage(uuid) ?? uuid, [uuid]);
  const openRename = () => {
    bizTracker.event('rename_click', { group_category: groupType });
    openModal.dropdown({
      popcorn,
      placement: 'bottom-start',
      content({ onCloseModal }) {
        if (!block) return null;
        return <ChangeTitle close={onCloseModal} uuid={uuid} />;
      },
    });
  };

  let items;
  if (isSharePage) {
    items = [
      {
        type: ListItemType.OPERATION,
        data: {
          icon: 'IcUrl',
          title: '复制页面访问链接',
          onClick: () => {
            const prefix = isSharePage ? 'share/' : '';
            void writeTextInClipboard(`${getLocationOrigin()}/${prefix}${uuid}`);
          },
        },
      },
    ];
  } else if (isFileBlock) {
    // 当收藏的是文件时，只展示取消快速访问
    // 这么分开写逻辑清晰点
    items = [
      {
        type: ListItemType.OPERATION,
        data: {
          icon: 'IcQuickAccessRemove',
          title: '取消快速访问',
          onClick: () => {
            transaction(() => {
              bizTracker.event('favorite_cancel_click');
              FavoriteManager.remove(uuid, { parentId: $currentUserCache.currentSpaceViewId });
            });
          },
        },
        isHidden: !isFavorite,
      },
    ];
  } else {
    const isDeleteLinkPage = isLinkPage && !cache.blocks[block?.data.ref?.uuid ?? ''];
    items = [
      {
        type: ListItemType.OPERATION,
        data: {
          icon: 'IcMenuDelete',
          title: '删除',
          onClick: () => {
            bizTracker.event('delete_click');
            transaction(() => {
              archiveBlock(uuid);

              if (block && isLinkTableBlock(block)) {
                history.push(normalizePath(block.parentId));
              }
            });
          },
        },
        isHidden:
          isInFavoriteTop ||
          !parentIsOk ||
          !(role === PermissionRole.EDITOR || role === PermissionRole.WRITER),
      },
      {
        type: ListItemType.OPERATION,
        data: {
          icon: 'IcQuickAccessRemove',
          title: '取消快速访问',
          onClick: () => {
            bizTracker.event('favorite_cancel_click');
            transaction(() => {
              FavoriteManager.remove(uuid, { parentId: $currentUserCache.currentSpaceViewId });
            });
          },
        },
        isHidden: !isFavorite,
      },
      {
        type: ListItemType.OPERATION,
        data: {
          icon: 'IcQuickAccess',
          title: '添加到快速访问',
          onClick: () => {
            bizTracker.event('favorite_click');
            transaction(() => {
              FavoriteManager.add(uuid, {
                first: true,
                parentId: $currentUserCache.currentSpaceViewId,
              });

              // 积分任务
              void updateTask(ActivityIds.ADD_TO_FAVORITE, ActivitiesListType.advancedList);
            });
          },
        },
        isHidden: isFavorite || isLinkPage, // 引用页面也不能添加至收藏
      },
      {
        type: ListItemType.OPERATION,
        data: {
          icon: 'IcMenuDuplicate',
          title: '拷贝副本',
          onClick: () => {
            bizTracker.event('copy_click');
            void copyBlock([uuid]);
          },
        },
        isHidden: isInFavoriteTop || !parentIsOk || (isGuest && isRoot) || isDeleteLinkPage,
      },
      {
        type: ListItemType.OPERATION,
        data: {
          icon: 'IcUrl',
          title: '复制页面访问链接',
          onClick: () => {
            const prefix = isSharePage ? 'share/' : '';
            void writeTextInClipboard(`${getLocationOrigin()}/${prefix}${uuid}`);
          },
        },
      },
      {
        type: ListItemType.OPERATION,
        data: {
          icon: 'IcMenuRename',
          title: '重命名',
          onClick: openRename,
        },
        isHidden: isLinkPage || readonly,
      },
      {
        type: ListItemType.LINE,
        data: {},
        disableClick: true,
        isHidden: !canMove || isDeleteLinkPage,
      },
      {
        type: ListItemType.OPERATION,
        data: {
          icon: 'IcMenuMove',
          title: '移动到',
          onClick: () => {
            bizTracker.event('moveto_menu_click', { group_category: groupType });
            openMoveTo({ popcorn, uuid });
          },
        },
        isHidden: !canMove || isDeleteLinkPage,
      },
    ];

    if (groupType === TocType.SHARE && isRoot && isGuest) {
      items.unshift({
        type: ListItemType.LINE,
        data: {},
        disableClick: true,
        isHidden: false,
      });

      items.unshift({
        type: ListItemType.OPERATION,
        data: {
          icon: 'MIcSettingsLogout',
          title: '退出协作',
          onClick: () => {
            void leaveDoc(uuid);
          },
        },
        isHidden: false,
      });
    }
  }

  // 风控或者页面已被删除 的文件菜单很精简，就不用isHidden去剔除了
  if (!block && isInFavoriteTop) {
    items = [
      {
        type: ListItemType.OPERATION,
        data: {
          icon: 'IcQuickAccessRemove',
          title: '取消快速访问',
          onClick: () => {
            bizTracker.event('favorite_cancel_click');
            transaction(() => {
              FavoriteManager.remove(uuid, { parentId: $currentUserCache.currentSpaceViewId });
            });
          },
        },
      },
    ];
  } else if (illegal) {
    if (groupType === TocType.SHARE && isRoot && isGuest) {
      items = [
        {
          type: ListItemType.OPERATION,
          data: {
            icon: 'MIcSettingsLogout',
            title: '退出协作',
            onClick: () => {
              void leaveDoc(uuid);
            },
          },
        },
      ];
    } else {
      items = [
        {
          type: ListItemType.OPERATION,
          data: {
            icon: 'IcMenuDelete',
            title: '彻底删除',
            onClick: () => openCompleteDelete(),
          },
          isHidden: readonly,
        },
      ];
    }
  }

  const openRightPage = {
    type: ListItemType.OPERATION,
    isHidden: isFileBlock || judgeSubscribePage(),
    data: {
      icon: 'IcOpenRight',
      title: '在侧边栏中打开',
      onClick: () => openPage(uuid, { forceOpenInRight: true }),
    },
  };
  const subscribed = !unSubscribeSet.has(targetId);
  const isSubscribePageRoot = targetId === uuid;
  const subscribe = {
    type: ListItemType.OPERATION,
    isHidden: !judgeSubscribePage() || !isSubscribePageRoot || groupType === TocType.RECOMMEND,
    data: {
      tip: '取消订阅后下次访问不再显示',
      icon: 'MIcSettingsLogout',
      title: subscribed ? '取消订阅' : '订阅',
      onClick: async () => {
        const targetId = findFirstAllowSubscribePage(uuid) ?? uuid;

        await addSubscribe(
          {
            targetId,
            subscribed: !subscribed,
            addSubscribe: request.infra.addSubscribe,
            cancelSubscribe: request.infra.cancelSubscribe,
            user: currentUser,
            openDialog: openModal.warning,
            callback: (s) => s && location.reload(),
          },
          {
            pageId: uuid,
            needPay: false,
            userId: getCurrentUser().uuid,
            createdBy: block?.createdBy,
            spaceId: block?.spaceId,
            event: bizTracker.event,
          }
        );
        if (subscribed) {
          unSubscribeSet.add(targetId);
        } else {
          unSubscribeSet.delete(targetId);
        }
      },
    },
  };

  items.unshift(subscribe);
  items.unshift(openRightPage);

  return (
    <ListView
      className="w-56 py-2 rounded-r next-modal"
      items={items}
      onItemClick={() => close()}
    />
  );
};

interface MoreButtonProps {
  uuid: string;
  deep: number;
}

export const useOpenRightMenu = ({ uuid, deep }: MoreButtonProps) => {
  const openModal = useOpenModal();
  const groupType = useTocGroupType();
  const isInFavoriteTop = groupType === TocType.FAVORITE && deep === 0;

  return useCallback(
    (event: MouseEvent<HTMLElement>) => {
      bizTracker.event('pagemenu_click', { group_category: groupType });
      event.preventDefault();
      event.stopPropagation();

      openModal.dropdown({
        popcorn: getVirtualElement(event),
        placement: 'bottom-start',
        offset: [4, 4],
        content({ onCloseModal }) {
          return (
            <TocMenuList
              groupType={groupType}
              isInFavoriteTop={isInFavoriteTop}
              uuid={uuid}
              popcorn={getVirtualElement(event)}
              close={onCloseModal}
              isRoot={deep === 0}
            />
          );
        },
      });
    },
    [deep, groupType, isInFavoriteTop, openModal, uuid]
  );
};

/** TOC 更多按钮 */
export const MoreButton: FC<MoreButtonProps> = (props) => {
  // 表示这个item在收藏分类顶层，收藏顶层item弹出的菜单是特殊的
  const openRightMenu = useOpenRightMenu(props);

  return (
    <Tooltip popup="展开菜单">
      <button
        {...bindDataTestId(TestIds.asideItemMore)}
        className="items-center justify-center hidden rounded-sm animate-hover group-hover:flex w-5 h-5"
        onClick={openRightMenu}
      >
        <Icon name="IcMoreMiddleBold" className="text-grey3" size="small" />
      </button>
    </Tooltip>
  );
};

const ChangeTitle: FC<{ uuid: string; close(): void }> = ({ uuid, close }) => {
  const block = usePickBlock(uuid, ['data'], ['segments']);
  const transaction = useTransaction();

  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.code === 'Enter') {
        close();
      }
    };
    window.addEventListener('keydown', handleKeydown);
    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [close]);

  if (!block) return null;

  return (
    <div className="next-modal flex items-center p-1 rounded-t" style={{ width: '365px' }}>
      <Input
        autoFocus
        showBorder={false}
        defaultValue={segmentsToText(block.data.segments)}
        onEnter={close}
        onChange={(title) => {
          transaction(() => {
            updateBlock(uuid, { data: { segments: textToSegments(title) } });
          });
        }}
        placeholder={getUntitledName(block.type)}
        className="w-full h-8 bg-grey8"
      />
    </div>
  );
};
