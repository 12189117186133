import { Compare } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import type { SnapshotDTO, SnapshotRecordDTO } from '@next-space/fe-api-idl';
import { configureStore } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { Provider as ReduxProvider } from 'react-redux';
import { combineReducers } from 'redux';
import { Button } from 'src/common/components/button';
import { Icon } from 'src/common/components/icon';
import { LoadingContainer } from 'src/common/components/loading-container';
import { useCloseModal, useOpenModal } from 'src/common/components/next-modal';
import { UUID_NAMESPACE } from 'src/common/const';
import { request } from 'src/common/request';
import type { PreviewPageContent } from 'src/components/preview-page-content';
import { useRestoreToSnapshot } from 'src/hooks/page/use-restore-to-snapshot';
import { useCurrentSpace } from 'src/hooks/space';
import { useIsFreeSpaceLimit } from 'src/hooks/space/use-is-pro-space';
import { getUserName } from 'src/hooks/user/use-remark-name';
import { Modals } from 'src/modals';
import * as reducers from 'src/redux/reducers';
import { getState } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { bizTracker } from 'src/utils/biz-tracker';
import {
  useAsyncComputation,
  useFinalValue,
  useScheduleUpdate,
} from '@flowus/common/hooks/react-utils';
import { PageScene } from '../scene-context';
import { OpenSettingFrom, SettingMenuType } from '../setting-modal/type';
import { useOpenSettingModal, useOpenUpgradeSpace } from '../setting-modal/use-open-setting-modal';
import { PRODUCT_NAME } from 'src/const/title';

const PER_PAGE = 20;
const CN_NUM_MAP = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二'];

let PreviewPageContent0: typeof PreviewPageContent;

export const antiCycleSet_PreviewPageContent = (component: typeof PreviewPageContent) => {
  PreviewPageContent0 = component;
};
// TODO: 缓存请求结果

export const PageHistoryModal: FC<{
  docId: string;
  initialSelectedSnapshotId?: string;
}> = ({ docId, initialSelectedSnapshotId }) => {
  const openModal = useOpenModal();
  const space = useCurrentSpace();
  const isFreeSpaceLimit = useIsFreeSpaceLimit();
  const openSettingModal = useOpenSettingModal();
  const openUpgradeSpace = useOpenUpgradeSpace();
  const closeModal = useCloseModal();
  const scheduleUpdate = useScheduleUpdate();
  const state = useFinalValue(() => ({
    nextPage: 1,
    selected: initialSelectedSnapshotId,
    history: [] as SnapshotDTO[],
    initialLoaded: false,
    loading: false,
    more: false,
  }));
  const restoreToSnapshot = useRestoreToSnapshot();

  const loadMore = useCallback(async () => {
    /* eslint-disable require-atomic-updates */
    if (state.loading) return;
    state.loading = true;
    scheduleUpdate();
    const page = state.nextPage;
    const result = await request.editor.getDocHistories(docId, page, PER_PAGE);
    if (result.results) {
      state.nextPage = page + 1;
      if (page === 1 && initialSelectedSnapshotId == null) {
        state.history = [];
        state.selected = result.results[0]?.uuid;
      }
      state.history.push(...result.results);
    }
    state.initialLoaded = true;
    state.loading = false;
    state.more = result.more ?? false;
    scheduleUpdate();
    if (
      state.selected != null &&
      state.history.findIndex((it) => it.uuid === state.selected) === -1 &&
      state.more
    ) {
      // 继续加载，直到包括选中版本
      setTimeout(() => loadMore());
    }
  }, [docId, initialSelectedSnapshotId, scheduleUpdate, state]);

  useEffect(() => {
    state.nextPage = 1;
    void loadMore();
  }, [loadMore, state]);

  const [sentryRef] = useInfiniteScroll({
    loading: state.loading,
    hasNextPage: state.more,
    onLoadMore: loadMore,
  });

  const computeGroups = (history: SnapshotDTO[]) => {
    const today = [];
    const yesterday = [];
    const week = [];
    const months = new Map<number, SnapshotDTO[]>();
    const now = dayjs();
    for (const snapshot of history) {
      const time = dayjs(snapshot.createdAt);
      if (time.isSame(now, 'day')) {
        today.push(snapshot);
      } else if (time.isSame(now.subtract(1, 'day'), 'day')) {
        yesterday.push(snapshot);
      } else if (time.isSame(now, 'week')) {
        week.push(snapshot);
      } else {
        const month = time.startOf('month').toDate().getTime();
        if (!months.has(month)) {
          months.set(month, []);
        }
        months.get(month)?.push(snapshot);
      }
    }
    const groups: { label: string; snapshots: SnapshotDTO[] }[] = [];
    if (today.length >= 1) {
      groups.push({ label: '今天', snapshots: today });
    }
    if (yesterday.length >= 1) {
      groups.push({ label: '昨天', snapshots: yesterday });
    }
    if (week.length >= 1) {
      groups.push({ label: '本周', snapshots: week });
    }
    groups.push(
      ...[...months.entries()]
        .sort(Compare.reversed(Compare.by((it) => it[0])))
        .map(([key, snapshots]) => {
          const time = dayjs(key);
          const label = time.isSame(now, 'month')
            ? '本月'
            : time.isSame(now, 'year')
            ? `${CN_NUM_MAP[time.month()]}月`
            : time.format('YYYY年M月');
          return { label, snapshots };
        })
    );
    return groups;
  };

  const groups = computeGroups(state.history);

  if (!state.initialLoaded) {
    return (
      <div className="w-[80vw] h-[80vh]">
        <LoadingContainer />
      </div>
    );
  }

  return (
    <div className="w-[80vw] h-[80vh] rounded-md overflow-hidden">
      <div className="flex h-full">
        {isFreeSpaceLimit ? (
          <div className="flex-1 h-full min-w-0 flex items-center justify-center">
            <div className="text-center">
              <div>
                <Icon size="normal" name={'IcMenuPageHistory'} className="!w-12 !h-12 text-grey4" />
              </div>
              <h3 className="text-t1-bold">升级空间，即刻查看和恢复页面历史</h3>
              <div className="flex justify-center mt-2">
                <Button
                  colorType="active"
                  onClick={() => {
                    if (space.planType === 'freeTeam') {
                      openUpgradeSpace(OpenSettingFrom.pageHistory);
                    } else {
                      openSettingModal({
                        defaultMenuType: SettingMenuType.upgrade,
                        from: OpenSettingFrom.pageHistory,
                      });
                    }
                  }}
                >
                  升级空间
                </Button>
              </div>
            </div>
          </div>
        ) : groups.length <= 0 ? (
          <div className="w-[80vw] h-[80vh] flex items-center justify-center">
            <div className="text-center">
              <div>
                <Icon size="normal" name={'IcMenuPageHistory'} className="!w-12 !h-12 text-grey4" />
              </div>
              <h3 className="text-t1-bold">尚未生成历史页面</h3>
              <p className="text-t2 mt-2">
                继续编辑，{PRODUCT_NAME}会帮助你生成页面历史，
                <br />
                然后你可以选择将页面内容恢复成某个历史时间的内容。
              </p>
            </div>
          </div>
        ) : (
          <div
            className="flex-1 h-full flex min-w-0 bg-white1"
            onContextMenu={(event) => {
              event.stopPropagation();
            }}
          >
            {state.selected && (
              <SnapshotPreviewLoader
                key={state.selected}
                docId={docId}
                snapshotId={state.selected}
              />
            )}
          </div>
        )}
        <div
          className={cx(
            'w-[240px] h-full border-l-black_006 border-l flex flex-col',
            groups.length <= 0 && 'hidden'
          )}
        >
          <div className="flex-1 py-2 overflow-y-auto">
            {groups.map((it) => {
              return (
                <div key={it.label} className="mt-2">
                  <div className="text-t2 text-grey3 px-3 mb-1">{it.label}</div>
                  <div className="flex flex-col">
                    {it.snapshots.map((it) => {
                      return (
                        <SnapshotItem
                          className="px-3 py-1 my-0.5"
                          key={it.uuid}
                          snapshot={it}
                          selected={it.uuid != null && it.uuid === state.selected}
                          onClick={() => {
                            state.selected = it.uuid;
                            scheduleUpdate();
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
            {(state.loading || state.more) && (
              <div className="h-10 overflow-hidden" ref={sentryRef}>
                <LoadingContainer />
              </div>
            )}
          </div>
          <div className="h-[60px] border-t-black_006 border-t flex items-center justify-evenly">
            <Button
              colorType="active"
              className={cx('w-[132px]')}
              disable={isFreeSpaceLimit || !state.selected}
              onClick={() => {
                const snapshot = state.history.find((it) => it.uuid === state.selected);
                const now = dayjs();
                const time = dayjs(snapshot?.createdAt);
                const label = time.isSame(now, 'year')
                  ? time.format('M月D日 HH:mm')
                  : time.format('YYYY年M月D日 HH:mm');
                openModal.warning({
                  title: <div className="w-80">确认恢复页面？</div>,
                  content: <p>页面将恢复为 {label} 的版本</p>,
                  confirm() {
                    closeModal(Modals.DOC_HISTORY_MODAL_ID);
                    if (state.selected) {
                      bizTracker.event('page_history');
                      void restoreToSnapshot(docId, state.selected);
                    }
                  },
                });
              }}
            >
              恢复此版本
            </Button>
            <Button
              className="w-[60px]"
              onClick={() => {
                closeModal(Modals.DOC_HISTORY_MODAL_ID);
              }}
            >
              取消
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const SnapshotItem: FC<{
  snapshot: SnapshotDTO;
  selected: boolean;
  className?: string;
  onClick?: () => void;
}> = ({ snapshot, selected, className, onClick }) => {
  const now = dayjs();
  const time = dayjs(snapshot.createdAt);
  const label = time.isSame(now, 'year')
    ? time.format('M月D日 HH:mm')
    : time.format('YYYY年M月D日 HH:mm');
  return (
    <div
      className={cx(
        'animate-hover',
        {
          'bg-grey9': selected,
        },
        className
      )}
      onClick={onClick}
    >
      <div className="text-t2">{label}</div>
      <div className="text-t4 text-grey3">
        {snapshot.userList?.map((it) => (
          <span key={it.uuid}>{getUserName(it.uuid)}</span>
        ))}
      </div>
    </div>
  );
};

const SnapshotPreviewLoader: FC<{ docId: string; snapshotId: string }> = ({
  docId,
  snapshotId,
}) => {
  const result = useAsyncComputation(async () => {
    return request.editor.getDocHistoryDetail(docId, snapshotId);
  }, [docId, snapshotId]);
  if (result[0] === 'pending') {
    return <LoadingContainer />;
  }
  return <SnapshotPreview docId={docId} snapshot={result[1]} />;
};

export const SnapshotPreview: FC<{
  docId: string;
  snapshot: SnapshotRecordDTO;
}> = ({ docId, snapshot }) => {
  const store = useMemo(() => {
    return createPreviewStore(snapshot);
  }, [snapshot]);
  return (
    <ReduxProvider store={store}>
      <PreviewPageContent0
        docId={docId}
        pageScene={PageScene.PAGE_HISTORY}
        nameSpace={UUID_NAMESPACE.PAGE_HISTORY}
      />
    </ReduxProvider>
  );
};

const createPreviewStore = (info: SnapshotRecordDTO) => {
  const reducer = combineReducers(reducers);
  const { users } = getState();
  const store = configureStore({
    reducer,
    preloadedState: {
      users,
      blocks: info.blocks as Record<string, NextBlock>,
      collectionViews: info.collectionViews,
      discussions: (info as any).discussions,
      comments: (info as any).comments,
    },
  });
  return store;
};
