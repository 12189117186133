import type { FC } from 'react';
import { SpacePlanType } from 'src/redux/types';

import type { SelectUpgradePlanProps } from './common';
import { UpgradePersonalPlan } from './upgrade-personal-plan';
import { UpgradeTeamPlan } from './upgrade-team-plan';

/** 购买space套餐弹窗 */
export const SelectUpgradePlan: FC<SelectUpgradePlanProps> = (props) => {
  const { selectPlanSpaceType } = props;
  return selectPlanSpaceType === SpacePlanType.personal ? (
    <UpgradePersonalPlan {...props} />
  ) : (
    <UpgradeTeamPlan {...props} />
  );
};
