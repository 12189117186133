import { cx } from '@flowus/common/cx';
import { CollectionViewType, CoverType } from '@next-space/fe-api-idl';
import type { FC, MouseEvent } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { useBitable } from 'src/bitable/context';
import { ILLEGAL_TEXT } from 'src/common/const';
import { BlockDefaultIcon } from 'src/components/block-default-icon';
import { IconTrigger } from 'src/components/icon-trigger';
import { BlockDrop } from 'src/editor/editor/plugin/dnd/block-drop';
import { useCoverSetting } from 'src/hooks/collection-view/use-collection-view';
import { useOpenPage } from 'src/hooks/page/use-open-page';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { usePickBlock } from 'src/utils/pick-block';
import { CardCover } from './card-cover';
import { CardMenu } from './card-menu';
import { CardTitle } from './card-title';
import { PropertyValues } from './property-values';

interface Props {
  recordId: string;
  groupValue?: string;
  subGroupValue?: string;
}

export const GroupCard: FC<Props> = React.memo((props) => {
  const { recordId, groupValue, subGroupValue } = props;
  const { viewId, viewType } = useBitable();
  const block = usePickBlock(recordId, []);
  const { illegal } = usePermissions(recordId);
  const openPage = useOpenPage();
  const blockRef = useRef<HTMLDivElement>(null);
  const { previewType } = useCoverSetting(viewId);
  const noPreview = previewType === CoverType.NONE;

  const isGallery = viewType === CollectionViewType.GALLERY;

  if (!block) return null;

  const openRecordPage = (event: MouseEvent) => {
    const linkNode = (event.target as HTMLElement).closest('a');
    if (linkNode && event.currentTarget.contains(linkNode)) {
      return;
    }

    const onOpenInRight = () => {
      if (isGallery) {
        const container = blockRef.current?.closest('.block-list') as HTMLElement | null;
        if (container) {
          container.style.width = `${container?.clientWidth}px`;
          setTimeout(() => {
            container.style.width = 'unset';
          }, 1000);
        }
      }
    };

    openPage(recordId, {
      illegal,
      onOpenInRight,
      forceOpenInRight: event.altKey,
      forceOpenNewTab: event.ctrlKey || event.metaKey,
    });
  };

  return (
    <BlockDrop
      id={recordId}
      viewId={viewId}
      blockRef={blockRef}
      hideHoverMenu
      data-draggable
      horizontal={isGallery}
      groupValue={groupValue}
      subGroupValue={subGroupValue}
      data-only-horizontal={isGallery}
      onClick={openRecordPage}
      data-no-cancel-selected
      className={cx(
        'sub-group-card relative w-full list-none next-modal shadow-modal-sm cursor-pointer animate-hover overflow-auto',
        illegal && 'opacity-30',
        isGallery && 'h-full',
        !noPreview ? 'min-h-[48px]' : 'min-h-[38px]'
      )}
      style={{
        scrollbarWidth: 'none',
      }}
    >
      {illegal ? (
        <div className="text-t2-medium whitespace-pre-wrap break-words px-2 py-2">
          <IconTrigger
            className="mr-1 inline p-0 align-middle"
            blockId={recordId}
            trigger={false}
            iconSize={20}
            defaultIcon={<BlockDefaultIcon uuid={recordId} size="middle" />}
          />
          <span className="border-b border-grey6">{ILLEGAL_TEXT}</span>
        </div>
      ) : (
        <>
          {!noPreview && <CardCover recordId={recordId} />}
          <CardTitle recordId={recordId} />
          <PropertyValues recordId={recordId} />
          <CardMenu recordId={recordId} />
        </>
      )}
    </BlockDrop>
  );
});

export const GalleryCard: FC<Props> = (props) => {
  const placeholderRef = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const placeholder = placeholderRef.current;
    if (!placeholder) return;

    placeholder.style.height = `${placeholder.previousElementSibling?.clientHeight ?? 200}px`;
    placeholder.style.borderWidth = '1px';

    const callback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        setVisible(() => {
          if (entry.isIntersecting) {
            placeholder.style.height = 'unset';
            placeholder.style.borderWidth = '0px';
            return true;
          }

          placeholder.style.height = `${placeholder.offsetHeight}px`;
          placeholder.style.borderWidth = '1px';

          return false;
        });
      });
    };

    const observer: IntersectionObserver = new IntersectionObserver(callback, {
      rootMargin: '600px 0px',
    });

    observer.observe(placeholder);
    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={placeholderRef} className="rounded cursor-pointer" data-card-id={props.recordId}>
      {visible && <GroupCard {...props} />}
    </div>
  );
};
