import { Switch } from '@flowus/common/components/switch';
import { cx } from '@flowus/common/cx';
import { useOpenModal } from '@flowus/common/next-modal';
import type { FC } from 'react';
import { useMemo, useState } from 'react';
import { Button } from 'src/common/components/button';
import { Divider } from 'src/common/components/divider';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { ListView } from 'src/common/components/list-view';
import { ListItemType } from 'src/common/components/list-view/types';
import { publicLink } from 'src/common/const';
import { usePublicShare } from 'src/hooks/page/use-public-share';
import { transaction } from 'src/hooks/use-transaction';
import { bizTracker } from 'src/utils/biz-tracker';
import { usePickBlock } from 'src/utils/pick-block';
import { HelperIcon } from '../helper-icon';
import { RebatePlan } from '../rebate-plan';
import { useOpenPreviewPage } from './open-preview-page';
import type { ShareProps } from './types';

const MAX_AMOUNT = 10000;
/**
 * 免费试读：分为不可试读、30个块、50个块、100个块，默认不可试读。当选择可试读后，下方增加【预览】按钮
 */
const previewBlockMap = new Map<number, string>();
previewBlockMap.set(0, '不可试读');
previewBlockMap.set(10, '10个块');
previewBlockMap.set(30, '30个块');
previewBlockMap.set(50, '50个块');
const previewBlockKeys = [0, 10, 30, 50];

// 付费周期：约等于 永久有效、12个月、6个月、3个月、1个月，默认【永久有效】
// 由于每个月份天数不同，用天表示
const validDayKeys = [0, 365, 180, 90, 30];
const validDayMap = new Map<number, string>();
validDayKeys.forEach((v) => {
  validDayMap.set(v, `${v}天`);
});
validDayMap.set(0, '永久有效');

/** 付费订阅设置页面 */
export const SharePay: FC<Pick<ShareProps, 'uuid' | 'backToMainPanel'>> = (props) => {
  const { uuid, backToMainPanel } = props;
  const { set, permissionsState, roleStatus, openParentRoleLimit } = usePublicShare(uuid);
  const {
    allowSubscribe,
    accessFee = 0,
    openPreview = false,
    previewBlockCount = 0,
    accessFeeValidDays = 0,
    originPrice: _originPrice = 0,
  } = permissionsState;
  const [sharePageDistributionRatio, setSharePageDistributionRatio] = useState(
    permissionsState.sharePageDistributionRatio
  );
  const block = usePickBlock(uuid, []);
  const [money, setMoney] = useState<number>(accessFee);
  const [noPay, setCheck] = useState(!money);
  const openModal = useOpenModal();
  const [previewCount, setPreviewCount] = useState(
    openPreview === true && previewBlockCount === 0 ? 10 : previewBlockCount
  );
  const [validDays, setValidDays] = useState(accessFeeValidDays);
  const [originPrice, setOriginPrice] = useState(_originPrice === 0 ? undefined : _originPrice);

  const openPreviewPage = useOpenPreviewPage();

  const enableSave = useMemo(() => {
    if (noPay) {
      return true;
    }
    if (!money) {
      return false;
    }
    if (originPrice) {
      if (Number(money) >= Number(originPrice)) {
        return false;
      }
    }
    return true;
  }, [money, noPay, originPrice]);

  const save = async () => {
    if (roleStatus.isRestricted) return;
    // money有可能是string但后端需要传number
    const _money = Number(money);
    const _save = () => {
      // 埋点
      if (noPay) {
        bizTracker.event('share_pay_cancel');
      } else {
        if (accessFee !== _money) {
          bizTracker.event('share_pay_open');
        }
      }
      transaction(() => {
        void set().setPartialPermission({
          allowSubscribe: _money ? true : allowSubscribe,
          allowSeo: false,
          accessFee: noPay ? 0 : (_money <= 0 ? 0 : _money) || 0,
          openPreview: noPay ? false : previewCount > 0,
          sharePageDistributionRatio: noPay ? 0 : sharePageDistributionRatio,
          previewBlockCount: previewCount,
          accessFeeValidDays: validDays,
          originPrice: originPrice ? Number(originPrice) : undefined,
        });
      });
      backToMainPanel();
    };
    // 之前设置过并且修改了金额才弹出提示
    if (accessFee && money && money !== accessFee) {
      openModal.warning({
        title: '修改付费金额后，已购买的账户仍然可以继续访问，不需要重复付费。',
        confirm() {
          _save();
        },
      });
    } else {
      _save();
    }
  };

  const openPay = (status: boolean) => {
    if (status && accessFee) {
      openModal.warning({
        title: '取消付费设置后，任何人都可以访问分享页面，确认关闭吗？',
        confirm: () => {
          setCheck(status);
        },
      });
      return;
    }
    setMoney(0);
    setCheck(status);
  };

  if (__BUILD_IN__) return null;

  const previewData = previewBlockKeys.map((count) => {
    const title = previewBlockMap.get(count);
    return {
      type: ListItemType.TEXT_DESCRIPTION,
      data: {
        title,
        selected: count === previewCount,
        onClick: () => {
          setPreviewCount(count);
          // // 由于要支持预览，因此这里设置后需要先保存到后台
          // transaction(() => {
          //   void set().setPartialPermission({
          //     previewBlockCount: count,
          //   });
          // });
        },
      },
    };
  });

  const validDayData = validDayKeys.map((count) => {
    const title = validDayMap.get(count);
    return {
      type: ListItemType.TEXT_DESCRIPTION,
      data: {
        title,
        selected: count === validDays,
        onClick: () => {
          setValidDays(count);
        },
      },
    };
  });

  const formatInput = (value: number) => {
    const xiaoShu = value.toString().split('.')[1];
    if (xiaoShu && xiaoShu.length > 2) {
      value = Number(Number(value).toFixed(2));
    }

    if (value > MAX_AMOUNT) {
      value = MAX_AMOUNT;
    }
    return value;
  };

  return (
    <div className="select-none">
      <div className="p-4 flex items-center text-t2">
        <Icon name="IcArrowBack" className={'mr-2'} size="middle" onClick={backToMainPanel} />
        付费订阅
        <HelperIcon
          placement="bottom"
          popup={
            <div className="space-y-2">
              <div>
                付费订阅适用于知识付费，您可以对您创作的内容进行收费，通过分享链接或者入驻知识库分享给您的读者。开启了付费订阅的页面，读者通过页面链接无法直接查看，需要支付订阅费后才能浏览页面内容
              </div>
              <a
                href={publicLink.subscribeCenter}
                target="_blank"
                className="text-active_color ml-auto"
              >
                查看知识库
              </a>
            </div>
          }
        />
      </div>
      {roleStatus.isRestricted && (
        <div
          className="text-t4 bg-grey6 px-4 py-2 animate-click flex justify-between"
          onClick={openParentRoleLimit}
        >
          <span>已继承上级设置，不可单独修改</span>
          <span className="text-active_color mx-1">解除权限继承</span>
        </div>
      )}
      <div className={cx('text-t2', roleStatus.isRestricted && 'pointer-events-none opacity-30')}>
        <div
          className="flex px-4 items-center justify-between animate-click py-2.5 space-x-1"
          onClick={() => openPay(!noPay)}
        >
          <span>付费订阅</span>
          <Switch bgClassName="pointer-events-none" open={!noPay} />
        </div>

        {noPay && <Divider className="my-1" />}
        {!noPay && (
          <>
            <div className="px-4">
              {[
                {
                  autoFocus: true,
                  title: '付费金额',
                  readonly: roleStatus.isRestricted,
                  value: (money || '').toString(),
                  placeholder: '请输入付费金额，必须大于 0 ',
                  onChange: (value: number) => {
                    value = formatInput(value);
                    setMoney(value);
                    if (value && noPay) {
                      setCheck(false);
                    }
                  },
                },
                {
                  title: (
                    <div>
                      划线金额
                      <HelperIcon
                        placement="bottom"
                        popup="展示划线价可以让专栏价格看起来更优惠。设置后会展示在付款页面，划线价仅作为价格对比使用，不作为最终成交价格。请填写真实有效的价格，不得虚构或夸大，以免误导消费者，具体规定参考《价格法》"
                      />
                    </div>
                  ),
                  readonly: roleStatus.isRestricted,
                  error: money && originPrice ? Number(money) >= Number(originPrice) : false,
                  value: (originPrice || '').toString(),
                  placeholder: '必须高于付费金额',
                  onChange: (value: number) => {
                    value = formatInput(value);
                    setOriginPrice(value);
                  },
                },
              ].map((item) => (
                <div className="flex items-center py-2.5 justify-between" key={item.placeholder}>
                  <div className="text-t3 shrink-0">{item.title}</div>
                  <Input
                    autoFocus={item.autoFocus}
                    type="number"
                    inputType="number"
                    disableFloat={false}
                    readonly={item.readonly}
                    value={item.value}
                    onChange={item.onChange}
                    maxLength={8}
                    className={'h-8 border-none w-4/5 bg-transparent flex-1'}
                    inputClassName={cx('text-right px-1', item.error && 'text-red')}
                    // inputClassName={cx('text-right px-1')}
                    showBorder={false}
                    placeholder={item.placeholder}
                    addonAfter={'元'}
                  />
                </div>
              ))}
              <div
                className="animate-click flex items-center justify-between py-2.5"
                onClick={(e) => {
                  openModal.dropdown({
                    popcorn: e.currentTarget,
                    content: ({ onCloseModal }) => {
                      return (
                        <ListView
                          className="py-1 next-modal w-[300px]"
                          items={validDayData}
                          onItemClick={() => {
                            onCloseModal();
                          }}
                        />
                      );
                    },
                  });
                }}
              >
                <div className="text-t3">付费周期</div>
                <div className="space-x-1 text-grey3">
                  <span>{computePeriod(validDays)}</span>
                  <Icon size="xxsmall" name="IcArrow" />
                </div>
              </div>
            </div>
            <Divider className="my-1" />
            <div className="px-4">
              <div
                className="animate-click flex items-center justify-between py-2.5"
                onClick={(e) => {
                  openModal.dropdown({
                    popcorn: e.currentTarget,
                    content: ({ onCloseModal }) => {
                      return (
                        <ListView
                          className="py-1 next-modal w-[300px]"
                          items={previewData}
                          onItemClick={() => {
                            onCloseModal();
                          }}
                        />
                      );
                    },
                  });
                }}
              >
                <div className="text-t3">免费试读</div>
                <div className="space-x-1 text-grey3">
                  <span>{previewBlockMap.get(previewCount)}</span>
                  <Icon size="xxsmall" name="IcArrow" />
                </div>
              </div>
              <RebatePlan
                className="flex items-center justify-between py-2.5"
                setSharePageDistributionRatio={setSharePageDistributionRatio}
                sharePageDistributionRatio={sharePageDistributionRatio}
              >
                <div className="text-t3">分佣比例</div>
              </RebatePlan>
            </div>
            <Divider className="my-1" />
            <div className="text-grey3 py-1 text-t3 px-4">
              <span>开启付费分享，即表示你同意并确保遵守</span>
              <a href={publicLink.creator} className="text-active_color" target="_blank">
                《创作者合作协议》
              </a>
            </div>
          </>
        )}

        <div className="flex items-center justify-between py-2.5 px-4">
          <div>
            {previewCount > 0 && !noPay && (
              <Button
                colorType="primary"
                onClick={() =>
                  openPreviewPage({
                    accessFee,
                    spaceId: block?.spaceId ?? '',
                    uuid,
                    previewCount,
                  })
                }
              >
                预览
              </Button>
            )}
          </div>
          <div className="flex space-x-2.5">
            <Button
              colorType={'secondary'}
              onClick={() => {
                backToMainPanel();
              }}
            >
              取消
            </Button>
            <Button colorType={'active'} onClick={save} disable={!enableSave}>
              保存
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const computePeriod = (value: number | undefined) => {
  if (value === undefined || value === 0) {
    return '永久有效';
  }
  return `${value}天`;
};
