import { BlockType, CollectionViewType, PermissionRole } from '@next-space/fe-api-idl';
import type { FC, MouseEvent } from 'react';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';
import { message } from 'src/common/components/message';
import { useCloseModal, useOpenModal } from 'src/common/components/next-modal';
import { HelperIconBox, helperLink } from 'src/components/helper-icon';
import type { SearchItem } from 'src/components/search';
import { SearchMenu } from 'src/components/search';
import { covertNextBlockToSearchItem } from 'src/components/search/covert-next-block-to-search-item';
import { useAddCollectionView } from 'src/hooks/block/use-add-collection-view';
import { useCopyBlock } from 'src/hooks/block/use-copy-block';
import { getOwnerPage } from 'src/hooks/block/use-get-owner-page';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import { useNormalizePath } from 'src/hooks/page';
import { useCanMove } from 'src/hooks/share/use-permission-utils';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useTransaction } from 'src/hooks/use-transaction';
import { Modals } from 'src/modals';
import { moveBlock } from 'src/redux/managers/block/move';
import { removeBlock } from 'src/redux/managers/block/remove';
import { updateBlock } from 'src/redux/managers/block/update';
import { cache } from 'src/redux/store';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { $currentUserCache } from 'src/services/user/current-user';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { getLocationOrigin } from 'src/utils/location-utils';
import { useBitable } from '../context';
import { useOpenPreViewForm } from '../form-view/share-form-view';
import { useOpenShareFormSetting } from '../form-view/use-open-share-form-setting';
import { ConfigTableFilter } from './config-table-filter';
import { ConfigTableSort } from './config-table-sort';
import { GroupList } from './group-list';
import { NoDateRecords, useNoDateRecord } from './no-date-record';
import { PropertyList } from './property-list';

export const PageOptionsMenu: FC = () => {
  const { viewId, viewParentId, isLocked } = useBitable();
  const openModal = useOpenModal();
  const transaction = useTransaction();

  const viewType = useObservableStore(
    ({ collectionViews }) => collectionViews[viewId]?.type,
    [viewId]
  );

  /** 收集表相关设置 */
  const formOptions = useFormOptions();

  const items: ListItem[] = [
    ...formOptions,
    {
      type: ListItemType.OPERATION,
      data: {
        icon: isLocked ? 'IcMenuUnlock' : 'IcMenuLock',
        title: isLocked ? '解锁多维表' : '锁定多维表',
        tip: '锁定时，无法新增/修改多维表的配置、视图和属性，但可以新增/编辑记录。',
        onClick: () => {
          transaction(() => {
            updateBlock(viewParentId, {
              data: {
                format: {
                  locked: !isLocked,
                  lockedBy: $currentUserCache.uuid,
                },
              },
            });
          });
        },
      },
    },
    {
      type: ListItemType.LINE,
    },
    {
      type: ListItemType.OPERATION,
      data: {
        title: '属性',
        icon: 'IcProperties',
        onClick(event: MouseEvent) {
          openModal.dropdown({
            popcorn: event.currentTarget,
            placement: 'left',
            content() {
              return <PropertyList />;
            },
          });
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: viewType === CollectionViewType.FORM,
      data: {
        title: '筛选',
        icon: 'IcFilter',
        onClick(event: MouseEvent) {
          openModal.dropdown({
            popcorn: event.currentTarget,
            placement: 'left',
            content() {
              return <ConfigTableFilter />;
            },
          });
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: viewType === CollectionViewType.FORM,
      data: {
        title: '排序',
        icon: 'IcSort',
        onClick(event: MouseEvent) {
          openModal.dropdown({
            popcorn: event.currentTarget,
            placement: 'left',
            content() {
              return <ConfigTableSort />;
            },
          });
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: viewType === CollectionViewType.CALENDAR || viewType === CollectionViewType.FORM,
      data: {
        title: '分组',
        icon: 'IcGroup',
        onClick(event: MouseEvent) {
          openModal.dropdown({
            popcorn: event.currentTarget,
            placement: 'left',
            content() {
              return <GroupList />;
            },
          });
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: viewType !== CollectionViewType.BOARD,
      data: {
        title: '子分组',
        icon: 'IcSubGroup',
        onClick(event: MouseEvent) {
          openModal.dropdown({
            popcorn: event.currentTarget,
            placement: 'left',
            content() {
              return <GroupList isSubGroup={true} />;
            },
          });
        },
      },
    },
    {
      type: ListItemType.LINE,
      isHidden: viewType === CollectionViewType.FORM,
    },
    {
      type: ListItemType.OPERATION,
      isHidden: viewType === CollectionViewType.FORM,
      data: {
        title: '复制访问链接',
        icon: 'IcUrl',
        onClick() {
          void writeTextInClipboard(`${getLocationOrigin()}/${viewParentId}#${viewId}`);
        },
      },
    },
  ];

  return (
    <div className="w-[200px] py-2 next-modal">
      <ListView
        items={items}
        customFooter={
          <HelperIconBox
            beforeIcon
            linkContainer
            link={helperLink.bitableLink}
            className="text-t4 text-grey3 items-center h-10 mx-4 mt-1 border-t border-grey6 w-full"
          >
            了解如何使用多维表
          </HelperIconBox>
        }
      />
    </div>
  );
};

export const EmbedOptionMenu: FC = () => {
  const { viewId, viewParentId, isLocked, tableCellWrap } = useBitable();
  const history = useHistory();
  const openModal = useOpenModal();
  const transaction = useTransaction();
  const noDateRecord = useNoDateRecord();
  const path = useNormalizePath(viewParentId);

  const copyBlock = useCopyBlock();
  const viewType = useObservableStore(
    ({ collectionViews }) => collectionViews[viewId]?.type,
    [viewId]
  );
  const { role: parentRole } = usePermissions(cache.blocks[viewParentId]?.parentId ?? '');
  const canMove = useCanMove(viewParentId);
  const formOptions = useFormOptions();
  const closeModal = useCloseModal();

  const openMoveTo = (event: MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'left',
      content({ onCloseModal }) {
        const { blocks } = cache;
        const viewParent = blocks[viewParentId];
        const pageId = getOwnerPage(viewParentId);
        if (!viewParent) return null;

        const defaultItems = (blocks[viewParent.spaceId]?.subNodes ?? [])
          .map((id) => {
            if (id === pageId || id === viewParentId) return undefined;
            const itemBlock = blocks[id];
            if (!itemBlock) return undefined;

            if (
              itemBlock.type !== BlockType.PAGE &&
              itemBlock.type !== BlockType.COLLECTION_VIEW_PAGE &&
              itemBlock.type !== BlockType.COLLECTION_VIEW
            ) {
              return undefined;
            }
            return covertNextBlockToSearchItem(itemBlock);
          })
          .filter((item): item is SearchItem => !!item);

        return (
          <SearchMenu
            onSelect={async (target) => {
              onCloseModal();
              transaction(() =>
                moveBlock([viewParentId], {
                  parentId: target.uuid,
                  last: true,
                })
              );
            }}
            defaultValue={defaultItems}
            searchType="page"
            source="move"
          />
        );
      },
    });
  };

  const items: ListItem[] = [
    {
      type: ListItemType.OPERATION,
      isHidden: parentRole === PermissionRole.READER,
      data: {
        title: '删除',
        icon: 'IcMenuDelete',
        onClick() {
          transaction(() => {
            removeBlock(viewParentId);
          });
          closeModal(Modals.BITABLE_OPTION_MENU);
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: viewType === CollectionViewType.FORM,
      data: {
        title: '复制访问链接',
        icon: 'IcUrl',
        onClick: () => {
          void writeTextInClipboard(`${getLocationOrigin()}/${viewParentId}#${viewId}`);
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: parentRole === PermissionRole.READER,
      data: {
        title: '拷贝副本',
        icon: 'IcMenuDuplicate',
        onClick: () => {
          void copyBlock([viewParentId]);
          closeModal(Modals.BITABLE_OPTION_MENU);
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: !canMove,
      data: {
        title: '移动到',
        icon: 'IcMenuMove',
        onClick: openMoveTo,
      },
    },
    {
      type: ListItemType.OPERATION,
      data: {
        title: '以页面打开',
        icon: 'IcMenuOpen',
        onClick: () => {
          history.push(path);
          closeModal(Modals.BITABLE_OPTION_MENU);
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      data: {
        icon: isLocked ? 'IcMenuUnlock' : 'IcMenuLock',
        title: isLocked ? '解锁多维表' : '锁定多维表',
        tip: '锁定时，无法新增/修改多维表的配置、视图和属性，但可以新增/编辑记录。',
        onClick: () => {
          transaction(() => {
            updateBlock(viewParentId, {
              data: {
                format: {
                  locked: !isLocked,
                  lockedBy: $currentUserCache.uuid,
                },
              },
            });
          });
        },
      },
    },
    {
      type: ListItemType.LINE,
    },
    {
      type: ListItemType.OPERATION,
      isHidden: parentRole === PermissionRole.READER,
      data: {
        title: '转换为页面',
        icon: 'IcMenuTurnInto',
        onClick: () => {
          transaction(() => {
            const { blocks } = cache;
            const viewParent = blocks[viewParentId];
            updateBlock(viewParentId, {
              type:
                viewParent?.type === BlockType.COLLECTION_VIEW
                  ? BlockType.COLLECTION_VIEW_PAGE
                  : BlockType.REFERENCE_COLLECTION_PAGE,
            });
            closeModal(Modals.BITABLE_OPTION_MENU);
          });
        },
      },
    },
    {
      type: ListItemType.LINE,
    },
    ...formOptions,
    {
      type: ListItemType.OPERATION,
      data: {
        title: '属性',
        icon: 'IcProperties',
        onClick(event: MouseEvent) {
          openModal.dropdown({
            popcorn: event.currentTarget,
            placement: 'left',
            content() {
              return <PropertyList />;
            },
          });
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: viewType && [CollectionViewType.FORM].includes(viewType),
      data: {
        title: '筛选',
        icon: 'IcFilter',
        onClick(event: MouseEvent) {
          openModal.dropdown({
            popcorn: event.currentTarget,
            placement: 'left',
            content() {
              return <ConfigTableFilter />;
            },
          });
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: viewType && [CollectionViewType.FORM].includes(viewType),
      data: {
        title: '排序',
        icon: 'IcSort',
        onClick(event: MouseEvent) {
          openModal.dropdown({
            popcorn: event.currentTarget,
            placement: 'left',
            content() {
              return <ConfigTableSort />;
            },
          });
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: noDateRecord?.length === 0 || viewType !== CollectionViewType.CALENDAR,
      data: {
        title: `无时间记录(${noDateRecord?.length ?? 0})`,
        icon: 'IcTitleNodate',
        onClick(event: MouseEvent) {
          openModal.dropdown({
            popcorn: event.currentTarget,
            placement: 'left',
            content: ({ onCloseModal, popper }) => (
              <NoDateRecords closeModal={onCloseModal} popper={popper} />
            ),
          });
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden:
        viewType && [CollectionViewType.CALENDAR, CollectionViewType.FORM].includes(viewType),
      data: {
        title: '分组',
        icon: 'IcGroup',
        onClick: (event: MouseEvent) => {
          openModal.dropdown({
            popcorn: event.currentTarget,
            placement: 'left',
            content() {
              return <GroupList />;
            },
          });
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: viewType && viewType !== CollectionViewType.BOARD,
      data: {
        title: '子分组',
        icon: 'IcSubGroup',
        onClick: (event: MouseEvent) => {
          openModal.dropdown({
            popcorn: event.currentTarget,
            placement: 'left',
            content() {
              return <GroupList isSubGroup={true} />;
            },
          });
        },
      },
    },
    {
      type: ListItemType.LINE,
      isHidden:
        !viewType ||
        ![CollectionViewType.TABLE, CollectionViewType.BOARD, CollectionViewType.GALLERY].includes(
          viewType
        ),
    },
    {
      type: ListItemType.TEXT_SWITCH,
      isHidden:
        !viewType ||
        ![CollectionViewType.TABLE, CollectionViewType.BOARD, CollectionViewType.GALLERY].includes(
          viewType
        ),
      data: {
        title: '自适应行高',
        disabled: isLocked,
        switch: tableCellWrap,
        onSwitch: (status: boolean) => {
          if (isLocked) return;

          updateViewFormat(viewId, { tableWrap: status });
        },
      },
    },
  ];

  return (
    <div className="w-[200px] py-2 next-modal-scroll">
      <ListView
        items={items}
        customFooter={
          !__BUILD_IN__ && (
            <HelperIconBox
              beforeIcon
              linkContainer
              link={helperLink.bitableLink}
              className="text-t4 text-grey3 items-center h-10 mx-4 mt-1 border-t border-grey6 w-full"
            >
              了解如何使用多维表
            </HelperIconBox>
          )
        }
      />
    </div>
  );
};

/** 收集表相关设置 */
const useFormOptions = () => {
  const { viewId, collectionId, viewType, changeView, viewParentId } = useBitable();
  const openPreViewForm = useOpenPreViewForm();
  const openShareFormSetting = useOpenShareFormSetting();
  const addCollectionView = useAddCollectionView();
  const closeModal = useCloseModal();

  /** 收集表相关设置 */
  return useMemo(() => {
    if (viewType !== CollectionViewType.FORM) return [];
    return [
      {
        type: ListItemType.OPERATION,
        data: {
          title: '预览',
          icon: 'IcMenuItemHide',
          onClick() {
            openPreViewForm({ viewId, collectionId });
            closeModal(Modals.BITABLE_OPTION_MENU);
          },
        },
      },
      {
        type: ListItemType.OPERATION,
        data: {
          title: '分享收集表',
          icon: 'IcFormShare',
          onClick(event: MouseEvent) {
            openShareFormSetting({
              popcorn: event.currentTarget,
              viewId,
              collectionId,
              placement: 'left-start',
            });
          },
        },
      },
      {
        type: ListItemType.OPERATION,
        data: {
          title: '查看收集表数据',
          icon: 'IcMenuOpenNew',
          onClick() {
            const firstTable = cache.blocks[collectionId]?.views?.find(
              (it) => cache.collectionViews[it]?.type === CollectionViewType.TABLE
            );
            if (firstTable) {
              changeView(firstTable);
            } else {
              const newTableId = addCollectionView(viewParentId, '表格', CollectionViewType.TABLE);
              if (newTableId) {
                changeView(newTableId);
              } else {
                message.error('切换失败');
              }
            }
            closeModal(Modals.BITABLE_OPTION_MENU);
          },
        },
      },
    ];
  }, [
    addCollectionView,
    changeView,
    closeModal,
    collectionId,
    openPreViewForm,
    openShareFormSetting,
    viewId,
    viewParentId,
    viewType,
  ]);
};
