import { useOpenModal } from '@flowus/common/next-modal';
import { Modals } from '@flowus/shared';
import { type FC } from 'react';
import { useDoneLocalStatus } from 'src/hooks/block/use-local-status';
import { useReadonly } from 'src/hooks/page';
import { usePickBlock } from 'src/utils/pick-block';
import { BlockEmptyView } from '../block-empty-view';
import { ChartConfigPanel } from './chart-config-panel';
import { getRecentlyUpdateCollection } from './chart-config-panel/get-recently-update-collection';

interface Props {
  id: string;
}
export const EmptyBlocker: FC<Props> = (props) => {
  const block = usePickBlock(props.id, ['data', 'local'], ['format']);
  const readonly = useReadonly(props.id);
  const openModal = useOpenModal();
  useDoneLocalStatus({ uuid: props.id, callback: getRecentlyUpdateCollection });

  if (block?.data.format?.chartFormat?.ref?.uuid) {
    return <>{props.children}</>;
  }
  return (
    <BlockEmptyView
      defaultOpenPanel={Boolean(block?.local)}
      readonly={readonly}
      onClick={() => {
        openModal.modal({
          modalId: Modals.CHART_PANEL_ID,
          autoClose: false,
          className: 'rounded-md',
          content: () => {
            return <ChartConfigPanel id={props.id} />;
          },
        });
      }}
      iconProp={{ name: 'IcChartLineSmall', size: 'normal' }}
      text={'添加数据图表'}
    />
  );
};
