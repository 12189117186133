import type { SegmentDTO } from '@next-space/fe-api-idl';
import { BlockType, TextType } from '@next-space/fe-api-idl';
import { formatUrl } from 'src/common/utils/url-utils';
import { segmentsToText } from 'src/editor/utils/editor';
import { getUserName } from 'src/hooks/user/use-remark-name';
import { supportAppendChild } from 'src/mind-map/utils/mind-engine-utils';
import { cache } from 'src/redux/store';
import { isPageLike } from 'src/utils/block-type-utils';
import { getLocationOrigin } from 'src/utils/location-utils';

type EnhancerKey = keyof SegmentDTO['enhancer'];

const mdMark = (type: EnhancerKey) => {
  switch (type) {
    case 'bold':
      return '**';
    case 'code':
      return '`';
    case 'italic':
      return '*';
    case 'lineThrough':
      return '~~';
    default:
      return '';
  }
};

export const segmentsToMarkdown = (segments: SegmentDTO[]) => {
  interface MdSegment {
    head: ReturnType<typeof mdMark>[];
    tail: ReturnType<typeof mdMark>[];
    pre: Set<EnhancerKey>;
    text: string;
  }

  const mdSegments: MdSegment[] = [];

  let preSegment: SegmentDTO = {
    type: TextType.TEXT,
    enhancer: {},
    text: '',
  };

  // 插入空为了触发最后一个 segment
  const newSegments = segments.concat(preSegment);

  const enhancerKeys = ['code', 'lineThrough', 'italic', 'bold'] as EnhancerKey[];

  for (const segment of newSegments) {
    const { type, enhancer } = segment;

    const mdSegment: MdSegment = {
      head: [],
      tail: [],
      pre: new Set(),
      text: '',
    };

    enhancerKeys.forEach((key) => {
      if (!preSegment.enhancer[key] && enhancer[key]) {
        mdSegment.pre.add(key);
      }
      if (preSegment.enhancer[key] && !enhancer[key]) {
        for (let i = mdSegments.length - 1; i >= 0; i--) {
          const m = mdSegments[i];
          if (!m) continue;
          if (m.pre.has(key)) {
            const md = mdMark(key);
            m.pre.delete(key);
            m.head.unshift(md);
            mdSegment.tail.push(md);
            break;
          }
        }
      }
    });

    switch (type) {
      case TextType.DATE:
        mdSegment.text = `@${segment.startDate}`;
        break;
      case TextType.USER:
        if (segment.uuid) {
          mdSegment.text = `@${getUserName(segment.uuid)}`;
        }
        break;
      case TextType.DATETIME:
        mdSegment.text = `@${segment.startDate} ${segment.startTime}`;
        break;
      case TextType.EQUATION:
        mdSegment.text = `$${segment.text}$`;
        break;
      case TextType.URL:
        mdSegment.text = `[${segment.text}](${segment.url})`;
        break;
      case TextType.LINK_PAGE:
        if (segment.uuid) {
          const block = cache.blocks[segment.uuid];
          const title = segmentsToText(block?.data.segments);
          mdSegment.text = `[${title}](${getLocationOrigin()}/${segment.uuid})`;
        }
        break;
      default:
        mdSegment.text = segment.text;
    }

    mdSegments.push(mdSegment);

    preSegment = segment;
  }

  return mdSegments.reduce((s, m) => {
    // 处理 有多个空格的文字
    s = `${s.replace(/(\s*)$/, ($0, $1) => `${m.tail.join('')}${$1}`)}`;
    s += `${m.text?.replace(/^(\s*)/, ($0, $1) => `${$1}${m.head.join('')}`)}`;
    return s;
  }, '');
};

export const convertText = (ids: string[], depth = 0) => {
  const { blocks } = cache;

  let num = 1;

  return ids.reduce((pre, id) => {
    const block = blocks[id];

    if (!block) return pre;

    const { type, data, uuid, parentId, subNodes } = block;
    const {
      segments = [],
      link,
      checked,
      format,
      level,
      display,
      caption,
      ref,
      collectionProperties,
      linkInfo,
    } = data;

    if (depth && type !== BlockType.TABLE_ROW) {
      pre += `${Array(depth * 2)
        .fill(' ')
        .join('')}`;
    }

    const ORIGIN = getLocationOrigin();

    switch (type) {
      case BlockType.PAGE:
      case BlockType.FOLDER:
      case BlockType.COLLECTION_VIEW:
      case BlockType.COLLECTION_VIEW_PAGE:
        pre += `[${segmentsToText(segments)}](${ORIGIN}/${uuid})`;
        break;
      case BlockType.TEXTAREA:
      case BlockType.MARK:
        pre += segmentsToMarkdown(segments);
        break;
      case BlockType.BOOKMARK:
        pre += `[${segmentsToText(linkInfo) || formatUrl(link ?? '')}](${link})`;
        break;
      case BlockType.EMBED:
        pre += `[${link}](${link})`;
        break;
      case BlockType.TODO:
        pre += `- [${checked ? 'x' : ' '}] ${segmentsToMarkdown(segments)}`;
        break;
      case BlockType.CODE:
        pre += `\`\`\`${format?.language ?? ''}\n${segmentsToText(segments)}\n\`\`\`\n`;
        break;
      case BlockType.DIVIDER:
        pre += '---';
        break;
      case BlockType.MIND_MAPPING:
      case BlockType.HEADER:
        pre += `${Array(level).fill('#').join('')} ${segmentsToMarkdown(segments)}`;
        break;
      case BlockType.QUOTE:
        pre += `> ${segmentsToMarkdown(segments)}`;
        break;
      case BlockType.TOGGLE_HEADER:
      case BlockType.FOLD_LIST:
      case BlockType.LIST:
        pre += `- ${segmentsToMarkdown(segments)}`;
        break;
      case BlockType.ORDER_LIST:
        // TODO: 复制时保存当前序号
        pre += `${num}. ${segmentsToMarkdown(segments)}`;
        num++;
        break;
      case BlockType.EQUATION:
        pre += `$${segmentsToText(segments)}$`;
        break;
      case BlockType.REFERENCE:
      case BlockType.REFERENCE_COLLECTION:
      case BlockType.REFERENCE_COLLECTION_PAGE: {
        if (ref?.uuid) {
          const page = blocks[ref.uuid];
          if (!page) break;
          pre += `[${segmentsToText(page.data.segments)}](${ORIGIN}/${ref.uuid})`;
        }
        break;
      }
      case BlockType.EXTERNAL_FILE:
      case BlockType.FILE: {
        const name = segmentsToText(segments);
        if (display === 'folder') {
          pre += `[${name}](${ORIGIN}/${uuid})`;
        } else {
          const isImage = display === 'image';
          const _fileName = caption ? `${segmentsToText(caption)}` : isImage ? link || name : name;
          const prefix = isImage ? '!' : '';

          if (link) {
            pre += `${prefix}[${_fileName}](${link})`;
          } else {
            pre += `${prefix}[${_fileName}](${ORIGIN}/preview/${uuid})`;
          }
        }

        break;
      }
      case BlockType.TABLE_ROW: {
        const parentBlock = blocks[parentId];
        if (!parentBlock) break;
        const isRowHeader = parentBlock.data.format?.tableBlockRowHeader;
        const columns = parentBlock.data.format?.tableBlockColumnOrder ?? [];
        const isFirst = id === parentBlock?.subNodes[0];
        const isLast = id === parentBlock?.subNodes[parentBlock?.subNodes.length - 1];

        if (isFirst && !isRowHeader) {
          pre += `${Array.from(Array(columns.length + 1))
            .fill('|')
            .join('')}\n${Array.from(Array(columns.length)).fill('|-').join('')}|\n`;
        }

        pre += columns.reduce((str, id) => {
          const segments = collectionProperties?.[id];
          if (segments) {
            str += segmentsToMarkdown(segments);
          }
          return `${str}|`;
        }, '|');

        if (isFirst && isRowHeader) {
          pre += `\n${Array.from(Array(columns.length)).fill('|-').join('')}|`;
        }

        pre += isLast ? '\n\n' : '\n';

        break;
      }
      default:
        num = 1;
    }

    if (caption) {
      pre += `\n${segmentsToMarkdown(caption)}`;
    }

    if (type !== BlockType.TABLE && type !== BlockType.TABLE_ROW) {
      pre += `\n\n`;
    }

    if (!isPageLike(type) && subNodes.length) {
      const noTab = type === BlockType.COLUMN_LIST || type === BlockType.COLUMN;
      if (!noTab) ++depth;
      pre += convertText(subNodes, depth);
      if (!noTab) --depth;
    }

    return pre;
  }, '');
};

export const convertTextForMindMap = (id: string, level = 1) => {
  const { blocks } = cache;

  const block = blocks[id];
  if (!block) return '';
  if (level === 5) return '';
  if (!supportAppendChild(id)) {
    return '';
  }
  let pre = `${Array(level).fill('#').join('')} ${segmentsToMarkdown(block.data.segments ?? [])}\n`;
  block.subNodes.forEach((cId) => {
    pre += convertTextForMindMap(cId, level + 1);
  });
  return pre;
};
