/**
 * 空间搜索
 */

import { cx } from '@flowus/common/cx';
import type { BlockDTO, SearchBlockDTO, SearchFilterDTO } from '@next-space/fe-api-idl';
import { BlockType, SearchDocSortType } from '@next-space/fe-api-idl';
import { useDebounceEffect } from 'ahooks';
import dayjs from 'dayjs';
import { omit } from 'lodash-es';
import type { FC, MouseEvent, MouseEventHandler, MutableRefObject } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { DirectionScroller } from 'src/common/components/direction-scroller';
import { Highlight } from '@flowus/common/components/hight-light';
import { HighlightPlus } from '@flowus/common/components/hight-light-plus';

import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { LoadingIcon } from 'src/common/components/loading-icon';
import { useCloseModal, useOpenModal } from 'src/common/components/next-modal';
import { PageNavigator } from 'src/common/components/page-navigator';
import { Tooltip } from 'src/common/components/tooltip';
import { DATE_FORMAT } from 'src/common/const';
import { request } from 'src/common/request';
import { PRIORITY_DIALOG } from 'src/common/utils/global-listener-helper';
import { LRUCache } from 'src/common/utils/lru-cache';
import { BlockDefaultIcon } from 'src/components/block-default-icon';
import { useOpenFilePreview } from 'src/components/file-preview/use-open-file-preview';
import { IconTrigger } from 'src/components/icon-trigger';
import { segmentsToText } from 'src/editor/utils/editor';
import { getOwnerPage } from 'src/hooks/block/use-get-owner-page';
import { useGlobalSearch } from 'src/hooks/block/use-global-search';
import { useOpenPage } from 'src/hooks/page/use-open-page';
import { useSpace } from 'src/hooks/space';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { useCurrentUser } from 'src/hooks/user';
import { getUserName } from 'src/hooks/user/use-remark-name';
import { Modals } from 'src/modals';
import { blocksActions } from 'src/redux/reducers/blocks';
import { cache, dispatch } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { bizTracker } from 'src/utils/biz-tracker';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { useGetPageId } from 'src/utils/getPageId';
import { setLocalStorage } from 'src/utils/local-storage';
import { usePickBlock } from 'src/utils/pick-block';
import { useFinalValue } from '@flowus/common/hooks/react-utils';
import { ShortcutSystemSymbol } from 'src/utils/shortcut';
import type { PatchState } from 'src/utils/type-utils';
import { getVirtualElement } from 'src/utils/virtualElement';
import { FilterPanel } from './search/filter-panel';
import { SearchResultHeader } from './search/search-result-header';
import { EllipsisSpan } from '@flowus/common/components/ellipsis-span';
import { AiTip } from './ai-tip';

type SearchBlockResult = SearchBlockDTO & {
  navs: BlockDTO[];
};

type SearchRecord = SearchFilterDTO & { pagesName?: string[] };
// #region schema
interface State {
  searchRecord: LRUCache<string, SearchRecord | undefined>;
  type: GlobalSearchType;
  view: 'RECENT' | 'RESULT' | 'LOADING' | 'NOT_FOUND';
  keyword: string; // 搜索关键字
  recent: BlockDTO[];
  result: {
    pageNo: number;
    // 搜索结果
    total: number;
    hasMore: boolean;
    items: SearchBlockResult[];
  };
  sortType: SearchDocSortType;
  filter: SearchFilterDTO;
  showFilter?: boolean;
  inPageId?: string;
  rootPageId?: string;
}

type Consumer = FC<{ state: State; patch: PatchState<State> }>;

const stateCache: Map<string, State> = new Map();

// #endregion
const MAX_RECENTLY = 7;

export enum GlobalSearchType {
  'normal' = 'normal',
  'share' = 'share',
}
interface SearchProps {
  type?: GlobalSearchType;
  inPageId?: string;
  rootPageId?: string;
}
/** 搜索按钮 */
export const Search: FC<SearchProps> = (props) => {
  const openSearchPopup = useOpenSearchPopup();

  const handleOpen: MouseEventHandler = () => {
    openSearchPopup(props);
  };

  return (
    <>
      <span className="w-px h-3.5 bg-grey6 flex-shrink-0" />
      <Tooltip
        popup={`快速查找文档或文件${ShortcutSystemSymbol}+P`}
        className="flex justify-center items-center animate-hover"
      >
        <Icon size="small" boxSize="large" name="IcSearchMiddleBold" onClick={handleOpen} />
      </Tooltip>
    </>
  );
};
const defaultFilter: SearchFilterDTO = {
  createdBy: [],
  ancestors: [],
};

/** 搜索弹层 */
const SearchPopup: FC<SearchProps> = (props) => {
  const { type = GlobalSearchType.normal, inPageId, rootPageId } = props;
  const pageId = useGetPageId();
  const block = usePickBlock(pageId, []);
  const user = useCurrentUser();
  const searchRecord = useFinalValue(
    () =>
      new LRUCache<string, SearchRecord | undefined>(
        localStorage,
        `recently_search${user.uuid}${block?.spaceId ?? getCurrentSpaceId()}`,
        5
      )
  );
  const [state, setState] = useState<State>(() => {
    if (stateCache.has(`${inPageId}`)) {
      return stateCache.get(`${inPageId}`) as State;
    }
    return {
      searchRecord,
      type,
      view: type === 'normal' ? 'RECENT' : 'RESULT',
      keyword: '',
      recent: [],
      result: { total: 0, items: [], hasMore: false, pageNo: 1 },
      sortType: SearchDocSortType.RELEVANCE,
      showFilter: false,
      inPageId, // 外面传进来的，分享页能定位到在哪个页面搜索
      rootPageId, // 外面传进来的，分享页能定位根页面
      filter: inPageId ? { ancestors: [inPageId] } : defaultFilter,
    };
  });

  const patchState: PatchState<State> = useCallback(
    (key, value) => {
      setState((s) => {
        if (key === 'view' && value === 'RESULT' && !s.keyword) {
          return s;
        }
        // 如果是分享页不允许修改这些字段
        if (type === 'share') {
          switch (key) {
            case 'showFilter':
              return s;
            case 'view': // 分享页不需要显示最近浏览,回到空结果页就行
              if (value === 'RECENT') {
                return {
                  ...s,
                  view: 'RESULT',
                  result: { total: 0, items: [], hasMore: false, pageNo: 1 },
                };
              }
              break;
            default:
          }
        }

        return { ...s, [key]: value };
      });
    },
    [type]
  );

  useEffect(() => {
    stateCache.set(`${inPageId}`, state);
  }, [state, inPageId]);

  useEffect(() => {
    const onChange = () => {
      patchState('searchRecord', searchRecord);
    };
    searchRecord.addChangeListener(onChange);
    return () => {
      searchRecord.removeChangeListener(onChange);
    };
  }, [patchState, searchRecord]);

  const SearchContent: Consumer = {
    RECENT: SearchRecent,
    RESULT: SearchResult,
    LOADING: SearchResult,
    NOT_FOUND: SearchNotFound,
  }[state.view];

  useEffect(() => {
    if (!state.showFilter) {
      patchState('filter', inPageId ? { ancestors: [inPageId] } : defaultFilter);
    }
  }, [inPageId, pageId, patchState, state.showFilter]);

  useEffect(() => {
    return () => {
      bizTracker.event('search_cancel');
    };
  }, []);

  return (
    <div
      className={`flex next-modal overflow-x-auto transition-all max-h-[630px] w-[90vw] max-w-[800px]`}
    >
      <div className={cx(state.showFilter ? 'w-[calc(100%-220px)]' : 'w-full')}>
        <SearchInput state={state} patch={patchState} />
        <SearchContent state={state} patch={patchState} />
      </div>
      {state.showFilter && (
        <FilterPanel
          defaultFilter={state.filter}
          onFilterChange={(filter) => {
            patchState('filter', filter);
          }}
        />
      )}
    </div>
  );
};

export const useOpenSearchPopup = () => {
  const openModal = useOpenModal();
  return (params?: SearchProps) => {
    bizTracker.event('search_click');
    openModal.modal({
      modalId: Modals.QUICK_SEARCH,
      center: false,
      containerClassName: '!pt-[10vh]',
      content: () => <SearchPopup {...params} />,
    });
  };
};

/** 输入框 */
const SearchInput: Consumer = (props) => {
  const {
    state: { keyword, view, filter, sortType, type, inPageId },
    patch: patchState,
  } = props;

  const globalSearch = useGlobalSearch();
  const inputRef = useRef<HTMLInputElement>(null);
  const pageId = useGetPageId();
  const block = usePickBlock(pageId, ['data'], ['segments']);
  const space = useSpace(block?.spaceId ?? getCurrentSpaceId()); // 分享页也有搜索所以不能用useCurrentSpace
  const isComposingRef = useRef(false);

  const patchKeyword = (value: string) => {
    if (!value) {
      patchState('view', 'RECENT');
      patchState('showFilter', false);
    }
    patchState('keyword', value);
  };
  const fetchCount = useRef(0);
  useDebounceEffect(
    () => {
      if (!keyword) return;
      if (isComposingRef.current) return;
      const search = async (keyword: string) => {
        const requestId = ++fetchCount.current;
        const { total, results, hasMore } = await globalSearch({
          space: block?.spaceId ?? getCurrentSpaceId(),
          page: 1,
          perPage: 10,
          query: keyword,
          source: type === 'normal' ? 'quickFind' : 'quickFindPublic',
          sort: sortType,
          filters: filter,
        });
        if (requestId !== fetchCount.current) return;

        if (results.length > 0) {
          patchState('result', { total, items: results, hasMore, pageNo: 1 });
          patchState('view', 'RESULT');
        } else {
          patchState('result', { total: 0, items: [], hasMore, pageNo: 1 });
          patchState('view', 'NOT_FOUND');
        }
        patchState('showFilter', true);
      };
      void search(keyword);
    },
    [sortType, filter, keyword, patchState, block?.spaceId, globalSearch],
    {
      wait: 800,
    }
  );
  useEffect(() => {
    if (keyword) {
      // 先展示loading
      patchState('view', 'LOADING');
    }
  }, [sortType, filter, keyword, patchState]);

  return (
    <div className="pt-2.5 mx-2.5 relative">
      <Input
        selection={[0, keyword.length]}
        onCompositionChange={(isComposing) => {
          isComposingRef.current = isComposing;
        }}
        inputRef={inputRef}
        autoFocus
        className="h-8 px-2.5"
        inputClassName="pl-1"
        placeholder={
          inPageId
            ? `在「${
                segmentsToText(block?.data.segments) || getUntitledName(block?.type)
              }」页面搜索`
            : `在「${space.title}」中搜索或问问 AI`
        }
        value={keyword}
        onFocus={() => bizTracker.event('search_box_click')}
        onChange={(value) => patchKeyword(value)}
        addonBefore={
          view === 'LOADING' ? (
            <LoadingIcon size="middle" className="text-grey4" />
          ) : (
            <Icon size="middle" className="text-grey4" name="IcSearchMiddle" />
          )
        }
        addonAfter={
          keyword && (
            <Icon
              size="middle"
              onClick={() => {
                patchState('showFilter', false);
                patchKeyword('');
                inputRef.current?.focus();
              }}
              name={'IcUploadCancel'}
              className="text-grey4"
            />
          )
        }
      />
    </div>
  );
};

const FilterText: FC<{ filter?: SearchRecord }> = ({ filter }) => {
  const texts = [];
  if (!filter) return null;
  if (filter.isTitleOnly) {
    texts.push('只搜索标题');
  }
  if (filter.pagesName && filter.pagesName.length > 0) {
    filter.pagesName.forEach((pageName, index, arr) => {
      texts.push(
        [index === 0 ? '在' : '', pageName, index !== arr.length - 1 ? ', ' : ' 中搜索'].join('')
      );
    });
  }
  if (filter.createdBy && filter.createdBy.length > 0) {
    filter.createdBy.forEach((uuid, index, arr) => {
      const userName = getUserName(uuid);
      userName &&
        texts.push([index === 0 ? '由' : '', userName, index !== arr.length - 1 ? ', ' : ' 创建']);
    });
  }
  if (filter.createdTime) {
    texts.push(
      [
        '创建于',
        ' ',
        `${dayjs(filter.createdTime.start).format(DATE_FORMAT)}-${dayjs(
          filter.createdTime.start
        ).format(DATE_FORMAT)}`,
      ].join('')
    );
  }
  if (filter.lastEditedTime) {
    texts.push(
      [
        '最后编辑于',
        ' ',
        `${dayjs(filter.lastEditedTime.start).format(DATE_FORMAT)}-${dayjs(
          filter.lastEditedTime.start
        ).format(DATE_FORMAT)}`,
      ].join('')
    );
  }
  let filterDesc = '';
  if (texts.length > 0) {
    filterDesc = `- ${texts.join('·')}`;
  }
  return <span className="ml-2 text-t2 text-grey4">{filterDesc}</span>;
};

/** 最近浏览 */
const SearchRecent: Consumer = (props) => {
  const {
    state: { recent, searchRecord, keyword, inPageId },
    patch: patchState,
  } = props;

  const pageId = useGetPageId();
  const block = usePickBlock(pageId, []);
  const divRef = useRef(null);
  const openFilePreview = useOpenFilePreview();
  const closeModal = useCloseModal();
  const openSearchContextMenu = useOpenSearchContextMenu();
  const openPage = useOpenPage();

  const lastRecentClearTime = {
    key: 'RECENT_VISIT_TIMESTAMP',
    get: () => Number(localStorage.getItem(lastRecentClearTime.key) ?? 0),
    set: (value: number) => setLocalStorage(lastRecentClearTime.key, String(value)),
  };
  const sortSearchRecords = searchRecord.getSortItems();

  const patchRecent = async () => {
    const spaceId = block?.spaceId ?? getCurrentSpaceId();
    const time = lastRecentClearTime.get();

    const response = await request.editor.querySpaceVisitsHistory(spaceId, +time);
    if (typeof response !== 'object') return;

    const { results, recordMap } = response;
    if (!results) return;
    if (!recordMap) return;

    const { blocks } = recordMap;
    if (!blocks) return;

    dispatch(blocksActions.update({ blocks: blocks as Record<string, NextBlock> }));

    const getItem = (uuid?: string) => {
      if (uuid) {
        const block = blocks[uuid];
        if (block) {
          return block;
        }
      }
    };

    const recent = [];
    let count = 0;
    // 只显示MAX_RECENTLY个数的最近浏览记录
    for (let i = 0; count < MAX_RECENTLY && i < results.length; i++) {
      const uuid = results[i]?.uuid;
      const item = getItem(uuid);
      if (item && recent.length < MAX_RECENTLY) {
        count++;
        recent.push(item);
      }
    }

    patchState('recent', recent);
  };

  useEffect(() => {
    void patchRecent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearRecent = () => {
    bizTracker.event('clear_recent_used');
    lastRecentClearTime.set(Date.now());
    patchState('recent', []);
  };

  const getTitle = (block: BlockDTO) => {
    const title = segmentsToText(block.data.segments);
    if (title) return title;
    return getUntitledName(block.type);
  };

  const handleOpen = (
    block: BlockDTO,
    opt: { forceOpenInRight?: boolean; forceOpenNewTab: boolean }
  ) => {
    bizTracker.event('recent_used', { page_type: block.type });
    if (block.type === BlockType.FILE) {
      openFilePreview(block.uuid);
      closeModal(Modals.QUICK_SEARCH);
    } else {
      openPage(block.uuid, {
        forceOpenInRight: opt.forceOpenInRight,
        forceOpenNewTab: opt.forceOpenNewTab,
      });
      if (!opt.forceOpenNewTab) {
        closeModal(Modals.QUICK_SEARCH);
      }
    }
  };

  if (recent.length === 0 && searchRecord.length() === 0) {
    return <div className="h-2.5" />;
  }

  return (
    <div className="max-h-[600px] overflow-y-auto py-1 transition-all" ref={divRef}>
      <DirectionScroller
        customItem={true}
        defaultIndex={0}
        containerRef={divRef}
        listenPriority={PRIORITY_DIALOG}
        itemCount={recent.length + searchRecord.length()}
      >
        {(activeIndex: number, setSelectedIndex: (n: number) => void) => {
          return (
            <>
              <AiTip showType={inPageId ? 'page' : 'space'} className="h-10 px-4 py-1" />
              <div className="group">
                {recent.map((block, index) => (
                  <div key={index}>
                    {index === 0 && (
                      <div className="mt-2 mb-1 mx-[15px] flex items-center justify-between text-t2">
                        <div className="text-grey3">最近浏览</div>
                        <div
                          className="px-2 text-grey4 text-center rounded animate-hover hidden group-hover:block"
                          onClick={clearRecent}
                        >
                          清除
                        </div>
                      </div>
                    )}
                    <div
                      list-item-custom=""
                      className={cx('py-[9px] flex  mx-2 flex-row animate-hover', {
                        'normal-bg': index === activeIndex,
                      })}
                      onContextMenu={(e) => {
                        openSearchContextMenu(e, () =>
                          handleOpen(block, {
                            forceOpenNewTab: true,
                          })
                        );
                      }}
                      onClick={(event) =>
                        handleOpen(block, {
                          forceOpenInRight: event.altKey,
                          forceOpenNewTab: event.ctrlKey || event.metaKey,
                        })
                      }
                      onMouseEnter={() => setSelectedIndex(index)}
                    >
                      <div className="ml-2 text-xl leading-none block">
                        <IconTrigger
                          blockId={block.uuid}
                          trigger={false}
                          defaultIcon={<BlockDefaultIcon uuid={block.uuid} />}
                        />
                      </div>
                      <div className={cx('flex flex-col justify-center ml-2 flex-1 max-w-[280px]')}>
                        <EllipsisSpan className="text-t2-medium block">
                          {getTitle(block)}
                        </EllipsisSpan>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="group">
                {sortSearchRecords.map((item, _index) => {
                  const index = _index + recent.length;
                  return (
                    <div key={item.key}>
                      {_index === 0 && (
                        <div className="mt-4 mb-1 mx-[15px] flex items-center justify-between text-t2">
                          <div className="text-grey3">最近搜索</div>
                          <div
                            className="px-2 text-grey4 text-center rounded animate-hover hidden group-hover:block"
                            onClick={() => {
                              searchRecord.clear();
                            }}
                          >
                            清除
                          </div>
                        </div>
                      )}
                      <div
                        list-item-custom=""
                        className={cx('group-scope py-[9px] flex  mx-2 flex-row animate-hover', {
                          'normal-bg': index === activeIndex,
                        })}
                        onMouseEnter={() => setSelectedIndex(index)}
                        onClick={() => {
                          patchState('keyword', item.key);
                          if (item.value) {
                            patchState('filter', omit(item.value, 'pagesName'));
                          }
                        }}
                      >
                        <div className="ml-2 text-xl leading-none block">
                          <Icon name="IcSearch" size="middle" />
                        </div>
                        <div className="flex flex-col justify-center ml-2 flex-1 text-t2-medium text-ellipsis">
                          <span>
                            {item.key}
                            <FilterText filter={item.value} />
                          </span>
                        </div>
                        <Icon
                          onClick={(e) => {
                            searchRecord.delete(item.key);
                            e.stopPropagation();
                          }}
                          name={'IcUploadCancel'}
                          className="text-grey4 mr-2 transition-opacity opacity-0 group-scope-hover:opacity-100"
                          size="middle"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          );
        }}
      </DirectionScroller>
    </div>
  );
};

/** 搜索结果 */
const SearchResult: Consumer = (props) => {
  const {
    state: {
      searchRecord,
      keyword,
      result: { items, hasMore, pageNo },
      sortType,
      filter,
      showFilter,
      type,
      rootPageId,
      inPageId,
    },
    patch: patchState,
  } = props;

  const pageId = useGetPageId();
  const block = usePickBlock(pageId, []);
  const globalSearch = useGlobalSearch();
  const [loading, setLoading] = useState(false);
  const closeModal = useCloseModal();
  const openFilePreview = useOpenFilePreview();
  const openSearchContextMenu = useOpenSearchContextMenu();
  const openPage = useOpenPage();

  const handleAttachBottom = async () => {
    const spaceId = block?.spaceId ?? getCurrentSpaceId();
    setLoading(true);
    try {
      const { total, results, hasMore } = await globalSearch({
        space: spaceId,
        page: pageNo + 1,
        perPage: 10,
        sort: sortType,
        filters: filter,
        query: keyword,
        source: type === 'normal' ? 'quickFind' : 'quickFindPublic',
      });

      const finalItems = [...items];
      finalItems.push(...results);

      patchState('result', { total, items: finalItems, hasMore, pageNo: pageNo + 1 });
    } finally {
      setLoading(false);
    }
  };
  const container = useRef() as MutableRefObject<HTMLDivElement>;

  const handleClick = (
    item: SearchBlockResult,
    opt: { forceOpenInRight?: boolean; forceOpenNewTab: boolean }
  ) => {
    const page = cache.blocks[item.pageId ?? ''];
    if (!page) return;

    bizTracker.event('search_result_click', { page_type: page.type });
    const block = cache.blocks[item.uuid];
    let { pageId } = item;
    const { uuid } = item;
    let blockId: string | undefined = uuid;
    if (block?.type === BlockType.TABLE_ROW) {
      blockId = block.parentId;
    } else if (page.type === BlockType.FILE) {
      openFilePreview(page.uuid);
      closeModal(Modals.QUICK_SEARCH);
    } else if (item.pageId === item.uuid) {
      blockId = '';
    } else {
      let jumpPageId = page.uuid;
      const ownerPageId = getOwnerPage(item.uuid);
      // 如果是内嵌就跳到内嵌上去
      if (ownerPageId && cache.blocks[ownerPageId]?.type === BlockType.MIND_MAPPING) {
        jumpPageId = ownerPageId;
      }
      pageId = jumpPageId;
    }
    if (pageId) {
      openPage(pageId, {
        forceOpenInRight: opt.forceOpenInRight,
        forceOpenNewTab: opt.forceOpenNewTab,
        hash: blockId ? `#${blockId}` : undefined,
      });
    }
    closeModal(Modals.QUICK_SEARCH);
    const pagesName = filter?.ancestors?.map((uuid) => {
      const block = cache.blocks[uuid];
      const title = segmentsToText(block?.data.segments);
      if (title) return title;
      return getUntitledName(block?.type);
    });
    searchRecord.put(keyword, {
      ...filter,
      pagesName,
    });
  };

  return (
    <>
      <AiTip
        showType={inPageId ? 'page' : 'space'}
        className="h-10 px-4 py-1 mt-2"
        keyword={keyword}
      />
      {items.length > 0 && (
        <SearchResultHeader
          showCurrentPageSwitch={type === GlobalSearchType.share && pageId !== rootPageId}
          searchInCurrentPage={filter.ancestors?.length === 1 && filter.ancestors?.[0] === pageId}
          onClickSearchInCurrentPage={(open) => {
            if (open) {
              patchState('filter', { ...filter, ancestors: [pageId] });
            } else if (rootPageId) {
              patchState('filter', { ...filter, ancestors: [rootPageId] });
            }
          }}
          onShowFilter={() => {
            patchState('showFilter', true);
          }}
          showFilter={showFilter}
          sortType={sortType}
          onSwitchType={(newSortType) => {
            patchState('sortType', newSortType);
          }}
        />
      )}
      <div ref={container} className="max-h-[530px] overflow-y-auto pb-2 transition-all">
        <InfiniteScroll
          pageStart={1}
          initialLoad={false}
          useWindow={false}
          loadMore={handleAttachBottom}
          getScrollParent={() => container.current}
          hasMore={hasMore}
        >
          <DirectionScroller
            customItem={true}
            defaultIndex={-1}
            containerRef={container}
            itemCount={items.length}
          >
            {(activeIndex: number) =>
              items.map((item, index) => (
                <SearchResultItem
                  key={index}
                  active={index === activeIndex}
                  item={item}
                  keyword={keyword}
                  onClick={(event) => {
                    handleClick(item, {
                      forceOpenNewTab: event.metaKey || event.ctrlKey,
                      forceOpenInRight: event.altKey,
                    });
                  }}
                  onContextMenu={(e) => {
                    openSearchContextMenu(e, () =>
                      handleClick(item, {
                        forceOpenNewTab: true,
                      })
                    );
                  }}
                />
              ))
            }
          </DirectionScroller>
        </InfiniteScroll>
        {loading && (
          <div className="w-full flex justify-center">
            <LoadingIcon size="large" />
          </div>
        )}
      </div>
    </>
  );
};

interface ItemProps {
  active?: boolean;
  item: SearchBlockResult;
  keyword: string;
  onClick: (e: MouseEvent) => void;
  onContextMenu: (e: MouseEvent) => void;
}
export const SearchResultItem: FC<ItemProps> = (props) => {
  const { item, active, keyword } = props;
  const page = usePickBlock(item.pageId ?? '', ['data'], ['segments']);
  if (!page) return null;
  const getTitle = () => {
    const title = segmentsToText(page.data.segments);
    if (title) return title;
    return getUntitledName(page.type);
  };

  // 是否匹配到page，是的话就是匹配到title
  const matchPage = item.uuid === item.pageId;
  return (
    <div
      list-item-custom=""
      key={page.uuid}
      className={cx('py-[9px] flex flex-row animate-hover mx-2 rounded', {
        'normal-bg': active,
      })}
      onContextMenu={props.onContextMenu}
      onClick={props.onClick}
    >
      <div className="ml-[15px] text-xl leading-none block">
        <IconTrigger
          blockId={page.uuid}
          trigger={false}
          defaultIcon={<BlockDefaultIcon uuid={page.uuid} />}
        />
      </div>
      <div
        className={cx('ml-2 overflow-hidden', !item.navs.length && 'flex flex-col justify-center')}
      >
        <EllipsisSpan className="text-t2 block">
          {matchPage ? (
            <Highlight text={getTitle()} search={keyword} className="text-t2-medium" />
          ) : (
            <div className="text-t2-medium">{getTitle()}</div>
          )}
        </EllipsisSpan>
        <div className="mt-[2px] text-t4 text-grey3" hidden={!item.navs.length}>
          <PageNavigator navs={item.navs} />
        </div>
        {!matchPage && (
          <HighlightPlus
            limitLen={50}
            text={item.hitText}
            search={keyword}
            className="text-t2-medium"
            containerClassName="text-ellipsis"
          />
        )}
      </div>
    </div>
  );
};

/** 无相关页面 */
const SearchNotFound: Consumer = () => {
  return <div className="py-5 text-t2 text-grey4 text-center leading-[22px]">无相关页面</div>;
};

/** 正在搜索 */
// const SearchLoading: Consumer = () => {
//   return <div className="py-5 text-t2 text-grey4 text-center leading-[22px]">正在搜索...</div>;
// };

const useOpenSearchContextMenu = () => {
  const openModal = useOpenModal();
  return useCallback(
    (event, onClick: (event: MouseEvent) => void) => {
      openModal.dropdown({
        placement: 'bottom-start',
        offset: [4, 4],
        popcorn: getVirtualElement(event),
        content: () => {
          return (
            <div className="next-modal w-[240px]" onClick={onClick}>
              <div className="animate-hover rounded flex items-center p-2 cursor-pointer justify-between">
                <div className="flex items-center">
                  <Icon name={'IcMenuOpenNew'} size="middle" />
                  <div className="text-t2 ml-2">新窗口打开</div>
                </div>
              </div>
            </div>
          );
        },
      });
    },
    [openModal]
  );
};
