import { BlockType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useState } from 'react';
import { publicLink } from 'src/common/const';
import { usePickBlock } from 'src/utils/pick-block';
import { EmptyLink } from '../components/empty-link';
import { Tip } from '../components/tip';
import type { SuggestState, TabProps } from '../types';
import { LinkOther } from './link-other';
import { MentionCurrent } from './mention-current';

export const LinkSuggest: FC<Pick<TabProps, 'uuid'>> = ({ uuid }) => {
  const block = usePickBlock(uuid, []);
  const isFolder = block?.type === BlockType.FOLDER;

  const [state, setState] = useState<SuggestState>({
    mention: true,
    other: !isFolder,
  });

  return (
    <div>
      {!__BUILD_IN__ && (
        <Tip
          type="LINK_TABS_SUGGEST"
          describe="💡 当前页面与其他页面或有相互提及的情况，但并未建立引用链接，将给出引用建议"
          tip="了解引用建议"
          link={publicLink.quoteSuggestion}
        />
      )}

      {!state.mention && !state.other ? (
        <EmptyLink>暂无引用建议</EmptyLink>
      ) : (
        <>
          <MentionCurrent setState={setState} uuid={uuid} />
          {!isFolder && <LinkOther setState={setState} uuid={uuid} />}
        </>
      )}
    </div>
  );
};
