import type { SegmentDTO } from '@next-space/fe-api-idl';
import { TextType } from '@next-space/fe-api-idl';

export const convertSegmentTypeFromSuggest = (segments?: SegmentDTO[]) => {
  return segments?.map((segment) => {
    if (segment.type === ('suggest' as any)) {
      return { ...segment, type: TextType.LINK_PAGE };
    }
    return segment;
  });
};
