import { Input } from '@flowus/common/components/input';
import { useMemo, type FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { AsideConfig } from './aside-config';
import { Button } from '@flowus/common/components';
import { Provider as ReduxProvider, useStore } from 'react-redux';
import { ChartPreview } from './chart-preview';
import { ChartConfigContext } from './chart-config-context';
import { NextModalProvider, useCloseModal } from 'src/common/components/next-modal';
import { updateBlock } from 'src/redux/managers/block/update';
import { segmentsToText, textToSegments } from 'src/editor/utils/editor';
import { useObservableBlock } from 'src/services/rxjs-redux/use-obs-block';
import { PageScene, PageSceneContext } from 'src/views/main/scene-context';
import type { RootState } from 'src/redux/types';
import { transaction } from 'src/hooks/use-transaction';
import { createPreviewStore } from './data';
import { Modals } from '@flowus/shared';

interface Props {
  id: string; // chartblock id,not collection id
}
export const ChartConfigPanel: FC<Props> = (props) => {
  const store = useMemo(() => {
    return createPreviewStore(props.id);
  }, [props.id]);
  return (
    <ChartConfigContext.Provider value={props.id}>
      <PageSceneContext.Provider value={PageScene.PAGE_CHART}>
        <ReduxProvider store={store}>
          <NextModalProvider>
            <ChartConfigPanel0 {...props} />
          </NextModalProvider>
        </ReduxProvider>
      </PageSceneContext.Provider>
    </ChartConfigContext.Provider>
  );
};

const ChartConfigPanel0: FC<Props> = (props) => {
  const store = useStore();
  const segments = useObservableBlock(props.id, (b) => b?.data.segments);
  const closeModal = useCloseModal();

  const title = useMemo(() => {
    return segmentsToText(segments);
  }, [segments]);

  return (
    <div className="chart-panel flex flex-col min-w-[960px] w-[64vw] h-[80vh] overflow-auto">
      <div className="flex items-center content-between py-3 px-4">
        <Input
          className="text-h4 w-full h-9 bg-transparent"
          showBorder={false}
          value={title}
          placeholder="请输入图表名称"
          onChange={(v) => {
            updateBlock(
              props.id,
              {
                data: {
                  segments: textToSegments(v),
                },
              },
              true,
              store as any
            );
          }}
        />
        <Icon
          name={'IcTitleClose'}
          size="middle"
          onClick={() => {
            closeModal(Modals.CHART_PANEL_ID);
          }}
        />
      </div>
      <div className="w-full bg-grey7 h-px" />

      <div className="flex overflow-hidden h-full">
        <div className="flex-1 overflow-hidden">
          <ChartPreview id={props.id} preview={true} />
        </div>
        <AsideConfig className="w-[340px] border-l border-grey7" />
      </div>
      <div className="shrink-0">
        <div className="w-full bg-grey7 h-px" />
        <div className="w-full flex justify-end space-x-2 py-2">
          <Button
            className="px-6"
            onClick={() => {
              closeModal(Modals.CHART_PANEL_ID);
            }}
          >
            取消
          </Button>
          <Button
            colorType="active"
            className="!mr-4 px-6"
            onClick={() => {
              const state = store.getState() as RootState;
              const chatBlock = state.blocks[props.id];
              transaction(() => {
                updateBlock(props.id, {
                  data: {
                    segments: chatBlock?.data.segments,
                    format: {
                      chartFormat: {
                        ...chatBlock?.data.format?.chartFormat,
                      },
                    },
                  },
                });
                closeModal(Modals.CHART_PANEL_ID);
              });
            }}
          >
            确定
          </Button>
        </div>
      </div>
    </div>
  );
};
