import type { Middleware } from '@reduxjs/toolkit';
import { isAnyOf } from '@reduxjs/toolkit';
import { keys, omit } from 'lodash-es';
import { OPEN_RX } from 'src/feature-flags_';
import { MEMOIZE_BLOCK_VERSION } from 'src/services/memoize/cache';
import { observableStoreOp } from 'src/services/rxjs-redux/map';
import {
  getIgnoreDeepKey,
  getIgnoreOtherData,
  getPermissionKey,
  getSelectedBlocksKey,
  getSelectedCellKey,
  omitComplexTypes,
  prefixSelectBlock,
  prefixSelectCell,
  prefixSimpleTable,
  resetSelectValue,
  RxPatchKey,
} from 'src/services/rxjs-redux/patch';
import { spaceViewsOperation } from 'src/services/spaces/space-views';
import { spacesOperation } from 'src/services/spaces/spaces';
import {
  ADD_MARK_NAME,
  CREATE_BLOCK,
  CREATE_COLLECTION_VIEW,
  CREATE_SPACE,
  CREATE_SPACE_VIEW,
  DELETE_SPACE_VIEW,
  DESTROY_BLOCK,
  LIST_AFTER_BLOCK,
  LIST_AFTER_COLLECTION_VIEW,
  LIST_AFTER_FAVORITE,
  LIST_AFTER_TEMPLATE,
  LIST_BEFORE_BLOCK,
  LIST_BEFORE_COLLECTION_VIEW,
  LIST_BEFORE_FAVORITE,
  LIST_BEFORE_TEMPLATE,
  LIST_REMOVE_BLOCK,
  LIST_REMOVE_COLLECTION_VIEW,
  LIST_REMOVE_COLLECTION_VIEW_PAGESORT,
  LIST_REMOVE_FAVORITE,
  LIST_REMOVE_TEMPLATE,
  UPDATE_COLLECTION_VIEW,
  UPDATE_SPACE,
  UPDATE_SPACE_VIEW,
} from '../actions';
import { CREATE_COMMENT, LIST_AFTER_COMMENT, LIST_REMOVE_COMMENT } from '../actions/comments';
import { CREATE_DISCUSSION, UPDATE_DISCUSSION } from '../actions/discussion';
import { blocksActions } from '../reducers/blocks';
import { collectionViewsActions } from '../reducers/collection-views';
import { commentsActions } from '../reducers/comments';
import { discussionsActions } from '../reducers/discussions';
import { simpleTableActions } from '../reducers/simple-table';
import { uiActions } from '../reducers/ui';
import { usersActions } from '../reducers/users';
import type { RootState, SelectBlock, SelectCell } from '../types';

export const patchRxjsMiddleware: Middleware = ({ getState }: { getState: () => RootState }) => {
  const patchBlock = (id: string) => {
    const _state = getState();

    const data =
      _state.blocks[id] ||
      _state.collectionViews[id] ||
      _state.comments[id] ||
      _state.discussions[id] ||
      _state.users[id];

    observableStoreOp.onlyPatch(id, data);

    // @ts-ignore 这里是只监听 permission 的情况处理
    observableStoreOp.onlyPatch(getPermissionKey(id), data?.permissions);

    if (data) {
      const ignoreDeepKey = getIgnoreDeepKey(id);
      const ignoreOtherData = getIgnoreOtherData(id);

      const hasIgnoreDeepKey = observableStoreOp.has(ignoreDeepKey);
      const hasIgnoreOtherData = observableStoreOp.has(ignoreOtherData);

      // 没有特殊需求就没必要深度计算特殊结构的数据了
      if (hasIgnoreDeepKey || hasIgnoreOtherData) {
        // 这里忽略了一些不太重要的变更属性，防止不必要的重复计算
        const omitOtherData = omit(data, [
          'updatedAt',
          'updatedBy',
          'createdAt',
          'createdBy',
          'version',
        ]);

        // 下面这俩是用于基础属性监听的，不监听深层的数据。同时也忽略了上面的不重要属性
        const omitComplexTypesData = omitComplexTypes(omitOtherData);

        // 是否只判断第一层
        observableStoreOp.onlyPatch(ignoreDeepKey, omitComplexTypesData);

        // 是否排除额外字段
        observableStoreOp.onlyPatch(ignoreOtherData, omitOtherData);
      }
    }
  };

  /** 选中 block 推送 rx 订阅 */
  const nextSelectBlock = (item: SelectBlock) => {
    if (item.syncId) {
      const key = getSelectedBlocksKey({ syncId: item.syncId });
      observableStoreOp.onlyPatch(key);
    }

    if (item.blockId) {
      const key = getSelectedBlocksKey({ blockId: item.blockId });
      observableStoreOp.onlyPatch(key);
    }

    if (item.viewId) {
      const key = getSelectedBlocksKey({ viewId: item.viewId });
      observableStoreOp.onlyPatch(key);
    }

    if (item.blockId && item.syncId && item.viewId) {
      const key = getSelectedBlocksKey(item);
      observableStoreOp.onlyPatch(key);
    }
    const key = getSelectedBlocksKey({ all: true });
    observableStoreOp.onlyPatch(key);
    observableStoreOp.onlyPatch(RxPatchKey.HAS_SELECT_BLOCK);
  };

  const nextSelectCell = (item: SelectCell) => {
    if (item.recordId) {
      const key = getSelectedCellKey({ recordId: item.recordId });
      observableStoreOp.onlyPatch(key);
    }

    if (item.viewId) {
      const key = getSelectedCellKey({ viewId: item.viewId });
      observableStoreOp.onlyPatch(key);
    }

    if (item.recordId) {
      const key = getSelectedCellKey({ recordId: item.recordId });
      observableStoreOp.onlyPatch(key);
    }

    if (item.recordId && item.viewId && item.propertyId) {
      const key = getSelectedCellKey(item);
      observableStoreOp.onlyPatch(key);
    }

    const key = getSelectedCellKey({ all: true });
    observableStoreOp.onlyPatch(key);
    observableStoreOp.onlyPatch(RxPatchKey.HAS_SELECT_CELL);
  };

  const cleanPrefixData = (prefix: string) => {
    observableStoreOp.onlyPatch(RxPatchKey.HAS_SELECT_BLOCK);
    const opMap = observableStoreOp.getMap();
    opMap.forEach((ob, key) => {
      if (key?.includes(prefix)) {
        const value = ob.getValue();
        if (value !== false) {
          observableStoreOp.onlyPatch(key, resetSelectValue);
        }
      }
    });
  };

  /** 清空所有选中的数据 */
  const updateAllPrefixSelect = () => {
    cleanPrefixData(prefixSelectBlock);
    cleanPrefixData(prefixSelectCell);
  };

  return (next) => (action) => {
    const runPatch = () => {
      if (
        isAnyOf(
          discussionsActions.update,
          discussionsActions.clear,
          UPDATE_DISCUSSION,
          CREATE_DISCUSSION,
          LIST_AFTER_COMMENT,
          LIST_REMOVE_COMMENT
        )(action)
      ) {
        observableStoreOp.onlyPatch(RxPatchKey.ALL_DISCUSSIONS);
      }

      // 特殊优化
      if (
        isAnyOf(
          blocksActions.incVersion,
          UPDATE_COLLECTION_VIEW,
          CREATE_COLLECTION_VIEW,
          blocksActions.update,
          blocksActions.clear
        )(action)
      ) {
        MEMOIZE_BLOCK_VERSION.clear();
      }

      // 更新 version 不需要刷新 UI
      if (isAnyOf(blocksActions.incVersion)(action)) {
        return;
      }

      if (isAnyOf(blocksActions.clear, discussionsActions.clear)(action)) {
        observableStoreOp.clear();
        return;
      }

      if (
        isAnyOf(
          usersActions.clear,
          usersActions.removeUser,
          usersActions.update,
          usersActions.updateSpaceUsers
        )(action)
      ) {
        observableStoreOp.onlyPatch(RxPatchKey.ALL_USERS);
      }

      // #region 处理同样的参数

      /**
       * 包含了
       * UPDATE_COLLECTION_VIEW
       * LIST_REMOVE_COLLECTION_VIEW_PAGESORT
       * LIST_REMOVE_BLOCK
       * LIST_AFTER_BLOCK
       * LIST_BEFORE_BLOCK
       * LIST_AFTER_COLLECTION_VIEW
       * LIST_BEFORE_COLLECTION_VIEW
       * LIST_REMOVE_COLLECTION_VIEW
       * LIST_REMOVE_COLLECTION_VIEW_PAGESORT
       * UPDATE_BLOCK
       * SET_BLOCK_PERMISSION
       * REMOVE_BLOCK_PERMISSION
       * UPDATE_DISCUSSION
       * LIST_REMOVE_DISCUSSION
       * LIST_AFTER_DISCUSSION
       * UPDATE_COMMENT
       * LIST_AFTER_COMMENT
       * LIST_REMOVE_COMMENT
       * LIST_BEFORE_COLLECTION_VIEW_PAGESORT
       * LIST_AFTER_COLLECTION_VIEW_PAGESORT
       */

      // 选择操作不应该更新 block 数据
      if (
        !isAnyOf(
          uiActions.addSelectBlock,
          uiActions.removeSelectBlock,
          uiActions.update,
          uiActions.updateSelectBlocks,
          uiActions.updateSelectCells
        )(action)
      ) {
        if (action.payload?.uuid) {
          patchBlock(action.payload.uuid);
        }

        if (action.payload?.discussionId) {
          patchBlock(action.payload.uuid);
        }

        if (action.payload?.viewId) {
          patchBlock(action.payload.viewId);
        }

        if (action.payload?.parentId) {
          patchBlock(action.payload.parentId);
        }

        if (action.payload?.after) {
          patchBlock(action.payload.after);
        }

        if (action.payload?.before) {
          patchBlock(action.payload.before);
        }
      }

      // #endregion

      // #region collectionViews
      if (isAnyOf(collectionViewsActions.update)(action)) {
        keys(action.payload).forEach((key) => {
          patchBlock(key);
        });
        return;
      }

      if (isAnyOf(CREATE_COLLECTION_VIEW)(action)) {
        patchBlock(action.payload.collectionView.uuid);
        return;
      }
      // #endregion

      // #region simpleTable
      if (isAnyOf(simpleTableActions.update)(action)) {
        observableStoreOp.onlyPatch(prefixSimpleTable);
        return;
      }

      // #endregion

      // #region block
      if (
        isAnyOf(
          LIST_REMOVE_BLOCK,
          LIST_AFTER_BLOCK,
          LIST_BEFORE_BLOCK,
          LIST_AFTER_COLLECTION_VIEW,
          LIST_BEFORE_COLLECTION_VIEW,
          LIST_REMOVE_COLLECTION_VIEW,
          LIST_REMOVE_COLLECTION_VIEW_PAGESORT
        )(action)
      ) {
        // observableStoreOp.onlyPatch(action.payload.uuid);
        const block = getState().blocks[action.payload.uuid];
        if (block?.parentId) {
          patchBlock(block.parentId);
        }
        return;
      }

      if (isAnyOf(CREATE_BLOCK)(action)) {
        const { block } = action.payload;
        patchBlock(block.uuid);
        return;
      }

      if (isAnyOf(DESTROY_BLOCK)(action)) {
        const { uuid } = action.payload;
        observableStoreOp.deleteObs(uuid);
        return;
      }

      if (isAnyOf(blocksActions.update)(action)) {
        keys(action.payload.blocks).forEach((key) => {
          patchBlock(key);
        });
        return;
      }

      if (isAnyOf(blocksActions.incVersion)(action)) {
        const ids = action.payload;
        ids.forEach((id) => {
          const block = getState().blocks[id];
          if (block) {
            patchBlock(id);
          }
        });
        return;
      }

      // #endregion

      // #region spaces
      if (isAnyOf(CREATE_SPACE)(action)) {
        spacesOperation.create(action.payload.space);
        return;
      }

      if (isAnyOf(UPDATE_SPACE)(action)) {
        spacesOperation.update(action.payload);
        return;
      }

      if (isAnyOf(ADD_MARK_NAME)(action)) {
        spacesOperation.addMarkName(action.payload);
        return;
      }

      if (isAnyOf(LIST_REMOVE_TEMPLATE)(action)) {
        spacesOperation.listRemoveTemplate(action.payload);
        return;
      }

      if (isAnyOf(LIST_BEFORE_TEMPLATE)(action)) {
        spacesOperation.listBeforeTemplate(action.payload);
        return;
      }

      if (isAnyOf(LIST_AFTER_TEMPLATE)(action)) {
        spacesOperation.listAfterTemplate(action.payload);
        return;
      }
      // #endregion

      // #region spaceViews
      if (isAnyOf(CREATE_SPACE_VIEW)(action)) {
        spaceViewsOperation.create(action.payload);
        return;
      }

      if (isAnyOf(DELETE_SPACE_VIEW)(action)) {
        spaceViewsOperation.delete(action.payload);
        return;
      }

      if (isAnyOf(UPDATE_SPACE_VIEW)(action)) {
        spaceViewsOperation.updateSpaceView(action.payload);
        return;
      }

      if (isAnyOf(LIST_REMOVE_FAVORITE)(action)) {
        spaceViewsOperation.listRemoveFavorite(action.payload);
        return;
      }

      if (isAnyOf(LIST_BEFORE_FAVORITE)(action)) {
        spaceViewsOperation.listBeforeFavorite(action.payload);
        return;
      }

      if (isAnyOf(LIST_AFTER_FAVORITE)(action)) {
        spaceViewsOperation.listAfterFavorite(action.payload);
        return;
      }

      // #endregion

      // #region discussions
      if (isAnyOf(discussionsActions.update)(action)) {
        keys(action.payload).forEach((key) => {
          patchBlock(key);
        });
        return;
      }

      if (isAnyOf(CREATE_DISCUSSION)(action)) {
        patchBlock(action.payload.discussion.uuid);
        return;
      }

      // #endregion

      // #region comments
      if (isAnyOf(commentsActions.update)(action)) {
        keys(action.payload).forEach((key) => {
          patchBlock(key);
        });
        return;
      }

      if (isAnyOf(CREATE_COMMENT)(action)) {
        patchBlock(action.payload.comment.uuid);
        return;
      }
      // #endregion

      // #region ui

      // 更新 blocks 和  cell
      if (isAnyOf(uiActions.update)(action)) {
        if (
          action.payload.selectedBlocks?.length === 0 &&
          action.payload.selectedCells?.length === 0
        ) {
          updateAllPrefixSelect();
          return;
        }

        if (action.payload.selectedBlocks) {
          cleanPrefixData(prefixSelectBlock);
          action.payload.selectedBlocks.forEach(nextSelectBlock);
        }

        if (action.payload.selectedCells) {
          cleanPrefixData(prefixSelectCell);
          action.payload.selectedCells.forEach(nextSelectCell);
        }
      }

      if (isAnyOf(uiActions.updateSelectBlocks)(action)) {
        if (action.payload.length) {
          cleanPrefixData(prefixSelectCell);
        }
        cleanPrefixData(prefixSelectBlock);
        action.payload.forEach(nextSelectBlock);
      }

      // 操作单条数据
      if (isAnyOf(uiActions.addSelectBlock, uiActions.removeSelectBlock)(action)) {
        nextSelectBlock(action.payload);
      }

      // 更新 cell
      if (isAnyOf(uiActions.updateSelectCells)(action)) {
        if (action.payload.length) {
          cleanPrefixData(prefixSelectBlock);
        }
        cleanPrefixData(prefixSelectCell);
        action.payload.forEach(nextSelectCell);
      }

      // #endregion
    };

    next(action);

    if (OPEN_RX) {
      runPatch();
    }
  };
};
