import { cx } from '@flowus/common/cx';
import type { ModalSchema } from '@flowus/common/next-modal';
import { useOpenModal } from '@flowus/common/next-modal';
import type { PermissionDTO, SegmentDTO } from '@next-space/fe-api-idl';
import { PermissionType, TextType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useState } from 'react';
import { Icon } from 'src/common/components/icon';
import type { SegmentDTOExt } from 'src/components/editor-person-list';
import { EditorPersonList } from 'src/components/editor-person-list';
import { useIsGuest } from 'src/hooks/share/use-permission-utils';
import { useCurrentSpace } from 'src/hooks/space';
import { getUserName } from 'src/hooks/user/use-remark-name';

interface Props {
  userPermissions: PermissionDTO[];
  onSelect: (permission: PermissionDTO[]) => void;
}

export const RemindPerson: FC<Props> = (props) => {
  const openModal = useOpenModal();
  const space = useCurrentSpace();
  const isGuest = useIsGuest();
  const combineName = props.userPermissions.reduce((pre, cur) => {
    if (cur.userId && cur.type === PermissionType.USER) {
      const username = getUserName(cur.userId);
      if (username) {
        if (pre) pre += ',';
        pre += username;
      }
    } else if (cur.type === PermissionType.GROUP) {
      const permissionGroup = space.permissionGroups?.find((v) => v.id === cur.groupId);
      if (permissionGroup) {
        if (pre) pre += ',';
        pre += permissionGroup.name;
      }
    } else {
      if (pre) pre += ',';
      pre += '全体成员';
    }
    return pre;
  }, '');

  const userSegments = props.userPermissions.map((v) => {
    const isUser = v.type === PermissionType.USER;
    return {
      type: TextType.USER,
      permissionType: v.type,
      uuid: isUser ? v.userId : v.groupId,
      text: isUser
        ? getUserName(v.userId ?? '')
        : space.permissionGroups?.find((o) => o.id === v.groupId)?.name,
      enhancer: {},
    } as SegmentDTOExt;
  });

  const openPersonList = (event: React.MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'right',
      content: (_) => (
        <PersonListDropdown
          defaultValue={userSegments}
          onUpdate={(segments) => {
            if (!segments) segments = [];
            const permissionGroup = segments.map((v) => {
              return {
                type: v.permissionType,
                userId: v.permissionType === PermissionType.USER ? v.uuid : '',
                groupId: v.permissionType === PermissionType.GROUP ? v.uuid : '',
              } as PermissionDTO;
            });
            props.onSelect(permissionGroup);
          }}
          onCloseModal={_.onCloseModal}
        />
      ),
    });
  };
  return (
    <div
      className={cx('flex items-center h-10 my-1 px-4 justify-between', {
        'opacity-30': isGuest,
      })}
    >
      <div className="flex items-center">
        <Icon name="IcMenuPerson" size={'middle'} />
        <span className="text-t2 ml-2">提醒成员</span>
      </div>
      <div
        className="flex items-center cursor-pointer"
        onClick={isGuest ? undefined : openPersonList}
      >
        <span className="text-t2 ml-2 mr-2 text-ellipsis text-grey3 max-w-[136px]">
          {combineName || '无'}
        </span>
        <Icon name="IcArrow" size="xxxsmall" />
      </div>
    </div>
  );
};
const PersonListDropdown: FC<{
  defaultValue?: SegmentDTO[];
  onUpdate: (value?: SegmentDTOExt[]) => void;
  onCloseModal: ModalSchema.CloseModalType;
}> = ({ defaultValue, onUpdate, onCloseModal }) => {
  const [value, setValue] = useState(defaultValue);
  return (
    <div className="next-modal">
      <EditorPersonList
        value={value}
        onClose={() => onCloseModal()}
        onUpdate={(v) => {
          setValue(v);
          onUpdate(v);
        }}
      />
    </div>
  );
};
