import { LOCAL_LNG } from '@flowus/common/const';
import { AggregationAction, CollectionSchemaType } from '@next-space/fe-api-idl';

export const commonAction = [
  { type: AggregationAction.NONE, title: '不展示' },
  { type: AggregationAction.COUNT_ALL, title: '记录数' },
  { type: AggregationAction.COUNT_VALUE, title: '值数' },
  { type: AggregationAction.COUNT_UNIQUE_VALUE, title: '唯一值' },
  { type: AggregationAction.COUNT_EMPTY, title: LOCAL_LNG.countEmpty },
  { type: AggregationAction.COUNT_NOT_EMPTY, title: LOCAL_LNG.countNotEmpty },
  { type: AggregationAction.PERCENT_EMPTY, title: '未填写占比' },
  { type: AggregationAction.PERCENT_NOT_EMPTY, title: '已填写占比' },
];

const dateAction = [
  ...commonAction,
  { type: AggregationAction.EARLIEST_DATE, title: '最早' },
  { type: AggregationAction.LATEST_DATE, title: '最晚' },
  { type: AggregationAction.DATE_RANGE, title: '日期范围' },
];

const checkboxAction = [
  { type: AggregationAction.NONE, title: '不展示' },
  { type: AggregationAction.COUNT_ALL, title: '记录数' },
  { type: AggregationAction.CHECKED, title: '已完成' },
  { type: AggregationAction.UNCHECKED, title: '未完成' },
  { type: AggregationAction.PERCENT_CHECKED, title: '已完成占比' },
  { type: AggregationAction.PERCENT_UNCHECKED, title: '未完成占比' },
];

const numberAction = [
  ...commonAction,
  { type: AggregationAction.SUM, title: '求和' },
  { type: AggregationAction.AVERAGE, title: '平均值' },
  { type: AggregationAction.MEDIAN, title: '中位数' },
  { type: AggregationAction.MIN, title: '最小' },
  { type: AggregationAction.MAX, title: '最大' },
  { type: AggregationAction.RANGE, title: '范围' },
];

export const MenuItems: Record<
  string,
  {
    type: AggregationAction;
    title: string;
  }[]
> = {
  [CollectionSchemaType.TITLE]: commonAction,
  [CollectionSchemaType.TEXT]: commonAction,
  [CollectionSchemaType.SELECT]: commonAction,
  [CollectionSchemaType.MULTI_SELECT]: commonAction,
  [CollectionSchemaType.NUMBER]: numberAction,
  [CollectionSchemaType.CHECKBOX]: checkboxAction,
  [CollectionSchemaType.DATE]: dateAction,
  [CollectionSchemaType.CREATED_AT]: dateAction,
  [CollectionSchemaType.UPDATED_AT]: dateAction,
  [CollectionSchemaType.CREATED_BY]: commonAction,
  [CollectionSchemaType.UPDATED_BY]: commonAction,
  [CollectionSchemaType.PERSON]: commonAction,
  [CollectionSchemaType.FILE]: commonAction,
  [CollectionSchemaType.URL]: commonAction,
  [CollectionSchemaType.EMAIL]: commonAction,
  [CollectionSchemaType.PHONE]: commonAction,
  [CollectionSchemaType.FORMULA]: commonAction,
  [CollectionSchemaType.RELATION]: commonAction,
  [CollectionSchemaType.ROLLUP]: commonAction,
};

export const AggregationActionUiMap = {
  none: '不展示',
  countAll: '记录数',
  countValue: '值数',
  countUniqueValue: '唯一值',
  countEmpty: LOCAL_LNG.countEmpty,
  countNotEmpty: LOCAL_LNG.countNotEmpty,
  percentEmpty: '未填写占比',
  percentNotEmpty: '已填写占比',
  sum: '求和',
  average: '平均值',
  median: '中位数',
  min: '最小',
  max: '最大',
  range: '范围',
  checked: __BUILD_IN__ ? 'Checked' : '已完成',
  unchecked: '未完成',
  percentChecked: '已完成占比',
  percentUnchecked: '未完成占比',
  earliestDate: '最早',
  latestDate: '最晚',
  dateRange: '日期范围',
  showUnique: '显示 唯一值',
  showOrigin: '显示 原始值',
  deduplication: '去重',
};

export const getRollupAggregation = (
  type: CollectionSchemaType,
  aggregation?: AggregationAction
) => {
  if (isShowOriginalValue(aggregation)) {
    return commonAction;
  }

  if (
    (type === CollectionSchemaType.DATE ||
      type === CollectionSchemaType.CREATED_AT ||
      type === CollectionSchemaType.UPDATED_AT) &&
    isDateAggregation(aggregation)
  ) {
    return dateAction;
  }

  return numberAction;
};

export const isShowOriginalValue = (aggregation?: AggregationAction) => {
  return (
    !aggregation ||
    aggregation === AggregationAction.SHOW_UNIQUE ||
    aggregation === AggregationAction.SHOW_ORIGIN
  );
};

export const isDateAggregation = (aggregation?: AggregationAction) => {
  return (
    aggregation === AggregationAction.EARLIEST_DATE ||
    aggregation === AggregationAction.LATEST_DATE ||
    aggregation === AggregationAction.DATE_RANGE
  );
};

export const isNumberAggregation = (aggregation?: AggregationAction) => {
  return (
    aggregation === AggregationAction.SUM ||
    aggregation === AggregationAction.AVERAGE ||
    aggregation === AggregationAction.MEDIAN ||
    aggregation === AggregationAction.MIN ||
    aggregation === AggregationAction.MAX ||
    aggregation === AggregationAction.RANGE
  );
};

export const isPercentAggregation = (aggregation?: AggregationAction) => {
  return (
    aggregation === AggregationAction.PERCENT_EMPTY ||
    aggregation === AggregationAction.PERCENT_NOT_EMPTY ||
    aggregation === AggregationAction.PERCENT_CHECKED ||
    aggregation === AggregationAction.PERCENT_UNCHECKED
  );
};

export const getRollupValueType = (aggregation?: AggregationAction) => {
  if (isDateAggregation(aggregation)) {
    return 'date';
  }

  if (
    isNumberAggregation(aggregation) ||
    aggregation === AggregationAction.COUNT_ALL ||
    aggregation === AggregationAction.COUNT_VALUE ||
    aggregation === AggregationAction.COUNT_UNIQUE_VALUE ||
    aggregation === AggregationAction.COUNT_EMPTY ||
    aggregation === AggregationAction.COUNT_NOT_EMPTY ||
    aggregation === AggregationAction.CHECKED ||
    aggregation === AggregationAction.UNCHECKED
  ) {
    return 'number';
  }

  return 'string';
};
