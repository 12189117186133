import { cx } from '@flowus/common/cx';
import { useAtomValue } from 'jotai';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { publicLink } from 'src/common/const';
import type { Template, TemplateCategory } from 'src/hooks/template/types';
import { useCustomTemplatesCategory } from 'src/hooks/template/use-custom-templates-category';
import { bizTracker } from 'src/utils/biz-tracker';
import { Category } from './category';
import { Empty } from './empty';
import { searchResultState } from './template-context';
import { TemplateItem } from './template-item';

interface Props {
  officialTemplates: TemplateCategory[];
  currentCategory: string;
  openTemplatePreview: (uuid: string) => void;
  targetId?: string;
}

export const RightPanel: FC<Props> = (props) => {
  const { officialTemplates, currentCategory, openTemplatePreview } = props;

  const searchResult = useAtomValue(searchResultState);
  const { templates = [], hasResult = false, keyword } = searchResult;
  const path = currentCategory.split('/').filter(Boolean);

  const firstCategoryData = officialTemplates.find((item) => item.name === path[0]);
  const secondCategoryData = firstCategoryData?.subGroups?.find((item) => item.name === path[1]);
  const [thirdCategory, setThirdCategory] = useState(
    secondCategoryData?.subGroups?.[0]?.name ?? ''
  );

  const customTemplates = useCustomTemplatesCategory();
  const isCustomTemplate = currentCategory === 'myTemplate';

  useEffect(() => {
    setThirdCategory(secondCategoryData?.subGroups?.[0]?.name ?? '');
  }, [currentCategory, secondCategoryData]);

  let categoryTemplates: Template[] = [];
  if (!isCustomTemplate && secondCategoryData) {
    categoryTemplates = secondCategoryData.templates ?? [];

    if (thirdCategory) {
      categoryTemplates =
        secondCategoryData.subGroups?.find((item) => item.name === thirdCategory)?.templates ?? [];
    }
  }

  return (
    <div className="relative w-full px-[30px] overflow-auto h-full">
      {keyword &&
        (hasResult ? (
          <div
            className="overflow-auto grid gap-4 pt-5"
            style={{ gridTemplateColumns: `repeat(auto-fill, 360px)` }}
          >
            {templates.map((item) => {
              return (
                <TemplateItem
                  targetId={props.targetId}
                  template={item}
                  key={item.pageId}
                  onPreviewOpen={(uuid) => {
                    openTemplatePreview(uuid);
                  }}
                />
              );
            })}
          </div>
        ) : (
          <div>
            <div className="flex flex-col items-center relative py-[100px] border-b">
              <div className="text-[70px] leading-[80px]">👀</div>
              <div className="text-t1-medium text-black">暂时没有找到符合条件的模板</div>
              <a
                target="_blank"
                href={publicLink.templateFeedback}
                hidden={__BUILD_IN__}
                className="text-t2 text-active_color cursor-pointer"
                rel="noreferrer"
              >
                点击反馈你的模板需求
              </a>
            </div>
            <p className="h-9 text-t1 mt-5 mb-2.5">试试这些模板吧</p>
            <div
              className="overflow-auto grid gap-4"
              style={{ gridTemplateColumns: `repeat(auto-fill, 360px)` }}
            >
              {templates.map((item) => {
                return (
                  <TemplateItem
                    targetId={props.targetId}
                    key={item.pageId}
                    template={item}
                    onPreviewOpen={(uuid) => openTemplatePreview(uuid)}
                  />
                );
              })}
            </div>
          </div>
        ))}

      {!keyword && (
        <>
          {isCustomTemplate && (
            <>
              {(customTemplates.subGroups ?? []).map((item) => item.templates ?? []).flat()
                .length === 0 ? (
                <Empty className="mt-5" />
              ) : (
                customTemplates.subGroups?.map((category) => {
                  return (
                    <Category
                      targetId={props.targetId}
                      preview={true}
                      key={category.name}
                      category={category}
                      onPreviewOpen={openTemplatePreview}
                    />
                  );
                })
              )}
            </>
          )}

          {!isCustomTemplate && secondCategoryData?.subGroups && (
            <ThirdCategoryName
              templateGroups={secondCategoryData.subGroups}
              setThirdCategory={setThirdCategory}
              thirdCategory={thirdCategory}
            />
          )}

          {!isCustomTemplate && (
            <div
              className="w-full pb-9 pt-5 grid gap-4"
              style={{ gridTemplateColumns: `repeat(auto-fill, 360px)` }}
            >
              {categoryTemplates.map((item) => (
                <TemplateItem
                  targetId={props.targetId}
                  key={item.pageId}
                  template={item}
                  onPreviewOpen={openTemplatePreview}
                />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

const ThirdCategoryName: FC<{
  templateGroups: TemplateCategory[];
  thirdCategory?: string;
  setThirdCategory: React.Dispatch<React.SetStateAction<string>>;
}> = ({ templateGroups, setThirdCategory, thirdCategory }) => {
  const shadow = useRef<HTMLDivElement>(null);
  const [showLength, setShowLength] = useState(0);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    setShowMore(false);

    const shadowNode = shadow.current;
    if (!shadowNode) return;

    const containerWidth = shadowNode.parentElement?.clientWidth;
    if (!containerWidth) return;

    let allWidth = 0;
    let index = Array.from(shadowNode.children).findIndex((node) => {
      allWidth += node.clientWidth + 20;
      return containerWidth - allWidth < 80;
    });

    if (containerWidth - allWidth < 70) {
      index--;
    }

    if (index !== -1) {
      setShowLength(index + 1);
    } else {
      setShowLength(templateGroups.length);
    }
  }, [templateGroups]);

  if (templateGroups.length === 0) return null;

  return (
    <>
      <div className="flex flex-wrap pt-[15px] sticky top-0 bg-white2 z-10">
        {templateGroups.slice(0, showMore ? templateGroups.length : showLength)?.map((item) => {
          return (
            <button
              key={item.name}
              onClick={() => {
                bizTracker.event('template_category', { category_id: item.name });
                setThirdCategory(item.name);
              }}
              className={cx(
                'px-2 py-[5px] mr-5 rounded hover:bg-black_003 my-[5px] text-t2 text-grey2',
                thirdCategory === item.name && 'bg-black_006 text-t2-medium'
              )}
            >
              {item.name}
            </button>
          );
        })}

        {showLength !== templateGroups.length && (
          <button
            className="text-t2 text-grey3 ml-auto animate-hover px-2 flex items-center h-[30px] self-center"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? '收起' : '展开'}
            <Icon name="IcArrow" size="xxxsmall" className={cx('ml-1', showMore && 'rotate-180')} />
          </button>
        )}
      </div>

      <div
        className="flex pt-[15px] top-0 bg-white2 absolute w-[calc(100%-60px)] overflow-auto opacity-0"
        ref={shadow}
      >
        {templateGroups.map((item) => {
          return (
            <button
              key={item.name}
              className="px-2 py-[5px] mr-5 rounded hover:bg-black_003 my-[5px] text-t2 flex-shrink-0 text-grey2"
            >
              {item.name}
            </button>
          );
        })}
      </div>
    </>
  );
};
