import type { FC } from 'react';

interface Props {
  // 申诉 justice | 举报 report
  type: 'justice' | 'report';
  onCloseModal(): void;
  email?: string;
  success: boolean;
}

export const AppealResult: FC<Props> = ({ type, onCloseModal, success, email }) => {
  const IS_JUSTICE = type === 'justice';

  return (
    <div className="flex w-[400px] sm:w-full items-center flex-col p-7">
      <div className="text-7xl">{success ? '😀' : '😔'}</div>
      <div className="my-2.5 font-medium">
        {IS_JUSTICE &&
          (success ? '提交成功，预计 3 个工作日内完成申诉审核' : '审核中，你已申诉过该页面')}
        {!IS_JUSTICE && (success ? '举报已提交，预计12小时内受理' : '审核中，已举报过该页面')}
      </div>
      <div className="text-t2 text-center mb-7">
        <div>
          {IS_JUSTICE &&
            (success
              ? '申诉结果会以邮件形式发送至你的邮箱'
              : '72 小时内至多可提交 1 次申诉，请耐心等待复审结果')}
          {!IS_JUSTICE &&
            (success
              ? '我们会尽快进行人工审核，感谢你的支持'
              : '一个分享链接7天内仅能举报一次，请耐心等待复审结果，感谢你的支持')}
        </div>
        <div className="text-center text-active_color">{IS_JUSTICE && success && email}</div>
      </div>
      <div
        onClick={onCloseModal}
        className="w-full animate-click bg-black text-center text-t2 py-1.5 text-white1 rounded"
      >
        知道了
      </div>
    </div>
  );
};
