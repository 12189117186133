import type { SpaceSettingDTO } from '@next-space/fe-api-idl';
import { useCurrentSpace, useSpace } from './use-current-space';

export function useSpaceSecurityState<
  K extends keyof Pick<
    SpaceSettingDTO,
    'abandonedExport' | 'abandonedGuest' | 'abandonedPublicShare' | 'openWatermark'
  >
>(state: K, spaceId?: string) {
  const space = useSpace(spaceId);
  const currentSpace = useCurrentSpace();
  if (space.uuid) {
    if (space.planType !== 'team') return false;
    // @ts-ignore 不要动这里的代码
    return space?.setting?.openWatermark || space.openWatermark;
  }
  if (currentSpace.uuid) {
    if (currentSpace.planType !== 'team') return false;
    return currentSpace?.setting?.[state];
  }
}
