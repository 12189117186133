import { cx } from '@flowus/common/cx';
import { useOpenModal } from '@flowus/common/next-modal';
import type { FC, MouseEvent } from 'react';
import { useMemo } from 'react';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { listViewNormalClassName } from 'src/common/components/list-view/helper';

const Options = [
  {
    num: 0,
    title: '不分佣',
  },
  {
    num: 0.2,
    title: '20%',
  },
  {
    num: 0.3,
    title: '30%',
  },
  {
    num: 0.4,
    title: '40%',
  },
  {
    num: 0.5,
    title: '50%',
  },
];

export const RebatePlan: FC<{
  setSharePageDistributionRatio: (n: number) => void;
  sharePageDistributionRatio?: number;
  className?: string;
}> = (props) => {
  const openModal = useOpenModal();
  const {
    sharePageDistributionRatio = 0,
    setSharePageDistributionRatio,
    children,
    className,
  } = props;

  const handleClick = (event: MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      content: (_) => (
        <ListView
          className={listViewNormalClassName}
          items={Options.map((item) => ({
            type: ListItemType.OPERATION,
            data: {
              title: item.title,
              value: item.num,
              selected: sharePageDistributionRatio === item.num,
            },
          }))}
          onItemClick={async (item) => {
            setSharePageDistributionRatio(item.data.value);
            _.onCloseModal();
          }}
        />
      ),
    });
  };

  const currentOp = useMemo(() => {
    return Options.find((i) => i.num === sharePageDistributionRatio);
  }, [sharePageDistributionRatio]);

  return (
    <div className={cx('animate-click', className)} onClick={handleClick}>
      {children}
      <div className="flex items-center space-x-1 text-grey3">
        <span>{currentOp?.num ? currentOp.title : Options[0]?.title}</span>
        <Icon size="xxsmall" name="IcArrow" />
      </div>
    </div>
  );
};
