import type { UserAuthorizationType, UserDTO } from '@next-space/fe-api-idl';

/**展示的View类型 */
export enum ViewType {
  LOGIN = 0,
  FORGET_PASSWORD = 1,
  REGISTER = 2,
  BIND_PHONE = 3,
  WECHAT_LOGIN = 4,
  QQ_LOGIN = 5,
  COMPLETE = 6,
  JOIN_TEAM_SPACE = 7,
  /**h5专有 */
  LOGIN_VERIFY_CODE = 8,
  /** 海外专用*/
  EMAIL_LOGIN = 9,
  MULTI_LOGIN = 10,
}

export interface LoginProps {
  /**风格类型，lite表示h5或者弹窗相关 */
  styleType: 'default' | 'lite' | 'build-in-pc' | 'build-in-lite';
  /**注册需要的渠道号和邀请码 */
  promotionChannel?: string;
  inviteCode?: string;
  onRegister?: (user: UserDTO) => void;
  /**最后一步回调，这里回调之后表示要跳转到下一个页面 */
  onSuccess: (user: UserDTO, params?: { isAuto?: boolean }) => void;
  /**回调当前到哪个view了 */
  onStep?: (view: ViewType) => void;
  /**快速注册，只支持手机注册 */
  quickRegister?: boolean;
  /** 是否展示第三方登录，默认展示*/
  hideThirdPartLogin?: boolean;
  //是否需要跳到完善信息页，默认自动跳到完善信息页
  complete?: boolean;
  defaultViewState?: ViewState;
  autoLogin?: boolean;
  onFooterChange?: (show: boolean) => void;
  defaultTitle?: string;
  titleClassName?: string;
  inviteCodeRequired?: boolean;
}
/**view的状态 */
export interface ViewState {
  type: ViewType;
  props?: ViewProps;
}

/**账户状态 可注册/可登录/需要绑定手机/完善用户信息 */
export enum AccountStatus {
  CAN_SIGN = 0,
  CAN_LOGIN = 1,
  NEED_BIND_PHONE = 2,
  BIND_FAIL = 3, //绑定失败
}

export type SwitchViewTypeFun = (viewState: Partial<ViewState>) => void;

/**每个view的props */
export interface ViewProps {
  account?: string;
  password?: string;
  nickname?: string;
  newInviteCode?: string; //如果没传进来，用户可以自己输入
  accountStatus?: AccountStatus;
  //三方登录绑定需要的信息
  authorization?: {
    code: string;
    type: UserAuthorizationType;
  };
  userId?: string; //完善信息页用得到
  [key: string]: any;
  inviteCodeRequired?: boolean;
}
/**view的props和login的props合并 */
export interface CombineViewProps extends ViewProps, Readonly<Partial<LoginProps>> {
  switchViewType: SwitchViewTypeFun;
  onAuth?: (code: string) => void;
  defaultTitle?: string;
  isFromRegister?: boolean;
  bindWeChat?: boolean;
  inviteCodeRequired?: boolean;
}
