import type { FC, PropsWithChildren, RefObject } from 'react';
import React from 'react';
import type { Direction } from '../../hooks/use-control-select-list-element';
import { useControlSelectListElement } from '../../hooks/use-control-select-list-element';
import type { ListenPriority } from '../../utils/global-listener-helper';

interface Props {
  listenPriority?: ListenPriority;
  containerRef: RefObject<HTMLElement>;
  defaultIndex?: number;
  // 提供下面两个props会变成受控组件
  onSelectedActiveIndex?: (activeIndex: number) => void;
  activeIndex?: number;
  itemCount?: number; // 如果使用内置activeIndex模式，需要提供childrenCount
  customItem?: boolean; // 如果设置为true，则内部会寻找[list-item-custom=index]属性的element
  type?: ContainerType; // 类型，根据不同类型 方向键控制选中element的算法不一样
  spanCount?: number; // 如果是宫格展示的话，每行多少个
  customSelector?: string;
}
type ContainerType = 'list' | 'grid';
const INACTIVE_TAG = 'list-item-inactive';
const ITEM_TAG = 'list-item';
export const ITEM_CUSTOM_TAG = 'list-item-custom';
/**
 * 自带方向控制的scroller,请勿添加业务以及样式相关代码
 *
   example 1:
  <DirectionScroller onSelectedActiveIndex={(activeIndex)=>{
    //do sth
  }}>
    <div>...</div>
    <div>...</div>
    <div list-item-inactive >...</div>   ==>list-item-inactive表示这个item不响应active事件，比如分割线，置灰选项等
    <div >...</div>
  </DirectionScroller>


  example 2:
  <DirectionScroller itemCount={3}>
    {(activeIndex)=> {
    return <div>...</div>
    <div>...</div>
    <div list-item-inactive >...</div>   ==>list-item-inactive表示这个item不响应active事件，比如分割线，置灰选项等
    <div >...</div>}
  </DirectionScroller>

  更高级的用法可以参考emoji的方向键实现:

 */
export const DirectionScroller: FC<PropsWithChildren<Props>> = (props) => {
  const {
    listenPriority,
    containerRef,
    onSelectedActiveIndex,
    defaultIndex = 0,
    customItem,
    type = 'list',
    spanCount,
    customSelector = '',
  } = props;

  const childCount = props.itemCount || React.Children.count(props.children);
  const findElement = (index: number) => {
    if (containerRef.current === null) {
      // eslint-disable-next-line no-console
      console.warn('direction containerRef props不能为null');
      return null;
    }

    if (customItem) {
      return (
        containerRef.current.querySelectorAll(`${customSelector} [${ITEM_CUSTOM_TAG}=""]`)[index] ??
        null
      );
    }
    return containerRef.current.querySelector(`${customSelector} [${ITEM_TAG}="${index}"]`) ?? null;
  };
  const findNextIndex = (currentIndex: number, direction: Direction): number => {
    const nextIndex = computeNextIndex(type, currentIndex, direction, spanCount, childCount);
    if (nextIndex === -1) return nextIndex;

    const element = findElement(nextIndex);

    if (!element) {
      // 如果找不到说明nextIndex不在范围内，超标了，直接循环到下一个index
      return findNextIndex(direction === 'up' || direction === 'left' ? childCount : -1, direction);
    }
    // 如果这个element不支持active，就继续往下找
    if (element.hasAttribute(INACTIVE_TAG)) {
      return findNextIndex(nextIndex, direction);
    }
    // 如果支持就直接返回index
    return nextIndex;
  };
  const [selectedIndex, setSelectedIndex] = useControlSelectListElement(
    childCount,
    findElement,
    findNextIndex,
    listenPriority,
    {
      scrollContainer: containerRef,
      onSelectedActiveIndex,
      defaultActiveIndex: defaultIndex,
    }
  );
  let child = props.children;
  if (typeof props.children === 'function') {
    child = props.children(selectedIndex, setSelectedIndex);
    if (!customItem) {
      // 如果不是自定义才给这个element一个属性
      child = React.Children.map(child, (c, index) => {
        if (React.isValidElement(c)) {
          return React.cloneElement(c, { [`${ITEM_TAG}`]: `${index}` });
        }
        return null;
      });
    }
  } else {
    if (!customItem) {
      // 如果不是自定义才给这个element一个属性
      child = React.Children.map(props.children, (c, index) => {
        if (React.isValidElement(c)) {
          return React.cloneElement(c, { [`${ITEM_TAG}`]: `${index}` });
        }
        return null;
      });
    }
  }
  if (type === 'grid' && spanCount === undefined) {
    throw Error('type is "grid", spanCount props must not be undefined');
  }

  return <>{child}</>;
};
/**
 * TODO:宫格屏的上下键操作，在每个分组最后一行不满的情况下，逻辑还有待完善
 */
const computeNextIndex = (
  type: ContainerType,
  currentIndex: number,
  direction: Direction,
  spanCount?: number,
  totalCount?: number
) => {
  let nextIndex = -1;
  const isUp = direction === 'up';
  switch (type) {
    case 'list': {
      // 如果是list
      if (direction === 'left' || direction === 'right') return -1;
      nextIndex = isUp ? currentIndex - 1 : currentIndex + 1;
      if (isUp && currentIndex === 0) {
        nextIndex = totalCount ?? nextIndex;
      }
      break;
    }
    case 'grid': {
      // 如果是宫格类型
      if (spanCount === undefined || totalCount === undefined) return -1;
      if (spanCount >= totalCount) {
        if (isUp) {
          direction = 'left';
        } else if (direction === 'down') {
          direction = 'right';
        }
      }
      switch (direction) {
        case 'left':
        case 'right': {
          nextIndex = direction === 'left' ? currentIndex - 1 : currentIndex + 1;
          break;
        }
        case 'up':
        case 'down': {
          nextIndex = isUp ? currentIndex - spanCount : currentIndex + spanCount;
          break;
        }
        default:
      }
      break;
    }
    default:
      throw Error('what r u doing');
  }
  return nextIndex;
};
