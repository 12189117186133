import { Deferred } from '@flowus/common/async';
import { SpacePlanType } from '@flowus/shared';
import { throttle } from 'lodash-es';
import { useCallback, useRef } from 'react';
import { message } from 'src/common/components/message';
import { useOpenModal } from 'src/common/components/next-modal';
import { useOpenLogin } from 'src/components/empty/open-login';
import { useOnceAnyDay } from 'src/components/header-tips-bar';
import { LocalStorageKey } from 'src/const/local-storage-state-key';
import { PRODUCT_NAME } from 'src/const/title';
import { cache } from 'src/redux/store';
import { judgeSharePage, useGetPageId } from 'src/utils/getPageId';
import { useBlock } from '../block/use-block';
import { useIsNeedPay } from '../page/use-page-meta';
import { canCopyToSpace, useCopyToSpace } from '../share/use-copy-to-space';
import { useSpace } from '../space';
import { getIsProSpace } from '../space/use-is-pro-space';
import { getCurrentUser } from '../user';

const useOpenLimitMessage = () => {
  const copyToSpace = useCopyToSpace();
  const pageId = useGetPageId();

  return useCallback(() => {
    message.success({
      key: 'file-down-limit',
      duration: 5000,
      content: (
        <>
          拷贝页面永久保存文件内容、全速下载
          <span
            className="text-active_color animate-click mx-1"
            onClick={() => copyToSpace(pageId)}
          >
            拷贝到空间
          </span>
        </>
      ),
    });
  }, [copyToSpace, pageId]);
};

export const useCheckViewLimit = () => {
  const pageId = useGetPageId();
  const onceOneDay = useOnceAnyDay(LocalStorageKey.FILE_VIEW_LIMIT, { endDay: 1 });

  return useCallback(() => {
    const page = cache.blocks[pageId];
    const spaceId = page?.spaceId;
    const isShare = judgeSharePage();

    // 不能复制的时候，忽略其他提速方式
    if (!onceOneDay.showOnce || !isShare || !canCopyToSpace(pageId)) {
      return false;
    }

    if (spaceId) {
      const isProSpace = getIsProSpace(spaceId);
      // 付费空间，直接看
      if (isProSpace) {
        return false;
      }
      // 免费空间，得弹窗
      return true;
    }
    return false;
  }, [onceOneDay.showOnce, pageId]);
};

export const useFileDownLimit = () => {
  const openModal = useOpenModal();
  const openLogin = useOpenLogin();
  const pageId = useGetPageId();
  const page = useBlock(pageId);
  const openLimitMessage = useOpenLimitMessage();
  const isNeedPay = useIsNeedPay(pageId);
  const space = useSpace(page?.spaceId);

  const callback = useCallback(() => {
    const defer = new Deferred();
    const isLogin = !!getCurrentUser().uuid;
    const isShare = judgeSharePage();

    // 不能复制的时候，忽略其他提速方式
    if (!isShare || !canCopyToSpace(pageId)) {
      defer.resolve();
      return defer.promise;
    }

    if (isNeedPay) {
      openModal.warning({
        title: '请先付费订阅购买',
      });

      defer.reject(new Error('cancel'));
    } else {
      if (space.planType === SpacePlanType.team) {
        defer.resolve();
      } else {
        // 未登录的情况
        if (!isLogin) {
          openModal.warning({
            colorType: 'active',
            title: `登录 ${PRODUCT_NAME} 账户后获取文件`,
            confirmText: '去登录',
            cancelText: '取消',
            confirm: () => {
              openLogin();
            },
            closeAfterCallBack: () => defer.reject(new Error('cancel')),
          });
        }

        // 已登录的情况
        if (isLogin) {
          openLimitMessage();
          defer.resolve();
        }
      }
    }

    return defer.promise;
  }, [isNeedPay, openLimitMessage, openLogin, openModal, pageId, space.planType]);
  return callback;
};

export const useFileViewLimit = () => {
  const pageId = useGetPageId();
  const openModal = useOpenModal();
  const onceOneDay = useOnceAnyDay(LocalStorageKey.FILE_VIEW_LIMIT, { endDay: 1 });
  const copyToSpace = useCopyToSpace();
  const checkViewLimit = useCheckViewLimit();

  return useCallback(async () => {
    const defer = new Deferred();
    const isLimit = checkViewLimit();

    // 不能复制的时候，忽略其他提速方式
    if (!isLimit) {
      defer.resolve();
      return defer.promise;
    }

    // 免费空间，得弹窗
    openModal.warning({
      colorType: 'active',
      title: '拷贝页面永久保存文件内容、全速浏览',
      confirmText: '直接查看',
      cancelText: '拷贝到空间',
      closeAfterCallBack: () => defer.resolve(),
      cancel: () => {
        copyToSpace(pageId);
        defer.reject(new Error('cancel'));
      },
      confirm: () => {
        defer.resolve();
      },
    });

    onceOneDay.close();

    return defer.promise;
  }, [checkViewLimit, copyToSpace, onceOneDay, openModal, pageId]);
};

export const useWaitingLimit = () => {
  const isShare = judgeSharePage();
  const openLimitMessage = useOpenLimitMessage();
  const debounceRef = useRef(throttle(openLimitMessage, 30 * 1000));

  const waitTimer = useRef({
    timer: setTimeout(() => {}),
    start: () => {
      if (!isShare) return;
      waitTimer.current.timer = setTimeout(() => {
        debounceRef.current();
      }, 4 * 1000);
    },
    stop: () => {
      clearTimeout(waitTimer.current.timer);
    },
  });
  return waitTimer.current;
};
