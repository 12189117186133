const resourceMap: Record<string, Promise<void>> = {};

export const loadResource = (url: string, tagName: 'script' | 'link', async = false) => {
  if (resourceMap[url]) return resourceMap[url];

  resourceMap[url] = new Promise((resolve, reject) => {
    let tag: HTMLScriptElement | HTMLLinkElement;
    if (tagName === 'script') {
      tag = document.createElement(tagName) as HTMLScriptElement;
      tag.async = async;
      tag.src = url;
    } else {
      tag = document.createElement(tagName) as HTMLLinkElement;
      tag.href = url;
      tag.rel = 'stylesheet';
      tag.type = 'text/css';
    }

    tag.onload = () => resolve();
    tag.onerror = (event) => reject(event);
    document.head.insertBefore(tag, document.head.firstChild);
  });

  return resourceMap[url];
};
