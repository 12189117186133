import { getFormatImageUrl } from '@flowus/common';
import { afterExpire } from '@flowus/common/hooks/url-fetcher';
import { getOssName, ossImageSnapshotUrl } from '@flowus/common/url';
import type { RefObject } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { compressImageSupport } from 'src/common/utils/url-utils';
import { getFileNameInfo } from 'src/utils/file';
import { usePickBlock } from 'src/utils/pick-block';
import { urlFetcher } from 'src/utils/url-fetcher';
import isURL from 'validator/lib/isURL';

export const useCoverImageUrl = (blockId: string, containerRef?: RefObject<HTMLDivElement>) => {
  const block = usePickBlock(blockId, ['data'], ['cover', 'coverPos', 'localUrl', 'size']);
  const [imageUrl, setImageUrl] = useState(block?.data.localUrl ?? '');
  const cover = useMemo(() => {
    const oss = block?.data.cover ?? '';
    return getOssName(oss) || oss;
  }, [block?.data.cover]);

  useEffect(() => {
    if (imageUrl) return;
    if (isURL(cover)) {
      setImageUrl(cover);
      return;
    }

    if (block?.data.localUrl) {
      setImageUrl(block.data.localUrl);
    } else {
      void urlFetcher.fetchImageUrl({ blockId, ossName: cover }).then((url) => {
        const { extName } = getFileNameInfo(cover);
        const width = Math.max(
          Math.floor(containerRef?.current?.getBoundingClientRect().width ?? 100),
          800
        );
        if (compressImageSupport.test(extName)) {
          const image = ossImageSnapshotUrl(url, width);
          setImageUrl(getFormatImageUrl(image, extName));
        } else {
          setImageUrl(getFormatImageUrl(url, extName));
        }
      });
      return afterExpire(() => {
        setImageUrl('');
      });
    }
  }, [cover, block?.data.localUrl, blockId, containerRef, imageUrl]);

  return imageUrl;
};
