import {
  differenceInDays,
  differenceInYears,
  format,
  isThisYear,
  isToday,
  isYesterday,
} from 'date-fns';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT, TIME_FORMAT } from '../const';

export function formatTimestamp(timestamp: number): string {
  return dayjs(timestamp).format(DATE_TIME_FORMAT);
}

export function formatDotTimestamp(timestamp: number): string {
  return dayjs(timestamp).format('YYYY.MM.DD');
}

export function formatFileTimestamp(timestamp: number): string {
  return dayjs(timestamp).format('YYYY-M-D H:mm');
}

/**
 * @params time 毫秒
 */
export function covertToHumanReadableTime(time: number) {
  if (time <= 0) return '刚刚';
  // const desc = ['刚刚', '分钟前', '小时前', '天前', '个月前', '年前'];
  const timestamp = time * 1.0;
  const second = timestamp / 1000;
  // if (second <= 60) return '刚刚';
  const minute = Math.floor(second / 60);
  if (minute === 0) return '刚刚';
  const hour = Math.floor(minute / 60);
  if (hour === 0) return [minute.toFixed(0), '分钟前'].join(' ');
  const day = Math.floor(hour / 24);
  if (day === 0) return [hour.toFixed(0), '小时前'].join(' ');
  const month = Math.floor(day / 30);
  if (month === 0) return [day.toFixed(0), '天前'].join(' ');
  const year = Math.floor(month / 12);
  if (year === 0) return [month.toFixed(0), '个月前'].join(' ');
  return [year.toFixed(0), '年前'].join(' ');
}

/** 转换时间格式 - block最后编辑时间 */
export const convertToLastEditTime = (time?: number) => {
  if (!time) return null;
  const now = Date.now();
  if (isToday(time)) {
    return ['今天', format(time, TIME_FORMAT)].join(' ');
  } else if (isYesterday(time)) {
    return ['昨天', format(time, TIME_FORMAT)].join(' ');
  } else if (differenceInDays(time, now) === -2) {
    return ['前天', format(time, TIME_FORMAT)].join(' ');
  } else if (differenceInYears(time, now) === 0) {
    if (isThisYear(time)) {
      // 只要不是今年的就带上年份
      return format(time, 'MM月dd日 HH:mm');
    }
  }
  // 这里必须用yyyy和dd.大写不行,为什么之前flowus没有报错
  // https://github.com/date-fns/date-fns/blob/main/docs/unicodeTokens.md
  return format(time, 'yyyy年MM月dd日');
};
