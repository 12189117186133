import { cx } from '@flowus/common/cx';
import type { BlockDTO, PageActivityEditDTO } from '@next-space/fe-api-idl';
import { css } from 'otion';
import type { FC } from 'react';
import { useCallback } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { LoadingContainer } from 'src/common/components/loading-container';
import { request } from 'src/common/request';
import { blocksActions } from 'src/redux/reducers/blocks';
import { commentsActions } from 'src/redux/reducers/comments';
import { discussionsActions } from 'src/redux/reducers/discussions';
import { dispatch } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { updatePageActivities, usePageActivity } from 'src/services/page-activities/hook';
import { useGetPageId } from 'src/utils/getPageId';
import { useFinalValue, useScheduleUpdate } from '@flowus/common/hooks/react-utils';
import { BlockFeed } from './feeds/block-feed';
import { CollectionPropertyFeed } from './feeds/collection-property-feed';
import {
  CollectionViewChangedFeed,
  CollectionViewCreatedFeed,
  CollectionViewDeletedFeed,
} from './feeds/collection-view-feed';
import { CommentFeed } from './feeds/comment-feed';
import { SnapshotContext } from './feeds/feed-template';
import {
  PageCreatedFeed,
  PageDeletedFeed,
  PageLockedFeed,
  PageRestoredFeed,
  PageUnlockedFeed,
} from './feeds/page-feed';
import { PermissionFeed, PermissionRestrictionFeed } from './feeds/permission-feed';

export const PageFeedList: FC = () => {
  const state = useFinalValue(() => ({
    lastPageActivityId: undefined as string | undefined,
    initialLoaded: false,
    pageActivities: [] as {
      activityId: string;
      historyId?: string | undefined;
      historyCreatedAt?: number | undefined;
    }[],
    blocks: {} as Record<string, BlockDTO>,
    loading: false,
    more: true,
  }));
  const scheduleUpdate = useScheduleUpdate();
  const docId = useGetPageId();

  const loadMore = useCallback(async () => {
    /* eslint-disable require-atomic-updates */
    if (state.loading) return;
    state.loading = true;
    scheduleUpdate();
    const { lastPageActivityId: lastActivityId } = state;
    const result = await request.editor.getDocActivities(docId, lastActivityId);

    if (result.results.length >= 1) {
      const { results } = result;
      state.lastPageActivityId = results[results.length - 1]?.activityId;
      if (lastActivityId === undefined) {
        state.pageActivities = [];
      }
      state.pageActivities.push(...results);
    }

    if (result.recordMap.activities) {
      updatePageActivities(result.recordMap.activities);
    }
    if (result.recordMap.blocks) {
      dispatch(
        blocksActions.update({
          blocks: result.recordMap.blocks as Record<string, NextBlock>,
        })
      );
    }
    if (result.recordMap.discussions) {
      dispatch(discussionsActions.update(result.recordMap.discussions));
    }
    if (result.recordMap.comments) {
      dispatch(commentsActions.update(result.recordMap.comments));
    }
    state.initialLoaded = true;
    state.loading = false;
    state.more = result.more;
    scheduleUpdate();
  }, [docId, scheduleUpdate, state]);

  const [sentryRef] = useInfiniteScroll({
    loading: state.loading,
    hasNextPage: state.more,
    onLoadMore: loadMore,
  });

  return (
    <div
      className={cx(
        'h-full flex flex-col',
        css({
          selectors: {
            '&:empty::after': {
              display: 'block',
              content: '暂无动态',
              position: 'absolute',
              fontSize: '14px',
              color: 'var(--grey4)',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            },
          },
        })
      )}
    >
      {state.pageActivities.flatMap((pageActivity) => {
        return (
          <SnapshotContext.Provider key={pageActivity.activityId} value={pageActivity.historyId}>
            <PageActivity pageActivityId={pageActivity.activityId} />
          </SnapshotContext.Provider>
        );
      })}
      {(state.loading || state.more) && (
        <div className="flex-1 min-h-[60px] overflow-hidden" ref={sentryRef}>
          <LoadingContainer />
        </div>
      )}
    </div>
  );
};

export const PageActivity: FC<{ pageActivityId: string }> = ({ pageActivityId }) => {
  const pageActivity = usePageActivity(pageActivityId);
  if (pageActivity == null) return null;
  if (pageActivity.type === 'blockEdited') {
    return <BlockFeed pageActivity={pageActivity} />;
  }
  if (pageActivity.type === 'permissionsEdited') {
    return <PermissionFeed pageActivity={pageActivity} />;
  }
  if (
    pageActivity.type === 'permissionRestrictionCreated' ||
    pageActivity.type === 'permissionRestrictionDeleted'
  ) {
    return <PermissionRestrictionFeed pageActivity={pageActivity} />;
  }
  if (pageActivity.type === 'commented') {
    return <CommentFeed pageActivity={pageActivity} />;
  }
  return (
    <>
      {pageActivity.edits.map((edit, index) => (
        <RenderPageFeed key={index} edit={edit} />
      ))}
    </>
  );
};

export const RenderPageFeed: FC<{ edit: PageActivityEditDTO }> = ({ edit }) => {
  switch (edit.type) {
    case 'blockCreated':
    case 'blockChanged':
    case 'blockDeleted':
      return null;
    case 'pageLocked':
      return <PageLockedFeed edit={edit} />;
    case 'pageUnlocked':
      return <PageUnlockedFeed edit={edit} />;
    case 'pageCreated':
      return <PageCreatedFeed edit={edit} />;
    case 'pageDeleted':
      return <PageDeletedFeed edit={edit} />;
    case 'pageRestored':
      return <PageRestoredFeed edit={edit} />;
    case 'collectionViewCreated':
      return <CollectionViewCreatedFeed edit={edit} />;
    case 'collectionViewChanged':
      return <CollectionViewChangedFeed edit={edit} />;
    case 'collectionViewDeleted':
      return <CollectionViewDeletedFeed edit={edit} />;
    case 'collectionPropertyCreated':
    case 'collectionPropertyChanged':
    case 'collectionPropertyDeleted':
      return <CollectionPropertyFeed edit={edit} />;
    default:
      return null;
  }
};
