import { Compare } from '@flowus/common';
import { CollectionSchemaType } from '@next-space/fe-api-idl';
import { useEffect } from 'react';
import { useBitable } from 'src/bitable/context';
import { Selector } from 'src/editor/editor/uikit/selector';
import { useCreateDateProperty } from 'src/hooks/block/use-convert-collection-view';
import { getTablePropertyValue } from 'src/hooks/collection-view/get-property-value';
import {
  createRecordWalker,
  useBiTableGroups,
  useProvideRecordWalker,
} from 'src/hooks/collection-view/table-groups';
import { getState } from 'src/redux/store';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { CalenderWrapper } from './calender';
import './style.css';

export const CalendarMonthView = () => {
  const { viewId, collectionId, readonly, managerReadonly, embed } = useBitable();
  const createDateProperty = useCreateDateProperty();
  const biTableGroups = useBiTableGroups(viewId);
  const walker = useObservableStore(
    (state) => {
      const { collectionViews } = state;
      const view = collectionViews[viewId];
      if (!view) return createRecordWalker(() => null);
      const { calendarBy = '' } = view.format;
      const cmp = Compare.by((recordId: string) => {
        const block = getState().blocks[recordId];
        return getTablePropertyValue(block, calendarBy) as number | undefined;
      }, Compare.nullsLast(Compare.number));
      return createRecordWalker((recordId) => {
        if (biTableGroups == null) return null;
        if (biTableGroups.withoutValidGroup) {
          const records = biTableGroups.sortedRecordIds.slice(0);
          records.sort(cmp);
          const index = records.indexOf(recordId);
          if (index >= 0) {
            return { records, index };
          }
          return null;
        }
        for (const group of biTableGroups.visibleGroups) {
          const records = group.recordIds.slice(0);
          records.sort(cmp);
          const index = records.indexOf(recordId);
          if (index >= 0) {
            return { records, index };
          }
        }
        return null;
      });
    },
    [viewId, biTableGroups]
  );
  useProvideRecordWalker(viewId, walker);
  const needUpdate = useObservableStore(
    ({ blocks, collectionViews }) => {
      const collection = blocks[collectionId];
      const view = collectionViews[viewId];
      const schema = collection?.data.schema ?? {};
      if (!view) return;

      const { calendarBy = '', calendarByEnd = '' } = view.format;

      const needUpdate = {
        updateCalendarBy: false,
        updateCalendarByEnd: false,
      };

      const calendarByType = schema[calendarBy]?.type;
      const calendarByEndType = schema[calendarByEnd]?.type;
      if (
        calendarByType !== CollectionSchemaType.DATE &&
        calendarByType !== CollectionSchemaType.CREATED_AT &&
        calendarByType !== CollectionSchemaType.UPDATED_AT
      ) {
        needUpdate.updateCalendarBy = true;
      }

      if (
        calendarByEndType !== CollectionSchemaType.DATE &&
        calendarByEndType !== CollectionSchemaType.CREATED_AT &&
        calendarByEndType !== CollectionSchemaType.UPDATED_AT
      ) {
        needUpdate.updateCalendarByEnd = true;
      }

      return needUpdate;
    },
    [collectionId, viewId]
  );

  useEffect(() => {
    if (readonly || managerReadonly) return;
    if (!needUpdate) return;

    if (needUpdate.updateCalendarBy || needUpdate.updateCalendarByEnd) {
      createDateProperty(viewId);
    }
  }, [createDateProperty, needUpdate, readonly, viewId, managerReadonly]);

  if (!needUpdate) return null;

  if (needUpdate.updateCalendarBy || needUpdate.updateCalendarByEnd) {
    if (readonly || managerReadonly) {
      return (
        <div className="text-t2 h-[35px] flex items-center pl-2">
          当前视图无有效日期属性，无法展示
        </div>
      );
    }
    return null;
  }

  if (!biTableGroups?.sortedRecordIds) return null;
  return (
    <div className="min-w-[300px]">
      <CalenderWrapper embed={!!embed} />
      {!readonly && !embed && <Selector type="collection" />}
    </div>
  );
};
