import { cx } from '@flowus/common/cx';
import type { CSSProperties, FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { ActivityIds } from 'src/hooks/activities/activity-ids';
import { ActivitiesListType } from 'src/hooks/activities/use-activity';
import { useUpdateTask } from 'src/hooks/activities/use-update-task';
import { useTransaction } from 'src/hooks/use-transaction';
import { useIsFavoritePage } from 'src/hooks/user';
import * as FavoriteManager from 'src/redux/managers/favorite';
import { $currentUserCache } from 'src/services/user/current-user';
import { bizTracker } from 'src/utils/biz-tracker';

export const Favorite: FC<{ uuid: string; style?: CSSProperties; className?: string }> = ({
  uuid,
  style,
  className,
}) => {
  const updateTask = useUpdateTask();
  const isFavorite = useIsFavoritePage(uuid);
  const transaction = useTransaction();

  return (
    <Tooltip
      popup={isFavorite ? '取消快速访问' : '添加到快速访问'}
      className={cx('flex items-center justify-center animate-hover flex-shrink-0', className)}
      onClick={() => {
        if (isFavorite) {
          bizTracker.event('favorite_cancel_pageclick');
        } else {
          bizTracker.event('favorite_pageclick');
        }
        transaction(() => {
          isFavorite
            ? FavoriteManager.remove(uuid, {
                parentId: $currentUserCache.currentSpaceViewId,
              })
            : FavoriteManager.add(uuid, {
                first: true,
                parentId: $currentUserCache.currentSpaceViewId,
              });
        });

        if (!isFavorite) {
          void updateTask(ActivityIds.ADD_TO_FAVORITE, ActivitiesListType.advancedList);
        }
      }}
    >
      <Icon
        name={isFavorite ? 'IcQuickAccessMiddleBold' : 'IcQuickAccessMiddleBold'}
        size="small"
        style={style}
        boxSize="large"
        className={cx(isFavorite && 'text-yellow')}
      />
    </Tooltip>
  );
};
