import { cx } from '@flowus/common/cx';
import { BlockType, CollectionViewType } from '@next-space/fe-api-idl';
import { filter } from 'lodash-es';
import type { FC, MutableRefObject } from 'react';
import { useEffect, useRef, useState } from 'react';
import { TableViewMap, ViewIconMap } from 'src/bitable/bitable-manager/const';
import { DirectionScroller } from 'src/common/components/direction-scroller';
import type { IconName } from 'src/common/components/icon';
import { Icon } from 'src/common/components/icon';
import { useOpenModal } from 'src/common/components/next-modal';
import { PRIORITY_PORTAL } from 'src/common/utils/global-listener-helper';
import { BlockDefaultIcon } from 'src/components/block-default-icon';
import { IconTrigger } from 'src/components/icon-trigger';
import { ActivityIds } from 'src/hooks/activities/activity-ids';
import { ActivitiesListType } from 'src/hooks/activities/use-activity';
import { useUpdateTask } from 'src/hooks/activities/use-update-task';
import { useDocToBitable } from 'src/hooks/block/use-doc-to-bitable';
import { useEnableAI } from 'src/hooks/block/use-enable-AI';
import { fillRecordWithTemplate } from 'src/hooks/block/use-insert-record';
import { useInsertTemplateRecord } from 'src/hooks/block/use-insert-template-record';
import { useAvailableTemplatePages, useIsRecord } from 'src/hooks/block/use-is-record';
import { useFocusEditableByBlockId } from 'src/hooks/editor/use-focus-by-id';
import { useFetchPage } from 'src/hooks/page';
import { useOpenInRight } from 'src/hooks/page/use-open-in-right';
import { useTransaction } from 'src/hooks/use-transaction';
import { useAddSubNode } from 'src/mind-map/hook/use-add-sub-node';
import { Modals } from 'src/modals';
import { addBlock } from 'src/redux/managers/block/add';
import { updateBlock } from 'src/redux/managers/block/update';
import { sequence } from 'src/utils/async-utils';
import { bizTracker } from 'src/utils/biz-tracker';
import { useGetPageId } from 'src/utils/getPageId';
import { SegmentPlainText } from 'src/views/main/aside/toc/helper';
import { AIEditorFrom, AIEditorScene } from './ai-editor/const';
import { useOpenAIEditor } from './ai-editor/use-open-ai-editor';
import { useClickBlankAreaCreateBlock } from './blank-area';
import { Import } from './import';
import { ResourceCenter } from 'src/views/main/resource-center';
import { PropertyValueSubject } from 'src/views/main/drawer/page-feeds/subject';

/** 没有任何内容的时候唯一的行内提示，以后会增加其他创建选项 */
export const PagePlaceholderContent: FC<{ uuid: string }> = ({ uuid }) => {
  const updateTask = useUpdateTask();
  const openModal = useOpenModal();
  const transaction = useTransaction();
  const docToBitable = useDocToBitable();
  const isRecord = useIsRecord(uuid);
  const { isTemplate, collectionId, templatePages } = useAvailableTemplatePages(uuid);
  const container = useRef() as MutableRefObject<HTMLDivElement>;
  const focusEditableAt = useFocusEditableByBlockId();
  const simulateClickBlankArea = useClickBlankAreaCreateBlock();
  const insertTemplatePage = useInsertTemplateRecord();
  const openInRight = useOpenInRight();
  const fetchPage = useFetchPage();
  const addSubNode = useAddSubNode();
  const pageId = useGetPageId();
  // 由于只会render一次，导致container挂不上监听。所以这里让他强制再render一次
  const [placeholder, setPlaceholder] = useState(<></>);
  const { enableAI } = useEnableAI();
  const openAIEditor = useOpenAIEditor();

  const createText = () => {
    transaction(() => {
      const id = addBlock({}, { parentId: uuid, last: true });
      focusEditableAt(id, 0);
    });
    void updateTask(ActivityIds.GUIDE_FIRST_TIME_CREATED_PAGE, ActivitiesListType.basicList);
    void updateTask(ActivityIds.CREATE_NEW_PAGE, ActivitiesListType.basicList);
  };

  const createFolder = () => {
    transaction(() => {
      updateBlock(uuid, { type: BlockType.FOLDER });

      // 积分任务
      void updateTask(ActivityIds.CREATE_FOLDER_AND_UPLOAD, ActivitiesListType.basicList, {
        step1: true,
      });
    });

    void updateTask(ActivityIds.GUIDE_FIRST_TIME_CREATED_FOLDER, ActivitiesListType.basicList);
  };
  const createMindMap = () => {
    transaction(() => {
      updateBlock(uuid, { type: BlockType.MIND_MAPPING_PAGE });
      addSubNode({ parentId: uuid, last: true }, pageId);
      addSubNode({ parentId: uuid, last: true }, pageId);
      addSubNode({ parentId: uuid, last: true }, pageId);
    });

    // 积分任务
    void updateTask(ActivityIds.CREATE_MIND_MAP, ActivitiesListType.advancedList);
  };

  const items = filter(
    [
      {
        title: '页面',
        icon: 'IcPageMiddle',
        group: false,
        onClick: () => {
          bizTracker.event('pagetype_select', { type_select: BlockType.PAGE });
          createText();
        },
      },
      {
        title: '让 AI 快捷起草',
        isHidden: !enableAI,
        icon: 'IcAi',
        // isBeta: true,
        group: false,
        onClick: () => {
          transaction(() => {
            const blockId = addBlock({}, { parentId: uuid, last: true });

            setTimeout(() => {
              const popcorn = document.querySelector(
                `[data-block-id="${blockId}"]`
              ) as HTMLElement | null;
              if (popcorn) {
                openAIEditor({
                  popcorn,
                  blockId,
                  from: AIEditorFrom.EmptyAi,
                  editorScene: AIEditorScene.PageEmpty,
                });
              }
            }, 0);
          });
        },
      },
      {
        title: '文件夹',
        icon: 'IcFolderMiddle',
        group: false,
        onClick: () => {
          bizTracker.event('pagetype_select', { type_select: BlockType.FOLDER });
          createFolder();
        },
      },
      {
        title: '思维导图',
        icon: 'IcMindmapMiddle',
        group: false,
        onClick: () => {
          bizTracker.event('pagetype_select', { type_select: BlockType.MIND_MAPPING_PAGE });
          createMindMap();
        },
      },
      {
        title: '从模板库选择创建',
        icon: 'IcTemplateNew',
        group: false,
        isHidden: __PRIVATE__,
        onClick: () => {
          openModal.modal({
            modalId: Modals.TEMPLATE_CENTER,
            content: () => <ResourceCenter onlyShowTab="template" from="page" targetId={pageId} />,
          });
        },
      },
      {
        title: '导入',
        icon: 'IcImportMiddle',
        group: false,
        isHidden: __PRIVATE__,
        onClick: () => {
          openModal.modal({
            content: () => <Import pageId={uuid} from="page" />,
          });
        },
      },
      {
        title: '多维表',
        group: true,
      },
      ...TableViewMap.map(({ type, name }) => ({
        title: name,
        icon: ViewIconMap[type],
        group: false,
        onClick: () => {
          bizTracker.event('pagetype_select', {
            type_select: BlockType.COLLECTION_VIEW_PAGE,
            view_type: type,
          });
          bizTracker.event('bitable_create', {
            from_scene: 'empty',
            view_type: type,
          });
          docToBitable(uuid, type);

          // 积分任务
          void updateTask(
            ActivityIds.GUIDE_FIRST_TIME_CREATED_COLLECTION,
            ActivitiesListType.basicList
          );

          switch (type) {
            case CollectionViewType.TABLE:
              void updateTask(ActivityIds.CREATE_BITABLE_EXPLORE, ActivitiesListType.basicList);
              break;
            case CollectionViewType.GALLERY:
              void updateTask(ActivityIds.CREATE_GALLERY_VIEW, ActivitiesListType.advancedList, {
                step1: true,
              });
              break;
            case CollectionViewType.CALENDAR:
              void updateTask(
                ActivityIds.GUIDE_FIRST_TIME_CALENDAR_VIEW,
                ActivitiesListType.advancedList
              );
              void updateTask(ActivityIds.CREATE_CALENDAR_VIEW, ActivitiesListType.advancedList);
              break;
            case CollectionViewType.TIMELINE:
              void updateTask(
                ActivityIds.GUIDE_FIRST_TIME_TIMELINE_VIEW,
                ActivitiesListType.advancedList
              );
              void updateTask(ActivityIds.CREATE_TIMELINE_VIEW, ActivitiesListType.advancedList);
              break;
            case CollectionViewType.FORM:
              void updateTask(
                ActivityIds.GUIDE_FIRST_TIME_COLLECTION_VIEW,
                ActivitiesListType.advancedList
              );
              void updateTask(ActivityIds.CREATE_FORM_VIEW, ActivitiesListType.advancedList);
              break;
            default:
          }
        },
      })),
    ] as {
      title: string;
      icon: string;
      group: boolean;
      isBeta?: boolean;
      isHidden?: boolean;
      onClick?: () => void;
    }[],
    (i) => !i.isHidden
  );

  useEffect(() => {
    setPlaceholder(
      <>
        {isRecord ? (
          // 多维表模板记录不允许使用自定义模板
          templatePages.length >= 1 ? (
            '，或从下方选择模板应用'
          ) : isTemplate ? (
            ''
          ) : (
            <>
              {'，或'}
              <span
                className="underline cursor-pointer underline-offset-2"
                onClick={(e) => {
                  e.stopPropagation();
                  openModal.modal({
                    modalId: Modals.TEMPLATE_CENTER,
                    content: () => (
                      <ResourceCenter onlyShowTab="template" from="record" targetId={pageId} />
                    ),
                  });
                }}
              >
                从模板库选择创建
              </span>
            </>
          )
        ) : (
          '，或从下方选择页面类型'
        )}
      </>
    );
  }, [isRecord, isTemplate, openModal, pageId, templatePages.length]);

  return (
    <div className="line-default text-grey4" ref={container}>
      <div className="my-0.5 px-0.5 mb-6 py-1.5 cursor-text" onClick={createText}>
        按回车开始编辑
        {!isTemplate && placeholder}
      </div>
      {isRecord && !isTemplate && templatePages.length >= 1 && (
        <>
          {templatePages.map((templatePageId) => {
            return (
              <div
                key={templatePageId}
                className={cx(
                  'flex items-center flex-1 p-1 rounded cursor-pointer h-9 hover:text-grey4 animate-hover'
                )}
                onClick={async () => {
                  await fetchPage(templatePageId);
                  const defers: (() => Promise<void>)[] = [];
                  transaction(
                    () => {
                      void fillRecordWithTemplate(uuid, templatePageId, defers);
                    },
                    { noThrottle: true }
                  );
                  void sequence(async () => {
                    await Promise.all(defers.map((it) => it()));
                  });
                  simulateClickBlankArea(uuid);
                }}
              >
                <IconTrigger
                  iconSize={18}
                  blockId={templatePageId}
                  className="w-5 h-5 mr-1"
                  placement="bottom-start"
                  defaultIcon={
                    <BlockDefaultIcon className="opacity-30" uuid={templatePageId} size="middle" />
                  }
                />
                <SegmentPlainText uuid={templatePageId} />
              </div>
            );
          })}
          <div
            className={cx(
              'flex items-center flex-1 p-1 rounded cursor-pointer h-9 hover:text-grey4 animate-hover'
            )}
            onClick={() => {
              simulateClickBlankArea(uuid);
            }}
          >
            <Icon name="IcPageMiddle" size="middle" className="mr-1 opacity-30" />
            <span className="text-t1">空白页面</span>
          </div>
          <div
            className={cx(
              'flex items-center flex-1 p-1 rounded cursor-pointer h-9 hover:text-grey4 animate-hover'
            )}
            onClick={() => {
              if (collectionId != null) {
                const pageId = insertTemplatePage(collectionId);
                openInRight(pageId, {
                  state: {
                    noticeBannerShowBackButton: true,
                  },
                });
              }
            }}
          >
            <Icon name="IcAddSmallBold" size="small" className="mr-1 opacity-60" />
            <span className="text-t1">创建模板记录</span>
          </div>
          {!isTemplate && !__PRIVATE__ && (
            <div
              className={cx(
                'flex items-center flex-1 p-1 rounded cursor-pointer h-9 hover:text-grey4 animate-hover'
              )}
              onClick={() => {
                openModal.modal({
                  modalId: Modals.TEMPLATE_CENTER,
                  content: () => (
                    <ResourceCenter onlyShowTab="template" from="record" targetId={pageId} />
                  ),
                });
              }}
            >
              <Icon name="IcTemplateNew" size="middle" className="mr-1" />
              <span className="text-t1">从模板库选择创建</span>
            </div>
          )}
        </>
      )}
      {!isRecord && (
        <DirectionScroller
          defaultIndex={0}
          listenPriority={PRIORITY_PORTAL}
          containerRef={container}
          itemCount={items.length}
        >
          {(activeIndex: number, setSelectedIndex: (n: number) => void) =>
            items.map((item, i) => {
              // @ts-ignore hidden
              if (item.isHidden) return null;
              return item.group ? (
                <div key={i} list-item-inactive="" className="flex items-center mt-4 h-9 text-t2">
                  {item.title}
                </div>
              ) : (
                <div
                  key={i}
                  className={cx(
                    'flex items-center p-1 h-9 animate-hover',
                    i === activeIndex && 'bg-black_006 text-grey3'
                  )}
                  onClick={item.onClick}
                  onMouseEnter={() => setSelectedIndex(i)}
                >
                  {item.icon && (
                    <Icon size="middle" className="mr-2" name={item.icon as IconName} />
                  )}
                  {item.title}
                  {item.isBeta && (
                    <span className="text-t2 px-1.5 bg-grey/20 rounded-sm ml-2 text-grey2 inline-flex items-center">
                      Beta
                    </span>
                  )}
                  <Icon
                    size="middle"
                    className={cx(
                      'ml-auto opacity-0 transition-opacity mr-1',
                      i === activeIndex && 'opacity-100'
                    )}
                    name="IcEnter"
                  />
                </div>
              );
            })
          }
        </DirectionScroller>
      )}
    </div>
  );
};
