/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable require-atomic-updates */
import { Async } from '@flowus/common';
import { BlockStatus, CommandType } from '@next-space/fe-api-idl';
import type { Buffer } from '@redux-saga/core';
import { buffers } from '@redux-saga/core';
import { isAllOf } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import localforage from 'localforage';
import { assign, chunk, get, isPlainObject, omit, pick } from 'lodash-es';
import type { Dispatch } from 'react';
import type { AnyAction, Middleware } from 'redux';
import { Subject, filter, first, firstValueFrom, interval, race } from 'rxjs';
import { request } from 'src/common/request';
import { $appUiStateCache, setAppUiState } from 'src/services/app';
import { $networkStatus } from 'src/services/network-status';
import { getUploadInfo } from 'src/services/upload';
import { Deferred, sequence } from 'src/utils/async-utils';
import { lookupPageId } from 'src/utils/lookup-page-id';
import { v4 as uuidV4 } from 'uuid';
import {
  CREATE_BLOCK,
  LIST_AFTER_BLOCK,
  LIST_AFTER_FAVORITE,
  LIST_AFTER_TEMPLATE,
  LIST_BEFORE_BLOCK,
  LIST_BEFORE_FAVORITE,
  LIST_BEFORE_TEMPLATE,
  LIST_REMOVE_BLOCK,
  LIST_REMOVE_FAVORITE,
  LIST_REMOVE_TEMPLATE,
  REMOVE_BLOCK_PERMISSION,
  SET_BLOCK_PERMISSION,
  TRANSACTION_FIRE,
  TRANSACTION_FLUSH,
  UPDATE_BLOCK,
  UPDATE_SPACE,
  UPDATE_SPACE_VIEW,
} from '../actions';
import {
  CREATE_COLLECTION_VIEW,
  LIST_AFTER_COLLECTION_VIEW,
  LIST_AFTER_COLLECTION_VIEW_PAGESORT,
  LIST_BEFORE_COLLECTION_VIEW,
  LIST_BEFORE_COLLECTION_VIEW_PAGESORT,
  LIST_REMOVE_COLLECTION_VIEW,
  LIST_REMOVE_COLLECTION_VIEW_PAGESORT,
  UPDATE_COLLECTION_VIEW,
} from '../actions/collection-view';
import {
  CREATE_COMMENT,
  LIST_AFTER_COMMENT,
  LIST_REMOVE_COMMENT,
  UPDATE_COMMENT,
} from '../actions/comments';
import {
  CREATE_DISCUSSION,
  LIST_AFTER_DISCUSSION,
  LIST_REMOVE_DISCUSSION,
  UPDATE_DISCUSSION,
} from '../actions/discussion';
import { blocksSlice } from '../reducers/blocks';
import type { getState } from '../store';
import { UploadStatus } from '../types';
import { getCurrentSpaceId } from './helper';
import type { CacheType } from './types';

export const KEY_BACKUP_TRANSACTIONS = 'flowus.backup_transactions';
export const KEY_BACKUP_NO_PERMISSION_TRANSACTIONS = 'flowus.backup_no_permission_transactions';
export const KEY_BACKUP_ERROR_TRANSACTIONS = 'flowus.backup_error_transactions';
export const KEY_BACKUP_ANY_TIME_TRANSACTIONS = 'flowus.backup_any_time_';
export const KEY_BACKUP_ANY_TIME = 'flowus.backup_any_time';
const BACKUP_MAX_DAY = 7;

interface BackupTransactionsRecord {
  // pageId -> version
  affectedPages: Record<string, number>;
  transactions: Transaction[];
}

interface Operation {
  id: string;
  path: string[];
  command: CommandType;
  table: 'block' | 'space' | 'collectionView' | 'spaceView' | 'discussion' | 'comment';
  args: unknown;
}

interface Transaction {
  id: string;
  spaceId: string;
  operations: Operation[];
}

type SignalType = 'immediate' | 'defer';

let opsRecord: Record<string, Operation[]> = {};
const transactions: Buffer<Transaction> = buffers.expanding();
let sendingTransactions: Transaction[] = [];
const sendSignalChan = new Subject<SignalType>();

let transactionsUpdateTime = Date.now();
let transactionsBackupTime = 0;

const dirtyBlocks = new Set<string>();

const addOperation = (spaceId: string, op: Operation) => {
  const ops = opsRecord[spaceId] ?? (opsRecord[spaceId] = []);
  ops.push(op);
};

const splitTransaction = (_trs: Transaction[]) => {
  const trs: Transaction[] = [];
  _trs.forEach((tr) => trs.push(...chunkOp(tr)));

  return trs;
};

const getCurTimeKey = () => dayjs().format('YYYY-MM-DD.HH:mm');
const backupOperation = {
  loading: false,
  timer: null as unknown as NodeJS.Timeout,
  all: [] as Transaction[],
  noPermission: [] as Transaction[],
  error: [] as Transaction[],
  get: (key: 'all' | 'error' | 'noPermission') => {
    return backupOperation[key];
  },
  set: (key: 'all' | 'error' | 'noPermission', value: any) => {
    switch (key) {
      case 'all': {
        const curTime = getCurTimeKey();
        return localforage.setItem(`${KEY_BACKUP_ANY_TIME_TRANSACTIONS}${curTime}`, value);
      }
      case 'error': {
        return localforage.setItem(KEY_BACKUP_ERROR_TRANSACTIONS, value);
      }
      case 'noPermission': {
        return localforage.setItem(KEY_BACKUP_NO_PERMISSION_TRANSACTIONS, value);
      }
      default:
    }
  },
  getDB: async <T>(key: 'all' | 'error' | 'noPermission') => {
    if (key === 'all') {
      const curTime = getCurTimeKey();
      const times: string[] = (await localforage.getItem(KEY_BACKUP_ANY_TIME)) ?? [];

      const [firstTime] = times;
      if (firstTime && dayjs(firstTime).diff(dayjs(), 'day') >= BACKUP_MAX_DAY) {
        times.shift();
        void localforage.removeItem(`${KEY_BACKUP_ANY_TIME_TRANSACTIONS}${firstTime}`);
        void localforage.setItem(KEY_BACKUP_ANY_TIME, times);
      }

      if (!times?.includes(curTime)) {
        times.push(curTime);
        void localforage.setItem(KEY_BACKUP_ANY_TIME, times);
      }

      const res =
        (await localforage.getItem(`${KEY_BACKUP_ANY_TIME_TRANSACTIONS}${curTime}`)) ?? {};
      return res as T;
    }

    if (key === 'error') {
      const res = (await localforage.getItem(KEY_BACKUP_ERROR_TRANSACTIONS)) ?? [];
      return res as T;
    }

    if (key === 'noPermission') {
      const res = (await localforage.getItem(KEY_BACKUP_NO_PERMISSION_TRANSACTIONS)) ?? [];
      return res as T;
    }
    return [] as never;
  },
  update: (key: 'all' | 'error' | 'noPermission', value: Transaction[]) => {
    backupOperation[key] = [...backupOperation[key], ...value];
    backupOperation.start();
  },
  start: () => {
    if (backupOperation.loading) return;
    if (
      !(
        backupOperation.all.length +
        backupOperation.error.length +
        backupOperation.noPermission.length
      )
    ) {
      return;
    }

    backupOperation.stop();
    backupOperation.timer = setTimeout(async () => {
      backupOperation.loading = true;
      backupOperation.stop();
      if (backupOperation.noPermission.length) {
        const localTrs: Transaction[] = await backupOperation.getDB('noPermission');
        await backupOperation.set('noPermission', [...localTrs, ...backupOperation.noPermission]);
        backupOperation.noPermission = [];
        // emitter.emit('syncNoPermission');
      }

      if (backupOperation.error.length) {
        const localTrs: Transaction[] = await backupOperation.getDB('error');
        await backupOperation.set('error', [...localTrs, ...backupOperation.error]);
        backupOperation.error = [];
      }

      if (backupOperation.all.length) {
        const curTime = getCurTimeKey();
        const localTrs: Record<string, Transaction[]> = await backupOperation.getDB('all');
        localTrs[curTime] = [...(localTrs[curTime] ?? []), ...backupOperation.all];
        await backupOperation.set('all', localTrs);
        backupOperation.all = [];
      }

      backupOperation.loading = false;
      backupOperation.start();
    }, 3000);
  },
  stop: () => {
    clearTimeout(backupOperation.timer);
  },
  clear: async () => {
    const times: string[] = (await localforage.getItem(KEY_BACKUP_ANY_TIME)) ?? [];
    void localforage.removeItem(KEY_BACKUP_ERROR_TRANSACTIONS);
    void localforage.removeItem(KEY_BACKUP_NO_PERMISSION_TRANSACTIONS);
    void localforage.removeItem(KEY_BACKUP_NO_PERMISSION_TRANSACTIONS);
    for (const time of times) {
      void localforage.removeItem(time);
    }
    void localforage.removeItem(KEY_BACKUP_ANY_TIME);
  },
  getAllTrs: async () => {
    const times: string[] = (await localforage.getItem(KEY_BACKUP_ANY_TIME)) ?? [];
    const res: Record<string, Transaction[]> = {};
    for (const time of times) {
      const item: Transaction[] =
        (await localforage.getItem(`${KEY_BACKUP_ANY_TIME_TRANSACTIONS}${time}`)) ?? [];
      if (item) {
        assign(res, item);
      }
    }

    return res;
  },
};

export const exportBackupData = async () => {
  const error = await backupOperation.getDB('error');
  const noPermission = await backupOperation.getDB('noPermission');
  const all = await backupOperation.getAllTrs();
  await triggerDownloadTransactions({
    all,
    error,
    noPermission,
  });
};

export const clearBackupData = async () => {
  await backupOperation.clear();
};

// eslint-disable-next-line no-unused-vars
const flushTransactions = async (dispatch: Dispatch<AnyAction>) => {
  await backupTransactionsToIndexDB();
  sendingTransactions = splitTransaction([...sendingTransactions, ...transactions.flush()]);
  transactionsUpdateTime = Date.now();
  const noPermissionTransactions: Transaction[] = [];
  const errorTransactions: Transaction[] = [];
  const allTransactions: Transaction[] = [];

  if (sendingTransactions.length > 0) {
    if ($networkStatus.offline) {
      await firstValueFrom($networkStatus.onStatusChange.pipe(filter(() => $networkStatus.online)));
    }

    // 分片上传
    const piece: Transaction[] = [];
    while (sendingTransactions.length > 0) {
      const tr = sendingTransactions.shift()!;
      transactionsUpdateTime = Date.now();
      piece.push(tr);
      let needSend = false;
      if (checkStringSize(piece, 32 * 1024)) {
        // 就是这个 tempTr 导致 piece 超出了
        const tempTr = piece.pop()!;
        if (piece.length === 0) {
          // 如果单单就一个 tempTr 就导致 piece 超出了了
          piece.push(tempTr);
        } else {
          // 把这个 tempTr 放回 sendingTransactions
          sendingTransactions.unshift(tempTr);
          transactionsUpdateTime = Date.now();
        }
        needSend = true;
      } else {
        if (sendingTransactions.length <= 0) {
          needSend = true;
        }
      }

      if (needSend) {
        const sendTransactions = piece.slice(0);
        piece.length = 0;
        try {
          await request.editor.transactionRecords({
            requestId: uuidV4(),
            transactions: sendTransactions as any,
          });
          allTransactions.push(...sendTransactions);
        } catch (err: any) {
          errorTransactions.push(...sendTransactions);
          if (err.code !== 1403) {
            sendingTransactions = [...sendTransactions, ...sendingTransactions];
            // Rethrow error
            throw err;
          } else {
            // 无权限，给用户保存下数据
            noPermissionTransactions.push(...sendTransactions);
          }
        }
        $networkStatus.setStatus('online');
        setAppUiState({
          $syncUpFault: {
            noPermission: false,
            backup: false,
          },
        });
        sendTransactions.forEach((transaction) => {
          const callback = transactionToCallback.get(transaction.id);
          callback?.();
          transactionToCallback.delete(transaction.id);
        });
      }
    }

    if (sendingTransactions.length <= 0 && transactions.isEmpty()) {
      setAppUiState({ $dirty: false });
    }

    if (sendingTransactions.length <= 0) {
      void localforage.removeItem(KEY_BACKUP_TRANSACTIONS);
    }
  }

  backupOperation.update('all', allTransactions);

  if (noPermissionTransactions.length) {
    backupOperation.update('noPermission', noPermissionTransactions);
  }

  if (errorTransactions.length) {
    backupOperation.update('error', errorTransactions);
  }

  transactionsUpdateTime = Date.now();
};

const MIN_SEND_INTERVAL = 1000;

const runSendTransactionsLoop = async (dispatch: Dispatch<AnyAction>) => {
  const backupTrans = await localforage.getItem<BackupTransactionsRecord>(KEY_BACKUP_TRANSACTIONS);
  if (backupTrans != null && backupTrans.transactions.length > 0) {
    // assert(transactions.isEmpty());

    // 这个接口调用成功也说明用户和服务器是能建立连接的。
    // const res = await request.editor.queryRecords({
    //   requests: Object.keys(backupTrans.affectedPages).map((id) => ({ id, table: 'block' })),
    // });

    // let safeToRestore = true;
    // for (const block of Object.values(res.blocks ?? {})) {
    //   if (block.version > backupTrans.affectedPages[block.uuid]!) {
    //     safeToRestore = false;
    //     break;
    //   }
    // }
    // if (
    // safeToRestore
    // ||
    // confirm(
    //   '检测到上次未同步的编辑数据，无法执行自动恢复。原因：恢复未同步修改可能会覆盖你现有的数据。点击确认继续，点击取消放弃恢复'
    // )
    // ) {
    for (const transaction of backupTrans.transactions) {
      transactions.put(transaction);
    }

    try {
      await flushTransactions(dispatch);
    } catch {
      // DO NOTHING
    }

    if (sendingTransactions.length === 0) {
      await clearBackupTransactions();
      // alert('已执行自动恢复上次未同步的编辑数据，即将刷新页面');
      location.reload();
    } else {
      // 清空 sendingTransactions 和 transactions，避免 backupTransactions 继续写入到 indexeddb 里
      const remainingTransactions = [...sendingTransactions, ...transactions.flush()];
      sendingTransactions = [];

      // 二分查找，恢复尽可能多的transactions
      let startIndex = 0;
      let endIndex = remainingTransactions.length;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        if (endIndex <= startIndex) break;

        const transactions = remainingTransactions.slice(startIndex, endIndex);
        try {
          await request.editor.transactionRecords({
            requestId: uuidV4(),
            transactions: transactions as any,
          });
          startIndex = endIndex;
          endIndex = remainingTransactions.length;
        } catch (err) {
          endIndex = Math.floor((startIndex + endIndex) / 2);
        }
      }

      // if (endIndex < remainingTransactions.length) {
      // 这个case是说明有一些transaction无法同步成功
      // await triggerDownloadTransactions(remainingTransactions.slice(endIndex));
      // await Async.sleep(500);
      // alert(
      //   '已自动恢复上次同步失败的部分数据，如发现有丢失重要数据，可自行从下载的 transactions.json 中找回。'
      // );
      // alert('请确定已下载完备份文件后，再点击确定将刷新页面，若未备份数据丢失将无法找回');
      // await clearBackupTransactions();
      // location.reload();
      // } else {
      // alert('已执行自动恢复上次未同步的编辑数据，即将刷新页面');
      // }
      await clearBackupTransactions();
      location.reload();
    }
    // } else {
    //   await triggerDownloadTransactions(backupTrans.transactions);
    //   await Async.sleep(500);
    //   alert('请确定已下载完备份文件，若未备份数据丢失将无法找回');
    //   await clearBackupTransactions();
    // }
  }

  let errLogs: { error: any; timestamp: number }[] = [];
  let lastSentTime = -Infinity;
  let sleepNum = 0;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    let signal: SignalType = 'defer';
    if (sendingTransactions.length === 0 && transactions.isEmpty()) {
      signal = await firstValueFrom(sendSignalChan);
    }
    if (signal === 'defer') {
      const now = Date.now();
      if (now - lastSentTime < MIN_SEND_INTERVAL) {
        const delay = MIN_SEND_INTERVAL - (now - lastSentTime);
        await firstValueFrom(race(interval(delay), sendSignalChan.pipe(first())));
      }
    }
    if (sendingTransactions.length > 0 || !transactions.isEmpty()) {
      try {
        await flushTransactions(dispatch);
        // 重置计数器
        errLogs = [];
        sleepNum = 0;
      } catch (err: any) {
        const serverError = /502/i.test(`${err?.code}`);
        const isNetworkError = /Network Error/i.test(`${err?.message}`);
        // 13开头可以重试
        const retry = /^13/.test(`${err?.code}`);
        // 二次确认，曾复现过navigator.onLine为true却离线的情况
        if (isNetworkError && !navigator.onLine) {
          $networkStatus.setStatus('offline');
        }
        // 如果是服务器错误了，就多等一会
        if (serverError || retry) {
          sleepNum = Math.min(sleepNum + 8, 32);
          await Async.sleep(1000 * sleepNum);
        } else if (!isNetworkError) {
          const now = Date.now();
          errLogs.push({
            error: err,
            timestamp: now,
          });
          // NOTE: 有用户反馈合上笔记本后第二天打开会到这里，因此：这里忽略过早的错误，仅考虑短时间内的连续错误
          errLogs = errLogs.filter((it) => now - it.timestamp < 15 * 60 * 1000);
          // 无视网络错误
          if (errLogs.length >= 3) {
            // 让 Sentry 可以捕获到错误日志
            Sentry.captureException(err);
            setAppUiState({
              $syncUpFault: {
                ...$appUiStateCache.$syncUpFault,
                backup: true,
              },
            });
            // 原先这里是退出同步循环，现在改成等15分钟再试，主要原因是有的用户点了确定之后挂着页面就走开了。
            // 这里不放弃15分钟后同步能成功的可能。
            await Async.sleep(15 * 60 * 1000);
          }
        }
        // Keep sendingTransactions
      }
      lastSentTime = Date.now();
    }
  }
};

// redux-saga 的 buffer 不支持 peek
const peekAll = <T>(buffer: Buffer<T>) => {
  const items = buffer.flush();
  for (const item of items) {
    buffer.put(item);
  }
  return items;
};

export const getBackupTransactions = () => {
  return [...sendingTransactions, ...peekAll(transactions)];
};

const backupTransactions = async (getState0: typeof getState) => {
  if (transactionsBackupTime < transactionsUpdateTime) {
    const toBackup = [...sendingTransactions, ...peekAll(transactions)];
    const affectedPages: Record<string, number> = {};
    for (const transaction of toBackup) {
      for (const op of transaction.operations) {
        const uuid = (op.args as any)?.uuid ?? (op.args as any)?.parentId ?? op.id;
        const pageId = lookupPageId(uuid, getState0());
        if (pageId != null) {
          affectedPages[pageId] = getState0().blocks[pageId]!.version;
        }
      }
    }
    if (toBackup.length > 0) {
      await localforage.setItem(KEY_BACKUP_TRANSACTIONS, {
        affectedPages,
        transactions: toBackup,
      });
    } else {
      await localforage.removeItem(KEY_BACKUP_TRANSACTIONS);
    }
    transactionsBackupTime = Date.now();
  }
};

const runBackupTransactionsLoop = async (getState0: typeof getState) => {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    await backupTransactions(getState0);
    await Async.sleep(1000);
  }
};

export const clearBackupTransactions = async () => {
  sendingTransactions = [];
  transactions.flush();
  await localforage.removeItem(KEY_BACKUP_TRANSACTIONS);
};

const backupTransactionsToIndexDB = async (transactions?: Transaction[]) => {
  const jsonText = JSON.stringify(transactions ?? getBackupTransactions(), null, 2);

  try {
    const k = (await localforage.getItem<number>(`${KEY_BACKUP_TRANSACTIONS}.$`)) ?? 0;
    await localforage.setItem(`${KEY_BACKUP_TRANSACTIONS}.${k}`, jsonText);
    await localforage.setItem<number>(`${KEY_BACKUP_TRANSACTIONS}.$`, (k + 1) % 5);
  } catch {
    Sentry.captureMessage('backupTransactionsAndTriggerDownload: backup fail');
  }
};

export const triggerDownloadTransactions = async (content?: any, filename?: string) => {
  const jsonText = JSON.stringify(content, null, 2);

  const blob = new Blob([jsonText], { type: 'application/json; charset=UTF-8' });
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = filename || 'backup.json';
  link.style.cssText = 'position: fixed; left: -9999px; top: -9999px;';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  setTimeout(() => {
    URL.revokeObjectURL(blobUrl);
  }, 60 * 1000 * 3);
};

export const getBackUpNoPermissionTransactions = async () => {
  const res: Transaction[] =
    (await localforage.getItem(KEY_BACKUP_NO_PERMISSION_TRANSACTIONS)) ?? [];
  return res;
};

export const clearBackupNoPermissionTransactions = async () => {
  await localforage.removeItem(KEY_BACKUP_NO_PERMISSION_TRANSACTIONS);
};

const getTable = (block?: { type: unknown }) => {
  return block?.type === 'SPACE' ? 'space' : 'block';
};

const transactionToCallback = new Map<string, () => void>();

const checkStringSize = (data: any, size: number) => {
  try {
    return JSON.stringify(data).length >= size;
  } catch {
    return true;
  }
};

const chunkOp = (trs: Transaction) => {
  const newTrs: Transaction[] = [];
  const { operations, spaceId, id } = trs;
  const callback = transactionToCallback.get(id);

  let callbackEd = false;
  const callback2 = () => {
    if (callbackEd) return;
    callback?.();
    transactionToCallback.delete(id);
    callbackEd = true;
  };

  // 超过的时候再进行分段
  const isAllowChunk = checkStringSize(operations, 32 * 1024);
  const chunkOps = isAllowChunk ? chunk(operations, 12) : [operations];
  chunkOps.forEach((ops, index) => {
    const trId = uuidV4();
    const transaction: Transaction = {
      id: trId,
      spaceId,
      operations: ops,
    };
    if (callback != null && index === chunkOps.length - 1) {
      transactionToCallback.set(trId, callback2);
    }
    newTrs.push(transaction);
  });
  return newTrs;
};

export const persistenceMiddleware: Middleware<{}, CacheType> = ({ getState, dispatch }) => {
  void runSendTransactionsLoop(dispatch);
  void runBackupTransactionsLoop(getState as any);

  const queueTransaction = (opt?: { callback?: () => void }) => {
    const { callback = () => {} } = opt || {};

    let callbackEd = false;
    const callback2 = () => {
      if (callbackEd) return;
      callback?.();
      callbackEd = true;
    };

    let hasTransactions = false;
    for (const [spaceId, operations] of Object.entries(opsRecord)) {
      // 超过64KB的时候再进行分段
      const isAllowChunk = checkStringSize(operations, 64 * 1024);
      const chunkOps = isAllowChunk ? chunk(operations, 12) : [operations];
      chunkOps.forEach((ops, index) => {
        const trId = uuidV4();
        const transaction: Transaction = {
          id: trId,
          spaceId,
          operations: ops,
        };
        if (callback != null && index === chunkOps.length - 1) {
          transactionToCallback.set(trId, callback2);
        }
        transactions.put(transaction);
      });
      hasTransactions = true;
      transactionsUpdateTime = Date.now();
    }

    const dirtyIds = [...dirtyBlocks];
    dispatch(blocksSlice.actions.incVersion(dirtyIds));
    if (dirtyIds.length > 0) {
      setAppUiState({ $dirty: true });
    }

    dirtyBlocks.clear();
    opsRecord = {};

    // 如果没有 transactions 可以关联 callback，则直接调用
    if (!hasTransactions) {
      callback2?.();
    }
  };

  window.addEventListener('beforeunload', (event) => {
    // 这里并不保险，不过有总比没有好
    void backupTransactions(getState as any);
    if (
      Object.keys(opsRecord).length ||
      !transactions.isEmpty() ||
      sendingTransactions.length > 0
    ) {
      event.preventDefault();
      event.returnValue = '同步操作未完成, 是否离开?';
    }
    const uploadEntries = Object.entries(getUploadInfo());
    if (
      uploadEntries.some((e) => e[1].progress !== 100 && e[1].status === UploadStatus.uploading)
    ) {
      event.preventDefault();
      event.returnValue = '图片仍在上传, 是否离开?';
    }
  });

  return (next) => {
    return (action) => {
      // 忽略本地操作
      if (!isTransactionAction(action) || action.payload?.ignoreOp) {
        return next(action);
      }

      if (isAllOf(TRANSACTION_FLUSH)(action)) {
        queueTransaction();
        sendSignalChan.next('defer');

        return next(action);
      }

      if (isAllOf(TRANSACTION_FIRE)(action)) {
        const defer = new Deferred();
        queueTransaction({
          callback: () => {
            defer.resolve();
          },
        });
        sendSignalChan.next('immediate');
        void sequence(() => {
          return defer.promise;
        });
        return next(action);
      }

      dirtyBlocks.add(action.payload.uuid);

      if (isAllOf(CREATE_BLOCK)(action)) {
        const { payload } = action;

        addOperation(payload.block.spaceId, {
          id: payload.block.uuid,
          command: CommandType.SET,
          table: 'block',
          path: [],
          args: {
            ...omit(payload.block, ['local', 'version', 'subNodes', 'data']),
            data: pickSafeData(payload.block.data),
          },
        });

        return next(action);
      }

      if (isAllOf(LIST_REMOVE_BLOCK)(action)) {
        const { payload } = action;
        const { blocks } = getState();
        const block = blocks[payload.uuid];

        if (block) {
          // 可能有 BUG，parent 不存在或者 type 不是 space
          const table =
            block.spaceId === block.parentId ? 'space' : getTable(blocks[block.parentId]);

          addOperation(block.spaceId, {
            id: block.parentId,
            command: CommandType.LIST_REMOVE,
            table,
            path: ['subNodes'],
            args: {
              uuid: block.uuid,
            },
          });
        }

        return next(action);
      }

      if (isAllOf(LIST_AFTER_BLOCK)(action)) {
        const { payload } = action;
        const { blocks } = getState();
        const block = blocks[payload.uuid];
        const parent = blocks[payload.parentId];

        if (block) {
          // 可能有 BUG，parent 不存在或者 type 不是 space
          const table = block.spaceId === payload.parentId ? 'space' : getTable(parent);

          addOperation(block.spaceId, {
            id: block.uuid,
            command: CommandType.UPDATE,
            table: 'block',
            path: [],
            args: {
              status: BlockStatus.NORMAL,
              parentId: payload.parentId,
            },
          });

          addOperation(block.spaceId, {
            id: payload.parentId,
            command: CommandType.LIST_AFTER,
            table,
            path: ['subNodes'],
            args: {
              uuid: block.uuid,
              after: payload.after,
            },
          });
        }

        return next(action);
      }

      if (isAllOf(LIST_BEFORE_BLOCK)(action)) {
        const { payload } = action;
        const { blocks } = getState();
        const block = blocks[payload.uuid];
        const parent = blocks[payload.parentId];
        if (block) {
          // 可能有 BUG，parent 不存在或者 type 不是 space
          const table = block.spaceId === payload.parentId ? 'space' : getTable(parent);

          if (block.parentId !== payload.parentId) {
            addOperation(block.spaceId, {
              id: block.uuid,
              command: CommandType.UPDATE,
              table: 'block',
              path: [],
              args: {
                status: BlockStatus.NORMAL,
                parentId: payload.parentId,
              },
            });
          }

          addOperation(block.spaceId, {
            id: payload.parentId,
            command: CommandType.LIST_BEFORE,
            table,
            path: ['subNodes'],
            args: {
              uuid: block.uuid,
              before: payload.before,
            },
          });
        }

        return next(action);
      }

      if (isAllOf(UPDATE_BLOCK)(action)) {
        const { payload } = action;
        const block = getState().blocks[payload.uuid];

        if (block && block?.parentId) {
          const segments = payload.patch.data?.segments;
          if (payload.patch.data && segments?.length) {
            // 校验修复错误格式
            if (JSON.stringify(segments).includes(`"text":false`)) {
              payload.patch.data.segments = segments.map((i) => ({
                ...i,
                enhancer: omit(i.enhancer, 'textColor'),
              }));
            }
          }
          const args = omit(payload.patch, ['local', 'version', 'subNodes', 'data']);
          if (Object.keys(args).length) {
            addOperation(block.spaceId, {
              id: block.uuid,
              path: [],
              command: CommandType.UPDATE,
              table: 'block',
              args,
            });
          }

          // 这玩意是个 data:{xx:{}} 保持原格式了
          if (payload.path) {
            addOperation(block.spaceId, {
              id: block.uuid,
              path: payload.path,
              command: CommandType.UPDATE,
              table: 'block',
              args: get(payload.patch, payload.path),
            });
          } else if (payload.patch.data) {
            const _args = omit(payload.patch.data, ['format']);
            addOperation(block.spaceId, {
              id: block.uuid,
              path: ['data'],
              command: CommandType.UPDATE,
              table: 'block',
              args: pickSafeData(_args),
            });
            // 由于之前更新format的地方有不少缺失了填充之前的format，于是一次性在这里解决问题，像data那样支持局部更新
            if (payload.patch.data.format) {
              addOperation(block.spaceId, {
                id: block.uuid,
                path: ['data', 'format'],
                command: CommandType.UPDATE,
                table: 'block',
                args: { ...payload.patch.data.format },
              });
            }
          }
        }

        return next(action);
      }

      if (isAllOf(SET_BLOCK_PERMISSION)(action)) {
        const { payload } = action;
        const block = getState().blocks[payload.uuid];

        if (block) {
          addOperation(block.spaceId, {
            id: block.uuid,
            path: [],
            command: CommandType.SET_PERMISSION,
            table: 'block',
            args: payload.permission,
          });
        }

        return next(action);
      }

      if (isAllOf(REMOVE_BLOCK_PERMISSION)(action)) {
        const { payload } = action;
        const block = getState().blocks[payload.uuid];

        if (block) {
          addOperation(block.spaceId, {
            id: block.uuid,
            path: [],
            command: CommandType.REMOVE_PERMISSION,
            table: 'block',
            args: payload.permission,
          });
        }

        return next(action);
      }

      if (isAllOf(CREATE_COLLECTION_VIEW)(action)) {
        const { payload } = action;
        addOperation(payload.collectionView.spaceId, {
          id: payload.collectionView.uuid,
          command: CommandType.SET,
          table: 'collectionView',
          path: [],
          args: payload.collectionView,
        });

        return next(action);
      }

      if (isAllOf(UPDATE_COLLECTION_VIEW)(action)) {
        const { payload } = action;
        const { collectionViews } = getState();
        const view = collectionViews[payload.uuid];

        if (view) {
          const args = omit(payload.patch, ['format']);
          addOperation(view.spaceId, {
            id: payload.uuid,
            command: CommandType.UPDATE,
            table: 'collectionView',
            path: [],
            args,
          });

          if (payload.patch.format) {
            addOperation(view.spaceId, {
              id: payload.uuid,
              command: CommandType.UPDATE,
              table: 'collectionView',
              path: ['format'],
              args: { ...payload.patch.format },
            });
          }
        }
        return next(action);
      }

      if (isAllOf(LIST_AFTER_COLLECTION_VIEW)(action)) {
        const { payload } = action;
        const { collectionViews } = getState();
        const view = collectionViews[payload.uuid];

        if (view) {
          if (view.parentId !== payload.parentId) {
            addOperation(view.spaceId, {
              id: view.uuid,
              command: CommandType.UPDATE,
              table: 'collectionView',
              path: [],
              args: {
                status: BlockStatus.NORMAL,
                parentId: payload.parentId,
              },
            });
          }

          addOperation(view.spaceId, {
            id: payload.parentId,
            table: 'block',
            command: CommandType.LIST_AFTER,
            path: ['views'],
            args: {
              uuid: payload.uuid,
              after: payload.after,
            },
          });
        }

        return next(action);
      }

      if (isAllOf(LIST_BEFORE_COLLECTION_VIEW)(action)) {
        const { payload } = action;
        const { collectionViews } = getState();
        const view = collectionViews[payload.uuid];

        if (view) {
          if (payload.parentId !== view.parentId) {
            addOperation(view.spaceId, {
              id: view.uuid,
              command: CommandType.UPDATE,
              table: 'collectionView',
              path: [],
              args: {
                status: BlockStatus.NORMAL,
                parentId: payload.parentId,
              },
            });
          }

          addOperation(view.spaceId, {
            id: payload.parentId,
            table: 'block',
            command: CommandType.LIST_BEFORE,
            path: ['views'],
            args: {
              uuid: payload.uuid,
              before: payload.before,
            },
          });
        }

        return next(action);
      }

      if (isAllOf(LIST_BEFORE_COLLECTION_VIEW_PAGESORT)(action)) {
        const { payload } = action;
        const { collectionViews } = getState();
        const view = collectionViews[payload.viewId];

        if (view) {
          addOperation(view.spaceId, {
            id: payload.viewId,
            table: 'collectionView',
            command: CommandType.LIST_BEFORE,
            path: ['pageSort'],
            args: {
              uuid: payload.uuid,
              before: payload.before,
            },
          });
        }

        return next(action);
      }

      if (isAllOf(LIST_AFTER_COLLECTION_VIEW_PAGESORT)(action)) {
        const { payload } = action;
        const { collectionViews } = getState();
        const view = collectionViews[payload.viewId];

        if (view) {
          addOperation(view.spaceId, {
            id: payload.viewId,
            table: 'collectionView',
            command: CommandType.LIST_AFTER,
            path: ['pageSort'],
            args: {
              uuid: payload.uuid,
              after: payload.after,
            },
          });
        }

        return next(action);
      }

      if (isAllOf(LIST_REMOVE_COLLECTION_VIEW_PAGESORT)(action)) {
        const { payload } = action;
        const { collectionViews } = getState();
        const view = collectionViews[payload.viewId];

        if (view) {
          addOperation(view.spaceId, {
            id: payload.viewId,
            table: 'collectionView',
            command: CommandType.LIST_REMOVE,
            path: ['pageSort'],
            args: { uuid: payload.uuid },
          });
        }

        return next(action);
      }

      if (isAllOf(LIST_REMOVE_COLLECTION_VIEW_PAGESORT)(action)) {
        const { payload } = action;
        const { collectionViews } = getState();
        const view = collectionViews[payload.uuid];

        if (view) {
          addOperation(view.spaceId, {
            id: payload.viewId,
            table: 'collectionView',
            command: CommandType.LIST_REMOVE,
            path: ['pageSort'],
            args: {
              uuid: payload.uuid,
            },
          });
        }

        return next(action);
      }

      if (isAllOf(LIST_REMOVE_COLLECTION_VIEW)(action)) {
        const { payload } = action;
        const { collectionViews } = getState();
        const view = collectionViews[payload.uuid];

        if (view) {
          addOperation(view.spaceId, {
            id: payload.parentId,
            table: 'block',
            command: CommandType.LIST_REMOVE,
            path: ['views'],
            args: {
              uuid: payload.uuid,
            },
          });
        }

        return next(action);
      }

      if (isAllOf(LIST_AFTER_FAVORITE)(action)) {
        const { payload } = action;
        const block = getState().blocks[payload.uuid];
        if (block) {
          addOperation(block.spaceId, {
            id: payload.parentId,
            table: 'spaceView',
            command: CommandType.LIST_AFTER,
            path: ['favoritePages'],
            args: {
              uuid: payload.uuid,
              after: payload.after,
            },
          });
        }
        return next(action);
      }

      if (isAllOf(LIST_BEFORE_FAVORITE)(action)) {
        const { payload } = action;
        const block = getState().blocks[payload.uuid];
        if (block) {
          addOperation(block.spaceId, {
            id: payload.parentId,
            table: 'spaceView',
            command: CommandType.LIST_BEFORE,
            path: ['favoritePages'],
            args: {
              uuid: payload.uuid,
              before: payload.before,
            },
          });
        }
        return next(action);
      }

      if (isAllOf(LIST_REMOVE_FAVORITE)(action)) {
        const { payload } = action;
        const spaceId = getCurrentSpaceId();
        // block有可能已经被删掉，因此这里只能这么获取spaceId
        addOperation(spaceId, {
          id: payload.parentId,
          table: 'spaceView',
          command: CommandType.LIST_REMOVE,
          path: ['favoritePages'],
          args: {
            uuid: payload.uuid,
          },
        });
        return next(action);
      }

      if (isAllOf(UPDATE_SPACE_VIEW)(action)) {
        const { payload } = action;
        const args = omit(payload.patch, ['setting']);
        const spaceId = getCurrentSpaceId();
        addOperation(spaceId, {
          id: payload.uuid,
          table: 'spaceView',
          command: CommandType.UPDATE,
          path: [],
          args,
        });
        if (payload.patch.setting) {
          addOperation(spaceId, {
            id: payload.uuid,
            table: 'spaceView',
            command: CommandType.UPDATE,
            path: ['setting'],
            args: { ...payload.patch.setting },
          });
        }

        return next(action);
      }

      if (isAllOf(UPDATE_SPACE)(action)) {
        const { payload } = action;
        const spaceId = getCurrentSpaceId();
        const args = pick(payload.patch, [
          'title',
          'icon',
          'permissionGroups',
          'domain',
          'publicHomePage',
          'isShowAd',
          'setting',
        ]);
        addOperation(spaceId, {
          id: payload.uuid,
          table: 'space',
          command: CommandType.SET,
          path: [],
          args,
        });
      }

      if (isAllOf(LIST_AFTER_TEMPLATE)(action)) {
        const { payload } = action;
        const block = getState().blocks[payload.uuid];
        if (block) {
          addOperation(block.spaceId, {
            id: payload.parentId,
            table: 'space',
            command: CommandType.LIST_AFTER,
            path: ['customTemplates'],
            args: {
              uuid: payload.uuid,
              after: payload.after,
            },
          });
        }
        return next(action);
      }

      if (isAllOf(LIST_BEFORE_TEMPLATE)(action)) {
        const { payload } = action;
        const block = getState().blocks[payload.uuid];

        if (block) {
          addOperation(block.spaceId, {
            id: payload.parentId,
            table: 'space',
            command: CommandType.LIST_BEFORE,
            path: ['customTemplates'],
            args: {
              uuid: payload.uuid,
              before: payload.before,
            },
          });
        }
        return next(action);
      }

      if (isAllOf(LIST_REMOVE_TEMPLATE)(action)) {
        const { payload } = action;
        const spaceId = getCurrentSpaceId();
        // block有可能已经被删掉，因此这里只能这么获取spaceId
        addOperation(spaceId, {
          id: payload.parentId,
          table: 'space',
          command: CommandType.LIST_REMOVE,
          path: ['customTemplates'],
          args: {
            uuid: payload.uuid,
          },
        });
        return next(action);
      }

      // #region Discussion
      if (isAllOf(CREATE_DISCUSSION)(action)) {
        const { payload } = action;
        addOperation(payload.discussion.spaceId, {
          id: payload.discussion.uuid,
          command: CommandType.SET,
          table: 'discussion',
          path: [],
          args: payload.discussion,
        });

        return next(action);
      }

      if (isAllOf(UPDATE_DISCUSSION)(action)) {
        const { payload } = action;
        const { discussions } = getState();
        const discussion = discussions[payload.uuid];

        if (discussion) {
          addOperation(discussion.spaceId, {
            id: payload.uuid,
            command: CommandType.UPDATE,
            table: 'discussion',
            path: [],
            args: payload.patch,
          });
        }
        return next(action);
      }

      if (isAllOf(LIST_AFTER_DISCUSSION)(action)) {
        const { payload } = action;
        const discussion = getState().discussions[payload.uuid];
        if (discussion) {
          addOperation(discussion.spaceId, {
            id: payload.parentId,
            table: 'block',
            command: CommandType.LIST_AFTER,
            path: ['discussions'],
            args: {
              uuid: payload.uuid,
              after: payload.after,
            },
          });
        }
        return next(action);
      }

      if (isAllOf(LIST_REMOVE_DISCUSSION)(action)) {
        const { payload } = action;
        addOperation(payload.spaceId, {
          id: payload.parentId,
          table: 'block',
          command: CommandType.LIST_REMOVE,
          path: ['discussions'],
          args: {
            uuid: payload.uuid,
          },
        });
        return next(action);
      }
      // #endregion

      // #region Comment
      if (isAllOf(CREATE_COMMENT)(action)) {
        const { payload } = action;
        addOperation(payload.comment.spaceId, {
          id: payload.comment.uuid,
          command: CommandType.SET,
          table: 'comment',
          path: [],
          args: payload.comment,
        });

        return next(action);
      }

      if (isAllOf(UPDATE_COMMENT)(action)) {
        const { payload } = action;
        const { comments } = getState();
        const comment = comments[payload.uuid];

        if (comment) {
          addOperation(comment.spaceId, {
            id: payload.uuid,
            command: CommandType.UPDATE,
            table: 'comment',
            path: [],
            args: payload.patch,
          });
        }
        return next(action);
      }

      if (isAllOf(LIST_AFTER_COMMENT)(action)) {
        const { payload } = action;
        const comment = getState().comments[payload.uuid];
        if (comment) {
          addOperation(comment.spaceId, {
            id: payload.parentId,
            table: 'discussion',
            command: CommandType.LIST_AFTER,
            path: ['comments'],
            args: {
              uuid: payload.uuid,
              after: payload.after,
            },
          });
        }
        return next(action);
      }

      if (isAllOf(LIST_REMOVE_COMMENT)(action)) {
        const { payload } = action;
        addOperation(payload.spaceId, {
          id: payload.parentId,
          table: 'discussion',
          command: CommandType.LIST_REMOVE,
          path: ['comments'],
          args: {
            uuid: payload.uuid,
          },
        });
        return next(action);
      }
      // #endregion

      next(action);
    };
  };
};

/**
 * 获取可以传递给 server 的 data
 */
function pickSafeData(data: unknown) {
  if (!isPlainObject(data)) {
    return {};
  }
  return pick(data, [
    'pageId', // 页面 uuid
    'cover', // 背景图 oss id
    'coverPos', // 背景图位置 (page)
    'icon', // 图标 (page)
    'segments', // 文本 segment
    'checked', // 是否选中
    'level', // 标题等级 (header)
    'width', // 图像宽度(px)
    'height', // 图像高度(px)
    'ratio', // 图片比例(web端用)
    'ossName', // oss 名称
    'fileType', // ???
    'caption', // 说明块
    'widths', // 分栏宽度 百分比数组 (column)
    'size', // 文件大小
    'text', // 文件名称
    'ref', // 引用id
    'link', // 外部资源 url
    'extName', // 文件拓展名
    'display', // 展示类型，仅file和image block展示使用
    'pageFixedWidth', // 页面固定宽度
    'directoryMenu', // 右侧目录树菜单
    'format', // 自定义展示，例如宽高
    'embedType', // 嵌入第三方网页类型
    'viewMode', // 文件夹/文件 三种视图
    'columnRatio', // 分栏比例
    'permissions', // 权限
    'schema', // bitable 形状
    'collectionProperties', // bitable 属性,
    'collectionPageProperties', // bitable 页面属性排序,
    'collectionCardColor', // 卡片背景颜色
    'description', // bitable顶部描述
    'linkInfo', // 书签块的标题信息
    'header', // 书签地址的header信息
    'createdTemplateBy', // 模板创建者
    'pdfAnnotation', // 模板创建者,
    'isByAI', // 是否 ai 创建,
    'openLink', // 是否 ai 创建,
  ]);
}

function isTransactionAction(action: any): action is AnyAction {
  return /^(block|collection-view|space-view|transaction|favorite|space|template|discussion|comment)\//.test(
    action?.type
  );
}
