import { publicLink } from 'src/common/const';
import { ImportType } from '@next-space/fe-api-idl';
import type { ImportBtnType } from './types';

export const IMPORT_MODAL_ID = 'import-modal';
export const NOTION_MODAL_ID = 'notion-modal';

const isDev = __HOST_LOCAL__ || __HOST_TEST__;
export const clientId = isDev
  ? '6ef844fb-8ff0-4bde-b093-4155f0e2f530'
  : 'f843d557-50d6-4110-b05c-6e60029bd338';
export const host = isDev ? 'test.allflow.cn' : 'flowus.cn';

export const btnConfig: ImportBtnType = {
  [ImportType.MARKDOWN]: {
    icon: 'MIcImportMarkdown',
    title: 'Markdown',
    description: '支持 ZIP RAR',
    accept: '.md,.zip,.rar',
  },
  [ImportType.CSV]: {
    icon: 'MIcImportCsv',
    title: 'CSV',
    description: '',
    accept: '.csv',
  },
  [ImportType.HTML]: {
    icon: 'MIcImportHtml',
    title: 'HTML',
    description: '',
    accept: '.html,.zip,.rar',
  },
  [ImportType.WORD]: {
    icon: 'MIcImportWord',
    title: 'Word',
    description: '支持 DOCX',
    accept: '.docx,.zip,.rar',
  },
  [ImportType.NOTION]: {
    icon: 'MIcImportNotion',
    title: 'Notion',
    description: '通过 Notion API 导入',
    accept: '',
    help: __BUILD_IN__ ? undefined : publicLink.importFromNotion,
  },
};
