import type { AppIpc } from '@flowus/shared';
import dsBridge from 'dsbridge';
import { isElectron } from 'react-device-detect';
import { getIsApp } from './get-is-app';
interface CallbackType {
  code: number;
  msg: string;
  data: any;
}

/**
 * 文档
 * https://flowus.cn/504d89a4-0cc3-44dd-b162-17f1eee9ddbb#01ba6477-aeb2-4f1b-aa3b-f482c42c7b19
 */
export const callNativeAsync = (
  params: {
    event: AppIpc;
    param?: any;
  },
  callback?: (res: CallbackType) => void
) => {
  console.log('调用dsBridge callNativeAsync params: ', params);
  dsBridge.call('callNativeAsync', params, (res: string) => {
    console.log('dsBridge app 回调', res);
    console.log('JSON.parse(res): ', JSON.parse(res));
    callback?.(JSON.parse(res || '{}'));
  });
};

export const registerNativeAsync = (event: AppIpc, callback?: (res: CallbackType) => void) => {
  dsBridge.register(event, (res: string) => {
    console.log('dsBridge.register 回调', res);
    console.log('JSON.parse(res): ', JSON.parse(res));
    callback?.(JSON.parse(res || '{}'));
  });
};

export const getIpcRenderer = () => {
  // @ts-ignore flowUsIpcRenderer
  const { flowUsIpcRenderer } = window;
  if (flowUsIpcRenderer) {
    const flowusIpc = flowUsIpcRenderer;
    return flowusIpc;
  }
  return flowUsIpcRenderer;
};

export const isFlowUsApp = new Proxy(
  {
    check: false,
  },
  {
    get: () => {
      const status = !!(isElectron && getIpcRenderer());
      return status;
    },
  }
);

export { getIsApp };
