import { cx } from '@flowus/common/cx';
import type { PermissionGroupDTO } from '@next-space/fe-api-idl';
import { PermissionRole } from '@next-space/fe-api-idl';
import isHotkey from 'is-hotkey';
import { last, throttle } from 'lodash-es';
import type { FC, MouseEvent, MutableRefObject } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { AutoHeightTextArea } from 'src/common/components/auto-height-text-area';
import { Avatar } from 'src/common/components/avatar';
import { Button } from 'src/common/components/button';
import { DirectionScroller } from 'src/common/components/direction-scroller';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { message } from 'src/common/components/message';
import type { ModalSchema } from 'src/common/components/next-modal';
import { useOpenModal } from 'src/common/components/next-modal';
import { SortableList } from 'src/common/components/sortable-list';
import { UNNAMED_USER } from 'src/common/const';
import { useModel } from 'src/common/create-model';
import { IconPanel } from 'src/components/icon-trigger/icon-panel';
import { UserAvatar } from 'src/components/user-avatar';
import { useCurrentSpace, useUpdateSpace } from 'src/hooks/space';
import { useCurrentSpaceUsers } from 'src/hooks/space/use-current-space-users';
import { useGetSpaceRolePermission } from 'src/hooks/space/use-get-space-role-permission';
import { useIsTeamProSpace } from 'src/hooks/space/use-is-pro-space';
import { useSearchSpaceMembers } from 'src/hooks/space/use-search-space-members';
import { useUpload } from 'src/hooks/space/use-upload';
import { useTransaction } from 'src/hooks/use-transaction';
import { useCurrentUser } from 'src/hooks/user';
import { getUserName } from 'src/hooks/user/use-remark-name';
import { MessageIds } from 'src/modals';
import { bizTracker } from 'src/utils/biz-tracker';
import { searchUsers } from 'src/utils/search-util';
import { elementToGetBoundingClientRect } from 'src/utils/virtualElement';
import { v4 as uuid4 } from 'uuid';
import { SettingModalCommon, SettingProvider } from '../common';
import { OpenSettingFrom } from '../type';
import { FoldItemList } from './fold-item-list';

/** 成员组 Tab */
export const MemberGroups: FC = () => {
  const openModal = useOpenModal();
  const currentSpace = useCurrentSpace();
  const currentUser = useCurrentUser();
  const transaction = useTransaction();
  const updateSpace = useUpdateSpace();
  /** 点击升级空间 */
  const { checkoutUpgraded } = useModel(SettingProvider);
  const isTeamProSpace = useIsTeamProSpace();
  const getSpaceRolePermission = useGetSpaceRolePermission();
  const { editor: editorUser } = getSpaceRolePermission(currentUser.uuid);
  const { permissionGroups = [] } = currentSpace;
  const [renderList, setRenderList] = useState(permissionGroups);
  const [searchKeywords, setSearchKeywords] = useState('');
  const [lastCreateGroup, setLastCreateGroup] = useState<string>('');

  useEffect(() => {
    setRenderList(permissionGroups);
  }, [permissionGroups]);

  /** 保存组信息，由于修改名字会频繁调用接口，所以做一层节流 */
  const saveChangeGroupInfoThrottle = (permissionGroups: PermissionGroupDTO[] = []) => {
    transaction(() => {
      void updateSpace(currentSpace.uuid, {
        permissionGroups,
      });
    });
  };

  const saveChangeGroupInfo = useRef(throttle(saveChangeGroupInfoThrottle, 1000)).current;

  /** 更新组的字段 */
  const updateGroupInfo = <K extends keyof PermissionGroupDTO>(
    id: string,
    key: K,
    value: PermissionGroupDTO[K]
  ) => {
    const newGroups: typeof renderList = [];
    renderList.forEach((item) => {
      if (item.id === id) {
        newGroups.push({ ...item, [key]: value });
      } else {
        newGroups.push(item);
      }
    });

    /** 修改字段直接同步到state */
    setRenderList(newGroups);
    void saveChangeGroupInfo(newGroups);
  };

  /** 创建组 */
  const createGroup = () => {
    const newGroup = {
      name: '',
      id: uuid4(),
      userIds: [],
    };
    bizTracker.event('new_group');
    setLastCreateGroup(newGroup.id);
    void saveChangeGroupInfo([newGroup, ...renderList]);
  };

  /** 删除分组 */
  const deleteGroup = (groupId: string) => {
    openModal.warning({
      title: '确定删除当前分组吗？',
      content: '如果有仅该组成员可管理的页面将会转移给你管理。',
      confirmText: '确认删除',
      colorType: 'red',
      confirm: () => {
        bizTracker.event('delete_group');
        void saveChangeGroupInfo(renderList.filter((i) => i.id !== groupId));
      },
    });
  };

  /** 分组排序 */
  const sortGroup = (newGroups: PermissionGroupDTO[] = []) => {
    setRenderList(newGroups);
    void saveChangeGroupInfo(newGroups);
  };

  /** 如果不是团队空间，则提示升级空间 */
  if (!isTeamProSpace) {
    return (
      <div>
        <div className="h-9 text-t1-medium flex items-center mt-3.5">升级空间开启成员组</div>
        <div className="text-t3 text-grey3 h-9 flex items-center mb-5">
          你可以将任意成员组成 1 个或多个成员组，成员组可以帮你更方便的配置页面协作权限。
        </div>
        <Button
          className="!text-t2-medium"
          colorType="active"
          onClick={() => checkoutUpgraded(OpenSettingFrom.group)}
        >
          升级空间
        </Button>
      </div>
    );
  }

  return (
    <div className="mt-3.5">
      <div className="mb-9">
        <div className="flex items-center mb-2.5 h-9">
          你可以将任意成员组成 1 个或多个成员组，成员组可以帮你更方便的配置页面协作权限。
        </div>
        <div className="flex justify-between items-center h-14">
          {editorUser ? (
            <Button colorType="active" className="text-t2-medium" onClick={createGroup}>
              创建成员组
            </Button>
          ) : (
            <div />
          )}
          {!!renderList.length && (
            <Input
              className="pl-3 pr-1.5 w-full max-w-[300px] h-8 rounded-full"
              inputClassName="px-0"
              value={searchKeywords}
              onChange={setSearchKeywords}
              placeholder="搜索人员、手机号"
              addonBefore={<Icon size="middle" name="IcSearchMiddle" className="text-grey4" />}
              addonAfter={
                searchKeywords && (
                  <Icon
                    size="middle"
                    name="IcUploadCancel"
                    onClick={() => setSearchKeywords('')}
                    className="text-grey4 cursor-pointer"
                  />
                )
              }
            />
          )}
        </div>
        {!!renderList.length && <SettingModalCommon.SettingDivider />}
      </div>
      <SortableList
        items={renderList}
        onChange={sortGroup}
        renderItemContent={({ item, renderDragHandle }) => (
          <MemberGroupsComponent
            item={item}
            searchKeywords={searchKeywords}
            lastCreateGroup={lastCreateGroup}
            deleteGroup={deleteGroup}
            renderDragHandle={renderDragHandle}
            updateGroupInfo={updateGroupInfo}
          />
        )}
      />
    </div>
  );
};
/** 成员组组件 */
const MemberGroupsComponent: FC<{
  searchKeywords: string;
  lastCreateGroup: string;
  item: PermissionGroupDTO;
  renderDragHandle: (props: { className?: string; popup?: string }) => React.ReactNode;
  deleteGroup: (groupId: string) => void;
  updateGroupInfo: <K extends keyof PermissionGroupDTO>(
    id: string,
    key: K,
    value: PermissionGroupDTO[K]
  ) => void;
}> = (props) => {
  const { item, lastCreateGroup, searchKeywords, renderDragHandle, updateGroupInfo, deleteGroup } =
    props;
  const openModal = useOpenModal();
  const currentUser = useCurrentUser();

  const getSpaceRolePermission = useGetSpaceRolePermission();
  const { editor: editorUser } = getSpaceRolePermission(currentUser.uuid);
  const searchSpaceMembers = useSearchSpaceMembers();
  const upload = useUpload();
  const space = useCurrentSpace();

  const userList = useMemo(() => {
    const list = searchSpaceMembers({ keywords: searchKeywords });
    return list.filter((user) => item.userIds.includes(user.uuid));
  }, [searchSpaceMembers, searchKeywords, item.userIds]);

  /** 更新组成员 */
  const updateGroupMembers = (ids: string[]) => {
    updateGroupInfo(item.id, 'userIds', ids); // 更新组成员
  };

  /** 打开添加成员弹窗 */
  const openAddMember = (event: MouseEvent) => {
    openModal.dropdown({
      popcorn: elementToGetBoundingClientRect(event.currentTarget),
      placement: 'bottom-start',
      content: ({ onCloseModal }) => (
        <MemberListSelect
          defaultSelectIds={item.userIds}
          updateGroupMembers={updateGroupMembers}
          onCloseModal={onCloseModal}
        />
      ),
    });
  };

  /** 从组中移除用户 */
  const removeMember = (userId: string) => {
    openModal.warning({
      title: '确认移除这位成员吗？',
      confirm: () => {
        updateGroupMembers(item.userIds.filter((id) => id !== userId));
      },
    });
  };

  /** 成员组设置弹窗 */
  const openOption = (event: MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom-start',
      content: ({ onCloseModal }) => (
        <ListView
          className="py-2 w-[270px] next-modal"
          items={[
            {
              type: ListItemType.TEXT_DESCRIPTION,
              data: {
                title: (
                  <div className="text-red">
                    <Icon name="IcMenuDelete" size="middle" className="mr-2" />
                    删除分组
                  </div>
                ),
                onClick: () => {
                  deleteGroup(item.id);
                  onCloseModal();
                },
              },
            },
          ]}
        />
      ),
    });
  };

  /** 修改头像 */
  const openEmoji = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (!editorUser) {
      return;
    }
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom',
      content: ({ onCloseModal }) => (
        <IconPanel
          showRecently={false}
          onSelectEmoji={(emojiStr, isFromRandom) => {
            if (!isFromRandom) {
              onCloseModal();
            }
            updateGroupInfo(item.id, 'icon', {
              type: 'emoji',
              value: emojiStr,
            });
          }}
          onUploadFile={(file) => {
            onCloseModal();
            message.loading({ key: MessageIds.UPLOAD_ICON, content: '头像上传中...' });
            void upload({
              file,
              type: 'public',
              searchType: 'avatar',
              onComplete(ret) {
                if (ret.success) {
                  message.success('上传完成');
                  updateGroupInfo(item.id, 'icon', {
                    type: 'upload',
                    value: ret.ossName,
                  });
                }
              },
            });
          }}
          onRemoveBtnClick={() => {
            onCloseModal();
            updateGroupInfo(item.id, 'icon', {});
          }}
        />
      ),
    });
  };

  return (
    <div className="mb-9">
      <div className="flex relative items-center h-9 group">
        {editorUser &&
          renderDragHandle({
            className:
              'cursor-pointer flex items-center justify-center w-5 h-5 mr-1 text-grey4 absolute top-2 -left-7  opacity-0 group-hover:opacity-100 transition-opacity',
            popup: '长按拖拽',
          })}
        <Avatar
          className={cx('mr-2 text-h4', editorUser && 'cursor-pointer')}
          name={item.name || '未'}
          icon={item.icon}
          onClick={openEmoji}
        />
        <AutoHeightTextArea
          singleLine
          readonly={!editorUser}
          autoFocus={!item.name && lastCreateGroup === item.id}
          value={item.name}
          className="whitespace-nowrap"
          boxClassName="cursor-text rounded animate-hover text-ellipsis"
          placeholder="请输入组名"
          fontClassName={cx(
            'text-t2-medium whitespace-nowrap text-ellipsis',
            !item.name && 'min-w-[70px]'
          )}
          onChange={(event) => updateGroupInfo(item.id, 'name', event.currentTarget.value)}
        />
        {!!item.userIds.length && (
          <div className="ml-2 text-grey3 text-t2 flex-shrink-0">{item.userIds.length}位成员</div>
        )}
        {editorUser && (
          <Icon
            name="IcMore"
            size="middle"
            className="text-grey3 ml-auto flex-shrink-0 animate-hover"
            onClick={openOption}
          />
        )}
      </div>
      <SettingModalCommon.SettingDivider />
      <>
        <FoldItemList
          foldLength={10}
          items={userList}
          renderItemContent={(user) => {
            const markName = space.userRemark?.[user.uuid];
            let userName = user.nickname;
            if (markName) {
              userName = `${markName} (${user.nickname})`;
            }
            return (
              <div key={user.uuid} className="flex items-center justify-between h-9 text-t2 group">
                <div className="flex items-center">
                  <UserAvatar user={user} className="mr-2 !text-t2-medium" />
                  {userName}
                  {user.spaceRole === PermissionRole.EDITOR && (
                    <span className="ml-2 flex items-center px-1.5 bg-grey/20 text-grey3 rounded-sm h-5">
                      管理员
                    </span>
                  )}
                </div>
                {editorUser && (
                  <span
                    className="text-red cursor-pointer opacity-0 group-hover:opacity-100"
                    onClick={() => removeMember(user.uuid)}
                  >
                    移出
                  </span>
                )}
              </div>
            );
          }}
        />

        {editorUser && (
          <div className="flex items-center h-9 text-grey4 cursor-pointer" onClick={openAddMember}>
            <Icon name="IcAddBoard" className="mr-2" size="middle" />
            添加成员
          </div>
        )}
      </>
    </div>
  );
};
/** 成员组添加成员组件 */
const MemberListSelect: FC<{
  onCloseModal: ModalSchema.CloseModalType;
  updateGroupMembers: (ids: string[]) => void;
  defaultSelectIds: string[];
}> = (props) => {
  const { updateGroupMembers, onCloseModal, defaultSelectIds = [] } = props;
  const users = useCurrentSpaceUsers();
  const spaceUser = useSearchSpaceMembers()({ filterIds: defaultSelectIds, users });

  const container = useRef() as MutableRefObject<HTMLDivElement>;
  const [keywords, setKeywords] = useState('');
  const [selectIds, setSelectIds] = useState<string[]>([]);

  /** 选中添加用户 */
  const handleClick = (userId: string) => {
    setKeywords('');
    setSelectIds((pre) => [...pre, userId]);
  };

  /** 移除选中 */
  const removeUser = (userId: string) => {
    setSelectIds((pre) => pre.filter((i) => i !== userId));
  };

  /** backspace删除选中用户 */
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const lastUser = last(selectIds);
    if (isHotkey('BackSpace')(event) && !keywords && lastUser) {
      removeUser(lastUser);
    }
  };

  /** 保存选中列表到用户组 */
  const save = () => {
    updateGroupMembers([...defaultSelectIds, ...selectIds]);
    onCloseModal();
  };

  /** 判断选中是否有修改，是否展示保存按钮 */
  const showSave = selectIds.length > 0;

  /** 底部成员列表，包含搜索的关键词 */
  const renderList = useMemo(() => {
    const restUsers = spaceUser.filter((user) => !selectIds.includes(user.uuid));
    return searchUsers(restUsers, keywords);
  }, [keywords, selectIds, spaceUser]);

  return (
    <div className="w-[400px] next-modal">
      <div className="p-2.5 pb-1 border-b border-grey6">
        <div className="flex flex-wrap items-center">
          {selectIds.map((id) => {
            const user = users[id];
            if (!user) {
              return null;
            }
            return (
              <span
                key={user.uuid}
                className="flex items-center mr-1.5 mb-1.5 cursor-default text-t2"
              >
                <UserAvatar user={user} className="mr-2 !text-t2-medium" />
                {getUserName(id) || UNNAMED_USER}
                <Icon
                  name="IcToastClose"
                  size="middle"
                  className="opacity-50"
                  onClick={() => removeUser(id)}
                />
              </span>
            );
          })}
          <Input
            autoFocus
            showBorder={false}
            className="flex-1 mb-1.5 h-6 !bg-transparent min-w-[100px]"
            inputClassName="px-0 text-t2"
            value={keywords}
            onChange={setKeywords}
            onKeyDown={handleKeyDown}
            placeholder="搜索成员昵称"
          />
        </div>
        <div className="flex justify-end -translate-y-1" hidden={!showSave}>
          <Button onClick={save} colorType="active" className="text-t2-medium">
            添加
          </Button>
        </div>
      </div>
      <div ref={container} className="overflow-y-auto p-2.5 w-[400px] max-h-[40vh]">
        <DirectionScroller containerRef={container} itemCount={renderList.length}>
          {(activeIndex: number, setSelectedIndex: (n: number) => void) =>
            renderList.map((item, index) => (
              <div
                key={item.uuid}
                onClick={() => handleClick(item.uuid)}
                className={cx(
                  'flex items-center h-10 text-t2 w-full px-1 cursor-pointer animate-hover rounded',
                  activeIndex === index && 'normal-bg'
                )}
                onMouseEnter={() => setSelectedIndex(index)}
              >
                <UserAvatar user={item} className="mr-2 !text-t2-medium" />
                {getUserName(item.uuid) || UNNAMED_USER}
              </div>
            ))
          }
        </DirectionScroller>
        {!renderList.length && (
          <div className="flex items-center text-grey3 cursor-default text-t2">无搜索结果</div>
        )}
      </div>
    </div>
  );
};
