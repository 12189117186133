import { cx } from '@flowus/common/cx';
import type { BlockDTO } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { RichTextDiff } from 'src/components/rich-text-diff';

export const FileNameChange: FC<{
  className?: string;
  from: BlockDTO;
  to: BlockDTO;
  onClick?: () => void;
}> = ({ className, from, to, onClick }) => {
  return (
    <div className="mt-1 px-1 animate-hover" onClick={onClick}>
      <div>
        <span className={cx('text-t4 text-grey3', onClick && 'animate-hover', className)}>
          <Icon
            name={'IcMenuListDescription'}
            size="middle"
            className="mr-1 align-middle relative bottom-[1px]"
          />
          <span>文件重命名</span>
        </span>
      </div>
      <div className="flex items-center mt-1">
        <RichTextDiff segmentsA={from.data.segments} segmentsB={to.data.segments} />
      </div>
    </div>
  );
};
