import { Tab, TabPane } from '@flowus/common/components';
import type { SharePagePayRecord, WithdrawOrderDTO } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { request } from 'src/common/request';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { blocksActions } from 'src/redux/reducers/blocks';
import { cache, dispatch } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { getCurrencySymbols } from 'src/utils/currency-format';
import { Tooltip } from 'src/common/components/tooltip';
import { DATE_TIME_FORMAT } from 'src/common/const';
import { usersActions } from 'src/redux/reducers/users';
import { DefaultPage } from 'src/editor/editor/raw/default-page';
import { SegmentPlainText } from '../../aside/toc/helper';
import { usePickBlock } from 'src/utils/pick-block';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { getUserName } from 'src/hooks/user/use-remark-name';
import { useOpenPage } from 'src/hooks/page/use-open-page';

export const IncomeRecord: FC = () => {
  return (
    <Tab active={0} className="w-[70vw] px-4 h-[500px]" childrenClassName="h-[calc(100%-50px)]">
      <TabPane title="收支明细">
        <IncomeList />
      </TabPane>

      <TabPane title="提现记录">
        <CashWithdrawList />
      </TabPane>
    </Tab>
  );
};

const CashWithdrawList: FC = () => {
  const [orderList, setOrderList] = useState<WithdrawOrderDTO[]>();
  useEffect(() => {
    // 每天只允许提现一次，所以loadmore就先不做了吧)
    void request.infra.getWithdrawOrderList(1, 365, getCurrentSpaceId()).then((res) => {
      setOrderList(res.list);
    });
  }, []);
  if (orderList === undefined) return null;
  const getPayStatusDesc = (status?: number) => {
    // 状态 0 可提现, 1 已提现, 2 已取消
    switch (status) {
      case 0: {
        return '可提现';
      }
      case 1: {
        return '已提现';
      }
      default: {
        return '已取消';
      }
    }
  };
  return (
    <div className="h-full px-1 flex flex-col justify-between items-center overflow-y-hidden overscroll-x-auto flex-1">
      <div className="grid grid-cols-10 gap-x-2 w-full py-2.5 text-t2 border-b">
        <div className="col-span-3">记录内容</div>
        <div className="col-span-1">状态</div>
        <div className="col-span-1">金额</div>
        <div className="col-span-3">收款账户</div>
        <div className="col-span-2">创建时间</div>
      </div>
      <div className="overflow-y-auto w-full h-full">
        {orderList.map((item, index) => {
          item.total;
          return (
            <div className="grid grid-cols-10 gap-x-2 w-full py-2.5 text-t2" key={index}>
              <div className="col-span-3 break-words">{item.description ?? '空'}</div>
              <div className="break-words col-span-1">
                <div>{getPayStatusDesc(item.status)}</div>
              </div>
              <div className="break-words col-span-1">
                {/* TODO 需要后台支持 */}
                {getCurrencySymbols(undefined)}
                {item.total}
              </div>
              <Tooltip className="col-span-3" popup={getUserName(item.userId ?? '')}>
                <div className="text-ellipsis">
                  {`${getUserName(item.userId ?? '')}(${cache.users[item.userId ?? '']?.phone})`}
                </div>
              </Tooltip>
              <div className="break-words col-span-2">
                {item.createdAt ? dayjs(new Date(item.createdAt)).format(DATE_TIME_FORMAT) : ''}
              </div>
            </div>
          );
        })}
        {orderList.length === 0 && (
          <div className="text-t4 text-center text-grey3 mt-3">暂无提现记录</div>
        )}
      </div>
    </div>
  );
};
const IncomeList: FC = () => {
  const [payRecord, setPayRecord] = useState<SharePagePayRecord[]>();

  useEffect(() => {
    void request.infra.getSharePaidRecords(getCurrentSpaceId(), 1, 500).then((res) => {
      res.recordMap.blocks &&
        dispatch(
          blocksActions.update({
            blocks: res.recordMap.blocks as Record<string, NextBlock>,
          })
        );
      res.recordMap.users &&
        dispatch(usersActions.updateSpaceUsers({ users: res.recordMap.users }));
      setPayRecord(res.results);
    });
  }, []);

  if (payRecord === undefined) return null;
  const getPayStatusDesc = (status?: number) => {
    // 状态 0 未付款, 1 已支付, 2 已取消,3 已提现
    switch (status) {
      case 0: {
        return '未付款';
      }
      case 2: {
        return '已取消';
      }
      default: {
        return '已支付';
      }
    }
  };
  return (
    <div className="h-full px-1 flex flex-col justify-between items-center overflow-y-hidden overscroll-x-auto flex-1">
      <div className="grid grid-cols-10 gap-x-2 w-full py-2.5 text-t2 border-b">
        <div className="col-span-3">页面来源</div>
        <div className="col-span-1">类型</div>
        <div className="col-span-1">金额</div>
        <div className="col-span-3">付款人</div>
        <div className="col-span-2">创建时间</div>
        {/* <div className="col-span-1">操作</div> */}
      </div>
      <div className="overflow-y-auto w-full h-full">
        {payRecord.map((item, index) => {
          item.blockId;
          return (
            <div className="grid grid-cols-10 gap-x-2 w-full py-2.5 text-t2" key={index}>
              <div className="col-span-3 break-words">
                <Page id={item.blockId ?? ''} />
              </div>
              <div className="break-words col-span-1">
                <div>{getPayStatusDesc(item.status)}</div>
              </div>
              <div className="break-words col-span-1">
                {/* TODO 需要后台支持 */}
                {getCurrencySymbols(undefined)}
                {item.total}
              </div>
              <Tooltip className="col-span-3" popup={getUserName(item.userId ?? '')}>
                <div className="text-ellipsis">
                  {`${getUserName(item.userId ?? '')}(${cache.users[item.userId ?? '']?.phone})`}
                </div>
              </Tooltip>
              <div className="break-words col-span-2">
                {item.createdAt ? dayjs(new Date(item.createdAt)).format(DATE_TIME_FORMAT) : ''}
              </div>
            </div>
          );
        })}
        {payRecord.length === 0 && (
          <div className="text-t4 text-center text-grey3 mt-3">暂无收支明细</div>
        )}
      </div>
    </div>
  );
};

const Page: FC<{ id: string }> = (props) => {
  const { illegal } = usePermissions(props.id);
  const block = usePickBlock(props.id, ['data'], ['segments']);
  const title = !block ? '无法访问' : <SegmentPlainText uuid={props.id ?? ''} />;
  const openPage = useOpenPage();
  const handlePageClick = (event: React.MouseEvent) => {
    openPage(props.id, {
      forceOpenNewTab: true,
    });
  };
  return <DefaultPage id={props.id} title={title} illegal={illegal} onClick={handlePageClick} />;
};
