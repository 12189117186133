import { cx } from '@flowus/common/cx';
import { ossVideoSnapshotUrl } from '@flowus/common/url';
import type { SegmentDTO } from '@next-space/fe-api-idl';
import type { IContent, ISelection } from '@next-space/fe-inlined';
import React from 'react';
import { Icon } from 'src/common/components/icon';
import { DEFAULT_PAGE_WIDTH } from 'src/common/const';
import { VideoJS } from 'src/components/video';
import { InfiniteProgress } from 'src/editor/component/image/infinite-progress';
import { ResizeElement } from 'src/editor/component/resize-element';
import { useResize } from 'src/hooks/block/use-resize';
import { useThrottleUpdateSegments } from 'src/hooks/block/use-throttle-update-block';
import { useReadonly } from 'src/hooks/page/use-read-only';
import { useResource } from 'src/hooks/public/use-resource';
import { useTextareaPlaceHolder } from 'src/services/app';
import { useUploadInfoById } from 'src/services/upload';
import { $searchParams } from 'src/utils';
import { usePickBlock } from 'src/utils/pick-block';
import { NodeWrapper } from '../component/node-wrapper';
import { useMonitorFocused } from '../hook/use-monitor-focused';
import type { MindNodeElement } from './all-node-renderer';
import { MindMapRichText } from './editor/mind-map-rich-text';

export const VideoNode: MindNodeElement = React.memo((props) => {
  const blockId = props.ownerBlockId ?? props.id;
  const block = usePickBlock(props.id, ['data'], ['caption', 'width', 'link']);
  const { alwaysShowPlaceHolder, placeHolder } = useTextareaPlaceHolder(props.id);
  const readonly = useReadonly(blockId);
  const updateSegments = useThrottleUpdateSegments(props.id, 'caption');
  const { url } = useResource(props.id);
  const uploadInfo = useUploadInfoById(props.id);
  const {
    onRenderSize,
    changeSize,
    containerWidth,
    containerRef,
    isDisable,
    defaultBlockFullWidth,
    defaultWidth,
    defaultHeight,
    widthBreakPoints,
  } = useResize({
    id: blockId,
    root: false,
    fullPageContentWidth: false,
  });
  useMonitorFocused(containerRef, props.id, 'caption');
  if (!block) return null;
  return (
    <NodeWrapper id={blockId} level={props.level} ref={containerRef} className="max-w-[380px]">
      <div className="w-min px-3">
        <MindMapRichText
          placeholder={placeHolder}
          uuid={props.id}
          alwaysShowPlaceholder={alwaysShowPlaceHolder}
          className="whitespace-pre-wrap break-words"
          segments={block.data.caption}
          segmentType="caption"
          onChange={(
            segments: SegmentDTO[],
            prevContent: IContent,
            prevSelection: ISelection | null
          ) => {
            updateSegments(segments, [prevContent, prevSelection]);
          }}
        />
        <ResizeElement
          fullHeight
          resizeWidth
          readonly={readonly || isDisable}
          disableStyle={!defaultWidth}
          defaultWidth={defaultWidth ?? 0}
          defaultHeight={defaultHeight}
          defaultBlockFullWidth={defaultBlockFullWidth}
          maxWidth={block.data.width ?? DEFAULT_PAGE_WIDTH}
          widthBreakPoints={widthBreakPoints}
          minHeight={36}
          minWidth={36}
          onChange={changeSize}
          onRenderSize={onRenderSize}
          className={cx(
            'max-[520px]',
            isDisable && 'pointer-events-none',
            $searchParams.print && 'my-2.5'
          )}
        >
          {url && (
            <VideoJS
              videoUrl={url}
              style={{
                width: containerWidth,
                cursor: 'pointer',
              }}
              options={{
                fluid: true,
                poster: ossVideoSnapshotUrl(url),
              }}
            />
          )}
          {uploadInfo?.progress && (
            <div className="absolute flex items-center px-1.5 right-2.5 top-2.5 rounded-sm bg-black/75">
              <InfiniteProgress />
              <div className="text-white text-t4">{uploadInfo?.progress}%</div>
            </div>
          )}
          {block?.data.link && (
            <div className="absolute flex items-center top-2.5 right-2.5 bg-black/75 group-hover:opacity-100 opacity-0 rounded-sm">
              <Icon size="middle" className="" name={'IcUploadWeb'} style={{ color: 'white' }} />
            </div>
          )}
        </ResizeElement>
      </div>
    </NodeWrapper>
  );
});
