import type { CaptchaSendType } from '@next-space/fe-api-idl';

import { loginInjector } from '../utils/init';

export enum SmsCode {}

interface VerifySmsCodeParams {
  phone: string;
  code: string;
  onError?: (err: string) => void;
  type: CaptchaSendType;
}
export const useVerifySmsCode = () => {
  return async (params: VerifySmsCodeParams) => {
    const { phone, code, onError, type } = params;
    const res = await loginInjector.request.infra.phoneAuthorization.raw({
      phone,
      captcha: code,
      type,
    });
    if (res.code === 200) {
      return res.data;
    }
    onError?.(res.msg);
  };
};
