/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { cx } from '@flowus/common/cx';
import { checkImagePreviewFormat, FileRegex } from '@flowus/common/regex';
import { BlockType } from '@next-space/fe-api-idl';
import { useHover } from 'ahooks';
import { cloneDeep, last } from 'lodash-es';
import type { FC, MouseEvent } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from 'src/common/components/icon';
import { LoadingContainer } from 'src/common/components/loading-container';
import { useOpenModal } from 'src/common/components/next-modal';
import { ProgressBar } from 'src/common/components/progress-bar';
import { Tooltip } from 'src/common/components/tooltip';
import { ILLEGAL_TEXT } from 'src/common/const';
import { BlockDefaultIcon } from 'src/components/block-default-icon';
import { FileNameUiKit } from 'src/components/file-name-uikit';
import { useOpenFilePreview } from 'src/components/file-preview/use-open-file-preview';
import { IconTrigger } from 'src/components/icon-trigger';
import { IconUiSizeBox } from 'src/components/icon-ui-size-box';
import { ImagesProvider } from 'src/components/images-provider';
import { useOpenImagePreview } from 'src/components/images-provider/provider';
import { PagePreview } from 'src/components/page-preview';
import { FloatToolbar } from 'src/editor/component/float-toolbar';
import { segmentsToText } from 'src/editor/utils/editor';
import { useGetFolder } from 'src/hooks/block/use-get-folder';
import { useChangeViewMode } from 'src/hooks/block/use-update-view-mode';
import { useMultipleDownload } from 'src/hooks/drive/use-multiple-download';
import { useReadonly } from 'src/hooks/page';
import { useFetchBlockSubNode } from 'src/hooks/page/use-fetch-block-subNode';
import { usePermissions } from 'src/hooks/share/use-permissions';
import type { NextBlock } from 'src/redux/types';
import { UploadStatus, ViewModel } from 'src/redux/types';
import { useDisableInteractive } from 'src/services/app/hook/use-drop-info';
import { useFolderUploadingCount, useUploadFiles } from 'src/services/upload';
import { isInColumnList } from 'src/utils/block-utils';
import { getFileIcon, getFileNameInfo } from 'src/utils/file';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { judgeSharePage, useGetPageId } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { PageScene, usePageScene } from 'src/views/main/scene-context';
import type { Instance, Props } from 'tippy.js';
import { ViewModelCardView, ViewModelNormal, ViewModelPreView } from '../../component/view-mode';
import type { BlockElement } from '../core/type';

// #region start
export const FolderBlockElement: BlockElement = ({ id: uuid, root, ownerBlockId }) => {
  const folderBlock = usePickBlock(uuid, ['subNodes', 'data'], ['viewMode']);
  const ownerBlock = usePickBlock(ownerBlockId, ['data'], ['ref', 'viewMode']);
  const readonly = useReadonly(uuid);
  // 如果有refId并且找不到block，说明在网盘被彻底删除了
  const showNotFound = Boolean(ownerBlock?.data.ref?.uuid && !folderBlock);
  const blocks = useGetFolder(uuid);
  const viewMode = ownerBlock?.data.viewMode ?? folderBlock?.data.viewMode;
  const { illegal } = usePermissions(uuid);
  const scene = usePageScene();
  const uploadingCount = useFolderUploadingCount(uuid);
  // const fileCount = uploadingCount + blocks.files.length + blocks.folder.length;
  const fileCount = folderBlock?.subNodes.length || 0;
  const changeViewModel = useChangeViewMode(ownerBlockId || uuid);
  const tippyInstance = useRef<Instance<Props>>();
  const pageId = useGetPageId();
  const inColumn = useMemo(() => isInColumnList(uuid, pageId), [pageId, uuid]);
  const disableInteractive = useDisableInteractive();

  // 被风控了
  if (illegal) {
    return (
      <ViewModelNormal
        {...{
          uuid,
          root,
          ownerBlockId,
          isFile: true,
          title: ILLEGAL_TEXT,
          iconName: fileCount ? 'IcFolderContentSmall' : 'IcFolderMiddle',
        }}
      />
    );
  }

  if (uploadingCount > 0 && blocks.folder.length + blocks.files.length === 0) {
    return (
      <ViewModelNormal
        {...{
          ownerBlockId,
          root,
          uuid,
          readonly,
          iconName: fileCount ? 'IcFolderContentSmall' : 'IcFolderMiddle',
          title: `文件夹上传中 还剩 ${uploadingCount} 个`,
          context: `${fileCount} 项内容`,
        }}
      />
    );
  }

  const viewProps = {
    showNotFound,
    uuid,
    root: !!root,
    ownerBlockId,
    fileCount,
    readonly,
  };
  let element = (
    <Tooltip
      lazyLoad={true}
      interactive={!disableInteractive}
      onShow={(instance) => {
        tippyInstance.current = instance;
      }}
      theme="none"
      popupClass="next-modal"
      offset={[0, 5]}
      placement={inColumn ? 'left' : 'top-start'}
      className="w-full"
      maxWidth={10000}
      delay={[500, 50]}
      animation={'shift-away'}
      popup={
        <PagePreview
          pageId={uuid}
          isLink={Boolean(ownerBlockId)}
          onCloseOutsideModel={() => {
            tippyInstance.current?.hide();
          }}
        />
      }
    >
      <NormalView {...viewProps} />
    </Tooltip>
  );
  // element = <NormalView {...viewProps} />;

  let showType: 'default' | 'small' | 'large' = 'default';
  if (viewMode === ViewModel.card) {
    element = (
      <Tooltip
        lazyLoad={true}
        onShow={(instance) => {
          tippyInstance.current = instance;
        }}
        theme="none"
        interactive={true}
        popupClass="next-modal"
        offset={[0, 5]}
        placement={inColumn ? 'left' : 'top-start'}
        className="w-full"
        maxWidth={10000}
        delay={[500, 50]}
        animation={'shift-away'}
        popup={
          <PagePreview
            pageId={uuid}
            isLink={Boolean(ownerBlockId)}
            onCloseOutsideModel={() => {
              tippyInstance.current?.hide();
            }}
          />
        }
      >
        <CardView {...viewProps} />
      </Tooltip>
    );
    showType = 'small';
  }

  if (viewMode === ViewModel.preview) {
    if (scene === PageScene.PAGE_HISTORY) {
      element = <CardView {...viewProps} />;
      showType = 'small';
    }
    element = <PreView {...viewProps} />;
    showType = 'large';
  }
  return (
    <Tooltip
      theme="next-modal"
      placement="top-end"
      className="my-1"
      interactive={true}
      animation={'shift-away'}
      interactiveDebounce={0}
      popup={
        !readonly &&
        !judgeSharePage() && (
          <FloatToolbar
            showType={showType}
            onClick={(showType) => {
              switch (showType) {
                case 'default':
                  changeViewModel('normal');
                  break;
                case 'small':
                  changeViewModel('card');
                  break;
                case 'large':
                  changeViewModel('preview');
                  break;
                default:
              }
            }}
          />
        )
      }
    >
      {element}
    </Tooltip>
  );
};
// #endregion

interface ViewProps {
  fileCount: number;
  uuid: string;
  root: boolean;
  ownerBlockId?: string;
  showNotFound: boolean;
  readonly: boolean;
}
// #region normal view
const NormalView: FC<ViewProps> = (props) => {
  const { uuid, root, ownerBlockId, showNotFound, readonly, fileCount } = props;
  const folderBlock = usePickBlock(uuid, ['data', 'subNodes'], ['segments']);
  const fileName = segmentsToText(folderBlock?.data.segments) || getUntitledName(BlockType.FOLDER);

  return (
    <ViewModelNormal
      {...{
        ownerBlockId,
        root,
        uuid,
        readonly,
        iconName: fileCount ? 'IcFolderContentSmall' : 'IcFolderMiddle',
        title: showNotFound ? '文件夹已被删除' : fileName,
        context: `${fileCount} 项内容`,
      }}
    />
  );
};
// #endregion

// #region card view
const CardView: FC<ViewProps> = (props) => {
  const { uuid, ownerBlockId, root, fileCount, showNotFound, readonly } = props;
  const folderBlock = usePickBlock(uuid, ['data'], ['segments']);
  const onReplace = () => {};
  const fileName = segmentsToText(folderBlock?.data.segments) || getUntitledName(BlockType.FOLDER);

  return (
    <ViewModelCardView
      {...{
        root,
        uuid,
        onReplace,
        ownerBlockId,
        readonly,
        title: showNotFound ? '文件夹已被删除' : fileName,
        context: `${fileCount} 项内容`,
        iconName: fileCount ? 'IcFolderContentSmall' : 'IcFolderMiddle',
      }}
    />
  );
};
// #endregion

// #region preview

/** 文件列表 */
interface FileItemProps {
  block: NextBlock;
  readonly?: boolean;
  clickFolder?: (uuid: string, title: string) => void;
}
const FileItem: FC<FileItemProps> = (props) => {
  const { block, readonly, clickFolder } = props;
  const isFile = block.type === BlockType.FILE;
  const fileName: string = segmentsToText(block.data.segments) || getUntitledName(block.type);
  const fileInfo = getFileNameInfo(fileName);
  const openFilePreview = useOpenFilePreview();
  const multipleDownload = useMultipleDownload();
  const ref = useRef<HTMLDivElement>(null);
  const isHovering = useHover(ref.current);
  const openImagePreview = useOpenImagePreview();
  // 被风控了
  const { illegal } = usePermissions(block.uuid);

  const handleClick = useCallback(() => {
    if (isFile) {
      if (FileRegex.pdf.test(fileInfo.extName)) {
        window.open(`/${judgeSharePage() ? 'share/' : 'preview/'}${block.uuid}`);
      } else {
        if (checkImagePreviewFormat(fileInfo.extName)) {
          openImagePreview({ uuid: block.uuid });
          return;
        }
        openFilePreview(block.uuid);
      }
    } else {
      clickFolder?.(block.uuid, fileName);
    }
  }, [
    block.uuid,
    clickFolder,
    fileInfo.extName,
    fileName,
    isFile,
    openFilePreview,
    openImagePreview,
  ]);

  const handleDownLoad = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      void multipleDownload([block.uuid]);
    },
    [block.uuid, multipleDownload]
  );

  return (
    <div
      ref={ref}
      className="flex items-center justify-between px-1 cursor-pointer h-11 text-t2 animate-hover-black3"
      onClick={handleClick}
    >
      <div className="flex w-full items-center">
        {isFile ? (
          <Icon name={getFileIcon(fileInfo.extName)} className="ml-1 mr-2" size="middle" />
        ) : (
          <IconTrigger
            iconSize={20}
            blockId={block.uuid}
            className="mr-2"
            defaultIcon={<BlockDefaultIcon uuid={block.uuid} size="middle" />}
          />
        )}
        <Tooltip placement="top-start" arrow className="w-full pr-9" popup={fileName}>
          {isFile ? <FileNameUiKit fileName={fileName} /> : <span>{fileName}</span>}
        </Tooltip>
      </div>
      <div className="flex">
        {!readonly && !illegal && (
          <Icon
            name="IcMenuDownload"
            onClick={handleDownLoad}
            className={cx(
              'transition-opacity rounded-sm animate-hover',
              isHovering ? 'opacity-100' : 'opacity-0'
            )}
            size="middle"
          />
        )}
      </div>
    </div>
  );
};

/** 面包屑 */
interface NavsProps {
  navs: { uuid: string; title: string }[];
  clickFolder: (uuid: string, title: string) => void;
}
const RenderNavs: FC<NavsProps> = (props) => {
  const { navs, clickFolder } = props;
  const openModal = useOpenModal();
  const NavItem = (item: { uuid: string; title: string }, index: number) => (
    <div key={item.uuid} className="flex items-center">
      {index !== 0 && <div className="px-0.5 text-grey4">/</div>}
      <Tooltip
        popup={item.title || getUntitledName(BlockType.FOLDER)}
        onClick={() => clickFolder(item.uuid, item.title)}
        className={cx(
          'block rounded-sm animate-hover text-ellipsis max-w-[200px]',
          index !== 0 ? 'px-2' : 'px-0.5'
        )}
      >
        {item.title || getUntitledName(BlockType.FOLDER)}
      </Tooltip>
    </div>
  );

  const moreNavs = cloneDeep(navs).splice(1, navs.length - 2);

  const openMore = (event: MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom',
      offset: [0, 4],
      content: ({ onCloseModal }) => (
        <div className="w-44 p-2 next-modal overflow-y-auto max-h-[30vh]">
          {moreNavs.map((item) => (
            <div
              key={item.uuid}
              onClick={() => {
                clickFolder(item.uuid, item.title);
                onCloseModal();
              }}
              className="flex items-center p-2 rounded cursor-pointer animate-hover text-t2"
            >
              <BlockDefaultIcon uuid={item.uuid} size="middle" className="mr-2" />
              <div className="text-ellipsis">{item.title || getUntitledName(BlockType.FOLDER)}</div>
            </div>
          ))}
        </div>
      ),
    });
  };

  return navs.length > 3 ? (
    <>
      {NavItem(navs[0]!, 0)}
      <span className="px-0.5 text-grey4">/</span>
      <IconUiSizeBox
        popup="查看折叠的页面路径"
        onClick={openMore}
        className="rounded-sm cursor-pointer animate-hover"
      >
        ...
      </IconUiSizeBox>
      {NavItem(last(navs)!, navs.length - 2)}
    </>
  ) : (
    <>{navs.map((item, index) => NavItem(item, index))}</>
  );
};

/** 渲染组件 */
const PreView: FC<ViewProps> = (props) => {
  const { uuid, ownerBlockId, root, showNotFound, readonly } = props;
  const [currentId, setCurrentId] = useState(uuid);
  const folderBlock = usePickBlock(uuid, ['data', 'subNodes'], ['segments']);
  const [errorCode, setErrorCode] = useState<number | null>();
  const [loading, setLoading] = useState(false);
  const [navs, setNavs] = useState<{ uuid: string; title: string }[]>([]);
  const fetchBlockSubNode = useFetchBlockSubNode();
  const history = useHistory();
  const blocks = useGetFolder(currentId);
  const uploadingCount = useFolderUploadingCount(currentId);
  const len = blocks.folder.length + blocks.files.length + uploadingCount;

  useEffect(() => {
    setCurrentId(uuid);
    setNavs([]);
  }, [uuid]);

  useEffect(() => {
    if ((showNotFound && !len) || (currentId === uuid && (folderBlock?.subNodes ?? 0) === 0)) {
      setErrorCode(null);
      return;
    }
    // 延迟一定时间后请求接口，避免拖拽后引发bug
    setLoading(true);
    const timer = setTimeout(() => {
      void fetchBlockSubNode(currentId).then((res) => {
        setErrorCode(res);
        setLoading(false);
      });
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [currentId, fetchBlockSubNode, showNotFound, len, uuid, folderBlock?.subNodes]);

  const clickFolder = (clickId: string, title: string) => {
    setNavs((pre) => {
      const index = pre.findIndex((i) => i.uuid === clickId);
      if (uuid === clickId) {
        return [];
      }

      if (index === -1) {
        pre.push({ uuid: clickId, title });
        return pre;
      }

      pre.splice(index + 1, pre.length - index);
      return pre;
    });
    setCurrentId(clickId);
  };

  const ErrorContent = useMemo(() => {
    const isNotShare = errorCode === 1403;
    const element = (
      <div className="flex flex-col items-center justify-center flex-1 h-full text-grey3 text-t2 bg-grey6">
        <Icon name="IcUploadImageFaild" size="large" className="mb-2" />
        <span>{isNotShare ? '无法访问该页面' : '文件夹已被删除'}</span>
        {isNotShare && <span className="mt-1 text-grey3">当前页面并未开启分享</span>}
      </div>
    );

    if (showNotFound) {
      return element;
    }
    switch (errorCode) {
      case 1403:
      case 3005:
      case 1600:
        return element;
      default:
        return null;
    }
  }, [errorCode, showNotFound]);

  return (
    <ViewModelPreView
      {...{
        root,
        uuid,
        ownerBlockId,
        readonly,
        title: (
          <div className="flex items-center">
            {showNotFound && !len ? (
              '文件夹已被删除'
            ) : (
              <RenderNavs
                navs={[{ uuid, title: segmentsToText(folderBlock?.data.segments) }, ...navs]}
                clickFolder={clickFolder}
              />
            )}
          </div>
        ),
        context: `${len} 项内容`,
        iconName: len ? 'IcFolderContentSmall' : 'IcFolderMiddle',
        viewModelToolBarData: {
          onOpen: () => {
            history.push(`/${currentId}`);
          },
        },
      }}
    >
      <ImagesProvider uuid={currentId}>
        {errorCode || showNotFound ? (
          ErrorContent
        ) : len > 0 ? (
          <div className="overflow-x-hidden overflow-y-auto">
            <LoadList uuid={currentId} />
            {blocks.folder.map((item) => (
              <FileItem
                readonly={readonly}
                key={item.uuid}
                block={item}
                clickFolder={clickFolder}
              />
            ))}
            {blocks.files.map((item) => (
              <FileItem readonly={readonly} key={item.uuid} block={item} />
            ))}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-full bg-grey8 text-grey4 text-t2">
            {loading ? (
              <LoadingContainer />
            ) : (
              <>
                <Icon name="IcUploadImageFaild" size="large" className="mb-2" />
                <span>当前文件夹为空</span>
              </>
            )}
          </div>
        )}
      </ImagesProvider>
    </ViewModelPreView>
  );
};

/** 上传文件 */
const LoadList: FC<{ uuid: string }> = ({ uuid }) => {
  const uploadFiles = useUploadFiles(uuid);
  return (
    <>
      {uploadFiles.map((info) => {
        const fileName = info.name ?? '';
        const fileNameInfo = getFileNameInfo(fileName);

        return (
          <div
            className="relative flex px-2 py-3 text-t2 animate-hover-black3 group"
            key={info.key}
          >
            <Icon name={getFileIcon(fileNameInfo.extName)} size="middle" className="mr-2" />
            <FileNameUiKit className="sm:leading-[15px]" fileName={fileName} />
            <ProgressBar
              progress={info.progress ?? -1}
              fail={info.status === UploadStatus.failure}
              className={cx('absolute z-10 h-[3px] w-full left-0 bottom-[0px] ')}
            />
          </div>
        );
      })}
    </>
  );
};
// #endregion
