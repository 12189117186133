import type { FC } from 'react';
import { useEffect } from 'react';
import { PromptEditorAside } from './prompt-editor-aside';
import { PromptContent } from './prompt-content';
import { usePromptStore } from './store';
import { useUpdateSpaceView } from 'src/hooks/space/use-update-space-view';
import { getCurrentSpaceView } from 'src/hooks/space/get-space';
import { useTransaction } from 'src/hooks/use-transaction';

export const PromptEditor: FC = () => {
  const curentPromptId = usePromptStore((s) => s.curentPromptId);
  const updateSpaceView = useUpdateSpaceView();
  const transaction = useTransaction();

  useEffect(() => {
    return () => {
      const store = usePromptStore.getState();
      if (!store.hasUpdate) return;
      const spaceView = getCurrentSpaceView();
      if (!spaceView) return;
      transaction(() => {
        updateSpaceView(spaceView.uuid, {
          setting: {
            // @ts-ignore type need added in idl
            aiPrompt: {
              editPrompts: store.editPrompts,
              emptyWithPrompts: store.emptyWithPrompts,
              records: store.records,
            },
          },
        });
      });
    };
  }, []);

  return (
    <div className="next-modal flex w-[900px] h-[420px]">
      <PromptEditorAside
        onChangePrompt={(id) => {
          usePromptStore.setState({
            curentPromptId: id,
          });
        }}
      />
      <div className="w-px h-full bg-grey6"></div>
      {curentPromptId && <PromptContent key={curentPromptId} />}
    </div>
  );
};
