import { getEmbedWebsiteUrl, isUrl } from '@flowus/common/embed-website';
import { shouldEncodeUrl } from '@flowus/common/url';
import { request } from 'src/common/request';
import { LRUCache } from 'src/common/utils/lru-cache';
import { isFlowUsApp } from './electron-util';
import { decodeName } from '@flowus/common/utils';

const linkInfoCache = new LRUCache<
  string,
  {
    title: string;
    link: string;
    description?: string | undefined;
    icon?: string | undefined;
    cover?: string | undefined;
    headerMap?: Record<string, string>;
  }
>(localStorage, 'linkInfo', 50);

const THREE_DAY = 3 * 24 * 60 * 60 * 1000;
const SEVEN_DAY = 7 * 24 * 60 * 60 * 1000;
/** 缓存接口数据 & 获取网页响应头数据 */
export const getLinkInfo = async (url: string, tran = false) => {
  if (tran) {
    url = getEmbedWebsiteUrl(url).url;
  }

  if (url.includes(' ')) {
    const [first] = url.split(' ');
    if (first) {
      url = first;
    }
  }

  const cache = linkInfoCache.get(url);
  if (cache?.title) return cache;

  if (shouldEncodeUrl(url)) {
    url = encodeURI(url);
  }

  try {
    const res = await request.editor.linkInfo.raw({
      url,
      headers: ['X-Frame-Options'],
    });
    if (res.code === 200) {
      res.data.link = decodeName(res.data.link);
      if (res.data.cover && res.data.icon) {
        linkInfoCache.put(url, res.data, Date.now() + SEVEN_DAY);
      } else {
        // 如果拿不到封面上啥的，三天后调接口的话继续尝试一下
        linkInfoCache.put(url, res.data, Date.now() + THREE_DAY);
      }
      return res.data;
    }
    return null;
  } catch {
    return null;
  }
};

export const normalizeHref = (url: string) => {
  if (isUrl(url)) {
    return url;
  }
  if (/^\w+:?\/\//.test(url)) {
    return url;
  }
  try {
    const urlObj = new URL(`${isFlowUsApp.check ? 'https://' : location.protocol}${url}`);
    let newUrl = urlObj.toString();
    if (!url.endsWith('/')) {
      newUrl = newUrl.replace(/\/$/g, url.includes('/') ? '/' : '');
    }
    return newUrl;
  } catch {
    return url;
  }
};

/** 固定的书签网页域名 */
export const isBookmarkLink = (url: string) => {
  if (/mp\.weixin\.qq\.com/.test(url)) {
    return true;
  }
  return false;
};

/** 获取link是不是某个特殊的已知类型 */
export const getEmbedLinkType = (url: string) => getEmbedWebsiteUrl(url).website;
