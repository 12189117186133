import type {
  CollectionSchema,
  CollectionViewProperties,
  SegmentDTO,
} from '@next-space/fe-api-idl';
import { BlockType, CollectionSchemaType, CollectionViewType } from '@next-space/fe-api-idl';
import type { ICursor, IEditorModel } from '@next-space/fe-inlined';
import type { VirtualElement } from '@popperjs/core';
import { flatten, keys, values } from 'lodash-es';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as _const from 'src/bitable/timeline-view/const';
import { ColorKey } from 'src/colors';
import { ListItemType, ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import { message } from 'src/common/components/message';
import type { ModalSchema } from 'src/common/components/next-modal';
import { useCloseModal, useOpenModal } from 'src/common/components/next-modal';
import { emptyConvertBlockTypes, LAST_USED_COLOR_INFO } from 'src/common/const';
import { DEFAULT_COLOR_PICKER_DATA } from 'src/components/color-picker/default-data';
import { useOpenMoveTo } from 'src/components/move-to';
import { SearchMenu } from 'src/components/search';
import { getTableCellEditorKey } from 'src/editor/editor/plugin/table/helper';
import { useOpenAIEditor } from 'src/editor/editor/uikit/ai-editor/use-open-ai-editor';
import { ActivityIds } from 'src/hooks/activities/activity-ids';
import { ActivitiesListType } from 'src/hooks/activities/use-activity';
import { useUpdateTask } from 'src/hooks/activities/use-update-task';
import {
  composeBoardViewFormat,
  composeCalendarViewFormat,
  composeFormViewFormat,
  composeGalleryViewFormat,
  composeListViewFormat,
  composeTableViewFormat,
  composeTimelineViewFormat,
  getPropertiesFromSchema,
} from 'src/hooks/block/use-add-collection-view';
import { useCopyBlock } from 'src/hooks/block/use-copy-block';
import { getGroupOptions } from 'src/hooks/block/use-create-property';
import { useEnableAI } from 'src/hooks/block/use-enable-AI';
import { useGetAncestors } from 'src/hooks/block/use-get-ancestors';
import type { FocusEditableFun } from 'src/hooks/editor/use-focus-by-id';
import { useCreateDocPage } from 'src/hooks/page';
import { useOpenPage } from 'src/hooks/page/use-open-page';
import { kolUserIds } from 'src/hooks/template/use-fetch-templates';
import { useTransaction } from 'src/hooks/use-transaction';
import { useAddSubNode } from 'src/mind-map/hook/use-add-sub-node';
import { HISTORY_EFFECTS } from 'src/redux/actions';
import { addBlock } from 'src/redux/managers/block/add';
import { addColumn, addNewColumns } from 'src/redux/managers/block/add-column';
import { archiveBlock } from 'src/redux/managers/block/archive';
import { convertBlock, covertToSyncBlock } from 'src/redux/managers/block/convert';
import { updateBlock } from 'src/redux/managers/block/update';
import * as CollectionViewManager from 'src/redux/managers/collection-view';
import { useCloseCreateBlockMenuList } from 'src/redux/managers/ui';
import { patchExpand } from 'src/redux/managers/ui/use-fold';
import { simpleTableActions } from 'src/redux/reducers/simple-table';
import { uiActions } from 'src/redux/reducers/ui';
import { dispatch, getState } from 'src/redux/store';
import type { NextCollectionView, SelectBlock } from 'src/redux/types';
import {
  setAppUiState,
  setExpandPageRecord,
  useCreateBlockMenuListId,
  useMenuListKeywords,
  useSlashSelection,
} from 'src/services/app/hook';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { $currentUserCache } from 'src/services/user/current-user';
import { bizTracker } from 'src/utils/biz-tracker';
import {
  findDescendantBlock,
  getAllEditorInfoFromDomContent,
  getDefaultSearchList,
} from 'src/utils/block-utils';
import { focusEditable } from 'src/utils/editor';
import { useGetPageId } from 'src/utils/getPageId';
import { setLocalStorage } from 'src/utils/local-storage';
import { usePickBlock } from 'src/utils/pick-block';
import { isSlashSymbol } from 'src/utils/slash-symbol';
import { stringToLowerCaseAndRemoveSpace } from 'src/utils/string-util';
import { v4 as uuidV4 } from 'uuid';
import type { AIEditType } from '../../editor/uikit/ai-editor/const';
import { AIEditorFrom, AIEditorScene } from '../../editor/uikit/ai-editor/const';
import { getInitBlockParams } from '../../utils/block';
import { textToSegments } from '../../utils/editor';
import { saveLastMarkBlockInfo } from '../../utils/mark-block-utils';
import { buildDateSegment } from '../../utils/segments';
import { MenuCategoryPinyin } from './const';
import { getSearchMenuItemByCache, setSearchMenuItemByCache } from './helper';
import { useOpenInlineContentMenu } from './inline-content-menu';
import type { BlockMenuTypes } from './menu-item';
import { CreateBlockTextMenu, getMemoBlockMenuList } from './menu-item';
import { checkPageIsEmpty } from './page-is-empty';
import { MenuCategory, MenuListActionType } from './types';
import { useOpenCopyView } from './use-open-copy-view';

// #region 创建块list
export interface BlockMenuProps extends ModalSchema.CloseModal {
  blockId: string;
  popcorn: VirtualElement;
  selectItem?: () => void;
  slash?: boolean;
  blockNoText?: boolean;
}

export const CreateBlockMenuList: FC<
  BlockMenuProps & {
    cursorLeft: ICursor;
    cursorRight: ICursor;
    syncId?: string;
    focusEditableAt: FocusEditableFun;
    getEditorModel: (blockId: string) => IEditorModel | undefined;
  }
> = (props) => {
  const closeCreateBlockMenuList = useCloseCreateBlockMenuList();
  const [notItemKeywords, setNotItemKeywords] = useState('');
  const { blockId, popcorn, selectItem, slash, blockNoText, cursorLeft, cursorRight, syncId } =
    props;
  const BlockMenu = getMemoBlockMenuList();
  const { getEditorModel, focusEditableAt } = props;
  const blockMenuSelect = useBlockMenuSelect(blockId, focusEditableAt, syncId);
  const transaction = useTransaction();
  const openInlineContentMenu = useOpenInlineContentMenu();
  const keywords = useMenuListKeywords();
  const localColorInfo = localStorage.getItem(LAST_USED_COLOR_INFO);
  const pageId = useGetPageId();
  const pageIsEmpty = useObservableStore(
    ({ blocks }) => checkPageIsEmpty(pageId, blockId, blocks),
    [pageId, blockId]
  );
  const { enableAI } = useEnableAI();

  const items = useMemo(() => {
    // 去掉开头的slash符号
    const searchKeywords = stringToLowerCaseAndRemoveSpace(
      !isSlashSymbol(keywords[0] ?? '') ? keywords : keywords.slice(1)
    );
    const cacheKey = `${searchKeywords}-pageIsEmpty-${pageIsEmpty}-enableAI-${enableAI}`;
    const cacheItem = getSearchMenuItemByCache(cacheKey);

    if (cacheItem) {
      return cacheItem;
    }

    const menuListItems: Record<keyof typeof BlockMenu, any[]> = {
      aiAssistantWithEmptyDoc: [],
      aiAssistantWithNotEmptyDoc: [],
      basicBlock: [],
      media: [],
      inlineElement: [],
      database: [],
      embed: [],
      advanced: [],
      layout: [],
      turninto: [],
      action: [],
      lastUsedColor: [],
      color: [],
      colorBackground: [],
    };
    // @ts-ignore next line
    keys(BlockMenu).forEach((blockType: keyof typeof BlockMenu) => {
      if (
        !enableAI &&
        (blockType === 'aiAssistantWithEmptyDoc' || blockType === 'aiAssistantWithNotEmptyDoc')
      ) {
        return;
      }
      if (
        enableAI &&
        ((pageIsEmpty && blockType === 'aiAssistantWithNotEmptyDoc') ||
          (!pageIsEmpty && blockType === 'aiAssistantWithEmptyDoc'))
      ) {
        return;
      }
      BlockMenu.advanced.ad.isHidden = !kolUserIds.has($currentUserCache.uuid);
      const menus = BlockMenu[blockType];
      const categoryInfo = MenuCategoryPinyin[blockType];
      const category = MenuCategory[blockType];
      values(menus).forEach((menuItem: BlockMenuTypes) => {
        const menu: BlockMenuTypes & { matchAll?: boolean } = menuItem;
        if (menu.isHidden) return;

        // 如果在同步块内是要隐藏掉同步块的，不能嵌套
        const hideSyncItem = syncId && menu.data.type === BlockType.SYNC_CONTAINER;
        const existMenu =
          // @ts-ignore string[]
          (menu.data.thesaurus ?? []).some((it) => it.includes(searchKeywords)) && !hideSyncItem;

        if (existMenu) {
          // 遍历标题
          switch (blockType) {
            case 'aiAssistantWithEmptyDoc':
            case 'aiAssistantWithNotEmptyDoc':
              if (menuListItems[blockType].length === 0) {
                menuListItems[blockType].push(
                  CreateBlockTextMenu(MenuCategory[blockType], {
                    titleSuffix: (
                      <span className="text-t2 px-1.5 bg-grey/20 rounded-sm ml-2 text-grey2 inline-flex items-center">
                        Beta
                      </span>
                    ),
                  })
                );
              }
              break;
            case 'inlineElement':
            case 'basicBlock':
            case 'embed':
            case 'media':
            case 'database':
            case 'advanced':
              if (menuListItems[blockType].length === 0) {
                menuListItems[blockType].push(CreateBlockTextMenu(MenuCategory[blockType]));
              }
              break;
            case 'layout':
            case 'turninto':
            case 'action':
            case 'color':
            case 'colorBackground':
              if (menuListItems[blockType].length === 0 && slash) {
                menuListItems[blockType].push(CreateBlockTextMenu(MenuCategory[blockType]));
              }
              break;
            case 'lastUsedColor':
              // 特殊处理上次使用的color
              if (localColorInfo && localColorInfo !== 'undefined') {
                menuListItems[blockType].push(CreateBlockTextMenu(MenuCategory[blockType]));
                const colorInfo = JSON.parse(localColorInfo);
                menu.data = {
                  ...menu.data,
                  ...(colorInfo.isBgColor
                    ? { backgroundColor: colorInfo.colorkey || 'default' }
                    : { textColor: colorInfo.colorkey || 'default' }),
                  title: colorInfo.desc,
                  keywords: [colorInfo.colorkey || 'default'],
                  params: {
                    ...(colorInfo.isBgColor
                      ? { backgroundColor: colorInfo.colorkey || '', textColor: '' }
                      : { backgroundColor: '', textColor: colorInfo.colorkey || '' }),
                  },
                };
              } else {
                return;
              }
              break;
            default:
          }

          const matchAllRule = [...menu.data.keywords].filter((i) => !!i);

          // 判断是否被全匹配到
          menu.matchAll = Boolean(
            searchKeywords &&
              !blockType.includes(searchKeywords) &&
              matchAllRule.some((i) => i.includes(searchKeywords))
          );

          // 排除在搜索组名的情况
          if (menu.matchAll) {
            if (
              [categoryInfo.pinyin, categoryInfo.initials, category]
                .join(' ')
                .includes(searchKeywords)
            ) {
              menu.matchAll = false;
            }
          }

          menuListItems[blockType].push(menu);
        }
      });
    });

    // 如果一组数据中有全部匹配到关键词的话，就收缩范围，不展示整个分组的内容
    keys(menuListItems).forEach((key) => {
      // @ts-ignore keyof
      const menus: any[] = menuListItems[key];
      const exist = menus.some((i) => i.matchAll);
      if (exist) {
        // @ts-ignore keyof
        menuListItems[key] = [
          ...menus.filter((menu) => menu.matchAll || menu.type === 'blockText'),
        ];
      }
    });

    const itemsMenu = slash
      ? flatten(values(menuListItems).map((value) => value))
      : flatten(
          values([
            ...menuListItems.aiAssistantWithEmptyDoc,
            ...menuListItems.aiAssistantWithNotEmptyDoc,
            ...menuListItems.basicBlock,
            ...menuListItems.inlineElement,
            ...menuListItems.database,
            ...menuListItems.media,
            ...menuListItems.embed,
            ...menuListItems.advanced,
          ]).map((value) => value)
        );

    const result: typeof itemsMenu = [];
    // 遍历插入分割线
    itemsMenu.forEach((item, index) => {
      if (item.type === 'blockText' && index !== 0) {
        result.push({ type: ListItemType.LINE });
      }

      result.push(item);
    });

    setSearchMenuItemByCache(cacheKey, result);
    return result;
  }, [keywords, BlockMenu, slash, syncId, localColorInfo, enableAI, pageIsEmpty]);

  useEffect(() => {
    if (items.length === 0) {
      if (!notItemKeywords) {
        setNotItemKeywords(keywords);
      } else {
        const keywordsLen = keywords.replace(/[^\x00-\xff]/g, '**').length;
        const notItemKeywordsLen = notItemKeywords.replace(/[^\x00-\xff]/g, '**').length;
        if (keywordsLen - notItemKeywordsLen >= 5) {
          closeCreateBlockMenuList();
        }
      }
    } else if (notItemKeywords) {
      setNotItemKeywords('');
    }
  }, [closeCreateBlockMenuList, items.length, keywords, notItemKeywords]);

  const submit = useCallback(
    (params: {
      type: BlockType;
      params?: any;
      actionType: MenuListActionType;
      tableType?: CollectionViewType;
      aiEditType?: AIEditType;
    }) => {
      selectItem?.();
      transaction(() => {
        void blockMenuSelect({ ...params, blockNoText, popcorn, slash });
      });
    },
    [blockMenuSelect, blockNoText, popcorn, selectItem, slash, transaction]
  );

  const onItemClick = useCallback(
    (res: ListItem<any>) => {
      const { type, params, isInline, actionType, tableType, title, category, aiEditType } =
        res.data;
      if (res.data.renderSubMenu) {
        return;
      }
      bizTracker.event('block_choose', {
        block_name: title,
        block_type: type,
        block_category: category,
        is_from: slash ? 'slash' : 'add',
      });
      if (isInline) {
        openInlineContentMenu(cursorLeft.offset, cursorRight.offset, blockId, type, getEditorModel);
      } else {
        submit({ type, params, actionType, tableType, aiEditType });
      }
    },
    [
      blockId,
      cursorLeft.offset,
      cursorRight.offset,
      getEditorModel,
      openInlineContentMenu,
      slash,
      submit,
    ]
  );

  if (!items.length) {
    return null;
  }

  return (
    <ListView
      virtualList
      className={'py-2 overflow-y-auto min-w-[290px] next-modal max-h-[40vh]'}
      items={items}
      onItemClick={onItemClick}
      defaultActiveIndex={items.length > 1 ? 1 : 0}
    />
  );
};
// #endregion

// #region 列表的点击事件
interface BlockMenuSelectSubmitProps {
  type: BlockType;
  params?: any;
  tableType?: CollectionViewType;
  popcorn: VirtualElement;
  slash?: boolean;
  onCloseModal?: () => void;
  blockNoText?: boolean;
  actionType: MenuListActionType;
  aiEditType?: AIEditType;
}
const useBlockMenuSelect = (
  blockId: string,
  focusEditableAt: FocusEditableFun,
  syncId?: string
) => {
  const updateTask = useUpdateTask();
  const block = usePickBlock(blockId, ['backgroundColor', 'subNodes', 'textColor']);
  const createPage = useCreateDocPage();
  const openModal = useOpenModal();
  const closeModal = useCloseModal();
  const getAncestors = useGetAncestors();
  const transaction = useTransaction();
  const copyBlock = useCopyBlock();
  const openMoveTo = useOpenMoveTo();
  const createBlockMenuListId = useCreateBlockMenuListId();
  const slashSelection = useSlashSelection();
  const addSubNode = useAddSubNode();
  const openPage = useOpenPage();
  const openCopyView = useOpenCopyView();
  const pageId = useGetPageId();
  const pageIsEmpty = useObservableStore(
    ({ blocks }) => checkPageIsEmpty(pageId, blockId, blocks),
    [pageId, blockId]
  );
  const openAIEditor = useOpenAIEditor();

  // 最外层调用的地方已经用transaction包裹了，内部无需再包裹
  const submit = async (props: BlockMenuSelectSubmitProps) => {
    const {
      type,
      params = {},
      popcorn,
      blockNoText,
      actionType,
      slash,
      tableType,
      aiEditType,
    } = props;
    if (!block) return;

    // 积分任务
    switch (type) {
      case BlockType.PAGE:
        // 老任务要保留
        void updateTask(ActivityIds.GUIDE_FIRST_TIME_CREATED_PAGE, ActivitiesListType.basicList);
        void updateTask(ActivityIds.CREATE_NEW_PAGE, ActivitiesListType.basicList);
        break;
      case BlockType.TODO:
        void updateTask(ActivityIds.CREATE_TODO_BLOCK, ActivitiesListType.basicList);
        break;
      case BlockType.HEADER:
        void updateTask(ActivityIds.CREATE_HEADER_BLOCK, ActivitiesListType.basicList, {
          step1: true,
        });
        break;
      case BlockType.QUOTE:
        void updateTask(ActivityIds.CREATE_QUOTE_BLOCK, ActivitiesListType.basicList);
        break;
      case BlockType.MARK:
        void updateTask(ActivityIds.CREATE_MARK_BLOCK, ActivitiesListType.basicList);
        break;
      case BlockType.FILE:
        if (params.data.display === 'image') {
          void updateTask(ActivityIds.CREATE_IMAGE_BLOCK, ActivitiesListType.basicList, {
            step1: true,
          });
        }
        break;
      case BlockType.MIND_MAPPING:
      case BlockType.MIND_MAPPING_PAGE:
        void updateTask(ActivityIds.CREATE_MIND_MAP, ActivitiesListType.advancedList);
        break;
      case BlockType.COLLECTION_VIEW:
      case BlockType.COLLECTION_VIEW_PAGE:
        void updateTask(ActivityIds.CREATE_BITABLE_EXPLORE, ActivitiesListType.basicList);
        void updateTask(
          ActivityIds.GUIDE_FIRST_TIME_CREATED_COLLECTION,
          ActivitiesListType.basicList
        );
        switch (tableType) {
          case CollectionViewType.GALLERY:
            void updateTask(ActivityIds.CREATE_GALLERY_VIEW, ActivitiesListType.advancedList, {
              step1: true,
            });
            break;
          case CollectionViewType.CALENDAR:
            void updateTask(
              ActivityIds.GUIDE_FIRST_TIME_CALENDAR_VIEW,
              ActivitiesListType.advancedList
            );
            void updateTask(ActivityIds.CREATE_CALENDAR_VIEW, ActivitiesListType.advancedList);
            break;
          case CollectionViewType.TIMELINE:
            void updateTask(
              ActivityIds.GUIDE_FIRST_TIME_TIMELINE_VIEW,
              ActivitiesListType.advancedList
            );
            void updateTask(ActivityIds.CREATE_TIMELINE_VIEW, ActivitiesListType.advancedList);
            break;
          case CollectionViewType.FORM:
            void updateTask(
              ActivityIds.GUIDE_FIRST_TIME_COLLECTION_VIEW,
              ActivitiesListType.advancedList
            );
            void updateTask(ActivityIds.CREATE_FORM_VIEW, ActivitiesListType.advancedList);
            break;
          default:
        }
        break;
      case BlockType.FOLDER:
        void updateTask(ActivityIds.CREATE_FOLDER_AND_UPLOAD, ActivitiesListType.basicList, {
          step1: true,
        });
        void updateTask(ActivityIds.GUIDE_FIRST_TIME_CREATED_FOLDER, ActivitiesListType.basicList);
        break;
      case BlockType.TABLE:
        void updateTask(ActivityIds.CREATE_SIMPLE_TABLE, ActivitiesListType.basicList);
        void updateTask(ActivityIds.GUIDE_FIRST_TIME_CREATED_TABLE, ActivitiesListType.basicList);
        break;
      case BlockType.TEMPLATE:
        void updateTask(
          ActivityIds.GUIDE_FIRST_TIME_CREATED_TEMPLATE,
          ActivitiesListType.advancedList
        );
        void updateTask(ActivityIds.CREATE_TEMPLATE_BUTTON, ActivitiesListType.advancedList);
        break;
      case BlockType.SYNC_CONTAINER:
        void updateTask(ActivityIds.GUIDE_FIRST_TIME_CREATED_SYNC, ActivitiesListType.advancedList);
        void updateTask(ActivityIds.CREATE_SYNC_BLOCK_NEW, ActivitiesListType.advancedList, {
          step1: true,
        });
        break;
      case BlockType.COLUMN_LIST:
        void updateTask(ActivityIds.CREATE_COLUMNS_BLOCK, ActivitiesListType.basicList);
        void updateTask(
          ActivityIds.GUIDE_FIRST_TIME_CREATED_COLUMN_LIST,
          ActivitiesListType.basicList
        );
        break;
      default:
    }

    // 积分任务
    if (slash) {
      void updateTask(
        ActivityIds.GUIDE_FIRST_TIME_SHORTCUT_CREATED,
        ActivitiesListType.advancedList
      );
    }

    if (actionType === MenuListActionType.AI_EDITOR) {
      const popcorn = document.querySelector(`[data-block-id="${blockId}"]`) as HTMLElement | null;
      if (!popcorn) return;

      openAIEditor({
        popcorn,
        blockId,
        editorScene: pageIsEmpty ? AIEditorScene.PageEmpty : AIEditorScene.PageHasContent,
        editType: aiEditType,
        selection: { start: slashSelection.left ?? 0 },
        from: AIEditorFrom.ContextMenu,
      });

      closeModal(createBlockMenuListId);
      return;
    }

    if (actionType === MenuListActionType.CREATE) {
      switch (type) {
        case BlockType.COLUMN_LIST: {
          const createBottom = Boolean(params.columnCount);
          if (createBottom) {
            // 在下面插入n列
            addNewColumns(blockId, params.columnCount as number, {
              convertBlock,
            });
          } else {
            const ancestors = [...getAncestors(blockId)];
            const { blocks } = getState();
            let columnId = '';
            for (const uuid of ancestors) {
              if (blocks[uuid]?.type === BlockType.PAGE) break;
              if (blocks[uuid]?.type === BlockType.COLUMN) {
                columnId = uuid;
              }
            }
            if (columnId) {
              const column = blocks[columnId];
              if (column) {
                const newId = addBlock(
                  { type: BlockType.TEXTAREA },
                  { parentId: column.uuid } // 这个是临时的
                );
                if (newId) {
                  addColumn([newId], column.uuid, params.left ? 'left' : 'right');
                }
              }
            } else {
              const newId = addBlock(
                { type: BlockType.TEXTAREA },
                { parentId: block.parentId, after: blockId }
              );
              if (newId) {
                addColumn([newId], blockId, params.left ? 'left' : 'right');
              }
            }
          }

          break;
        }
        case BlockType.REFERENCE: {
          // 默认列表是一级 pages，需要过滤掉自己所在页面
          // 之前网盘的文件都是挂到根目录上的，后台可能没删干净，我们过滤掉
          const defaultItems = getDefaultSearchList({
            ancestorId: block.spaceId,
            filterTypes: [BlockType.FILE],
          });
          setTimeout(() => {
            // 因为菜单消失的时候会重置这个textareaPlaceHolder，所以这里需要delay一下再设置
            setAppUiState({
              $textareaPlaceHolder: {
                blockId,
                alwaysShow: true,
                placeHolder: '指定引用页面',
              },
            });
          }, 250);
          openModal.dropdown({
            popcorn,
            placement: 'top-start',
            closeAfterCallBack: () => {
              setAppUiState({ $textareaPlaceHolder: undefined });
            },
            content: (modal) => (
              <SearchMenu
                defaultValue={defaultItems}
                onSelect={(target) => {
                  transaction(() => {
                    modal.onCloseModal();
                    if (blockNoText && block.type === BlockType.TEXTAREA) {
                      convertBlock([blockId], {
                        type: BlockType.REFERENCE,
                        data: { ref: { uuid: target.uuid }, ...params.data },
                      });
                    } else {
                      addBlock(
                        {
                          type: BlockType.REFERENCE,
                          data: { ...params.data, ref: { uuid: target.uuid } },
                        },
                        { parentId: block.parentId, after: blockId }
                      );
                    }
                    // 积分任务
                    void updateTask(
                      ActivityIds.GUIDE_FIRST_TIME_LINK_PAGE,
                      ActivitiesListType.advancedList
                    );
                  });
                }}
              />
            ),
          });
          closeModal(createBlockMenuListId);
          return;
        }
        case BlockType.REFERENCE_COLLECTION: {
          const defaultItems = getDefaultSearchList({
            ancestorId: block.spaceId,
            findTypes: [BlockType.COLLECTION_VIEW, BlockType.COLLECTION_VIEW_PAGE],
          });
          setTimeout(() => {
            // 因为菜单消失的时候会重置这个textareaPlaceHolder，所以这里需要delay一下再设置
            setAppUiState({
              $textareaPlaceHolder: {
                blockId,
                alwaysShow: true,
                placeHolder: '指定引用多维表',
              },
            });
          }, 250);

          openModal.dropdown({
            popcorn,
            placement: 'top-start',
            closeAfterCallBack: () => {
              setAppUiState({ $textareaPlaceHolder: undefined });
            },
            content: ({ onCloseModal }) => (
              <SearchMenu
                defaultValue={defaultItems}
                placeholder="搜索多维表"
                searchType="collection"
                source="collectionSettingsSource"
                onSelect={(target) => {
                  onCloseModal();
                  void openCopyView({
                    popcorn,
                    collectionId: target.uuid,
                    blockId: block.uuid,
                  });
                }}
              />
            ),
          });
          closeModal(createBlockMenuListId);
          return;
        }
        case BlockType.PAGE: {
          if (blockNoText) {
            convertBlock([blockId], { type, local: true, data: { segments: [] } });
            openPage(blockId);
          } else {
            const id = createPage(BlockType.PAGE, { parentId: block.parentId, after: blockId });
            if (!id) return;
            setExpandPageRecord([id], true);
            openPage(id);
          }
          break;
        }
        case BlockType.COLLECTION_VIEW: {
          bizTracker.event('bitable_create', {
            from_scene: 'inline',
            view_type: type,
          });

          if (blockNoText && block.type === BlockType.TEXTAREA && block.subNodes.length === 0) {
            convertBlock([blockId], { type, local: true, data: { segments: [] } });
            makeInitialCollection(blockId, tableType);
          } else {
            const newBlockId = addBlock(
              { type },
              { parentId: block.parentId, after: blockId },
              false,
              true
            );
            makeInitialCollection(newBlockId, tableType);
          }
          break;
        }
        case BlockType.COLLECTION_VIEW_PAGE: {
          bizTracker.event('bitable_create', {
            from_scene: 'subpage',
            view_type: type,
          });

          if (blockNoText && block.type === BlockType.TEXTAREA && block.subNodes.length === 0) {
            convertBlock([blockId], { type });
            makeInitialCollection(blockId, tableType);
            openPage(blockId);
          } else {
            const newBlockId = addBlock({ type }, { parentId: block.parentId, after: blockId });
            makeInitialCollection(newBlockId, tableType);
            openPage(newBlockId);
          }
          break;
        }
        case BlockType.TEMPLATE: {
          const defaultText = '添加一个待办任务';
          let newBlockId = blockId;
          if (blockNoText && block.type === BlockType.TEXTAREA) {
            convertBlock([blockId], {
              type: BlockType.TEMPLATE,
              textColor: ColorKey.blue,
              data: {
                segments: textToSegments(defaultText),
              },
            });
          } else {
            newBlockId = addBlock(
              {
                type: BlockType.TEMPLATE,
                textColor: ColorKey.blue,
                data: {
                  segments: textToSegments(defaultText),
                },
              },
              { parentId: blockId }
            );
          }
          addBlock(
            {
              type: BlockType.TODO,
            },
            { parentId: newBlockId }
          );
          // 自动展开编辑
          patchExpand(newBlockId, true, false);
          closeModal(createBlockMenuListId, {
            closeAfterCallBack: () => focusEditableAt(newBlockId || newBlockId, defaultText.length),
          });
          break;
        }
        case BlockType.TABLE: {
          let tableId: string = blockId;

          if (blockNoText && block.type === BlockType.TEXTAREA) {
            convertBlock([blockId], { type: BlockType.TABLE });
          } else {
            tableId = addBlock(
              { type: BlockType.TABLE },
              { parentId: block.parentId, after: blockId }
            );
          }

          const column1 = uuidV4();
          const column2 = uuidV4();

          const createRow = () => {
            return addBlock({ type: BlockType.TABLE_ROW }, { parentId: tableId });
          };

          const row1 = createRow();
          const row2 = createRow();
          const row3 = createRow();

          updateBlock(tableId, {
            subNodes: [row1, row2, row3],
            data: {
              format: {
                tableBlockColumnOrder: [column1, column2],
                tableBlockRowHeader: true,
              },
            },
          });

          dispatch(
            uiActions.update({
              selectedCells: [{ recordId: row1, propertyId: column1, viewId: tableId, syncId }],
            })
          );
          dispatch(simpleTableActions.update({ activityTableId: tableId, focus: true }));

          closeModal(createBlockMenuListId);

          setTimeout(() => {
            focusEditable(getTableCellEditorKey(tableId, 0, 0), Infinity, Infinity);
          });

          return;
        }
        case BlockType.MIND_MAPPING:
        case BlockType.MIND_MAPPING_PAGE: {
          let mindMapId = blockId;
          if (blockNoText && block.type === BlockType.TEXTAREA) {
            convertBlock([blockId], { ...params, type });
          } else {
            mindMapId = addBlock({ type }, { ...params, parentId: block.parentId, after: blockId });
          }
          const createDefaultNode = () => {
            transaction(() => {
              addSubNode({ parentId: mindMapId, last: true }, mindMapId);
              addSubNode({ parentId: mindMapId, last: true }, mindMapId);
              addSubNode({ parentId: mindMapId, last: true }, mindMapId);
            });
            if (type === BlockType.MIND_MAPPING_PAGE) {
              openPage(blockId);
            }
          };
          createDefaultNode();
          return;
        }
        default: {
          if (blockNoText && emptyConvertBlockTypes.includes(block.type)) {
            if (type === BlockType.MARK) {
              if (!block.textColor && !block.backgroundColor) {
                // 如果原来的block没有颜色值，就用上一次着重block用过的，着重block的feature,产品提的
                const initParams = getInitBlockParams(BlockType.MARK);
                Object.assign(params, initParams);
              }
            } else if (type === BlockType.SYNC_CONTAINER) {
              const newSyncId = covertToSyncBlock([block.uuid], block.parentId);
              closeModal(createBlockMenuListId, {
                closeAfterCallBack: () => {
                  focusEditableAt(block.uuid, 0, undefined, { syncId: newSyncId || syncId });
                },
              });
              return;
            } else if (type === BlockType.AD) {
              const adIds = findDescendantBlock(pageId, [BlockType.AD]);
              if (adIds.length > 2) {
                message.warning('广告块不能多于3个');
                return;
              }
            }
            // 开始转化块type
            convertBlock([blockId], {
              ...params,
              type,
            });

            if (type === BlockType.CODE) {
              closeModal(createBlockMenuListId, {
                closeAfterCallBack: () => {
                  focusEditableAt(`${blockId}_mock`, 0, undefined, { syncId });
                },
              });
            } else if (type === BlockType.DIVIDER) {
              const editableList = getAllEditorInfoFromDomContent(false);
              const editableIndex = editableList.findIndex(
                (v) => v.blockId === block.uuid && v.syncId === syncId
              );
              const index = editableIndex + 1;
              if (index < editableList.length) {
                const nextEditable = editableList[index];
                if (!nextEditable) return;
                closeModal(createBlockMenuListId, {
                  closeAfterCallBack: () => {
                    focusEditable(nextEditable.editorKey, 0, 0);
                  },
                });
              } else {
                const newId = addBlock({}, { parentId: block.parentId });
                closeModal(createBlockMenuListId, {
                  closeAfterCallBack: () => {
                    focusEditableAt(newId, 0, undefined, { syncId });
                  },
                });
              }
              return;
            }

            if (type === BlockType.FOLDER) {
              openPage(blockId);
            }
          } else {
            let newSyncId: string | undefined;
            const initParams = getInitBlockParams(type);
            let newId = addBlock(
              { ...initParams, ...params, type, data: { ...params.data, ...initParams.data } },
              { parentId: block.parentId, after: blockId }
            );
            if (type === BlockType.SYNC_CONTAINER) {
              // 需要另外添加一个textarea的block到subnode下
              newSyncId = newId;
              newId = addBlock({}, { parentId: newId });
            }
            if (type === BlockType.CODE) {
              newId = `${newId}_mock`;
            }
            closeModal(createBlockMenuListId, {
              closeAfterCallBack: () => {
                focusEditableAt(newId, 0, undefined, { syncId: newSyncId ?? syncId });
              },
            });

            if (type === BlockType.FOLDER) {
              openPage(newId);
            }

            return;
          }
        }
      }
    } else {
      switch (actionType) {
        case MenuListActionType.COLOR: {
          updateBlock(blockId, {
            type: block.type,
            ...params,
          });

          const isBgColor = Boolean(params.backgroundColor);
          const [textColor, backgroundColor] = DEFAULT_COLOR_PICKER_DATA;
          const colorInfo = (isBgColor ? backgroundColor : textColor)?.items.find(
            (i) => i.colorkey === (isBgColor ? params.backgroundColor : params.textColor)
          );

          setLocalStorage(LAST_USED_COLOR_INFO, JSON.stringify(colorInfo));

          if (block.type === BlockType.MARK) {
            saveLastMarkBlockInfo(params);
          }
          break;
        }
        case MenuListActionType.TURN_INTO: {
          convertBlock([blockId], {
            ...params,
            type,
            data: { ...params.data },
          });

          break;
        }
        case MenuListActionType.DELETE: {
          archiveBlock([blockId]);

          break;
        }
        case MenuListActionType.MOVETO: {
          openMoveTo({ popcorn, uuid: blockId });
          break;
        }
        case MenuListActionType.COPY: {
          void copyBlock([blockId], {
            effects: (ids: SelectBlock[]) => {
              const firstBlockId = ids[0]?.blockId ?? '';
              dispatch(
                HISTORY_EFFECTS({
                  init() {
                    focusEditableAt(firstBlockId, slashSelection.left ?? Infinity);
                  },
                  redo() {
                    focusEditableAt(firstBlockId, slashSelection.left ?? Infinity);
                  },
                  undo() {
                    focusEditableAt(blockId, slashSelection.left ?? Infinity);
                  },
                })
              );
            },
          });

          break;
        }
        default:
      }
    }

    if (!slash) {
      focusEditableAt(block.uuid, 0, undefined, { syncId });
    } else {
      focusEditableAt(block.uuid, slashSelection.left ?? 0, undefined, { syncId });
    }

    closeModal(createBlockMenuListId);
  };

  return useCallback(submit, [
    addSubNode,
    block,
    blockId,
    closeModal,
    copyBlock,
    createBlockMenuListId,
    createPage,
    focusEditableAt,
    getAncestors,
    openAIEditor,
    openCopyView,
    openModal,
    openMoveTo,
    openPage,
    pageId,
    pageIsEmpty,
    slashSelection.left,
    syncId,
    transaction,
    updateTask,
  ]);
};
// #endregion

// #region 创建多维表视图
export const createView = (
  collectionId: string,
  parentId: string,
  viewType = CollectionViewType.TABLE
) => {
  const { blocks } = getState();
  const collection = blocks[collectionId];
  if (!collection) return;

  const schema = collection.data.schema ?? {};

  // 添加视图
  CollectionViewManager.add(
    {
      title: '',
      format: composeTableViewFormat({
        tableProperties: getPropertiesFromSchema(schema, viewType),
      }),
      pageSort: collection.subNodes,
      type: viewType,
    },
    {
      parentId,
      last: true,
    }
  );
};

export const makeInitialCollection = (
  collectionId: string,
  type: CollectionViewType = CollectionViewType.TABLE
) => {
  const options: CollectionSchema['options'] = getGroupOptions();

  // 构造 collection
  const isTimeline = type === CollectionViewType.TIMELINE;
  const isCalendar = type === CollectionViewType.CALENDAR;
  const isForm = type === CollectionViewType.FORM;
  const newSelectPropertyId = uuidV4();
  const newStartDatePropertyId = uuidV4();
  const newEndDatePropertyId = uuidV4();
  const collectionPageProperties: CollectionViewProperties = [];
  const schema = {
    title: {
      name: '标题',
      type: CollectionSchemaType.TITLE,
    },
    [newSelectPropertyId]: {
      name: '分类',
      type: CollectionSchemaType.SELECT,
      options,
    },
  };
  collectionPageProperties.push(
    { property: 'title', visible: true },
    { property: newSelectPropertyId, visible: true }
  );
  if (isTimeline || isCalendar) {
    schema[newStartDatePropertyId] = {
      name: '开始时间',
      type: CollectionSchemaType.DATE,
    };
    schema[newEndDatePropertyId] = {
      name: '结束时间',
      type: CollectionSchemaType.DATE,
    };
    collectionPageProperties.push(
      { property: newStartDatePropertyId, visible: true },
      { property: newEndDatePropertyId, visible: true }
    );
  }
  updateBlock(collectionId, {
    local: true,
    data: { schema, collectionPageProperties },
  });

  // 构造表格内容
  let ids: string[] = [];
  if (!isCalendar && !isForm) {
    ids = [5, 3, 1].map((value) => {
      const collectionProperties: Record<string, SegmentDTO[]> = {
        [newSelectPropertyId]: [],
      };

      if (isTimeline) {
        const startDate = new Date(Date.now() - value * _const.ONE_DAY);
        collectionProperties[newStartDatePropertyId] = [
          buildDateSegment({
            from: startDate,
          }),
        ];
        collectionProperties[newEndDatePropertyId] = [
          buildDateSegment({
            from: new Date(startDate.getTime() + 5 * _const.ONE_DAY),
          }),
        ];
      }

      return addBlock(
        {
          type: BlockType.PAGE,
          local: true,
          data: {
            segments: [],
            collectionProperties,
          },
        },
        { parentId: collectionId },
        false,
        true
      );
    });
  }

  // 构造 format
  const initProperties = [
    { property: 'title', visible: true },
    {
      property: newSelectPropertyId,
      visible: type === CollectionViewType.TABLE,
    },
  ];
  if (isTimeline || isCalendar) {
    initProperties.push(
      {
        property: newStartDatePropertyId,
        visible: false,
      },
      {
        property: newEndDatePropertyId,
        visible: false,
      }
    );
  }

  let format: NextCollectionView['format'] = {};
  switch (type) {
    case CollectionViewType.TABLE:
      format = composeTableViewFormat({ tableProperties: initProperties });
      break;
    case CollectionViewType.BOARD:
      format = composeBoardViewFormat({
        groupProperty: newSelectPropertyId,
        boardProperties: initProperties,
        options,
      });
      break;
    case CollectionViewType.GALLERY:
      format = composeGalleryViewFormat({
        galleryProperties: initProperties,
      });
      break;
    case CollectionViewType.LIST:
      format = composeListViewFormat({
        listProperties: initProperties,
      });
      break;
    case CollectionViewType.TIMELINE:
      format = composeTimelineViewFormat({
        timelineProperties: initProperties,
        timelineBy: newStartDatePropertyId,
        timelineByEnd: newEndDatePropertyId,
      });
      break;
    case CollectionViewType.CALENDAR:
      format = composeCalendarViewFormat({
        calendarProperties: initProperties,
        calendarBy: newStartDatePropertyId,
        calendarByEnd: newEndDatePropertyId,
      });
      break;
    case CollectionViewType.FORM:
      format = composeFormViewFormat({ formProperties: initProperties });
      break;
    default:
      break;
  }

  CollectionViewManager.add(
    {
      title: '默认视图',
      format,
      pageSort: ids,
      type,
    },
    { parentId: collectionId }
  );

  if (isForm) {
    CollectionViewManager.add(
      {
        title: '表格',
        format: composeTableViewFormat({ tableProperties: initProperties }),
        pageSort: [],
        type: CollectionViewType.TABLE,
      },
      { parentId: collectionId }
    );
  }
};
// #endregion
