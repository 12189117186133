import { getTagBackgroundColor } from '@flowus/common/block/color/get-block-color';
import type { FC, MutableRefObject } from 'react';
import { useRef, useState } from 'react';
import { useStore } from 'react-redux';
import { Input } from 'src/common/components/input';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { useOpenModal } from 'src/common/components/next-modal';
import { useRemovePropertyOption } from 'src/hooks/block/use-remove-property-option';
import { useUpdatePropertyOption } from 'src/hooks/block/use-update-property-option';
import type { Option } from '../../types';
import { ColorSelector } from './color-selector';

interface Props {
  option: Option;
  propertyId: string;
  className?: string;
  closeModal(): void;
  collectionId: string;
  closeBeforeCallbackRef: MutableRefObject<(() => void | 'prevent') | undefined>;
}

export const OptionMore: FC<Props> = ({
  option,
  propertyId,
  closeModal,
  closeBeforeCallbackRef,
  collectionId,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { getState } = useStore();
  const updateOption = useUpdatePropertyOption();
  const removeOption = useRemovePropertyOption();

  const openModal = useOpenModal();
  const [value, setValue] = useState(option.value);

  const checkNameThenUpdate = () => {
    const optionName = value.replaceAll(',', '').trim();
    if (!optionName) return;

    const { blocks } = getState();
    const options = blocks[collectionId]?.data.schema?.[propertyId]?.options;
    if (!options) return;

    if (optionName === option.value) {
      return;
    }

    if (options.some((item) => item.value === optionName)) {
      openModal.warning({
        title: <>此属性已存在同名的选项</>,
        noCancel: true,
        confirm() {
          inputRef.current?.focus();
        },
      });
      return 'prevent';
    }

    updateOption(collectionId, propertyId, {
      id: option.id,
      value: optionName,
    });
  };
  closeBeforeCallbackRef.current = checkNameThenUpdate;

  return (
    <div className="flex flex-col w-[240px] next-modal">
      <div className="flex flex-row items-center w-full">
        <Input
          inputRef={inputRef}
          autoFocus
          placeholder="请输入名称"
          value={value}
          onChange={setValue}
          onEnter={(event: KeyboardEvent) => {
            event.preventDefault();
            closeModal();
          }}
          className="h-8 mx-2.5 my-2.5 flex-1"
        />
      </div>
      <div className="flex flex-col mb-[5px]">
        <ListView
          enableKeyDown={false}
          items={[
            {
              type: ListItemType.OPERATION,
              data: {
                icon: 'IcMenuDelete',
                title: '删除',
                onClick: () => {
                  openModal.warning({
                    title: <>是否确认删除该选项？</>,
                    confirm: () => {
                      closeBeforeCallbackRef.current = undefined;
                      removeOption(collectionId, propertyId, option.id);
                      closeModal();
                    },
                  });
                },
              },
            },
            {
              type: ListItemType.LINE,
              data: {},
              disableClick: true,
            },
          ]}
        />
        <ColorSelector
          bgColorFn={getTagBackgroundColor}
          value={option.color}
          onSelect={(color: string) => {
            updateOption(collectionId, propertyId, {
              id: option.id,
              color,
            });
            closeModal();
          }}
        />
      </div>
    </div>
  );
};
